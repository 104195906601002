import React, { useEffect, useState, useRef } from "react";

// components
import RulesCreation from "./RulesCreation";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";
import { Switch } from "../_commons/Switch";
import ContextMenu from "../_commons/ContextMenu";
import TextHighlightChip from "../_commons/TextHighlightChip";
import ConflictingRuleModal from "./ConflictingRuleModal";
import ResolveConflictDrawer from "./ResolveConflictDrawer";
import { clientMenu } from "../../client-menu";

import {
	GET_RULES_LIST,
	UPDATE_RULE_STATUS,
	GET_CONFLICTS_LIST,
	GET_PUBLISH_LOCATIONS_LIST
} from "../../graphql/menus";
import { capitaliseText, removeProp } from "../../atlas-utils";
import Image from "../_commons/Image";
import Placeholder from "../_commons/Placeholder";
import ArchiveRestoreModal from "../_commons/ArchiveRestoreModal";
import { store } from "../../store/configureStore";
import { toggleGlobalLoader } from "../../actions/actions";
import { client } from "../../client";
import { GET_BIZ_PLATFORMS_MINI } from "../../graphql/locations";
import { getMenuMetaData } from "../../actions/menus";

const OPERATION_UI_LABEL_MAP = {
	price: "Default price",
	description: "Description",
	image_url: "Image",
	markup_price: "Markup price",
	name: "Name"
};

const CONTEXT_MENU_INITIAL_STATE = {
	contextId: null
};

const LOADING_STATES = {
	locations: false,
	platforms: false,
	items: false,
	options: false,
	mainView: false
};

const LOCATIONS_LIST_INIT_STATE = {
	count: 0,
	objects: [],
	filters: []
};

const SELECTED_FIELDS_INIT_VALUE = {
	entity: null,
	platform: null,
	location: null
};

const MenuRules = ({ menuId, isOpen, brandId, isPOSmenuType = false, parentSections = [] }) => {
	const [isConflictingRuleModalOpen, setConflictingRuleModalOpen] = useState(false);
	const [latestModifiedRuleDetails, setLatestModifiedRuleDetails] = useState({});
	const [isResolveConflictDrawerOpen, setResolveConflictDrawerOpen] = useState(false);
	const [conflictingRuleDetails, setConflictingRuledetails] = useState([]);

	const [isRulesListLoading, setIsRulesListLoading] = useState(false);
	const [rulesList, setRulesList] = useState([]);
	const [contextMenuData, setContextMenuData] = useState(CONTEXT_MENU_INITIAL_STATE);
	const [ruleCreateEditForm, setRuleCreateEditForm] = useState({
		isOpen: false,
		isEditMode: false,
		ruleId: null
	});

	const [deleteModalStates, setDeleteModalStates] = useState({
		isOpen: false,
		ruleId: null
	});
	const [loadingStates, setLoadingStates] = useState(LOADING_STATES);
	const [itemsList, setItemsList] = useState({});
	const [platformsData, setPlatformsData] = useState({});
	const [locationsData, setLocationsData] = useState({});
	const [selectedFields, setSelectedFields] = useState(SELECTED_FIELDS_INIT_VALUE);
	const [searchKey, setSearchKey] = useState("");
	const debouncedSearchRef = useRef();

	const showRulesCreationDrawer = (isOpen = true, isEditMode = false, ruleId = null) => {
		setRuleCreateEditForm({
			isOpen,
			isEditMode,
			ruleId
		});
	};

	const handleFilterFieldSelection = (field, value) => {
		setSelectedFields((current) => ({
			...current,
			[field]: value
		}));
	};

	const handleSearch = (field, value) => {
		setSearchKey(value);
	};

	const handleLoadingStates = (field, state) => {
		setLoadingStates((current) => ({
			...current,
			[field]: state
		}));
	};

	const fetchPlatformsList = async () => {
		handleLoadingStates("platforms", true);
		try {
			const variables = {
				filters: [
					{
						field: "is_enabled",
						value: true
					}
				],
				includeUrbanpiper: false
			};
			const respPlatforms = await client.query({
				query: GET_BIZ_PLATFORMS_MINI,
				variables,
				fetchPolicy: "no-cache"
			});

			setPlatformsData(respPlatforms?.data?.bizPlatforms ?? {});
			handleLoadingStates("platforms", false);
		} catch (e) {
			handleLoadingStates("platforms", false);
			console.log(e);
		}
	};

	const fetchLocationsList = async (offset = 0) => {
		handleLoadingStates("locations", true);
		try {
			const variables = {
				limit: 50,
				offset: offset,
				filters: {
					brand: brandId ? String(brandId) : null
				},
				searchKeyword: null,
				menuId,
				sortOptions: {}
			};
			const respLocation = await clientMenu.query({
				query: GET_PUBLISH_LOCATIONS_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			if (respLocation?.data?.locations) {
				setLocationsData(respLocation.data.locations);
			}
			handleLoadingStates("locations", false);
		} catch (e) {
			console.log(e);
			handleLoadingStates("locations", false);
		}
	};

	const closeRulesCreationDrawer = (refresh) => {
		setRuleCreateEditForm({
			isOpen: false,
			isEditMode: false,
			ruleId: null
		});
		if (refresh) {
			fetchRules();
		}
	};

	const openDeleteModal = (record) => {
		setDeleteModalStates((current) => ({
			...current,
			isOpen: true,
			ruleId: record?.ruleId
		}));
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
		setConflictingRuleModalOpen(false);
	};

	const closeDeleteModal = (refresh) => {
		setDeleteModalStates((current) => ({
			...current,
			isOpen: false,
			ruleId: null
		}));

		if (refresh) {
			fetchRules();
		}
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => showRulesCreationDrawer(true, true, record?.ruleId)}>
					Edit Rule
				</div>
				<div className="action-item action-item--archive" onClick={() => openDeleteModal(record)}>
					Delete Rule
				</div>
			</React.Fragment>
		);
	};

	const handleClickOutsideContextMenu = () => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};

	const openContextMenu = (id) => {
		setContextMenuData((current) => ({ ...current, contextId: id }));
	};

	const fetchRules = async () => {
		store.dispatch(toggleGlobalLoader(true));
		setIsRulesListLoading(true);
		const filterOptions = {};

		if (selectedFields?.entity) {
			filterOptions.entityType = selectedFields?.entity?.value;
		}
		if (selectedFields?.platform) {
			filterOptions.platforms = [selectedFields?.platform?.platformName.toLowerCase()];
		}
		if (selectedFields?.location) {
			filterOptions.locations = [String(selectedFields?.location?.id)];
		}

		try {
			const variables = {
				menuId,
				searchKeyword: searchKey,
				filterOptions: !!Object.keys(filterOptions).length ? filterOptions : null
			};
			const resp = await clientMenu.query({
				query: GET_RULES_LIST,
				variables,
				fetchPolicy: "no-cache"
			});

			const rulesArray = resp?.data?.rulesV2 ?? [];

			setRulesList(rulesArray);
			setIsRulesListLoading(false);
		} catch (e) {
			setIsRulesListLoading(false);
			console.log(e);
		}
		store.dispatch(toggleGlobalLoader(false));
	};

	const updateRuleStatus = (ruleId, newStatus) => {
		const updatedRulesList = rulesList?.map((rule) => {
			if (rule?.id === ruleId) return { ...rule, status: newStatus };
			return rule;
		});

		setRulesList(updatedRulesList);
	};

	const handleRuleStatusUpdate = async (ruleId, newStatus) => {
		try {
			updateRuleStatus(ruleId, newStatus);
			const variables = {
				menuId,
				ruleId,
				newStatus
			};

			const ruleStatusUpdateResp = await clientMenu.mutate({
				mutation: UPDATE_RULE_STATUS,
				variables,
				fetchPolicy: "no-cache"
			});
			if (!ruleStatusUpdateResp?.data?.updateRuleStatusV2?.success) {
				updateRuleStatus(ruleId, newStatus === "active" ? "disabled" : "active");
				setLatestModifiedRuleDetails({
					ruleObject: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.ruleObject,
					error: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.error,
					success: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.success,
					conflicts: ruleStatusUpdateResp?.data?.updateRuleStatusV2?.conflicts
				});
				if (
					ruleStatusUpdateResp?.data?.updateRuleStatusV2?.error === "CONFLICT_DETECTED" &&
					ruleStatusUpdateResp?.data?.updateRuleStatusV2?.conflicts?.length > 0
				) {
					setConflictingRuleModalOpen && setConflictingRuleModalOpen(true);
				}
			} else {
				getMenuMetaData(menuId);
			}
		} catch (e) {
			updateRuleStatus(ruleId, newStatus === "active" ? "disabled" : "active");

			console.log(e);
		}
	};

	const calculateRulesCount = (rulesObjectKeysArray) => {
		let count = 0;

		rulesObjectKeysArray.forEach((entityId) => {
			count += rulesList[entityId].length;
		});

		return count;
	};

	useEffect(() => {
		if (debouncedSearchRef.current) {
			clearTimeout(debouncedSearchRef.current);
			debouncedSearchRef.current = setTimeout(() => {
				fetchRules();
			}, 500);
		} else {
			debouncedSearchRef.current = setTimeout(() => {
				fetchRules();
			}, 500);
		}

		return () => {
			if (debouncedSearchRef.current) {
				clearTimeout(debouncedSearchRef.current);
			}
		};
	}, [searchKey]);

	useEffect(() => {
		fetchRules();
	}, [selectedFields]);

	useEffect(() => {
		fetchRules();
		fetchLocationsList();
		fetchPlatformsList();
	}, []);

	const totalRulesCount = rulesList?.length || 0;

	if (isRulesListLoading && rulesList.length === 0) {
		return (
			<div className="menu-rules-container P(10px)">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	if (rulesList.length === 0) {
		return (
			<div className={`menu-rules-container ${isRulesListLoading ? "disabled" : ""}`}>
				<Header
					totalRulesCount={totalRulesCount}
					showRulesCreationDrawer={showRulesCreationDrawer}
					handleFilterFieldSelection={handleFilterFieldSelection}
					selectedFields={selectedFields}
					platformsData={platformsData}
					locationsData={locationsData}
					itemsList={itemsList}
					handleSearch={handleSearch}
					searchKey={searchKey}
					isPOSmenuType={isPOSmenuType}
				/>
				<Placeholder
					placeholderImageUrl="/assets/empty_states/graphics-empty-rules.svg"
					placeholderText="No rules added yet!"
					placeholderSubtext="Manage and create menu related rules here"
					// redirectionLink="#"
					size="medium"
					placeholderButtonContent={<span>+ Add Your First Rule</span>}
					placeholderButtonClickAction={!isPOSmenuType ? showRulesCreationDrawer : () => {}}
					// redirectionLinkText="Learn more about Rules"
				/>
				<RulesCreation
					isOpen={ruleCreateEditForm?.isOpen}
					close={closeRulesCreationDrawer}
					brandId={brandId}
					menuId={menuId}
					parentSections={parentSections}
				/>
				<ArchiveRestoreModal
					isOpen={deleteModalStates?.isOpen}
					mode="delete"
					entityType="menuRule"
					entityName="Rule"
					dataObject={{ menuId, ruleId: deleteModalStates?.ruleId }}
					close={closeDeleteModal}
				/>
			</div>
		);
	}

	const handleReviewConflicts = async () => {
		const ruleIds = latestModifiedRuleDetails?.conflicts?.map((ct) => ct?.ruleId) || [];
		const resp = await fetchConflictsList(ruleIds);
		if (resp?.data?.rulesV2?.length) {
			setResolveConflictDrawerOpen(true);
		}
		setConflictingRuleModalOpen(false);
	};

	const fetchConflictsList = async (ruleIds = []) => {
		try {
			let resp = {};
			if (ruleIds?.length > 0) {
				resp = await clientMenu.query({
					query: GET_CONFLICTS_LIST,
					variables: { menuId, filterOptions: { ruleIds } },
					fetchPolicy: "no-cache"
				});
				if (resp?.data?.rulesV2?.length) {
					setConflictingRuledetails(resp?.data?.rulesV2);
				}
			} else {
				setConflictingRuledetails([]);
			}
			return resp;
		} catch (e) {
			console.log(e);
		}
	};

	function extractConflictingValues(conflicts = []) {
		const OPERATION_LABEL_MAP = {
			change_default_price: "Default price",
			change_description: "Description",
			change_image: "Image",
			change_markup_price: "Markup price",
			change_name: "Name"
		};
		return conflicts.reduce((conflictingValues, conflict) => {
			const { conflictingLocations, conflictingPlatforms, conflictingOperations, entityId } = conflict;

			conflictingValues.push(entityId);

			const locationValues = conflictingLocations.reduce((values, location) => {
				values.add(location);
				return values;
			}, new Set(conflictingValues));

			const platformValues = conflictingPlatforms.reduce((values, platform) => {
				values.add(platform);
				return values;
			}, locationValues);

			const operationValues = conflictingOperations.reduce((values, operation) => {
				const label = OPERATION_LABEL_MAP[operation];
				values.add(label || operation);
				return values;
			}, platformValues);

			return Array.from(operationValues);
		}, []);
	}

	let conflictingValues = [];

	if (latestModifiedRuleDetails) {
		conflictingValues = extractConflictingValues(latestModifiedRuleDetails?.conflicts);
	}
	const handleResolveConflictEditAction = (record) => {
		setResolveConflictDrawerOpen(false);
		showRulesCreationDrawer(true, true, record?.ruleId);
	};
	const handleUpdateRuleResolveSuccess = (updateRuleResp) => {
		setLatestModifiedRuleDetails({
			ruleObject: updateRuleResp?.data?.updateRuleV2?.ruleObject,
			error: updateRuleResp?.data?.updateRuleV2?.error,
			success: updateRuleResp?.data?.updateRuleV2?.success,
			conflicts: updateRuleResp?.data?.updateRuleV2?.conflicts
		});
		const conflictRuleIds = updateRuleResp?.data?.updateRuleV2?.conflicts?.map((ct) => ct?.ruleId) || [];
		fetchConflictsList(conflictRuleIds);
		setResolveConflictDrawerOpen(true);
	};

	return (
		<>
			<div className={`menu-rules-container ${isRulesListLoading ? "disabled" : ""}`}>
				<Header
					totalRulesCount={totalRulesCount}
					showRulesCreationDrawer={showRulesCreationDrawer}
					handleFilterFieldSelection={handleFilterFieldSelection}
					selectedFields={selectedFields}
					platformsData={platformsData}
					locationsData={locationsData}
					itemsList={itemsList}
					handleSearch={handleSearch}
					searchKey={searchKey}
					isPOSmenuType={isPOSmenuType}
				/>
				<div className="rules-basic-info-container">
					{rulesList &&
						rulesList?.map((rule, i) => (
							<RuleBasicInfo
								key={i}
								ruleInfo={rule}
								handleRuleStatusUpdate={handleRuleStatusUpdate}
								contextMenuData={contextMenuData}
								renderMenuItems={renderMenuItems}
								handleClickOutsideContextMenu={handleClickOutsideContextMenu}
								openContextMenu={openContextMenu}
								isPOSmenuType={isPOSmenuType}
							/>
						))}
				</div>
			</div>
			<RulesCreation
				isOpen={ruleCreateEditForm?.isOpen}
				isEditMode={ruleCreateEditForm?.isEditMode}
				close={closeRulesCreationDrawer}
				brandId={brandId}
				menuId={menuId}
				ruleId={ruleCreateEditForm?.ruleId}
				latestModifiedRuleDetails={latestModifiedRuleDetails}
				setLatestModifiedRuleDetails={setLatestModifiedRuleDetails}
				setConflictingRuleModalOpen={setConflictingRuleModalOpen}
				handleUpdateRuleResolveSuccess={handleUpdateRuleResolveSuccess}
				parentSections={parentSections}
			/>
			<ConflictingRuleModal
				isOpen={isConflictingRuleModalOpen}
				handleDiscardNewRule={() => {
					openDeleteModal({
						ruleId: latestModifiedRuleDetails?.ruleObject?.id
					});
				}}
				handleReviewConflicts={handleReviewConflicts}
				latestModifiedRuleDetails={latestModifiedRuleDetails}
			/>
			<ResolveConflictDrawer
				isOpen={isResolveConflictDrawerOpen}
				newRuleDetails={latestModifiedRuleDetails}
				conflictingRuleDetails={conflictingRuleDetails}
				conflictingValues={conflictingValues}
				menuId={menuId}
				setResolveConflictDrawerOpen={setResolveConflictDrawerOpen}
				handleNewRuleEditAction={handleResolveConflictEditAction}
				fetchRules={fetchRules}
				setLatestModifiedRuleDetails={setLatestModifiedRuleDetails}
			/>
			<ArchiveRestoreModal
				isOpen={deleteModalStates?.isOpen}
				mode="delete"
				entityType="menuRule"
				entityName="Rule"
				dataObject={{ menuId, ruleId: deleteModalStates?.ruleId }}
				close={closeDeleteModal}
			/>
		</>
	);
};
export default MenuRules;

const Header = ({
	showRulesCreationDrawer,
	totalRulesCount,
	handleFilterFieldSelection,
	selectedFields,
	platformsData,
	locationsData,
	itemsList,
	handleSearch,
	searchKey = "test",
	isPOSmenuType = false
}) => (
	<div className="menu-rules-container--header">
		<div className="count-create-cta-container">
			<div className="count">{totalRulesCount || 0} Menu Rules</div>
			{!isPOSmenuType && (
				<div className="hyperlink" onClick={showRulesCreationDrawer}>
					+ Create Rule
				</div>
			)}
		</div>
		<div className="rule-selector-search-container">
			<div className="dropdown-filter-container">
				<SelectFilter
					placeholder="All Entities"
					field="entity"
					options={[
						{
							label: "Modifier",
							value: "option"
						},
						{
							label: "Item",
							value: "item"
						}
					]}
					currValue={selectedFields?.entity}
					setFilter={handleFilterFieldSelection}
				/>
				<SelectFilter
					placeholder="All Locations"
					labelKey="name"
					valueKey="id"
					options={locationsData?.objects}
					currValue={selectedFields?.location}
					field="location"
					setFilter={handleFilterFieldSelection}
				/>
				<SelectFilter
					placeholder="All Platforms"
					labelKey="platformName"
					valueKey="id"
					options={platformsData?.objects}
					currValue={selectedFields?.platform}
					field="platform"
					setFilter={handleFilterFieldSelection}
				/>
			</div>
			<SearchFilter
				placeholder="Search"
				setFilter={handleSearch}
				value={searchKey}
				filterOption={{ field: "searchByName" }}
			/>
		</div>
	</div>
);

const RuleBasicInfo = ({
	ruleInfo,
	handleRuleStatusUpdate,

	contextMenuData,
	renderMenuItems,
	handleClickOutsideContextMenu,
	openContextMenu,

	isPOSmenuType = false
}) => {
	return (
		<div className="rules-basic-info-content">
			<div className="header-section">
				<div className="title-subtitle-container">
					<div className="title">{ruleInfo?.name || "--"}</div>
					<div className="actions-container">
						<Switch
							readOnly={isPOSmenuType}
							checked={ruleInfo?.status === "active"}
							clickHandler={() =>
								handleRuleStatusUpdate(
									ruleInfo?.id,
									ruleInfo.status === "active" ? "disabled" : "active"
								)
							}
						/>
						<ContextMenu
							disableContextMenu={isPOSmenuType}
							isOpen={contextMenuData?.contextId === ruleInfo?.id}
							data={{
								ruleId: ruleInfo?.id
							}}
							renderMenuItems={renderMenuItems}
							handleOpenMenu={(e) => {
								e.stopPropagation();
								openContextMenu(ruleInfo?.id);
							}}
							handleOutsideClick={
								contextMenuData?.contextId === ruleInfo?.id
									? () => handleClickOutsideContextMenu()
									: () => {}
							}
						/>
					</div>
				</div>
			</div>

			<div className="rule-info" key={ruleInfo?.id}>
				<div className="rule-description">
					When
					{!!ruleInfo?.locations?.length && (
						<>
							<TextHighlightChip content={"Location"} />
							is
							<TextHighlightChip content={ruleInfo?.locations?.map((loc) => loc?.locationName)} />
						</>
					)}
					{!!ruleInfo?.locations?.length && !!ruleInfo?.platforms?.length && "+"}
					{!!ruleInfo?.platforms?.length && (
						<>
							<TextHighlightChip content={"Platform"} />
							is
							<TextHighlightChip content={ruleInfo?.platforms?.map((plf) => capitaliseText(plf))} />
						</>
					)}
					<Image alt="right arrow" src="/assets/icons/icon-right-arrow-tailed.svg" />
					{ruleInfo?.operations?.some((op) => op.field === "sold_at") ? (
						<>&ensp; Do not sell </>
					) : (
						<>
							&ensp; Change
							<TextHighlightChip
								content={(ruleInfo?.operations ?? [])?.map(
									(operation) => OPERATION_UI_LABEL_MAP[operation?.field]
								)}
							/>
							of{" "}
						</>
					)}
					<TextHighlightChip content={(ruleInfo?.entitiesInfo ?? [])?.map((entity) => entity?.entityName)} />
				</div>
			</div>
		</div>
	);
};
