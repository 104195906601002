import React, { useState, useRef, useEffect, useCallback } from "react";

// components
import { CheckBox } from "../_commons/CheckBox";
import DropdownCustom from "../_commons/DropdownCustom";

// utils
import { extractInitials } from "../../atlas-utils";

export const SelectFilterGroupCustom = ({
	title,
	options = [],
	field,
	currValue,
	setFilter,
	isLoading,
	labelKey = "label",
	valueKey = "value",
	iconKey = "image",
	requiredLabel = false,
	placeholder = "Select",
	multi = false,
	customLabel = false,
	customOptions = false,
	renderLabel,
	renderOptions,
	customSearchLabel = false,
	handleCustomSearchLabel,
	showIcon = false,
	showCheckBox = false,
	showSelectAllOption = false,
	selectAllPlaceholder = "-- Select all --",
	optionUpdates = {},
	handleCheck,
	isCheckedAll = {},
	handleCheckAll,
	isSearchable = true,
	isClearable = false,
	ellipsizedLength = 30,
	classes = "",
	readOnly = false,
	hideSelectedValues,
	validationMessage = "",
	countDisplayText = "",
	maxSelectable = 0,
	isSearchExternal = false,
	handleSelectFilterState = () => {},
	handleSearch = () => {},
	selected = "selected",
	enableGrouping = false,
	groupedOptions = [],
	groupObjectKey = "options",
	handleSelectAll = () => {}
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchVal, setSearchVal] = useState("");
	const dropdownRef = useRef(null);
	const searchInputRef = useRef(null);
	const count =
		optionUpdates[field] && Object.values(optionUpdates[field])
			? Object.values(optionUpdates[field])?.filter((val) => val === true).length
			: 0;

	const outsideClickHandler = () => {
		setIsOpen(false);
		setSearchVal("");
	};

	const handleClickDropdown = useCallback(() => {
		setIsOpen(!isOpen);

		// focus search input when dropdown is opened, if it's searchable
		if (!isOpen && isSearchable) {
			setTimeout(() => {
				if (searchInputRef.current) {
					searchInputRef.current.focus();
				}
			}, 300);
		}
	}, [isOpen, isSearchable, searchInputRef]);

	useEffect(() => {
		handleSelectFilterState(isOpen, field);
	}, [isOpen]);

	useEffect(() => {
		handleSearch(searchVal);
	}, [searchVal]);

	const handleSelect = (f, opt) => {
		setFilter(f, opt);
		outsideClickHandler();
	};

	const handleClearCurrValue = (e) => {
		e.stopPropagation();
		setFilter(field, multi ? [] : null);
	};

	const getDropdownLabel = () => {
		if (hideSelectedValues) {
			return <div className="placeholder">{placeholder}</div>;
		}
		if (customLabel && multi && isCheckedAll[field]) {
			return (
				<div className="option all">
					All {field || "options"} {selected}
				</div>
			);
		} else if (customLabel && (multi ? currValue?.length > 0 : currValue)) {
			return (
				<div className="option">
					<div className="custom-label">
						{renderLabel(currValue, handleCheck, field, { labelKey, valueKey })}
					</div>
				</div>
			);
		} else if (showCheckBox && multi && !customLabel) {
			return isCheckedAll[field] ? (
				<div className="option">{`All ${countDisplayText || "option"}s ${selected}`}</div>
			) : count > 0 ? (
				<div className="option">
					{count} {`${countDisplayText || "option"}` + (count > 1 ? `s ${selected}` : ` ${selected}`)}
				</div>
			) : (
				<div className="placeholder">{placeholder}</div>
			);
		} else if (currValue?.[labelKey]) {
			return (
				<div className="option">
					{showIcon && (
						<div className="option-icon">
							<img src={currValue.icon} alt="" />
						</div>
					)}
					<div>
						{currValue[labelKey] && currValue[labelKey].length > ellipsizedLength
							? currValue[labelKey].slice(0, ellipsizedLength) + "..."
							: currValue[labelKey]}
					</div>
				</div>
			);
		}
		return <div className="placeholder">{placeholder}</div>;
	};

	const handleDropdownComponentPosition = () => {
		setTimeout(() => {
			const dropdownCustom = dropdownRef?.current?.querySelector(".dropdown-custom") || null;
			const dropdownComponent = dropdownCustom ? dropdownCustom?.querySelector(".dropdown-component") : null;
			if (dropdownCustom && dropdownComponent) {
				dropdownComponent.style.top = `${dropdownCustom.clientHeight + 6}px`;
			}
		}, 10);
	};

	useEffect(() => {
		if (isOpen && multi) {
			handleDropdownComponentPosition();
		}
	}, [isOpen, multi, currValue]);

	const isCheckBoxDisabled = (readOnly = false, isChecked = false) => {
		if (maxSelectable !== 0) {
			if (!isChecked && maxSelectable === count) {
				return true;
			} else {
				return false;
			}
		}
		return readOnly;
	};

	return (
		<div ref={dropdownRef} className={"select-filter-custom " + (readOnly ? "read-only " : "") + classes}>
			{title && <div className={"meta-info" + (requiredLabel ? " required-sign" : "")}>{title}</div>}
			<DropdownCustom
				selected={getDropdownLabel()}
				currValue={currValue}
				multi={multi && customLabel}
				isOpen={isOpen}
				handleClick={handleClickDropdown}
				handleOutsideClick={outsideClickHandler}
				clickEvent="mousedown"
				classes={isLoading ? "no-click" : ""}
				isClearable={isClearable}
				clearCurrValue={handleClearCurrValue}
			>
				<div className="dropdown-options">
					{maxSelectable ? (
						<div
							className={
								"max-selectable-message" +
								(count === maxSelectable ? " max-selection-reached-color" : "")
							}
						>
							{isCheckedAll[field] ? `All selected` : `Selected ${count}/${maxSelectable} `}
						</div>
					) : null}
					{isSearchable && (
						<div className="option search-input">
							<input
								ref={searchInputRef}
								onChange={(e) => setSearchVal(e.target.value)}
								placeholder="Type to search"
							/>
						</div>
					)}
					{enableGrouping ? (
						groupedOptions?.length > 0 &&
						groupedOptions.map((groupOption, i) => (
							<>
								<div className="option-header-container">
									<p className="option-header">{groupOption?.name}</p>
									{optionUpdates[groupOption?.id] ? (
										<span
											className="hyperlink option-select-all"
											onClick={() => handleSelectAll(groupOption?.id, field, true)}
										>
											Deselect All
										</span>
									) : (
										<span
											className="hyperlink option-select-all"
											onClick={() => handleSelectAll(groupOption?.id, field)}
										>
											Select All
										</span>
									)}
								</div>

								{groupOption?.[groupObjectKey]?.length > 0 &&
									groupOption?.[groupObjectKey]
										?.filter((opt) =>
											searchVal !== "" && !isSearchExternal
												? (customSearchLabel
														? handleCustomSearchLabel(opt, { labelKey, valueKey })
														: opt?.[labelKey]
													)
														?.toLowerCase()
														.includes(searchVal.toLowerCase())
												: true
										)
										.map((opt, i) => (
											<div
												key={i}
												className={
													"option " +
													(!multi
														? opt?.[labelKey] === currValue?.[labelKey]
															? "selected"
															: ""
														: "")
												}
												onClick={
													!showCheckBox
														? () => handleSelect(field, opt)
														: (e) => e.stopPropagation()
												}
											>
												{!showCheckBox && showIcon ? (
													opt.icon || opt[iconKey] ? (
														<div className="option-icon">
															<img src={opt.icon || opt[iconKey]} alt="" />
														</div>
													) : (
														<div className={"initials " + (opt.color ? opt.color : "")}>
															{extractInitials(opt[labelKey]?.split(" "))}
														</div>
													)
												) : null}
												{customOptions && (
													<div className="custom-option">
														{renderOptions(opt, field, handleCheck, optionUpdates, {
															labelKey,
															valueKey
														})}
													</div>
												)}
												{!customOptions && !showCheckBox && (
													<div>
														{opt[labelKey] && opt[labelKey].length > ellipsizedLength
															? opt[labelKey].slice(0, ellipsizedLength) + "..."
															: opt[labelKey]}
													</div>
												)}
												{!customOptions && showCheckBox && multi && (
													<CheckBox
														checked={
															optionUpdates[field] && optionUpdates[field][opt[valueKey]]
																? true
																: false
														}
														clickHandler={() =>
															handleCheck(
																optionUpdates[field] &&
																	optionUpdates[field][opt[valueKey]]
																	? false
																	: true,
																field,
																opt,
																{ labelKey, valueKey }
															)
														}
														readOnly={isCheckBoxDisabled(
															opt?.readOnly,
															optionUpdates[field] && optionUpdates[field][opt[valueKey]]
																? true
																: false
														)}
														title={opt[labelKey]}
													>
														{showIcon ? (
															opt.icon || opt[iconKey] ? (
																<div className="option-icon">
																	<img src={opt.icon || opt[iconKey]} alt="" />
																</div>
															) : (
																<div
																	className={
																		"initials " + (opt.color ? opt.color : "")
																	}
																>
																	{extractInitials(opt[labelKey]?.split(" "))}
																</div>
															)
														) : null}
														{opt[labelKey] && opt[labelKey].length > ellipsizedLength
															? opt[labelKey].slice(0, ellipsizedLength) + "..."
															: opt[labelKey]}
													</CheckBox>
												)}
											</div>
										))}
							</>
						))
					) : (
						<>
							{options.length > 0 && showCheckBox && multi && showSelectAllOption && searchVal === "" && (
								<div className="option">
									<CheckBox
										checked={isCheckedAll[field]}
										clickHandler={() =>
											handleCheckAll(!isCheckedAll[field], field, options, { labelKey, valueKey })
										}
									>
										{selectAllPlaceholder}
									</CheckBox>
								</div>
							)}
							{options?.length > 0 &&
								options
									?.filter((opt) =>
										searchVal !== "" && !isSearchExternal
											? (customSearchLabel
													? handleCustomSearchLabel(opt, { labelKey, valueKey })
													: opt?.[labelKey]
												)
													?.toLowerCase()
													.includes(searchVal.toLowerCase())
											: true
									)
									.map((opt, i) => (
										<div
											key={i}
											className={
												"option " +
												(!multi
													? opt?.[labelKey] === currValue?.[labelKey]
														? "selected"
														: ""
													: "")
											}
											onClick={
												!showCheckBox
													? () => handleSelect(field, opt)
													: (e) => e.stopPropagation()
											}
										>
											{!showCheckBox && showIcon ? (
												opt.icon || opt[iconKey] ? (
													<div className="option-icon">
														<img src={opt.icon || opt[iconKey]} alt="" />
													</div>
												) : (
													<div className={"initials " + (opt.color ? opt.color : "")}>
														{extractInitials(opt[labelKey]?.split(" "))}
													</div>
												)
											) : null}
											{customOptions && (
												<div className="custom-option">
													{renderOptions(opt, field, handleCheck, optionUpdates, {
														labelKey,
														valueKey
													})}
												</div>
											)}
											{!customOptions && !showCheckBox && (
												<div>
													{opt[labelKey] && opt[labelKey].length > ellipsizedLength
														? opt[labelKey].slice(0, ellipsizedLength) + "..."
														: opt[labelKey]}
												</div>
											)}
											{!customOptions && showCheckBox && multi && (
												<CheckBox
													checked={
														optionUpdates[field] && optionUpdates[field][opt[valueKey]]
															? true
															: false
													}
													clickHandler={() =>
														handleCheck(
															optionUpdates[field] && optionUpdates[field][opt[valueKey]]
																? false
																: true,
															field,
															opt,
															{ labelKey, valueKey }
														)
													}
													readOnly={isCheckBoxDisabled(
														opt?.readOnly,
														optionUpdates[field] && optionUpdates[field][opt[valueKey]]
															? true
															: false
													)}
													title={opt[labelKey]}
												>
													{showIcon ? (
														opt.icon || opt[iconKey] ? (
															<div className="option-icon">
																<img src={opt.icon || opt[iconKey]} alt="" />
															</div>
														) : (
															<div className={"initials " + (opt.color ? opt.color : "")}>
																{extractInitials(opt[labelKey]?.split(" "))}
															</div>
														)
													) : null}
													{opt[labelKey] && opt[labelKey].length > ellipsizedLength
														? opt[labelKey].slice(0, ellipsizedLength) + "..."
														: opt[labelKey]}
												</CheckBox>
											)}
										</div>
									))}
							{options?.filter((opt) =>
								searchVal !== "" && !isSearchExternal
									? (customSearchLabel
											? handleCustomSearchLabel(opt, { labelKey, valueKey })
											: opt?.[labelKey]
										)
											?.toLowerCase()
											.includes(searchVal.toLowerCase())
									: true
							).length === 0 && (
								<div className="option no-hover">{isLoading ? "Loading..." : "No results found"}</div>
							)}
						</>
					)}
				</div>
			</DropdownCustom>
			{validationMessage && <div className="validation-message">{validationMessage}</div>}
		</div>
	);
};
