import React from "react";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";

export default function WarningCard({ showIcon, title, description }) {
	return (
		<div className="warning-card">
			{showIcon && (
				<div>
					<img src="/assets/icons/warning-icon.svg" alt="warning-icon" />
				</div>
			)}
			<div>
				<Text variant={TextVariant.HEADING4} className="warning-card__title">
					{title}
				</Text>
				<Text variant={TextVariant.BODY1} className="warning-card__subtitle">
					{description}
				</Text>
			</div>
		</div>
	);
}
