import React, { useState, useEffect } from "react";

// components
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import Popover from "../_commons/Popover";
import Placeholder from "../_commons/Placeholder";
import ContextMenu from "../_commons/ContextMenu";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { Modal } from "../_commons/Modal";
import SchedulesIntro from "./SchedulesIntro";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../history";
import Highlighter from "react-highlight-words";

// store
import { store } from "../../store/configureStore";

// utils
import { trackEvent } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchScheduleReportsList, editScheduleReport } from "../../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "./Overview";
import CreateIcon from "../_commons/CreateIcon";
import { fetchConfigurationValues } from "../../actions/actions";
const columns = [
	{
		name: "Report Name",
		field: "report-name",
		render: (record, i, rest) => (
			<div className="table-cell report-name" key={i}>
				<Link
					className="hyperlink hyperlink--black-color"
					to={`/reports/history/schedules/${record.id}?name=${record?.report?.name
						?.toLowerCase()
						?.split(" ")
						?.join("-")}&group=${record?.report?.group}`}
				>
					<Highlighter
						className="name"
						highlightClassName="highlight"
						searchWords={[rest.searchKeyword]}
						autoEscape={true}
						textToHighlight={record?.report?.name || "--"}
					/>
				</Link>
				<div className="category">{REPORTS_GROUP_MAP[record?.report?.group]?.name || "--"}</div>
			</div>
		)
	},
	{
		name: "Scheduled For",
		field: "scheduled-for",
		render: (record, i, rest) => (
			<div className="table-cell scheduled-for" key={i}>
				{record.scheduleType || ""}
			</div>
		)
	},
	{
		name: "Data Period",
		field: "data-period",
		render: (record, i, rest) => (
			<div className="table-cell data-period" key={i}>
				{record.showDataFor || ""}
			</div>
		)
	},
	{
		name: "Recipient",
		field: "recipient",
		render: (record, i, rest) => (
			<div className="table-cell recipient" key={i}>
				{record.emails?.length > 0 &&
					record.emails?.slice(0, 2)?.map((email, j) => (
						<div key={j} className="email" title={email}>
							{email}
						</div>
					))}
				{record.emails?.length > 2 && (
					<Popover
						data={record}
						showOnHover={false}
						showOnClick={true}
						renderPopover={rest.handleEmailsPopover}
						position="down-right"
					>
						<div className="more">+ more</div>
					</Popover>
				)}
			</div>
		)
	},
	{
		name: "Status",
		field: "schedule-status",
		render: (record, i) => (
			<div className="table-cell schedule-status" key={i}>
				<div className={"status " + record.status}>{record.status}</div>
			</div>
		)
	},
	{
		name: "Actions",
		field: "schedule-actions",
		render: (record, i, rest) => (
			<div className="table-cell schedule-actions" key={i}>
				<ContextMenu
					isOpen={rest.contextMenu?.id === record.id}
					data={record}
					renderMenuItems={rest.renderMenuItems}
					handleOpenMenu={() => rest.openContextMenu(record, rest)}
					handleOutsideClick={rest.contextMenu?.id === record.id ? rest.closeContextMenu : () => {}}
					clickEvent="mousedown"
					rest={rest}
				/>
			</div>
		)
	}
];
const INIT_MODAL_PROPS = {
	action: "",
	status: "",
	header: {
		text: ""
	},
	content: {
		title: "",
		text: ""
	},
	footer: {
		showSubmitAction: false,
		showDeleteAction: false,
		submitTitle: "",
		deleteTitle: ""
	},
	data: {},
	eventName: ""
};

const Schedules = ({ scheduleReportsList, searchKeyword = "", access, ongoingTasks }) => {
	const { limit, offset, sort, loading, data, currentFilters, appliedFilters } = scheduleReportsList;
	const [modalOpen, setModalOpen] = useState(false);
	const [modalContent, setModalContent] = useState(INIT_MODAL_PROPS);
	const [modalBtnLoading, setModalBtnLoading] = useState(false);
	const [contextMenu, setContextMenu] = useState({
		id: null,
		data: {},
		props: {}
	});

	useEffect(() => {
		fetchScheduleReportsList(searchKeyword);
	}, [limit, offset, sort, appliedFilters, searchKeyword]);

	const freeTrial = scheduleReportsList?.schedule_reports_enabled?.value == "1";
	const getFreeTrialReportData = async () => {
		const resp = await fetchConfigurationValues({
			keys: ["schedule_reports_enabled"]
		});
	};
	useEffect(() => {
		if (!freeTrial) {
			getFreeTrialReportData();
		}
		trackEvent("schedule_reports_list_view", {});
	}, []);

	useEffect(() => {
		if (scheduleReportsList?.schedule_reports_enabled?.key == "schedule_reports_enabled") {
			if (!freeTrial) {
				store.dispatch({
					type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
					payload: { banner: "schedule_reports_enabled" }
				});
			} else {
				if (ongoingTasks?.banner === "schedule_reports_enabled") {
					store.dispatch({
						type: ActionTypes.UPDATE_ONGOING_TASKS_STATE,
						payload: { banner: "schedule_reports_enabled" }
					});
				}
			}
		}
	}, [freeTrial, scheduleReportsList?.schedule_reports_enabled?.key]);

	const updateScheduleReportsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SCHEDULE_REPORTS_LIST,
			payload
		});
	};

	const handleFilters = (field, value) => {
		const updatedFilters = { ...currentFilters };
		updatedFilters[field] = value;
		updateScheduleReportsListState({
			currentFilters: updatedFilters,
			appliedFilters: updatedFilters,
			offset: 0
		});
	};

	const handlePagination = (page) => {
		// set new offset
		updateScheduleReportsListState({
			offset: (page - 1) * limit
		});
	};

	const handlePageSize = async (field, size) => {
		// set new limit
		if (size && size?.value !== limit) {
			updateScheduleReportsListState({
				[field]: size.value
			});
		}
	};

	const renderMenuItems = (data, props) => {
		return (
			<React.Fragment>
				<div className="action-item" onClick={() => handleScheduleReportActions("edit", data)}>
					<ButtonIcon icon="edit-pen" color="#AAAAAA" />
					<span>Edit</span>
				</div>
				{data.status === "paused" ? (
					<div className="action-item" onClick={() => handleScheduleReportActions("resume", data)}>
						<ButtonIcon icon="resume" color="#AAAAAA" />
						<span>Resume</span>
					</div>
				) : (
					<div className="action-item" onClick={() => handleScheduleReportActions("pause", data)}>
						<ButtonIcon icon="pause" color="#AAAAAA" />
						<span>Pause</span>
					</div>
				)}
				{/* <div className="action-item" onClick={() => handleScheduleReportActions("delete", data)}>
					<ButtonIcon icon="delete" color="#D64949" />
					<span>Delete</span>
				</div> */}
			</React.Fragment>
		);
	};

	const openContextMenu = (data, props) => {
		setContextMenu({
			id: contextMenu.id === data.id ? null : data.id,
			data,
			props
		});
	};

	const closeContextMenu = () => {
		setContextMenu({
			id: null,
			data: {},
			props: {}
		});
	};

	const handleEmailsPopover = (record) => {
		return (
			<div className="emails-popover">
				<div className="header">Recipient</div>
				<div className="emails-list">
					{record.emails.map((email, i) => (
						<div className="email-row" key={i}>
							<div className="value">{email}</div>
						</div>
					))}
				</div>
			</div>
		);
	};

	const handleModalClose = () => {
		setModalOpen(false);
		setModalContent(INIT_MODAL_PROPS);
	};

	const handleScheduleReportActions = (action, data) => {
		closeContextMenu();
		if (action === "edit") {
			// setModalContent({
			// 	action,
			// 	status: "active",
			// 	header: {
			// 		text: "Edit Schedule"
			// 	},
			// 	content: {
			// 		title: "This is an existing scheduled report",
			// 		text: "Making changes to this report will result in receiving an updated report. Do you want to proceed?"
			// 	},
			// 	footer: {
			// 		showSubmitAction: true,
			// 		showDeleteAction: false,
			// 		submitTitle: "Edit Schedule",
			// 		deleteTitle: ""
			// 	},
			// 	data,
			//	eventName: "schedule_report_edit"
			// });
			// setModalOpen(true);

			// skip modal action and redirect to edit view
			history.push(`/reports/list/schedules/edit/${data?.id}/${data?.report?.typeId?.replace("codex##", "")}`);
			return;
		}
		if (action === "pause") {
			setModalContent({
				action,
				status: "paused",
				header: {
					text: "Pause Schedule"
				},
				content: {
					title: "",
					text: "By pausing this scheduled report, you will no longer be receiving it. You can resume the report at any time. Do you wish to proceed?"
				},
				footer: {
					showSubmitAction: true,
					showDeleteAction: false,
					submitTitle: "Pause Schedule",
					deleteTitle: ""
				},
				data,
				eventName: "schedule_report_pause"
			});
			setModalOpen(true);
			return;
		}
		if (action === "resume") {
			setModalContent({
				action,
				status: "active",
				header: {
					text: "Resume Schedule"
				},
				content: {
					title: "",
					text: "By resuming this scheduled report, you will start receiving it again from its next scheduled run based on the most recent settings. Do you wish to proceed?"
				},
				footer: {
					showSubmitAction: true,
					showDeleteAction: false,
					submitTitle: "Resume Schedule",
					deleteTitle: ""
				},
				data,
				eventName: "schedule_report_resume"
			});
			setModalOpen(true);
			return;
		}
		if (action === "delete") {
			setModalContent({
				action,
				status: "deleted",
				header: {
					text: "Delete Schedule"
				},
				content: {
					title: "",
					text: "By deleting this scheduled report, you will no longer be receiving it. Do you want to proceed?"
				},
				footer: {
					showSubmitAction: false,
					showDeleteAction: true,
					submitTitle: "",
					deleteTitle: "Delete Schedule"
				},
				data,
				eventName: "schedule_report_delete"
			});
			setModalOpen(true);
			return;
		}
	};

	const handleModalAction = async () => {
		if (modalContent.action === "edit") {
			history.push(
				`/reports/list/schedules/edit/${modalContent?.data?.id}/${modalContent?.data?.report?.typeId?.replace(
					"codex##",
					""
				)}`
			);
			setModalOpen(false);
			setModalContent(INIT_MODAL_PROPS);
			return;
		}
		if (modalContent.action === "pause" || modalContent.action === "resume" || modalContent.action === "delete") {
			setModalBtnLoading(true);
			const status = await editScheduleReport(
				{
					...modalContent.data,
					status: modalContent.status
				},
				true
			);
			if (status?.success) {
				// track event
				trackEvent(modalContent.eventName, {
					report_name: modalContent?.data?.report?.name,
					report_category: REPORTS_GROUP_MAP[modalContent?.data?.report?.group]?.name || ""
				});

				setModalOpen(false);
				setModalContent(INIT_MODAL_PROPS);
				fetchScheduleReportsList(searchKeyword);
			}
			setModalBtnLoading(false);
			return;
		}
	};

	return (
		<>
			{!freeTrial ? (
				<SchedulesIntro />
			) : (
				<div className="schedule-reports">
					<div className="section-header-container">
						<div className="section-title">
							Scheduled Reports <span className="free-trial-active">Free Trial Active</span>
						</div>
						<div className="section-actions">
							{((!searchKeyword && data?.objects?.length > 0) ||
								searchKeyword ||
								data?.objects?.length > 0 ||
								loading) && (
								<Link
									to="/reports/list/schedules/new"
									onClick={() => trackEvent("schedule_report_create_initiate", {})}
								>
									<Button>
										<CreateIcon /> <span>Create New</span>
									</Button>
								</Link>
							)}
						</div>
					</div>
					<div className="section-filters">
						<div className="left">
							<SelectFilter
								options={[
									{ valueForDisplay: "All", value: "all" },
									...(data?.filters?.find((filter) => filter.field === "status")?.values ?? [])
								]}
								currValue={currentFilters?.["status"] || null}
								field="status"
								setFilter={handleFilters}
								isClearable={false}
								customDropdownLabel={
									currentFilters?.["status"] ? (
										<div className="custom-value">
											Status <span>{currentFilters?.["status"]?.["valueForDisplay"]}</span>
										</div>
									) : null
								}
								labelKey="valueForDisplay"
								valueKey="value"
								placeholder="Select status"
								classes="status"
							/>
							{access.isAdmin && (
								<SelectFilter
									options={
										data?.filters?.find((filter) => filter.field === "report_user")?.values || []
									}
									currValue={currentFilters?.["report_user"] || null}
									field="report_user"
									setFilter={handleFilters}
									isClearable={false}
									customDropdownLabel={
										currentFilters?.["report_user"] ? (
											<div className="custom-value">
												Scheduled by
												<span>{currentFilters?.["report_user"]?.["valueForDisplay"]}</span>
											</div>
										) : null
									}
									labelKey="valueForDisplay"
									valueKey="value"
									placeholder="Select scheduled by"
									classes="scheduled-by"
								/>
							)}
						</div>
					</div>
					<CustomTable
						bordered
						columns={columns}
						loading={loading}
						data={data?.objects || []}
						handleEmailsPopover={handleEmailsPopover}
						appliedFilters={appliedFilters}
						searchKeyword={searchKeyword}
						contextMenu={contextMenu}
						renderMenuItems={renderMenuItems}
						openContextMenu={openContextMenu}
						closeContextMenu={closeContextMenu}
						classes="schedule-reports-table-container"
						customPlaceholder={
							<Placeholder
								placeholderText={searchKeyword ? "No results found" : "There are no scheduled reports"}
								placeholderImageUrl={
									searchKeyword
										? "/assets/empty_states/graphics-empty-search.svg"
										: "/assets/empty_states/graphics-empty-reports.svg"
								}
								placeholderSubtext={
									searchKeyword
										? "The report you are searching for is not available"
										: "When a new report is scheduled, it will be listed here."
								}
								size="medium"
								placeholderButtonContent={!searchKeyword ? "Schedule your first report" : ""}
								placeholderButtonClickAction={() => history.push("/reports/list/schedules/new")}
							/>
						}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={data.count || 0}
						goToPage={handlePagination}
						setPageSize={handlePageSize}
						showPageSize={true}
					/>
					<Modal
						isOpen={modalOpen}
						close={handleModalClose}
						title={modalContent?.header?.text || ""}
						showSubmitAction={modalContent.footer.showSubmitAction}
						submitTitle={modalContent.footer.submitTitle}
						submitAction={() => handleModalAction(modalContent.action)}
						submitBtnLoading={modalBtnLoading}
						submitButtonWidth="150"
						showDeleteAction={modalContent.footer.showDeleteAction}
						deleteTitle={modalContent.footer.deleteTitle}
						deleteAction={() => handleModalAction(modalContent.action)}
						deleteBtnLoading={modalBtnLoading}
						deleteButtonWidth="150"
						showCancelAction={true}
						cancelTitle="Cancel"
						showCloseIcon={false}
					>
						{modalContent.content.title && (
							<div className="modal-sub-title">{modalContent.content.title}</div>
						)}
						{modalContent.content.text}
					</Modal>
				</div>
			)}
		</>
	);
};
export default connect((store) => ({
	scheduleReportsList: store.scheduleReportsList,
	ongoingTasks: store.ongoingTasks,
	access: store.login.loginDetail.access
}))(Schedules);
