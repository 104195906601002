import React from "react";

const LocationIcon = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		style={{ ...styles }}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M10 1.875C6.20312 1.875 3.125 4.95312 3.125 8.75C3.125 10.4691 3.7575 12.0394 4.80031 13.2447L10 19.0625L15.1997 13.2447C16.2425 12.0394 16.875 10.4691 16.875 8.75C16.875 4.95312 13.7969 1.875 10 1.875ZM10 11.5625C8.44656 11.5625 7.1875 10.3034 7.1875 8.75C7.1875 7.19656 8.44656 5.9375 10 5.9375C11.5534 5.9375 12.8125 7.19656 12.8125 8.75C12.8125 10.3034 11.5534 11.5625 10 11.5625Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(LocationIcon);
