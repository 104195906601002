import React, { useState, useEffect, useRef, useCallback } from "react";

// components
import { FormContainer } from "./Meraki";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { Configurations } from "../components/CustomIntegrations/Configurations";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";

// third party
import { connect } from "react-redux";
import { debounce } from "lodash";

// utils
import { client } from "../client";
import { store } from "../store/configureStore";
import { scroll, removeProp, extractInitials } from "../atlas-utils";

// graphql
import { GET_LOCATIONS_LIST } from "../graphql/hub";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchCustomIntegrations, editCustomIntegrations } from "../actions/customIntegrations";
import { fetchBrands } from "../actions/actions";
import NotificationServices from "../services/NotificationService";

const CustomIntegrationsContainer = ({ customIntegrations, isMultibrandEnabled, brands }) => {
	const [isFormTouched, setFormTouched] = useState(false);
	const { loading, data, error } = customIntegrations;
	const [locationsList, setLocationsList] = useState([]);
	const [searchingLocation, setSearchingLocation] = useState(false);
	const [searchLocation, setSearchLocation] = useState({ key: "default", value: "" });
	const [selectedLocation, setSelectedLocation] = useState({ id: "default", title: "Default" });
	const [selectedBrand, setSelectedBrand] = useState({ id: "default", name: "Default" });
	const topRef = useRef();

	const fetchLocationsList = useCallback(
		async (brand = null, brandChange = false) => {
			if (brand === "default" && isMultibrandEnabled) {
				return;
			}
			setSearchingLocation(true);
			try {
				const variables = {
					limit: 50,
					offset: 0,
					filters: [
						{
							field: "is_active",
							value: true
						}
					],
					sort: {
						field: "name",
						order: "ASC"
					}
				};

				if (brand !== "null" && brand !== null) {
					variables.brand = String(brand);
				}
				if (searchLocation.value !== "") {
					variables.search = [searchLocation];
				}

				let resp = await client.query({
					query: GET_LOCATIONS_LIST,
					variables,
					fetchPolicy: "no-cache"
				});
				const locations = resp?.data?.stores?.objects || [];
				setLocationsList(isMultibrandEnabled ? locations : [{ id: "default", title: "Default" }, ...locations]);

				if (brandChange || (locations.length > 0 && isMultibrandEnabled && searchLocation.value !== "")) {
					setSelectedLocation(locations[0]);
					await fetchCustomIntegrations(locations[0], brand);
					setFormTouched(false);
				}
			} catch (error) {
				console.log(error);
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: error.message || "Something went wrong.",
						timeout: 2000,
						error: true,
						errObject: error
					}
				});
			}
			setSearchingLocation(false);
		},
		[searchLocation]
	);

	const handleLocation = async (f, location) => {
		if (location === null) {
			return;
		}
		setSelectedLocation(location);
		await fetchCustomIntegrations(location, selectedBrand?.id);
		setFormTouched(false);
	};

	const handleLocationSearch = debounce((searchQuery) => {
		if (searchQuery !== searchLocation.value) {
			setSearchingLocation(true);
			setSearchLocation({
				...searchLocation,
				value: searchQuery
			});
		}
	}, 300);

	const handleCancel = () => {
		fetchCustomIntegrations(selectedLocation, selectedBrand?.id);
		setFormTouched(false);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleForm = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_CUSTOM_INTEGRATIONS,
			payload: {
				field: field,
				value: value
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		let validData = true;
		// const groups = [data.keysV2, data.keys]; // Combine both arrays including keys and keysV2
		// groups.forEach((groupArray, index) => {
		// 	if (groupArray)
		// 		groupArray.forEach((group) => {
		// 			group.groupKeys.forEach((key) => {
		// 				if (key.required && (key.value == null || key.value == "")) {
		// 					validData = false;
		// 				}
		// 			});
		// 		});
		// });
		if (validData) {
			const resp = await editCustomIntegrations(sanitisedData, selectedLocation, selectedBrand?.id || null);
			if (resp) {
				setFormTouched(false);
				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });
			}
		} else {
			NotificationServices.pushNotification({
				message: "Fill the required fields to proceed",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 25 ? brand.name.slice(0, 25) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleBrand = (brand) => {
		if (brand?.id === "default") {
			setLocationsList([]);
			setSelectedLocation({ id: "default", title: "Default" });
			fetchCustomIntegrations();
		}
		setSelectedBrand(brand);
		fetchLocationsList(brand?.id, true);
	};

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("");
		}
		fetchCustomIntegrations(selectedLocation, selectedBrand?.id || null);
	}, []);

	useEffect(() => {
		fetchLocationsList(isMultibrandEnabled ? selectedBrand?.id || null : null);
	}, [searchLocation]);

	const brandOptions = [{ id: "default", name: "Default" }, ...brands.items];
	return (
		<div className="custom-integrations-section section-container-common" ref={topRef}>
			<div className="credits-section-header integrations-header">
				<div className="header-text">
					<div className="title">POS Integrations</div>
					<div className="subtitle">
						Manage <span className="highlight">third party POS integrations</span> for your business and
						your stores from here
					</div>
				</div>
				<div className="header-action-button">
					{isMultibrandEnabled && (
						<SelectFilterCustom
							options={brandOptions}
							isLoading={brands.isLoading}
							field="brands"
							currValue={selectedBrand}
							setFilter={(f, value) => handleBrand(value)}
							labelKey="name"
							valueKey="id"
							isSearchable={false}
							customLabel={true}
							customOptions={true}
							renderLabel={handleBrandsLabelOption}
							renderOptions={handleBrandsLabelOption}
							placeholder="Select brand"
							handleBrand={handleBrand}
						/>
					)}
					<SelectFilter
						options={locationsList}
						isLoading={loading || searchingLocation}
						field="selectedLocation"
						currValue={selectedLocation}
						setFilter={handleLocation}
						labelKey="title"
						valueKey="id"
						isClearable={false}
						isAsync={true}
						handleSearch={handleLocationSearch}
					/>
				</div>
			</div>
			<div className="custom-integrations-container">
				<FormContainer
					cancel={handleCancel}
					submit={handleSubmit}
					submitTitle="Save"
					hideActions={!isFormTouched}
				>
					{data &&
						data?.keysV2?.map((configGroup) => {
							return (
								<div className="card-container">
									<div className="card-header">
										{configGroup.groupName === "Order" && (
											<div className="header-container">
												<div className="app-icon">
													<img src="/assets/icons/shopping-cart.svg" alt="" />
												</div>
												<div className="app-header">
													<div className="header-text">{configGroup.groupName} Configs</div>
													<div className="header-subtext">
														Configure the settings needed to relay order information from
														delivery platforms to your POS system
													</div>
												</div>
											</div>
										)}
										{configGroup.groupName === "Menu" && (
											<div className="header-container">
												<div className="app-icon">
													<img src="/assets/icons/read.svg" alt="" />
												</div>
												<div className="app-header">
													<div className="header-text">{configGroup.groupName} Configs</div>
													<div className="header-subtext">
														Configure the settings needed to sync menu from your POS system
													</div>
												</div>
											</div>
										)}
									</div>
									<div className={"form-container " + (loading ? "disabled" : "")}>
										<div className="form-row">
											{configGroup?.groupKeys?.map((key, i) => (
												<Configurations
													index={i}
													config={key}
													configValue={key.value}
													handleForm={handleForm}
													validations={error.fields || {}}
												/>
											))}
											{configGroup.length === 0 && (
												<div className="no-items-placeholder">No configurations found!</div>
											)}
										</div>
									</div>
								</div>
							);
						})}

					{!loading && (!data || !data?.keysV2) && (
						<div className="no-items-placeholder">No integrations found!</div>
					)}
					{loading && (!data || !data?.keysV2) && (
						<div className="P(10px 0)">
							<div className="shimmer H(60px) Mb(10px) Mt(15px) Mstart(10px) Mend(10px)" />
							<div className="shimmer H(60px) Mb(25px) Mstart(10px) Mend(10px)" />
						</div>
					)}
				</FormContainer>
			</div>
		</div>
	);
};
const mapStateToProps = (store) => ({
	customIntegrations: store.customIntegrations,
	isMultibrandEnabled: store.login.loggedInbizDetail?.isMultibrandEnabled || false,
	brands: store.configItems.brands
});
export default connect(mapStateToProps)(CustomIntegrationsContainer);
