// action types
import { ActionTypes } from "../actions/_types";

export const REWIND_INITIAL_STATE = {
	isRewindOpen: false,
	loading: false,
	data: {
		totalOrders: 0,
		netRevenue: 0,
		topPlatform: {
			name: "",
			orders: 0,
			netRevenue: 0
		},
		topLocation: {
			name: "",
			orders: 0,
			netRevenue: 0
		},
		totalItems: 0,
		topItemUnitsSold: "",
		topItemNetRevenue: "",
		topAddOn: "",
		goldenHourTimePeriod: "",
		busiestDayOrders: "",
		bestMonthOrders: "",
		bestCityOrders: ""
	}
};
export const rewindState = (state = REWIND_INITIAL_STATE, action) => {
	switch (action.type) {
		case ActionTypes.REWIND_OPEN:
			return {
				...state,
				...action.payload
			};
		case ActionTypes.UPDATE_REWIND_STATE:
			return {
				...state,
				data: {
					...state.data,
					...action.payload
				}
			};
		case ActionTypes.RESET_REWIND_STATE:
			return REWIND_INITIAL_STATE;
		default:
			return state;
	}
};
