import React, { useState, useCallback, useEffect } from "react";

// components
import { DateFilterDropdown } from "./DateFilterDropdown";
import DropdownCustom from "./DropdownCustom";
import { Button } from "./Button";
import { SelectFilter } from "./SelectFilter";

// third party
import DatePicker from "react-datepicker";
import moment from "moment";
import history from "../../history";

// utils
import { trackEvent } from "../../atlas-utils";

// assets
import { ButtonIcon } from "./ButtonIcon";

// constants
import { DATE_COMPARE_FILTER_PAGES_MAP, TWELVE_HOUR_MAPPING } from "../../client-config";
const DATE_FORMAT = "DD MMM, YYYY";
const MAX_DATE_RANGE_ALLOWED = 365;
export const DATE_TYPES = [
	{ label: "Preset", value: "preset_duration" },
	{ label: "Custom", value: "range" }
];
export const TIME_TYPES = [
	{ label: "Preset", value: "preset_duration" },
	{ label: "Custom", value: "range" }
];
export const COMPARE_DATE_TYPES = [
	{ label: "Not specified", value: "not_specified" },
	{ label: "Default", value: "default" },
	{ label: "Custom", value: "range" }
];
export const CUSTOM_TYPES = [
	{ label: "Between", value: "between" },
	{ label: "After", value: "greater_than" },
	{ label: "Before", value: "less_than" }
];
export const PRESET_TYPES = [
	{ label: "All time", value: "", title: "All time" },
	{ label: "Today", value: "TODAY", title: "Today" },
	{ label: "Yesterday", value: "YESTERDAY", title: "Yesterday" },
	{ label: "This week", value: "THIS_WEEK", title: "This week", meta_info: "A week since" },
	{ label: "This month", value: "THIS_MONTH", title: "This month", meta_info: "A month since" },
	{ label: "This year", value: "THIS_YEAR", title: "This year", meta_info: "A year since" },
	{ label: "7 D", value: "LAST_7_DAYS", title: "Last 7 days", meta_info: "7 days since" },
	{ label: "15 D", value: "LAST_15_DAYS", title: "Last 15 days", meta_info: "15 days since" },
	{ label: "30 D", value: "LAST_30_DAYS", title: "Last 30 days", meta_info: "30 days since" },
	{ label: "90 D", value: "LAST_90_DAYS", title: "Last 90 days", meta_info: "90 days since" }
];
export const TIME_PRESET_TYPES = [
	{ label: "Indefinitely", value: "", title: "Indefinitely" },
	{ label: "30 minutes", value: "30_MINUTES", title: "30 minutes" },
	{ label: "1 hour", value: "1_HOUR", title: "1 hour" },
	{ label: "6 hours", value: "6_HOURS", title: "6 hours" },
	{ label: "12 hours", value: "12_HOURS", title: "12 hours" },
	{ label: "1 day", value: "1_DAY", title: "1 day" },
	{ label: "7 days", value: "7_DAYS", title: "7 days" }
];
const MERIDIEM = [
	{ label: "AM", value: true },
	{ label: "PM", value: false }
];

export const NewDateCompareFilter = ({
	title = "",
	comparisonTitle = "",
	requiredLabel = false,
	showCustomTooltip = false,
	tooltipInfo,
	tooltipPosition = "up-left",
	isRange = true,
	showDropdown = false,
	loading = false,
	currentDateFilter = {},
	appliedDateFilter = {},
	updateState,
	isClearable = false,
	hideCustomDate = false,
	hideComparison = false,
	includeAllTime = false,
	showSelectedDateRange = true,
	showCustomDateSelection = false,
	setDefaultRange = true,
	hidePresetTypes = [],
	defaultCustomRange = 45, // in days
	minDate,
	maxDate,
	showTime = false,
	hideFooter = false,
	customMessage = "",
	customLabel = "",
	monthsShown = 2,
	hideDatePresets = false,
	showTimePresets = false,
	align = "",
	position = "left",
	module = "",
	validationMessage = ""
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [isComparisonOpen, setIsComparisonOpen] = useState(false);
	const { current = {} } = currentDateFilter;
	const {
		rangeStartDate = undefined,
		rangeEndDate = undefined,
		rangeStartTime = undefined,
		rangeEndTime = undefined,
		customTypeSelected = undefined
	} = current;
	const [showCustomDate, setShowCustomDate] = useState(showCustomDateSelection);
	const [isCleared, setIsCleared] = useState(false);

	useEffect(() => {
		if (
			(isRange && currentDateFilter?.current?.rangeStartDate && currentDateFilter?.current?.rangeEndDate) ||
			customTypeSelected?.value !== CUSTOM_TYPES[0]?.value
		) {
			updateDateRange();
		}
	}, [rangeStartDate, rangeEndDate]);

	useEffect(() => {
		if (
			(isRange &&
				showTime &&
				currentDateFilter?.current?.rangeStartDate &&
				currentDateFilter?.current?.rangeEndDate) ||
			customTypeSelected?.value !== CUSTOM_TYPES[0]?.value
		) {
			updateDateRange();
		}
	}, [rangeStartTime, rangeEndTime]);

	// Based on current start/end date or preset selected this function
	// returns default compare start/end date and diff in number of days.
	const getDefaultCompareStartEndDateRange = useCallback((currentDateFilter) => {
		const { current } = currentDateFilter;
		let diff = 0;
		let defaultCompareStart = moment();
		let defaultCompareEnd = moment();
		if (current?.dateTypeSelected?.value === DATE_TYPES[0].value) {
			switch (current.dateFilter) {
				case "TODAY":
					diff = 0;
					defaultCompareStart = moment().subtract(1, "d");
					defaultCompareEnd = moment().subtract(1, "d");
					break;
				case "YESTERDAY":
					diff = 0;
					defaultCompareStart = moment().subtract(2, "d");
					defaultCompareEnd = moment().subtract(2, "d");
					break;
				case "THIS_WEEK":
					diff = moment().diff(moment().day("Monday"), "d");
					defaultCompareStart = moment().subtract(1, "w").day("Monday");
					defaultCompareEnd = defaultCompareStart.clone().add(diff, "d");
					break;
				case "THIS_MONTH":
					diff = moment().date();
					defaultCompareStart = moment().subtract(1, "M").date(1);
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_7_DAYS":
					diff = 7;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_15_DAYS":
					diff = 15;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_30_DAYS":
					diff = 30;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "LAST_90_DAYS":
					diff = 90;
					defaultCompareStart = moment().subtract(diff * 2 - 1, "d");
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				case "THIS_YEAR":
					diff = moment().dayOfYear();
					defaultCompareStart = moment().subtract(1, "y").dayOfYear(1);
					defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
					break;
				default:
					break;
			}
		} else {
			if (current?.rangeStartDate && current?.rangeEndDate) {
				diff = current?.rangeEndDate.diff(current?.rangeStartDate, "d") + 1;
				defaultCompareStart = moment(current?.rangeStartDate).subtract(diff, "d");
				defaultCompareEnd = defaultCompareStart.clone().add(diff - 1, "d");
			}
		}
		return [defaultCompareStart, defaultCompareEnd, diff];
	}, []);

	const changeCustomType = useCallback(
		(customTypeSelected) => {
			let dateObject = {};
			switch (customTypeSelected.value) {
				case CUSTOM_TYPES[0].value:
					dateObject = {
						rangeStartDate: moment().subtract(defaultCustomRange, "d"),
						rangeEndDate: moment()
					};
					break;
				case CUSTOM_TYPES[1].value:
					dateObject = {
						rangeStartDate: moment().subtract(defaultCustomRange, "d"),
						rangeEndDate: undefined
					};
					break;
				case CUSTOM_TYPES[2].value:
					dateObject = {
						rangeStartDate: undefined,
						rangeEndDate: moment()
					};
				default:
					break;
			}

			const { rangeStartDate, rangeEndDate } = dateObject;
			const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
			const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
			const dateFilter = showTime
				? `${start ? `${start} ${currentDateFilter?.current?.rangeStartTime}` : ""},${
						end ? `${end} ${currentDateFilter?.current?.rangeEndTime}` : ""
				  }`
				: `${start},${end}`;
			updateState({
				currentDateFilter: {
					...currentDateFilter,
					current: {
						...currentDateFilter?.current,
						...dateObject,
						customTypeSelected,
						dateFilter
					}
				}
			});
		},
		[updateState, currentDateFilter, defaultCustomRange]
	);

	const closeDateFilter = useCallback(() => {
		setTimeout(() => {
			updateState({
				currentDateFilter: {
					...appliedDateFilter
				}
			});

			// close calendar date view if applied date filter is not custom date type
			if (appliedDateFilter?.current?.dateTypeSelected?.value !== "range") {
				setShowCustomDate(false);
			}
		}, 100);
	}, [appliedDateFilter, updateState]);

	const updateDateRange = useCallback(
		(reset = false) => {
			if (isRange) {
				if (currentDateFilter?.current?.rangeStartDate && currentDateFilter?.current?.rangeEndDate && !reset) {
					const start = currentDateFilter?.current?.rangeStartDate.format("YYYY-MM-DD");
					const end = currentDateFilter?.current?.rangeEndDate.format("YYYY-MM-DD");
					const dateFilter = showTime
						? `${start} ${currentDateFilter?.current?.rangeStartTime},${end} ${currentDateFilter?.current?.rangeEndTime}`
						: `${start},${end}`;
					updateState({
						currentDateFilter: {
							...currentDateFilter,
							current: {
								...currentDateFilter?.current,
								dateTypeSelected: DATE_TYPES[1],
								dateFilter
							}
						}
					});
				} else if (
					currentDateFilter?.current?.customTypeSelected?.label &&
					(currentDateFilter?.current?.rangeStartDate || currentDateFilter?.current?.rangeEndDate) &&
					!reset
				) {
					const start = currentDateFilter?.current?.rangeStartDate
						? currentDateFilter?.current?.rangeStartDate.format("YYYY-MM-DD")
						: "";
					const end = currentDateFilter?.current?.rangeEndDate
						? currentDateFilter?.current?.rangeEndDate.format("YYYY-MM-DD")
						: "";
					const dateFilter = showTime
						? `${start ? `${start} ${currentDateFilter?.current?.rangeStartTime}` : ""},${
								end ? `${end} ${currentDateFilter?.current?.rangeEndTime}` : ""
						  }`
						: `${start},${end}`;
					updateState({
						currentDateFilter: {
							...currentDateFilter,
							current: {
								...currentDateFilter?.current,
								dateTypeSelected: DATE_TYPES[1],
								dateFilter
							}
						}
					});
				} else {
					const rangeStartDate = moment().subtract(defaultCustomRange, "d");
					const rangeEndDate = moment();
					const start = rangeStartDate.format("YYYY-MM-DD");
					const end = rangeEndDate.format("YYYY-MM-DD");
					const dateFilter = showTime ? `${start} 00:00:00,${end} 23:59:59` : `${start},${end}`;
					const current = {
						...currentDateFilter?.current,
						dateTypeSelected: DATE_TYPES[1],
						rangeStartDate,
						rangeEndDate,
						dateFilter
					};
					if (includeAllTime) {
						current["customTypeSelected"] = CUSTOM_TYPES[0];
					}
					if (showTime) {
						current["rangeStartTime"] = "00:00:00";
						current["rangeEndTime"] = "23:59:59";
					}
					updateState({
						currentDateFilter: {
							...currentDateFilter,
							current: {
								...current
							}
						}
					});
				}
			}
		},
		[updateState, currentDateFilter, defaultCustomRange, showTime, includeAllTime, isRange]
	);

	const updateCompareDateRange = useCallback(
		(reset = false) => {
			if (currentDateFilter?.compare?.rangeStartDate && currentDateFilter?.compare?.rangeEndDate && !reset) {
				const start = currentDateFilter?.compare?.rangeStartDate.format("YYYY-MM-DD");
				const end = currentDateFilter?.compare?.rangeEndDate.format("YYYY-MM-DD");
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						compare: {
							...currentDateFilter?.compare,
							dateFilter: `${start},${end}`
						}
					}
				});
			} else {
				const [rangeStartDate, rangeEndDate] = getDefaultCompareStartEndDateRange(currentDateFilter);
				const start = rangeStartDate.format("YYYY-MM-DD");
				const end = rangeEndDate.format("YYYY-MM-DD");
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						compare: {
							...currentDateFilter?.compare,
							dateTypeSelected: COMPARE_DATE_TYPES[2],
							rangeStartDate,
							rangeEndDate,
							dateFilter: `${start},${end}`
						}
					}
				});
			}
		},
		[updateState, currentDateFilter, getDefaultCompareStartEndDateRange]
	);

	const setDateRange = useCallback(
		(whichDate, date) => {
			if (isRange) {
				const { rangeStartDate, rangeEndDate } = currentDateFilter?.current;
				if (whichDate === "rangeStartDate") {
					if (rangeEndDate && moment(date).isAfter(rangeEndDate)) {
						if (setDefaultRange) {
							currentDateFilter.current.rangeEndDate = moment.min([
								moment(date).add(defaultCustomRange, "d"),
								moment()
							]);
						} else {
							currentDateFilter.current.rangeEndDate = undefined;
						}
					}
				} else if (whichDate === "rangeEndDate") {
					if (rangeStartDate && moment(date).isBefore(rangeStartDate)) {
						if (setDefaultRange) {
							currentDateFilter.current.rangeStartDate = minDate
								? moment.max([minDate, moment(date).subtract(defaultCustomRange, "d")])
								: moment(date).subtract(defaultCustomRange, "d");
						} else {
							currentDateFilter.current.rangeStartDate = undefined;
						}
					}
				}
				// if (whichDate === 'rangeStartDate' && rangeEndDate && rangeEndDate.diff(date, 'd') > MAX_DATE_RANGE_ALLOWED) {
				// 	currentDateFilter.current.rangeEndDate = date.clone().add(365, 'd');
				// }
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter?.current,
							[whichDate]: date
						}
					}
				});
			} else {
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						[whichDate]: date
					}
				});
			}
		},
		[updateState, currentDateFilter, isRange]
	);

	const setCompareDateRange = useCallback(
		(whichDate, date) => {
			const currentRangeDiff = getDefaultCompareStartEndDateRange(currentDateFilter)?.[2];
			const rangeEndDate = date
				.clone()
				.add(
					currentDateFilter?.current?.dateFilter !== "THIS_WEEK" && currentRangeDiff > 0
						? currentRangeDiff - 1
						: currentRangeDiff,
					"d"
				);
			const start = date ? date.format("YYYY-MM-DD") : "";
			const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
			updateState({
				currentDateFilter: {
					...currentDateFilter,
					compare: {
						...currentDateFilter?.compare,
						dateTypeSelected: COMPARE_DATE_TYPES[2],
						[whichDate]: date,
						rangeEndDate,
						dateFilter: `${start},${end}`
					}
				}
			});
		},
		[updateState, currentDateFilter, getDefaultCompareStartEndDateRange]
	);

	const setTimeRange = useCallback(
		(whichTime, field, value) => {
			const currTime = isRange
				? currentDateFilter?.current?.[whichTime]?.split(":")
				: currentDateFilter?.[whichTime]?.split(":");
			let updatedTime = [...currTime];
			switch (field) {
				case "hours":
					if (parseInt(currTime[0]) > 11) {
						updatedTime[0] = Object.keys(TWELVE_HOUR_MAPPING)
							.filter((key) => parseInt(key) > 11)
							.find((key) => TWELVE_HOUR_MAPPING[key] === value.value);
					} else {
						updatedTime[0] = Object.keys(TWELVE_HOUR_MAPPING)
							.filter((key) => parseInt(key) <= 11)
							.find((key) => TWELVE_HOUR_MAPPING[key] === value.value);
					}
					break;
				case "minutes":
					updatedTime[1] = value.value;
					break;
				case "seconds":
					updatedTime[2] = value.value;
					break;
				case "meridiem":
					if (value) {
						updatedTime[0] = Object.keys(TWELVE_HOUR_MAPPING)
							.filter((key) => parseInt(key) <= 11)
							.find((key) => TWELVE_HOUR_MAPPING[key] === TWELVE_HOUR_MAPPING[currTime[0]]);
					} else {
						updatedTime[0] = Object.keys(TWELVE_HOUR_MAPPING)
							.filter((key) => parseInt(key) > 11)
							.find((key) => TWELVE_HOUR_MAPPING[key] === TWELVE_HOUR_MAPPING[currTime[0]]);
					}
					break;
				default:
					break;
			}
			if (isRange) {
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter?.current,
							[whichTime]: updatedTime.join(":")
						}
					}
				});
			} else {
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						[whichTime]: updatedTime.join(":")
					}
				});
			}
		},
		[updateState, currentDateFilter, isRange]
	);

	const clearDateRange = useCallback(
		(e) => {
			e.stopPropagation();
			const updatedCurrentDateFilter = {
				...currentDateFilter,
				current: {
					dateFilter: "",
					dateTypeSelected: DATE_TYPES[0],
					presetTypeSelected: undefined,
					rangeStartDate: undefined,
					rangeEndDate: undefined,
					customTypeSelected: CUSTOM_TYPES[0]
				},
				compare: { ...(appliedDateFilter?.compare ?? {}) }
			};
			updateState({
				currentDateFilter: { ...updatedCurrentDateFilter },
				appliedDateFilter: { ...updatedCurrentDateFilter },
				offset: 0
			});
			setIsCleared(true);
		},
		[currentDateFilter, appliedDateFilter, updateState]
	);

	const applyDateRange = useCallback(
		(dateTypeSelected, presetTypeSelected = undefined) => {
			if (isRange) {
				const updatedCurrentDateFilter = {
					...currentDateFilter,
					current: {
						...currentDateFilter?.current,
						presetTypeSelected: presetTypeSelected
							? presetTypeSelected
							: currentDateFilter?.current?.presetTypeSelected,
						dateTypeSelected,
						dateFilter: presetTypeSelected
							? presetTypeSelected.value
							: currentDateFilter?.current?.dateFilter
					},
					compare: { ...appliedDateFilter?.compare }
				};

				// reset custom date range
				if (dateTypeSelected?.value === "preset_duration" && !setDefaultRange) {
					updatedCurrentDateFilter.current.rangeStartDate = undefined;
					updatedCurrentDateFilter.current.rangeEndDate = undefined;
					setTimeout(() => {
						setShowCustomDate(false);
					}, 150);
				}

				// check for compare date filter and update it
				const { compare } = appliedDateFilter;
				if (compare?.dateTypeSelected?.value !== COMPARE_DATE_TYPES[0].value && compare?.dateFilter) {
					const [rangeStartDate, rangeEndDate] = getDefaultCompareStartEndDateRange(updatedCurrentDateFilter);
					const start = rangeStartDate ? rangeStartDate.format("YYYY-MM-DD") : "";
					const end = rangeEndDate ? rangeEndDate.format("YYYY-MM-DD") : "";
					updateState({
						currentDateFilter: {
							...updatedCurrentDateFilter,
							compare: {
								...appliedDateFilter?.compare,
								rangeStartDate,
								rangeEndDate,
								dateFilter: `${start},${end}`
							}
						},
						appliedDateFilter: {
							...updatedCurrentDateFilter,
							compare: {
								...appliedDateFilter?.compare,
								rangeStartDate,
								rangeEndDate,
								dateFilter: `${start},${end}`
							}
						},
						offset: 0
					});

					// track this event
					if (
						DATE_COMPARE_FILTER_PAGES_MAP[
							history?.location?.pathname?.includes("/analytics")
								? "/analytics"
								: history?.location?.pathname.match(/^\/reports$/g) !== null
								? "/reports"
								: history?.location?.pathname
						]
					) {
						const eventName = "duration_selector";
						const eventMeta = {
							page: DATE_COMPARE_FILTER_PAGES_MAP[
								history?.location?.pathname?.includes("/analytics")
									? "/analytics"
									: history?.location?.pathname.match(/^\/reports$/g) !== null
									? "/reports"
									: history?.location?.pathname
							],
							type: dateTypeSelected.label,
							comparison_applied: true,
							comparison: rangeStartDate.format(DATE_FORMAT)
						};
						if (module) {
							eventMeta.module = module;
						}
						if (eventMeta.type === "Preset") {
							eventMeta.preset = presetTypeSelected.label;
						} else {
							eventMeta.custom = {
								duration: `${updatedCurrentDateFilter?.current?.rangeEndDate.diff(
									updatedCurrentDateFilter?.current?.rangeStartDate,
									"d"
								)} days`,
								start_date: updatedCurrentDateFilter?.current?.rangeStartDate.format(DATE_FORMAT),
								end_date: updatedCurrentDateFilter?.current?.rangeEndDate.format(DATE_FORMAT),
								executed_on: moment().format(DATE_FORMAT)
							};
						}
						trackEvent(eventName, eventMeta);
					}
				} else {
					updateState({
						currentDateFilter: { ...updatedCurrentDateFilter },
						appliedDateFilter: { ...updatedCurrentDateFilter },
						offset: 0
					});

					// track this event
					if (
						DATE_COMPARE_FILTER_PAGES_MAP[
							history?.location?.pathname?.includes("/analytics")
								? "/analytics"
								: history?.location?.pathname.match(/^\/reports$/g) !== null
								? "/reports"
								: history?.location?.pathname
						]
					) {
						const eventName = "duration_selector";
						const eventMeta = {
							page: DATE_COMPARE_FILTER_PAGES_MAP[
								history?.location?.pathname?.includes("/analytics")
									? "/analytics"
									: history?.location?.pathname.match(/^\/reports$/g) !== null
									? "/reports"
									: history?.location?.pathname
							],
							type: dateTypeSelected.label,
							comparison_applied: false
						};
						if (module) {
							eventMeta.module = module;
						}
						if (eventMeta.type === "Preset") {
							eventMeta.preset = presetTypeSelected.label;
						} else {
							eventMeta.custom = { executed_on: moment().format(DATE_FORMAT) };
							if (updatedCurrentDateFilter?.current?.rangeStartDate) {
								eventMeta.custom.start_date =
									updatedCurrentDateFilter?.current?.rangeStartDate.format(DATE_FORMAT);
							}
							if (updatedCurrentDateFilter?.current?.rangeEndDate) {
								eventMeta.custom.end_date =
									updatedCurrentDateFilter?.current?.rangeEndDate.format(DATE_FORMAT);
							}
							if (
								updatedCurrentDateFilter?.current?.rangeStartDate &&
								updatedCurrentDateFilter?.current?.rangeEndDate
							) {
								eventMeta.custom.duration = `${updatedCurrentDateFilter?.current?.rangeEndDate.diff(
									updatedCurrentDateFilter?.current?.rangeStartDate,
									"d"
								)} days`;
							}
							if (updatedCurrentDateFilter?.current?.customTypeSelected?.label) {
								eventMeta.custom.type = updatedCurrentDateFilter?.current?.customTypeSelected?.label;
							}
						}
						trackEvent(eventName, eventMeta);
					}
				}
			} else {
				const updatedCurrentDateFilter = {
					...currentDateFilter,
					presetTypeSelected: presetTypeSelected ? presetTypeSelected : currentDateFilter?.presetTypeSelected
				};
				if (showTime) {
					updatedCurrentDateFilter.timeTypeSelected = dateTypeSelected;
				} else {
					updatedCurrentDateFilter.dateTypeSelected = dateTypeSelected;
				}
				updateState({
					currentDateFilter: { ...updatedCurrentDateFilter },
					appliedDateFilter: { ...updatedCurrentDateFilter }
				});
			}
			if (showDropdown) {
				setIsOpen(false);
			}
		},
		[
			currentDateFilter,
			appliedDateFilter,
			updateState,
			getDefaultCompareStartEndDateRange,
			showDropdown,
			isRange,
			showTime,
			module
		]
	);

	const applyCompareDateRange = useCallback(() => {
		updateState({
			appliedDateFilter: {
				...currentDateFilter,
				compare: {
					...currentDateFilter?.compare
				}
			},
			offset: 0
		});

		// track this event
		if (
			DATE_COMPARE_FILTER_PAGES_MAP[
				history?.location?.pathname?.includes("/analytics")
					? "/analytics"
					: history?.location?.pathname.match(/^\/reports$/g) !== null
					? "/reports"
					: history?.location?.pathname
			]
		) {
			const eventName = "duration_selector";
			const eventMeta = {
				page: DATE_COMPARE_FILTER_PAGES_MAP[
					history?.location?.pathname?.includes("/analytics")
						? "/analytics"
						: history?.location?.pathname.match(/^\/reports$/g) !== null
						? "/reports"
						: history?.location?.pathname
				],
				type: currentDateFilter.current.dateTypeSelected.label,
				comparison_applied: true,
				comparison: currentDateFilter.compare.rangeStartDate.format(DATE_FORMAT)
			};
			if (module) {
				eventMeta.module = module;
			}
			if (eventMeta.type === "Preset") {
				eventMeta.preset = currentDateFilter.current.presetTypeSelected.label;
			} else {
				eventMeta.custom = {
					duration: `${currentDateFilter?.current?.rangeEndDate.diff(
						currentDateFilter?.current?.rangeStartDate,
						"d"
					)} days`,
					start_date: currentDateFilter?.current?.rangeStartDate.format(DATE_FORMAT),
					end_date: currentDateFilter?.current?.rangeEndDate.format(DATE_FORMAT),
					executed_on: moment().format(DATE_FORMAT)
				};
			}
			trackEvent(eventName, eventMeta);
		}
	}, [currentDateFilter, updateState, module]);

	const resetDateFilter = useCallback(
		(compareFilter = false) => {
			if (compareFilter) {
				updateCompareDateRange(true);
			} else {
				updateDateRange(true);
			}
		},
		[updateDateRange, updateCompareDateRange]
	);

	const clearCompareDateRange = useCallback(
		(e) => {
			e.stopPropagation();
			updateState({
				currentDateFilter: {
					...appliedDateFilter,
					compare: {
						...currentDateFilter?.compare,
						dateTypeSelected: COMPARE_DATE_TYPES[0],
						dateFilter: "",
						rangeStartDate: undefined,
						rangeEndDate: undefined
					}
				},
				appliedDateFilter: {
					...appliedDateFilter,
					compare: {
						...currentDateFilter?.compare,
						dateTypeSelected: COMPARE_DATE_TYPES[0],
						dateFilter: "",
						rangeStartDate: undefined,
						rangeEndDate: undefined
					}
				},
				offset: 0
			});
		},
		[currentDateFilter, appliedDateFilter, updateState]
	);

	const handleShowCustomDate = useCallback(
		(state) => {
			setShowCustomDate(state);
			if (state) {
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter.current,
							timeTypeSelected: TIME_TYPES[1],
							dateTypeSelected: DATE_TYPES[1]
						}
					}
				});
			} else {
				updateState({
					currentDateFilter: {
						...currentDateFilter,
						current: {
							...currentDateFilter.current,
							timeTypeSelected: TIME_TYPES[0],
							dateTypeSelected: DATE_TYPES[0]
						}
					}
				});
			}
		},
		[currentDateFilter]
	);

	const getDropdownLabel = useCallback(
		(type = "default") => {
			if (type === "compare") {
				const { dateTypeSelected, rangeStartDate, rangeEndDate } = appliedDateFilter.compare;
				if (dateTypeSelected?.value !== "range") {
					return (
						<div className="dropdown-label">
							<ButtonIcon icon="compare" opacity={"0.6"} />
							<div>Compare to past</div>
						</div>
					);
				} else {
					return (
						<div className="dropdown-label">
							<ButtonIcon icon="compare" opacity={"0.6"} />
							<div
								className="custom-date-time-label"
								title={`${rangeEndDate.diff(rangeStartDate, "d")} days`}
							>
								Compared with {moment(rangeStartDate).format(DATE_FORMAT)} <span>to</span>{" "}
								{moment(rangeEndDate).format(DATE_FORMAT)}
							</div>
						</div>
					);
				}
			} else {
				if (isRange) {
					const {
						dateTypeSelected,
						presetTypeSelected,
						rangeStartDate,
						rangeEndDate,
						rangeStartTime,
						rangeEndTime
					} = appliedDateFilter.current;
					if (customLabel) {
						return <div>{customLabel}</div>;
					} else if (dateTypeSelected.value === "preset_duration") {
						if (presetTypeSelected) {
							return (
								<div className="dropdown-label">
									<ButtonIcon icon="calendar" opacity={"0.6"} />
									<div>{presetTypeSelected?.title}</div>
								</div>
							);
						}
						return (
							<div className="dropdown-placeholder">
								<ButtonIcon icon="calendar" opacity={"0.2"} />
								<div>Select date {showTime ? "and time" : ""} range</div>
							</div>
						);
					} else if (dateTypeSelected.value === "range") {
						if (showTime) {
							return (
								<div className="dropdown-label">
									<ButtonIcon icon="calendar" opacity={"0.6"} />
									<div>
										{moment(rangeStartDate).format("DD MMM YYYY")}
										{`, ${moment(rangeStartTime, "hh:mm:ss").format("hh:mm:ss A")}`} <span>to</span>{" "}
										{moment(rangeEndDate).format("DD MMM YYYY")}
										{`, ${moment(rangeEndTime, "hh:mm:ss").format("hh:mm:ss A")}`}
									</div>
								</div>
							);
						} else if (rangeStartDate && rangeEndDate) {
							return (
								<div className="dropdown-label">
									<ButtonIcon icon="calendar" opacity={"0.6"} />
									<div>
										{moment(rangeStartDate).format(DATE_FORMAT)} <span>to</span>{" "}
										{moment(rangeEndDate).format(DATE_FORMAT)}
									</div>
								</div>
							);
						}
						return (
							<div className="dropdown-placeholder">Select date {showTime ? "and time" : ""} range</div>
						);
					}
					return <div className="dropdown-placeholder">Select</div>;
				} else {
					const { dateTypeSelected, presetTypeSelected, timeTypeSelected, date, time } = appliedDateFilter;
					if (customLabel) {
						return <div>{customLabel}</div>;
					} else if (showTime && timeTypeSelected?.value === "preset_duration") {
						return (
							<div className="dropdown-label">
								<ButtonIcon icon="calendar" opacity={"0.6"} />
								<div>{presetTypeSelected?.title}</div>
							</div>
						);
					} else if (dateTypeSelected?.value === "preset_duration") {
						return (
							<div className="dropdown-label">
								<ButtonIcon icon="calendar" opacity={"0.6"} />
								<div>{presetTypeSelected?.title}</div>
							</div>
						);
					} else if (showTime && timeTypeSelected?.value === "range") {
						if (date && time) {
							return (
								<div className="dropdown-label">
									<ButtonIcon icon="calendar" opacity={"0.6"} />
									<div>
										{moment(date).format("DD MMM YYYY")}
										{`, ${moment(time, "hh:mm:ss").format("hh:mm:ss A")}`}
									</div>
								</div>
							);
						}
						return <div className="dropdown-placeholder">Select date and time</div>;
					} else if (dateTypeSelected?.value === "range") {
						if (date) {
							return (
								<div className="dropdown-label">
									<ButtonIcon icon="calendar" opacity={"0.6"} />
									<div>{moment(date).format(DATE_FORMAT)}</div>
								</div>
							);
						}
						return <div className="dropdown-placeholder">Select date</div>;
					}
					return <div className="dropdown-placeholder">Select</div>;
				}
			}
		},
		[appliedDateFilter, showTime, customLabel]
	);

	if (showDropdown) {
		return (
			<div
				className={
					"dropdown-date-time-filter " +
					(showTime ? "has-time " : "") +
					(!hideCustomDate && showCustomDate ? "with-custom " : "") +
					(loading ? "loading " : "") +
					position
				}
			>
				<DropdownCustom
					title={title}
					requiredLabel={requiredLabel}
					showCustomTooltip={showCustomTooltip}
					tooltipInfo={tooltipInfo}
					tooltipPosition={tooltipPosition}
					selected={getDropdownLabel("default")}
					isOpen={isOpen}
					handleClick={() => setIsOpen(!isOpen)}
					handleOutsideClick={() => setIsOpen(false)}
					clickEvent="mousedown"
					isClearable={isClearable}
					currValue={currentDateFilter?.current?.dateFilter}
					clearCurrValue={clearDateRange}
				>
					{!hideDatePresets && (
						<div
							className={"date-preset-types" + (!hideCustomDate && showCustomDate ? " with-custom" : "")}
						>
							{PRESET_TYPES.filter(
								(type) =>
									!hidePresetTypes.includes(type.label) &&
									(!includeAllTime ? type.label !== "All time" : type)
							).map((type, i) => (
								<div
									key={i}
									className={
										"preset" +
										(appliedDateFilter?.current?.dateFilter === type.value ? " selected" : "")
									}
									onClick={() => {
										applyDateRange(DATE_TYPES[0], type);
										setShowCustomDate(false);
									}}
								>
									{type.title}
								</div>
							))}
							{!hideCustomDate && (
								<div
									className={
										"custom" +
										(appliedDateFilter?.current?.dateTypeSelected?.value === "range"
											? " selected"
											: "")
									}
									onClick={() => handleShowCustomDate(!showCustomDate)}
								>
									{appliedDateFilter?.current?.dateTypeSelected?.value === "range" ? (
										<ButtonIcon icon="calendar" color="#2f58f2" />
									) : (
										<ButtonIcon icon="calendar" opacity={"0.6"} />
									)}
									<div>Custom</div>
								</div>
							)}
						</div>
					)}
					{!hideCustomDate && showCustomDate && (
						<DateTimeCalendar
							isOpen={isOpen}
							setIsOpen={setIsOpen}
							range={isRange}
							currValue={isRange ? currentDateFilter?.current : currentDateFilter}
							applValue={isRange ? appliedDateFilter?.current : appliedDateFilter}
							setFilter={setDateRange}
							setTimeRange={setTimeRange}
							setDateRange={updateDateRange}
							setDefaultRange={setDefaultRange}
							applyDateRange={() => applyDateRange(DATE_TYPES[1])}
							closeDateFilter={closeDateFilter}
							resetDateFilter={resetDateFilter}
							changeCustomType={changeCustomType}
							showCustomType={includeAllTime}
							showSelectedDateRange={showSelectedDateRange}
							showArrow={false}
							monthsShown={monthsShown}
							showTime={showTime}
							hideFooter={hideFooter}
							customMessage={customMessage}
							align={align}
							isCleared={isCleared}
							setIsCleared={setIsCleared}
							classes={
								appliedDateFilter?.current?.dateTypeSelected?.value === "range"
									? "preset-type selected"
									: "preset-type"
							}
							minDate={minDate || moment().subtract(10, "years")}
							maxDate={
								// currentDateFilter?.current?.rangeStartDate
								// ? moment.min(currentDateFilter?.current?.rangeStartDate.clone().add(MAX_DATE_RANGE_ALLOWED, 'd'), moment())
								// : moment()
								maxDate || moment()
							}
						/>
					)}
					{showTimePresets && (
						<div
							className={"time-preset-types" + (!hideCustomDate && showCustomDate ? " with-custom" : "")}
						>
							{TIME_PRESET_TYPES.map((type, i) => (
								<div
									key={i}
									className={
										"preset" +
										(isRange
											? appliedDateFilter?.current?.timeFilter === type.value
												? " selected"
												: ""
											: appliedDateFilter?.presetTypeSelected?.value === type.value &&
											  appliedDateFilter?.timeTypeSelected?.value !== "range"
											? " selected"
											: "")
									}
									onClick={() => applyDateRange(TIME_TYPES[0], type)}
								>
									{type.title}
								</div>
							))}
							{!hideCustomDate && (
								<div
									className={
										"custom" +
										(isRange
											? appliedDateFilter?.current?.timeTypeSelected?.value === "range"
												? " selected"
												: ""
											: appliedDateFilter?.timeTypeSelected?.value === "range"
											? " selected"
											: "")
									}
									onClick={() => handleShowCustomDate(!showCustomDate)}
								>
									{(
										isRange
											? appliedDateFilter?.current?.timeTypeSelected?.value === "range"
											: appliedDateFilter?.timeTypeSelected?.value === "range"
									) ? (
										<ButtonIcon icon="calendar" color="#2f58f2" />
									) : (
										<ButtonIcon icon="calendar" opacity={"0.6"} />
									)}
									<div>Custom</div>
								</div>
							)}
						</div>
					)}
				</DropdownCustom>
				{validationMessage && <div className="validation-message">{validationMessage}</div>}
				{!hideComparison && !includeAllTime && (
					<DropdownCustom
						title={comparisonTitle}
						selected={getDropdownLabel("compare")}
						isOpen={isComparisonOpen}
						handleClick={() => setIsComparisonOpen(!isComparisonOpen)}
						handleOutsideClick={() => setIsComparisonOpen(false)}
						currValue={appliedDateFilter.compare.dateTypeSelected.value === "range"}
						isClearable={true}
						clearCurrValue={clearCompareDateRange}
						clickEvent="mousedown"
					>
						<DateTimeCalendar
							isOpen={isComparisonOpen}
							setIsOpen={setIsComparisonOpen}
							range={isRange}
							compare={true}
							currValue={currentDateFilter?.compare}
							applValue={appliedDateFilter?.compare}
							setFilter={setCompareDateRange}
							setDateRange={updateCompareDateRange}
							applyDateRange={applyCompareDateRange}
							closeDateFilter={closeDateFilter}
							resetDateFilter={resetDateFilter}
							showSelectedDateRange={showSelectedDateRange}
							showArrow={false}
							monthsShown={monthsShown}
							hideFooter={hideFooter}
							customMessage={customMessage}
							align={align}
							classes={
								appliedDateFilter?.compare?.dateTypeSelected?.value === "range"
									? "compare-preset-type selected"
									: "compare-preset-type"
							}
							minDate={minDate || moment().subtract(10, "years")}
							maxDate={moment().subtract(getDefaultCompareStartEndDateRange(currentDateFilter)?.[2], "d")}
						/>
					</DropdownCustom>
				)}
			</div>
		);
	}

	return (
		<div className={"new-date-compare-filter" + (loading ? " loading" : "")}>
			{PRESET_TYPES.filter(
				(type) => !hidePresetTypes.includes(type.label) && (!includeAllTime ? type.label !== "All time" : type)
			).map((type, i) => (
				<div
					key={i}
					title={type.title}
					className={
						"preset-type" + (appliedDateFilter?.current?.dateFilter === type.value ? " selected" : "")
					}
					onClick={() => applyDateRange(DATE_TYPES[0], type)}
				>
					{type.label}
				</div>
			))}
			{!hideCustomDate && (
				<DateFilterDropdown
					range={true}
					field="timeDaily"
					currValue={currentDateFilter?.current}
					applValue={appliedDateFilter?.current}
					customLabel={
						appliedDateFilter?.current?.dateTypeSelected?.value !== "range" ? (
							<React.Fragment>
								<ButtonIcon icon="calendar" opacity={"0.4"} />
								<div>Custom</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<ButtonIcon icon="calendar" color="#2f58f2" />
								{includeAllTime &&
								appliedDateFilter?.current?.customTypeSelected?.value === CUSTOM_TYPES[1].value ? (
									<div className="custom-date-time-label">
										After {moment(appliedDateFilter?.current?.rangeStartDate).format(DATE_FORMAT)}
										{showTime
											? ` - ${moment(
													appliedDateFilter?.current?.rangeStartTime,
													"hh:mm:ss"
											  ).format("hh:mm:ss A")}`
											: ""}
									</div>
								) : includeAllTime &&
								  appliedDateFilter?.current?.customTypeSelected?.value === CUSTOM_TYPES[2].value ? (
									<div className="custom-date-time-label">
										Before {moment(appliedDateFilter?.current?.rangeEndDate).format(DATE_FORMAT)}
										{showTime
											? ` - ${moment(appliedDateFilter?.current?.rangeEndTime, "hh:mm:ss").format(
													"hh:mm:ss A"
											  )}`
											: ""}
									</div>
								) : (
									<div
										className="custom-date-time-label"
										title={`${appliedDateFilter?.current?.rangeEndDate.diff(
											appliedDateFilter?.current?.rangeStartDate,
											"d"
										)} days`}
									>
										{moment(appliedDateFilter?.current?.rangeStartDate).format(DATE_FORMAT)}
										{showTime
											? ` - ${moment(
													appliedDateFilter?.current?.rangeStartTime,
													"hh:mm:ss"
											  ).format("hh:mm:ss A")}`
											: ""}{" "}
										<span>to</span>{" "}
										{moment(appliedDateFilter?.current?.rangeEndDate).format(DATE_FORMAT)}
										{showTime
											? ` - ${moment(appliedDateFilter?.current?.rangeEndTime, "hh:mm:ss").format(
													"hh:mm:ss A"
											  )}`
											: ""}
									</div>
								)}
							</React.Fragment>
						)
					}
					setFilter={setDateRange}
					setTimeRange={setTimeRange}
					setDateRange={updateDateRange}
					applyDateRange={() => applyDateRange(DATE_TYPES[1])}
					closeDateFilter={closeDateFilter}
					resetDateFilter={resetDateFilter}
					changeCustomType={changeCustomType}
					showCustomType={includeAllTime}
					showSelectedDateRange={showSelectedDateRange}
					showArrow={false}
					showTime={showTime}
					align={align}
					classes={
						appliedDateFilter?.current?.dateTypeSelected?.value === "range"
							? "preset-type selected"
							: "preset-type"
					}
					minDate={minDate || moment().subtract(10, "years")}
					maxDate={
						currentDateFilter?.current?.rangeStartDate
							? moment.min(
									currentDateFilter?.current?.rangeStartDate.clone().add(MAX_DATE_RANGE_ALLOWED, "d"),
									moment()
							  )
							: moment()
					}
				/>
			)}
			{!hideComparison && !includeAllTime && (
				<DateFilterDropdown
					range={true}
					field="timeDaily"
					currValue={currentDateFilter?.compare}
					applValue={appliedDateFilter?.compare}
					presetType={appliedDateFilter?.current?.presetTypeSelected}
					customLabel={
						appliedDateFilter?.compare?.dateTypeSelected?.value !== "range" ? (
							<React.Fragment>
								<ButtonIcon icon="compare" opacity={"0.4"} />
								<div>Compare to past</div>
							</React.Fragment>
						) : (
							<React.Fragment>
								<ButtonIcon icon="compare" color="#2f58f2" />
								<div
									className="custom-date-time-label"
									title={`${appliedDateFilter?.compare?.rangeEndDate.diff(
										appliedDateFilter?.compare?.rangeStartDate,
										"d"
									)} days`}
								>
									Compared with{" "}
									{moment(appliedDateFilter?.compare?.rangeStartDate).format(DATE_FORMAT)}{" "}
									<span>to</span>{" "}
									{moment(appliedDateFilter?.compare?.rangeEndDate).format(DATE_FORMAT)}
								</div>
								<ButtonIcon
									title="Remove compare filter"
									icon="cancel"
									color="#2f58f2"
									clickHandler={clearCompareDateRange}
								/>
							</React.Fragment>
						)
					}
					setFilter={setCompareDateRange}
					setDateRange={updateCompareDateRange}
					applyDateRange={applyCompareDateRange}
					closeDateFilter={closeDateFilter}
					resetDateFilter={resetDateFilter}
					showSelectedDateRange={showSelectedDateRange}
					showArrow={false}
					showTime={showTime}
					align={align}
					compare={true}
					classes={
						appliedDateFilter?.compare?.dateTypeSelected?.value === "range"
							? "compare-preset-type selected"
							: "compare-preset-type"
					}
					minDate={moment().subtract(10, "years")}
					maxDate={moment().subtract(getDefaultCompareStartEndDateRange(currentDateFilter)?.[2], "d")}
				/>
			)}
		</div>
	);
};

const DateTimeCalendar = ({
	isOpen = false,
	setIsOpen,
	title,
	field = "date",
	setFilter,
	setTimeRange,
	currValue,
	applValue,
	presetType = {},
	dateFormat = "DD MMM, YYYY",
	dateFormatCalender = "MMMM YYYY",
	minDate,
	maxDate,
	placeholder = "Select Date",
	customLabel = "",
	range = false,
	compare = false,
	monthsShown = 2,
	setDateRange,
	setDefaultRange = true,
	showSelectedDateRange = true,
	closeDateFilter,
	resetDateFilter,
	applyDateRange,
	changeCustomType,
	showCustomType = false,
	showArrow = true,
	showTime = false,
	hideFooter = false,
	customMessage = "",
	isCleared = false,
	setIsCleared,
	align = "", // left or right
	classes = ""
}) => {
	const [currFocussedDateTime, setCurrFocussedDateTime] = useState(range ? "rangeStartDate" : undefined);
	const [hoursList, setHoursList] = useState([...Array(12).keys()]);
	const [minutesSecondsList, setMinutesSecondsList] = useState([...Array(60).keys()]);

	const setTimingsList = () => {
		setHoursList(
			hoursList.map((hr) => ({
				label: hr + 1 < 10 ? `0${hr + 1}` : `${hr + 1}`,
				value: hr + 1 < 10 ? `0${hr + 1}` : `${hr + 1}`,
				index: hr
			}))
		);
		setMinutesSecondsList(
			minutesSecondsList.map((min) => ({
				label: min < 10 ? `0${min}` : `${min}`,
				value: min < 10 ? `0${min}` : `${min}`,
				index: min
			}))
		);
	};

	useEffect(() => {
		setTimingsList();
	}, []);

	useEffect(() => {
		if (isCleared) {
			setCurrFocussedDateTime("rangeStartDate");
			setIsCleared(false);
		}
	}, [isCleared]);

	useEffect(() => {
		if (range) {
			if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[0].value) {
				setCurrFocussedDateTime("rangeStartDate");
			} else if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value) {
				setCurrFocussedDateTime("rangeStartDate");
			} else if (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value) {
				setCurrFocussedDateTime("rangeEndDate");
			}
		}
	}, [currValue?.customTypeSelected]);

	useEffect(() => {
		if (isOpen && setDefaultRange) {
			setDateRange();
		} else if (!isOpen) {
			closeDateFilter();
			if (range) {
				setTimeout(() => {
					setCurrFocussedDateTime("rangeStartDate");
				}, 50);
			}
		}
	}, [isOpen]);

	const handleReset = useCallback(() => {
		resetDateFilter(compare);
	}, [compare, resetDateFilter]);

	const applySelectedDateRange = useCallback(() => {
		setIsOpen(false);
		applyDateRange();
	}, [applyDateRange]);

	const handleDate = useCallback(
		(field, date) => {
			setFilter(field, date);

			// // change focus to start time
			// if (showTime && range && (currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) && !compare && currFocussedDateTime === 'rangeStartDate') {
			// 	setTimeout(() => {
			// 		setCurrFocussedDateTime('rangeStartTime');
			// 	}, 50);
			// }
			// change focus to end date
			if (
				!showTime &&
				range &&
				currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value &&
				!compare &&
				currFocussedDateTime === "rangeStartDate"
			) {
				setTimeout(() => {
					setCurrFocussedDateTime("rangeEndDate");
				}, 50);
			}
			// // change focus to end time
			// if (showTime && range && (currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) && !compare && currFocussedDateTime === 'rangeEndDate') {
			// 	setTimeout(() => {
			// 		setCurrFocussedDateTime('rangeEndTime');
			// 	}, 50);
			// }
		},
		[setFilter, range, compare, showCustomType, currValue, currFocussedDateTime]
	);

	const handleTimeSlots = useCallback(
		(field, value) => {
			if (range) {
				const dateTimeMap = {
					rangeStartDate: "rangeStartTime",
					rangeEndDate: "rangeEndTime"
				};
				setTimeRange(dateTimeMap[currFocussedDateTime] || currFocussedDateTime, field, value);
			} else {
				setTimeRange("time", field, value);
			}
		},
		[currFocussedDateTime, setTimeRange, range]
	);

	const getCurrValue = useCallback(
		(type) => {
			if (currFocussedDateTime === "rangeStartDate") {
				return currValue?.rangeStartDate;
			} else if (currFocussedDateTime === "rangeEndDate") {
				return currValue?.rangeEndDate;
			} else if (currFocussedDateTime === "rangeStartTime" || currFocussedDateTime === "rangeEndTime") {
				let value = null;
				const time = currValue?.[currFocussedDateTime]?.split(":");
				switch (type) {
					case "hours":
						value = hoursList?.find((hr) => hr.value === TWELVE_HOUR_MAPPING[time[0]]);
						break;
					case "minutes":
						value = minutesSecondsList.find((min) => min.value === time[1]);
						break;
					case "seconds":
						value = minutesSecondsList.find((sec) => sec.value === time[2]);
						break;
					case "meridiem":
						value = parseInt(time[0]) < 12;
						break;
					default:
						break;
				}
				return value;
			}
		},
		[currValue, currFocussedDateTime]
	);

	const getCurrTime = useCallback(
		(type) => {
			if (showTime) {
				const dateTimeMap = {
					rangeStartDate: "rangeStartTime",
					rangeEndDate: "rangeEndTime"
				};
				let value = null;
				const time = range
					? currValue?.[dateTimeMap?.[currFocussedDateTime] || currFocussedDateTime]?.split(":")
					: currValue?.time?.split(":");
				switch (type) {
					case "hours":
						value = hoursList?.find((hr) => hr.value === TWELVE_HOUR_MAPPING[time[0]]);
						break;
					case "minutes":
						value = minutesSecondsList.find((min) => min.value === time[1]);
						break;
					case "seconds":
						value = minutesSecondsList.find((sec) => sec.value === time[2]);
						break;
					case "meridiem":
						value = parseInt(time[0]) < 12;
						break;
					default:
						break;
				}
				return value;
			}
		},
		[currValue, currFocussedDateTime, showTime, range, hoursList, minutesSecondsList]
	);

	if (currValue?.customTypeSelected && currValue?.customTypeSelected?.value !== CUSTOM_TYPES[0].value) {
		showSelectedDateRange = false;
	}

	const handleNextField = () => {
		switch (currFocussedDateTime) {
			case "rangeStartDate":
				// if (showTime) {
				// 	setTimeout(() => {
				// 		setCurrFocussedDateTime('rangeStartTime');
				// 	}, 50);
				// } else {
				// }
				setTimeout(() => {
					setCurrFocussedDateTime("rangeEndDate");
				}, 50);
				break;
			// case 'rangeStartTime':
			// 	setTimeout(() => {
			// 		setCurrFocussedDateTime('rangeEndDate');
			// 	}, 50);
			// 	break;
			// case 'rangeEndDate':
			// 	if (showTime) {
			// 		setTimeout(() => {
			// 			setCurrFocussedDateTime('rangeEndTime');
			// 		}, 50);
			// 	}
			// 	break;
			default:
				break;
		}
	};

	return (
		<div className="date-time-calendar">
			{
				// range &&
				// <div>
				// 	{
				// 		showCustomType &&
				// 		<div className="custom-type-selector">
				// 			<SelectFilter
				// 				options={CUSTOM_TYPES}
				// 				field="Custom type"
				// 				currValue={currValue?.customTypeSelected}
				// 				setFilter={(f, v) => changeCustomType(v)}
				// 				isClearable={false}
				// 				isSearchable={false}
				// 			/>
				// 		</div>
				// 	}
				// </div>
			}
			<DatePicker
				inline
				selected={range ? getCurrValue() : currValue.date}
				onChange={(date) => handleDate(range ? currFocussedDateTime : field, date)}
				dateFormat={dateFormat}
				dateFormatCalendar={dateFormatCalender}
				monthsShown={monthsShown}
				useWeekdaysShort={true}
				selectsStart={range && currFocussedDateTime === "rangeStartDate"}
				selectsEnd={range && currFocussedDateTime === "rangeEndDate"}
				startDate={range && showSelectedDateRange ? currValue?.rangeStartDate : undefined}
				endDate={range && showSelectedDateRange ? currValue?.rangeEndDate : undefined}
				minDate={
					// (range && currFocussedDateTime === 'rangeEndDate') || (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value)
					// ? currValue?.rangeStartDate || minDate || moment().subtract(12, 'months')
					// : minDate || moment().subtract(12, 'months')
					minDate || moment().subtract(12, "months")
				}
				maxDate={
					// range ? (!compare && currFocussedDateTime === 'rangeStartDate' ? currValue?.rangeEndDate : maxDate || moment()) : undefined
					maxDate || (range ? moment() : undefined)
				}
			/>
			{showTime && (
				<div className="time-selector">
					<SelectFilter
						// title="HH"
						options={hoursList}
						field="hours"
						currValue={getCurrTime("hours")}
						setFilter={handleTimeSlots}
						labelKey="label"
						valueKey="value"
						isClearable={false}
						placeholder="HH"
						noOptionsMessage="- -"
					/>
					<span className="colon">:</span>
					<SelectFilter
						// title="MM"
						options={minutesSecondsList}
						field="minutes"
						currValue={getCurrTime("minutes")}
						setFilter={handleTimeSlots}
						labelKey="label"
						valueKey="value"
						isClearable={false}
						placeholder="MM"
						noOptionsMessage="- -"
					/>
					<span className="colon">:</span>
					<SelectFilter
						// title="SS"
						options={minutesSecondsList}
						field="seconds"
						currValue={getCurrTime("seconds")}
						setFilter={handleTimeSlots}
						labelKey="label"
						valueKey="value"
						isClearable={false}
						placeholder="MM"
						noOptionsMessage="- -"
					/>
					<div className="meridiem-container">
						{/* <div className="meta-info">AM/PM</div> */}
						<SelectFilter
							// title="AM/PM"
							options={MERIDIEM}
							field="meridiem"
							currValue={MERIDIEM.find((val) => val.value === getCurrTime("meridiem"))}
							setFilter={(f, v) => handleTimeSlots(f, v.value)}
							labelKey="label"
							valueKey="value"
							isClearable={false}
							placeholder="AM"
							noOptionsMessage="- -"
						/>
						{/* <div className="meridiem" onClick={() => handleTimeSlots('meridiem', !getCurrTime('meridiem'))}>
							{getCurrTime('meridiem') ? 'AM' : 'PM'}
						</div> */}
					</div>
					{hideFooter && (
						<div className="actions-container">
							<Button
								clickHandler={applySelectedDateRange}
								classes={
									showCustomType ||
									(range ? currValue?.rangeStartDate && currValue?.rangeEndDate : currValue?.date)
										? ""
										: " disabled"
								}
							>
								Apply
							</Button>
						</div>
					)}
				</div>
			)}
			{!hideFooter && (
				<div className="date-time-footer">
					<div className="date-time-selector">
						{!showTime && (
							<div className="date-range-selector">
								{(!showCustomType ||
									currValue?.customTypeSelected?.value !== CUSTOM_TYPES[2].value) && (
									<div
										className={
											"start-date-container" +
											(currFocussedDateTime === "rangeStartDate" ? " selected" : "")
										}
										onClick={() => setCurrFocussedDateTime("rangeStartDate")}
									>
										{/* <div className="meta-info">
											{compare && presetType?.meta_info ? presetType?.meta_info : (showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value)) ? 'Date' : 'Start Date'}
										</div> */}
										<div className={"date" + (currValue?.rangeStartDate ? "" : " placeholder")}>
											{currValue?.rangeStartDate
												? moment(currValue?.rangeStartDate).format(dateFormat)
												: "Select date"}
										</div>
									</div>
								)}
								{!compare && <div className="separator">{"—"}</div>}
								{(!showCustomType || currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) &&
									!compare && (
										<div
											className={
												"end-date-container" +
												(currFocussedDateTime === "rangeEndDate" ? " selected" : "") +
												(compare ? " disabled" : "")
											}
											onClick={() => setCurrFocussedDateTime("rangeEndDate")}
										>
											{/* <div className="meta-info">
											{(showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value)) ? 'Date' : 'End Date'}
										</div> */}
											<div className={"date" + (currValue?.rangeEndDate ? "" : " placeholder")}>
												{currValue?.rangeEndDate
													? moment(currValue?.rangeEndDate).format(dateFormat)
													: "Select date"}
											</div>
										</div>
									)}
							</div>
						)}
						{showTime && (
							<div className="date-time-range-selector">
								{(!showCustomType ||
									currValue?.customTypeSelected?.value !== CUSTOM_TYPES[2].value) && (
									<div className="start date-time">
										<div
											className={
												"start-date-container" +
												(currFocussedDateTime === "rangeStartDate" ? " selected" : "")
											}
											onClick={() => setCurrFocussedDateTime("rangeStartDate")}
										>
											{/* <div className="meta-info">
												{compare && presetType?.meta_info ? presetType?.meta_info : (showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value)) ? 'Date' : 'Start Date'}
											</div> */}
											<div className={"date" + (currValue?.rangeStartDate ? "" : " placeholder")}>
												{currValue?.rangeStartDate && currValue?.rangeStartTime
													? `${moment(currValue?.rangeStartDate).format(
															"DD MMM YYYY"
													  )}, ${moment(currValue?.rangeStartTime, "hh:mm:ss").format(
															"hh:mm:ss A"
													  )}`
													: "Select date and time"}
											</div>
										</div>
										{/* <div
											className={"start-time-container" + (currFocussedDateTime === 'rangeStartTime' ? " selected" : "")}
											onClick={() => setCurrFocussedDateTime('rangeStartTime')}
										>
											<div className="meta-info">
												{compare && presetType?.meta_info ? presetType?.meta_info : (showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[1].value)) ? 'Time' : 'Start Time'}
											</div>
											<div className={"date" + (currValue?.rangeStartTime ? "" : " placeholder")}>
												{currValue?.rangeStartTime ? moment(currValue?.rangeStartTime, 'hh:mm:ss').format('hh:mm:ss A').replaceAll(':', ' : ') : 'Select time'}
											</div>
										</div> */}
									</div>
								)}
								<div className="separator">{"—"}</div>
								{(!showCustomType || currValue?.customTypeSelected?.value !== CUSTOM_TYPES[1].value) &&
									!compare && (
										<div className="end date-time">
											<div
												className={
													"end-date-container" +
													(currFocussedDateTime === "rangeEndDate" ? " selected" : "") +
													(compare ? " disabled" : "")
												}
												onClick={() => setCurrFocussedDateTime("rangeEndDate")}
											>
												{/* <div className="meta-info">
												{(showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value)) ? 'Date' : 'End Date'}
											</div> */}
												<div
													className={"date" + (currValue?.rangeEndDate ? "" : " placeholder")}
												>
													{currValue?.rangeEndDate && currValue?.rangeEndTime
														? `${moment(currValue?.rangeEndDate).format(
																"DD MMM YYYY"
														  )}, ${moment(currValue?.rangeEndTime, "hh:mm:ss").format(
																"hh:mm:ss A"
														  )}`
														: "Select date and time"}
												</div>
											</div>
											{/* <div
											className={"end-time-container" + (currFocussedDateTime === 'rangeEndTime' ? " selected" : "")}
											onClick={() => setCurrFocussedDateTime('rangeEndTime')}
										>
											<div className="meta-info">
												{(showCustomType && (currValue?.customTypeSelected?.value === CUSTOM_TYPES[2].value)) ? 'Time' : 'End Time'}
											</div>
											<div className={"date" + (currValue?.rangeEndTime ? "" : " placeholder")}>
												{currValue?.rangeEndTime ? moment(currValue?.rangeEndTime, 'hh:mm:ss').format('hh:mm:ss A').replaceAll(':', ' : ') : 'Select time'}
											</div>
										</div> */}
										</div>
									)}
							</div>
						)}
					</div>
					{range && (
						<div className="actions-container">
							{/* <img className="reset" src="/assets/icons/icon-blue-reset.svg" title="Reset to default" onClick={handleReset} /> */}
							<Button
								clickHandler={
									!compare && currFocussedDateTime === "rangeStartDate"
										? handleNextField
										: applySelectedDateRange
								}
								classes={
									showCustomType || (currValue?.rangeStartDate && currValue?.rangeEndDate)
										? ""
										: " disabled"
								}
							>
								{!compare && currFocussedDateTime === "rangeStartDate" ? "Next" : "Apply"}
							</Button>
							{/* <div className="action cancel" onClick={handleClose}>Cancel</div> */}
						</div>
					)}
				</div>
			)}
			{customMessage && <div className="custom-message">{customMessage}</div>}
		</div>
	);
};
