import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import LoginForm from "../components/Login/LoginForm";
import Announcements from "../components/Login/Announcements";
import BrandSelection from "../components/Login/BrandSelection";
import { Modal } from "../components/_commons/Modal";
// import { Accordion } from "../components/_commons/Accordion";
import { Button } from "../components/_commons/Button";
// import { ButtonIcon } from "../components/_commons/ButtonIcon";

// third party
// import { useLocation } from 'react-router-dom';
import PubSub from "pubsub-js";
import history from "../history";
import { connect } from "react-redux";
import { debounce } from "lodash";
import SatismeterService from "../services/SatismeterService";
// import { withLDConsumer } from "launchdarkly-react-client-sdk";

// client
import { client } from "../client";
import { resetClientOnboarding } from "../client-onboarding";
import { store } from "../store/configureStore";

// utils
import {
	msaagesArrayToHtml,
	removeProp,
	isLoggedin,
	validators,
	lS,
	generateUniqueUserId,
	snakiseObject,
	isChristmasSeason
} from "../atlas-utils";

// graphql
import { LOGIN_USER } from "../graphql/auth";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands, updateBizLogo, getLowPiperCreditsBalanceAlert } from "../actions/actions";

// constants
import { TOPIC_LIST_TOP_PERFORMING, MIN_PIPER_CREDITS_BALANCE, PIPER_CREDITS_BALANCE_LIMIT } from "../client-config";
import { IDENTIFY_AND_REGISTER, TRACK_EVENT } from "../atlas-utils/tracking";
const PHONE_NUMBER_VALIDATION_API = process.env.REACT_APP_PHONE_NUMBER_VALIDATION;
const ULE_LOGIN_URL = process.env.REACT_APP_ULE_LOGIN;
const ENVIRONMENT_FLAG = process.env.REACT_APP_SHOW_ENVIRONMENT_FLAG;

export const LOGIN_WORKFLOW = [
	{
		label: "Login",
		value: "login"
	},
	{
		label: "Select a brand",
		value: "brand"
	}
];

const Login = ({ brands, flags }) => {
	// const query = new URLSearchParams(useLocation().search);
	const [stepNum, setStepNum] = useState(LOGIN_WORKFLOW[0]);
	const [loading, setLoading] = useState(false);
	const [recaptchaToken, setRecaptchaToken] = useState(undefined);
	const [resetPass, setResetPass] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [endDate, setEndDate] = useState(new Date("Aug 15, 2023 23:59:59").getTime());
	const [timer, setTimer] = useState(undefined);
	// const [isAccordionExpanded, setIsAccordionExpanded] = useState(true);
	const [uleLogin, setUleLogin] = useState(ENVIRONMENT_FLAG === "prod" ? true : false);
	const [filteredBrands, setFilteredBrands] = useState([]);
	const interval = useRef();
	const [data, setData] = useState({
		email: "",
		password: "",
		email_validation_message: "",
		password_validation_message: "",
		recaptcha_validation_message: "",
		token: "",
		newPassword: "",
		newPasswordConfirm: ""
	});
	const [phoneNumberValidation, setPhoneNumberValidation] = useState({
		loading: false,
		showPhoneNumberValidation: false,
		endResultType: ""
	});

	const countdown = () => {
		interval.current = setInterval(() => {
			const now = new Date().getTime();
			const timeleft = endDate - now;
			if (timeleft < 0) {
				setTimer("expired");
				clearInterval(interval.current);
				return;
			}

			// set timer in days, hours, minutes and seconds
			const timer = {
				days: "0" + Math.floor(timeleft / (1000 * 60 * 60 * 24)),
				hours: "0" + Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
				minutes: "0" + Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60)),
				seconds: "0" + Math.floor((timeleft % (1000 * 60)) / 1000)
			};
			setTimer(timer);
		}, 1000);
	};

	useEffect(() => {
		if (!uleLogin) {
			history.push("/login");
			// countdown();
			// set ule login flag in local storage
			lS.set("uleLogin", false);
		}
		return () => {
			clearInterval(interval.current);
		};
	}, []);

	const handleModal = () => {
		clearInterval(interval.current);
		setIsModalOpen(false);
	};

	const handleRecaptcha = (token) => {
		setRecaptchaToken(token);
		setData({
			...data,
			recaptcha_validation_message: ""
		});
	};

	const handleRecaptchaExpiry = () => {
		setRecaptchaToken(undefined);
	};

	useEffect(() => {
		if (!resetPass) {
			const script = document.createElement("script");
			script.src = "https://www.google.com/recaptcha/api.js";
			document.body.appendChild(script);
		}
	}, [resetPass]);

	const removeValidationState = (e, str) => {
		setData({
			...data,
			[str]: ""
		});
	};

	const handleChange = (field, value) => {
		setData({
			...data,
			[field]: value
		});

		if (field === "email") {
			if (validators.phoneNumberValidator(value) && value.length > 4) {
				validatePhoneNumberDebounced(value);
			} else {
				setPhoneNumberValidation({
					loading: false,
					showPhoneNumberValidation: false,
					endResultType: ""
				});
			}
		}
	};

	const handlePasswordReset = () => {
		setRecaptchaToken(undefined);
		setData({
			...data,
			password: ""
		});
	};

	const validatePhoneNumber = useCallback(async (value) => {
		const data = {
			phone_number: value
		};
		try {
			setPhoneNumberValidation({
				loading: true,
				showPhoneNumberValidation: false,
				endResultType: ""
			});
			const resp = await fetch(PHONE_NUMBER_VALIDATION_API, {
				method: "post",
				body: JSON.stringify(data),
				headers: {
					"Content-Type": "application/json"
				}
			});
			const parsedResp = await resp.json();
			if (parsedResp?.is_valid_phone_number) {
				setPhoneNumberValidation({
					loading: false,
					showPhoneNumberValidation: true,
					endResultType: "success"
				});
			} else {
				setPhoneNumberValidation({
					loading: false,
					showPhoneNumberValidation: true,
					endResultType: "failure"
				});
			}
		} catch (e) {
			console.log(e);
			setPhoneNumberValidation({
				loading: false,
				showPhoneNumberValidation: true,
				endResultType: "failure"
			});
		}
	}, []);

	const validatePhoneNumberDebounced = useCallback(
		debounce((value) => validatePhoneNumber(value), 500),
		[]
	);

	const updateCache = async ({ data }) => {
		if (data && data.login.status.success) {
			let loginDetail = {
				success: true,
				id: data.login.userInfo.id,
				name: data.login.userInfo.fullName,
				firstName: data.login.userInfo.firstName,
				lastName: data.login.userInfo.lastName,
				fullName: data.login.userInfo.fullName,
				token: data.login.token,
				username: data.login.userInfo.username,
				cannySsoToken: data.login.cannySsoToken,
				email: data.login.userInfo.email,
				phone: data.login.userInfo.phone,
				isdCode: data.login.userInfo.isdCode,
				access: data.login.userInfo.access,
				associatedLocations: data.login.userInfo.associatedLocations || [],
				verificationDetails: data.login.userInfo.verificationDetails,
				internalUser: data.login.userInfo.email.includes("urbanpiper.com") ? true : false
			};

			let loggedInbizDetail = data.login.biz;
			let creditsCost = data.login.creditsCost;
			let creditsMapping = data.login.creditsMapping;
			let allCreditsCost = data.login.allCreditsCost;

			// banner message
			let showCustomBannerMessage = false;
			let isBannerMessageDismissed = { state: false, timeStamp: null };
			let banner = {};

			if (data.login.biz.scheduledActionsAndNotifications?.notifications?.length > 0) {
				const notification = data.login.biz.scheduledActionsAndNotifications?.notifications?.[0];
				showCustomBannerMessage = notification?.message ? notification?.applicable : false;
				banner = {
					topic: notification?.action,
					icon: "info",
					message: notification?.message,
					classes: notification?.type === "info" ? "default" : notification?.type,
					isDismissible: notification?.dismissible,
					trackEvent: false
				};
			} else if (
				(data.login.biz.credits < MIN_PIPER_CREDITS_BALANCE &&
					(loginDetail?.access?.isAdmin || loginDetail?.access?.isBilling)) ||
				data.login.biz.credits <= PIPER_CREDITS_BALANCE_LIMIT
			) {
				showCustomBannerMessage = true;
				banner = getLowPiperCreditsBalanceAlert(data.login.biz.credits);
			}

			// update store
			store.dispatch({
				type: ActionTypes.UPDATE_BANNER_STATE,
				payload: { ...banner }
			});
			store.dispatch({
				type: ActionTypes.LOGIN_SUCCESS,
				payload: {
					loginDetail,
					loggedInbizDetail,
					creditsCost,
					creditsMapping,
					allCreditsCost,
					uleLogin: false,
					showCustomBannerMessage,
					isBannerMessageDismissed
				}
			});

			// set showCustomBannerMessage, isBannerMessageDismissed and banner in local storage
			window.lS.set("showCustomBannerMessage", showCustomBannerMessage);
			window.lS.set("isBannerMessageDismissed", isBannerMessageDismissed);
			window.lS.set("banner", banner);

			const { modulesEnabled, supportedLanguages, isMultibrandEnabled, isMenuOverCatalogueEnabled } =
				loggedInbizDetail;
			let { productType } = store.getState().selectedModule;

			let modules_enabled = {};
			modulesEnabled.forEach((module) => (modules_enabled[module] = true));

			let languages_enabled = {};
			supportedLanguages.map((lang) => (languages_enabled[lang.valueForDisplay] = true));

			let eventMeta = {
				biz_id: loggedInbizDetail.id,
				biz_name: loggedInbizDetail.name,
				name: data.login.userInfo.fullName || data.login.userInfo.username || null,
				email: data.login.userInfo.email || null,
				phone: data.login.userInfo.phone || null,
				isd_code: data.login.userInfo.isdCode || null,
				biz_country: loggedInbizDetail.country,
				biz_currency: loggedInbizDetail.currency,
				modules_enabled_via_atlas: modules_enabled,
				languages_enabled_atlas: languages_enabled,
				atlas_roles: snakiseObject(removeProp(data.login.userInfo.access, "__typename")),
				internal_user: data.login.userInfo.email.includes("urbanpiper.com") ? true : false,
				is_multibrand: isMultibrandEnabled,
				is_mocEnabled: isMenuOverCatalogueEnabled,
				is_atlas_user: true,
				source: "Atlas"
			};

			// register and track this user in mixpanel
			PubSub.publish(IDENTIFY_AND_REGISTER, {
				uniqueId: generateUniqueUserId(eventMeta.email, eventMeta.isd_code, eventMeta.phone),
				eventMeta
			});
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName: "login",
				eventMeta: {
					user_name: data.login.userInfo.username,
					user_email: data.login.userInfo.email,
					user_phone: data.login.userInfo.phone,
					isd_code: data.login.userInfo.isdCode
				}
			});

			// update atlas onboarding state
			store.dispatch({
				type: ActionTypes.GET_ATLAS_ONBOARDING_STATE,
				payload: {
					isLoading: false,
					flowData: lS.get("onboardingFlow")?.[loggedInbizDetail.id]?.flowData || {},
					flowSteps: lS.get("onboardingFlow")?.[loggedInbizDetail.id]?.flowSteps || []
				}
			});

			// update default topic for topPerforming query
			if (productType === "LOYALTY") {
				store.dispatch({
					type: "DASHBOARD_STATE_CHANGE",
					payload: {
						topPerfTopic: TOPIC_LIST_TOP_PERFORMING[5]
					}
				});
			} else {
				store.dispatch({
					type: "DASHBOARD_STATE_CHANGE",
					payload: {
						topPerfTopic: TOPIC_LIST_TOP_PERFORMING[0]
					}
				});
			}
			window.lS.set("auth", data.login);
			// set ule login flag in local storage
			window.lS.set("uleLogin", false);
			if (isMultibrandEnabled) {
				const brands = await fetchBrands("", true);
				if (
					brands.length > 1 &&
					!loginDetail?.access?.isNonHqReport &&
					!loginDetail?.access?.isNonHqAnalytics
				) {
					setStepNum(LOGIN_WORKFLOW[1]);
				} else {
					// now route to main landing page
					window.lS.set("brand", brands?.[0] || null);
					store.dispatch({
						type: ActionTypes.UPDATE_SELECTED_BRAND,
						payload: brands?.[0] || null
					});
					history.push("/");
				}
			} else {
				// now route to main landing page
				window.lS.set("brand", null);
				store.dispatch({
					type: ActionTypes.UPDATE_SELECTED_BRAND,
					payload: null
				});
				history.push("/");
			}

			// handle snowfall animation during christmas season
			if (isChristmasSeason()) {
				store.dispatch({
					type: ActionTypes.UPDATE_SNOWFALL_STATE,
					payload: {
						active: true
					}
				});

				// stop animation after 5 seconds
				setTimeout(() => {
					store.dispatch({
						type: ActionTypes.UPDATE_SNOWFALL_STATE,
						payload: {
							active: false
						}
					});
				}, 5000);
			}
		} else {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(data.login.status.messages),
					timeout: 5000,
					error: true
				}
			});
			window.grecaptcha.reset();
			handlePasswordReset();
		}
		setLoading(false);
	};

	const handleLogin = async () => {
		if (phoneNumberValidation?.endResultType === "failure") {
			return;
		}
		const variables = {
			username: data.email,
			password: data.password,
			recaptchaToken: recaptchaToken
		};
		const _state = {
			...data
		};
		let _error = false;
		if (!data.email) {
			_error = true;
			_state.email_validation_message = "Enter valid email/phone";
		}
		if (!data.password) {
			_error = true;
			_state.password_validation_message = "Password can't be blank";
		}
		if (!recaptchaToken) {
			_error = true;
			_state.recaptcha_validation_message = "Captcha validation failed";
		}

		if (_error) {
			setData(_state);
			return;
		}

		store.dispatch({
			type: "LOGIN_FORM_LOAD_INIT",
			payload: null
		});
		setLoading(true);
		try {
			const resp = await client.mutate({
				mutation: LOGIN_USER,
				variables: variables
			});
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			await updateCache(resp);
			if (isLoggedin()) {
				updateBizLogo();
			}
			// create/reset clientOnboarding
			await resetClientOnboarding();
		} catch (error) {
			store.dispatch({
				type: "LOGIN_FORM_LOAD_DONE",
				payload: null
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	};

	const handleResetPass = (state = false) => {
		setResetPass(state);
		if (!state) {
			setData({
				...data,
				password: "",
				email_validation_message: "",
				password_validation_message: "",
				recaptcha_validation_message: "",
				token: "",
				newPassword: "",
				newPasswordConfirm: ""
			});
			setRecaptchaToken(undefined);
		}
	};

	window.handleRecaptcha = handleRecaptcha;
	window.handleRecaptchaExpiry = handleRecaptchaExpiry;

	const handleModalHeader = () => {
		return (
			<React.Fragment>
				<div className="icon">
					<img src="/assets/icons/icon-key.svg" alt="" />
				</div>
				<div className="header-text">Try the new & improved</div>
				<div className="header-text">login experience</div>
			</React.Fragment>
		);
	};

	if (uleLogin) {
		window.location = ULE_LOGIN_URL;
		return null;
	}

	return (
		<div className="new-login-container">
			{stepNum.value === LOGIN_WORKFLOW[0].value && (
				<div className="login-screen">
					<div className="atlas-logo">
						<img src="/assets/logo.svg" alt="" />
					</div>
					<div className="content">
						<Announcements />
						<LoginForm
							data={data}
							handleChange={handleChange}
							handleLogin={handleLogin}
							removeValidationState={removeValidationState}
							loading={loading}
							setLoading={(state) => setLoading(state)}
							resetPass={resetPass}
							handleResetPass={handleResetPass}
							phoneNumberValidation={phoneNumberValidation}
						/>
					</div>
					<Modal
						isOpen={timer && isModalOpen}
						close={handleModal}
						headerLeft={handleModalHeader()}
						hideActions={true}
						outsideClickClose={false}
					>
						<div className="form-content">
							<div className="description">
								Old Experience discontinues{" "}
								{timer && timer !== "expired" ? (
									<>
										from <b>16 Aug, 2023</b>
									</>
								) : (
									"soon"
								)}
							</div>
							{timer && timer !== "expired" && (
								<div className="timer">
									{Object.keys(timer).map((type, i) => (
										<React.Fragment key={i}>
											<div className="units-container">
												<div className="unit">{timer[type].slice(-2)}</div>
												<div className="type">{type}</div>
											</div>
											{i + 1 < Object.keys(timer).length && <div className="colon">:</div>}
										</React.Fragment>
									))}
								</div>
							)}
							{/* <div className="alert">
								<div className="time-left">
									<div className="icon"><ButtonIcon icon="triangle-alert" color="#A8870F" /></div>
									<div>{timer?.days?.slice(-2)} {Number(timer?.days?.slice(-2)) === 1 ? 'Day' : 'Days'} Left</div>
								</div>
								<div className="description">Current login method will no longer be supported after <span>Aug 16, 2023</span>.</div>
							</div> */}
							{/* <Accordion
								title="Benefits of New Login Experience"
								isExpanded={isAccordionExpanded}
								handleExpand={() => setIsAccordionExpanded(!isAccordionExpanded)}
							> */}
							<div className="description">With the new experience you get to:</div>
							<div className="points-container">
								<div className="points">
									<div className="point">1</div>
									<div className="text">
										Manage all your UrbanPiper accounts under one credential.
									</div>
								</div>
								<div className="points">
									<div className="point">2</div>
									<div className="text">Login with OTP, so no need to remember passwords.</div>
								</div>
							</div>
							{/* <div className="description">Current login method will no longer be supported after <span>Aug 16, 2023</span>.</div> */}
							{/* </Accordion> */}
							<a href={ULE_LOGIN_URL}>
								<Button>Try New Login Experience</Button>
							</a>
							<div className="action" onClick={handleModal}>
								Maybe Later
							</div>
						</div>
					</Modal>
				</div>
			)}
			{stepNum.value === LOGIN_WORKFLOW[1].value && <BrandSelection brands={brands} />}
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands
	// }))(withLDConsumer()(Login));
}))(Login);
