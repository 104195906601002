/*
 * Satismeter is initialized in html file but rest of the stuff is done here
 */

const SATISMETER_KEY = process.env.REACT_APP_SATISMETER_KEY;
const SATISMETER_EVENTS = {
	login: "login",
	locationToggle: "location_toggle",
	cataloguePublish: "catalogue_publish",
	requestToGoLive: "request_to_go_live",
	menuOverCatalogueCSAT: "menu_v2_csat",
	rewindCarousel: "rewind_carousel_closed"
};

class SatismeterService {
	static isUrbanPiperUser = true;

	static userInit(loginData) {
		if (loginData) {
			const traits = {
				biz_name: loginData?.loggedInbizDetail?.name,
				biz_id: loginData?.loggedInbizDetail?.id,
				email: loginData?.loginDetail?.email,
				phone: loginData?.loginDetail?.phone,
				isdCode: loginData?.loginDetail?.isdCode,
				company: loginData?.loggedInbizDetail?.name,
				name: loginData?.loginDetail?.fullName
			};

			this.isUrbanPiperUser = loginData?.loginDetail?.email?.includes("@urbanpiper.com");
			const userId = loginData?.loginDetail?.id;

			// don't initialize satismeter for urbanpiper users
			if (this.isUrbanPiperUser) return;

			if (window.satismeter) {
				window.satismeter({
					writeKey: SATISMETER_KEY,
					userId: userId,
					traits: traits
				});
			}
		}
	}

	static locationToggle() {
		if (this.isUrbanPiperUser) return;
		window.satismeter("track", {
			event: SATISMETER_EVENTS.locationToggle
		});
	}

	static menuPublish() {
		if (this.isUrbanPiperUser) return;
		window.satismeter("track", {
			event: SATISMETER_EVENTS.cataloguePublish
		});
	}

	static requestToGoLive() {
		if (this.isUrbanPiperUser) return;
		window.satismeter("track", {
			event: SATISMETER_EVENTS.requestToGoLive
		});
	}
	static menuOverCatalogueEvents() {
		if (this.isUrbanPiperUser) return;
		window.satismeter("track", {
			event: SATISMETER_EVENTS.menuOverCatalogueCSAT
		});
	}
	static rewindCarousel() {
		if (this.isUrbanPiperUser) return;
		window.satismeter("track", {
			event: SATISMETER_EVENTS.rewindCarousel
		});
	}
}

export default SatismeterService;
