import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import BasicDetails from "../components/TimingGroupEdit/BasicDetails";
import RegularSchedule, { scheduleTypeOptions } from "../components/TimingGroupEdit/RegularSchedule";

// utils
import { parseErrorMessages } from "../atlas-utils";
import { client } from "../client";
import history from "../history";
import { store } from "../store/configureStore";

// actions
import { ActionTypes } from "../actions/_types";
import {
	addEmptySlot,
	checkForEmptyDetails,
	fetchTimingGroupsList,
	getFilteredDays,
	getStartEndTimes,
	getUpdatedTimeSlots
} from "../actions/timingGroups";

// graphql
import { CREATE_TIMING_GROUP } from "../graphql/timingGroups";

// constants

export const SLOT_INITIAL_STATE = {
	startHours: null,
	startMinutes: null,
	endHours: null,
	endMinutes: null
};

export const TIME_SLOT_INITIAL_STATE = {
	scheduleType: null,
	selectDays: {},
	slots: [SLOT_INITIAL_STATE]
};

export const CUSTOM_SCHEDULE_TYPE_TIME_SLOT = {
	scheduleType: {
		label: "Custom",
		value: "Custom"
	},
	selectDays: {},
	slots: [SLOT_INITIAL_STATE]
};
const DailyScheduleType = scheduleTypeOptions[0];
const WeekdaysScheduleType = scheduleTypeOptions[1];
const WeekendScheduleType = scheduleTypeOptions[2];

const TimingGroupCreate = ({
	hasAccess = false,
	fromLocationCreateWizard = false,
	closeNestedContainer = () => {},
	presetTitle = "",
	locationDetails,
	biz
}) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [timeSlots, setTimeSlots] = useState([TIME_SLOT_INITIAL_STATE]);
	const [data, setData] = useState({
		title: presetTitle ? presetTitle + " timing group" : "",
		description: ""
	});
	const [step, setStep] = useState(1);
	const STEP_SUBMIT = 2;
	const [isCustomScheduleSelected, setIsCustomScheduleSelected] = useState(false);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchTimingGroupsList(true, "", biz?.isMultibrandEnabled ? "all" : "", 10, 0, {
			field: "title",
			order: "ASC"
		});
		setTimeout(() => {
			if (!fromLocationCreateWizard) {
				history.push("/timing-groups");
			} else {
				closeNestedContainer();
			}
		}, 100);
	};

	const handleForm = (field, value, slotIndex) => {
		if (field === "addNewTimeSlot") {
			setTimeSlots([...timeSlots, CUSTOM_SCHEDULE_TYPE_TIME_SLOT]);
		} else if (field === "addSlot") {
			const updatedTimeSlots = addEmptySlot(timeSlots, slotIndex);
			setTimeSlots(updatedTimeSlots);
		} else {
			setData({
				...data,
				[field]: value
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		}
	};

	const handleTimeSlots = (data, updatedTimeSlotIndex, updatedSlotIndex) => {
		if (updatedTimeSlotIndex === 0 && data?.scheduleType?.label === "Custom") {
			setIsCustomScheduleSelected(true);
		} else if (updatedTimeSlotIndex === 0 && data?.scheduleType?.label && data?.scheduleType?.label !== "Custom") {
			setIsCustomScheduleSelected(false);
			setTimeSlots([timeSlots[0]]);
		}
		const updatedTimeSlots = getUpdatedTimeSlots(data, timeSlots, updatedTimeSlotIndex, updatedSlotIndex);
		setTimeSlots(updatedTimeSlots);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	const handleSelectDays = (day, value, index) => {
		const newSelectDays = {
			...timeSlots[index].selectDays
		};
		newSelectDays[day] = value;
		const updatedTimeSlots = timeSlots.map((slot, i) => {
			if (i === index) {
				const daysCount = Object.values(newSelectDays).filter((val) => val === true).length;
				if (daysCount === 7) {
					Object.keys(newSelectDays).map((day) => {
						newSelectDays[day] = false;
					});
					return {
						...slot,
						selectDays: newSelectDays,
						scheduleType: {
							label: "Daily",
							value: "Daily"
						}
					};
				} else {
					return {
						...slot,
						selectDays: newSelectDays
					};
				}
			}
			return slot;
		});
		setTimeSlots(updatedTimeSlots);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleDeleteTimeSlot = (deleteSlotIndex) => {
		const updatedTimeSlots = timeSlots.filter((slot, slotIndex) => slotIndex !== deleteSlotIndex);
		setTimeSlots(updatedTimeSlots);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	const getSanitizedTimeslots = (timeslots = []) => {
		if (
			timeslots?.[0]?.scheduleType?.value === DailyScheduleType?.value ||
			timeslots?.[0]?.scheduleType?.value === WeekdaysScheduleType?.value ||
			timeslots?.[0]?.scheduleType?.value === WeekendScheduleType?.value
		) {
			return [timeslots?.[0]];
		} else {
			return timeslots;
		}
	};
	const handleDeleteSingleTiming = (deleteSlotIndex, deleteTimeSlotIndex) => {
		const updatedTimeSlots = timeSlots.map((timeslot, timeslotIndex) => {
			if (timeslotIndex === deleteTimeSlotIndex) {
				const updatedSlots = timeslot?.slots.filter((slot, slotIndex) => slotIndex !== deleteSlotIndex);
				return {
					...timeslot,
					slots: updatedSlots
				};
			}
			return timeslot;
		});
		setTimeSlots(updatedTimeSlots);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		try {
			const variables = {
				...data
			};
			const sanitisedTimeslots = getSanitizedTimeslots(timeSlots);
			if (checkForEmptyDetails(sanitisedTimeslots)) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please select both Start Time and End Time for a time slot",
						timeout: 3000,
						error: true
					}
				});
				setConfirmLoading(false);
				return;
			}
			const daySlots = [];
			let flag = false;

			for (const slot of sanitisedTimeslots) {
				if (flag) break;
				if (
					slot.scheduleType.value === "Custom" &&
					Object.values(slot.selectDays).filter((val) => val === true).length === 0
				) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please select day(s) for a time slot",
							timeout: 3000,
							error: true
						}
					});
					flag = true;
					setConfirmLoading(false);
					break;
				}
				const filteredDays = getFilteredDays(slot);
				filteredDays.forEach((day) => {
					const daySlot = daySlots.find((obj) => obj.day === day.value);
					if (daySlot) {
						slot.slots.forEach((ts) => daySlot.slots.push(getStartEndTimes(ts)));
					} else {
						const newDaySlot = { day: day.value || day, slots: [] };
						slot.slots.forEach((ts) => newDaySlot.slots.push(getStartEndTimes(ts)));
						daySlots.push(newDaySlot);
					}
				});
			}
			variables.daySlots = daySlots;
			if (!flag) {
				const resp = await client.mutate({
					mutation: CREATE_TIMING_GROUP,
					variables
				});
				if (resp.data.saveTimingsGroup.status.success) {
					setConfirmLoading(false);
					store.dispatch({
						type: "SHOW_GLOBAL_MESSAGE",
						payload: {
							message: "Schedule created successfully",
							timeout: 5000,
							error: false
						}
					});
					store.dispatch({
						type: ActionTypes.UPDATE_TIMING_GROUPS_LIST,
						payload: resp.data.saveTimingsGroup.object
					});
					if (fromLocationCreateWizard) {
						closeNestedContainer();
					} else if (locationDetails?.redirectionId) {
						const id = locationDetails?.redirectionId;
						store.dispatch({
							type: ActionTypes.CLEAR_REDIRECTION
						});
						history.push(`/locations/edit/${id}`);
					} else {
						history.push(`/timing-groups/edit/${resp.data.saveTimingsGroup.object.id}`);
					}
				} else {
					setConfirmLoading(false);
					setError(parseErrorMessages(resp.data.saveTimingsGroup.status.messages));
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message:
								resp?.data?.saveTimingsGroup?.status?.messages?.[0]?.message ||
								error.message ||
								"Something went wrong.",
							timeout: 5000,
							error: true
						}
					});
				}
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};
	const handleStepChange = () => {
		if (step === STEP_SUBMIT) {
			handleSubmit();
			return;
		}
		setStep((current) => current + 1);
	};
	const timingGroupCreationSubmitBtnTitle = {
		1: "Next: Add Timings",
		2: "Save"
	};

	const timingGroupCreationStepComponents = {
		1: () => (
			<BasicDetails data={data} handleForm={handleForm} validations={error.fields || {}} readOnly={!hasAccess} />
		),
		2: () => (
			<RegularSchedule
				handleForm={handleForm}
				timeSlots={timeSlots}
				readOnly={!hasAccess}
				handleSelectDays={handleSelectDays}
				handleTimeSlots={handleTimeSlots}
				handleDeleteTimeSlot={handleDeleteTimeSlot}
				handleDeleteSingleTiming={handleDeleteSingleTiming}
				isCustomScheduleSelected={isCustomScheduleSelected}
			/>
		)
	};
	const disabledStateOfBtn = {
		1: !(data?.title?.length > 0),
		2: timeSlots?.length > 0 && !timeSlots[0]?.scheduleType
	};
	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleStepChange}
			title="Create Regular Schedule"
			subTitle="Enter details for this schedule"
			loading={confirmLoading}
			submitTitle={timingGroupCreationSubmitBtnTitle[step]}
			submitButtonWidth={200}
			disabled={disabledStateOfBtn[step]}
			hideActions={!isFormTouched}
		>
			<div className="timing-group-create-form-container">{timingGroupCreationStepComponents?.[step]?.()}</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	locationDetails: store.locationDetailsReducer,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(TimingGroupCreate);
