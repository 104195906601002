import React, { useState, useEffect } from "react";

// components
import { Button } from "./Button";
import { ButtonIcon } from "./ButtonIcon";
import { Modal } from "./Modal";

// third party
import { SideNav } from "@urbanpiper-engineering/horizon";
import ProgressBar from "@urbanpiper-engineering/horizon/dist/atoms/progressbar";
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../history";
import { Transition, config, animated } from "react-spring/renderprops";

// store
import { store } from "../../store/configureStore";

// utils
import { openFreshchatWidget, isChristmasSeason } from "../../atlas-utils";

// actions
import { ActionTypes } from "../../actions/_types";

// helpers
import getSideNavItems from "../../helpers/sideNav";

// config
import { ONBOARDING_FLOWS, ATLAS_ONBOARDING_STEPS } from "../../client-config";

const SideNavContainer = ({
	classes = "",
	collapsed = false,
	biz = {},
	access = {},
	merakiWebSubscription,
	periscopeEarlyAccessState = {},
	atlasOnboardingState = {},
	freshChatWidgetState
}) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [openItems, setOpenItems] = useState(null);
	const isOnboardingFlowEnabled = atlasOnboardingState?.flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
	const { pathname } = useLocation();

	const handleOpenItems = () => {
		const items = rearrangedItems();
		let newOpenItems = [];

		items.some((item) => {
			const itemRegex = new RegExp(`^${item?.path?.split("?")?.[0] ?? ""}`, "g");
			if (item?.render && item?.path && pathname.match(itemRegex) !== null) {
				newOpenItems = [item.key];
				return true;
			}

			if (item?.render && item?.childrenProps?.length) {
				item.childrenProps.some((child) => {
					const childRegex = new RegExp(`^${child?.path?.split("?")?.[0] ?? ""}`, "g");

					if (child?.render && child?.path && pathname.match(childRegex) !== null) {
						newOpenItems = [item.key, child.key];
						return true;
					}
				});
			}
		});

		setOpenItems(newOpenItems);
	};

	useEffect(() => {
		handleOpenItems();
	}, [pathname, merakiWebSubscription]);

	const rearrangedItems = () => {
		const items = getSideNavItems({
			biz,
			access,
			merakiWebSubscription,
			periscopeEarlyAccessState,
			atlasOnboardingState,
			handleModalOpen
		});

		const filteredItems = items
			.map((item) => {
				if (item?.childrenProps?.length) {
					item.childrenProps = item.childrenProps.filter((child) => !!child?.render);
				}
				return item;
			})
			.filter((item) => !!item?.render);

		return filteredItems;
	};

	const handleNavItemClick = (openNavItems) => {
		const items = rearrangedItems();
		const parentNav = items?.find((item) => item?.key === openNavItems?.[0]);
		const getNavWithPath = parentNav?.childrenProps?.length
			? parentNav?.childrenProps?.find((nav) => nav.key === openNavItems?.[openNavItems?.length - 1])
			: parentNav;

		if (getNavWithPath?.path) {
			setOpenItems(openNavItems);
			history.push(getNavWithPath.path);
			return;
		}

		if (getNavWithPath?.onClick) {
			getNavWithPath.onClick();
			return;
		}
	};

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const handleSetupMeraki = () => {
		window.open(
			"https://www.urbanpiper.com/meraki?utm_source=Atlas&utm_medium=referral&utm_campaign=Atlas-in-app&utm_id=In-app"
		);
	};

	const renderSideNavHeader = () => {
		return <LogoBlock collapsed={collapsed} redirectTo={isOnboardingFlowEnabled ? "/onboarding" : "/"} />;
	};

	const renderSideNavFooter = () => {
		return <FreshchatWidgetBlock collapsed={collapsed} freshChatWidgetState={freshChatWidgetState} />;
	};

	const renderSideNavCustomBody = () => {
		if (isOnboardingFlowEnabled) {
			return (
				<OnboardingProgress
					collapsed={collapsed}
					atlasOnboardingState={atlasOnboardingState}
					isMultibrandEnabled={biz?.isMultibrandEnabled}
				/>
			);
		}
		return null;
	};

	return (
		<div className={"side-nav-container " + classes}>
			{openItems && (
				<SideNav
					className={!collapsed ? "side-nav-comp" : "side-nav-comp side-nav-comp--collapsed"}
					activeKey={openItems}
					collapsed={collapsed}
					sideNavItems={rearrangedItems()}
					onSideNavItemClick={handleNavItemClick}
					header={renderSideNavHeader()}
					footer={renderSideNavFooter()}
					customBody={renderSideNavCustomBody()}
				/>
			)}
			<SetupMeraki
				isModalOpen={isModalOpen}
				handleModalClose={handleModalClose}
				handleSubmit={handleSetupMeraki}
			/>
		</div>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	merakiWebSubscription: store.merakiWebSubscription.data,
	periscopeEarlyAccessState: store.periscopeEarlyAccessState,
	atlasOnboardingState: store.atlasOnboardingState,
	freshChatWidgetState: store.freshChatWidgetState
});
export default connect(mapStateToProps)(SideNavContainer);

const LogoBlock = ({ collapsed, redirectTo }) => {
	const handleSnowfall = (active = false) => {
		// handle snowfall animation during christmas season
		if (isChristmasSeason()) {
			store.dispatch({
				type: ActionTypes.UPDATE_SNOWFALL_STATE,
				payload: {
					active
				}
			});
		}
	};
	return (
		<div
			className={"atlas-logo-container" + (collapsed ? " collapsed" : "")}
			onMouseEnter={() => handleSnowfall(true)}
			onMouseLeave={() => handleSnowfall(false)}
		>
			<Link to={redirectTo}>
				<img className="atlas-logo-img img-first" src="/assets/icons/atlas-logo.svg" />
				{!collapsed && (
					<img className="atlas-logo-img" src="/assets/header-icons/atlas-text-logo.svg" alt="atlas-logo" />
				)}
				{isChristmasSeason() && (
					<img
						className={collapsed ? "santa-hat-collapsed" : "santa-hat"}
						src="/assets/icons/icon-santa-hat.svg"
					/>
				)}
			</Link>
		</div>
	);
};

const FreshchatWidgetBlock = ({ collapsed, freshChatWidgetState }) => {
	return (
		<div
			className={
				collapsed
					? "freshchat-support-btn-container freshchat-left-nav-closed-get-help-btn"
					: "freshchat-support-btn-container"
			}
		>
			<Transition
				native
				from={{ transform: "scale(0)", opacity: 0 }}
				enter={{ transform: "scale(1)", opacity: 1 }}
				leave={{ transform: "scale(0)", opacity: 0 }}
				items={!freshChatWidgetState.busy}
				config={{ ...config.stiff, ...{ duration: 500 } }}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div style={{ ...props }}>
							{collapsed ? (
								<div onClick={openFreshchatWidget}>
									<img src="/assets/left-nav/icon-get-help.svg" />
								</div>
							) : (
								<Button
									clickHandler={openFreshchatWidget}
									classes="freshchat-help-button"
									loading={!freshChatWidgetState.loaded}
								>
									<ButtonIcon icon="freshchat" />
									<span>GET HELP</span>
								</Button>
							)}
						</animated.div>
					))
				}
			</Transition>
		</div>
	);
};

const SetupMeraki = ({ isModalOpen = false, handleSubmit, handleModalClose }) => {
	return (
		<Modal
			isOpen={isModalOpen}
			close={handleModalClose}
			title={"Trying to setup Meraki?"}
			classes="sidebar-modal"
			subTitle={"Enabling Meraki lets you generate customised website for your business"}
			showSubmitAction
			submitAction={handleSubmit}
			submitTitle="Learn more"
			submitButtonWidth="200"
			showCancelAction
			cancelAction={handleModalClose}
			cancelTitle="Cancel"
		>
			<div>
				<img src="/assets/icons/meraki-ad.png" className="meraki-ad-image" />
			</div>
			<div>
				Get your own <strong>branded website & app</strong> for your restaurant.
				<br /> Save on commissions and own your customer relationships.
			</div>
		</Modal>
	);
};

const OnboardingProgress = ({ collapsed = false, atlasOnboardingState, isMultibrandEnabled = false }) => {
	const getOnboardingProgressPercentage = () => {
		const completedSteps = atlasOnboardingState?.flowSteps
			?.filter((step) => step?.status === "complete")
			.map((step) => step?.step?.name);
		let percentage = 0;
		const weightageMap = {
			[ATLAS_ONBOARDING_STEPS.COMPLETE_COMPANY_DETAILS]: isMultibrandEnabled ? 5 : 10,
			[ATLAS_ONBOARDING_STEPS.CREATE_BRANDS]: 5,
			[ATLAS_ONBOARDING_STEPS.CONFIGURE_LOCATIONS]: 15,
			[ATLAS_ONBOARDING_STEPS.CONFIGURE_MENUS]: 30,
			[ATLAS_ONBOARDING_STEPS.TAXES_CHARGES]: 10,
			[ATLAS_ONBOARDING_STEPS.GO_LIVE_ON_LOCATIONS]: 30,
			[ATLAS_ONBOARDING_STEPS.INVITE_USERS]: 5
		};

		Object.keys(weightageMap).forEach((step) => {
			if (completedSteps.includes(step)) {
				percentage += weightageMap[step];
			}
		});

		return percentage;
	};

	return (
		<div className={"side-nav-onboarding-progress" + (collapsed ? " collapsed" : "")}>
			<div className="side-nav-onboarding-progress-row" onClick={() => history.push("/onboarding")}>
				<img className="side-nav-onboarding-user-icon" src="/assets/icons/user-blue.svg" />
				{!collapsed && (
					<React.Fragment>
						<Text variant={TextVariant.BODY1} className="side-nav-onboarding-text">
							Setup Guide
						</Text>
						<Text variant={TextVariant.BODY1} className="side-nav-onboarding-percentage-text">
							{`${getOnboardingProgressPercentage()}%`}
						</Text>
					</React.Fragment>
				)}
			</div>
			<ProgressBar
				className="side-nav-onboarding-progress-bar"
				progress={getOnboardingProgressPercentage()}
				width={100}
			/>
		</div>
	);
};
