import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { ActionTypes } from "../../actions/_types";
import { store } from "../../store/configureStore";
import { lS } from "../../atlas-utils";
import Header from "../../components/Analytics/Compare/Header";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MetricCard from "../../components/Analytics/Common/MetricCard";
import MetricSection from "../../components/Analytics/Common/MetricSection";
import { metricContainer, metric } from "./CompareTableWidgets";
import EnterLocation from "../../components/Analytics/Compare/EnterLocation";
import {
	fetchCatalogueLocationPerformanceTableCompare,
	fetchItemPerformanceTableCompare,
	fetchLostOrdersBreakdownTableCompare,
	fetchLostOrdersTableCompare,
	fetchLostRevenueBreakdownCompare,
	fetchLostRevenueCompare,
	fetchNetRevenue,
	fetchOrderCompletionTimeChartCompare,
	fetchOrdersBreakdownCompare,
	fetchOrdersReceivedMetricsCompare,
	fetchRevenueBreakdownCompare,
	fetchUnitsSoldMetricsCompare,
	getAllAppliedFilters,
	getEncodedAnalyticsFilters,
	fetchItem,
	fetchBizPlatforms as fetchBizPlatform,
	fetchBrands as fetchBrand,
	fetchLocationDetail,
	fetchRestaurantAvailabilityCompare,
	fetchItemAvailabilityCompare,
	fetchSavedComparison,
	fetchCatalogueLostOrdersBreakdownTableCompare,
	fetchOrderCompletionTotalTimeCompare,
	fetchLocationGroupInFormat
} from "../../actions/analytics";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { updatedFilters, updateDurationFilter } from "../../helpers/analytics";

const ComparisonMetrics = ({ analyticsFiltersState, compareAnalytics }) => {
	const { metrics, id } = useParams();
	const history = useHistory();
	const [dropDownStatus, setDropDownStatus] = useState();
	const breakdown = compareAnalytics?.breakdown;
	const emptyArray = Array.from({ length: 5 });
	const tableRef = useRef(null);
	const columnData = compareAnalytics?.columnData?.[metrics];
	const appliedFilters = analyticsFiltersState?.appliedFilters;
	const appliedDateFilter = analyticsFiltersState?.appliedDateFilter;
	const count = compareAnalytics.columnData?.[metrics]?.count;
	const data = compareAnalytics.columnData?.[metrics]?.data;
	const metricData = {
		location: "location_id",
		brand: "brand_id",
		platform: "platform_names",
		item: "item"
	};
	const [status, setStatus] = useState("");
	const displayInfo = compareAnalytics?.displayInfo;
	const objectMetric = compareAnalytics?.tableData?.[metrics];

	let columns = Object.keys(objectMetric || {});

	const [highlightedIndex, setHighlightedIndex] = useState({});
	const columnRef = useRef();
	const [saveComparisonText, setSaveComparisonText] = useState("");

	const location = useLocation();

	const metricType = {
		location: fetchLocationDetail,
		location_group_id: fetchLocationGroupInFormat,
		item: fetchItem,
		platform: fetchBizPlatform,
		brand: fetchBrand
	};

	const firstDivRef = useRef(null);

	const columnDataWidth = firstDivRef?.current?.offsetWidth;
	const headerRef = useRef(null);
	const rowRef = useRef(null);

	const [userId, setUserId] = useState("");
	const [initialfilters, setInitialFilters] = useState("");
	const [initialColumnValues, setInitialColumnValues] = useState("");
	useEffect(() => {
		if (!id) {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_INITIAL_COLUMN_DATA
			});
		}
		if (location?.state?.userId) {
			setUserId(location?.state?.userId);
		} else {
			setUserId("");
		}
		if (location?.state?.status) {
			setStatus(location?.state?.status);
		} else {
			setStatus("draft");
		}

		if (location?.state?.filters) {
			setInitialFilters(location?.state?.filters);
		}
		if (location?.state?.columns) {
			setInitialColumnValues(JSON.stringify(location?.state?.columns));
		}
		store.dispatch({
			type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
			payload: { compareVisit: location?.pathname }
		});
	}, []);
	useEffect(() => {
		const handleResize = () => {
			window.scrollTo(0, 0);
			updateHeight();
		};
		handleResize();
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const initialScroll = useRef(0);
	const handleScroll = () => {
		if (initialScroll.current == 0) {
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
				payload: {
					headerVisibilityToggle: false
				}
			});
			initialScroll.current += 1;
		}
	};

	useEffect(() => {
		const secondDiv = document.getElementById("secondDiv");
		secondDiv.addEventListener("scroll", handleScroll);
		return () => {
			secondDiv.addEventListener("scroll", handleScroll);
		};
	}, [analyticsFiltersState.headerVisibilityToggle]);

	useEffect(() => {
		preFetchData();
	}, []);

	useEffect(() => {
		const divElement = rowRef.current;
		divElement.addEventListener("scroll", updateScrollProps);
		updateScrollProps();
		return () => {
			divElement.removeEventListener("scroll", updateScrollProps);
		};
	}, [dropDownStatus]);

	useEffect(() => {
		if (!["location", "platform", "item", "brand"].includes(metrics)) {
			history.push(`/analytics/compare?filters=${getEncodedAnalyticsFilters()}`);
		}
	}, [metrics]);

	useEffect(() => {
		if (columns.length > 1) {
			calculateHighlightedValues();
		} else {
			setHighlightedIndex({});
		}
	}, [compareAnalytics?.tableData?.[metrics], columnData]);

	const refs = {
		totalRevenue: useRef(),
		lostRevenue: useRef(),
		avgRevenuePerDay: useRef(),
		avgOrderValue: useRef(),
		totalOrders: useRef(),
		completedVsLost_Orders: useRef(),
		lostOrders: useRef(),
		avgOrdersPerDay: useRef(),
		bestSellingItems: useRef(),
		bestPerformingLocations: useRef(),
		worstSellingItems: useRef(),
		worstPerformingLocations: useRef(),
		orderCompletionTime: useRef(),
		locationAvailability: useRef(),
		itemAvailability: useRef(),
		snoozedItems: useRef(),
		totalSnoozeCount: useRef(),
		totalSnoozeTime: useRef()
	};

	const fetchCompareApi = (rowTitle, breakdown = false) => {
		const { filters } = getAllAppliedFilters();
		let currentFilters = filters.filter((val) => {
			if (metrics === "location" && val.field == "location_group_id") {
				return false;
			}
			return val.field !== (metricData?.[metrics] || "");
		});

		const fetchData = (fn, ...args) => {
			if (typeof fn !== "function") {
				return;
			}
			(data || []).forEach((element, index) => {
				if (index && count > 0) {
					let answer = metrics == "platform" ? "valueForDisplay" : "value";
					let field = metricData?.[metrics];
					if (element?.["field"] == "Location Groups") {
						field = "location_group_id";
					}
					let individualFilter = [...currentFilters, { field: field, value: element?.[answer]?.toString() }];
					fn(individualFilter, metrics, element.valueForDisplay, ...args);
				}
			});
		};

		const actions = {
			totalRevenue: () => {
				fetchData(fetchNetRevenue);
				if (metrics !== "platform") {
					fetchData(fetchRevenueBreakdownCompare);
				}
			},
			avgRevenuePerDay: () => {
				fetchData(fetchNetRevenue);
			},
			avgOrderValue: () => {
				fetchData(fetchNetRevenue);
			},
			lostRevenue: () => {
				if (metrics !== "item") {
					fetchData(fetchLostRevenueCompare);
				}
				if (metrics == "item") {
					fetchData(fetchLostOrdersTableCompare);
				}
				if (breakdown && metrics !== "platform") {
					fetchData(fetchLostRevenueBreakdownCompare);
				}
			},
			totalOrders: () => {
				if (breakdown && metrics !== "platform") {
					fetchData(fetchOrdersBreakdownCompare);
				}
				if (metrics !== "item") {
					fetchData(fetchOrdersReceivedMetricsCompare);
				}
				if (metrics == "item") {
					fetchData(fetchOrdersBreakdownCompare);
				}
			},
			completedVsLost_Orders: () => {
				if (metrics !== "item") {
					fetchData(fetchOrdersReceivedMetricsCompare);
				}
				if (metrics == "item") {
					fetchData(fetchUnitsSoldMetricsCompare);
				}
			},
			lostOrders: () => {
				if (metrics !== "item") {
					fetchData(fetchOrdersReceivedMetricsCompare);
					if (breakdown && metric !== "platform") {
						fetchData(fetchLostOrdersBreakdownTableCompare);
					}
				}
				if (metrics == "item") {
					fetchData(fetchCatalogueLostOrdersBreakdownTableCompare);
				}
			},
			avgOrdersPerDay: () => {
				if (metrics !== "item") {
					fetchData(fetchOrdersReceivedMetricsCompare);
				}
				if (metrics == "item") {
					fetchData(fetchUnitsSoldMetricsCompare);
				}
			},
			bestSellingItems: () => {
				if (metrics !== "item") {
					fetchData(fetchItemPerformanceTableCompare);
				}
			},
			worstSellingItems: () => {
				if (metrics !== "item") {
					fetchData(fetchItemPerformanceTableCompare, true);
				}
			},
			orderCompletionTime: () => {
				if (metrics !== "item") {
					fetchData(fetchOrderCompletionTotalTimeCompare);
					if (breakdown) {
						fetchData(fetchOrderCompletionTimeChartCompare);
					}
				}
			},

			bestPerformingLocations: () => {
				if (metrics == "item") {
					fetchData(fetchCatalogueLocationPerformanceTableCompare);
				}
			},
			worstPerformingLocations: () => {
				if (metrics == "item") {
					fetchData(fetchCatalogueLocationPerformanceTableCompare, true);
				}
			},
			locationAvailability: () => {
				fetchData(fetchRestaurantAvailabilityCompare);
			},
			itemAvailability: () => {
				fetchData(fetchItemAvailabilityCompare);
			}
		};

		const action = actions?.[rowTitle];

		if (action) {
			action();
		}
	};

	useEffect(() => {
		fetchCompareApi(displayInfo, true);
	}, [
		displayInfo,
		count,
		JSON.stringify(appliedFilters),
		JSON.stringify(appliedDateFilter),
		compareAnalytics?.columnData?.[metrics]?.data
			?.slice(1)
			.map((curr) => curr?.["valueForDisplay"])
			.join(",")
	]);
	const handleShow = (row) => {
		let value = row;
		if (displayInfo == row) {
			value = "";
		}
		store.dispatch({
			type: ActionTypes.UPDATE_COMPARE_ANALYTICS_CHANGE_STATE,
			payload: { displayInfo: value }
		});
	};

	const calculateHighlightedValues = () => {
		metric.forEach((val) => {
			const highlight = breakdown[val]?.["highlight"];
			if (!highlight) {
				return;
			}
			let flag = false;
			const columnNames = (compareAnalytics?.columnData?.[metrics]?.data).reduce((acc, curr, index) => {
				if (index !== 0) {
					acc.push(curr?.["valueForDisplay"]);
				}
				return acc;
			}, []);
			const values = columns
				.map((col) => {
					const metricValue = objectMetric?.[col]?.[val]?.value;
					if (columnNames.includes(col)) {
						if (objectMetric?.[col]?.[val]?.loading && count == 2) {
							flag = true;
							return null;
						}
						if (objectMetric?.[col]?.[val]?.error ? count == 2 : false) {
							flag = true;
							return null;
						}
						return Number(metricValue) >= 0 ? Number(metricValue) : null;
					} else {
						return null;
					}
				})
				.filter((v) => v !== null);

			if (values.length >= 2 && !flag) {
				let targetIndex;
				if (highlight === "max") {
					const maxValue = Math.max(...values);
					targetIndex = maxValue;
				} else if (highlight === "min") {
					const minValue = Math.min(...values);
					targetIndex = minValue;
				}
				if (JSON.stringify(highlightedIndex) !== JSON.stringify({ ...highlightedIndex, [val]: targetIndex })) {
					setHighlightedIndex((prev) => {
						return { ...prev, [val]: targetIndex };
					});
				}
			} else {
				if (JSON.stringify(highlightedIndex) !== JSON.stringify({ ...highlightedIndex, [val]: "" })) {
					setHighlightedIndex((prev) => {
						return { ...prev, [val]: "" };
					});
				}
			}
		});
	};

	const updateHeight = (val = 0) => {
		const secondDiv = document.getElementById("secondDiv");
		if (secondDiv) {
			const secondDivTop = secondDiv.getBoundingClientRect().top;
			const viewportHeight = window.innerHeight;
			let value = viewportHeight - secondDivTop + 9;
			if (val) {
				value += val;
			}
			secondDiv.style.height = `${value}px`;
		}
	};

	const handleColumn = (index) => {
		if (columnRef.current) {
			columnRef.current.handleColumnClick(index);
		}
	};
	const filters = useMemo(() => {
		return {
			appliedDateFilter: {
				current: {
					dateFilter: "LAST_7_DAYS",
					dateTypeSelected: {
						label: "Preset",
						value: "preset_duration"
					},
					presetTypeSelected: {
						label: "7 D",
						value: "LAST_7_DAYS",
						title: "Last 7 days",
						meta_info: "7 days since"
					}
				},
				compare: {
					dateFilter: "",
					dateTypeSelected: {
						label: "Not specified",
						value: "not_specified"
					},
					rangeStartDate: undefined,
					rangeEndDate: undefined
				}
			},
			appliedFilters: {
				brand_id: ["all"],
				location_id: ["all"],
				platform_names: ["all"]
			}
		};
	}, []);

	const [savedFor, setSavedFor] = useState("");
	const fetchColumnData = async (columns, type, selectedMetricFilters) => {
		try {
			let promises;
			const selectMetricObj = {};
			selectedMetricFilters.map((filter) => {
				if (filter?.field && filter?.value) {
					selectMetricObj[filter.field] = filter?.value.split(",");
				}
			});
			if (type !== "platform" && type !== "brand") {
				promises = columns.map((index) => {
					if (type === "location") {
						if (index) {
							if ((selectMetricObj?.location_group_id || []).includes(index)) {
								return metricType["location_group_id"](Number(index));
							} else {
								return metricType["location"](Number(index));
							}
						}
					} else {
						return metricType[type](Number(index));
					}
				});
			} else {
				let data = type == "platform" ? await fetchBizPlatform(true, true, true) : await fetchBrand();
				if (!data) {
					throw new Error("Error occured in fetching Platforms");
				}
				let ans = [];
				columns.forEach((index) => {
					(data || []).forEach((value) => {
						if (value?.id == index) {
							const valueForDisplay = type === "platform" ? value.platformName : value?.name;
							const image = type === "platform" ? value.logo : value.image;
							let obj = { value: value.id, image, valueForDisplay };
							if (value?.color) {
								obj["color"] = value?.color;
							}
							ans.push(obj);
						}
					});
				});
				promises = ans;
			}

			let results = await Promise.allSettled(promises);
			let filteredResults = [];
			results.forEach((result) => {
				if (result.status == "fulfilled") {
					if (result.value) {
						filteredResults.push(result.value);
					}
				}
			});
			let payload = {
				metric: type,
				data: [type, ...filteredResults],
				new: true,
				count: filteredResults.length
			};
			store.dispatch({
				type: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
				payload: { columnData: { ...compareAnalytics.columnData, loading: false } }
			});
			store.dispatch({
				type: "UPDATE_COMPARE_ANALYTICS_COLUMN_DATA",
				payload
			});
		} catch (err) {
			console.log(err);
		}
	};
	const fetchInititalData = async () => {
		if (id) {
			if (!location?.state?.handleClearFilters) {
				try {
					store.dispatch({
						type: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
						payload: { columnData: { ...compareAnalytics.columnData, loading: true } }
					});
					const resp = await fetchSavedComparison({ id: id });
					const selectedMetricFilters = resp?.data?.getComparison?.comparison?.filters;
					setUserId(
						String(lS.get("auth")?.userInfo?.id) === String(resp?.data?.getComparison?.userId)
							? resp?.data?.getComparison?.userId
							: null
					);
					setStatus(resp?.data?.getComparison?.status);
					setSavedFor(resp?.data?.getComparison?.savedFor);
					let newFilters = updatedFilters(filters, resp?.data?.getComparison?.comparison?.filters);
					newFilters = updateDurationFilter(newFilters, resp?.data?.getComparison?.comparison?.duration);
					setInitialFilters(newFilters);
					setInitialColumnValues(JSON.stringify(resp?.data?.getComparison?.comparison?.metrics || {}));
					store.dispatch({
						type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
						payload: {
							currentFilters: newFilters.appliedFilters,
							appliedFilters: newFilters.appliedFilters,
							initialFiltersFromUrl: newFilters.appliedFilters,
							currentDateFilter: newFilters.appliedDateFilter,
							appliedDateFilter: newFilters.appliedDateFilter
						}
					});
					fetchColumnData(resp?.data?.getComparison?.comparison?.metrics, metrics, selectedMetricFilters);
				} catch (err) {
					store.dispatch({
						type: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
						payload: { columnData: { ...compareAnalytics.columnData, loading: false } }
					});
				}
			}
		}
	};
	useEffect(() => {
		fetchInititalData();
	}, []);

	const preFetchData = async () => {
		if (location?.state?.handleClearFilters) {
			try {
				store.dispatch({
					type: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
					payload: { columnData: { ...compareAnalytics.columnData, loading: true } }
				});
				let type = location?.state?.type;
				let columns = location?.state?.columns || [];
				let promises;
				if (type !== "platform" && type !== "brand") {
					promises = columns.map((index) => {
						if (type === "location") {
							let { appliedFilters } = location?.state?.filters;
							if (index) {
								if ((appliedFilters?.location_group_id || []).includes(index)) {
									return metricType["location_group_id"](Number(index));
								} else {
									return metricType["location"](Number(index));
								}
							}
						} else {
							return metricType[type](Number(index));
						}
					});
				} else {
					let data = type == "platform" ? await fetchBizPlatform(true, true, true) : await fetchBrand();
					if (!data) {
						throw new Error("Error occured in fetching Platforms");
					}
					let ans = [];
					columns.forEach((index) => {
						(data || []).forEach((value) => {
							if (value?.id == index) {
								const valueForDisplay = type === "platform" ? value.platformName : value?.name;
								const image = type === "platform" ? value.logo : value.image;
								let obj = { value: value.id, image, valueForDisplay };
								if (value?.color) {
									obj["color"] = value?.color;
								}
								ans.push(obj);
							}
						});
					});
					promises = ans;
				}
				let results = await Promise.allSettled(promises);
				let filteredResults = [];
				results.forEach((result) => {
					if (result.status == "fulfilled") {
						if (result.value) {
							filteredResults.push(result.value);
						}
					}
				});

				let payload = {
					metric: type,
					data: [type, ...filteredResults],
					new: true,
					count: filteredResults.length
				};
				store.dispatch({
					type: "UPDATE_COMPARE_ANALYTICS_CHANGE_STATE",
					payload: { columnData: { ...compareAnalytics.columnData, loading: false } }
				});
				store.dispatch({
					type: "UPDATE_COMPARE_ANALYTICS_COLUMN_DATA",
					payload
				});
			} catch (err) {
				console.log(err);
			}
		}
		if (location?.state?.name) {
			setSaveComparisonText(location?.state?.name);
		}
	};

	useOnClickOutside(headerRef, (e) => {
		if (!e.target.closest(".head-row-col") && dropDownStatus) {
			setDropDownStatus();
		}
	});

	const getData = (currIndex, rowTitle) => {
		const currentData = compareAnalytics.columnData?.[metrics].data?.[currIndex];
		const tableData = compareAnalytics.tableData?.[metrics]?.[currentData?.valueForDisplay];
		const individualData = tableData?.[rowTitle];
		const condition =
			metrics === "platform"
				? individualData?.loading ||
					individualData?.error ||
					(displayInfo == rowTitle && rowTitle !== "totalRevenue")
				: individualData?.loading || individualData?.error || displayInfo == rowTitle;
		if (condition) {
			return undefined;
		}
		return individualData?.value;
	};

	const handleSelect = (num) => {
		if (dropDownStatus == num) {
			setDropDownStatus();
		} else {
			if (checkStatus(num)) {
				setDropDownStatus(num);
			} else {
				setDropDownStatus(num + 1);
			}
		}
	};

	const checkStatus = (num) => {
		let firstCol = document.querySelector(".head-row-col");

		if (rowRef.current.scrollLeft > 0 && num == 1) {
			return false;
		}
		if (num >= 2) {
			if (rowRef.current.scrollLeft > (num - 1) * firstCol.offsetWidth) {
				return false;
			}
		}
		return true;
	};

	const updateScrollProps = () => {
		let firstCol = document.querySelector(".head-row-col");
		if (dropDownStatus) {
			if (dropDownStatus == 1) {
				if (rowRef.current.scrollLeft > 0) {
					setDropDownStatus();
				}
			}
			if (dropDownStatus >= 2) {
				if (rowRef.current.scrollLeft > (dropDownStatus - 1) * firstCol.offsetWidth) {
					setDropDownStatus();
				}
			}
		}
	};

	const pltFiltersApplied = (getAllAppliedFilters()?.filters || []).filter((obj) => obj.field === "platform_names");
	const pltFilters =
		pltFiltersApplied.length > 0
			? pltFiltersApplied
					.reduce((acc, curr) => {
						acc += curr.value;
						return acc;
					}, "")
					.split(",")
			: "";
	const showBreakdown =
		count >= 1 && metrics !== "platform" && (pltFiltersApplied.length == 0 || pltFilters.length > 1) ? true : false;
	useEffect(() => {
		if (!showBreakdown && displayInfo !== "") {
			store.dispatch({
				type: ActionTypes.UPDATE_COMPARE_ANALYTICS_CHANGE_STATE,
				payload: { displayInfo: "" }
			});
		}
	}, [showBreakdown]);

	const handleCloseIndicator = () => {
		updateHeight(57);
	};
	return (
		<div className={`analytics-container section-container-common compare-metrics`} ref={tableRef}>
			<Header
				saveComparisonText={saveComparisonText}
				setSaveComparisonText={setSaveComparisonText}
				userId={userId}
				metrics={metrics}
				status={status}
				savedFor={savedFor}
				initialfilters={initialfilters}
				initialColumnValues={initialColumnValues}
				handleCloseIndicator={handleCloseIndicator}
			/>
			<div className="comparison-metrics-table" id="secondDiv" ref={rowRef}>
				<div className="header-div">
					{emptyArray.map((col, indexVal) => {
						return (
							<div
								className={indexVal == 0 ? "head-zero " : "head-row-col"}
								key={indexVal}
								ref={indexVal == 0 ? null : headerRef}
								onClick={() => handleColumn(indexVal)}
							>
								<EnterLocation
									metric={metrics}
									colIndex={indexVal}
									dropDownStatus={dropDownStatus}
									setDropDownStatus={setDropDownStatus}
									handleSelect={handleSelect}
									columnRef={columnRef}
								/>
							</div>
						);
					})}
				</div>
				<div>
					{metric.map((val, index) => {
						let rowTitle = metric[index];
						if (metricContainer[rowTitle]?.[metrics]) {
							return (
								<div key={val} className="flex">
									{Array(emptyArray.length)
										.fill()
										.map((_, currIndex) => {
											if (currIndex == 0) {
												rowTitle = metric[index];
											}
											if (metricContainer[rowTitle]?.[metrics]) {
												let highlight = "";
												if (
													Math.abs(Number(getData(currIndex, rowTitle))) >= 0 &&
													String(highlightedIndex?.[rowTitle]) ===
														String(getData(currIndex, rowTitle)) &&
													getData(currIndex, rowTitle) != undefined &&
													count > 1
												) {
													highlight = "highlight";
												}
												return (
													<React.Fragment key={currIndex}>
														<div
															className={`${
																currIndex === 0 ? "row-col-zero" : "row-col"
															} ${highlight} flex`}
															ref={index === 0 ? firstDivRef : null}
														>
															{currIndex == 0 ? (
																<div className="row-title" style={{ width: "100%" }}>
																	<MetricCard
																		metric={rowTitle}
																		tabMetric={rowTitle}
																		cardRef={refs[rowTitle]}
																	>
																		{(metric, metricCardInViewport) => {
																			return (
																				<>
																					<MetricSection
																						metric={metric}
																						metricCardInViewport={
																							metricCardInViewport
																						}
																						metricSectionApi={
																							fetchCompareApi
																						}
																						dependencies={[
																							count,
																							JSON.stringify(
																								appliedFilters
																							),
																							JSON.stringify(
																								appliedDateFilter
																							),
																							JSON.stringify(
																								columnData.data
																							)
																						]}
																					>
																						{() => {
																							return (
																								<div>
																									<div className="mini-header">
																										{
																											metricContainer[
																												rowTitle
																											]?.name
																										}
																									</div>
																									<div
																										className="breakdown"
																										onClick={() =>
																											handleShow(
																												rowTitle
																											)
																										}
																									>
																										{(showBreakdown ||
																											rowTitle ==
																												"orderCompletionTime") &&
																											(
																												Object.keys(
																													objectMetric
																												) || []
																											).length >
																												0 &&
																											breakdown?.[
																												rowTitle
																											]?.[
																												"type"
																											] ===
																												"breakdown" &&
																											(metrics ===
																											"platform"
																												? !!breakdown?.[
																														rowTitle
																													]?.[
																														metrics
																													]
																												: true) && (
																												<>
																													{displayInfo ==
																													rowTitle
																														? "Hide Breakdown"
																														: "Show Breakdown"}
																												</>
																											)}
																									</div>
																								</div>
																							);
																						}}
																					</MetricSection>
																				</>
																			);
																		}}
																	</MetricCard>
																</div>
															) : (
																<div style={{ width: "100%" }}>
																	{metricContainer[rowTitle]?.[metrics] &&
																		metricContainer[rowTitle]?.component({
																			rowTitle: rowTitle,
																			currIndex: currIndex,
																			metric: metrics,
																			columnDataWidth
																		})}
																</div>
															)}
														</div>
													</React.Fragment>
												);
											}
										})}
								</div>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(ComparisonMetrics);
