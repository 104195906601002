import React, { useEffect } from "react";

// third party
import { PiperRewind } from "@urbanpiper-engineering/horizon";
import { connect } from "react-redux";
import moment from "moment";
import he from "he";

// store
import { store } from "../store/configureStore";

// utils
import { trackEvent, isStartOfNewYear, commifyNumbers } from "../atlas-utils";

// services
import SatismeterService from "../services/SatismeterService";

// actions
import { ActionTypes } from "../actions/_types";
import {
	fetchOrdersReceivedMetrics,
	fetchGrossRevenueMetrics,
	fetchOrdersBreakdownTable,
	fetchOrdersByLocation,
	fetchCatalogueMetrics,
	fetchOrdersByItem,
	fetchRevenueByItem,
	fetchOrderFrequencyTimePeriod,
	fetchOrderFrequencyBusiestDay,
	fetchOrdersReceivedChart,
	fetchBestCityByOrder
} from "../actions/rewind";

// constants
const TRACK_SLIDE_EVENTS = {
	4: "top_level_data_viewed",
	7: "menu_numbers_viewed",
	10: "operation_numbers_viewed",
	11: "stayed_till_last"
};

const Rewind = ({ rewindState, currencySymbol }) => {
	const { isRewindOpen, data } = rewindState;

	useEffect(() => {
		if (isStartOfNewYear()) {
			fetchOrdersReceivedMetrics();
		}
	}, []);

	useEffect(() => {
		if (isRewindOpen && data?.totalOrders > 50) {
			fetchOrdersReceivedMetrics();
			fetchGrossRevenueMetrics();
			fetchOrdersBreakdownTable();
			fetchOrdersByLocation();
			fetchCatalogueMetrics();
			fetchOrdersByItem();
			fetchRevenueByItem();
			fetchOrderFrequencyTimePeriod();
			fetchOrderFrequencyBusiestDay();
			fetchOrdersReceivedChart();
			fetchBestCityByOrder();
		}
	}, [isRewindOpen]);

	const handleClose = () => {
		store.dispatch({
			type: ActionTypes.REWIND_OPEN,
			payload: { isRewindOpen: false }
		});

		// trigger Satismeter Survey
		SatismeterService.rewindCarousel();

		// track event
		trackEvent("rewind_carousel_closed", {});
	};

	const getCurrentViewingSlide = (index) => {
		// track event
		if (isRewindOpen && [4, 7, 10, 11].includes(index)) {
			trackEvent("rewind_slides_viewed_till", {
				highlights_year: moment().year() - 1,
				milestone: TRACK_SLIDE_EVENTS[index],
				number: index + 1
			});
		}
	};

	const generateSlidesData = () => {
		const curretYear = moment().year();
		const lastYear = curretYear - 1;
		const topic = `${lastYear} on the plate`;
		let carouselData = [];

		if (data.totalOrders > 50) {
			carouselData = [
				{
					type: "headline",
					bgImg: "/assets/background/purple-bg-1.png",
					topic: "",
					subTopic: "",
					content: [
						{
							variant: "secondary",
							text: `${topic} —`
						},
						{
							variant: "primary",
							text: "Celebrating our delicious journey together 🥂"
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-4",
					contentStyle: { margin: "130px", width: "500px" }
				},
				{
					type: "headline",
					bgImg: "/assets/background/purple-bg-2.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: "Wow, what a mouth-watering year! You didn't just serve meals; you served magic and cooked up some serious wins."
						},
						{
							variant: "primary",
							text: `Here's your delicious ${lastYear} recap!`
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-12",
					contentStyle: { margin: "45px", width: "685px", marginBottom: "70px" }
				},
				{
					type: "headline",
					bgImg: "/assets/background/green-bg-1.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: `🥇 Soaring numbers in ${lastYear}`
						}
					],
					containerClass: "flex items-center justify-center",
					containerStyle: {},
					contentClass: "",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/green-bg-2.png",
					topic,
					subTopic: `Soaring numbers in ${lastYear}`,
					metrics: [
						{
							title: "Orders this year",
							icon: "🥳",
							text: `You served up ${commifyNumbers(
								data?.totalOrders
							)} orders of happiness. That's a buffet of joy!`,
							highlight: commifyNumbers(data?.totalOrders)
						},
						{
							title: "Earnings this year",
							icon: "💰",
							text: `A scrumptious ${he.unescape(currencySymbol)}${commifyNumbers(
								data?.netRevenue
							)} made its way to your cash register. That's bread well-earned!`,
							highlight: `${he.unescape(currencySymbol)}${commifyNumbers(data?.netRevenue)}`
						}
					],
					containerClass: "",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/green-bg-3.png",
					topic,
					subTopic: `Soaring numbers in ${lastYear}`,
					metrics: [
						{
							title: "Top ordering platform",
							icon: "🚀",
							text: `Customers swarmed ${data?.topPlatform?.name} with ${commifyNumbers(
								data?.topPlatform?.orders
							)} orders and a hearty ${he.unescape(currencySymbol)}${commifyNumbers(
								data?.topPlatform?.netRevenue
							)} in earnings.`,
							highlight: data?.topPlatform?.name
						},
						{
							title: "Top Location",
							icon: "📍",
							text: `${data?.topLocation?.name} topped the charts with ${commifyNumbers(
								data?.topLocation?.orders
							)} orders and ${he.unescape(currencySymbol)}${commifyNumbers(
								data?.topLocation?.netRevenue
							)} earned. Cheers to your rockstar spot!`,
							highlight: data?.topLocation?.name
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "headline",
					bgImg: "/assets/background/pink-bg-1.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: "🌟 Menu Hall of Fame"
						}
					],
					containerClass: "flex items-center justify-center",
					containerStyle: {},
					contentClass: "",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/pink-bg-2.png",
					topic,
					subTopic: "Menu Hall of Fame",
					metrics: [
						{
							title: "Menu this year",
							icon: "🍕",
							text: `Your menu dazzled with ${commifyNumbers(
								data?.totalItems
							)} irresistible choices — spreading taste one dish at a time.`,
							highlight: commifyNumbers(data?.totalItems)
						},
						{
							title: "Top Ordered Dish",
							icon: "🥇",
							text: `${data?.topItemUnitsSold} was the headliner everyone came back for. Encore!`,
							highlight: data?.topItemUnitsSold
						}
					],
					containerClass: "",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/pink-bg-3.png",
					topic,
					subTopic: "Menu Hall of Fame",
					metrics: [
						{
							title: "Top Money Maker",
							icon: "💸",
							text: `${data?.topItemNetRevenue} brought in the big bucks, proving it's the dish of dreams.`,
							highlight: data?.topItemNetRevenue
						}
						// {
						// 	title: "Top Add-On",
						// 	icon: "🥤",
						// 	text: `${data?.topAddOn} was the MVP of meal upgrades. The perfect sidekick as you may say!`,
						// 	highlight: data?.topAddOn
						// }
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "headline",
					bgImg: "/assets/background/blue-bg-1.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: "🔥 Kitchen Chronicles"
						}
					],
					containerClass: "flex items-center justify-center",
					containerStyle: {},
					contentClass: "",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/blue-bg-2.png",
					topic,
					subTopic: "Kitchen Chronicles",
					metrics: [
						{
							title: "Rush Hour Rumble",
							icon: "⏰",
							text: `${data?.goldenHourTimePeriod} was your golden hour — game on, spatulas up!`,
							highlight: data?.goldenHourTimePeriod
						},
						{
							title: "Busiest Day",
							icon: "🏆",
							text: `${data?.busiestDayOrders} had your kitchen bustling like a symphony of sizzles!`,
							highlight: data?.busiestDayOrders
						}
					],
					containerClass: "",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "metrics",
					bgImg: "/assets/background/blue-bg-3.png",
					topic,
					subTopic: "Kitchen Chronicles",
					metrics: [
						{
							title: "Best Month",
							icon: "🌟",
							text: `${data?.bestMonthOrders} was your jackpot month — full plates and fuller pockets.`,
							highlight: data?.bestMonthOrders
						},
						{
							title: "Star City",
							icon: "🌆",
							text: `${data?.bestCityOrders} showed you all the love. Who needs Paris when you've got this star-studded crowd?`,
							highlight: data?.bestCityOrders
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex w-full h-full",
					contentStyle: {}
				},
				{
					type: "headline",
					bgImg: "/assets/background/purple-bg-3.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: `✨ Here's to an incredible ${lastYear} — you absolutely nailed! Thanks for letting us be a part of your flavour-packed journey. Let's make ${curretYear} even bigger and better!`
						},
						{
							variant: "secondary",
							text: (
								<div>
									Stay awesome,
									<br />
									Team UrbanPiper
								</div>
							)
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-12",
					contentStyle: { margin: "45px", width: "850px", marginBottom: "120px" }
				}
			];
		} else {
			carouselData = [
				{
					type: "headline",
					bgImg: "/assets/background/purple-bg-1.png",
					topic: "",
					subTopic: "",
					content: [
						{
							variant: "secondary",
							text: `${topic} —`
						},
						{
							variant: "primary",
							text: "Celebrating our delicious journey together 🥂"
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-4",
					contentStyle: { margin: "130px", width: "500px" }
				},
				{
					type: "headline",
					bgImg: "/assets/background/pink-bg-1.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: "Looks like our story is just getting started!"
						},
						{
							variant: "primary",
							text: `We wanted to serve you a delicious platter of ${lastYear}'s highlights, but we're still gathering the right ingredients.`
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-12",
					contentStyle: { margin: "45px", width: "675px", marginBottom: "120px" }
				},
				{
					type: "headline",
					bgImg: "/assets/background/blue-bg-1.png",
					topic,
					subTopic: "",
					content: [
						{
							variant: "primary",
							text: `Here's to a sizzling ${curretYear} with flavorful milestones! Happy New Year — Let's make it a success together! 🥂🍴✨`
						},
						{
							variant: "secondary",
							text: (
								<div>
									Stay awesome,
									<br />
									Team UrbanPiper
								</div>
							)
						}
					],
					containerClass: "flex items-center justify-start",
					containerStyle: {},
					contentClass: "flex flex-col gap-16",
					contentStyle: { margin: "45px", width: "850px", marginBottom: "120px" }
				}
			];
		}

		return carouselData;
	};

	return (
		<PiperRewind
			className="urbanpiper-rewind"
			open={isRewindOpen}
			onClose={handleClose}
			data={generateSlidesData()}
			modalProps={{ maskClosable: false }}
			carouselProps={{ afterChange: getCurrentViewingSlide }}
		/>
	);
};
const mapStateToProps = (store) => ({
	rewindState: store.rewindState,
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});
export default connect(mapStateToProps)(Rewind);
