import React, { useEffect, useRef, useState } from "react";
import { dateFormat, periscopeHelpClicked } from "../../helpers/periscope";
import { connect } from "react-redux";
import { Button } from "../_commons/Button";
import HelpIcon from "../_commons/HelpIcon";
import { NestedEntityContainer } from "../_commons/NestedEntityContainer";
import { store } from "../../store/configureStore";
import { adjustNestedContainer } from "../../atlas-utils";
import { NESTED_ENTITY_TYPES, periscopeVidTimeStamps } from "../../client-config";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { fetchPeriscopeData as fetchPeriscopeRefreshStatus } from "../../actions/notifications";
import { ActionTypes } from "../../actions/_types";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const MainHeader = ({
	classname = "",
	headerText = "",
	periscopeDataResponse,
	showTime = true,
	periscopeEarlyAccessState,
	login
}) => {
	const location = useLocation();
	useEffect(() => {
		if (!periscopeEarlyAccessState?.initialiseSocket && location.pathname.startsWith("/periscope")) {
			fetchPeriscopeRefreshStatus(login?.loggedInbizDetail?.id);
			console.log(login?.loggedInbizDetail?.id);
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
				payload: { initialiseSocket: true }
			});
		}
	}, [location.pathname]);
	const timestamp = periscopeDataResponse?.timestamp;
	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "periscope",
				start: periscopeVidTimeStamps?.[location.pathname.split("/")[2]]
					? periscopeVidTimeStamps?.[location.pathname.split("/")[2]]?.time
					: "00:00"
			}
		});
		periscopeHelpClicked(periscopeVidTimeStamps?.[location.pathname.split("/")[2]]?.type || "Home");
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};
	const nestedRef = useRef();
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};

	return (
		<div className={classname}>
			<div className="title-peri"> {headerText} </div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
			<div className="head-right-div">
				{showTime && timestamp ? <div className=" fetch-time "> {dateFormat(timestamp)} </div> : ""}
				<Button clickHandler={handlePiperAcademy} type="secondary">
					<HelpIcon />
					<span>Help</span>
				</Button>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	login: state.login,
	periscopeEarlyAccessState: state.periscopeEarlyAccessState,
	periscopeDataResponse: state.periscopeDataResponse
});

export default connect(mapStateToProps)(MainHeader);
