import React, { useRef, useState } from "react";
import { NESTED_ENTITY_TYPES, periscopeVidTimeStamps } from "../../../client-config";
import { store } from "../../../store/configureStore";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { adjustNestedContainer } from "../../../atlas-utils";
import { NestedEntityContainer } from "../../_commons/NestedEntityContainer";
import { periscopeHelpClicked } from "../../../helpers/periscope";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const Header = ({ showWaitlistCTA }) => {
	const nestedRef = useRef();
	const location = useLocation();
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const timestamp = location.pathname.includes("get-started") || location.pathname.includes("checkout");

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "periscope",
				start: timestamp ? periscopeVidTimeStamps?.["onboarding"]?.time : "00:00"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
		periscopeHelpClicked(showWaitlistCTA ? "Waitlist" : "Free Trial");
	};

	const handleNestedEntity = (toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
		}
		adjustNestedContainer(toOpen);
	};
	return (
		<div className="early-access-header">
			<div className="header-text">Periscope</div>
			<NestedEntityContainer
				show={nestedEntity.show}
				type={nestedEntity.type}
				id={nestedEntity.id}
				closeNestedContainer={() => handleNestedEntity(false)}
				nestedRef={nestedRef}
				isNested={false}
				isForeignSource={true}
			/>
			<div className="enquiry">
				<img src="/assets/periscope/any-questions.svg" />
				<span className="questions">Have questions?</span>
				<span className="contact" onClick={handlePiperAcademy}>
					Learn more
				</span>
			</div>
		</div>
	);
};

export default Header;
