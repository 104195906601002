// action types
import { ActionTypes } from "../actions/_types";

// store
import { store } from "../store/configureStore";

// utils
import { isLoggedin, lS, getLoggedInBizId } from "../atlas-utils";

// constants
import { ATLAS_ONBOARDING_STEPS, ONBOARDING_FLOWS } from "../client-config";

const ATLAS_ONBOARDING_STEPS_LIST_INIT = {
	isLoading: false,
	flowData: isLoggedin() && getLoggedInBizId() ? lS.get("onboardingFlow")?.[getLoggedInBizId()]?.flowData || {} : {},
	flowSteps: isLoggedin() && getLoggedInBizId() ? lS.get("onboardingFlow")?.[getLoggedInBizId()]?.flowSteps || [] : []
};

const MENU_PULL_FROM_URL = {
	status: "default",
	brandId: null
};

export const menuPullFromUrlState = (state = MENU_PULL_FROM_URL, action) => {
	switch (action.type) {
		case ActionTypes.MENU_PULL_FROM_URL_STATUS_UPDATE:
			return {
				...state,
				status: action.payload.status,
				brandId: action.payload.brandId
			};
		default:
			return state;
	}
};

export const atlasOnboardingState = (state = ATLAS_ONBOARDING_STEPS_LIST_INIT, action) => {
	let onboardingFlow = {};
	let bizId = getLoggedInBizId();

	switch (action.type) {
		case ActionTypes.GET_USER_FLOW_STEPS_REQUEST:
			return {
				...state,
				isLoading: true
			};

		case ActionTypes.GET_USER_FLOW_STEPS_SUCCESS:
			let flowData = action.payload?.find?.(({ flow }) => {
				return flow?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;
			})?.flow;

			const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
			if (!isMultibrandEnabled && flowData) {
				flowData.steps = flowData?.steps?.filter((step) => step.name !== ATLAS_ONBOARDING_STEPS.CREATE_BRANDS);
			}

			// update local storage
			bizId = getLoggedInBizId();
			if (bizId) {
				onboardingFlow = lS.get("onboardingFlow") || {};
				onboardingFlow[bizId] = {
					...(onboardingFlow[bizId] ?? {}),
					flowData: flowData ?? {}
				};
				lS.set("onboardingFlow", onboardingFlow);
			}

			return {
				...state,
				isLoading: false,
				flowData: flowData ?? {}
			};

		case ActionTypes.GET_USER_FLOW_STEPS_FAILURE:
			return {
				...state,
				isLoading: false
			};

		case ActionTypes.GET_USER_STEPS_REQUEST:
			return {
				...state
			};

		case ActionTypes.GET_USER_STEPS_SUCCESS:
			// update local storage
			bizId = getLoggedInBizId();
			if (bizId) {
				onboardingFlow = lS.get("onboardingFlow") || {};
				onboardingFlow[bizId] = {
					...(onboardingFlow[bizId] ?? {}),
					flowSteps: action.payload
				};
				lS.set("onboardingFlow", onboardingFlow);
			}

			return {
				...state,
				flowSteps: action.payload
			};

		case ActionTypes.GET_USER_STEPS_FAILURE:
			return {
				...state,
				flowSteps: []
			};

		case ActionTypes.GET_ATLAS_ONBOARDING_STATE:
			return {
				...state,
				...action.payload
			};

		case ActionTypes.RESET_ATLAS_ONBOARDING_STATE:
			// update local storage
			onboardingFlow = lS.get("onboardingFlow") || {};
			delete onboardingFlow[getLoggedInBizId()];
			lS.set("onboardingFlow", onboardingFlow);

			return {
				isLoading: false,
				flowData: {},
				flowSteps: []
			};

		default:
			return state;
	}
};
