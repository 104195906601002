import React, { useState, useEffect, useCallback, useImperativeHandle, useRef } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { Button } from "../components/_commons/Button";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import BasicDetails from "../components/TimingGroupEdit/BasicDetails";
import RegularSchedule from "../components/TimingGroupEdit/RegularSchedule";

// utils
import history from "../history";
import { store } from "../store/configureStore";
import { removeProp, adjustNestedContainer } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import {
	fetchTimingGroupsList,
	fetchTimingGroupDetail,
	editTimingGroupDetail,
	parseTimingGroupData,
	getUpdatedTimeSlots,
	addEmptySlot
} from "../actions/timingGroups";
import { ActionTypes } from "../actions/_types";

// assets
import HelpIcon from "../components/_commons/HelpIcon";

// constant
import { NESTED_ENTITY_TYPES } from "../client-config";
import { TIME_SLOT_INITIAL_STATE, SLOT_INITIAL_STATE, CUSTOM_SCHEDULE_TYPE_TIME_SLOT } from "./TimingGroupCreate";
import LocationAssociation from "../components/TimingGroupEdit/LocationAssociation";

const FORM_TABS = [
	{
		label: "Basic Details",
		value: "basic"
	},
	{
		label: "Schedule",
		value: "schedule"
	},
	{
		label: "Locations",
		value: "location"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const TimingGroupEdit = ({
	timingGroupDetails,
	access,
	match,
	isNested = false,
	closeNestedContainer,
	connectedRef,
	configItems,
	biz
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [archRestData, setArchRestData] = useState([]);
	const { loading, data, timeSlots, error } = timingGroupDetails;
	const [isModalBusy, setModalBusy] = useState(false);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const nestedRef = useRef();
	const [isCustomScheduleSelected, setIsCustomScheduleSelected] = useState(false);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
	}, []);
	useEffect(() => {
		const hasCustomSchedule =
			timeSlots &&
			timeSlots.some((entry) => entry && entry.scheduleType && entry.scheduleType.label === "Custom");
		setIsCustomScheduleSelected(Boolean(hasCustomSchedule));
	}, [timeSlots]);

	const handleFetchTimingGroupDetail = async () => {
		if (configItems?.brands?.selectedBrand?.id) {
			await fetchTimingGroupDetail(parseInt(match.params.id), configItems.brands.selectedBrand.id);
		} else {
			await fetchTimingGroupDetail(parseInt(match.params.id));
		}
	};

	useEffect(() => {
		handleFetchTimingGroupDetail();
	}, [match.params.id]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleCancel = () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			store.dispatch({
				type: ActionTypes.RESET_TIMING_GROUPS_DETAIL
			});
			setTimeout(() => {
				if (isNested) {
					closeNestedContainer();
				} else {
					fetchTimingGroupsList(true, "", biz?.isMultibrandEnabled ? "all" : "", 10, 0, {
						field: "title",
						order: "ASC"
					});
					history.push("/timing-groups");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleForm = (field, value, index) => {
		if (field === "addNewTimeSlot") {
			store.dispatch({
				type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
				payload: {
					[field]: CUSTOM_SCHEDULE_TYPE_TIME_SLOT
				}
			});
		} else if (field === "addSlot") {
			const updatedTimeSlots = addEmptySlot(timeSlots, index);
			store.dispatch({
				type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
				payload: {
					timeSlots: updatedTimeSlots
				}
			});
		} else {
			store.dispatch({
				type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		}
	};

	const handleSelectDays = (day, value, index) => {
		const newSelectDays = {
			...timeSlots[index].selectDays
		};
		newSelectDays[day] = value;
		const updatedTimeSlots = timeSlots.map((slot, i) => {
			if (i === index) {
				const daysCount = Object.values(newSelectDays).filter((val) => val === true).length;
				if (daysCount === 7) {
					Object.keys(newSelectDays).map((day) => {
						newSelectDays[day] = false;
					});
					return {
						...slot,
						selectDays: newSelectDays,
						scheduleType: {
							label: "Daily",
							value: "Daily"
						}
					};
				} else {
					return {
						...slot,
						selectDays: newSelectDays
					};
				}
			}
			return slot;
		});
		store.dispatch({
			type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
			payload: {
				timeSlots: updatedTimeSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleTimeSlots = (data, updatedTimeSlotIndex, updatedSlotIndex) => {
		let updatedTimeSlots = [];
		if (updatedTimeSlotIndex === 0 && data?.scheduleType?.label === "Custom") {
			setIsCustomScheduleSelected(true);
			updatedTimeSlots = getUpdatedTimeSlots(data, timeSlots, updatedTimeSlotIndex, updatedSlotIndex);
		} else if (updatedTimeSlotIndex === 0 && data?.scheduleType?.label && data?.scheduleType?.label !== "Custom") {
			setIsCustomScheduleSelected(false);
			updatedTimeSlots = getUpdatedTimeSlots(data, [timeSlots[0]], updatedTimeSlotIndex, updatedSlotIndex);
		} else {
			updatedTimeSlots = getUpdatedTimeSlots(data, timeSlots, updatedTimeSlotIndex, updatedSlotIndex);
		}
		store.dispatch({
			type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
			payload: {
				timeSlots: updatedTimeSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleDeleteTimeSlot = (index) => {
		const updatedTimeSlots = timeSlots.filter((slot, i) => i !== index);
		store.dispatch({
			type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
			payload: {
				timeSlots: updatedTimeSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};
	const handleDeleteSingleTiming = (slotIndex, timeSlotIndex) => {
		const updatedTimeSlots = timeSlots.map((slot, i) => {
			if (i === timeSlotIndex) {
				const updatedSlots = slot?.slots.filter((ts, j) => j !== slotIndex);
				return {
					...slot,
					slots: updatedSlots
				};
			}
			return slot;
		});
		store.dispatch({
			type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
			payload: {
				timeSlots: updatedTimeSlots
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				store.dispatch({
					type: ActionTypes.UPDATE_TIMING_GROUPS_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const handleArchRestData = useCallback(() => {
		const variables = parseTimingGroupData("server", data, timeSlots);
		setArchRestData(variables);
		setArchiveRestore(true);
	}, [data, timeSlots]);

	const handleSubmit = async () => {
		const sanitisedData = removeProp(data, "__typename");
		const resp = await editTimingGroupDetail(sanitisedData, timeSlots);
		if (resp) {
			setFormTouched(false);
			await handleFetchTimingGroupDetail();
		}
	};

	const handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "schedules"
			}
		});
		handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	const handleRefetchLocationAssociation = async (brandId = "") => {
		if (brandId && brandId !== "") {
			await fetchTimingGroupDetail(parseInt(match.params.id), brandId);
		} else {
			await fetchTimingGroupDetail(parseInt(match.params.id));
		}

		setFormTab(FORM_TABS[2].value);
	};

	return (
		<div className="timing-groups-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={handleSubmit}
				title={data.title || "Schedule"}
				subTitle="Edit this schedule"
				submitTitle="Save"
				loading={loading}
				disabled={!(data?.title?.length > 0) || !isFormTouched}
				hideActions={formTab === FORM_TABS[2].value || !isFormTouched}
				headerRight={
					<React.Fragment>
						{access.isCatalogueManagement && (
							<Button
								classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
								clickHandler={handleArchRestData}
							>
								{data.isActive ? "Archive" : "Restore"}
							</Button>
						)}
						<div className="help-btn-container">
							<Button clickHandler={handlePiperAcademy} type="secondary">
								<HelpIcon />
								<span>Help</span>
							</Button>
						</div>
					</React.Fragment>
				}
			>
				<Topbar
					tabs={FORM_TABS}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop={true}
				/>
				<div>
					{formTab === FORM_TABS[0].value && (
						<BasicDetails
							data={data}
							validations={error.fields || {}}
							readOnly={!access.isCatalogueManagement}
							handleForm={handleForm}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<RegularSchedule
							handleForm={handleForm}
							timeSlots={timeSlots}
							readOnly={!access.isCatalogueManagement}
							handleSelectDays={handleSelectDays}
							handleTimeSlots={handleTimeSlots}
							handleDeleteTimeSlot={handleDeleteTimeSlot}
							handleDeleteSingleTiming={handleDeleteSingleTiming}
							isCustomScheduleSelected={isCustomScheduleSelected}
							isEditFlow
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<LocationAssociation
							entityId={parseInt(match.params.id)}
							entityType={"TIMING_GROUP"}
							associatedLocations={timingGroupDetails?.data?.slotsRelatedLocations?.objects}
							refetchAssociations={handleRefetchLocationAssociation}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[9]}
						entityName={data.title}
						object={archRestData}
						mode={data.isActive ? "archive" : "restore"}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
					/>
				</div>
			</FormSidebar>
		</div>
	);
};
const mapStateToProps = (store) => ({
	timingGroupDetails: store.timingGroupDetails,
	access: store.login.loginDetail.access,
	configItems: store.configItems,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(TimingGroupEdit);
