import React, { useState, useEffect } from "react";

// components
import BrandSelection from "../components/Login/BrandSelection";
import { Button } from "../components/_commons/Button";

// third party
import queryString from "query-string";
import PubSub from "pubsub-js";
import { connect } from "react-redux";
import history from "../history";

// client
import { client } from "../client";
import { resetClientOnboarding } from "../client-onboarding";
import { store } from "../store/configureStore";

// utils
import { removeProp, lS, isLoggedin, generateUniqueUserId, snakiseObject, isChristmasSeason } from "../atlas-utils";
import { IDENTIFY_AND_REGISTER, TRACK_EVENT } from "../atlas-utils/tracking";

// graphql
import { AUTH_SERVICE_LOGIN } from "../graphql/authServiceLogin";

// actions
import { fetchBrands, updateBizLogo, getLowPiperCreditsBalanceAlert } from "../actions/actions";
import { ActionTypes } from "../actions/_types";

// constants
import { TOPIC_LIST_TOP_PERFORMING, MIN_PIPER_CREDITS_BALANCE, PIPER_CREDITS_BALANCE_LIMIT } from "../client-config";
import { LOGIN_WORKFLOW } from "./Login";
const ULE_LOGIN_URL = process.env.REACT_APP_ULE_LOGIN;

const AuthServiceLogin = ({ location, brands }) => {
	const [stepNum, setStepNum] = useState(LOGIN_WORKFLOW[0]);
	const [loading, setLoading] = useState(true);
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [error, setError] = useState(undefined);
	const qs = queryString.parse(location.search);

	useEffect(() => {
		handleLogin(qs?.access_token);
	}, [location]);

	const handleLogin = async (token = undefined) => {
		if (!token) {
			setError("Invalid Token");
			setLoading(false);
			return;
		}
		setLoading(true);
		try {
			const variables = {
				token
			};
			const resp = await client.query({
				query: AUTH_SERVICE_LOGIN,
				variables,
				fetchPolicy: "no-cache"
			});
			if (resp.data.loginAuthServiceAccess.status.success) {
				await updateCache(resp.data.loginAuthServiceAccess);

				// create/reset clientOnboarding
				await resetClientOnboarding();

				setIsAuthenticated(true);
				if (isLoggedin()) {
					updateBizLogo();
				}
			} else {
				// handle error message
				const error =
					resp.data.loginAuthServiceAccess.status.messages?.[0]?.message ||
					"There was an error during authentication.";
				// logout if already logged in
				lS.remove("auth");
				lS.remove("brand");
				store.dispatch({
					type: "USER_LOGOUT"
				});
				await client.cache.reset();
				setError(error);
				setIsAuthenticated(false);
			}
		} catch (error) {
			console.log(error);
			setError("There was an error during authentication.");
			setIsAuthenticated(false);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const updateCache = async (data) => {
		let loginDetail = {
			success: true,
			id: data.userInfo.id,
			name: data.userInfo.fullName,
			firstName: data.userInfo.firstName,
			lastName: data.userInfo.lastName,
			fullName: data.userInfo.fullName,
			token: data.token,
			username: data.userInfo.username,
			cannySsoToken: data.cannySsoToken,
			email: data.userInfo.uleEmail || data.userInfo.email,
			phone: data.userInfo.ulePhone || data.userInfo.phone,
			isdCode: data.userInfo.uleIsdCode || data.userInfo.isdCode,
			access: data.userInfo.access,
			associatedLocations: data.userInfo.associatedLocations || [],
			verificationDetails: data.userInfo.verificationDetails,
			internalUser:
				data.userInfo?.uleEmail?.includes("urbanpiper.com") || data.userInfo?.email?.includes("urbanpiper.com")
					? true
					: false
		};

		let loggedInbizDetail = data.biz;
		let creditsCost = data.creditsCost;
		let creditsMapping = data.creditsMapping;
		let authServiceData = data.authServiceData;
		let allCreditsCost = data.allCreditsCost;

		// banner message
		let showCustomBannerMessage = false;
		let isBannerMessageDismissed = { state: false, timeStamp: null };
		let banner = {};

		if (data.biz.scheduledActionsAndNotifications?.notifications?.length > 0) {
			const notification = data.biz.scheduledActionsAndNotifications?.notifications?.[0];
			showCustomBannerMessage = notification?.message ? notification?.applicable : false;
			banner = {
				topic: notification?.action,
				icon: "info",
				message: notification?.message,
				classes: notification?.type === "info" ? "default" : notification?.type,
				isDismissible: notification?.dismissible,
				trackEvent: false
			};
		} else if (
			(data.biz.credits < MIN_PIPER_CREDITS_BALANCE &&
				(loginDetail?.access?.isAdmin || loginDetail?.access?.isBilling)) ||
			data.biz.credits <= PIPER_CREDITS_BALANCE_LIMIT
		) {
			showCustomBannerMessage = true;
			banner = getLowPiperCreditsBalanceAlert(data.biz.credits);
		}

		// update store
		store.dispatch({
			type: ActionTypes.UPDATE_BANNER_STATE,
			payload: { ...banner }
		});
		store.dispatch({
			type: ActionTypes.LOGIN_SUCCESS,
			payload: {
				loginDetail,
				loggedInbizDetail,
				creditsCost,
				creditsMapping,
				allCreditsCost,
				authServiceData,
				uleLogin: true,
				showCustomBannerMessage,
				isBannerMessageDismissed
			}
		});

		// set showCustomBannerMessage, isBannerMessageDismissed and banner in local storage
		window.lS.set("showCustomBannerMessage", showCustomBannerMessage);
		window.lS.set("isBannerMessageDismissed", isBannerMessageDismissed);
		window.lS.set("banner", banner);

		const { modulesEnabled, supportedLanguages, isMultibrandEnabled, isMenuOverCatalogueEnabled } =
			loggedInbizDetail;
		let { productType } = store.getState().selectedModule;

		let modules_enabled = {};
		modulesEnabled.forEach((module) => (modules_enabled[module] = true));

		let languages_enabled = {};
		supportedLanguages.map((lang) => (languages_enabled[lang.valueForDisplay] = true));

		let eventMeta = {
			biz_id: loggedInbizDetail.id,
			biz_name: loggedInbizDetail.name,
			name: data.userInfo.fullName || data.userInfo.username || null,
			email: data.userInfo.uleEmail || data.userInfo.email || null,
			phone: data.userInfo.ulePhone || data.userInfo.phone || null,
			isd_code: data.userInfo.uleIsdCode || data.userInfo.isdCode || null,
			biz_country: loggedInbizDetail.country,
			biz_currency: loggedInbizDetail.currency,
			modules_enabled_via_atlas: modules_enabled,
			languages_enabled_atlas: languages_enabled,
			atlas_roles: snakiseObject(removeProp(data.userInfo.access, "__typename")),
			internal_user:
				data.userInfo?.uleEmail?.includes("urbanpiper.com") || data.userInfo?.email?.includes("urbanpiper.com")
					? true
					: false,
			is_multibrand: isMultibrandEnabled,
			is_mocEnabled: isMenuOverCatalogueEnabled,
			is_atlas_user: true,
			source: "Atlas"
		};

		// register and track this user in mixpanel
		PubSub.publish(IDENTIFY_AND_REGISTER, {
			uniqueId: generateUniqueUserId(eventMeta.email, eventMeta.isd_code, eventMeta.phone, qs?.auth || undefined),
			eventMeta
		});
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName: "login",
			eventMeta: {
				user_name: data.userInfo.username,
				user_email: data.userInfo.uleEmail || data.userInfo.email,
				user_phone: data.userInfo.ulePhone || data.userInfo.phone,
				isd_code: data.userInfo.uleIsdCode || data.userInfo.isdCode
			}
		});

		// update atlas onboarding state
		store.dispatch({
			type: ActionTypes.GET_ATLAS_ONBOARDING_STATE,
			payload: {
				isLoading: false,
				flowData: lS.get("onboardingFlow")?.[loggedInbizDetail.id]?.flowData || {},
				flowSteps: lS.get("onboardingFlow")?.[loggedInbizDetail.id]?.flowSteps || []
			}
		});

		// update default topic for topPerforming query
		if (productType === "LOYALTY") {
			store.dispatch({
				type: "DASHBOARD_STATE_CHANGE",
				payload: {
					topPerfTopic: TOPIC_LIST_TOP_PERFORMING[5]
				}
			});
		} else {
			store.dispatch({
				type: "DASHBOARD_STATE_CHANGE",
				payload: {
					topPerfTopic: TOPIC_LIST_TOP_PERFORMING[0]
				}
			});
		}
		window.lS.set("auth", {
			...data,
			userInfo: {
				...data.userInfo,
				email: data.userInfo.uleEmail,
				phone: data.userInfo.ulePhone,
				isdCode: data.userInfo.uleIsdCode
			}
		});
		// set ule login flag in local storage
		window.lS.set("uleLogin", true);
		if (isMultibrandEnabled) {
			const brands = await fetchBrands("", true);
			if (brands?.length > 1 && !loginDetail?.access?.isNonHqReport && !loginDetail?.access?.isNonHqAnalytics) {
				setStepNum(LOGIN_WORKFLOW[1]);
			} else {
				// now route to main landing page
				window.lS.set("brand", brands?.[0] || null);
				store.dispatch({
					type: ActionTypes.UPDATE_SELECTED_BRAND,
					payload: brands?.[0] || null
				});
				history.push("/");
			}
		} else {
			// now route to main landing page
			window.lS.set("brand", null);
			store.dispatch({
				type: ActionTypes.UPDATE_SELECTED_BRAND,
				payload: null
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Login successful!",
					timeout: 3000,
					error: false
				}
			});
			history.push("/");
		}

		// handle snowfall animation during christmas season
		if (isChristmasSeason()) {
			store.dispatch({
				type: ActionTypes.UPDATE_SNOWFALL_STATE,
				payload: {
					active: true
				}
			});

			// stop animation after 5 seconds
			setTimeout(() => {
				store.dispatch({
					type: ActionTypes.UPDATE_SNOWFALL_STATE,
					payload: {
						active: false
					}
				});
			}, 5000);
		}
	};

	const handleOpenUrl = (url, target = "_self") => {
		if (url) {
			window.open(url, target).focus();
		}
	};

	return (
		<div className={`${stepNum.value === LOGIN_WORKFLOW[0].value ? "auth-service" : "new"}-login-container`}>
			{stepNum.value === LOGIN_WORKFLOW[0].value && (
				<div className="page-container">
					<div className="user-form-container">
						{loading ? (
							<div className="header">
								<div className="text text--small">Authenticating...</div>
							</div>
						) : !isAuthenticated ? (
							<div className="header">
								<div className="text text--large">{`Login failed${
									error ? `, ${error.toLowerCase()}` : ""
								}`}</div>
								<Button clickHandler={() => handleOpenUrl(ULE_LOGIN_URL)}>Go Back</Button>
							</div>
						) : (
							<div className="header">
								<div className="text text--small">Authenticating...</div>
							</div>
						)}
					</div>
				</div>
			)}
			{stepNum.value === LOGIN_WORKFLOW[1].value && <BrandSelection brands={brands} />}
		</div>
	);
};
export default connect((store) => ({
	brands: store.configItems.brands
}))(AuthServiceLogin);
