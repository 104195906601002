import React, { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Header from "../../../components/Periscope/EarlyAccess/Header";
import {
	fetchConfigurationValues,
	fetchConfigurationValuesForPeriscope,
	periscopeJoinWaitlist,
	saveConfigurationValues
} from "../../../actions/periscope";
import { connect } from "react-redux";
import { lS, trackEvent } from "../../../atlas-utils";

const EarlyAccess = ({
	login,
	periscopeEarlyAccessState,
	periscopeFeatureFlag,
	showWaitlistCTA,
	access = false,
	periscopeEarlyAccessData
}) => {
	const history = useHistory();
	const earlyAccess = access;
	const waitlist = periscopeEarlyAccessState?.waitlist?.bizId || lS?.get("periscope")?.waitlist;
	const variables = {
		bizId: String(login?.loggedInbizDetail?.id),
		bizName: login?.loggedInbizDetail?.name,
		email: login?.loginDetail?.email,
		mobile: login?.loginDetail?.phone,
		isdCode: login?.loginDetail?.isdCode,
		country: login?.loggedInbizDetail?.country
	};

	const fullStar = <img src="/assets/periscope/yellow-star.svg" className="star" />;
	const star = <img src="/assets/periscope/star.svg" className="star" />;

	const enablePeriscopeEarlyAccess = () => {
		window.posthog.updateEarlyAccessFeatureEnrollment("periscope-beta", true);
	};
	const isPeriscopeEnabled = (periscopeEarlyAccessData?.configurationValues || []).filter((value) => {
		return value.key === "periscope_enabled";
	})?.[0];
	if (isPeriscopeEnabled?.value == "1") {
		history.push("/periscope/early-access/get-started");
	}

	const handleClick = () => {
		trackEvent("periscope_free_trial_interest_click");
		if (isPeriscopeEnabled?.value == "1") {
			history.push("/periscope/early-access/get-started");
		} else {
			history.push("/periscope/early-access/free-trial");
		}
	};

	useEffect(() => {
		isPeriscopeEnabled?.value !== "1" &&
			fetchConfigurationValuesForPeriscope({
				keys: ["periscope_enabled"]
			});
	}, []);
	const joinWaitList = () => {
		periscopeJoinWaitlist(variables);
		enablePeriscopeEarlyAccess();
		trackEvent("periscope_waitlist_joined");
	};

	return (
		<div className="periscope-container">
			<div className="section-container-common periscope-early-access " data-testid="early-access">
				<Header showWaitlistCTA={showWaitlistCTA} />
				<div className="early-access-container">
					<div className="early-access-text">
						<div className="info-text">
							{earlyAccess && periscopeFeatureFlag !== undefined ? (
								<div className="free-access">
									<img src="/assets/periscope/gift-blue.svg" />
									<span className="access-text">GET FREE TRIAL ACCESS</span>
								</div>
							) : (
								""
							)}
							<div className="heading-text">
								Get real time customer facing view of your delivery platforms
							</div>
							<div className="para-text">
								Periscope lets you view your restaurant's availability status, ratings, and menu issues
								in real-time - all from a single dashboard.
							</div>
						</div>

						{periscopeFeatureFlag === undefined ? (
							<></>
						) : earlyAccess ? (
							<>
								{waitlist ? (
									<div className="verified">
										<img src="/assets/periscope/verified.svg" className="verified-img" />
										<div>
											<div className="verified-bold">You are on the waitlist!</div>
											<div className="verified-para">
												We will notify you when Periscope is live
											</div>
										</div>
									</div>
								) : (
									<>
										{showWaitlistCTA && (
											<button className="access-button" onClick={() => joinWaitList()}>
												Join The Waitlist
											</button>
										)}
									</>
								)}
							</>
						) : (
							<button className="access-button" onClick={() => handleClick()}>
								Get Free Trial Access
							</button>
						)}
					</div>
					<div className="early-access-images">
						<div className="first-set-images">
							<div className="mushroom-pizza" style={{ maxWidth: "225.01px", height: "fit-content" }}>
								<div className="deliver">
									<div className="mushroom-main">
										<img className="mushroom-img" src="/assets/periscope/pizza.svg" />
										<span className="text">MUSHROOM PIZZA</span>
									</div>
									<div className="location-ratings">
										<div className="yellow-rating">
											<img src="/assets/periscope/yellow-exclamatory.svg" />
											<span>Poor Rating</span>
										</div>
										<div className="text">
											<span className="normal-text">on</span>
											<span className="bold-text"> Deliveroo</span>
											<span className="normal-text"> at</span>
											<span className="bold-text"> 3 Locations</span>
										</div>
									</div>
								</div>
								<div className="mushroom-ratings">
									<div className="view-ratings">
										<span className="location-text">Downtown</span>
										<span>
											{[...Array(2)].map((index, i) => {
												return <React.Fragment key={i}>{fullStar}</React.Fragment>;
											})}
											{[...Array(3)].map((index, i) => {
												return <React.Fragment key={i}>{star}</React.Fragment>;
											})}
										</span>
									</div>
									<div className="view-ratings">
										<span className="location-text">City Centre Mall</span>
										<span>
											{[...Array(1)].map((index, i) => {
												return <React.Fragment key={i}>{fullStar}</React.Fragment>;
											})}
											{[...Array(4)].map((index, i) => {
												return <React.Fragment key={i}>{star}</React.Fragment>;
											})}
										</span>
									</div>
									<div className="view-ratings">
										<span className="location-text">100Ft Road</span>
										<span>
											{[...Array(1)].map((index, i) => {
												return <React.Fragment key={i}>{fullStar}</React.Fragment>;
											})}
											<img src="/assets/periscope/half-yellow-star.svg" className="star" />
											{[...Array(3)].map((index, i) => {
												return <React.Fragment key={i}>{star}</React.Fragment>;
											})}
										</span>
									</div>
								</div>
							</div>
							<div className="right-side-images">
								<div className="offers-coupon" style={{ width: "190.77px" }}>
									<div className="orion-mall">
										<img src="/assets/periscope/percent.svg" />
										<div className="mall-text">
											<span className="bold-text">2 offers </span>
											<span> running at </span>
											<span className="bold-text"> Orion Mall </span>
										</div>
									</div>
									<div className="orion-discounts">
										<span className="coupon-border">BOGO200</span>
										<span className="offer-text">Buy 1 Get 1 Free</span>
									</div>
									<div className="orion-discounts">
										<span className="coupon-border">BOGO200</span>
										<span className="offer-text">Buy 1 Get 1 Free</span>
									</div>
								</div>
								<div className="burger" style={{ width: "262.71px" }}>
									<div className="item-title">
										<img className="burger-img" src="/assets/periscope/burger.svg" />
										<span className="burger-text">FRIED CHICKEN BURGER</span>
									</div>
									<div className="burger-info">
										<div style={{ display: "flex", gap: "5.54px", flexDirection: "column" }}>
											<div className="availability">
												<img src="/assets/periscope/red-exclamatory.svg" />
												<span>Not Available </span>
											</div>
											<div className="locations-text">
												<span className="normal-text">on</span>
												<span className="bold-text">UberEats</span>
												<span className="normal-text">at</span>
												<span className="bold-text">32 Locations</span>
											</div>
										</div>
										<div className="view-locations">
											<span> View Locations </span>
											<img
												src="../../../assets/periscope/right-arrow.svg"
												className="right-arrow"
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="second-set-images">
							<div className="city-centers">
								<div className="city-center-mall-platform">
									<div className="city-center">
										<img src="/assets/periscope/brown-storefront.svg" />
										<span className="bold-text">CITY CENTRE MALL</span>
									</div>
									<div className="all-platforms">
										<div className="platform border-right">
											<div className="platform-star green">
												<span className=" rating-text">4.2</span>
												<img src="/assets/periscope/green-star.svg" />
											</div>
											<span className="platform-text">UberEats</span>
										</div>
										<div className="platform border-right">
											<div className="platform-star green">
												<span className=" rating-text">4.2</span>
												<img src="/assets/periscope/green-star.svg" />
											</div>
											<span className="platform-text">Swiggy</span>
										</div>
										<div className="platform border-right">
											<div className="platform-star red">
												<span className="  rating-text ">4.2</span>
												<img src="/assets/periscope/red-star.svg" />
											</div>
											<span className="platform-text">Zomato</span>
										</div>
										<div className="platform">
											<div className="platform-star green">
												<span className=" rating-text ">4.2</span>
												<img src="/assets/periscope/green-star.svg" />
											</div>
											<span className="platform-text">Zomato</span>
										</div>
									</div>
								</div>
								<div className="issues-info">
									<div className="mall-info">
										<img src="/assets/periscope/issues-book.svg" />
										<span className="city-center-name">CITY CENTRE MALL</span>
									</div>
									<div className="info-text">
										<div className="justify-between">
											<div className="platform-info-display">
												<img src="/assets/periscope/item-issues.svg" className="issue-image" />
												<span>2 items missing description</span>
											</div>
											<img src="/assets/icons/platform-uber-eats.svg" className="platform-img" />
										</div>
										<div className="justify-between">
											<div className="platform-info-display">
												<img src="/assets/periscope/item-issues.svg" className="issue-image" />
												<span>3 items missing images</span>
											</div>
											<img src="/assets/icons/icon-meraki.svg" className="platform-img" />
										</div>
									</div>
								</div>
							</div>
							<div className="mall-platforms">
								<div className="para-one">
									<div className="header-mall">
										<img src="/assets/periscope/storefronts-grey.svg" className="storefront-mall" />
										<span className="orion-text">ORION MALL</span>
									</div>
									<div className="active-menus">
										<div className="green-circle"> </div>
										<span>3 Menus Active</span>
									</div>
								</div>

								<div className="platforms-display">
									<div className="all-platforms-between">
										<div className="view-platform">
											<img src="/assets/icons/platform-icon-zomato.svg" className="plt-img" />
											<span>Zomato</span>
										</div>
										<div className="show-menu">
											<span>Show Menu</span>
											<img src="/assets/periscope/right-arrow-black.svg" className="menu-icon" />
										</div>
									</div>
									<div className="all-platforms-between">
										<div className="view-platform">
											<img src="/assets/icons/platform-uber-eats.svg" className="plt-img" />
											<span>UberEats</span>
										</div>
										<div className="show-menu">
											<span>Show Menu</span>
											<img src="/assets/periscope/right-arrow-black.svg" className="menu-icon" />
										</div>
									</div>
									<div className="all-platforms-between">
										<div className="view-platform">
											<img src="/assets/icons/platform-swiggy.svg" className="plt-img" />
											<span>Swiggy</span>
										</div>
										<div className="show-menu">
											<span>Show Menu</span>
											<img src="/assets/periscope/right-arrow-black.svg" className="menu-icon" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="background-image"></div>
					<img src="/assets/periscope/frame-girl.svg" className="frame-girl" />
				</div>
				<div className="details">
					<div className="specific-one">
						<img src="../../../assets/periscope/location.svg" className="info-img" />
						<div className="text">
							<div className="heading">Monitor Availability</div>
							<span className="info-text">
								Easily identify offline locations and out of stock items across platforms
							</span>
						</div>
					</div>
					<div className="specific-one">
						<img src="../../../assets/periscope/black-storefront.svg" className="info-img" />
						<div className="text">
							<div className="heading">Monitor your Menus</div>
							<span className="info-text">
								Preview customer facing menus on multiple platforms from a single screen{" "}
							</span>
						</div>
					</div>
					<div className="specific-one">
						<img src="../../../assets/periscope/info-black.svg" className="info-img" />
						<div className="text">
							<div className="heading">Monitor Issues </div>
							<span className="info-text">
								Quickly identify menu items missing images or descriptions across platforms
							</span>
						</div>
					</div>
					<div className="specific-one">
						<img src="../../../assets/periscope/location.svg" className="info-img" />
						<div className="text">
							<div className="heading">Monitor Ratings</div>
							<span className="info-text">
								Spot poorly rated locations and items and see what’s performing well{" "}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	periscopeEarlyAccessState: state.periscopeEarlyAccessState,
	periscopeEarlyAccessData: state.periscopeEarlyAccessData,
	login: state?.login
});

export default connect(mapStateToProps)(EarlyAccess);
