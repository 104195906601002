import React, { useState, useRef } from "react";

// components
import BulkOperation from "../_commons/BulkOperation";
import { BulkOperationSuccess } from "../_commons/BulkOperationSuccess";
import { BulkOperationFailure } from "../_commons/BulkOperationFailure";
import { SelectFilter } from "../_commons/SelectFilter";
import { TagsInputWrapper } from "../_commons/TagsInputWrapper";
import { Textarea } from "../_commons/Textarea";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { downloadTemplate, handleBulkExportDownload } from "../../actions/actions";

// utils
import history from "../../history";
import { lS, scroll } from "../../atlas-utils";

// constatnt
const RELOAD_TYPES = [
	{ label: "Promotional", value: "promotional" },
	{ label: "Refund", value: "refund" }
];
const ACTION_TYPES = [
	{ label: "Validate", value: "validate" },
	{ label: "Process", value: "process" }
];
const data = [
	{
		fieldName: "Phone",
		description:
			"Phone number of customer for whom the wallet is to be reloaded. Country code followed by phone number. For example if country code is 91 for India and phone is 9090909090, then valid input is 919090909090",
		isRequired: true,
		acceptedValues: "Numerical values"
	},
	{
		fieldName: "Amount",
		description: "Decimal value. The amount of money to be added to the customer's wallet",
		isRequired: true,
		acceptedValues: "Decimal format"
	}
];

const BulkCustomers = (props) => {
	const [downloading, setDownloading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [csvFile, setCsvFile] = useState(undefined);
	const [uploadedFileName, setUploadedFileName] = useState(undefined);
	const [validation, setValidation] = useState(undefined);
	const [bulkOpState, setBulkOpState] = useState("default");
	const [headerFields, setHeaderFields] = useState(undefined);
	const [failureResponse, setFailureResponse] = useState(undefined);
	const [failureData, setFailureData] = useState(undefined);
	const [successData, setSuccessData] = useState(undefined);
	const [downloadAction, setDownloadAction] = useState(false);
	const [currEmail, setCurrEmail] = useState("");
	const [uploadFormData, setUploadFormData] = useState({
		action: ACTION_TYPES[0],
		reload_type: RELOAD_TYPES[0],
		recipients: [],
		comments: ""
	});
	const [formValidations, setFormValidations] = useState({
		recipients: "",
		comments: ""
	});
	const topRef = useRef();
	const bottomRef = useRef();

	const currLocation = {
		pathname: props.location.pathname,
		title: "Bulk actions on customers"
	};
	const prevLocation = {
		pathname: "/customers",
		title: "Customers"
	};

	const handleBackAction = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
	};

	const handleUploadNewFile = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");

		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleSaveChanges = () => {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Changes saved successfuly",
				timeout: 2000,
				error: false
			}
		});
		setTimeout(() => {
			history.push("/customers");
		}, 100);
	};

	const csvToJson = (data) => {
		let sanitisedData = data
			.split("\n")
			.map((row) => row.trim())
			.filter((row) => row !== "")
			.map((row) => row.split(","));
		const headers = sanitisedData[0].map((header) => header.toLowerCase().split(" ").join("_"));

		const fields = {};
		headers.map((header) => {
			fields[header] = true;
		});
		setHeaderFields(fields);

		const tableData = [];
		if (headers.includes("invalid_headers")) {
			let obj = {
				invalid_headers: sanitisedData[1].join(", ")
			};
			tableData.push(obj);
		} else {
			sanitisedData.map((row, i) => {
				if (i !== 0) {
					let obj = {};
					headers.map((column, j) => {
						obj[column] = row[j];
					});
					tableData.push(obj);
				}
			});
		}
		setFailureData(tableData);
	};

	const auth = lS.get("auth");

	const exportTemplate = async (emails, entity) => {
		setDownloading(true);
		const modelName = "optiongroup";
		await handleBulkExportDownload(modelName, emails, entity);
		setDownloading(false);
	};

	const handleTemplateDownload = async () => {
		setDownloading(true);
		const modelName = "wallet-reload";
		await downloadTemplate(modelName);
		setDownloading(false);
	};

	const downloadErrorFile = () => {
		setDownloading(true);
		const file = new Blob([failureResponse], { type: "text/csv" });

		// process to auto download it
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download = `Customers-Errors.csv`;
		link.click();
		setDownloading(false);
	};

	const handleUploadFile = async (e) => {
		let newFormValidations = {};
		if (uploadFormData.recipients.length === 0) {
			newFormValidations.recipients = "Atleast one email must be added";
		}
		if (uploadFormData.comments.length <= 6) {
			newFormValidations.comments = "More than 6 characters required";
		}
		if (Object.keys(newFormValidations).length !== 0) {
			setFormValidations(newFormValidations);
			return;
		} else {
			setFormValidations({
				recipients: "",
				comments: ""
			});
		}

		setUploading(true);
		setValidation(undefined);

		if (!csvFile) {
			var files = await e.target.files;
			setCsvFile(files[0]);
			setUploadedFileName(files[0].name);
			var formData = new FormData();
			formData.append("sheet", files[0]);
			formData.append("action", uploadFormData.action.value);
			formData.append("reload_type", uploadFormData.reload_type.value);
			formData.append("recipients", uploadFormData.recipients.join(","));
			formData.append("comments", uploadFormData.comments);
		} else {
			var formData = new FormData();
			formData.append("sheet", csvFile);
			formData.append("action", uploadFormData.action.value);
			formData.append("reload_type", uploadFormData.reload_type.value);
			formData.append("recipients", uploadFormData.recipients.join(","));
			formData.append("comments", uploadFormData.comments);
		}

		try {
			const res = await fetch(process.env.REACT_APP_API_URL_WALLET_RELOAD, {
				method: "POST",
				headers: { Authorization: auth && `Bearer ${auth.token}` },
				body: formData
			});
			const contentType = res.headers.get("content-type");
			const status = res.status;

			if (contentType === "text/csv" && status === 200) {
				const data = await res.text();
				setFailureResponse(data);
				csvToJson(data);
				setDownloadAction(false);
				setBulkOpState("failure");

				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please correct the errors and try again",
						timeout: 2000,
						error: true
					}
				});
			} else if (contentType === "application/json") {
				const data = await res.json();
				if (status === 200) {
					const tableData = [{ message: data.message }];
					setSuccessData(tableData);
					setBulkOpState("success");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: data.message,
							timeout: 2000,
							error: false
						}
					});
				} else if (status === 400) {
					const fields = { reason: true };
					setHeaderFields(fields);

					const tableData = [{ reason: data.message }];
					setFailureData(tableData);

					setDownloadAction(true);
					setBulkOpState("failure");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please correct the errors and try again",
							timeout: 2000,
							error: true
						}
					});
				}
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
		setUploading(false);
	};

	const handleUploadForm = (field, value) => {
		setUploadFormData({
			...uploadFormData,
			[field]: value
		});
	};

	const handleCurrEmail = (val) => {
		setCurrEmail(val);
		if (formValidations.recipients) {
			setFormValidations({
				...formValidations,
				recipients: ""
			});
		}
	};

	const renderUploadForm = () => {
		return (
			<React.Fragment>
				<div className="form-row row-half">
					<SelectFilter
						title="Action"
						options={ACTION_TYPES}
						field="action"
						currValue={uploadFormData.action}
						setFilter={handleUploadForm}
						requiredLabel={true}
						isClearable={false}
					/>
					<SelectFilter
						title="Reload Type"
						options={RELOAD_TYPES}
						field="reload_type"
						currValue={uploadFormData.reload_type}
						setFilter={handleUploadForm}
						requiredLabel={true}
						isClearable={false}
					/>
				</div>
				<div className="form-row row-full">
					<TagsInputWrapper
						tags={uploadFormData.recipients}
						onChange={(tags) => handleUploadForm("recipients", tags)}
						tagInput={currEmail}
						onChangeInput={handleCurrEmail}
						requiredLabel={true}
						placeholder={"Enter an email"}
						validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
						onValidationReject={() =>
							setFormValidations({
								...formValidations,
								recipients: "Please enter a valid email"
							})
						}
						validationMessage={formValidations.recipients}
						showDropdown={true}
					>
						Recipient Emails
					</TagsInputWrapper>
				</div>
				<div className="form-row row-full">
					<Textarea
						value={uploadFormData.comments}
						onChange={(e) => handleUploadForm("comments", e.target.value)}
						requiredLabel={true}
						validationMessage={formValidations.comments}
					>
						Comments
					</Textarea>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div>
			{bulkOpState === "default" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperation
							title="Bulk actions on customers"
							subTitle="Perform bulk updates to your customers"
							hideExportAction={true}
							downloadTitle="Download Template"
							downloadTemplate={handleTemplateDownload}
							exportTemplate={exportTemplate}
							data={data}
							downloading={downloading}
							uploading={uploading}
							handleUploadFile={handleUploadFile}
							uploadedFileName={uploadedFileName}
							renderUploadForm={renderUploadForm()}
							validation={validation}
							prevLocation={prevLocation}
							currLocation={currLocation}
						/>
					</div>
					<div ref={bottomRef}></div>
				</React.Fragment>
			)}
			{bulkOpState === "success" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationSuccess
							title="Bulk actions on customers"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							uploadedFileName={uploadedFileName}
							saveChanges={handleSaveChanges}
							data={successData || []}
						/>
					</div>
				</React.Fragment>
			)}
			{bulkOpState === "failure" && (
				<React.Fragment>
					<div className="back-navigation-arrow" onClick={handleBackAction}>
						<img src="/assets/icons/ic_left_arrow_blue.svg" />
						<span className="navigation-title">Back</span>
					</div>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationFailure
							title="Bulk actions on customers"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							downloading={downloading}
							hideDownloadAction={downloadAction}
							uploadedFileName={uploadedFileName}
							downloadErrorFile={downloadErrorFile}
							uploadNewFile={handleUploadNewFile}
							headerFields={headerFields || {}}
							data={failureData || []}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
export default BulkCustomers;
