import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../../store/configureStore";

import moment from "moment";
// components
import { CustomTable } from "../_commons/CustomTable";
import { Paginator } from "../_commons/Paginator";
import StatusAlertBox from "../../components/_commons/StatusAlertBox";
import FixErrorsDrawer from "../MenuEdit/FixErrorsDrawer";

import { CATALOGUE_PLATFORMS_LOGO, TRACKING_EVENT_NAMES } from "../../client-config";
import { clientMenu } from "../../client-menu";

//actions
import { cancelScheduledPublish, fetchMenuDetails } from "../../actions/menus";

//types
import { ActionTypes } from "../../actions/_types";

// constants
import { PLATFORM_MAP } from "../Hub/Integrations";

//graphql
import { UPDATE_MENU } from "../../graphql/menus";

//utils
import { capitaliseText, trackEvent } from "../../atlas-utils";
// services
import NotificationServices from "../../services/NotificationService";

//actions
import {
	fetchMenusList,
	fetchParentSectionsList,
	getMenuPublishValidationStatus,
	getMenuPreviewValidationStatus
} from "../../actions/menus";
import Popover from "../_commons/Popover";
import { fetchFailedRequests } from "../../actions/dspIntegration";
import { Loading } from "../_commons/Loading";
import { fetchBizPlatforms } from "../../actions/actions";
import StatusInfo from "../_commons/StatusInfo";
import Placeholder from "../_commons/Placeholder";

// Define columns for the CustomTable
const getColumns = (dsp, handleFixMenu, resolvedLocations) => {
	return [
		{
			name: (
				<div className="platform">
					<img
						className="logo"
						src={CATALOGUE_PLATFORMS_LOGO["urbanpiper"] || CATALOGUE_PLATFORMS_LOGO["default"]}
						alt=""
					/>
					<div className="name">UrbanPiper Storefronts</div>
				</div>
			),
			field: "up-stores-non-dsp",
			render: (record, i) => {
				return (
					<div className="table-cell up-stores-non-dsp" key={i}>
						{record?.locationName || "--"}
					</div>
				);
			}
		},
		{
			name: <div className="name">Requested Menu</div>,
			field: "menu-display",
			render: (record, i, rest) => {
				const currMenu = rest?.menus?.items?.find((menu) => menu.menuId === record?.menuId);
				const currentMenuStatus = record?.menuAssociationStatus;
				const renderPopover = () => {
					return (
						<div className="popover-info">
							<div className="popover-header">
								{currentMenuStatus === "failed"
									? "The current request has failed"
									: currentMenuStatus === "in-progress"
										? "The current request is in progress."
										: currentMenuStatus === "success"
											? "The current request is successful."
											: " "}
							</div>
							{record?.menuVerificationMessage && (
								<div className="popover-message">{record?.menuVerificationMessage}</div>
							)}
						</div>
					);
				};

				return (
					<div className="table-cell menu-display" key={i}>
						<div className="menu-detail">
							{record?.menuName || currMenu?.name ? (
								<div
									className={"menu-detail"}
									onClick={async () => {
										//if (currentMenuStatus === "failed") {
										handleFixMenu(record);
										//}
									}}
								>
									{record?.imageUrl || currMenu?.imageUrl ? (
										<img className="menu-img" src={record.imageUrl} />
									) : (
										<img src="/assets/left-nav/icon-menus.svg" />
									)}
									<div
										className={
											currentMenuStatus === "failed"
												? "hyperlink hyperlink--black-color menu-name"
												: "menu-name"
										}
									>
										{record?.menuName || currMenu?.name}
									</div>
								</div>
							) : (
								<div className="menu-name text-light">No Menu Assigned</div>
							)}
							<div>
								{currentMenuStatus && (
									<Popover showOnHover renderPopover={renderPopover} position="down-right">
										{currentMenuStatus === "failed" && (
											<img src="/assets/icons/error-red-icon.svg" />
										)}
										{currentMenuStatus === "in-progress" && (
											<img src="/assets/icons/hourglass.svg" />
										)}
										{/* {resolvedLocations.includes(record?.id) && <img src="/assets/icons/resolve.svg" />} */}
									</Popover>
								)}
							</div>
						</div>
					</div>
				);
			}
		}
	];
};
const FixMenuError = ({
	menus,
	bizPlatforms,
	handlePageSize,
	dspIntegration = {},
	handlePagination,
	handleAssociationUpdates,
	isFixMenuOpen,
	setIsFixMenuOpen,
	menuDetailsState,
	parentSectionDetailsData,
	menuPublishValidationStatus
}) => {
	const [parentSectionSearchKey, setParentSectionSearchKey] = useState("");
	const { items } = bizPlatforms;
	const { platform, data } = dspIntegration;
	const { menuDetailsData } = menuDetailsState;
	const [isFixErrorDrawerOpen, setIsFixErrorDrawerOpen] = useState(false);
	const [menuId, setMenuId] = useState("");
	const [temporaryPersistedData, setTemporaryPersistedData] = useState();
	const [makeNewAssociations, setMakeNewAssociations] = useState(false);
	const [resolvedLocations, setResolvedLocations] = useState([]);
	const [failedRequestData, setFailedRequestData] = useState({});
	const [loading, setLoading] = useState(false);
	const [loadingPublishStatus, setLoadingPublishStatus] = useState(false);

	const [RTGLReviewFixes, setRTGLReviewFixes] = useState({
		state: true,
		menuId: "",
		//locationId:"",
		platformId: "",
		locationIdsRevalidated: [],
		success: null
	});

	useEffect(() => {
		fetchMenusList();
		if (bizPlatforms?.items.length === 0) fetchBizPlatforms();
	}, []);

	useEffect(() => {
		if (isFixMenuOpen) {
			setLoading(true);
			setLoadingPublishStatus(true);
			const fetchData = async () => {
				const data = await fetchFailedRequests();
				setFailedRequestData(data);
				setLoadingPublishStatus(false);
			};

			fetchData();

			setLoading(false);
		}
	}, [isFixMenuOpen, RTGLReviewFixes.success]);

	useEffect(() => {
		if (RTGLReviewFixes.success) {
			setResolvedLocations([...new Set([...resolvedLocations, ...RTGLReviewFixes.locationIdsRevalidated])]);

			NotificationServices.pushNotification({
				message: "Request successfully sent!",
				timeout: 5000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		}
	}, [RTGLReviewFixes.success]);

	useEffect(() => {
		if (!loading) {
			const currplatform = items.find((obj) => obj.platformName.toLowerCase() === platform.toLowerCase());
			const platformId = currplatform?.id || null;
			setRTGLReviewFixes({ ...RTGLReviewFixes, platformId: platformId });
		}
	}, [failedRequestData?.count, loading]);

	const handleFixMenu = async (record) => {
		setMenuId(record?.menuId);
		setRTGLReviewFixes({
			...RTGLReviewFixes,
			menuId: record?.menuId
			//locationId :locId
		});
		const STATUS_MAP = {
			PENDING: "in-progress",
			FAILURE: "failed",
			SUCCESS: "success"
		};
		const publishId = record?.traceId;
		const res = await getMenuPublishValidationStatus(record?.menuId, false, publishId);
		if (res?.status === "FAILURE" && !!res?.report) setIsFixErrorDrawerOpen(true);
		else if (res?.status === "PENDING")
			NotificationServices.pushNotification({
				message: "The request is under validation!",
				timeout: 3000,
				type: "info",
				isClosable: true,
				theme: "dark"
			});
		else if (res?.status === "SUCCESS")
			NotificationServices.pushNotification({
				message: `The request for ${record?.locationName} is verified and sent to ${capitaliseText(platform)}.`,
				timeout: 3000,
				type: "success",
				isClosable: true,
				theme: "dark"
			});
		else if (res?.status === "ERROR" || res?.status === "FAILURE")
			NotificationServices.pushNotification({
				message: "Some error occured while validating. Wait for sometime and retry publishing.",
				timeout: 3000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		// if(await res?.status){
		// 	data.objects.find((obj) =>
		// 		obj?.id === record.id
		// 	).associatedPlatforms.find((plt)=>plt.platformName===platform).menu.progress.menuAssociationStatus = STATUS_MAP[res.status]

		// 	console.log(data.objects.find((obj) =>
		// 		obj?.id === record.id
		// 	).associatedPlatforms.find((plt)=>plt.platformName===platform).menu.progress.menuAssociationStatus,res.status)

		// }
		fetchMenuDetails(record?.menuId);
		return res?.status;
	};

	const handleDetailsEditSection = (id, isOpen = false, refresh = false) => {
		if (!isOpen) {
			setTemporaryPersistedData();
			return;
		}
		setTemporaryPersistedData({
			isEditSectionMode: true,
			editSectionId: id
		});
	};

	const handleItemOverridesUpdate = async (itemInfo) => {
		try {
			const newOverrideEntities = {
				...itemInfo
			};
			delete newOverrideEntities.id;

			if (!newOverrideEntities?.image) {
				newOverrideEntities.image = null;
			}

			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: null
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) =>
						item.id === itemInfo?.id
							? {
									...item,
									overrides: {
										...item.overrides,
										...newOverrideEntities
									}
								}
							: { ...item, overrides: { ...item.overrides, image: null } }
					),
					categories: menuDetailsData.categories.map((category) => ({
						...category,
						overrides: {
							...category.overrides,
							image: null
						}
					}))
				}
			};

			const itemOverridesUpdateResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (itemOverridesUpdateResp?.data?.updateMenuV2) {
				NotificationServices.pushNotification({
					message: "Updated item details successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				trackEvent(TRACKING_EVENT_NAMES.MENU_ITEMS_BASIC_INFO_UPDATE, {
					status: "success"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: itemOverridesUpdateResp?.data?.updateMenuV2
				});
				fetchParentSectionsList(menuId);
				setParentSectionSearchKey("");
				handleItemEditClose();
				return true;
			}
		} catch (e) {
			NotificationServices.pushNotification({
				message: "Failed to modify item details",
				timeout: 5000,
				type: "failure",
				isClosable: true,
				theme: "dark"
			});
			trackEvent(TRACKING_EVENT_NAMES.MENU_ITEMS_BASIC_INFO_UPDATE, {
				status: "failure"
			});
			console.log(e);
		}
	};

	const handleItemEditClose = () => {
		setTemporaryPersistedData();
	};

	return (
		<div className="form-content menuFix">
			{data?.rtglProgress?.failed !== 0 && (
				<StatusInfo
					icon="info"
					status="info"
					color="#3b5feb"
					description="Resolve menu issues across all menus before submitting requests."
				/>
			)}

			{isFixErrorDrawerOpen && (
				<div className="menu-edit-container">
					<FixErrorsDrawer
						isOpen={isFixErrorDrawerOpen}
						menuTitle={menuDetailsData?.name}
						menuId={menuId}
						isFromMenuSection={false}
						handleDrawerClose={() => setIsFixErrorDrawerOpen(false)}
						handleItemOverridesUpdate={handleItemOverridesUpdate}
						isFixPublishMenuFlow
						isFromRTGLFixDrawer
						RTGLReviewFixes={RTGLReviewFixes}
						setRTGLReviewFixes={setRTGLReviewFixes}
						menuDetailsData={menuDetailsData}
						menuPublishValidationStatus={menuPublishValidationStatus}
						//menuPreviewValidationStatus={menuPreviewValidationStatus}
						parentSectionDetailsData={parentSectionDetailsData}
						handleDetailsEditSection={handleDetailsEditSection}
						publishType="instant"
					/>
				</div>
			)}

			{failedRequestData?.count === 0 && (
				<Placeholder
					placeholderImageUrl={"/assets/empty-states/office-meeting.svg"}
					placeholderText={"Request sent!"}
					placeholderSubtext={`Your request for review has been successfully sent.`}
				/>
			)}

			{failedRequestData?.count !== 0 && (
				<CustomTable
					bordered={true}
					loading={loading || loadingPublishStatus}
					isRowClickable={true}
					rowClickHandler={handleFixMenu}
					data={failedRequestData?.objects}
					columns={getColumns(platform, handleFixMenu, resolvedLocations)}
					handleAssociationUpdates={handleAssociationUpdates}
					menuPublishValidationStatus={menuPublishValidationStatus}
					showLoadingForce={loading}
					setIsFixMenuOpen={setIsFixMenuOpen}
					classes="locations-table-container fix-error-table-rtgl"
					content="locations"
					menus={menus}
					setMakeNewAssociations={setMakeNewAssociations}
					makeNewAssociations={makeNewAssociations}
				/>
			)}
			{failedRequestData?.count !== 0 && (
				<Paginator
					limit={failedRequestData?.limit}
					offset={failedRequestData?.offset}
					count={failedRequestData?.count || 0}
					goToPage={handlePagination}
					setPageSize={handlePageSize}
					showPageSize={true}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (store) => ({
	menuDetailsState: store.menuDetailsState,
	parentSectionDetailsData: store.parentSectionsState.parentSectionDetailsData,
	selectedParentSection: store.parentSectionsState.selectedParentSection,
	isSectionsLoading: store.parentSectionsState.isLoading,
	publishHistoryListsData: store.publishHistoryListsData,
	menuPreviewPlatformsLocationsList: store.menuPreviewPlatformsLocationsList,
	menuPublishValidationStatus: store.menuPublishValidationStatus,
	menus: store.configItems.menus,
	menusListState: store.menusListState,
	bizPlatforms: store.configItems.bizPlatforms,
	dspIntegration: store.dspIntegration,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	failedPublishRequest: store.failedPublishRequest
});
export default connect(mapStateToProps)(FixMenuError);
