import Lottie from "lottie-react";
import success from "../../lottie/success-icon.json";
import React from "react";
import LightningIcon from "../_icons/LightningIcon";
import InfoIcon from "../_icons/InfoIcon";
import { Button } from "./Button";

const FreeTrial = ({
	heading = "Get free trial",
	successIcon = false,
	handleCancel = () => {},
	handleSubmit = () => {},
	children,
	cancelBtn = false,
	cancelTitle = "Back",
	submitBtn = false,
	loading = false,
	submitTitle = "Submit",
	submitBtnLoading = false,
	submitClass,
	InfoIconColor = "#3B5FEB",
	infoText = "",
	cancelButtonWidth = 100,
	borderLeftColor = "#3b5feb",
	infoBgColor = "#f2f5ff",
	mainHeader = ""
}) => {
	return (
		<div className="free-trial">
			{mainHeader && <div className="main-header"> {mainHeader}</div>}
			<div className="free-trail-plan-wrap">
				<div className="free-trial-intro">
					<div className="heading">
						{heading}
						{successIcon && (
							<Lottie
								animationData={success}
								style={{
									width: "20px"
								}}
								loop={false}
							/>
						)}
					</div>
					<div className="modal-plan">
						<div className="free-trail">
							<LightningIcon fillColor="#6D7AA8" />
							<div>
								<span className="your-plan">Your plan</span> (Free Trial)
							</div>
						</div>
						<div className="all-plans">
							<div className="plan-benefits">YOUR PLAN BENEFITS</div>

							<div className="plans-info">{children}</div>
						</div>
					</div>

					{infoText && (
						<div
							className="info-div"
							style={{ borderLeft: `4px solid ${borderLeftColor}`, background: infoBgColor }}
						>
							<InfoIcon fill={InfoIconColor} /> {infoText}
						</div>
					)}

					<div className="btns">
						{cancelBtn && (
							<Button
								classes={
									`W(${cancelButtonWidth}px) Mend(10px) ` +
									(loading || submitBtnLoading ? "disabled" : "")
								}
								onClick={() => handleCancel()}
								type="secondary"
							>
								{cancelTitle}
							</Button>
						)}

						{submitBtn && (
							<Button
								loading={submitBtnLoading}
								classes={(loading || submitBtnLoading ? "disabled" : "") + submitClass}
								onClick={handleSubmit}
							>
								{submitTitle}
							</Button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default FreeTrial;
