import React, { useEffect, useState } from "react";

// components
import { FormContainer } from "../../containers/Meraki";
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Switch } from "../_commons/Switch";
import { SelectFilter } from "../_commons/SelectFilter";

// utils
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchMerakiWebSettings, editMerakiWebSettings } from "../../actions/merakiWeb";

// third party
import { connect } from "react-redux";

// constants
const DEFAULT_FULFILMENT_MODES = [
	{
		label: "Delivery",
		value: "delivery"
	},
	{
		label: "Pickup",
		value: "pickup"
	}
];
const IMAGE_SIZES = [
	{
		label: "Small",
		value: "small"
	},
	{
		label: "Medium",
		value: "medium"
	},
	{
		label: "Large",
		value: "large"
	}
];
const LOGIN_MODES = [
	{
		label: "Email",
		value: "email"
	},
	{
		label: "Phone",
		value: "phone"
	}
];

const Settings = ({ loginDetail }) => {
	const [loading, setLoading] = useState(true);
	const [settingsConfig, setSettingsConfig] = useState(undefined);
	const [isFormTouched, setFormTouched] = useState(false);

	const isInternalUser = loginDetail?.email.includes("@urbanpiper.com") || false;

	const fetchSettingsConfig = async () => {
		setLoading(true);
		try {
			const settingsConfig = await fetchMerakiWebSettings();
			setSettingsConfig(settingsConfig?.data);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		fetchSettingsConfig();
	}, []);

	const handleCancel = async () => {
		await fetchSettingsConfig();
		setFormTouched(false);
	};

	const handleForm = (field, value, parent) => {
		// extract value from dropdown select object
		if (["imageSize", "defaultMode", "primaryLogin"].includes(field)) {
			value = value.value;
		}
		const updatedAppsConfig = { ...settingsConfig };
		if (parent) {
			updatedAppsConfig[parent][field] = value;
		} else {
			updatedAppsConfig[field] = value;
		}
		setSettingsConfig(updatedAppsConfig);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		try {
			await editMerakiWebSettings(settingsConfig);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Settings saved!",
					timeout: 2000,
					error: false
				}
			});
			setFormTouched(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	if (loading && !settingsConfig) {
		return (
			<div style={{ margin: "25px" }}>
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		);
	}

	return (
		<div className={"settings-container" + (loading ? " disabled" : "")}>
			<FormContainer cancel={handleCancel} submit={handleSubmit} submitTitle="Save" hideActions={!isFormTouched}>
				<div className="form-content">
					<div className="form-row row-half">
						<Switch
							title="Enable delivery"
							tooltipInfo="Show delivery as an option for customers on the website UI"
							showCustomTooltip
							checked={settingsConfig?.deliveryPickup?.enableDelivery}
							clickHandler={() =>
								handleForm(
									"enableDelivery",
									!settingsConfig?.deliveryPickup?.enableDelivery,
									"deliveryPickup"
								)
							}
						/>
						<Switch
							title="Enable pickup"
							tooltipInfo="Show pickup as an option for customers on the website UI"
							showCustomTooltip
							checked={settingsConfig?.deliveryPickup?.enablePickup}
							clickHandler={() =>
								handleForm(
									"enablePickup",
									!settingsConfig?.deliveryPickup?.enablePickup,
									"deliveryPickup"
								)
							}
						/>
						<SelectFilter
							title="Default fulfilment mode"
							options={DEFAULT_FULFILMENT_MODES}
							currValue={
								DEFAULT_FULFILMENT_MODES.find(
									(mode) => mode.value == settingsConfig?.deliveryPickup?.defaultMode
								) || null
							}
							setFilter={(field, value) => handleForm("defaultMode", value, "deliveryPickup")}
							isSearchable={false}
							isClearable={false}
							labelKey="label"
							valueKey="value"
							tooltipInfo="Choose the fulfilment mode (delivery or pickup) to be pre-selected and shown on website"
							showCustomTooltip
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							title="Hide deliver/pickup now"
							tooltipInfo='Hide delivery/pickup now option on website and show only "deliver/pickup later"'
							showCustomTooltip
							checked={settingsConfig?.deliveryPickup?.hideDeliverPickupNow}
							clickHandler={() =>
								handleForm(
									"hideDeliverPickupNow",
									!settingsConfig?.deliveryPickup?.hideDeliverPickupNow,
									"deliveryPickup"
								)
							}
						/>
						<InputWithLabel
							value={settingsConfig?.deliveryPickup?.hideDeliverPickupNowAfterOffset}
							type="number"
							tooltipInfo="Set a time delay after which the delivery/pickup now options are hidden on website UI"
							showCustomTooltip
							onChange={(e) =>
								handleForm(
									"hideDeliverPickupNowAfterOffset",
									e.target.value ? parseFloat(e.target.value) : null,
									"deliveryPickup"
								)
							}
						>
							Hide deliver/pickup now after offset (Milliseconds)
						</InputWithLabel>
						<Switch
							title="Hide ordering time"
							showCustomTooltip
							tooltipInfo="Enable if you want users to choose a specific time (ex: 9:05AM) instead of pre-defined timeslots (ex: 9AM - 10AM)"
							checked={settingsConfig?.deliveryPickup?.hideOrderingTime}
							clickHandler={() =>
								handleForm(
									"hideOrderingTime",
									!settingsConfig?.deliveryPickup?.hideOrderingTime,
									"deliveryPickup"
								)
							}
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							title="Enable wallet"
							tooltipInfo="Show a wallet page for customers to view transactions and reload wallet"
							showCustomTooltip
							checked={settingsConfig?.wallet?.enable}
							clickHandler={() => handleForm("enable", !settingsConfig?.wallet?.enable, "wallet")}
						/>
						<InputWithLabel
							value={settingsConfig?.wallet?.minReloadBtnVal}
							type="number"
							tooltipInfo="Set the minimum amount customers must add to their wallet for reload"
							showCustomTooltip
							onChange={(e) =>
								handleForm("minReloadBtnVal", e.target.value ? Number(e.target.value) : null, "wallet")
							}
						>
							Min. wallet reload value
						</InputWithLabel>
						<Switch
							showCustomTooltip
							tooltipInfo="Enable to prevent customers from reloading their wallet"
							title="Disable wallet reload"
							checked={
								settingsConfig?.wallet?.disableReload !== undefined
									? settingsConfig?.wallet?.disableReload
									: false
							}
							clickHandler={() =>
								handleForm("disableReload", !settingsConfig?.wallet?.disableReload, "wallet")
							}
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							tooltipInfo="Allow users to place orders without logging into the website. Phone numbers entered by guest users are not validated by OTP"
							showCustomTooltip
							title="Guest checkout enable"
							checked={
								settingsConfig?.guestCheckout?.enable !== undefined
									? settingsConfig?.guestCheckout?.enable
									: true
							}
							clickHandler={() =>
								handleForm(
									"enable",
									settingsConfig?.guestCheckout?.enable !== undefined
										? !settingsConfig?.guestCheckout?.enable
										: false,
									"guestCheckout"
								)
							}
						/>
						<Switch
							showCustomTooltip
							tooltipInfo="Enable cash on delivery as a payment option for guest checkouts"
							title="Guest checkout cash on delivery"
							checked={settingsConfig?.guestCheckout?.cashOnDelivery}
							clickHandler={() =>
								handleForm(
									"cashOnDelivery",
									!settingsConfig?.guestCheckout?.cashOnDelivery,
									"guestCheckout"
								)
							}
						/>
						<Switch
							tooltipInfo="Make it mandatory for guest users to provide an email during checkout"
							showCustomTooltip
							title="Guest checkout email required"
							checked={
								settingsConfig?.guestCheckout?.emailRequired !== undefined
									? settingsConfig?.guestCheckout?.emailRequired
									: false
							}
							clickHandler={() =>
								handleForm(
									"emailRequired",
									settingsConfig?.guestCheckout?.emailRequired !== undefined
										? !settingsConfig?.guestCheckout?.emailRequired
										: true,
									"guestCheckout"
								)
							}
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							tooltipInfo="Collect feedback from customers. After an order is marked complete, a pop-up will appear on the customer's next website visit, inviting them to rate their experience"
							showCustomTooltip
							title="NPS Feedback enable"
							checked={
								settingsConfig?.npsFeedback?.enable !== undefined
									? settingsConfig?.npsFeedback?.enable
									: true
							}
							clickHandler={() =>
								handleForm(
									"enable",
									settingsConfig?.npsFeedback?.enable !== undefined
										? !settingsConfig?.npsFeedback?.enable
										: false,
									"npsFeedback"
								)
							}
						/>
						<InputWithLabel
							value={settingsConfig?.deliveryPickup?.order_detail_delivery_dt_msg}
							type="text"
							tooltipInfo="Set the message to be displayed on the order success page of website after customer places an order successfully"
							showCustomTooltip
							onChange={(e) =>
								handleForm("order_detail_delivery_dt_msg", e.target.value, "deliveryPickup")
							}
						>
							Order delivery message
						</InputWithLabel>
						<SelectFilter
							title="Image size"
							options={IMAGE_SIZES}
							currValue={IMAGE_SIZES.find((size) => size.value === settingsConfig?.imageSize) || null}
							setFilter={(field, value) => handleForm("imageSize", value)}
							isSearchable={false}
							isClearable={false}
							labelKey="label"
							valueKey="value"
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							title="Enable timeslots"
							showCustomTooltip
							tooltipInfo="Display predefined time slots (Ex: 8AM - 10AM) for customers to choose from, for placing orders on website"
							checked={settingsConfig?.deliveryPickup?.enableTimeSlots}
							clickHandler={() =>
								handleForm(
									"enableTimeSlots",
									!settingsConfig?.deliveryPickup?.enableTimeSlots,
									"deliveryPickup"
								)
							}
						/>
						<InputWithLabel
							tooltipInfo='Set the maximum number of days a customer can choose for a "deliver/pickup later" order on the website'
							showCustomTooltip
							value={settingsConfig?.deliveryPickup?.maxPreOrderDate}
							type="number"
							onChange={(e) =>
								handleForm(
									"maxPreOrderDate",
									e.target.value ? Number(e.target.value) : null,
									"deliveryPickup"
								)
							}
						>
							Max. pre-order date
						</InputWithLabel>
						<Switch
							classes={!isInternalUser ? "disabled" : ""}
							title="Show UrbanPiper branding"
							checked={settingsConfig?.showUrbanpiperBranding}
							clickHandler={() =>
								handleForm("showUrbanpiperBranding", !settingsConfig?.showUrbanpiperBranding)
							}
						/>
					</div>
					<div className="form-row row-half">
						<Switch
							tooltipInfo="Display dialog for cutomers to choose between deliver/pickup now or later option at the beginning of the website session"
							showCustomTooltip
							title="Upfront Fulfillment time prompt"
							checked={settingsConfig?.deliveryPickup?.upfrontFulfillmentTimePrompt}
							clickHandler={() =>
								handleForm(
									"upfrontFulfillmentTimePrompt",
									!settingsConfig?.deliveryPickup?.upfrontFulfillmentTimePrompt,
									"deliveryPickup"
								)
							}
						/>
						<SelectFilter
							title="Primary login mode"
							options={LOGIN_MODES}
							currValue={
								LOGIN_MODES.find((mode) => mode.value == settingsConfig?.primaryLogin) || "phone"
							}
							setFilter={(field, value) => handleForm("primaryLogin", value)}
							isSearchable={false}
							isClearable={false}
							labelKey="label"
							valueKey="value"
							tooltipInfo="Select the primary login mode for your business"
							showCustomTooltip
						/>
					</div>
				</div>
			</FormContainer>
		</div>
	);
};
const mapStateToProps = (store) => ({
	loginDetail: store.login.loginDetail
});
export default connect(mapStateToProps)(Settings);
