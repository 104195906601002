import React, { useState, useEffect, useCallback } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { InputWithLabel } from "../components/_commons/InputWithLabel";
import { Textarea } from "../components/_commons/Textarea";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { Button } from "../components/_commons/Button";

// utils
import history from "../history";
import { client } from "../client";
import { store } from "../store/configureStore";
import { parseErrorMessages, trackEvent } from "../atlas-utils";

// third party
import { connect } from "react-redux";

// actions
import { fetchFulfillmentModes } from "../actions/actions";
import { fetchChargesList } from "../actions/charges";
import { ActionTypes } from "../actions/_types";

// graphql
import { CREATE_CHARGE } from "../graphql/charges";
import { GET_BIZ_PLATFORMS } from "../graphql/locations";

// constants
import { TRACKING_EVENT_NAMES, TRACKING_STATUS, ONBOARDING_FLOWS } from "../client-config";
export const APPLICABLE_ON_OPTIONS = [
	{
		label: "Item Quantity",
		value: "item.quantity",
		types: [{ label: "Fixed value", value: "FIXED_VALUE" }]
	},
	{
		label: "Order Subtotal",
		value: "order.order_subtotal",
		types: [
			{ label: "Percentage", value: "PERCENTAGE" },
			{ label: "Range", value: "RANGE" }
		]
	},
	{
		label: "Flat Charge",
		value: "flat_charge",
		types: [{ label: "Fixed value", value: "FIXED_VALUE" }]
	},
	{
		label: "Item Price",
		value: "item.price",
		types: [{ label: "Percentage", value: "PERCENTAGE" }]
	}
];

const ChargeCreate = ({ biz, fulfillmentModes, hasAccess = false, atlasOnboardingflowData }) => {
	const [isFormOpen, setFormState] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [error, setError] = useState({});
	const [data, setData] = useState({
		title: "",
		description: "",
		merchantRefId: "",
		value: 0,
		excludedPlatforms: [],
		fulfillmentModes: [],
		rangeValues: [
			{
				min: "",
				max: "",
				value: ""
			}
		]
	});
	const [bizPlatforms, setBizPlatforms] = useState({
		loading: false,
		data: []
	});
	const [applicableOnOption, setApplicableOnOption] = useState(APPLICABLE_ON_OPTIONS[0]);
	const [fieldType, setFieldType] = useState(APPLICABLE_ON_OPTIONS[0].types[0]);
	const isOnboardingFlowEnabled = atlasOnboardingflowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchFulfillmentModes();
	}, []);

	const fetchBizPlatformsList = useCallback(async () => {
		try {
			setBizPlatforms({
				...bizPlatforms,
				loading: true
			});
			const variables = {
				limit: 20,
				offset: 0,
				filters: [
					{
						field: "is_enabled",
						value: "true"
					}
				]
			};
			const resp = await client.query({
				query: GET_BIZ_PLATFORMS,
				variables,
				fetchPolicy: "no-cache"
			});
			setBizPlatforms({
				loading: false,
				data: resp.data.bizPlatforms.objects
			});
		} catch (error) {
			console.log(error);
			setBizPlatforms({
				...bizPlatforms,
				loading: false
			});
		}
	}, [bizPlatforms]);

	useEffect(() => {
		fetchBizPlatformsList();
	}, []);

	const handleCancel = () => {
		setFormState(false);
		fetchChargesList();
		setTimeout(() => {
			history.push("/charges");
		}, 100);
	};

	const handleForm = (field, value, index, range = false) => {
		if (range) {
			let updatedRangeValues = data.rangeValues.map((range, i) => {
				if (i === index) {
					return {
						...range,
						[field]: value
					};
				}
				return range;
			});
			setData({
				...data,
				rangeValues: updatedRangeValues
			});
		} else {
			setData({
				...data,
				[field]: value
			});
		}
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleAddRange = () => {
		setData({
			...data,
			rangeValues: [
				...data.rangeValues,
				{
					min: "",
					max: "",
					value: ""
				}
			]
		});
	};

	const handleDeleteRange = (index) => {
		let updatedRangeValues = data.rangeValues.filter((range, i) => i !== index);
		setData({
			...data,
			rangeValues: updatedRangeValues
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleApplicableOnOption = (field, option) => {
		setApplicableOnOption(option);
		setFieldType(option.types[0]);
		setData({
			...data,
			rangeValues: [
				{
					min: "",
					max: "",
					value: ""
				}
			]
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleSubmit = async () => {
		setConfirmLoading(true);
		// default value is -1 if no merchantRefId is passed
		if (data.merchantRefId === "") {
			data.merchantRefId = "-1";
		}
		try {
			const variables = {
				...data,
				applicableOnOption: applicableOnOption.value,
				fieldType: fieldType.value
			};
			if (variables.excludedPlatforms) {
				variables.excludedPlatforms = variables.excludedPlatforms.map((plf) => plf.platformName);
			}
			if (variables.fulfillmentModes) {
				variables.fulfillmentModes = variables.fulfillmentModes.map((fm) => fm.id);
			}
			if (variables.value === null || variables.value === "") {
				variables.value = 0;
			}
			if (variables?.rangeValues?.length) {
				variables.rangeValues = variables.rangeValues.map((range) => {
					if (range.min === "") {
						range.min = null;
					}
					if (range.max === "") {
						range.max = null;
					}
					if (range.value === "") {
						range.value = null;
					}
					return range;
				});
			}
			const resp = await client.mutate({
				mutation: CREATE_CHARGE,
				variables
			});
			if (resp.data.saveCharge.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.NEW_CHARGE_CREATION, {
					status: TRACKING_STATUS.SUCCESS
				});

				setConfirmLoading(false);
				store.dispatch({
					type: "SHOW_GLOBAL_MESSAGE",
					payload: {
						message: "Charge created",
						timeout: 5000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_CHARGES_LIST,
					payload: resp.data.saveCharge.object
				});

				// track event
				if (isOnboardingFlowEnabled) {
					trackEvent("onboarding_taxes_charges_complete", {});
				}
				history.push(`/charges/edit/${resp.data.saveCharge.object.id}`);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.NEW_CHARGE_CREATION, {
					status: TRACKING_STATUS.FAILURE
				});

				setConfirmLoading(false);
				// handle error message
				if (
					resp.data.saveCharge.status.messages.length &&
					resp.data.saveCharge.status.messages[0].field === null
				) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: resp.data.saveCharge.status.messages[0].message,
							timeout: 3000,
							error: true
						}
					});
				} else {
					setError(parseErrorMessages(resp.data.saveCharge.status.messages));
				}
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.NEW_CHARGE_CREATION, {
				status: TRACKING_STATUS.FAILURE
			});

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
			setConfirmLoading(false);
		}
	};

	const validations = error.fields || {};

	return (
		<FormSidebar
			isOpen={isFormOpen}
			close={handleCancel}
			submit={handleSubmit}
			title="Charge"
			subTitle="Create a new charge"
			loading={confirmLoading}
			submitTitle="Create"
			disabled={!isFormTouched}
			hideActions={!isFormTouched}
		>
			<div className="form-content">
				<div className="form-row row-full">
					<InputWithLabel
						value={data.title}
						onChange={(e) => handleForm("title", e.target.value)}
						requiredLabel={true}
						validationMessage={validations.title || ""}
					>
						Name
					</InputWithLabel>
				</div>
				<div className="form-row row-half">
					<SelectFilter
						title="Applicable on"
						options={APPLICABLE_ON_OPTIONS}
						field="applicableOnOption"
						currValue={applicableOnOption}
						setFilter={handleApplicableOnOption}
						isSearchable={false}
						isClearable={false}
						labelKey="label"
						valueKey="value"
						requiredLabel={true}
						validationMessage={validations.applicableOn || ""}
					/>
					{applicableOnOption.value !== "order.order_subtotal" && (
						<InputWithLabel
							value={data.value}
							type="number"
							onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.value || ""}
							showLabel={true}
							classes="at-input--label"
							currency={
								(applicableOnOption.value === "item.quantity" ||
									applicableOnOption.value === "flat_charge") &&
								true
							}
							symbol={
								applicableOnOption.value === "order.order_subtotal" ||
								applicableOnOption.value === "item.price"
									? "%"
									: ""
							}
							currencySymbol={biz.currencySymbol}
						>
							{applicableOnOption.value === "item.quantity" ? (
								<span>Amount per quantity</span>
							) : applicableOnOption.value === "flat_charge" ? (
								<span>Fixed Value</span>
							) : (
								<span>Percentage</span>
							)}
						</InputWithLabel>
					)}
					{applicableOnOption.value === "order.order_subtotal" && (
						<SelectFilter
							title="Type"
							options={applicableOnOption.types}
							field="fieldType"
							currValue={fieldType}
							setFilter={(field, value) => setFieldType(value)}
							isSearchable={false}
							isClearable={false}
							labelKey="label"
							valueKey="value"
						/>
					)}
				</div>
				{applicableOnOption.value === "order.order_subtotal" && fieldType.value === "RANGE" && (
					<div className="card-container">
						{data.rangeValues.length > 0 &&
							data.rangeValues.map((range, i) => (
								<div className="form-row row-half" key={i}>
									<div className="min-max-range">
										<InputWithLabel
											value={range.min}
											type="number"
											onChange={(e) =>
												handleForm(
													"min",
													e.target.value ? parseFloat(e.target.value) : null,
													i,
													true
												)
											}
											validationMessage={validations.min || ""}
										>
											Min
										</InputWithLabel>
										<InputWithLabel
											value={range.max}
											type="number"
											onChange={(e) =>
												handleForm(
													"max",
													e.target.value ? parseFloat(e.target.value) : null,
													i,
													true
												)
											}
											validationMessage={validations.max || ""}
										>
											Max
										</InputWithLabel>
									</div>
									<div className="value-range">
										<InputWithLabel
											value={range.value}
											type="number"
											onChange={(e) =>
												handleForm(
													"value",
													e.target.value ? parseFloat(e.target.value) : null,
													i,
													true
												)
											}
											validationMessage={validations.value || ""}
										>
											Value
										</InputWithLabel>
										{data.rangeValues.length > 1 && (
											<div className="delete-range">
												<img
													className="delete-icon"
													onClick={() => handleDeleteRange(i)}
													src="/assets/icons/delete.svg"
												/>
											</div>
										)}
									</div>
								</div>
							))}
						<div className="add-another-range">
							<Button type="secondary" clickHandler={handleAddRange}>
								Add Range
							</Button>
						</div>
					</div>
				)}
				{applicableOnOption.value === "order.order_subtotal" && fieldType.value === "PERCENTAGE" && (
					<div className="form-row row-half">
						<InputWithLabel
							value={data.value}
							type="number"
							onChange={(e) => handleForm("value", e.target.value ? parseFloat(e.target.value) : null)}
							requiredLabel={true}
							validationMessage={validations.value || ""}
							showLabel={true}
							classes="at-input--label"
							symbol="%"
						>
							Percentage
						</InputWithLabel>
					</div>
				)}
				<div className="form-row row-full">
					<Textarea value={data.description} onChange={(e) => handleForm("description", e.target.value)}>
						Description
					</Textarea>
				</div>
				<div className="form-row row-half">
					<SelectFilter
						title="Excluded Platforms"
						options={bizPlatforms.data}
						isLoading={bizPlatforms.loading}
						field="excludedPlatforms"
						currValue={data.excludedPlatforms}
						setFilter={handleForm}
						labelKey="platformName"
						valueKey="platformName"
						validationMessage={validations.excludedPlatforms || ""}
						multi={true}
						isSearchable={false}
						isClearable={false}
						showCustomTooltip={true}
						tooltipInfo="This charge will not be applied to orders placed through platforms selected here"
						placeholder="Select"
					/>
					<SelectFilter
						title="Fulfillment Modes"
						options={fulfillmentModes.items}
						isLoading={fulfillmentModes.isLoading}
						field="fulfillmentModes"
						currValue={data.fulfillmentModes}
						setFilter={handleForm}
						labelKey="nameForDisplay"
						valueKey="id"
						validationMessage={validations.fulfillmentModes || ""}
						multi={true}
						isSearchable={false}
					/>
				</div>
				<div className="form-row row-half">
					<InputWithLabel
						value={data.merchantRefId}
						onChange={(e) => handleForm("merchantRefId", e.target.value)}
						showCustomTooltip={true}
						tooltipInfo="Applies only for third party POS integrations. Ignore if it doesn't apply to you"
					>
						POS ID
					</InputWithLabel>
				</div>
			</div>
		</FormSidebar>
	);
};
const mapStateToProps = (store) => ({
	biz: store.login.loggedInbizDetail,
	fulfillmentModes: store.configItems.fulfillmentModes,
	atlasOnboardingflowData: store.atlasOnboardingState.flowData
});
export default connect(mapStateToProps)(ChargeCreate);
