import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { getBrandDetailViaName, getFutureDate, getPlatformsDetailsViaId } from "../../../helpers/periscope";
import { connect } from "react-redux";
import Header from "../../../components/Periscope/EarlyAccess/Header";
import { fetchBizPlatforms, fetchBrands } from "../../../actions/actions";
import { extractInitials, lS, trackEvent } from "../../../atlas-utils";
import { periscopeCreateSubscription, periscopeEarlyAccessCheckout } from "../../../actions/periscope";
import { PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS } from "../../../client-config";

const CheckOut = ({ configItems, periscopeEarlyAccessState, login }) => {
	const brandItems = configItems?.brands?.items;
	const country = login?.loggedInbizDetail?.country || lS.get("auth")?.biz?.country;
	const bizPlatforms = configItems?.bizPlatforms?.items.filter((platform) => {
		if (platform.platformName == "All Platforms") {
			return true;
		}
		return (PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS?.[country] || []).includes(
			platform.platformName.toLowerCase()
		);
	});

	const [showToolTip, setShowToolTip] = useState(false);

	useEffect(() => {
		fetchBrands("", true);
		fetchBizPlatforms(false, false, true);
	}, []);

	const history = useHistory();
	const redirectToLocationSelection = () => {
		history.push("/periscope/early-access/get-started/select-location");
	};

	const selectedLocationsCount = periscopeEarlyAccessState?.selectedLocationsCount;
	const formattedData = periscopeEarlyAccessState?.dataSelected?.formattedData;
	const uniqBrands = periscopeEarlyAccessState?.dataSelected?.uniqBrands;
	const uniqPlatforms = periscopeEarlyAccessState?.dataSelected?.uniqPlatforms;
	const uniqLocations = periscopeEarlyAccessState?.dataSelected?.uniqLocations;
	let brands;
	let platforms;
	let locations;

	brands = Object.keys(uniqBrands || {});
	const brandsText = useMemo(() => {
		return brands.join(", ");
	}, [brands]);
	const [platformsText, setPlatformsText] = useState("");

	const brandsImages = useMemo(() => {
		if (uniqBrands && brandItems) {
			brands = Object.keys(uniqBrands);
			return brands.map((brandName, index) => {
				const brandDetails = getBrandDetailViaName(brandName, brandItems);
				const last = brands?.length ? true : false;
				return brandDetails?.image ? (
					<div className="img-outer-div" key={index}>
						<img
							key={index}
							src={brandDetails?.image}
							style={{ ...(last ? {} : { marginRight: "-4px" }) }}
							className={"img-inside-div"}
							alt={brandDetails?.name}
						/>
					</div>
				) : (
					<div className="img-outer-div" key={index}>
						<div
							key={index}
							className={`text-logo img-text-inside-div ${brandDetails?.color || "green"}`}
							style={{ ...(last ? {} : { marginRight: "-4px" }) }}
						>
							{extractInitials((brandDetails?.name || brandName)?.split(" "))}
						</div>
					</div>
				);
			});
		}
		return null;
	}, [uniqBrands, brandItems]);

	const pltformImages = useMemo(() => {
		if (uniqPlatforms && bizPlatforms) {
			const platforms = Object.keys(uniqPlatforms);
			let allPlatforms = "";
			const images = platforms.map((id, index) => {
				const pltDetails = getPlatformsDetailsViaId(id, bizPlatforms);
				const last = index === platforms.length - 1;
				let name = pltDetails?.platformName
					? pltDetails.platformName[0].toUpperCase() + pltDetails.platformName.slice(1)
					: "";
				name = name ? (last ? name : name + ", ") : "";
				allPlatforms += name;
				return pltDetails?.image ? (
					<div key={index} className="img-outer-div">
						<img
							src={pltDetails.image}
							style={{
								...(last ? {} : { marginRight: "-4px" })
							}}
							className={"img-inside-div"}
							alt={pltDetails.platformName}
						/>
					</div>
				) : null;
			});
			setPlatformsText(allPlatforms);
			return images;
		}
		return null;
	}, [uniqPlatforms]);

	if (uniqLocations) {
		locations = Object.keys(uniqLocations || []).join(", ");
	}

	if (uniqPlatforms) {
		platforms = Object.keys(uniqPlatforms);
	}
	useEffect(() => {
		const allLocations = Object.keys(uniqLocations || []);
		locations = allLocations.join(", ");
		if (locations.length == 0) {
			redirectToLocationSelection();
		}
	}, []);

	const finalCheckout = async () => {
		const response = await periscopeEarlyAccessCheckout(formattedData);
		if (response) {
			history.push("/periscope/collect");
			const subscriptionInput = {
				bizId: String(login?.loggedInbizDetail?.id),
				isActive: true,
				isEarlyAccessEnabled: true,
				bizName: login?.loggedInbizDetail?.name,
				country: login?.loggedInbizDetail?.country,
				email: login?.loginDetail?.email,
				isdCode: login?.loginDetail?.isdCode,
				mobile: login?.loginDetail?.phone,
				timezone: login?.loggedInbizDetail?.timezone
			};
			periscopeCreateSubscription(subscriptionInput);
			trackEvent("periscope_locations_onboarded", { location_count: selectedLocationsCount });
		}
	};
	return (
		<div className="periscope-container">
			<div className="section-container-common get-started-periscope" data-testid="checkout">
				<Header />
				<div className="get-started-content">
					<div className="checkout-div">
						<div className="location-info">
							<div className="select-location">
								<span className="location-get-started">Review Your Selections</span>
								<span className="location-text">
									{`You have selected ${selectedLocationsCount} locations to monitor during your free trial.`}
								</span>
								<span className="location-text">
									Updates occur every 30 minutes to ensure you have access to the most recent data.
								</span>
							</div>
							<div className=" checkout-data">
								<div className="options">
									<div className="selected-locations">
										<span className="option-tag">Selected Locations</span>
										<div className="loc-view">
											<span className="location-text">{locations}</span>
											<img
												src="/assets/periscope/pen.svg"
												className="pen"
												onClick={() => redirectToLocationSelection()}
											/>
										</div>
									</div>
									<div className="brand-platform-details">
										<div className="details">
											<span className="option-tag">Details</span>
											<div className="details-view">
												{brands && brands.length > 0 ? (
													<div className="brands">
														<span className="brand-text">
															{brands.length > 0 ? brands.length : ""} brands
														</span>
														<div
															className="brand-images"
															onMouseEnter={() => setShowToolTip(true)}
															onMouseLeave={() => setShowToolTip(false)}
														>
															{brandsImages}
														</div>
													</div>
												) : (
													""
												)}
												{platforms && platforms.length > 0 ? (
													<div className="platforms">
														<span className="platform-text">
															{platforms.length > 0 ? platforms.length : ""} platforms
														</span>
														<div
															className="plt-images"
															onMouseEnter={() => setShowToolTip(true)}
															onMouseLeave={() => setShowToolTip(false)}
														>
															{pltformImages}
														</div>
													</div>
												) : (
													""
												)}
											</div>
										</div>
										{showToolTip && (
											<div className="brand-plt-display">
												{brandsText?.length > 0 ? (
													<div className="text-board brands-board">
														<div className="heading"> Brands</div>
														<div className="brand-text">{brandsText}</div>
													</div>
												) : (
													""
												)}
												{platformsText?.length > 0 ? (
													<div className="text-board">
														<div className="heading">Platforms</div>
														<div className="plt-text">{platformsText}</div>
													</div>
												) : (
													""
												)}
											</div>
										)}
									</div>
								</div>
								<div className="show-locations-container">
									<div className="info-display">
										<img src="/assets/icons/info.png" className="icon-image" />
										<span>
											Locations selected here cannot be changed during your free trial. Please
											review carefully before proceeding.
										</span>
									</div>
								</div>
								<div className="btn-info">
									<button className="continue" onClick={() => finalCheckout()}>
										Start Free Trial
									</button>
								</div>
							</div>
						</div>
						{/* <div className="plan-view">
							<span className="main-font">
								Your free trial access will end on {getFutureDate(6, "months")}.
							</span>

							<span className="text-display">
								Your subscription after the trial period will be billed per storefront, which includes
								each unique combination of brand, location, and platform
							</span>
						</div> */}
					</div>
					<img className="select-location-img" src="/assets/periscope/select-location.svg" />
				</div>
				{/* <div className="terms">
				By continuing, you agree to the <span className="bold-text">Terms & Conditions </span> and
				<span className="bold-text">Privacy Policy</span>
				of Periscope by UrbanPiper
			</div> */}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	login: state?.login,
	periscopeEarlyAccessState: state.periscopeEarlyAccessState
});

export default connect(mapStateToProps)(CheckOut);
