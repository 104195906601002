import React, { useEffect, useRef, useState } from "react";

// third party
import Lottie from "lottie-react";

// component
import { FormSidebar } from "../_commons/FormSidebar";
import EntityAccordion from "../_commons/EntityAccordion";
import FoodTypeIndicator from "../_commons/FoodTypeIndicator";
import Image from "../_commons/Image";
import DragDropWrapper from "../_commons/DragDropWrapper";

// lottie
import dndAnimation from "../../lottie/dragAnimation.json";

// graphql
import { UPDATE_MENU } from "../../graphql/menus";

// clients
import { clientMenu } from "../../client-menu";

// services
import NotificationServices from "../../services/NotificationService";

// constants
import { FOODTYPE_MAPS, MENU_REORDER_TYPES, TRACKING_EVENT_NAMES } from "../../client-config";
import { store } from "../../store/configureStore";
import { ActionTypes } from "../../actions/_types";
import { trackEvent } from "../../atlas-utils";

const ReOrderItems = ({ isOpen, closeReOrderItem, parentSections = [], menuDetailsData }) => {
	const menuId = menuDetailsData?.menuId;
	const [expandedAccordions, setExpandedAccordions] = useState({});
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [parentSectionsState, setParentSectionsState] = useState(parentSections);
	const [stateUpdate, setStateUpdate] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [centralNodeId, setCentralNodeId] = useState(undefined);
	const [showAnimation, setShowAnimation] = useState(true);
	// const [isOverSectionId, setIsOverSectionId] = useState(null);

	const handleExpand = (id) => {
		setExpandedAccordions((current) => {
			const updatedState = {
				...current
			};
			if (updatedState[id]) {
				delete updatedState[id];
				return updatedState;
			}

			updatedState[id] = true;

			return updatedState;
		});
	};

	const getItemInfo = (dragDropInfo) => {
		const itemId = dragDropInfo?.id;
		for (let i = 0; i < parentSectionsState.length; i++) {
			const itemInfo = parentSectionsState[i]?.items.find((item) => item?.id === itemId);
			if (itemInfo) {
				return [itemInfo, dragDropInfo.currentParentId];
			}
		}
	};

	const handleReorderedListSave = async () => {
		setIsLoading(true);
		try {
			const menuUpdationVariables = {
				id: menuId,
				menuData: {
					image: null,
					imageUrl: menuDetailsData?.imageUrl,
					name: menuDetailsData.name,
					description: menuDetailsData.description,
					brand: menuDetailsData.brand,
					options: menuDetailsData.options.map((option) => ({
						...option,
						overrides: {
							...option.overrides,
							image: ""
						}
					})),
					optionGroups: menuDetailsData.optionGroups,
					items: menuDetailsData.items.map((item) => ({
						...item,
						overrides: {
							...item.overrides,
							image: ""
						}
					})),
					categories: [
						...parentSectionsState.map((category) => ({
							items: [...category.items.map((item) => String(item.id))],
							id: category?.id,
							subCategories: [...category.subCategories.map((subCategory) => String(subCategory?.id))],
							overrides: {
								name: category?.name || "",
								description: category?.description || "",
								image: ""
							}
						}))
					]
				}
			};
			const reOrderItemsResp = await clientMenu.mutate({
				mutation: UPDATE_MENU,
				variables: menuUpdationVariables
			});

			if (!!reOrderItemsResp?.data?.updateMenuV2?.menuId) {
				NotificationServices.pushNotification({
					message: "Menu rearranged successfully!",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				store.dispatch({
					type: ActionTypes.TOTAL_MENU_DETAILS_DATA_UPDATE,
					payload: reOrderItemsResp?.data?.updateMenuV2
				});
				setShowAnimation(true);
				closeReOrderItem(true);
			} else {
				NotificationServices.pushNotification({
					message: "Failed to rearrange menu",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
			}
		} catch (e) {
			console.log(e);
			NotificationServices.pushNotification({
				message: "Failed to rearrange menu",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
		setIsLoading(false);
	};

	const handleDropEvent = (draggedSectionInfo, droppedSectionInfo) => {
		trackEvent(TRACKING_EVENT_NAMES.MENU_REORDER, {
			entity: draggedSectionInfo?.type
		});
		// kill the process if the dragged item is a parent category or if the ids match, dragging & dropping on the same entity
		if (draggedSectionInfo?.id === droppedSectionInfo?.id) {
			return false;
		}

		let updatedParentSections = [...parentSectionsState];

		if (
			(draggedSectionInfo.type === "sub_category" || draggedSectionInfo.type === "parent_category") &&
			droppedSectionInfo?.type === "parent_category"
		) {
			let droppedParentSectionInfo = updatedParentSections?.find(
				(section) => section?.id === droppedSectionInfo?.id
			);

			if (
				!!droppedParentSectionInfo?.subCategories.find(
					(subCategory) => subCategory?.id === draggedSectionInfo?.id
				)
			) {
				NotificationServices.pushNotification({
					message: "Selected sub section already exists in the targetted parent section",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return false;
			}
			if (!droppedParentSectionInfo) {
				NotificationServices.pushNotification({
					message: "Failed to get parent section info",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return false;
			}

			if (!!(droppedParentSectionInfo?.items ?? []).length) {
				NotificationServices.pushNotification({
					message: "Sections with items in it cannot carry sub-sections",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return false;
			}

			let draggedSectionExtendedInfo = updatedParentSections?.find(
				(section) => section?.id === draggedSectionInfo?.id
			);

			if (!!draggedSectionExtendedInfo?.subCategories?.length) {
				NotificationServices.pushNotification({
					message: "Sections with sub sections cannot be ordered as a sub section",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});

				return false;
			}

			updatedParentSections = updatedParentSections.map((section) =>
				section.id === droppedSectionInfo?.id
					? {
							...droppedParentSectionInfo,
							subCategories: [
								...droppedParentSectionInfo?.subCategories,
								{ id: draggedSectionInfo?.id, name: draggedSectionInfo?.name }
							]
					  }
					: {
							...section,
							subCategories: section.subCategories.filter(
								(subCategory) => subCategory?.id !== draggedSectionInfo?.id
							)
					  }
			);
			setParentSectionsState(updatedParentSections);
			setIsFormTouched(true);
		}

		if (draggedSectionInfo.type === "item" && droppedSectionInfo?.type !== "item") {
			let droppedParentSectionInfo = updatedParentSections?.find(
				(section) => section?.id === droppedSectionInfo?.id
			);

			if (!!(droppedParentSectionInfo?.subCategories ?? []).length) {
				NotificationServices.pushNotification({
					message: "Sections with sub sections in it cannot carry items",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return false;
			}

			const [itemInfo, currentParentId] = getItemInfo(draggedSectionInfo);

			if (currentParentId === droppedSectionInfo?.id) {
				NotificationServices.pushNotification({
					message: "Item already exsists in the targeted section",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				return false;
			}

			setParentSectionsState((current) => [
				...current.map((section) =>
					section.id === droppedSectionInfo?.id
						? {
								...droppedParentSectionInfo,
								items: [itemInfo, ...droppedParentSectionInfo.items]
						  }
						: {
								...section,
								items: [...section.items.filter((item) => item.id !== draggedSectionInfo?.id)]
						  }
				)
			]);
			setIsFormTouched(true);
		}

		if (draggedSectionInfo?.type === "item" && droppedSectionInfo?.type === "item") {
			const [draggedItemData, currentDraggedParentId] = getItemInfo(draggedSectionInfo);
			const [droppedItemData, currentDroppedParentId] = getItemInfo(droppedSectionInfo);

			if (currentDraggedParentId === currentDroppedParentId) {
				let droppedParentSectionInfo = updatedParentSections?.find(
					(section) => section?.id === currentDroppedParentId
				);
				const currentItemsArray = [
					...droppedParentSectionInfo?.items.filter((item) => item.id !== draggedItemData?.id)
				];
				const updatedItemsArray = [];
				currentItemsArray.forEach((item) => {
					if (item.id === droppedItemData?.id && !droppedSectionInfo?.firstIndex) {
						updatedItemsArray.push(item);
						updatedItemsArray.push(draggedItemData);
					} else if (item.id === droppedItemData?.id && droppedSectionInfo?.firstIndex) {
						updatedItemsArray.push(draggedItemData);
						updatedItemsArray.push(item);
					} else {
						updatedItemsArray.push(item);
					}
				});
				droppedParentSectionInfo.items = [...updatedItemsArray];
				setParentSectionsState((current) => [
					...current.map((section) =>
						section.id === currentDroppedParentId
							? {
									...droppedParentSectionInfo
							  }
							: {
									...section
							  }
					)
				]);
				setIsFormTouched(true);
			}

			if (currentDraggedParentId !== currentDroppedParentId) {
				let droppedParentSectionInfo = updatedParentSections?.find(
					(section) => section?.id === currentDroppedParentId
				);

				const currentItemsArray = [
					...droppedParentSectionInfo?.items.filter((item) => item.id !== draggedItemData?.id)
				];
				const updatedItemsArray = [];
				currentItemsArray.forEach((item) => {
					if (item.id === droppedItemData?.id && !droppedSectionInfo?.firstIndex) {
						updatedItemsArray.push(item);
						updatedItemsArray.push(draggedItemData);
					} else if (item.id === droppedItemData?.id && droppedSectionInfo?.firstIndex) {
						updatedItemsArray.push(draggedItemData);
						updatedItemsArray.push(item);
					} else {
						updatedItemsArray.push(item);
					}
				});
				droppedParentSectionInfo.items = [...updatedItemsArray];

				setParentSectionsState((current) => [
					...current.map((section) =>
						section.id === currentDroppedParentId
							? {
									...droppedParentSectionInfo
							  }
							: {
									...section,
									items: [...section.items.filter((item) => item.id !== draggedSectionInfo?.id)]
							  }
					)
				]);
				setIsFormTouched(true);
			}
		}
	};

	// const handleHoverInfo = (id) => {
	// 	setIsOverSectionId(id)
	// }

	const getParentSectionId = (parentSections, subCatId) => {
		for (let i = 0; i < parentSections.length; i++) {
			const parentSectionId = parentSections[i].subCategories.find((subCategory) => subCategory?.id === subCatId);
			if (parentSectionId) {
				return parentSections[i].id;
			}
		}
	};

	const handleRestructureSection = (draggedSectionInfo, droppedSectionInfo) => {
		try {
			if (draggedSectionInfo?.id === droppedSectionInfo?.id) {
				return;
			}

			if (
				draggedSectionInfo?.type === "parent_category" &&
				draggedSectionInfo?.type === droppedSectionInfo?.type
			) {
				const draggedSectionContent = parentSectionsState.find(
					(parentSection) => parentSection?.id === draggedSectionInfo?.id
				);
				const updatedParentSections = [];
				const draggedSectionFilteredParentSection = parentSectionsState.filter(
					(parentSection) => parentSection?.id !== draggedSectionInfo?.id
				);

				for (let i = 0; i < draggedSectionFilteredParentSection.length; i++) {
					if (
						draggedSectionFilteredParentSection[i]?.id === droppedSectionInfo?.id &&
						!droppedSectionInfo?.firstIndex
					) {
						updatedParentSections.push(draggedSectionFilteredParentSection[i]);
						updatedParentSections.push(draggedSectionContent);
					} else if (
						draggedSectionFilteredParentSection[i]?.id === droppedSectionInfo?.id &&
						droppedSectionInfo?.firstIndex
					) {
						updatedParentSections.push(draggedSectionContent);
						updatedParentSections.push(draggedSectionFilteredParentSection[i]);
					} else {
						updatedParentSections.push(draggedSectionFilteredParentSection[i]);
					}
				}

				setParentSectionsState([...updatedParentSections]);
				setIsFormTouched(true);
				return;
			}

			if (draggedSectionInfo?.type === "sub_category" && draggedSectionInfo?.type === droppedSectionInfo?.type) {
				const draggedSectionParentId = getParentSectionId([...parentSectionsState], draggedSectionInfo?.id);
				const droppedSectionParentId = getParentSectionId([...parentSectionsState], droppedSectionInfo?.id);
				if (draggedSectionParentId && droppedSectionParentId) {
					if (draggedSectionParentId === droppedSectionParentId) {
						const commonParentSectionContent = parentSectionsState.find(
							(parentSection) => parentSection?.id === droppedSectionParentId
						);
						const draggedSubCatContent = commonParentSectionContent.subCategories.find(
							(subCat) => subCat?.id === draggedSectionInfo?.id
						);
						const subCategories = [
							...commonParentSectionContent?.subCategories.filter(
								(subCat) => subCat?.id !== draggedSectionInfo?.id
							)
						];
						const updatedSubCategories = [];

						for (let i = 0; i < subCategories.length; i++) {
							if (subCategories[i]?.id === droppedSectionInfo?.id && !droppedSectionInfo?.firstIndex) {
								updatedSubCategories.push(subCategories[i]);
								updatedSubCategories.push(draggedSubCatContent);
							} else if (
								subCategories[i]?.id === droppedSectionInfo?.id &&
								droppedSectionInfo?.firstIndex
							) {
								updatedSubCategories.push(draggedSubCatContent);
								updatedSubCategories.push(subCategories[i]);
							} else {
								updatedSubCategories.push(subCategories[i]);
							}
						}

						commonParentSectionContent.subCategories = [...updatedSubCategories];

						const updateParentSection = [
							...parentSectionsState.map((parentSection) =>
								parentSection?.id === droppedSectionParentId
									? {
											...commonParentSectionContent
									  }
									: { ...parentSection }
							)
						];

						setParentSectionsState(updateParentSection);
						setIsFormTouched(true);
						return;
					}

					const updatedParentSections = [];
					let draggedSubCategoriesContent = {};

					const rearrageSubCategories = (subCategories, draggedSubCategoriesContent) => {
						let updatedSubCategories = [];
						for (let i = 0; i < subCategories.length; i++) {
							if (subCategories[i]?.id === droppedSectionInfo?.id) {
								updatedSubCategories.push({
									...subCategories[i]
								});
								updatedSubCategories.push({
									...draggedSubCategoriesContent
								});
							} else {
								updatedSubCategories.push({
									...subCategories[i]
								});
							}
						}
						return [...updatedSubCategories];
					};

					for (let i = 0; i < parentSectionsState.length; i++) {
						let isDraggedSubCatContentsFound = false;
						for (let j = 0; j < parentSectionsState[i].subCategories.length; i++) {
							let subCatSearchResult = parentSectionsState[i].subCategories.find(
								(subCat) => subCat?.id === draggedSectionInfo?.id
							);
							if (subCatSearchResult) {
								draggedSubCategoriesContent = { ...subCatSearchResult };
								isDraggedSubCatContentsFound = true;
								break;
							}
						}
						if (isDraggedSubCatContentsFound) {
							break;
						}
					}

					for (let i = 0; i < parentSectionsState.length; i++) {
						if (parentSectionsState[i].id === draggedSectionParentId) {
							updatedParentSections.push({
								...parentSectionsState[i],
								subCategories: parentSectionsState[i].subCategories.filter(
									(subCat) => subCat?.id !== draggedSectionInfo?.id
								)
							});
						} else if (parentSectionsState[i].id === droppedSectionParentId) {
							updatedParentSections.push({
								...parentSectionsState[i],
								subCategories: rearrageSubCategories(
									parentSectionsState[i].subCategories,
									draggedSubCategoriesContent
								)
							});
						} else {
							updatedParentSections.push({
								...parentSectionsState[i]
							});
						}
					}
					setParentSectionsState([...updatedParentSections]);
					setIsFormTouched(true);
					return;
				}
			}

			if (draggedSectionInfo?.type === "sub_category" && droppedSectionInfo?.type === "parent_category") {
				const draggedSectionParentId = getParentSectionId([...parentSectionsState], draggedSectionInfo?.id);
				const draggedSectionContent = parentSectionsState.find(
					(parentSection) => parentSection?.id === draggedSectionInfo?.id
				);
				const updatedParentSections = [];
				const draggedSectionFilteredParentSection = parentSectionsState.filter(
					(parentSection) => parentSection?.id !== draggedSectionInfo?.id
				);

				for (let i = 0; i < draggedSectionFilteredParentSection.length; i++) {
					if (draggedSectionFilteredParentSection[i]?.id === droppedSectionInfo?.id) {
						if (draggedSectionParentId === draggedSectionFilteredParentSection[i]?.id) {
							updatedParentSections.push({
								...draggedSectionFilteredParentSection[i],
								subCategories: [
									...draggedSectionFilteredParentSection[i].subCategories.filter(
										(subCat) => subCat.id !== draggedSectionInfo?.id
									)
								]
							});
						} else {
							updatedParentSections.push(draggedSectionFilteredParentSection[i]);
						}
						updatedParentSections.push(draggedSectionContent);
					} else if (draggedSectionFilteredParentSection[i]?.id === draggedSectionParentId) {
						updatedParentSections.push({
							...draggedSectionFilteredParentSection[i],
							subCategories: [
								...draggedSectionFilteredParentSection[i].subCategories.filter(
									(subCat) => subCat.id !== draggedSectionInfo?.id
								)
							]
						});
					} else {
						updatedParentSections.push(draggedSectionFilteredParentSection[i]);
					}
				}

				setParentSectionsState([...updatedParentSections]);
				setIsFormTouched(true);
				return;
			}
		} catch (e) {
			console.log(e);
		}
	};

	const calculateTotalItemsCountInSubsection = (subSectionIds) => {
		let count = 0;

		subSectionIds.forEach((subSectionId) => {
			const itemsCountInSubsection = (
				parentSections.find((section) => section?.id === subSectionId?.id)?.items ?? []
			).length;
			count += itemsCountInSubsection;
		});

		return count;
	};

	useEffect(() => {
		if (isOpen) {
			setParentSectionsState(parentSections);
			setTimeout(() => {
				setShowAnimation(false);
			}, 3000);
		}
	}, [parentSections, isOpen]);

	let subCategories = {};
	(parentSectionsState ?? []).forEach((parentCategory) => {
		(parentCategory.subCategories ?? []).forEach((subCategory) => {
			subCategories[subCategory?.id] = true;
		});
	});
	const restructuredParentSections = parentSectionsState.filter((section) => !subCategories[section?.id]);

	return (
		<FormSidebar
			isOpen={isOpen}
			overlaySidebarClasses="medium"
			title="Reorder Menu"
			close={() => {
				closeReOrderItem();
				setShowAnimation(true);
			}}
			submit={handleReorderedListSave}
			loading={isLoading}
			hideActions={!isFormTouched}
		>
			<div className={`reorder-items-container ${isLoading ? "disabled" : ""}`}>
				{showAnimation ? (
					<div className="animation-container">
						<Lottie animationData={dndAnimation} className="lottie" />
						<div className="info-text">
							Drag and drop sections, sub-sections and items to rearrange its order
						</div>
					</div>
				) : (
					restructuredParentSections.map((section, i) => (
						<>
							{i === 0 && (
								<DragDropWrapper
									id={`parent_section_drop_zone_${section?.id}`}
									dndType={MENU_REORDER_TYPES?.SECTIONSLIST}
									handleDrop={handleRestructureSection}
									entityFields={{
										type: "parent_category",
										id: section.id,
										name: section?.name,
										firstIndex: true
									}}
									entityDependencyContent={parentSectionsState}
									inlineStyles={{
										height: "14px",
										transition: "all ease-in-out 100ms"
									}}
									isOverStyles={{
										boxShadow: "0 2px 0 #2f58f2 inset"
									}}
								></DragDropWrapper>
							)}
							<EntityAccordion
								title={
									<DragDropWrapper
										id={section.id}
										dndType={MENU_REORDER_TYPES?.SECTIONSLIST}
										handleDrop={handleDropEvent}
										entityFields={{
											type: "parent_category",
											id: section.id,
											name: section?.name
										}}
										entityDependencyContent={parentSectionsState}
										onDragClasses="drag-styles-section"
									>
										<React.Fragment>
											<span className="section-title-container">
												{section?.name}
												<span className="section-items-count">
													{section?.subCategories.length} subsections,{" "}
													{!!(section?.items ?? []).length
														? (section?.items ?? []).length
														: calculateTotalItemsCountInSubsection(
																section?.subCategories
														  )}{" "}
													items
												</span>
											</span>
											<Image
												className="drag-icon"
												alt="drag this"
												src="/assets/icons/icon-drag.svg"
											/>
										</React.Fragment>
									</DragDropWrapper>
								}
								isExpanded={expandedAccordions[section.id]}
								handleExpand={() => {
									handleExpand(section.id);
								}}
								key={i}
							>
								{!!(section?.items ?? []).length
									? section?.items.map((item, j) => (
											<DragDropWrapper
												id={item.id}
												entityFields={{
													id: item.id,
													type: "item",
													currentParentId: section?.id,
													firstIndex: j === 0
												}}
												handleDrop={handleDropEvent}
												entityDependencyContent={parentSectionsState}
												isCentralNodeVisible
												centralNodeId={centralNodeId}
												setCentralNodeId={setCentralNodeId}
											>
												<Item
													title={item.name}
													foodType={item.type}
													imageUrl={item.imageUrl}
													key={j}
												/>
											</DragDropWrapper>
									  ))
									: section.subCategories.map((subCategory, k) => (
											<>
												{k === 0 && (
													<DragDropWrapper
														id={`sub_section_drop_zone_${subCategory?.id}`}
														dndType={MENU_REORDER_TYPES?.SECTIONSLIST}
														handleDrop={handleRestructureSection}
														entityFields={{
															type: "sub_category",
															id: subCategory.id,
															name: subCategory?.name,
															firstIndex: true
														}}
														entityDependencyContent={parentSectionsState}
														inlineStyles={{
															height: "14px",
															transition: "all ease-in-out 100ms"
														}}
														isOverStyles={{
															boxShadow: "0 2px 0 #2f58f2 inset"
														}}
													></DragDropWrapper>
												)}
												<EntityAccordion
													title={
														<DragDropWrapper
															id={subCategory.id}
															handleDrop={handleDropEvent}
															entityFields={{
																id: subCategory.id,
																type: "sub_category",
																name: subCategory?.name
															}}
															entityDependencyContent={parentSectionsState}
														>
															<React.Fragment>
																<span className="section-title-container">
																	{subCategory?.name}
																	<span className="section-items-count">
																		0 subsections,{" "}
																		{
																			parentSectionsState.find(
																				(category) =>
																					category.id === subCategory?.id
																			)?.items.length
																		}{" "}
																		items
																	</span>
																</span>
																<Image
																	className="drag-icon"
																	alt="drag this"
																	src="/assets/icons/icon-drag.svg"
																/>
															</React.Fragment>
														</DragDropWrapper>
													}
													isExpanded={expandedAccordions[subCategory.id]}
													handleExpand={() => {
														handleExpand(subCategory.id);
													}}
													key={k}
													className="subcategories-container"
												>
													{(
														parentSectionsState.find(
															(category) => category.id === subCategory?.id
														)?.items ?? []
													).map((item, l) => (
														<DragDropWrapper
															id={item.id}
															entityFields={{
																id: item.id,
																type: "item",
																currentParentId: subCategory?.id,
																firstIndex: l === 0
															}}
															handleDrop={handleDropEvent}
															entityDependencyContent={parentSectionsState}
															isCentralNodeVisible
															centralNodeId={centralNodeId}
															setCentralNodeId={setCentralNodeId}
															onDragClasses="dnd-drag-reaction"
														>
															<Item
																title={item.name}
																foodType={item.foodType}
																imageUrl={item.imageUrl}
																key={l}
															/>
														</DragDropWrapper>
													))}
												</EntityAccordion>
												<DragDropWrapper
													id={`sub_section_drop_zone_${subCategory?.id}`}
													dndType={MENU_REORDER_TYPES?.SECTIONSLIST}
													handleDrop={handleRestructureSection}
													entityFields={{
														type: "sub_category",
														id: subCategory.id,
														name: subCategory?.name
													}}
													entityDependencyContent={parentSectionsState}
													inlineStyles={{
														height: "14px",
														transition: "all ease-in-out 100ms"
													}}
													isOverStyles={{
														boxShadow: "0 2px 0 #2f58f2 inset"
													}}
												></DragDropWrapper>
											</>
									  ))}
							</EntityAccordion>
							<DragDropWrapper
								id={`parent_section_drop_zone_${section?.id}`}
								dndType={MENU_REORDER_TYPES?.SECTIONSLIST}
								handleDrop={handleRestructureSection}
								entityFields={{
									type: "parent_category",
									id: section.id,
									name: section?.name
								}}
								entityDependencyContent={parentSectionsState}
								inlineStyles={{
									height: "14px",
									transition: "all ease-in-out 100ms"
								}}
								isOverStyles={{
									boxShadow: "0 2px 0 #2f58f2 inset"
								}}
							></DragDropWrapper>
						</>
					))
				)}
			</div>
		</FormSidebar>
	);
};
export default ReOrderItems;

const Item = ({ title, foodType, imageUrl }) => (
	<div className="item-details-container">
		<Image className="drag-icon" alt="drag this" src="/assets/icons/icon-drag.svg" />
		<Image src={imageUrl || "/assets/empty-dish.jpg"} alt={title} className="item-image" />
		<FoodTypeIndicator foodType={FOODTYPE_MAPS[foodType]} />
		<div className="item-title">{title}</div>
	</div>
);
