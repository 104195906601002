import React, { useEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import SectionHeader from "../../components/Analytics/Common/SectionHeader";
import { SearchFilter } from "../../components/_commons/SearchFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Topbar } from "../../components/_commons/Topbar";
import Item from "../../components/_icons/Item";
import Platform from "../../components/_icons/Platform";
import Brand from "../../components/_icons/Brand";
import {
	deleteComparison,
	fetchDebouncedSavedComparisons,
	fetchSavedComparisons,
	getEncodedAnalyticsFilters,
	saveComparison,
	updateLastViewed
} from "../../actions/analytics";
import { lS, trackEvent } from "../../atlas-utils";
import { ActionTypes } from "../../actions/_types";
import { store } from "../../store/configureStore";
import { CustomTable } from "../../components/_commons/CustomTable";
import { Paginator } from "../../components/_commons/Paginator";
import Popover from "../../components/_commons/Popover";
import { dateFormat, updatedFilters, updateDurationFilter } from "../../helpers/analytics";
import ArchiveRestoreModal from "../../components/_commons/ArchiveRestoreModal";
import { Loading } from "../../components/_commons/Loading";
import CompareIntro from "../../components/Analytics/Compare/CompareIntro";

const ARCHIVE_MENU_INIT_STATE = {
	isOpen: false,
	id: null,
	locationsCount: 0
};

const Compare = ({ isMultibrandEnabled, login, compareAnalytics }) => {
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(false);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [tab, setTab] = useState("saved");
	const savedComparisonVariables = (text = "") => {
		const textStr = text ? text : searchValue;
		return {
			bizId: String(login?.loggedInbizDetail?.id),
			limit: limit,
			offset: offset,
			search: [{ key: "name", value: textStr }],
			view: tab
		};
	};
	useEffect(() => {
		trackEvent("compare_tab_viewed", {
			module: "compare"
		});
	}, []);
	const setFilter = (field, value) => {
		setSearchValue(value.trim());
		setLoading(true);
	};
	useEffect(() => {
		fetchDebouncedSavedComparisons(savedComparisonVariables(searchValue, true));
	}, [searchValue]);

	const view = tab == "saved" ? "savedComparisons" : "recentlyViewed";
	const data = compareAnalytics?.[view]?.comparisons;
	const totalCount = (compareAnalytics?.[view]?.comparisons || []).length;
	const loadingSavedComparisons = compareAnalytics?.[view]?.loading;
	const errorSavedComparisons = compareAnalytics?.[view]?.error;
	const isFirstRender = useRef(true);
	useEffect(() => {
		if (!loadingSavedComparisons && !isFirstRender.current) {
			setLoading(false);
		}
		if (isFirstRender.current) {
			isFirstRender.current = false;
		}
	}, [loadingSavedComparisons]);
	const handleFormTab = (ans) => {
		setTab(ans.value);
	};

	useEffect(() => {
		fetchSavedComparisons(savedComparisonVariables());
	}, [tab, limit, offset]);

	const [archiveMenuState, setArchiveMenuState] = useState(ARCHIVE_MENU_INIT_STATE);
	const handleArchiveRestoreModal = (toOpen, refresh = false, data) => {
		let values;
		if (tab == "saved") {
			values = { id: data?.id, refreshFetch: savedComparisonVariables() };
		} else {
			values = {
				id: data?.id,
				input: {
					comparisonId: data?.id,
					bizId: String(login.loggedInbizDetail?.id),
					status: "removed"
				},
				refreshFetch: savedComparisonVariables()
			};
		}
		if (toOpen) {
			setArchiveMenuState({
				isOpen: true,
				...values
			});
			return;
		}
		setArchiveMenuState(ARCHIVE_MENU_INIT_STATE);
	};

	useEffect(() => {
		setOffset(0);
		setLimit(10);
	}, [tab]);

	const filters = useMemo(() => {
		return {
			appliedDateFilter: {
				current: {
					dateFilter: "LAST_7_DAYS",
					dateTypeSelected: {
						label: "Preset",
						value: "preset_duration"
					},
					presetTypeSelected: {
						label: "7 D",
						value: "LAST_7_DAYS",
						title: "Last 7 days",
						meta_info: "7 days since"
					}
				},
				compare: {
					dateFilter: "",
					dateTypeSelected: {
						label: "Not specified",
						value: "not_specified"
					},
					rangeStartDate: undefined,
					rangeEndDate: undefined
				}
			},
			appliedFilters: {
				brand_id: ["all"],
				location_id: ["all"],
				platform_names: ["all"]
			}
		};
	}, []);

	const cards = [
		{ header: "Locations", text: "Bangalore vs Delhi", type: "location" },
		{ header: "Items", text: "Pepperoni Pizza vs Veg Extra Pizza", type: "item" },
		{ header: "Platforms", text: "Zomato vs Swiggy", type: "platform" },
		{ header: "Brands", text: "Brand A vs Brand B", type: "brand" }
	];

	const images = {
		location: () => (
			<img style={{ width: "21px", height: "20px", opacity: "0.6" }} src="/assets/left-nav/icon-locations.svg" />
		),
		item: (variations, styles = { width: "22px", height: "22px" }) => (
			<Item variations={variations} styles={styles} />
		),
		platform: (variations, styles = { width: "20px", height: "20px" }) => (
			<Platform variations={variations} styles={styles} />
		),
		brand: (variations, styles = { width: "19px", height: "20px" }) => (
			<Brand variations={variations} styles={styles} />
		)
	};
	const savedCount = compareAnalytics?.savedCount ? compareAnalytics?.savedCount : "";
	const recentlyViewedCount = compareAnalytics?.recentlyViewedCount || null;
	const FORM_TABS = [
		{
			label: (
				<div className="label-heading">
					Saved Comparisons {savedCount && searchValue && <span className="data-count">{savedCount} </span>}
				</div>
			),
			value: "saved"
		},
		{
			label: (
				<div className="label-heading">
					Recently Viewed{" "}
					{recentlyViewedCount && searchValue && <span className="data-count">{recentlyViewedCount} </span>}
				</div>
			),
			value: "recently_viewed"
		}
	];

	const highlightedIndex = (text, highlight) => {
		if (!highlight) return <span>{text}</span>;

		const parts = text.split(new RegExp(`(${highlight})`, "gi"));
		return (
			<span>
				{parts.map((part, index) =>
					part.toLowerCase() === highlight.toLowerCase() ? (
						<span key={index} style={{ backgroundColor: "yellow" }}>
							{part}
						</span>
					) : (
						part
					)
				)}
			</span>
		);
	};

	const columns = [
		{
			name: "Comparing",
			field: "comparing",
			render: (record, i, rest) => {
				const name = record?.comparison?.name;
				const width = 90;
				const trimmedStr = (name || "").length >= width ? name.substring(0, width) : name || "";
				const renderPopover = (desc) => {
					return (
						<div style={{ width: "100%", padding: "8px 12px" }}>{highlightedIndex(desc, searchValue)}</div>
					);
				};
				return (
					<div className=" table-cell   comparing-div ">
						<div>{images?.[record?.type]({ fill: " #AAAAAA" })}</div>
						<div
							className="name-text hyperlink  hyperlink--black-color "
							onClick={() => handleClick("edit", record, "viewed")}
						>
							{(name || "").length >= width ? (
								<Popover
									data={name || ""}
									showOnHover={true}
									renderPopover={renderPopover}
									position={"down-left"}
								>
									<div>{highlightedIndex(trimmedStr, searchValue)}...</div>
								</Popover>
							) : (
								highlightedIndex(name, searchValue)
							)}
						</div>
					</div>
				);
			}
		},
		{
			name: tab == "saved" ? "Created" : "Last viewed",
			field: "created",
			render: (record, i, rest) => {
				const createdBy = record.createdBy;
				const loginUserId = login?.loginDetail?.id;
				const createdByName = record.userId === String(loginUserId) ? "You" : createdBy;
				const getDate = tab == "saved" ? dateFormat(record?.updatedAt) : dateFormat(record?.lastViewedAt);
				return (
					<div className="table-cell   created">
						<div className="date-text">{getDate}</div>
						<div className="createdby"> by: {tab == "saved" ? createdByName : "You"}</div>
					</div>
				);
			}
		}
	];

	const removeTypenameRecursive = (obj, keyToRemove = "__typename") => {
		if (Array.isArray(obj)) {
			return obj.map((item) => removeTypenameRecursive(item, keyToRemove));
		} else if (obj && typeof obj === "object" && !Array.isArray(obj)) {
			return Object.fromEntries(
				Object.entries(obj)
					.filter(([key]) => key !== keyToRemove)
					.map(([key, value]) => [key, removeTypenameRecursive(value, keyToRemove)])
			);
		}
		return obj;
	};

	const trackSavedComparisonEvent = (savedFor, metric) => {
		const visibility = savedFor === "me" ? "self" : "everyone";
		let entity = {
			entity: metric,
			visibility: visibility,
			type: "duplicate"
		};
		trackEvent("saved_comparison_created", { ...entity, module: "compare" });
	};

	const trackViewingComparison = (action) => {
		trackEvent("saved_comparison_viewed", { module: "compare", type: action });
	};

	const handleClick = async (action, val, event = "edit") => {
		if (action == "edit") {
			let newFilters = updatedFilters(filters, val.comparison.filters);
			newFilters = updateDurationFilter(newFilters, val.comparison.duration);
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
				payload: {
					currentFilters: newFilters.appliedFilters,
					appliedFilters: newFilters.appliedFilters,
					initialFiltersFromUrl: newFilters.appliedFilters,
					currentDateFilter: newFilters.appliedDateFilter,
					appliedDateFilter: newFilters.appliedDateFilter
				}
			});
			const encodedFilters = getEncodedAnalyticsFilters();
			const queryParams = new URLSearchParams({
				...(encodedFilters && { filters: encodedFilters })
			}).toString();
			const state = {
				handleClearFilters: true,
				type: val.type,
				name: val.comparison.name,
				columns: val.comparison.metrics,
				userId: String(val.userId) === String(lS.get("auth")?.userInfo?.id) ? val.userId : null,
				status: val?.status,
				savedFor: val?.savedFor,
				filters: newFilters
			};
			history.push(`/analytics/compare/${val.type}/edit/${val.id}?${queryParams}`, state);

			const event_type = event === "viewed" ? "opened" : "edit";
			trackViewingComparison(tab === "recently_viewed" ? "recently_viewed" : event_type);
		}
		if (action == "duplicate") {
			const variables = {
				bizId: val.bizId,
				type: val.type,
				savedFor: val.savedFor,
				status: val.status,
				comparison: removeTypenameRecursive({
					...val.comparison,
					name: `Copy of  ${val.comparison.name}`
				})
			};
			setContextMenu({
				id: null
			});
			setLoading(true);
			try {
				const resp = await saveComparison(variables);
				setLoading(false);
				if (resp?.data?.saveComparison?.status?.success) {
					fetchSavedComparisons(savedComparisonVariables());
				}
				trackSavedComparisonEvent(val.savedFor, val.type);
			} catch (err) {
				console.log(err);
			}
		}

		if (action == "delete") {
			try {
				const resp = await deleteComparison({ id: val.id });
				if (resp?.data?.deleteComparison?.status?.success) {
					fetchSavedComparisons(savedComparisonVariables());
				}
			} catch (err) {
				console.log(err);
			}
		}

		if (action == "remove") {
			let variables = {
				input: {
					comparisonId: val.id,
					bizId: String(login.loggedInbizDetail?.id),
					status: "removed"
				}
			};
			try {
				const resp = await updateLastViewed(variables);
				if (resp?.data?.updateLastViewed?.status?.success) {
					fetchSavedComparisons(savedComparisonVariables());
				}
			} catch (err) {
				console.log(err);
			}
		}
	};

	const renderMenuItems = (data) => {
		const loginUserId = login?.loginDetail?.id;
		const isCreatedBySelf = data.userId === String(loginUserId);
		return (
			<React.Fragment>
				{isCreatedBySelf && tab == "saved" ? (
					<div onClick={() => handleClick("edit", data)} className="action-item">
						Edit
					</div>
				) : null}
				{tab == "saved" && (
					<div onClick={() => handleClick("duplicate", data)} className=" action-item  ">
						Duplicate
					</div>
				)}
				{(tab == "saved" && isCreatedBySelf) || tab !== "saved" ? (
					<div
						onClick={() => handleArchiveRestoreModal(true, false, data)}
						className=" action-item"
						style={{ color: "rgb(255, 66, 92)" }}
					>
						{tab == "saved" ? "Delete" : "Remove"}
					</div>
				) : null}
			</React.Fragment>
		);
	};

	const [contextMenu, setContextMenu] = useState({ id: null });

	const closeContextMenu = (e) => {
		setContextMenu({
			id: null
		});
	};

	const openContextMenu = (record) => {
		setContextMenu({
			id: record?.id
		});
	};

	const handleMenuList = (record) => {
		return (
			<div className="create-menu">
				<span>Create a Menu</span>
			</div>
		);
	};

	const history = useHistory();

	const handleRedirect = async (metric) => {
		store.dispatch({
			type: ActionTypes.ANALYTICS_FILTERS_STATE_RESET
		});
		trackEvent("new_comparison_created", { entity: metric, module: "compare" });
		const encodedFilters = getEncodedAnalyticsFilters();
		history.push(`compare/${metric}${encodedFilters ? `?filters=${encodedFilters}&` : ""}`);
	};

	const handlePagination = (page) => {
		const offset = (page - 1) * limit;
		setOffset(offset);
	};

	const handlePageSize = (field, size) => {
		setLimit(size.value);
	};
	return (
		<>
			{loading ? (
				<div className="loading-circle">
					<Loading
						circular
						circleClass="loader-backdrop"
						svgStyle={{
							width: "80px",
							position: "absolute",
							height: window.screenTop,
							left: "54%",
							top: "50%"
						}}
						inlineStyle={{
							height: window.innerHeight,
							left: "0%",
							width: "100%",
							position: "fixed",
							zIndex: "111111"
						}}
					/>
				</div>
			) : null}

			<div className="analytics-container section-container-common compare">
				<SectionHeader
					title="Compare Performance"
					showHelpBtn={true}
					headerRight={
						<SearchFilter
							filterOption={{ field: "searchFieldValue" }}
							value={searchValue}
							setFilter={setFilter}
							placeholder="Search"
						/>
					}
					newBtn={true}
				/>
				{!lS.get("compare")?.getUserCompareVisit?.hasViewedCompare && <CompareIntro />}
				<div className="clickable-images">
					{cards.map((card) => {
						if (card.header == "Brands" && !isMultibrandEnabled) {
							return;
						}
						return (
							<div className="compare-divs" key={card.header}>
								<img src={`/assets/analytics/compare/${card.type}-compare.svg`} className="cards-img" />
								<div className="inner-cards">
									<div>
										<div className="card-header">{card.header}</div>
										<div className="card-text">
											<span className="like">like</span>
											<span className="info"> {card.text}</span>
										</div>
									</div>
									<div className="compare-btn" onClick={() => handleRedirect(card.type)}>
										Compare
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="topbar-compare">
					<Topbar tabs={FORM_TABS} selectedTab={tab} switchTab={(tab) => handleFormTab(tab)} />
				</div>

				{!loadingSavedComparisons && !errorSavedComparisons && data.length == 0 ? (
					<div className="empty-state-compare">
						<div>
							<img src="/assets/analytics/compare/women-waiting.svg" />
						</div>
						<div className="no-saved">
							{tab == "saved" ? "No saved comparison yet." : "No recently viewed comparisons yet."}{" "}
						</div>
						<div className="description">
							{tab == "saved"
								? "All saved comparisons will be listed down here"
								: "You can view up to your last 20 viewed comparisons here."}{" "}
						</div>
					</div>
				) : (
					<>
						<div className="comparison">
							<CustomTable
								loading={loadingSavedComparisons}
								columns={columns}
								data={data}
								showContextMenu={true}
								openContextMenu={openContextMenu}
								contextMenuId={contextMenu.id}
								renderMenuItems={renderMenuItems}
								closeContextMenu={closeContextMenu}
								handleMenuList={handleMenuList}
							/>
						</div>
						{!loadingSavedComparisons && !errorSavedComparisons && tab == "saved" ? (
							<div>
								<Paginator
									limit={limit}
									offset={offset}
									count={totalCount}
									goToPage={handlePagination}
									showPageSize={true}
									setPageSize={handlePageSize}
								/>
							</div>
						) : null}
						<ArchiveRestoreModal
							isOpen={archiveMenuState?.isOpen && archiveMenuState?.id}
							close={(refresh) => handleArchiveRestoreModal(false, refresh)}
							entityName={"comparison"}
							dataObject={archiveMenuState}
							mode={tab == "saved" ? "delete" : "remove"}
							entityType={tab == "saved" ? "analyticsCompareDelete" : "analyticsCompareRemoved"}
						/>
					</>
				)}
			</div>
		</>
	);
};

export default connect((store) => ({
	analyticsFiltersState: store.analyticsFiltersState,
	configItems: store.configItems,
	isMultibrandEnabled: store.login?.loggedInbizDetail?.isMultibrandEnabled,
	login: store.login,
	compareAnalytics: store.compareAnalytics
}))(Compare);
