import React, { useEffect, useState } from "react";

// third party
import { connect } from "react-redux";

//utils
import { capitaliseText, fixedToTwoDecimal } from "../../atlas-utils";
import { printCurrency } from "../SiteComp";

// services
import NotificationServices from "../../services/NotificationService";

// clients
import { clientMenu } from "../../client-menu";

// graphql
import { GET_ITEM_RULE_PREVIEW } from "../../graphql/menus";

//components
import { FormSidebar } from "../_commons/FormSidebar";
import Image from "../_commons/Image";
import LightningIcon from "../_icons/LightningIcon";

const RulePreviewDrawer = ({ isOpen = false, handleClose = () => {}, entityData = {}, currencySymbol }) => {
	const [itemPreviewDetails, setItemPreviewDetails] = useState({});

	const fetchItemPreviewDetails = async () => {
		try {
			const variables = { entityId: entityData?.entityId, menuId: entityData?.menuId };
			const { data } = await clientMenu.query({
				query: GET_ITEM_RULE_PREVIEW,
				variables,
				fetchPolicy: "no-cache"
			});

			if (data?.itemPreview) {
				setItemPreviewDetails(data.itemPreview?.[0]);
			}
		} catch (error) {
			console.error(error);
			NotificationServices.pushNotification({
				message: "Failed to get item rule preview details",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
			setItemPreviewDetails({});
			handleDrawerClose();
		}
	};

	useEffect(() => {
		fetchItemPreviewDetails();
	}, [entityData]);

	const renderEntityDetails = (values) => (
		<div className="entity-details-container">
			<div className="image-container">
				<Image
					alt={values?.name || "image"}
					className="entity-image"
					src={values?.imageUrl || "/assets/icons/icon-empty-img.svg"}
				/>
			</div>
			<div className="entity-info-container">
				<div className="entity-name">{values?.name || "--"}</div>
				<div className="entity-description">{values?.description || "--"}</div>
				<div className="entity-pricing-container">
					<span className="markup-price">
						{printCurrency(currencySymbol)}
						{fixedToTwoDecimal(values?.markupPrice) ?? "--"}
					</span>
					<span className="default-price">
						{printCurrency(currencySymbol)}
						{fixedToTwoDecimal(values?.price) ?? "--"}
					</span>
				</div>
			</div>
		</div>
	);
	const handleDrawerClose = () => {
		handleClose && handleClose();
		setItemPreviewDetails({});
	};

	return (
		<div className="rule-preview-drawer">
			<FormSidebar
				close={handleDrawerClose}
				isOpen={isOpen}
				title={`${capitaliseText(entityData?.entityName?.toLowerCase())} Rule`}
				hideActions
				overlaySidebarClasses="medium"
			>
				<div className="rule-preview-data-wrapper">
					<p className="preview-heading">Preview</p>

					<div className="status-container">
						<span className="status default">Default</span>
					</div>
					{renderEntityDetails(itemPreviewDetails?.beforeValues)}

					<div className="rule-applied-badge">
						<LightningIcon fillColor="#6D7AA8" />
						<p>Rule Applied</p>
					</div>
					{renderEntityDetails(itemPreviewDetails?.afterValues)}
				</div>
			</FormSidebar>
		</div>
	);
};

const mapStateToProps = (store) => ({
	currencySymbol: store.login.loggedInbizDetail.currencySymbol
});

export default connect(mapStateToProps)(RulePreviewDrawer);
