import React, { useEffect, useMemo, useRef, useState } from "react";
import { fetchBizPlatforms, fetchStores, fetchStoresDebounced, fetchBrands } from "../../actions/actions";
import { connect } from "react-redux";
import Popover from "../_commons/Popover";
import { SelectFilterCustom } from "../_commons/SelectFilterCustom";
import { CUSTOM_TYPES, DATE_TYPES, NewDateCompareFilter, PRESET_TYPES } from "../_commons/NewDateCompareFilter";
import moment from "moment";
import { SelectFilter } from "../_commons/SelectFilter";
import { CustomTable } from "../_commons/CustomTable";
import { client } from "../../client";
import { ITEM_ACTION_LOGS } from "../../graphql/items";
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import { Paginator } from "../_commons/Paginator";
import { debounce } from "lodash";
import { store } from "../../store/configureStore";
import { extractInitials } from "../../atlas-utils";

const INITIAL_DATE_FILTER_STATE = {
	currentDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[6].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[6],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	},
	appliedDateFilter: {
		current: {
			dateFilter: PRESET_TYPES[6].value,
			dateTypeSelected: DATE_TYPES[0],
			presetTypeSelected: PRESET_TYPES[6],
			rangeStartDate: undefined,
			rangeEndDate: undefined,
			customTypeSelected: CUSTOM_TYPES[0]
		}
	}
};

const columns = [
	{
		name: "Action",
		field: "action",
		render: (render, i) => {
			const pltObj = (store.getState().configItems?.bizPlatforms?.items || []).filter((plt) => {
				return plt.platformName.toLowerCase() === render?.platform;
			});
			const timeToDisplay = moment(render?.timestamp).format("DD MMM, YYYY [AT] hh:mm A");
			return (
				<div className="table-cell action" key={i}>
					{pltObj?.[0]?.image && String(pltObj?.[0]?.image) ? (
						<img className="img-div" src={String(pltObj?.[0]?.image)} />
					) : (
						<img className="img-div" src={CATALOGUE_PLATFORMS_LOGO[render?.platform || "default"]} />
					)}
					<div className="action-info">
						<div className="action-txt">{render?.action}</div>
						<div className="timestamp">{timeToDisplay ? timeToDisplay.toUpperCase() : "--"}</div>
					</div>
				</div>
			);
		}
	},
	{
		name: "Performed by",
		field: "userName",
		render: (render, i) => {
			const userName = render.userName;
			const renderPopover = (data) => {
				return <div className="renderpopover"> {data}</div>;
			};
			return (
				<div className="table-cell userName" key={i}>
					{(userName || "").length > 30 ? (
						<Popover data={userName} showOnHover={true} showOnClick renderPopover={renderPopover}>
							{userName.slice(0, 30)}...
						</Popover>
					) : (
						<span>{userName ? userName : "--"}</span>
					)}
				</div>
			);
		}
	},
	{
		name: "Location",
		field: "locationName",
		render: (render, i) => {
			const locationName = render?.locationName;
			const renderPopover = (data) => {
				return <div className="renderpopover"> {data}</div>;
			};
			return (
				<div className="table-cell locationName" key={i}>
					{(locationName || "").length > 30 ? (
						<Popover data={locationName} showOnHover={true} showOnClick renderPopover={renderPopover}>
							{locationName.slice(0, 30)}...
						</Popover>
					) : (
						<span>{locationName ? locationName : "--"}</span>
					)}
				</div>
			);
		}
	},
	{
		name: "Status",
		field: "status",
		render: (render, i) => (
			<div className={`table-cell status `} key={i}>
				<span className={`${render?.status}`}>{render?.status ? render?.status : "--"}</span>
			</div>
		)
	},
	{
		name: "Message",
		field: "message",
		render: (render, i) => {
			const message = render.message;
			const renderPopover = (data) => {
				return <div className="renderpopover"> {data}</div>;
			};
			return (
				<div className="table-cell message" key={i}>
					{message.length > 90 ? (
						<Popover data={message} showOnHover={true} showOnClick renderPopover={renderPopover}>
							{message.slice(0, 90)}...
						</Popover>
					) : (
						<>{message ? message : "--"} </>
					)}
				</div>
			);
		}
	}
];

const Logs = ({ configItems, login, itemId, isMultibrandEnabled }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState({});
	const [dateFilter, setDateFilter] = useState(INITIAL_DATE_FILTER_STATE);
	const [limit, setLimit] = useState(10);
	const [offset, setOffset] = useState(0);
	const [variables, setVariables] = useState({
		platform_names: "all",
		location_id: "all",
		action: "all",
		status: "all"
	});
	const [selectedBrand, setSelectedBrand] = useState({
		id: "all",
		name: "All Brands",
		image: "/assets/icons/icon-brands.svg"
	});
	const fetchTimestampRef = useRef(0);
	const fetchData = async (data) => {
		setLoading(true);
		let duration = {};
		const currentTimestamp = Date.now();
		fetchTimestampRef.current = currentTimestamp;
		if (dateFilter?.appliedDateFilter?.current?.dateTypeSelected?.label.toLowerCase() == "preset") {
			duration["preset"] = dateFilter?.appliedDateFilter?.current?.dateFilter;
		} else {
			const [start, end] = dateFilter?.appliedDateFilter?.current?.dateFilter.split(",");
			duration["custom"] = {
				startDate: start,
				endDate: end
			};
		}
		let filters = [
			{
				field: "item_id",
				value: itemId
			}
		];
		if (data?.status !== "all") {
			filters.push({ field: "item_action_status", value: data?.status });
		}
		if (data?.action !== "all") {
			filters.push({ field: "item_action", value: data?.action });
		}
		if (data?.platform_names !== "all") {
			filters.push({ field: "platform_names", value: (data?.platform_names).toLowerCase() });
		}
		if (data?.location_id !== "all") {
			filters.push({ field: "location_id", value: data?.location_id });
		}
		if (isMultibrandEnabled) {
			if (selectedBrand?.id !== "all") {
				filters.push({ field: "brand_id", value: selectedBrand?.id });
			}
		}

		let queryVariables = {
			query: "item_action_logs_view",
			filters,
			requiredFilters: {
				bizId: String(login?.loggedInbizDetail?.id),
				duration,
				limit,
				offset
			}
		};
		try {
			const resp = await client.query({
				query: ITEM_ACTION_LOGS,
				variables: queryVariables,
				fetchPolicy: "no-cache"
			});
			if (currentTimestamp !== fetchTimestampRef.current) {
				return;
			}
			setData(resp.data);
			setLoading(false);
		} catch (err) {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData(variables);
	}, [
		variables?.platform_names,
		variables?.location_id,
		variables?.action,
		variables?.status,
		dateFilter?.appliedDateFilter?.current?.dateFilter,
		limit,
		offset,
		selectedBrand?.id
	]);

	useEffect(() => {
		fetchBizPlatforms(true, true, true);
		fetchStores("");
		isMultibrandEnabled && fetchBrands("", true);
	}, []);

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
	};

	const handlePageSize = (field, size) => {
		if (size && size?.value !== limit) {
			setOffset(0);
			setLimit(size.value);
		}
	};
	const placeholderContent = {
		placeholderText: "No logs Available",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-logs.svg",
		placeholderSubtext: "Logs are unavailable for the selected filters. Please adjust the filters and try again",
		size: "medium"
	};

	return (
		<>
			<div className="logs-container">
				<div>
					<LogsFilters
						setOffset={setOffset}
						variables={variables}
						setVariables={setVariables}
						configItems={configItems}
						dateFilter={dateFilter}
						setDateFilter={setDateFilter}
						isMultibrandEnabled={isMultibrandEnabled}
						selectedBrand={selectedBrand}
						setSelectedBrand={setSelectedBrand}
					/>
					<CustomTable
						loading={loading}
						classes="item-action-logs-table"
						data={data?.getItemActionLogs?.objects[0]?.objects || []}
						columns={columns}
						showPlaceholder
						placeholderContent={placeholderContent}
					/>
				</div>
			</div>

			{(data?.getItemActionLogs?.objects[0]?.objects || []).length > 0 && (
				<Pagination
					limit={limit}
					offset={offset}
					handlePageSize={handlePageSize}
					handlePagination={handlePagination}
					count={data?.getItemActionLogs?.objects[0]?.pagination?.count || 0}
				/>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		configItems: state.configItems,
		login: state.login,
		isMultibrandEnabled: state.login?.loggedInbizDetail?.isMultibrandEnabled,
		periscopeDataResponse: state.periscopeDataResponse,
		periscopeFilters: state.periscopeFilters
	};
};
export default connect(mapStateToProps)(Logs);

export const LogsFilters = ({
	configItems,
	variables,
	setVariables,
	dateFilter,
	setDateFilter,
	setOffset,
	isMultibrandEnabled,
	selectedBrand,
	setSelectedBrand
}) => {
	const setDateRangeFilter = (payload) => {
		setOffset(0);
		setDateFilter({
			...dateFilter,
			...payload
		});
	};

	const [selectedLocations, setSelectedLocations] = useState([]);
	const [selectedPlatforms, setSelectedPlatforms] = useState([]);
	const actionOptions = useMemo(() => {
		return [
			{ id: "all", name: "All" },
			{ id: "stock-in", name: "Stock-in" },
			{ id: "stock-out", name: "Stock-out" }
		];
	}, []);

	const statusOptions = useMemo(() => {
		return [
			{ value: "all", valueForDisplay: "All" },
			{ value: "success", valueForDisplay: "Success" },
			{ value: "failed", valueForDisplay: "Failed" }
		];
	});
	const [action, setAction] = useState(actionOptions[0]);
	const [status, setStatus] = useState(statusOptions[0]);
	const [optionUpdates, setOptionUpdates] = useState({
		location_id: {},
		platform_names: {}
	});

	const [isCheckedAll, setIsCheckedAll] = useState({
		platform_names: true,
		location_id: true,
		action_id: true
	});

	const handleCheckAll = (state, field, options, props) => {
		if (Object.keys(optionUpdates[field] || []).filter((val) => optionUpdates[field]?.[val]).length > 0) {
			let _optionUpdates = { ...optionUpdates };
			let _isCheckedAllUpdates = { ...isCheckedAll };
			_isCheckedAllUpdates[field] = state;
			_optionUpdates[field] = {};
			setOptionUpdates(_optionUpdates);
			setIsCheckedAll(_isCheckedAllUpdates);
		}
	};

	const locationsFiltersRef = useRef([]);
	const handleCheck = (state, field, option, props) => {
		let _isCheckedAllUpdates = { ...isCheckedAll };
		let id = "id";
		if (field == "platform_names") {
			id = "platformName";
		}
		if (field === "location_id") {
			if (state) {
				locationsFiltersRef.current.push(option);
			} else {
				locationsFiltersRef.current = locationsFiltersRef.current.filter((item) => {
					if (item?.id == option.id || !optionUpdates?.["location_id"][item?.id]) {
						return false;
					}
					return true;
				});
			}
		}
		let _optionUpdates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[String(option?.[id])]: state
			}
		};
		if (state && _isCheckedAllUpdates[field]) {
			_isCheckedAllUpdates[field] = !state;
			setIsCheckedAll(_isCheckedAllUpdates);
		}
		if (
			!state &&
			Object.keys(_optionUpdates[field] || {}).filter((item) => _optionUpdates[field][item]).length == 0
		) {
			handleCheckAll(true, field);
		}
		setOptionUpdates(_optionUpdates);
	};

	const prevFiltersRef = useRef({ location_id: "all", platform_names: "all" });
	const handleSelectFilterState = (isOpen, field) => {
		if (!isOpen) {
			if (field && optionUpdates?.[field]) {
				let value;
				let currFieldValue = Object.keys(optionUpdates?.[field])
					.filter((key) => {
						return optionUpdates?.[field]?.[key];
					})
					.join(",");
				currFieldValue = currFieldValue == "" ? "all" : currFieldValue;
				const filterRefField = prevFiltersRef.current?.[field];
				if (currFieldValue !== filterRefField) {
					setOffset(0);
				}
				if (isCheckedAll?.[field]) {
					value = "all";
					if (field == "location_id" && selectedLocations.length > 0) {
						setSelectedLocations([]);
					}
					if (field == "platform_names" && selectedPlatforms.length > 0) {
						setSelectedPlatforms([]);
					}
				} else {
					let finalValues = [];
					const currOption = optionUpdates?.[field];
					value = (Object.keys(currOption) || [])
						.reduce((acc, curr) => {
							if (currOption?.[curr]) {
								acc.push(curr);
								let items;
								let id;
								if (field === "platform_names") {
									items = configItems?.bizPlatforms?.items;
									id = "platformName";
								} else if (field === "location_id") {
									items = locationsFiltersRef.current;
									id = "id";
								}
								const currentObj = (items || []).filter((item) => {
									return item?.[id] == curr;
								});
								if (currentObj.length > 0) {
									finalValues.push(currentObj[0]);
								}
							}
							return acc;
						}, [])
						.join(",");
					if (field === "platform_names") {
						setSelectedPlatforms(finalValues);
					} else if (field === "location_id") {
						setSelectedLocations(finalValues);
					}
				}
				value = value == "" ? "all" : value;
				prevFiltersRef.current = { ...variables, [field]: value };
				setVariables((variables) => ({ ...variables, [field]: value }));
			}
		}
	};

	const setFilter = (field, value) => {
		let val;
		if (field == "status") {
			val = "value";
			setStatus(value);
		} else if (field == "action") {
			val = "id";
			setAction(value);
		}
		if (variables?.[field] != value?.[val]) {
			setOffset(0);
		}
		setVariables((variables) => ({ ...variables, [field]: value?.[val] }));
	};

	const handleStoreSearch = (text) => {
		let includeAll = true;
		if (text) {
			includeAll = false;
		}
		fetchStoresDebounced(text, 50, null, includeAll);
	};
	const selectedLocationIds = new Set(selectedLocations.map((item) => item.id));
	const selectedPlatformIds = new Set(selectedPlatforms.map((item) => item.id));
	const handleBrandsLabelOption = (brandLocation) => {
		return (
			<React.Fragment>
				<div className={"logo " + brandLocation.color}>
					{brandLocation.image ? (
						<img src={brandLocation.image} alt="" />
					) : (
						extractInitials(brandLocation?.name?.split(" "))
					)}
				</div>
				<div title={brandLocation.name}>
					{brandLocation.name && brandLocation.name.length > 35
						? brandLocation.name.slice(0, 35) + "..."
						: brandLocation.name}
				</div>
			</React.Fragment>
		);
	};

	const renderPopover = (value) => {
		return (
			<div className="popover-info-brand">
				<div className="brand-title">{value?.title}</div>
				<div className="brand-desc"> {value.description} </div>
			</div>
		);
	};

	const [close, setClose] = useState(true);
	const handlePopoverClose = () => {
		setClose(false);
	};
	const handleBrandLocation = (value) => {
		setSelectedBrand(value);
	};

	return (
		<div>
			<div className="brand">
				{isMultibrandEnabled && configItems.brands.items.length > 0 && (
					<>
						<Popover
							open={close}
							data={{
								title: "Switching between brands",
								description: "Decide which brand's information you want to see by switching it here"
							}}
							renderPopover={renderPopover}
							showClose={true}
							onClose={handlePopoverClose}
							position="down-left"
						>
							<SelectFilterCustom
								options={configItems.brands.items}
								loading={configItems.brands?.isloading}
								field="selectedBrandLocation"
								currValue={selectedBrand}
								setFilter={(f, value) => handleBrandLocation(value)}
								labelKey={"name"}
								valueKey={"id"}
								isSearchable={false}
								customLabel={true}
								customOptions={true}
								isClearable={false}
								renderLabel={handleBrandsLabelOption}
								renderOptions={handleBrandsLabelOption}
								placeholder="Select brand"
								classes="brand-selector"
							/>
						</Popover>
					</>
				)}
			</div>

			<div className="filters">
				<div className="row">
					<SelectFilterCustom
						options={[
							...selectedLocations,
							...(configItems?.stores?.items?.length > 0 && configItems.stores.items[0]?.id === "all"
								? configItems.stores.items.slice(1)
								: configItems?.stores?.items || []
							).filter((item) => !selectedLocationIds.has(item.id))
						]}
						isLoading={configItems?.stores?.isLoading}
						field="location_id"
						labelKey={"name"}
						valueKey={"id"}
						multi={true}
						showCheckBox={true}
						showSelectAllOption
						optionUpdates={optionUpdates}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						placeholder={"Select locations"}
						selectAllPlaceholder={"All Locations"}
						countDisplayText={"location"}
						handleSelectFilterState={handleSelectFilterState}
						selected=""
						handleSearch={handleStoreSearch}
					/>
				</div>
				<div className="row platform-filter">
					<SelectFilterCustom
						selected=""
						options={[
							...selectedPlatforms,
							...((configItems?.bizPlatforms?.items || [])?.[0]?.id == "all" &&
							configItems?.bizPlatforms?.items.length > 0
								? configItems?.bizPlatforms?.items.slice(1)
								: configItems?.bizPlatforms?.items || []
							).filter((item) => !selectedPlatformIds.has(item.id))
						]}
						isLoading={configItems?.bizPlatforms?.isLoading}
						field="platform_names"
						labelKey={"platformName"}
						valueKey={"platformName"}
						multi={true}
						showIcon={true}
						showCheckBox={true}
						showSelectAllOption
						optionUpdates={optionUpdates}
						handleCheck={handleCheck}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						placeholder={"Select platforms"}
						selectAllPlaceholder={
							<>
								<div className="option-icon">
									<img src={"/assets/icons/icon-globe.svg"} alt="" />
								</div>
								<div>All Platforms</div>
							</>
						}
						countDisplayText={"Platform"}
						handleSelectFilterState={handleSelectFilterState}
					/>
				</div>
				<div className="row action">
					<SelectFilter
						options={actionOptions || []}
						currValue={action}
						field="action"
						labelKey="name"
						valueKey="id"
						setFilter={setFilter}
						isClearable={false}
						customDropdownLabel={
							<div className="action-head">
								<span>Action</span> <span className="custom-action">{action.name}</span>
							</div>
						}
					/>
				</div>
				<div className="row status">
					<SelectFilter
						options={statusOptions || []}
						currValue={status}
						field="status"
						labelKey="valueForDisplay"
						valueKey="value"
						setFilter={setFilter}
						isClearable={false}
						customDropdownLabel={
							<div className="status-head">
								Status <span className="custom-status">{status?.valueForDisplay}</span>
							</div>
						}
					/>
				</div>
				<NewDateCompareFilter
					showDropdown={true}
					hideComparison={true}
					includeAllTime={false}
					currentDateFilter={dateFilter?.currentDateFilter}
					appliedDateFilter={dateFilter?.appliedDateFilter}
					updateState={setDateRangeFilter}
					position={"right"}
					monthsShown={1}
					defaultCustomRange={10}
					minDate={moment().subtract(12, "months")}
				/>
			</div>
		</div>
	);
};

export const Pagination = ({ limit, offset, handlePageSize, handlePagination, count }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		const handleScroll = debounce(() => {
			const formContainer = document.querySelector(".form-container");

			if (formContainer) {
				const scrollTop = formContainer.scrollTop;
				const clientHeight = formContainer.clientHeight;
				const scrollHeight = formContainer.scrollHeight;
				const hasReachedBottom = scrollTop + clientHeight >= scrollHeight - 3;
				if (hasReachedBottom && !show) {
					setShow(true);
				} else if (!hasReachedBottom && show) {
					setShow(false);
				}
			}
		});

		const formContainer = document.querySelector(".form-container");
		if (formContainer) {
			formContainer.addEventListener("scroll", handleScroll);
		}

		return () => {
			if (formContainer) {
				formContainer.removeEventListener("scroll", handleScroll);
			}
		};
	}, [show]);

	return (
		<div className="pagination-div">
			{show && (
				<Paginator
					limit={limit}
					offset={offset}
					count={count}
					goToPage={handlePagination}
					setPageSize={handlePageSize}
					showPageSize={true}
				/>
			)}
		</div>
	);
};
