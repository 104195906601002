import React from "react";

// clients
import { store } from "../store/configureStore";

// third party
import moment from "moment";
import { mapKeys, camelCase } from "lodash";

// utils
import { lS, capitaliseText, trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "./_types";

// services
import NotificationServices from "../services/NotificationService";

// constant
export let websocketInterval;

// constants
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_NOTIFICATIONS;
const WEBSOCKET_STATE = {
	0: false, // CONNECTING
	1: true, // OPEN
	2: false, // CLOSING
	3: false // CLOSED
};
export const fetchPeriscopeData = (bizId) => {
	let heartbeatInterval;
	let reconnectInterval = 1000; // Initial reconnect interval in ms
	let websocket;
	const WebsocketUrl = process.env.REACT_APP_PERISCOPE_WEBSOCKET_URL;

	const connectWebSocket = () => {
		try {
			websocket = new WebSocket(`${WebsocketUrl}/ws/${bizId}`);
			websocket.onopen = function (event) {
				clearInterval(heartbeatInterval);
				heartbeatInterval = setInterval(() => {
					if (websocket.readyState === WebSocket.OPEN) {
						websocket.send("ping");
					}
				}, 60000);
				reconnectInterval = 1000; // Reset reconnect interval on successful connection
			};

			websocket.onmessage = function (event) {
				if (event.data === "ping") {
					// console.log("ping");
				} else {
					const data = JSON.parse(event?.data);
					if (data) {
						let periscopeDataFetch = data?.status === "success";
						store.dispatch({
							type: "PERISCOPE_RESPONSE_DATA_CHANGE",
							payload: { ...data, periscopeDataFetch }
						});
					}
				}
			};

			websocket.onclose = function (event) {
				clearInterval(heartbeatInterval);
				attemptReconnect();
			};

			websocket.onerror = function (error) {
				websocket.close();
			};
		} catch (err) {
			attemptReconnect();
		}
	};

	const attemptReconnect = () => {
		setTimeout(() => {
			connectWebSocket();
		}, reconnectInterval);
		reconnectInterval = Math.min(reconnectInterval * 2, 30000); // Exponential backoff with max interval of 30 seconds
	};

	connectWebSocket();
};

export const fetchNotifications = (bizId, count = 1) => {
	if (bizId && count <= 3) {
		store.dispatch({
			type: ActionTypes.GET_NOTIFICATIONS_LIST_REQUEST
		});
		try {
			const webSocket = new WebSocket(`${WEBSOCKET_URL}${bizId}/`);
			// console.log("ws open")

			// receive messages from websocket
			webSocket.onmessage = (e) => {
				const data = {
					...JSON.parse(e.data),
					isRead: false,
					hide: false,
					hideEntity: false,
					dateTime: moment()
				};
				// perform certain actions based on notification, modify/update notification payload
				const notification = notificationActions(data);
				// update notifications state
				store.dispatch({
					type: ActionTypes.GET_NOTIFICATIONS_LIST_SUCCESS,
					payload: notification
				});
				if (!notification?.hide) {
					// get saved notifications from local storage
					let savedNotifications = lS.get("notifications")
						? (lS.get("notifications")?.[bizId] || [])?.slice(0, 10)
						: [];
					if (savedNotifications?.length > 9) {
						// remove last and oldest saved notification
						savedNotifications.pop();
					}
					// push latest notification on top
					savedNotifications.unshift(notification);
					// update notifications in local storage
					lS.set("notifications", {
						...(lS.get("notifications") ?? {}),
						[bizId]: [...savedNotifications]
					});
				}
			};

			// close websocket connection
			webSocket.onclose = (e) => {
				window.websocket = undefined;
				// console.log("ws close")
				if (window?.webSocket?.forceClose) {
					clearInterval(websocketInterval);
					return;
				}
				// clear interval and re-establish connection
				clearInterval(websocketInterval);
				if (e.wasClean) {
					fetchNotifications(bizId);
				} else if (e.code === 1006) {
					fetchNotifications(bizId, count + 1);
				}
			};

			// ping server every 90sec to keep websocket alive, or else it expires and closes after 100sec
			webSocket.onopen = (e) => {
				clearInterval(websocketInterval);
				websocketInterval = setInterval(() => {
					if (webSocket.readyState === 1) {
						webSocket.send(JSON.stringify({ type: "ping" }));
					} else {
						webSocket.close();
					}
				}, 90000);
				window.webSocket = webSocket;
			};
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.GET_NOTIFICATIONS_LIST_FAILURE,
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
	}
};

export const handleNotification = (payload = {}) => {
	const data = {
		...payload,
		isRead: false,
		hide: false,
		hideEntity: false,
		dateTime: moment()
	};
	// perform certain actions based on notification, modify/update notification payload
	const notification = notificationActions(data);
	// update notifications state
	store.dispatch({
		type: ActionTypes.GET_NOTIFICATIONS_LIST_SUCCESS,
		payload: notification
	});
	if (!notification?.overrideHide && !notification?.hide) {
		const bizId = store.getState().login?.loggedInbizDetail?.id;
		// get saved notifications from local storage
		let savedNotifications = lS.get("notifications") ? (lS.get("notifications")?.[bizId] || [])?.slice(0, 10) : [];
		if (savedNotifications?.length > 9) {
			// remove last and oldest saved notification
			savedNotifications.pop();
		}
		// push latest notification on top
		savedNotifications.unshift(notification);
		// update notifications in local storage
		lS.set("notifications", {
			...(lS.get("notifications") ?? {}),
			[bizId]: [...savedNotifications]
		});
	}
};

export const notificationActions = (notification = {}) => {
	if (notification.payload) {
		const { entity, id, name, status, reference_id } = notification.payload;
		if (!entity) {
			// hide notificiation
			notification.hide = true;
		}
		switch (entity) {
			case "catalogue-backup":
				if (status === "BACKUP_STARTED") {
					store.dispatch({
						type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
						payload: {
							status: "BACKING_UP",
							catalogue_backup_id: parseInt(id),
							name: name,
							message: "Creating backup",
							restrict: true
						}
					});
					// hide notification
					notification.hide = true;
				} else if (status === "RESTORE_STARTED") {
					store.dispatch({
						type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
						payload: {
							status: "RESTORING",
							catalogue_backup_id: parseInt(id),
							name: name,
							message: "Restoring backup",
							restrict: true
						}
					});
					// hide notification
					notification.hide = true;
				} else if (status === "BACKUP_COMPLETED" || status === "RESTORE_COMPLETED") {
					store.dispatch({
						type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
						payload: {
							status: null,
							catalogue_backup_id: null,
							name: "",
							message: "",
							restrict: false
						}
					});
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: `Backup ${status === "BACKUP_COMPLETED" ? "created" : "restored"} successfully.`,
							timeout: 5000,
							error: false
						}
					});
					// show notification
					notification.hide = false;
				} else if (status === "BACKUP_FAILED" || status === "RESTORE_FAILED") {
					store.dispatch({
						type: ActionTypes.UPDATE_CATALOGUE_BACKUPS_STATUS,
						payload: {
							status: null,
							catalogue_backup_id: null,
							name: "",
							message: "",
							restrict: false
						}
					});
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: `${status === "BACKUP_FAILED" ? "Creating" : "Restoring"} backup failed.`,
							timeout: 5000,
							error: true
						}
					});
					// show notification
					notification.hide = false;
				}
				break;
			case "create-root-location":
				if (status === "QUEUED") {
					store.dispatch({
						type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
						payload: {
							status: status,
							reference_id: reference_id,
							restrict: true
						}
					});
					// hide notification
					notification.hide = true;
				} else if (status === "PROCESSING") {
					const { created_locations } = store.getState().createLocationStatus;
					const total_locations =
						notification.payload?.total_locations || store.getState().createLocationStatus?.total_locations;
					store.dispatch({
						type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
						payload: {
							status: status,
							reference_id: reference_id,
							total_locations,
							processed_locations: notification.payload?.processed_locations,
							created_locations:
								notification.payload?.created_locations?.length > 0
									? notification.payload?.created_locations
									: [...created_locations, { id, name }],
							restrict: true
						}
					});
					// hide notification
					notification.hide = true;
				} else if (status === "SUCCESS") {
					const total_locations =
						notification.payload?.total_locations || store.getState().createLocationStatus?.total_locations;
					const processed_locations =
						notification.payload?.processed_locations ||
						store.getState().createLocationStatus?.processed_locations;
					const created_locations =
						notification.payload?.created_locations ||
						store.getState().createLocationStatus?.created_locations;
					store.dispatch({
						type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
						payload: {
							status: status,
							reference_id: reference_id,
							restrict: false,
							total_locations,
							processed_locations,
							created_locations
						}
					});
					const message = `New ${total_locations > 1 ? "locations" : "location"} created successfully.`;
					notification.message = message;
					// store.dispatch({
					// 	type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					// 	payload: {
					// 		message,
					// 		timeout: 5000,
					// 		error: false,
					// 	}
					// });
					// show notification
					notification.hide = notification.overrideHide || false;
				} else if (status === "FAILED") {
					const { total_locations } = store.getState().createLocationStatus;
					store.dispatch({
						type: ActionTypes.UPDATE_CREATE_LOCATION_STATUS,
						payload: {
							status: status,
							reference_id: reference_id,
							restrict: false
						}
					});
					const message = `Create new ${total_locations > 1 ? "locations" : "location"} request failed.`;
					notification.message = message;
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message,
							timeout: 5000,
							error: true
						}
					});
					// show notification
					notification.hide = false;
				}
				break;
			case "request-to-go-live":
				NotificationServices.pushNotification({
					message: notification?.message || "",
					timeout: 5000,
					type: notification?.success ? "success" : "error",
					isClosable: true,
					theme: "dark"
				});
				break;

			case "on-demand-menu-verification":
				NotificationServices.pushNotification({
					message: notification?.message || "",
					timeout: 5000,
					type: notification?.success ? "success" : "error",
					isClosable: true,
					theme: "dark"
				});
				break;

			case "create-brand-location":
				store.dispatch({
					type: ActionTypes.UPDATE_ASSOCIATED_BRAND_LOCATIONS_STATE,
					payload: {
						status: status,
						currentAssociatedLocationsCount:
							notification?.payload?.current_location ||
							(status === "PROCESSED"
								? notification?.payload?.total_locations
								: store.getState()?.brandsCreationLocationAssociationState
										?.currentAssociatedLocationsCount),
						totalAssociatedLocationsCount: notification?.payload?.total_locations
					}
				});
				notification.hide = true;
				break;

			case "consolidated-recon-report":
				if (status === "GENERATED") {
					const { emails, start_date, end_date, platform } = notification.payload;
					const message = () => {
						return (
							<div>
								Consolidated report from {moment(start_date).format("Do MMMM YYYY")} to{" "}
								{moment(end_date).format("Do MMMM YYYY")} for {capitaliseText(platform)} has been sent
								to{" "}
								{emails.map((email, i) => {
									if (emails.length > 1 && i === emails.length - 2) {
										return (
											<span>
												<b key={i}>{email}</b>
												{" and "}
											</span>
										);
									} else if (emails.length > 1 && i !== emails.length - 1) {
										return (
											<span>
												<b key={i}>{email}</b>
												{", "}
											</span>
										);
									} else if (emails.length === 1 || i === emails.length - 1) {
										return <b key={i}>{email}</b>;
									}
								})}
							</div>
						);
					};
					// show global notification using NotificationServices
					NotificationServices.pushNotification({
						message: message(),
						timeout: 5000,
						type: "success",
						isClosable: true,
						theme: "dark"
					});
					// show notification
					notification.hide = false;
				} else if (status === "FAILED") {
					// hide notification
					notification.hide = true;
				}
				break;

			case "eis-status-change":
				if (status === "FAILED") {
					NotificationServices.pushNotification({
						message: notification?.message || "",
						timeout: 5000,
						type: "error",
						isClosable: true,
						theme: "dark"
					});
				}
				break;

			case "menu-pull-status":
				store.dispatch({
					type: ActionTypes.GET_MENU_PULL_INFO_UPDATE,
					payload: notification.payload
				});
				break;

			case "dsp-location-status-updated":
				const { status_mapping } = notification.payload;
				store.dispatch({
					type: ActionTypes.UPDATE_DSP_INTEGRATION_STATE,
					payload: {
						aggregatedStatus:
							status_mapping?.length > 0
								? status_mapping.map((el) => mapKeys(el, (val, key) => camelCase(key)))
								: []
					}
				});
				// hide notification
				notification.hide = true;
				break;

			case "report-download-ready":
				const { user_id, job_id, download_url, emails } = notification.payload;
				const loggedInUserId = store.getState()?.login?.loginDetail?.id;

				// since report generation and download is user specific,
				// check and show below only if logged in user id is matching.
				// if emails is not empty, it means that the user requested the report
				// to be sent to the emails, hence this step can be ignored.
				if (loggedInUserId === user_id && !emails?.length && status !== "pending") {
					// download generated report
					const link = document.createElement("a");
					link.href = download_url;
					link.click();

					setTimeout(() => {
						// show global notification using NotificationServices
						NotificationServices.pushNotification({
							message: status === "failed" ? "Report generation failed" : "Report generated successfully",
							timeout: 3000,
							type: status === "failed" ? "error" : "success",
							isClosable: true,
							theme: "dark"
						});

						// update task in ongoing tasks list
						store.dispatch({
							type: ActionTypes.UPDATE_TASK_IN_ONGOING_TASKS_LIST,
							payload: {
								id: job_id,
								status
							}
						});
					}, 1000);
				}
				// hide notification
				notification.hide = true;
				break;

			case "url-menu-pull-indexing-status":
			case "url-menu-pull-ingestion-status":
				if (["MENU_URL_PULL_FAILED", "MENU_CREATION_FAILED"].includes(notification.message)) {
					store.dispatch({
						type: ActionTypes.MENU_PULL_FROM_URL_STATUS_UPDATE,
						payload: {
							status: "failure",
							brandId: notification?.payload?.brand_id ?? null
						}
					});
				} else if (notification.message == "MENU_CREATION_SUCCESS") {
					store.dispatch({
						type: ActionTypes.MENU_PULL_FROM_URL_STATUS_UPDATE,
						payload: {
							status: "success",
							brandId: notification?.payload?.brand_id ?? null
						}
					});

					// track event
					trackEvent("onboarding_menu_complete", {});
				}

				notification.hide = true;

			default:
				// hide notification
				notification.hide = true;
				break;
		}
	} else {
		// hide notification
		notification.hide = true;
	}
	return { ...notification };
};

const highlightSuccessFailureTextRtgl = (message) => {
	if (message.includes("successful")) {
		const splitMessages = message.split("successful");
		return (
			<span>
				<span>{splitMessages?.[0] || ""}</span>
				<span style={{ color: "#2ecc71" }}>
					<strong>successful</strong>
				</span>
				<span>{splitMessages?.[1] || ""}</span>
			</span>
		);
	} else if (message.includes("failed")) {
		const splitMessages = message.split("failed");
		return (
			<span>
				<span>{splitMessages?.[0] || ""}</span>
				<span style={{ color: "#ff425c" }}>
					<strong>failed</strong>
				</span>
				<span>{splitMessages?.[1] || ""}</span>
			</span>
		);
	} else {
		return message;
	}
};

export const renderNotificationMessage = (notification, handleNotification, index) => {
	if (notification?.payload?.entity) {
		switch (notification?.payload?.entity) {
			case "consolidated-recon-report":
				const { emails, start_date, end_date, platform } = notification.payload;
				return (
					<div>
						Consolidated report from {moment(start_date).format("Do MMMM YYYY")} to{" "}
						{moment(end_date).format("Do MMMM YYYY")} for {capitaliseText(platform)} has been sent to{" "}
						{emails.map((email, i) => {
							if (emails.length > 1 && i === emails.length - 2) {
								return (
									<span>
										<b key={i}>{email}</b>
										{" and "}
									</span>
								);
							} else if (emails.length > 1 && i !== emails.length - 1) {
								return (
									<span>
										<b key={i}>{email}</b>
										{", "}
									</span>
								);
							} else if (emails.length === 1 || i === emails.length - 1) {
								return <b key={i}>{email}</b>;
							}
						})}
					</div>
				);

			case "request-to-go-live":
				return (
					<React.Fragment>
						{notification?.payload?.title || notification?.payload?.name ? (
							<div>
								{notification.message}:{" "}
								<span className="link-text" onClick={() => handleNotification(notification, index)}>
									{notification?.payload?.title || notification?.payload?.name}
								</span>
							</div>
						) : (
							<div>{highlightSuccessFailureTextRtgl(notification.message)}</div>
						)}
					</React.Fragment>
				);
			default:
				return (
					<React.Fragment>
						{notification?.payload?.title || notification?.payload?.name ? (
							<div>
								{notification.message}:{" "}
								<span className="link-text" onClick={() => handleNotification(notification, index)}>
									{notification?.payload?.title || notification?.payload?.name}
								</span>
							</div>
						) : (
							<div>{notification.message}</div>
						)}
					</React.Fragment>
				);
		}
	}
};
