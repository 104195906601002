import gql from "graphql-tag";

export const GET_MENUS_LIST = gql`
	query getMenus(
		$brands: [String!]!
		$status: String!
		$publishStatus: String!
		$offset: Int!
		$limit: Int!
		$searchKeyword: String!
	) {
		menus(
			brands: $brands
			status: $status
			publishStatus: $publishStatus
			offset: $offset
			limit: $limit
			searchKeyword: $searchKeyword
		) {
			objects {
				bizId
				menuId
				imageUrl
				name
				description
				brand
				brandName
				options {
					id
				}
				optionGroups {
					id
					options
				}
				items {
					id
				}
				categories {
					id
					items
				}
				itemCount
				locationCount
				platformCount
				createdAt
				updatedAt
				status
				publishStatus
				createdBy
				scheduledPublishDetails {
					scheduledAt
				}
				metadata {
					itemCount
					locationCount
					platformCount
					publishStatus
					lockStatus
					lockedBy
					menuType
				}
			}
			totalObjectCount
		}
	}
`;

export const CREATE_MENU = gql`
	mutation createMenu(
		$bizId: String!
		$image: Upload
		$name: String!
		$description: String
		$brand: String
		$options: [OptionInput!]!
		$optionGroups: [OptionGroupInput!]
		$items: [ItemInput!]
		$categories: [CategoryInput!]!
	) {
		addMenu(
			menuData: {
				bizId: $bizId
				image: $image
				name: $name
				description: $description
				brand: $brand
				options: $options
				optionGroups: $optionGroups
				items: $items
				categories: $categories
			}
		) {
			menuId
		}
	}
`;

export const GET_MENU = gql`
	query getMenu($id: String!) {
		menuV2(id: $id) {
			menuId
			imageUrl
			name
			description
			brand
			options {
				id
				nestedOptionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			optionGroups {
				id
				options
				overrides {
					name
					description
					min
					max
				}
			}
			items {
				id
				optionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			categories {
				id
				items
				subCategories
				overrides {
					name
					description
					imageUrl
				}
			}
			metadata {
				itemCount
				categoryCount
				createdBy
				bizId
				brand
				brandName
				createdAt
				locationCount
				platformCount
				publishStatus
				status
				updatedAt
				lockStatus
				pendingChangesCount
				lockedBy
				menuType
			}
			scheduledPublishDetails {
				scheduledAt
				scheduledBy
			}
		}
	}
`;

export const CANCEL_SCHEDULED_PUBLISH = gql`
	mutation cancelScheduledPublish($menuId: String!) {
		cancelScheduledPublish(menuId: $menuId) {
			status {
				success
				errors {
					field
					message
				}
				message
			}
		}
	}
`;

export const GET_MENU_METADATA = gql`
	query getMenuMetadata($id: String!) {
		menuV2(id: $id) {
			metadata {
				lockStatus
				pendingChangesCount
			}
		}
	}
`;

export const UPDATE_MENU = gql`
	mutation updateMenu(
		$id: String!
		$menuData: UpdateMenuInput!
		$savePendingChanges: Boolean
		$discardPendingChanges: Boolean
	) {
		updateMenuV2(
			id: $id
			menuData: $menuData
			savePendingChanges: $savePendingChanges
			discardPendingChanges: $discardPendingChanges
		) {
			menuId
			imageUrl
			name
			description
			brand
			options {
				id
				nestedOptionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			optionGroups {
				id
				options
				overrides {
					name
					description
					min
					max
				}
			}
			items {
				id
				optionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			categories {
				id
				items
				subCategories
				overrides {
					name
					description
					imageUrl
				}
			}
			metadata {
				itemCount
				categoryCount
				createdBy
				bizId
				brand
				brandName
				createdAt
				locationCount
				platformCount
				publishStatus
				status
				updatedAt
				lockStatus
				pendingChangesCount
			}
		}
	}
`;

export const UPDATE_MENU_V2 = gql`
	mutation updateMenuV2(
		$id: String!
		$menuData: UpdateMenuInput!
		$savePendingChanges: Boolean
		$discardPendingChanges: Boolean
	) {
		updateMenuV2(
			id: $id
			menuData: $menuData
			savePendingChanges: $savePendingChanges
			discardPendingChanges: $discardPendingChanges
		) {
			metadata {
				lockStatus
				pendingChangesCount
			}
		}
	}
`;

export const GET_MENU_SECTION_ITEMS_LIST = gql`
	query getMenuSectionItemsList(
		$menuId: String!
		$brandId: String!
		$limit: Int!
		$offset: Int!
		$searchKeyword: String!
		$itemFilters: ItemFilterOptions!
	) {
		items(
			menuId: $menuId
			brandId: $brandId
			limit: $limit
			offset: $offset
			searchKeyword: $searchKeyword
			itemFilters: $itemFilters
		) {
			totalObjectCount
			objects {
				id
				name
				price
				crmName
				imageUrl
				merchantRefId
				category {
					name
				}
				foodType
				tags
			}
		}
	}
`;

export const GET_PARENT_SECTIONS_LIST = gql`
	query getParentSectionsList(
		$menuId: String!
		$offset: Int!
		$limit: Int!
		$searchKeyword: String!
		$version: String
	) {
		categories(menuId: $menuId, offset: $offset, limit: $limit, searchKeyword: $searchKeyword, version: $version) {
			objects {
				id
				name
				description
				imageUrl
				items {
					id
					name
					crmName
					type
					price
					markupPrice
					tags
					imageUrl
					optionGroupsAssociated
					activeRules
				}
				subCategories {
					id
					name
				}
				timingsGroup {
					id
					title
				}
			}
			totalObjectCount
		}
	}
`;

export const GET_MENUS_LIST_MINI = gql`
	query getMenus(
		$brands: [String!]!
		$status: String!
		$offset: Int!
		$limit: Int!
		$searchKeyword: String!
		$excludeMenuId: String!
	) {
		menus(
			brands: $brands
			status: $status
			offset: $offset
			limit: $limit
			searchKeyword: $searchKeyword
			excludeMenuId: $excludeMenuId
		) {
			objects {
				menuId
				imageUrl
				name
				description
				brand
				brandName
				itemCount
				status
				publishStatus
				metadata {
					itemCount
					categoryCount
				}
			}
			totalObjectCount
		}
	}
`;

export const GET_LOCATIONS_LIST = gql`
	query getLocationsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$platform: Int
		$search: [SearchArgument]
		$brand: String
	) {
		stores(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			platform: $platform
			brand: $brand
		) {
			limit
			offset
			count
			searchKeywords {
				key
				valueForDisplay
			}
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			objects {
				id
				name
				city
			}
		}
	}
`;

export const GET_PUBLISH_LOCATIONS_LIST = gql`
	query getPublishLocationsList(
		$searchKeyword: String
		$filters: LocationFilterInput!
		$offset: Int!
		$limit: Int!
		$menuId: String
		$ruleId: String
		$sortOptions: LocationSortOption!
	) {
		locations(
			searchKeyword: $searchKeyword
			filters: $filters
			offset: $offset
			limit: $limit
			menuId: $menuId
			ruleId: $ruleId
			sortOptions: $sortOptions
		) {
			objects {
				id
				name
				city
				publishedMenus {
					menuDetails {
						menuId
					}
					platforms
				}
				ingestedMenus {
					menuDetails {
						name
						menuId
					}
				}
				scheduledPublishes {
					menuDetails {
						name
						menuId
					}
				}
			}
			totalObjectCount
		}
	}
`;

export const ARCHIVE_MENU = gql`
	mutation archiveMenu($id: String!) {
		archiveMenu(id: $id) {
			id
			message
		}
	}
`;

export const RESTORE_MENU = gql`
	mutation restoreMenu($id: String!) {
		restoreMenu(id: $id) {
			id
			message
		}
	}
`;

export const GET_MENU_ITEMS = gql`
	query getMenuItems($id: String!) {
		menuV2(id: $id) {
			imageUrl
			name
			description
			brand
			options {
				id
				nestedOptionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			optionGroups {
				id
				options
				overrides {
					name
					description
					min
					max
				}
			}
			items {
				id
				optionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
		}
	}
`;

export const PUBLISH_MENU = gql`
	mutation triggerMenuPublish(
		$menuId: String!
		$action: String!
		$locationIds: [Int!]!
		$platformIds: [String!]!
		$isActive: Boolean!
		$brand: String!
		$scheduleTimestamp: Float
		$locationInfo: LocationInfoInput
	) {
		publishMenu(
			menuId: $menuId
			action: $action
			locationIds: $locationIds
			platformIds: $platformIds
			isActive: $isActive
			brand: $brand
			scheduleTimestamp: $scheduleTimestamp
			locationInfo: $locationInfo
		) {
			status {
				success
				message
				errors {
					field
					message
				}
			}
		}
	}
`;

export const DUPLICATE_MENU = gql`
	mutation copyMenu($copyMenuInput: CopyMenuInput!) {
		copyMenu(copyMenuInput: $copyMenuInput) {
			menuId
			name
			status
		}
	}
`;
export const GET_ITEM_TAGS = gql`
	query getItemTags($limit: Int, $offset: Int, $sort: SortInput, $search: [SearchArgument]) {
		itemTags(limit: $limit, offset: $offset, sort: $sort, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				title
			}
		}
	}
`;

export const GET_MENU_VERSIONS = gql`
	query getMenuVersions($menuId: String!, $limit: Int!, $offset: Int!) {
		menu(id: $menuId) {
			getVersionList(offset: $offset, limit: $limit) {
				objects {
					version
					lastPublishedOn
					associatedPlatforms
					associatedLocations
					associatedItemCount
				}
				totalObjectCount
			}
		}
	}
`;

export const GET_VERSION_DETAILS = gql`
	query getVersionDetails($menuId: String!, $version: Int!) {
		versionPublishHistoryV2(menuId: $menuId, version: $version) {
			publishedBy
			publishedAt
			publishStatus
			locationsInfo {
				locationId
				locationName
				city
				platformsInfo {
					platformName
					publishStatus
					payload
				}
				publishStatus
			}
		}
	}
`;

export const RESTORE_MENU_VERSION = gql`
	mutation restoreMenuVersion($id: String!, $version: Int!) {
		restoreMenuVersion(id: $id, version: $version) {
			menuId
			imageUrl
			name
			description
			brand
			options {
				id
				nestedOptionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			optionGroups {
				id
				options
				overrides {
					name
					description
					min
					max
				}
			}
			items {
				id
				optionGroups
				overrides {
					name
					description
					price
					imageUrl
					isRecommended
				}
			}
			categories {
				id
				items
				subCategories
				overrides {
					name
					description
					imageUrl
				}
			}
			metadata {
				itemCount
				categoryCount
				createdBy
				bizId
				brand
				brandName
				createdAt
				locationCount
				platformCount
				publishStatus
				status
				updatedAt
				lockStatus
				pendingChangesCount
			}
			scheduledPublishDetails {
				scheduledAt
				scheduledBy
			}
		}
	}
`;

export const GET_RULES_LIST = gql`
	query getRulesList($menuId: String!, $filterOptions: RuleFilterOptions, $searchKeyword: String!) {
		rulesV2(menuId: $menuId, filterOptions: $filterOptions, searchKeyword: $searchKeyword) {
			id
			entityId
			entityIds
			entitiesInfo {
				entityId
				entityName
			}
			entityType
			entityName
			locations {
				locationId
				locationName
			}
			name
			operations {
				operator
				input {
					value
					type
					format
				}
				field
			}
			platforms
			status
		}
	}
`;

export const GET_RULE_CONFLICTS = gql`
	query ReviewRuleConflict($menuId: String!, $ruleId: String!) {
		reviewRuleConflict(menuId: $menuId, ruleId: $ruleId) {
			conflictingOperations
			conflictingLocations
			conflictingPlatforms
			ruleId
			ruleName
		}
	}
`;

export const UPDATE_RULE_STATUS = gql`
	mutation updateRuleStatus($menuId: String!, $ruleId: String!, $newStatus: RuleStatus!) {
		updateRuleStatusV2(menuId: $menuId, ruleId: $ruleId, newStatus: $newStatus) {
			success
			ruleObject {
				id
				name
				status
				locations {
					locationId
					locationName
				}
				entityIds
				entitiesInfo {
					entityId
					entityName
				}
				platforms
				entityId
				entityType
				entityName
				operations {
					operator
					input {
						value
						type
						format
					}
					field
				}
			}
			success
			error
			conflicts {
				ruleId
				ruleName
				entityId
				conflictingOperations
				conflictingLocations
				conflictingPlatforms
			}
		}
	}
`;

export const FETCH_RULE_EDIT_ITEMS_LIST = gql`
	query fetchRuleEditItemsList(
		$menuId: String!
		$brandId: String!
		$limit: Int!
		$offset: Int!
		$itemFilters: ItemFilterOptions!
		$fetchMenuItems: Boolean!
		$searchKeyword: String!
	) {
		items(
			brandId: $brandId
			itemFilters: $itemFilters
			menuId: $menuId
			searchKeyword: $searchKeyword
			fetchMenuItems: $fetchMenuItems
			limit: $limit
			offset: $offset
		) {
			objects {
				id
				name
				description
				markupPrice
				price
				imageUrl
			}
			totalObjectCount
		}
	}
`;

export const FETCH_RULE_EDIT_OPTIONS_LIST = gql`
	query fetchRuleEditOptionsList($menuId: String!, $limit: Int!, $offset: Int!, $searchKeyword: String!) {
		options(menuId: $menuId, searchKeyword: $searchKeyword, limit: $limit, offset: $offset) {
			objects {
				id
				name
				description
				imageUrl
				price
			}
			totalObjectCount
		}
	}
`;

export const ADD_RULE = gql`
	mutation addMenuRule($menuId: String!, $ruleInput: NewRuleInputV2!) {
		addRuleV2(menuId: $menuId, ruleInput: $ruleInput) {
			ruleObject {
				id
				name
				status
				locations {
					locationId
					locationName
				}
				platforms
				entityId
				entityIds
				entitiesInfo {
					entityId
					entityName
				}
				entityType
				entityName
				operations {
					operator
					input {
						value
						type
						format
					}
					field
				}
			}
			success
			error
			conflicts {
				ruleId
				ruleName
				entityId
				conflictingOperations
				conflictingLocations
				conflictingPlatforms
			}
		}
	}
`;

export const GET_RULE_INFO = gql`
	query getRuleInfo($menuId: String!, $ruleId: String!) {
		ruleV2(menuId: $menuId, ruleId: $ruleId) {
			entityId
			entityType
			id
			entityIds
			entitiesInfo {
				entityId
				entityName
			}
			locations {
				locationId
				locationName
			}
			name
			entityName
			operations {
				operator
				input {
					value
					type
					format
				}
				field
			}
			platforms
			status
		}
	}
`;

export const UPDATE_MENU_RULE = gql`
	mutation updateMenuRule($menuId: String!, $ruleInput: UpdateRuleInputV2!) {
		updateRuleV2(menuId: $menuId, ruleInput: $ruleInput) {
			ruleObject {
				id
				name
				status
				entityIds
				entitiesInfo {
					entityId
					entityName
				}
				locations {
					locationId
					locationName
				}
				platforms
				entityId
				entityType
				entityName
				operations {
					operator
					input {
						value
						type
						format
					}
					field
				}
			}
			success
			error
			conflicts {
				ruleId
				ruleName
				entityId
				conflictingOperations
				conflictingLocations
				conflictingPlatforms
			}
		}
	}
`;
export const DELETE_RULE = gql`
	mutation deleteMenuRule($menuId: String!, $ruleId: String!) {
		deleteRule(menuId: $menuId, ruleId: $ruleId) {
			success
		}
	}
`;

export const GET_CONFLICTS_LIST = gql`
	query getRulesList($menuId: String!, $filterOptions: RuleFilterOptions) {
		rulesV2(menuId: $menuId, filterOptions: $filterOptions) {
			id
			entityId
			entityName
			locations {
				locationId
				locationName
			}
			name
			entityIds
			entitiesInfo {
				entityId
				entityName
			}
			operations {
				operator
				input {
					value
					type
					format
				}
				field
			}
			platforms
			status
		}
	}
`;

export const BULK_UPDATE_RULE_STATUS = gql`
	mutation updateRuleStatus($menuId: String!, $ruleIds: [String!]!, $newStatus: RuleStatus!) {
		bulkUpdateRuleStatusV2(menuId: $menuId, ruleIds: $ruleIds, newStatus: $newStatus) {
			success
		}
	}
`;

export const PREVIEW_MENU = gql`
	query getMenuPreviewData(
		$menuId: String!
		$locationId: String
		$platform: String
		$version: String
		$limit: Int!
		$offset: Int!
	) {
		menuPreview(
			menuId: $menuId
			locationId: $locationId
			platform: $platform
			version: $version
			limit: $limit
			offset: $offset
		) {
			objects {
				description
				id
				imageUrl
				name
				items {
					id
					name
					description
					crmName
					type
					price
					tags
					imageUrl
					markupPrice
					optionGroupsAssociated
					activeRules
				}
				subCategories {
					id
					name
				}
			}
		}
	}
`;
export const REVIEW_TEMP_RULE = gql`
	query reviewTempRuleConflict($menuId: String!, $ruleInput: NewRuleInputV2!) {
		reviewTempRuleConflictV2(menuId: $menuId, ruleInput: $ruleInput) {
			ruleId
			ruleName
			entityId
			conflictingOperations
			conflictingLocations
			conflictingPlatforms
		}
	}
`;
export const CUSTOMISABLE_ITEM_DETAILS = gql`
	query getCustomisableItemDetails(
		$menuId: String!
		$itemId: String!
		$applyRules: Boolean!
		$locationId: String
		$platform: String
		$limit: Int
	) {
		item(menuId: $menuId, itemId: $itemId, applyRules: $applyRules, locationId: $locationId, platform: $platform) {
			id
			name
			description
			foodType
			imageUrl
			optionGroups(limit: $limit) {
				objects {
					id
					name
					isVariant
					minSelectable
					maxSelectable
					options(limit: $limit) {
						objects {
							id
							name
							price
							foodType
						}
						totalObjectCount
					}
				}
			}
		}
	}
`;

export const MENU_PREVIEW_VALIDATION_STATUS = gql`
	query fetchPreviewValidation($menuId: String!, $locationId: String!, $platform: String!) {
		fetchPreviewValidation(menuId: $menuId, locationId: $locationId, platform: $platform) {
			report {
				entityId
				entityName
				entityType
				errors {
					message
					platforms
				}
				warnings {
					message
					platforms
				}
			}
			status
			message
		}
	}
`;

export const MENU_PUBLISH_VALIDATION_STATUS = gql`
	query fetchPublishValidation($menuId: String!, $prePublish: Boolean, $publishId: String) {
		fetchPublishValidation(menuId: $menuId, prePublish: $prePublish, publishId: $publishId) {
			report {
				entityId
				entityName
				entityType
				errors {
					message
					platforms
				}
				warnings {
					message
					platforms
				}
			}
			status
			message
		}
	}
`;

export const VALIDATE_FAILED_PUBLISH = gql`
	mutation validateFailedPublish($menuId: String!) {
		validateFailedPublish(menuId: $menuId) {
			success
		}
	}
`;

export const MENU_CATEGORIES_LIST = gql`
	query bizCategories($offset: Int!, $limit: Int!, $searchKeyword: String!) {
		bizCategories(limit: $limit, offset: $offset, searchKeyword: $searchKeyword) {
			objects {
				id
				name
				subCategories {
					id
					name
				}
			}
			totalObjectCount
		}
	}
`;
export const GET_BIZ_CITIES = gql`
	query getCities {
		cities
	}
`;

export const GET_BIZ_LOCATION_GROUPS = gql`
	query getLocationGroups($brandId: String) {
		locationGroups(brandId: $brandId) {
			id
			name
		}
	}
`;

export const GET_ITEM_RULE_PREVIEW = gql`
	query ItemPreview($entityId: String!, $menuId: String!) {
		itemPreview(entityId: $entityId, menuId: $menuId) {
			afterValues {
				description
				imageUrl
				markupPrice
				name
				price
			}
			beforeValues {
				description
				imageUrl
				markupPrice
				name
				price
			}
		}
	}
`;

export const GET_MENU_PULL_INFO = gql`
	query getMenuPullInfo {
		getMenuPullInfo {
			canPullMenu
			canPullMenuLocation
			pos {
				id
				name
			}
			ongoingMenuPull {
				status
				message
			}
			lastMenuPull {
				status
				errorCode
				message
				report {
					status
					entities {
						entity
						warnings
						errors {
							name
							messages
						}
					}
				}
			}
		}
	}
`;

export const REQUEST_MENU_PULL = gql`
	mutation requestMenuPull(
		$locationIds: [Int]!
		$brandId: Int
		$pos: String
		$flushAll: Boolean
		$allLocations: Boolean
	) {
		requestMenuPull(
			input: {
				locationIds: $locationIds
				brandId: $brandId
				pos: $pos
				flushAll: $flushAll
				allLocations: $allLocations
			}
		) {
			status
			message
		}
	}
`;

// test order apis

export const CREATE_TEST_CART = gql`
	mutation createTestCart(
		$menuId: String!
		$items: [Item!]!
		$bizLocationId: String!
		$platform: String!
		$version: String
	) {
		createCart(
			menuId: $menuId
			bizLocationId: $bizLocationId
			platform: $platform
			version: $version
			items: $items
		) {
			cartId
			cartObject {
				meta {
					subTotal
					total
					totalCharges
					totalTaxes
				}
				items {
					id
					imgUrl
					title
					quantity
					pricePerUnit
					foodType
					subtotal
					total
					options {
						id
						title
						quantity
						imgUrl
						pricePerUnit
						subtotal
					}
				}
			}
			success
			error
		}
	}
`;

export const ADD_ITEMS_TEST_CART = gql`
	mutation addItemsTestCart($cartId: String!, $items: [Item!]!) {
		addItemsCart(cartId: $cartId, items: $items) {
			cartId
			cartObject {
				meta {
					subTotal
					total
					totalCharges
					totalTaxes
				}
				items {
					id
					title
					imgUrl
					quantity
					pricePerUnit
					foodType
					subtotal
					total
					options {
						id
						title
						quantity
						imgUrl
						pricePerUnit
						subtotal
					}
				}
			}
			success
			error
		}
	}
`;

export const RESET_CART = gql`
	mutation resetTestCart($cartId: String!) {
		resetCart(cartId: $cartId) {
			cartId
			cartObject {
				items {
					id
				}
			}
			success
			error
		}
	}
`;
export const PLACE_TEST_ORDER = gql`
	mutation placeTestOrder($cartId: String!) {
		placeOrder(cartId: $cartId) {
			cartId
			success
			error
		}
	}
`;

export const UPDATE_TEST_CART = gql`
	mutation updateItemsTestCart($cartId: String!, $items: [Item!]!) {
		updateItemsCart(cartId: $cartId, items: $items) {
			cartId
			cartObject {
				meta {
					subTotal
					total
					totalCharges
					totalTaxes
				}
				items {
					id
					imgUrl
					quantity
					title
					pricePerUnit
					subtotal
					foodType
					total
					options {
						id
						title
						quantity
						imgUrl
						pricePerUnit
						subtotal
					}
				}
			}
			success
			error
		}
	}
`;

export const REMOVE_ITEMS_TEST_CART = gql`
	mutation removeItemsTestCart($cartId: String!, $items: [Item!]!) {
		removeItemsCart(cartId: $cartId, items: $items) {
			cartId
			cartObject {
				meta {
					subTotal
					total
					totalCharges
					totalTaxes
				}
				items {
					id
					imgUrl
					quantity
					pricePerUnit
					subtotal
					title
					foodType
					options {
						id
						title
						quantity
						imgUrl
						pricePerUnit
						subtotal
					}
				}
			}
			success
			error
		}
	}
`;
