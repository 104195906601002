import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import Button, { ButtonSize, ButtonVariant } from "@urbanpiper-engineering/horizon/dist/atoms/button";

// third party
import { connect } from "react-redux";
import history from "../../history";

// constants
import { ATLAS_ONBOARDING_STEPS } from "../../client-config";

function MenuCreationStatus({
	flowSteps,
	selectedBrand,
	menuPullUrlBrand,
	state = "default",
	onBackToSetup = () => {},
	onSetupNextBrand = () => {},
	onViewMenu = () => {},
	onTryAgain = () => {}
}) {
	const brandsStep = flowSteps.find((step) => step.step.name === ATLAS_ONBOARDING_STEPS.CREATE_BRANDS);

	const getStatusUI = (state) => {
		// indexing takes a lot of time so we either show failure or by default show it is in process
		switch (state) {
			case "success":
				return (
					<div className="success">
						<img className="success-icon" src="/assets/icons/success-green-two-icon.svg" alt="success" />

						<Text className="success-text" variant={TextVariant.HEADING4}>
							Your menu was successfully imported
						</Text>

						<div className="actions">
							<Button onClick={onSetupNextBrand} variant={ButtonVariant.Primary}>
								Setup next brand
							</Button>

							<Button onClick={onViewMenu} variant={ButtonVariant.Secondary}>
								View Menu
							</Button>
						</div>
					</div>
				);
			case "failure":
				return (
					<div className="failure">
						<img className="failure-icon" src="/assets/icons/failure-icon.svg" alt="failure" />

						<div className="body">
							<Text className="title" variant={TextVariant.HEADING4}>
								Import Failed
							</Text>

							<Text className="subtitle" variant={TextVariant.BODY1}>
								Menu from the selected service could not be imported to UrbanPiper. There was an
								indexing issue with the source service
							</Text>
						</div>

						<div className="actions">
							<Button onClick={onBackToSetup} variant={ButtonVariant.Primary}>
								Try Again
							</Button>

							<Button onClick={() => history.push("/onboarding")} variant={ButtonVariant.Secondary}>
								Back to Setup
							</Button>
						</div>
					</div>
				);
			default:
				return (
					<div className="in-process">
						<img className="in-process-icon" src="/assets/icons/in-process-icon.svg" alt="in-process" />

						<div className="body">
							<Text className="title" variant={TextVariant.HEADING3}>
								Importing Your Menu
							</Text>

							<Text className="subtitle" variant={TextVariant.HEADING5}>
								We are currently importing your menu from the selected ordering platform.
							</Text>

							<Button onClick={() => {}} variant={ButtonVariant.Link} size={ButtonSize.MD}>
								Learn More
							</Button>

							<div className="warning">
								<img src="/assets/icons/warning-icon.svg" alt="warning-icon" />
								<Text className="warning-text" variant={TextVariant.HEADING5}>
									This may take some time. You can preview your menu(s) as soon as the import
									finishes.
								</Text>
							</div>
						</div>
						<div className="actions">
							{brandsStep?.metadata?.total > 1 && (
								<Button onClick={onBackToSetup} variant={ButtonVariant.Primary}>
									Setup Menu for next Brand
								</Button>
							)}

							<Button onClick={() => history.push("/onboarding")} variant={ButtonVariant.Secondary}>
								Back to Setup
							</Button>
						</div>
					</div>
				);
		}
	};
	return (
		<div className="onboarding-menus__menu-creation-status">
			{selectedBrand.id === menuPullUrlBrand ? getStatusUI(state) : getStatusUI("default")}
		</div>
	);
}

const mapStateToProps = (store) => ({
	flowSteps: store.atlasOnboardingState.flowSteps
});
export default connect(mapStateToProps)(MenuCreationStatus);
