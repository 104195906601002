import React, { Component } from "react";

// components
import { Header } from "../components/TransactionsList/Header";
import { Table } from "../components/TransactionsList/Table";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";

// graphql
import { GET_TRANSACTIONS_ONLINE_LIST, GET_TRANSACTIONS_IN_STORE_LIST } from "../graphql/transactions";

// clients
import { store } from "../store/configureStore";
import { client } from "../client";

// third party
import { connect } from "react-redux";
import moment from "moment";
import PubSub from "pubsub-js";

// actions
import { toggleGlobalLoader, fetchStores, fetchStoresDebounced, fetchBrands } from "../actions/actions";

// utils
import { scroll } from "../atlas-utils";
import { ActionTypes } from "../actions/_types";

// constant
import {
	STORE_TYPES,
	ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS,
	IN_STORE_TRANSACTIONS_LIST_SEARCH_FIELDS
} from "../client-config";
import { TRACK_EVENT } from "../atlas-utils/tracking";

const TABLE_FIELDS = {
	ORDERING: [
		{
			value: "id",
			valueForDisplay: "Order ID",
			readOnly: true
		},
		{
			value: "date",
			valueForDisplay: "Date & Time",
			readOnly: true
		},
		{
			value: "outlet",
			valueForDisplay: "Outlet"
		},
		{
			valueForDisplay: "Customer",
			value: "user"
		},
		{
			value: "payment",
			valueForDisplay: "Payment Mode"
		},
		{
			value: "status",
			valueForDisplay: "Status"
		},
		{
			value: "amount",
			valueForDisplay: "Amount",
			readOnly: true
		},
		{
			value: "associatedBrands",
			valueForDisplay: "Associated Brands"
		}
	],
	LOYALTY: [
		{
			valueForDisplay: "Purchase ID",
			value: "id"
		},
		{
			valueForDisplay: "Date & Time",
			value: "date"
		},
		{
			valueForDisplay: "Outlet",
			value: "outlet"
		},
		{
			valueForDisplay: "Customer",
			value: "user"
		},
		{
			valueForDisplay: "Associated Brands",
			value: "associatedBrands"
		},
		{
			valueForDisplay: "Amount",
			value: "amount"
		}
	]
};

@connect((store) => ({
	transactionsList: store.transactionsList,
	transactionsListState: store.transactionsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	selectedModule: store.selectedModule,
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))
export class TransactionsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			tableColumnsSelected: {
				tableColumnsSelected: {
					id: true,
					date: true,
					outlet: true,
					user: true,
					amount: true,
					payment: true,
					status: true
				}
			}
		};
	}

	async componentDidMount() {
		if (!this.props.configItems.stores.items.length) {
			fetchStores("", 50, this.props?.brands?.selectedBrand?.id || null);
		}

		// set tracking related info
		const eventName = "transactions_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch Transactions list
		if (this.props.isMultibrandEnabled) {
			const brand = this.props.brands.selectedBrand
				? this.props.brands.selectedBrand
				: { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" };
			store.dispatch({
				type: ActionTypes.UPDATE_SELECTED_BRAND,
				payload: brand
			});
			await this.fetchTransactionsList(brand);
		} else {
			await this.fetchTransactionsList();
		}

		// fetch brands
		if (this.props.isMultibrandEnabled) {
			fetchBrands("", true);
		}

		// fetch new orders every 20 seconds
		// this.interval = this.fetchNewOrders();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: "mixpanel",
		// 	eventName,
		// 	eventMeta
		// });
	}

	async componentWillReceiveProps(newProps) {
		if (
			this.props.transactionsListState.appliedDateFilter.current.dateFilter !==
			newProps.transactionsListState.appliedDateFilter.current.dateFilter
		) {
			// set tracking related info
			const eventName = "transactions_list_view_filter";
			let perfStart = 0;
			let perfEnd = 0;
			if (window.performance) {
				perfStart = window.performance.now();
			}

			if (newProps.transactionsListState.currentDateFilter.current.dateFilter !== "TODAY") {
				// stop fetching new orders when date filter is applied
				clearInterval(this.interval);
			}

			// apply date range filter
			await this.fetchTransactionsList();

			// set tracking related info and send the event to be logged
			if (window.performance) {
				perfEnd = window.performance.now();
			}
			const { appliedDateFilter, appliedFilters } = newProps.transactionsListState;
			const eventMeta = {
				duration: appliedDateFilter.current.dateFilter,
				filters: JSON.stringify(Object.values(appliedFilters)),
				time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
			};
			PubSub.publish(TRACK_EVENT, {
				tracker: "mixpanel",
				eventName,
				eventMeta
			});
		}
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	searchTransaction = async () => {
		this.updateTransactionsListState({
			offset: 0
		});

		// set tracking related info
		const eventName = "transactions_list_view_search";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// stop fetching new orders when filters are applied
		clearInterval(this.interval);

		// perform search
		await this.fetchTransactionsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { searchFieldSelected } = this.props.transactionsListState;
		const eventMeta = {
			search_field: searchFieldSelected?.value || "",
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	cancelSearchTransaction = () => {
		this.updateTransactionsListState({
			searchKW: "",
			offset: 0
		});
		this.fetchTransactionsList();
	};

	handleSearchKW = (searchKW) => {
		this.updateTransactionsListState({
			searchKW
		});
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	handleSearchField = (searchFieldSelected) => {
		this.updateTransactionsListState({
			searchFieldSelected
		});
	};

	updateTransactionsListState = (payload) => {
		store.dispatch({
			type: "TRANSACTIONS_LIST_STATE_CHANGE",
			payload
		});
	};

	handleStoreType = (storeType) => {
		// keep the date filter same for both the types of stores
		// and reset the filters, pagination, etc.
		const { currentDateFilter, appliedDateFilter } = this.props.transactionsListState;
		store.dispatch({
			type: "RESET_TRANSACTIONS_LIST_STATE"
		});
		store.dispatch({
			type: "RESET_TRANSACTIONS_LIST"
		});
		const payload = {
			currentDateFilter,
			appliedDateFilter
		};
		if (storeType === STORE_TYPES[0].type) {
			payload.searchFieldSelected = ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS[0];
		} else {
			payload.searchFieldSelected = IN_STORE_TRANSACTIONS_LIST_SEARCH_FIELDS[0];
		}
		this.updateTransactionsListState(payload);

		// change store type
		store.dispatch({
			type: "SET_APP_MODULE",
			payload: storeType
		});

		// fetch transactions
		this.fetchTransactionsList();
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updateTransactionsListState({
			currentFilters: this.props.transactionsListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.transactionsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updateTransactionsListState({
			currentFilters
		});
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updateTransactionsListState({
			appliedFilters: {
				...this.props.transactionsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "transactions_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// stop fetching new orders when filters are applied
		clearInterval(this.interval);

		// apply filters
		await this.fetchTransactionsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedDateFilter, appliedFilters } = store.getState().transactionsListState;
		const eventMeta = {
			duration: appliedDateFilter.current.dateFilter,
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			() => {
				this.updateTransactionsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				this.fetchTransactionsList();
				if (this.props.transactionsListState.appliedDateFilter.current.dateFilter === "TODAY") {
					this.interval = this.fetchNewOrders();
				}
			}
		);
	};

	fetchTransactionsList = async (brand = null) => {
		const { limit, offset, sort, appliedDateFilter, searchKW, appliedFilters, searchFieldSelected } =
			store.getState().transactionsListState;
		const { productType } = store.getState().selectedModule;
		const storeType = productType;
		store.dispatch(toggleGlobalLoader(true));
		store.dispatch({
			type: "GET_TRANSACTIONS_LIST_REQUEST"
		});
		try {
			const variables = {
				limit,
				offset
			};
			// date filter
			let filtersObject = [];
			if (appliedDateFilter.current.dateFilter) {
				filtersObject.push({
					field: "created",
					value: appliedDateFilter.current.dateFilter
				});
			}
			// sidebar filters
			Object.keys(appliedFilters).forEach((f) => {
				if (appliedFilters[f].value) {
					filtersObject.push(appliedFilters[f]);
				}
			});
			// sort
			if (sort.field !== "" && sort.order !== "") {
				variables.sort = sort;
			}
			// search filter
			let searchObject = [];
			if (searchKW) {
				searchObject.push({
					key: searchFieldSelected.value,
					value: searchKW
				});
			}
			// set search and filter
			variables.filters = filtersObject;
			variables.search = searchObject;
			variables.brand = this.props.isMultibrandEnabled
				? !brand
					? this.props?.brands?.selectedBrand?.id
						? String(this.props?.brands?.selectedBrand?.id)
						: null
					: String(brand.id)
				: null;
			if (storeType === STORE_TYPES[0].type) {
				const resp = await client.query({
					query: GET_TRANSACTIONS_ONLINE_LIST,
					variables,
					fetchPolicy: "no-cache"
				});
				store.dispatch({
					type: "GET_TRANSACTIONS_LIST_SUCCESS",
					payload: resp.data.orders ? { ...resp.data.orders } : {}
				});
			} else if (storeType === STORE_TYPES[1].type) {
				const resp = await client.query({
					query: GET_TRANSACTIONS_IN_STORE_LIST,
					variables
				});
				store.dispatch({
					type: "GET_TRANSACTIONS_LIST_SUCCESS",
					payload: resp.data.purchases ? { ...resp.data.purchases } : {}
				});
			}
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: "GET_TRANSACTIONS_LIST_FAILURE",
				error
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 5000,
					error: true,
					errObject: error
				}
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	};

	fetchNewOrders = () =>
		setInterval(async () => {
			// fetch new Transactions list
			await this.fetchTransactionsList();
		}, 20000);

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.transactionsListState;
		const offset = (page - 1) * limit;
		this.updateTransactionsListState({
			offset
		});
		// get new transacitons results
		this.fetchTransactionsList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.transactionsListState;
		if (size && size?.value !== limit) {
			this.updateTransactionsListState({
				[field]: size.value
			});
			// fetch new transactions list
			await this.fetchTransactionsList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updateTransactionsListState({
			offset: 0
		});
		store.dispatch({
			type: "TRANSACTIONS_LIST_STATE_CHANGE_SORT",
			payload: {
				sort
			}
		});
		this.fetchTransactionsList();
	};

	handleColumnSelection = (isSelected, field, optKey) => {
		this.setState({
			tableColumnsSelected: {
				tableColumnsSelected: {
					...this.state.tableColumnsSelected.tableColumnsSelected,
					[optKey.value]: isSelected
				}
			}
		});
	};

	handleBrand = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: value
		});
		this.fetchTransactionsList(
			!value ? { id: "all", name: "All Brands", image: "/assets/icons/icon-brands.svg" } : value
		);
		fetchStores("", 50, !value ? "all" : value?.id);
	};

	render() {
		const { transactionsList, transactionsListState, configItems, selectedModule, brands } = this.props;
		const {
			searchKW,
			limit,
			offset,
			currentFilters,
			searchFieldSelected,
			currentDateFilter,
			appliedDateFilter,
			sortedField
		} = transactionsListState;
		const { productType, productTypeOptions } = selectedModule;
		const storeType = productType;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (currentFilters[f].value != "") {
				filterCount++;
			}
		}
		// map filters to mark them async when applicable
		const filtersOptions = transactionsList.data.filters
			.map((f) => {
				if (f.type === "MULTIPLE" && f.field === "biz_location_id") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.stores,
						asyncLookup: this.state.storesLookup,
						updateAsyncLookup: this.updateStoresLookup,
						handleAsyncSearch: (search) =>
							fetchStoresDebounced(search, 50, this.props?.brands?.selectedBrand?.id || null),
						labelKey: "name",
						valueKey: "id"
					};
				}
				return f;
			})
			.filter((f) => f.type !== "DATE");

		const { tableColumnsSelected } = this.state;
		const { isMultibrandEnabled } = this.props;

		const columnsCount = Object.keys(tableColumnsSelected.tableColumnsSelected).filter(
			(column) => tableColumnsSelected.tableColumnsSelected[column]
		).length;
		const columnWidth = Math.floor(100 / (columnsCount === 0 ? 1 : columnsCount));

		return (
			<div className="transactions-container">
				<div className="transaction-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
					{configItems.dimensions.width > 768 && (
						<Filters
							isOpen={this.state.showFilters}
							close={this.filterSidebarCloseHandler}
							apply={this.applyFilters}
							clear={this.clearFilters}
							options={filtersOptions}
							currentFilters={currentFilters}
							setFilter={this.setFilter}
						/>
					)}
					<Header
						filterCount={filterCount}
						flipShowFilters={this.flipShowFilters}
						filterActive={this.state.showFilters}
						searchFieldSelected={searchFieldSelected}
						handleSearchField={this.handleSearchField}
						searchKW={searchKW}
						handleSearchKW={this.handleSearchKW}
						searchTransaction={this.searchTransaction}
						cancelSearchTransaction={this.cancelSearchTransaction}
						storeType={storeType}
						productTypeOptions={productTypeOptions}
						handleStoreType={this.handleStoreType}
						dimensions={configItems.dimensions}
						brands={brands}
						handleBrand={this.handleBrand}
						tableFields={
							!isMultibrandEnabled
								? TABLE_FIELDS[storeType].filter((field) => field.value !== "associatedBrands")
								: TABLE_FIELDS[storeType]
						}
						selectedColumns={this.state.tableColumnsSelected}
						handleColumnSelection={this.handleColumnSelection}
						loading={transactionsList.loading}
						currentDateFilter={currentDateFilter}
						appliedDateFilter={appliedDateFilter}
						updateState={this.updateTransactionsListState}
						isMultibrandEnabled={isMultibrandEnabled}
					/>
					<Table
						loading={transactionsList.loading}
						data={transactionsList.data.objects || []}
						currencySymbol={this.props.biz.currencySymbol}
						storeTypeSelected={storeType}
						sortList={this.sortList}
						sortedField={sortedField}
						dimensions={configItems.dimensions}
						appliedInlineStyles={{ width: `${columnWidth}%` }}
						tableColumnsSelected={tableColumnsSelected?.tableColumnsSelected}
					/>
					<Paginator
						limit={limit}
						offset={offset}
						count={transactionsList.data.count || 0}
						goToPage={this.handlePagination}
						setPageSize={this.handlePageSize}
						showPageSize={true}
					/>
				</div>
			</div>
		);
	}
}
