import React from "react";

// third party
import { Link } from "react-router-dom";

export const BackNavigation = ({ clickHandler, title = "", prevLocation, currLocation }) => {
	const location = {
		pathname: prevLocation?.pathname,
		state: currLocation
	};
	return (
		<div className="back-navigation-arrow">
			{clickHandler ? (
				<div onClick={clickHandler}>
					<img src="/assets/icons/ic_left_arrow_blue.svg" />
					<span className="navigation-title">{title}</span>
				</div>
			) : (
				<Link to={location}>
					<img src="/assets/icons/ic_left_arrow_blue.svg" />
					<span className="navigation-title">{prevLocation.title}</span>
				</Link>
			)}
		</div>
	);
};
