import React, { useState, useRef } from "react";

// components
import { Topbar } from "../_commons/Topbar";
import { Button } from "../_commons/Button";
import { CheckBox } from "../_commons/CheckBox";
import { TagsInputWrapper } from "./TagsInputWrapper";
import { BackNavigation } from "./BackNavigation";

// third party
import { connect } from "react-redux";

// utils
import { lS } from "../../atlas-utils";

// third party
import { useTrail, config, animated } from "react-spring";

const BulkOperation = ({
	bizId,
	title,
	subTitle,
	downloadTitle = "Download Template",
	exportTitle = "Export Template",
	downloadTemplate,
	exportTemplate,
	showTopBar = false,
	tabs = [],
	hiddenTabs = [],
	selectedTab,
	setCurrentTab,
	data = [],
	loading,
	downloading,
	uploading,
	disabled = false,
	hideDownloadAction = false,
	hideExportAction = false,
	handleUploadFile,
	uploadedFileName,
	renderUploadForm,
	validation,
	hideDownloadTemplateSection = false,
	catalogueBackupsStatus,
	prevLocation,
	currLocation
}) => {
	const [currEmail, setCurrEmail] = useState("");
	const [emailListFilter, setEmailListFilter] = useState(
		lS.get("bulk_actions_emails") ? lS.get("bulk_actions_emails")[bizId] || [] : []
	);
	const [emailValidationMsg, setEmailValidationMsg] = useState("");
	const inputRef = useRef(null);
	const tagsRef = useRef();

	const handleClick = () => {
		inputRef.current.click();
	};

	const setEmailFilter = (tags) => {
		setEmailListFilter(tags);
		lS.set("bulk_actions_emails", { ...lS.get("bulk_actions_emails"), [bizId]: [...tags] });
	};

	const handleTagInput = (val) => {
		setCurrEmail(val);
		if (emailValidationMsg) {
			setEmailValidationMsg("");
		}
	};

	return (
		<div className={"bulk-operation " + (downloading ? "downloading" : "") + (uploading ? "uploading" : "")}>
			<BackNavigation prevLocation={prevLocation} currLocation={currLocation} />
			<Header
				title={title}
				subTitle={subTitle}
				exportTitle={exportTitle}
				exportTemplate={exportTemplate}
				disabled={disabled}
				hideExportAction={hideExportAction}
			/>
			{showTopBar && (
				<Topbar
					tabs={tabs}
					selectedTab={selectedTab}
					switchTab={setCurrentTab}
					isStickyOnTop={true}
					hiddenTabs={hiddenTabs}
				/>
			)}
			{catalogueBackupsStatus.restrict && (
				<div className="sync-status-container restict-access">
					<div className="sync-icon">
						<img src="/assets/icons/icon-sync.svg" alt="" />
					</div>
					<div className="message">
						{catalogueBackupsStatus.status === "BACKING_UP" && (
							<React.Fragment>
								<div>
									A backup of your catalogue is currently being processed. This usually takes about a
									minute.
								</div>
								<div>Please wait for the backup to finish.</div>
							</React.Fragment>
						)}
						{catalogueBackupsStatus.status === "RESTORING" && (
							<React.Fragment>
								<div>
									Your catalogue backup is currently being restored. This usually takes between 5 to
									10 minutes.
								</div>
								<div>Please wait for it to finish.</div>
							</React.Fragment>
						)}
					</div>
				</div>
			)}
			{!catalogueBackupsStatus.restrict && !hideExportAction && (
				<div className="download-template">
					<div className="header">
						<div className="header-text">Export Template</div>
						<div className="subheader-text">
							Please enter the recipient email(s) to export {exportTitle.toLowerCase()}.
						</div>
					</div>
					<div className="export-template-btn">
						<TagsInputWrapper
							ref={tagsRef}
							tags={emailListFilter}
							onChange={setEmailFilter}
							tagInput={currEmail}
							onChangeInput={handleTagInput}
							requiredLabel={true}
							placeholder={"Enter an email"}
							validationRegex={/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/}
							onValidationReject={() => setEmailValidationMsg("Please enter a valid email")}
							validationMessage={emailValidationMsg}
							showDropdown={true}
						>
							Recipient Emails
						</TagsInputWrapper>
						{!hideDownloadAction && (
							<Button
								clickHandler={() => exportTemplate(emailListFilter.join(","), exportTitle)}
								classes={disabled || emailListFilter.length === 0 ? "disabled" : ""}
								type="secondary"
							>
								Export {exportTitle}
							</Button>
						)}
					</div>
				</div>
			)}
			{!catalogueBackupsStatus.restrict && !hideDownloadTemplateSection && (
				<div className="download-template">
					<div className="header">
						<div className="header-text">Download Template</div>
						<div className="subheader-text">
							Please download the template and fill it. Ensure you do not change column names or otherwise
							change the layout of the template.
						</div>
					</div>
					<div className="download-template-btn">
						{!hideDownloadAction && (
							<Button clickHandler={downloadTemplate} classes={disabled ? "disabled" : ""}>
								{downloadTitle}
							</Button>
						)}
					</div>
				</div>
			)}
			{!catalogueBackupsStatus.restrict && (
				<div className="fill-data">
					<div className="header">
						<div className="header-text">Fill Data</div>
						<div className="subheader-text">
							You can refer to this table for explanations of columns in the template.
						</div>
					</div>
					<Table loading={loading} data={data} />
				</div>
			)}
			{!catalogueBackupsStatus.restrict && (
				<div className="upload-filled-data">
					<div className="header">
						<div className="header-text">Upload Filled Data</div>
						<div className="subheader-text">
							Upload your CSV file to apply changes. Status and errors, if any, will be displayed after
							file validation.
						</div>
					</div>
					{renderUploadForm && <div className="upload-form">{renderUploadForm}</div>}
					<div className="upload-area">
						<div className="upload-container" onClick={handleClick}>
							<div className="upload-icon">
								<img src="/assets/icons/icon-bulk-upload.svg" alt="" />
							</div>
							<div className="upload-desc">
								Click or drag file to this area to upload
								<div className="text--light desc-text">Make sure this file is in CSV format</div>
							</div>
							<input
								accept=".csv"
								className="D(n)"
								ref={inputRef}
								type="file"
								onChange={handleUploadFile}
								onClick={() => {
									inputRef.current.value = null;
								}}
							/>
						</div>
					</div>
					{uploadedFileName && (
						<div className="uploaded-file">
							<div className="header">
								<div className="subheader-text">
									<span className="uploaded-file-icon">
										<img src="/assets/icons/paper-clip.svg" alt="" />
									</span>
									<span>{uploadedFileName}</span>
									{validation && <span className="uploaded-file-status success">{validation}</span>}
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	catalogueBackupsStatus: store.catalogueBackupsStatus
});
export default connect(mapStateToProps)(BulkOperation);

const Header = ({ title, subTitle, exportTitle, exportTemplate, disabled, hideExportAction }) => (
	<div className="credits-section-header transactions-list-header">
		<div className="header-text">
			<div className="title" title={title || ""}>
				{title || "--"}
			</div>
			<div className="subtitle" title={subTitle || ""}>
				{subTitle || "--"}
			</div>
		</div>
		{/* <div className="header-action-button">
			<div className="action-buttons-container">
				{
					!hideExportAction &&
					<Button
						clickHandler={exportTemplate}
						classes={disabled ? "disabled" : ""}
						type="secondary"
					>
						{exportTitle}
					</Button>
				}
			</div>
		</div> */}
	</div>
);

const Table = ({ data, loading }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container template-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No data found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = () => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text field`}>Field Name</div>
		<div className={`at-table-cell at-table-header at-header-text required`}>Required</div>
		<div className={`at-table-cell at-table-header at-header-text accepted-values`}>Accepted Values</div>
	</div>
);

const TableList = ({ fieldName, description, acceptedValues, isRequired, reqdDesc, style }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text field" title={fieldName || ""}>
				<div className="field-desc">
					{fieldName || "--"}
					{description && (
						<div className="text--light desc-text" title={description || ""}>
							{description || "--"}
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text required">
				<CheckBox checked={isRequired} clickHandler={() => {}} classes="at-checkbox--disabled" />
				<div className="text--light">{reqdDesc || ""}</div>
			</div>
			<div className="at-table-cell at-cell-text accepted-values">
				{acceptedValues ? (
					<React.Fragment>
						{typeof acceptedValues === "string" && acceptedValues}
						{typeof acceptedValues === "object" &&
							acceptedValues.map((val, i) => (
								<span className="text--light" key={i}>
									{val}
								</span>
							))}
					</React.Fragment>
				) : (
					"--"
				)}
			</div>
		</animated.div>
	);
};
