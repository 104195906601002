export const PERISCOPE_COUNTRY_WISE_SUPPORTED_PLATFORMS = {
	India: ["swiggy", "zomato"],
	MENA: ["talabat", "deliveroo", "ubereats"],
	UK: ["deliveroo", "ubereats", "justeat"],
	US: ["ubereats"]
};

export const CAMPAIGN_DYANAMIC_VARS = [
	{
		name: "User name",
		value: "{{user_name}}",
		length: 7
	},
	{
		name: "User email",
		value: "{{user_email}}",
		length: 15
	},
	{
		name: "User phone",
		value: "{{user_phone}}",
		length: 13
	},
	{
		name: "User points",
		value: "{{user_current_points}}",
		length: 4
	},
	{
		name: "User wallet balance",
		value: "{{user_wallet_balance}}",
		length: 4
	}
];

export const ORDER_CHANNELS = [
	{ label: "Web", value: "web" },
	{ label: "iOS", value: "app_ios" },
	{ label: "Android", value: "app_android" },
	{ label: "Satellite", value: "satellite" }
];

export const SIGNUP_CHANNELS = [
	{ label: "Web", value: "web" },
	{ label: "iOS", value: "app_ios" },
	{ label: "Android", value: "app_android" },
	{ label: "Satellite", value: "satellite" },
	{ label: "In-store", value: "in_store" }
];

export const CART_CHANNELS = [
	{ label: "Web", value: "web" },
	{ label: "iOS", value: "app_ios" },
	{ label: "Android", value: "app_android" }
];

// key:value pair to show labels for CampaignStatusType
export const CAMPAIGN_STATUS_LABELS = {
	DRAFT: "Draft",
	COMPLETED: "Completed",
	CANCELLED: "Cancelled",
	SCHEDULED: "Scheduled",
	SCHEDULED_RECURRING: "Recurring",
	SCHEDULED_INACTIVE: "Inactive",
	RUNNING: "Running"
};

export const FULFILLMENT_MODES = [
	{ label: "Pickup", value: "pickup" },
	{ label: "Delivery", value: "delivery" },
	{ label: "DineIn", value: "dinein" }
];

export const PAYMENT_MODES = [
	{ label: "Cash", value: "cash" },
	{ label: "Prepaid", value: "prepaid" },
	{ label: "Payment Gateway", value: "payment_gateway" },
	{ label: "Card on Delivery", value: "card_on_delivery" }
	// {label: 'PayTM', value: 'paytm'},
	// {label: 'Simpl', value: 'simpl'},
	// {label: 'Paypal', value: 'paypal'},
];

export const DASHBOARD_DURATION_PRESETS = [
	{ label: "Today", value: "TODAY" },
	{ label: "Yesterday", value: "YESTERDAY" },
	{ label: "Last 7 days", value: "LAST_7_DAYS" },
	{ label: "This week", value: "THIS_WEEK" },
	{ label: "Last 30 days", value: "LAST_30_DAYS" },
	{ label: "This month", value: "THIS_MONTH" },
	{ label: "Last 90 days", value: "LAST_90_DAYS" },
	{ label: "This year", value: "THIS_YEAR" }
];

export const MEDIUM_OPTIONS = [
	{ label: "SMS", value: "SMS" },
	{ label: "Email", value: "EMAIL" },
	{ label: "App Push Notification", value: "APP_PUSH_NOTIFICATION" }
	// { label: 'Web Push Notification', value: 'WEB_PUSH_NOTIFICATION'},
];

export const MESSAGE_TYPES = [
	{ label: "Info", value: "INFO" },
	{ label: "Alert", value: "ALERT" },
	{ label: "Promo", value: "PROMO" },
	{ label: "Coupon", value: "COUPON" },
	{ label: "Reward", value: "REWARD" },
	{ label: "Cashback", value: "CASHBACK" }
];

export const CONVERSION_TRACKING_TYPE = [
	{
		title: "Online order placed",
		value: "ONLINE_ORDER_PLACED"
	},
	{
		title: "In store purchase made",
		value: "IN_STORE_PURCHASE_MADE"
	},
	{
		title: "Coupon used",
		value: "COUPON_USED"
	}
];

export const BIZ_CREDIT_TRANSACTION_TYPE = [
	{
		title: {
			DEBIT: "Campaign"
		},
		value: "CAMPAIGN_SCHEDULED"
	},
	{
		title: {
			CREDIT: "Campaign (Cancelled)"
		},
		value: "CAMPAIGN_CANCELLED"
	},
	{
		title: {
			CREDIT: "Credits reload"
		},
		value: "ONLINE_RELOAD"
	},
	{
		title: {
			CREDIT: "Credits granted",
			DEBIT: "Credits adjusted"
		},
		value: "GRANT_ADJUSTABLE"
	},
	{
		title: {
			CREDIT: "Gift credits granted",
			DEBIT: "Gift credits expired"
		},
		value: "GRANT_TRIAL"
	},
	{
		title: {
			CREDIT: "Credits reload (Offline)",
			DEBIT: "Credits revoked (Offline)"
		},
		value: "GRANT_PERSISTENT"
	},
	{
		title: {
			CREDIT: "E-Bill",
			DEBIT: "E-Bill"
		},
		value: "E_BILL"
	},
	{
		title: {
			CREDIT: "SMS sent",
			DEBIT: "SMS sent"
		},
		value: "SMS_SENT"
	},
	{
		title: {
			CREDIT: "SMS: Meraki Login OTP",
			DEBIT: "SMS: Meraki Login OTP"
		},
		value: "SMS_LOGIN_OTP"
	},
	{
		title: {
			CREDIT: "SMS: OTP",
			DEBIT: "SMS: OTP"
		},
		value: "SMS_OTP"
	},
	{
		title: {
			CREDIT: "SMS Sent (Internal)",
			DEBIT: "SMS Sent (Internal)"
		},
		value: "SMS_INTERNAL"
	}
];

export const getRandFlatColor = (() => {
	let colorArray = ["#1abc9c", "#3498db", "#e74c3c", "#8e44ad", "#f39c12", "#34495e"];

	let count = colorArray.length;
	let rand = Math.floor(Math.random() * count);
	return colorArray[rand];
})();

export const HOURS = [];
export const MINUTES = [];
for (let i = 0; i < 24; i++) {
	let hourObject = {
		label: i < 10 ? `0${i}` : `${i}`,
		value: i < 10 ? `0${i}` : `${i}`
	};
	HOURS.push(hourObject);
}
for (let i = 0; i < 60; i++) {
	let hourObject = {
		label: i < 10 ? `0${i}` : `${i}`,
		value: i < 10 ? `0${i}` : `${i}`
	};
	MINUTES.push(hourObject);
}

export const TWELVE_HOUR_MAPPING = {
	"00": "12",
	"01": "01",
	"02": "02",
	"03": "03",
	"04": "04",
	"05": "05",
	"06": "06",
	"07": "07",
	"08": "08",
	"09": "09",
	10: "10",
	11: "11",
	12: "12",
	13: "01",
	14: "02",
	15: "03",
	16: "04",
	17: "05",
	18: "06",
	19: "07",
	20: "08",
	21: "09",
	22: "10",
	23: "11"
};

export const CRITERIA_ANALYTICS = ["ORDER_CHANNEL", "USER_SIGNUP_CHANNEL"];

export const TOPIC_LIST_ANALYTICS = ["SALES", "COUNT", "AVERAGE_SALES", "USER_SIGNUPS"];

export const TOPIC_LIST_TOP_PERFORMING = [
	"ITEMS_TOP_PERFORMING",
	"STORES_TOP_PERFORMING",
	"COUPONS_TOP_PERFORMING",
	"CATEGORIES_TOP_PERFORMING",
	"CITIES_TOP_PERFORMING",
	"SKUS_TOP_PERFORMING"
];

export const TOPIC_LIST_SA_OVERVIEW = [
	"SALES",
	"COUNT",
	"AVERAGE_SALES",
	"ITEMS_COUNT",
	"ITEMS_AVERAGE_COUNT",
	"LOST_SALES",
	"LOST_ORDERS"
];

export const TOPIC_LIST_SA_OVERVIEW_LABELS = {
	SALES: "Total revenue from all the orders excepts cancelled ones",
	COUNT: "All orders except cancelled ones",
	AVERAGE_SALES: "Average order value of all orders",
	ITEMS_COUNT: "Total number of items sold",
	ITEMS_AVERAGE_COUNT: "Average number of items in an order",
	LOST_SALES: "Total missed revenue from all cancelled orders",
	LOST_ORDERS: "All orders that got cancelled"
};

export const TOPIC_LIST_SA_OVERVIEW_TYPE_CURRENCY = ["SALES", "AVERAGE_SALES", "LOST_SALES"];

export const TOPIC_LIST_SA_PERFORMANCE = [
	"ITEMS_TOP_PERFORMING",
	"STORES_TOP_PERFORMING",
	"COUPONS_TOP_PERFORMING",
	"CATEGORIES_TOP_PERFORMING",
	"CITIES_TOP_PERFORMING",
	"SKUS_TOP_PERFORMING"
];

export const PRODUCT_TYPE_MODULE_MAPPING = {
	ORDERING: "Meraki",
	LOYALTY: "Loyalty"
};

// app push notification banner image size limit: 1MB
export const APP_PUSH_BANNER_SIZE_LIMIT = 1048576;

export const CRON_WEEK_OPTIONS = [
	{
		label: "Monday",
		value: "1"
	},
	{
		label: "Tuesday",
		value: "2"
	},
	{
		label: "Wednesday",
		value: "3"
	},
	{
		label: "Thursday",
		value: "4"
	},
	{
		label: "Friday",
		value: "5"
	},
	{
		label: "Saturday",
		value: "6"
	},
	{
		label: "Sunday",
		value: "0"
	}
];

export const DEFAULT_SUFFIX_META = [
	{ base: 1000, suffix: "K" },
	{ base: 1000000, suffix: "M" },
	{ base: 1000000000, suffix: "B" },
	{ base: 1000000000000, suffix: "T" }
];

export const CURRENCY_LOCALE_MAPPING = {
	INR: {
		languageCode: "en-IN",
		suffixMeta: [
			{ base: 1000, suffix: "K" },
			{ base: 100000, suffix: "L" },
			{ base: 10000000, suffix: "Cr" },
			{ base: 1000000000, suffix: "B" }
		]
	},
	USD: {
		languageCode: "en-US",
		suffixMeta: DEFAULT_SUFFIX_META
	},
	AED: {
		languageCode: "ar-AE",
		suffixMeta: DEFAULT_SUFFIX_META
	},
	IDR: {
		languageCode: "en-US",
		suffixMeta: DEFAULT_SUFFIX_META
	}
};

export const STORE_TYPES = [
	{
		type: "ORDERING",
		fields: {
			desktop: [
				{ label: "Order ID", value: "id", searchKey: "id" },
				{ label: "Date & Time", value: "date", searchKey: "created" },
				{ label: "Location", value: "outlet" },
				{ label: "Customer", value: "user" },
				{ label: "Payment Mode", value: "payment" },
				{ label: "Status", value: "status" },
				{ label: "Associated Brands", value: "associatedBrands" },
				{ label: "Amount", value: "amount", searchKey: "total" }
			],
			mobile: [
				{ label: "Order ID", value: "id", searchKey: "id" },
				{ label: "Date & Time", value: "date", searchKey: "created" },
				{ label: "Customer", value: "user" },
				{ label: "Amount", value: "amount", searchKey: "total" }
			]
		}
	},
	{
		type: "LOYALTY",
		fields: {
			desktop: [
				{ label: "Purchase ID", value: "id", searchKey: "id" },
				{ label: "Date & Time", value: "date", searchKey: "created" },
				{ label: "Location", value: "outlet" },
				{ label: "Customer", value: "user" },
				{ label: "Associated Brands", value: "associatedBrands" },
				{ label: "Amount", value: "amount", searchKey: "amount" }
			],
			mobile: [
				{ label: "Purchase ID", value: "id", searchKey: "id" },
				{ label: "Date & Time", value: "date", searchKey: "created" },
				{ label: "Customer", value: "user" },
				{ label: "Amount", value: "amount", searchKey: "amount" }
			]
		}
	}
];

export const PAYMENT_MODE_MAP = {
	CASH: "Cash",
	PREPAID: "Prepaid",
	PAYMENT_GATEWAY: "Payment Gateway",
	CARD_ON_DELIVERY: "Card on delivery",
	PAYTM: "PayTM",
	AGGREGATOR: "Aggregator",
	SIMPL: "Simpl",
	PAYPAL: "Paypal"
};

export const FULFILLMENT_MODE_MAP = {
	DELIVERY: "Delivery",
	PICKUP: "Pickup",
	DINEIN: "Dine in",
	PARTNER: "Partner"
};

export const DELIVERY_STATUS_MAP = {
	UNASSIGNED: "Unassigned",
	ASSIGNED: "Assigned",
	REASSIGNED: "Re-assigned",
	AT_STORE: "At store",
	OUT_FOR_DELIVERY: "Out for delivery",
	DELIVERED: "Delivered",
	CANCELLED: "Cancelled",
	NO_RIDER_FOUND: "No rider found"
};

export const ORDER_STATUS_MAP = {
	AWAITING_PAYMENT: "Awaiting payment",
	PLACED: "Placed",
	ACKNOWLEDGED: "Acknowledged",
	DECLINED_BY_DP: "Declined",
	DRIVER_ASSIGNED: "Rider assigned",
	DRIVER_REACHED_SHOP: "Rider reached shop",
	DISPATCHED: "Dispatched",
	CONFIRMED: "Confirmed",
	COMPLETED: "Completed",
	CANCELLED: "Cancelled",
	MODIFIED: "Modified",
	CANCELLED_FOR_MODIFICATION: "Cancelled for Modification",
	FOOD_READY: "Food ready"
};

export const PAYMENT_STATUS_MAP = {
	initialized: "Initialized",
	authorized: "Authorized",
	completed: "Completed",
	failed: "Failed",
	cancelled: "Cancelled",
	refunded: "Refunded",
	flagged: "Flagged",
	pending: "Pending"
};

export const ORDER_STATUS_PRESENT_TENSE_MAP = {
	AWAITING_PAYMENT: "Awaiting payment",
	PLACED: "Placed",
	ACKNOWLEDGED: "Acknowledge",
	DECLINED_BY_DP: "Decline",
	DRIVER_ASSIGNED: "Assign Rider",
	DRIVER_REACHED_SHOP: "Rider reached shop",
	DISPATCHED: "Dispatch",
	CONFIRMED: "Confirm",
	COMPLETED: "Complete",
	CANCELLED: "Cancel",
	MODIFIED: "Modify",
	FOOD_READY: "Food ready"
};

export const ORDER_BILL_COMPONENTS = [
	{
		field: "subtotal",
		label: "Sub total",
		type: "debit"
	},
	{
		field: "totalCharges",
		label: "Total charge",
		type: "debit"
	},
	{
		field: "totalTaxes",
		label: "Total tax",
		type: "debit"
	},
	{
		field: "discount",
		label: "Total discount",
		type: "credit"
	},
	{
		field: "couponText",
		label: "Coupon used",
		type: "debit",
		currency: false,
		isNumber: false
	},
	{
		field: "walletCreditApplied",
		label: "Wallet credits used",
		type: "credit"
	}
];

export const ONLINE_TRANSACTIONS_LIST_SEARCH_FIELDS = [
	{
		title: "Order ID",
		value: "up_order_id"
	},
	{
		title: "Aggregator ID",
		value: "ext_plt_order_id"
	},
	{
		title: "POS ID",
		value: "pos_order_id"
	},
	{
		title: "User phone",
		value: "user_phone"
	},
	{
		title: "User Name",
		value: "user_name"
	}
];

export const IN_STORE_TRANSACTIONS_LIST_SEARCH_FIELDS = [
	{
		title: "POS ID",
		value: "bill_number"
	},
	{
		title: "User phone",
		value: "user_phone"
	},
	{
		title: "User Name",
		value: "user_name"
	}
];

export const ORDER_CHANNEL_MAP = {
	IN_STORE: "In store",
	WEB: "Web",
	APP_IOS: "iOS",
	APP_ANDROID: "Android",
	SATELLITE: "Satellite",
	ZOMATO: "Zomato",
	FOODPANDA: "Foodpanda",
	SWIGGY: "Swiggy",
	UBEREATS: "Ubereats",
	SCOOTSY: "Scootsy",
	DELIVEROO: "Deliveroo"
};

export const EXTERNAL_PLATFORMS_LOGO = {
	ZOMATO: "/assets/icons/platform-icon-zomato.svg",
	FOODPANDA: "/assets/icons/food-panda.svg",
	UBEREATS: "/assets/icons/ubereats-tiny.png",
	SWIGGY: "/assets/icons/swiggy-tiny.png",
	DELIVEROO: "/assets/icons/platform-deliveroo.png",
	WEB: "/assets/icons/icons8-globe-40.png",
	APP_IOS: "/assets/icons/icon-ios.svg",
	APP_ANDROID: "/assets/icons/icon-android.svg",
	SATELLITE: "/assets/icons/icon-phone.svg",
	IN_STORE: "/assets/icons/in-store-tiny.png"
};

export const PLATFORM_NAME_MAP = {
	EATEASY: "SMILES",
	eateasy: "smiles"
};

const platformNameNormalizer = {
	get: function (target, prop, receiver) {
		/*
		 * Normalizing platform names as they are inconsistent in DB and BE
		 * Convert the property name to lowercase and remove spaces
		 */
		const processedProp =
			typeof prop === "string" && !["all platforms"].includes(prop)
				? prop.toLowerCase().replace(/\s+/g, "")
				: prop;
		return Reflect.get(target, processedProp, receiver);
	}
};

export const CATALOGUE_PLATFORMS_LOGO = new Proxy(
	{
		default: "/assets/icons/icons8-globe-40.png",
		"all platforms": "/assets/icons/icon-globe.svg",
		urbanpiper: "/assets/icons/platform-urbanpiper.svg",
		prime: "/assets/icons/prime-logo.svg",
		zomato: "/assets/icons/platform-icon-zomato.svg",
		swiggy: "/assets/icons/platform-swiggy.svg",
		apps: "/assets/icons/platform-apps.svg",
		web: "/assets/icons/platform-web.svg",
		ubereats: "/assets/icons/platform-uber-eats.svg",
		amazon: "/assets/icons/platform-amazon.svg",
		amazonfood: "/assets/icons/platform-amazon.svg",
		foodpanda: "/assets/icons/food-panda.svg",
		dunzo: "/assets/icons/platform-dunzo.png",
		talabat: "/assets/icons/platform-talabat.png",
		hungerbox: "/assets/icons/platform-hungerbox.png",
		jahez: "/assets/icons/platform-jahez.png",
		eazydiner: "/assets/icons/platform-eazydiner.png",
		shouut: "/assets/icons/platform-shouut.png",
		scootsy: "/assets/icons/platform-scootsy.png",
		dotpe: "/assets/icons/platform-dotpe.png",
		magicpin: "/assets/icons/platform-magicpin.png",
		noonfood: "/assets/icons/platform-noonfood.png",
		gupshup: "/assets/icons/platform-gupshup.png",
		deliveroo: "/assets/icons/platform-deliveroo.png",
		radyes: "/assets/icons/platform-radyes.png",
		inresto: "/assets/icons/platform-inresto.png",
		careem: "/assets/icons/platform-careem.png",
		chatfood: "/assets/icons/platform-chatfood.png",
		curefit: "/assets/icons/platform-curefit.svg",
		cultfit: "/assets/icons/platform-curefit.svg",
		justeat: "/assets/icons/platform-justeat.png",
		fidoo: "/assets/icons/platform-fidoo.png",
		mrsool: "/assets/icons/platform-mrsool.png",
		nextdoorhub: "/assets/icons/platform-nextdoorhub.png",
		peppo: "/assets/icons/platform-peppo.png",
		instashop: "/assets/icons/platform-instashop.png",
		eateasy: "/assets/icons/platform-eateasy.svg",
		droptheq: "/assets/icons/platform-droptheq.jpg",
		masalabox: "/assets/icons/platform-masalabox.jpg",
		hungerstation: "/assets/icons/platform-hungerstation.png",
		zomatomarket: "/assets/icons/platform-icon-zomato.svg",
		swiggystore: "/assets/icons/platform-swiggy.svg",
		app_ios: "/assets/icons/icon-ios.svg",
		app_android: "/assets/icons/icon-android.svg",
		in_store: "/assets/icons/in-store-tiny.png",
		satellite: "/assets/icons/icon-phone.svg",
		meraki: "/assets/icons/icon-meraki.svg",
		rezoy: "/assets/icons/platform-rezoy.svg",
		tipplr: "/assets/icons/platform-tipplr-logo.svg",
		app4: "/assets/icons/platform-app4.png",
		flipdish: "/assets/icons/platform-flipdish.svg",
		konnectedtech: "/assets/icons/platform-konnectedtechnology.png",
		hungrypanda: "/assets/icons/platform-hungrypanda.svg",
		bitsila: "/assets/icons/platform-bitsila.svg",
		itcstore: "/assets/icons/platform-itcstore.png",
		uengage: "/assets/icons/platform-uengage.jpeg",
		ordable: "/assets/icons/platform-ordable.svg",
		dca: "/assets/icons/platform-dca.png",
		labaiik: "/assets/icons/platform-labaik.png",
		sticky: "/assets/icons/platform-sticky.svg",
		pumpkinkart: "/assets/icons/platform-pumpkinkart.png",
		easyfoods: "/assets/icons/platform-easyfood.jpeg",
		wolt: "/assets/icons/platform-wolt.png",
		glovo: "/assets/icons/platform-glovo.jpeg",
		yalah: "/assets/icons/platform-yalah.png",
		rappi: "/assets/icons/platform-rappi.png",
		grubhub: "/assets/icons/platform-icon-grubhub.png",
		doordash: "/assets/icons/platform-icon-doordash.png",
		cari: "/assets/icons/platform-cari.png",
		isthara: "/assets/icons/platform-isthara.svg",
		rafeeq: "/assets/icons/platform-rafeeq.png",
		supplynote: "/assets/icons/platform-supplynote.png",
		gintaa: "/assets/icons/platform-gintaa.png",
		smiles: "/assets/icons/platform-smiles.svg"
	},
	platformNameNormalizer
);

export const MERAKI_WEB_EDITOR_PAGES_LIST = {
	default: "/assets/meraki/pages/menu.svg",
	menu: "/assets/meraki/pages/menu.svg",
	item_detail: "/assets/meraki/pages/item-detail.svg",
	order_history: "/assets/meraki/pages/order-history.svg",
	checkout: "/assets/meraki/pages/checkout.svg",
	favourite: "/assets/meraki/pages/favourite.svg",
	cart: "/assets/meraki/pages/cart.svg",
	wallet: "/assets/meraki/pages/wallet.svg",
	refer_earn: "/assets/meraki/pages/refer-earn.svg",
	profile: "/assets/meraki/pages/profile.svg",
	order_success: "/assets/meraki/pages/order-success.svg",
	privacy: "/assets/meraki/pages/privacy.svg",
	terms: "/assets/meraki/pages/terms.svg",
	store_locator: "/assets/meraki/pages/store-locator.svg",
	coupons: "/assets/meraki/pages/coupons.svg",
	page_not_found: "/assets/meraki/pages/page-not-found.svg",
	custom_page: "/assets/meraki/pages/custom-page.svg"
};

export const DELIVERY_STATUS_BACKGROUND_COLOR_MAP = {
	UNASSIGNED: "#a47c811a",
	ASSIGNED: "#543cb11a",
	REASSIGNED: "#a43c811a",
	AT_STORE: "#2f58f21a",
	OUT_FOR_DELIVERY: "#543cb11a",
	DELIVERED: "#2ecc711a",
	CANCELLED: "#ff425c1a",
	NO_RIDER_FOUND: "#ff425c1a"
};

export const DELIVERY_STATUS_TEXT_COLOR_MAP = {
	UNASSIGNED: "#a47c81",
	ASSIGNED: "#543cb1",
	REASSIGNED: "#a43c81",
	AT_STORE: "#2f58f2",
	OUT_FOR_DELIVERY: "#543cb1",
	DELIVERED: "#2ecc71",
	CANCELLED: "#ff425c",
	NO_RIDER_FOUND: "#ff425c"
};

export const DELIVERY_STATUS_BACKGROUND_COLOR_DEFAULT = "#a43c811a";

export const DELIVERY_STATUS_TEXT_COLOR_DEFAULT = "#a43c81";

export const ORDER_STATUS_BACKGROUND_COLOR_MAP = {
	AWAITING_PAYMENT: "#FEF6E9",
	PLACED: "#5c441d1a",
	ACKNOWLEDGED: "#F7EDF7",
	DECLINED_BY_DP: "#8c54541a",
	DRIVER_ASSIGNED: "#0850601a",
	DRIVER_REACHED_SHOP: "#0c452d1a",
	DISPATCHED: "#ECF7F6",
	CONFIRMED: "#ECF7F0",
	COMPLETED: "#ECF7F0",
	CANCELLED: "#FFE5E5",
	MODIFIED: "#0d7ea01a",
	CANCELLED_FOR_MODIFICATION: "#FFE5E5",
	FOOD_READY: "#ECF0F7",
	EXPIRED: "#F2F2F2"
};

export const ORDER_STATUS_TEXT_COLOR_MAP = {
	AWAITING_PAYMENT: "#C2831C",
	PLACED: "#5C441D",
	ACKNOWLEDGED: "#753675",
	DECLINED_BY_DP: "#8C5454",
	DRIVER_ASSIGNED: "#085060",
	DRIVER_REACHED_SHOP: "#0C452D",
	DISPATCHED: "#175751",
	CONFIRMED: "#0F5C2A",
	COMPLETED: "#0F5C2A",
	CANCELLED: "#961D1D",
	MODIFIED: "#0D7EA0",
	CANCELLED_FOR_MODIFICATION: "#961D1D",
	FOOD_READY: "#374F78",
	EXPIRED: "#4E4E4E"
};

export const ORDER_STATUS_BACKGROUND_COLOR_DEFAULT = "#a43c811a";

export const ORDER_STATUS_TEXT_COLOR_DEFAULT = "#a43c81";

export const ORDER_STATUS_COLOR_DEFAULT = "#a43c81";

export const RECONCILIATION_STATUS_BACKGROUND_COLOR_MAP = {
	reconciled: "#2ecc711a",
	missing: "#ff425c1a",
	inconsistent: "#FEF6E9",
	open: "#a47c811a",
	closed: "#9797971a",
	resolved: "#2f58f21a"
};

export const RECONCILIATION_STATUS_TEXT_COLOR_MAP = {
	reconciled: "#2ecc71",
	missing: "#ff425c",
	inconsistent: "#C2831C",
	open: "#a47c81",
	closed: "#979797",
	resolved: "#2f58f2"
};

export const RECONCILIATION_STATUS_BACKGROUND_COLOR_DEFAULT = "#a43c811a";

export const RECONCILIATION_STATUS_TEXT_COLOR_DEFAULT = "#a43c81";

export const PAYMENT_TRANSACTION_COLOR_MAP = {
	initialized: "#a43c81",
	authorized: "#543cb1",
	completed: "#2ecc71",
	failed: "#ff425c",
	cancelled: "#ff425c",
	refunded: "#2f58f2",
	flagged: "#ff425c",
	pending: "#e67e22"
};

export const PAYMENT_TRANSACTION_COLOR_DEFAULT = "#a43c81";

export const TREEMAP_COLORS = [
	"#543cb1",
	"#008ecc",
	"#228b22",
	"#fc6600",
	"#0e4c92",
	"#c21807",
	"#b8860b",
	"#813f0b",
	"#008080",
	"#c71585",
	"#2e8b57",
	"#008000",
	"#ff0090",
	"#8b008b",
	"#ec5578",
	"#ed2939",
	"#5f9ea0",
	"#543cb1",
	"#48494b",
	"#009e60",
	"#de3163",
	"#960019",
	"#6a5acd",
	"#d5b85a",
	"#3cb371",
	"#800000",
	"#0f52ba",
	"#7e481c",
	"#ff6347",
	"#997950",
	"#dc143c",
	"#cd5c5c",
	"#b8860b",
	"#006400",
	"#1e90ff",
	"#800080",
	"#d21f3c",
	"#e4cd05",
	"#ec5578",
	"#808000",
	"#000080",
	"#9932cc",
	"#ff2400",
	"#4d516d",
	"#ff0090",
	"#cc7722",
	"#543cb1",
	"#a43c81",
	"#228b22",
	"#a47c81",
	"#0080fe",
	"#fcd12a",
	"#800000",
	"#043927",
	"#ef7215",
	"#4b0082",
	"#4682b4",
	"#c21807",
	"#8fbc8f",
	"#1c2951",
	"#c71585",
	"#5097a4",
	"#daa520",
	"#b22222",
	"#708090",
	"#0000cd",
	"#d2691e",
	"#556b2f",
	"#8b008b",
	"#960019",
	"#ff7f50",
	"#008080",
	"#8a2be2",
	"#5f9ea0",
	"#ed2939",
	"#696969",
	"#008ecc",
	"#e67e22",
	"#ff425c"
];

export const ANALYTICS_DEFAULT_COLORS = [
	"#2543B6",
	"#88D9F8",
	"#FFA05B",
	"#B6D53A",
	"#FFD1FF",
	"#9FB3FF",
	"#FF709F",
	"#FFE55B",
	"#9048C8",
	"#20B2AA"
];

export const ANALYTICS_SANKEY_COLORS = {
	sankey1: "#2543B6",
	sankey2: "#587AF6",
	sankey3: "#9FB3FF",
	sankey4: "#C5D1FC",
	sankeyPositive: "#2ECC71",
	sankeyNegative: "#FF425C",
	sankeyIntermediate1: "#FFC045",
	sankeyIntermediate2: "#FD9D52"
};

export const ANALYTICS_HEATMAP_COLORS = {
	cool: ["#E4EAFF", "#9FB3FF", "#587AF6", "#2543B6"],
	warm: ["#FDD5AC", "#FD9D53", "#EB600E", "#8C2D04"]
};

export const BRAND_COLORS = ["brown", "green", "purple", "blue", "red"];

export const CUSTOMERS_LIST_SEARCH_FIELDS = [
	{
		title: "Customer's Name",
		value: "user_name"
	},
	{
		title: "Customer's Phone",
		value: "user_phone"
	},
	{
		title: "Customer's Email",
		value: "email"
	}
];

export const CUSTOMERS_LIST_HEADER_FIELDS = [
	{ label: "Customer", value: "customers-details" },
	{ label: "Email", value: "email" },
	{ label: "Signup Date", value: "signup-date", sortKey: "date_joined" },
	{ label: "Signup Channel/Platform", value: "outlet" },
	{ label: "Total No. of Orders", value: "num-orders" },
	{ label: "Total Value of Orders", value: "orders-value" }
];

export const CUSTOMERS_LIST_HEADER_FIELDS_MOBILE = [
	{ label: "Customer", value: "customers-details" },
	{ label: "Email", value: "email" },
	{ label: "Signup Date", value: "signup-date", sortKey: "date_joined" },
	{ label: "Total No. of Orders", value: "num-orders" },
	{ label: "Total Value of Orders", value: "orders-value" }
];

export const LOCATIONS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "title", sortKey: "name" },
	{ label: "City", value: "city", sortKey: "city" },
	{ label: "Associated Items", value: "associated_items" },
	{ label: "Associated Platforms", value: "associated_platforms" }
];

export const CATEGORIES_ITEMS_LIST_HEADER_FIELDS = [
	{ label: "Items", value: "items", sortKey: "title" },
	{ label: "Assoc. Locations", value: "associated_locations", sortKey: "related_locations_count" },
	{ label: "Assoc. brands", value: "brands" },
	{ label: "Price", value: "price", sortKey: "item_price" }
];

export const ITEMS_LOCATIONS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Availability", value: "availability" },
	{ label: "Stock count", value: "stock-count" },
	{ label: "Meraki Price", value: "price", subText: "(Your apps & website)" },
	{ label: "Hub Price", value: "aggregator_price", subText: "(Aggregators)" }
];

export const ITEMS_RELATED_LIST_HEADER_FIELDS = [
	{ label: "Title", value: "title" },
	{ label: "Category", value: "category" },
	{ label: "Price", value: "price" }
];
export const RECONCILIATION_ORDERS_HEADER_FIELDS = [
	{ label: "Order ID", value: "title" },
	{ label: "Order State", value: "title" },
	{ label: "Total", value: "category" },
	{ label: "Reconciliation Status", value: "price" }
];

export const ITEMS_LIST_HEADER_FIELDS = [
	{ label: "Items", value: "items", sortKey: "title" },
	{ label: "Category", value: "category", sortKey: "item_category_order" },
	{ label: "Associated Locations", value: "associated_locations", sortKey: "related_locations_count" },
	{ label: "Price", value: "price", sortKey: "item_price" }
];

export const MODIFIERS_LOCATIONS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Location", value: "city" },
	{ label: "Stock count", value: "stock-count" },
	{ label: "Price At Location", value: "price" }
];

export const CATEGORIES_EDIT_ITEMS_LIST = [
	{ label: "Name", value: "items" },
	{ label: "Assoc. Locations", value: "locationsCount" },
	{ label: "Assoc. Brands", value: "brands" },
	{ label: "Price", value: "price" }
];

export const OPERATIONAL_EFFICIENCY_ORDERS_HEADER_FIELDS = [
	{ label: "ORDER ID", value: "order_id" },
	{ label: "PLACED", value: "placed" },
	{ label: "ACKNOWLEDGED", value: "acknowledged" },
	{ label: "FOOD READY", value: "food_ready" },
	{ label: "DISPATCHED", value: "dispatched" },
	{ label: "COMPLETED", value: "completed" }
];

export const OPERATIONAL_EFFICIENCY_LOCATION_HEADER_FIELDS = [
	{ label: "OUTLET", value: "outlet" },
	{ label: "ACKNOWLEDGING", value: "acknowledged" },
	{ label: "PREPARATION", value: "food_ready" },
	{ label: "DISPATCHING", value: "dispatched" },
	{ label: "CANCELLING", value: "cancelled" }
];

export const OPERATIONAL_EFFICIENCY_ORDERS_HEADER_FIELDS_MOBILE = [
	{ label: "ORDER ID", value: "order_id" },
	{ label: "OUTLET", value: "outlet" },
	{ label: "PLACED", value: "placed" },
	{ label: "COMPLETED", value: "completed" }
];

export const CUSTOMER_FEEDBACKS_HEADER_FIELDS = [
	{ label: "Date & Time", value: "created", sortKey: "created" },
	{ label: "Comments", value: "notes" },
	{ label: "Rating", value: "rating", sortKey: "rating" },
	{ label: "Order ID", value: "order" },
	{ label: "Location", value: "location" }
];

export const CUSTOMER_FEEDBACK_ACTIONS = [
	{ label: "Reply by SMS", value: "SMS" },
	{ label: "Reply by E-Mail", value: "EMAIL" }
];

// for events
export const TRACKING_EVENT_NAMES = {
	// location
	ARCHIVE_RESTORE: "archive_restore",
	RTGL_START: "rtgl_start",
	RTGL_SUCCESS: "rtgl_success",
	RTGL_FAILURE: "rtgl_failed",
	ON_DEMAND_VERIFICATION: "on_demand_verification",
	UPDATE_ENTITES_LOCATIONS_LIST_VIEW: "update_entities_locations_list_view",
	PUBLISH_CATALOGUE: "publish_catalogue",
	LOCATION_BASIC_INFO_UPDATE: "location_basic_info_update",
	LOCATION_HUB_UPDATE: "location_hub_update",
	LOCATION_MERAKI_UPDATE: "location_meraki_update",
	LOCATION_SELF_DELIVERY_UPDATE: "location_self_delivery_update",
	LOCATION_TIMING_GROUPS_UPDATE: "location_timing_groups_update",
	LOCATION_LOCATION_GROUPS_UPDATE: "location_location_groups_update",
	LOCATION_TIMINGS_UPSTREAM_CALL: "location_timings_upstream_call",
	LOCATION_COPY_CATALOGUE_FROM_ANOTHER_LOCATION: "location_copy_catalogue_from_another_location",
	LOCATION_CATALOGUE_CUSTOMIZE: "location_catalogue_customize",
	NEW_LOCATIONS_CREATED: "new_locations_created",
	AVAILABILITY: "availability",

	// catalogue
	NEW_TAG_CREATION: "new_tag_creation",
	NEW_TAG_GROUP_CREATION: "new_tag_group_creation",
	NEW_CATEGORY_CREATION: "new_category_creation",
	NEW_ITEM_CREATION: "new_item_creation",
	NEW_MODIFIER_CREATION: "new_modifier_creation",
	NEW_MODIFIER_GROUP_CREATION: "new_modifier_group_creation",
	NEW_TAX_CREATION: "new_tax_creation",
	NEW_CHARGE_CREATION: "new_charge_creation",
	CATEGORY_BASIC_INFO_UPDATE: "category_basic_info_update",
	CATEGORY_ITEM_UPDATE: "category_item_update",
	CATEGORY_SUBCATEGORY_UPDATE: "category_subcategory_update",
	ITEM_BASIC_INFO_UPDATE: "item_basic_info_update",
	ITEM_IMAGES_UPDATE: "item_images_update",
	ITEM_PLATFORM_UPDATE: "item_platform_update",
	ITEM_LOCATION_UPDATE: "item_location_update",
	ITEM_MODIFIER_GROUP_UPDATE: "item_modifier_group_update",
	CUSTOM_FIELDS_UPDATE: "custom_fields_update",
	MODIFIER_GROUP_BASIC_INFO_UPDATE: "modifier_group_basic_info_update",
	MODIFIER_BASIC_INFO_UPDATE: "modifier_basic_info_update",
	MODIFIER_IMAGES_UPDATE: "modifier_images_update",
	MODIFIER_LOCATION_UPDATE: "modifier_location_update",
	MODIFIER_GROUP_MODIFIER_UPDATE: "modifier_group_modifier_update",
	MODIFIER_NESTED_MODIFIER_GROUP_UPDATE: "modifier_nested_modifier_group_update",
	TAXES_BASIC_INFO_UPDATE: "taxes_basic_info_update",
	TAXES_LOCATION_GROUP_ITEM_GROUP_UPDATE: "taxes_location_group_item_group_update",
	CHARGES_BASIC_INFO_UPDATE: "charges_basic_info_update",
	CHARGES_LOCATION_GROUP_ITEM_GROUP_UPDATE: "charges_location_group_item_group_update",
	TAG_GROUP_BASIC_INFO_UPDATE: "tag_group_basic_info_update",
	TAG_GROUP_TAG_ASSOCIATION: "tag_group_tag_association",
	TAG_BASIC_INFO_UPDATE: "tag_basic_info_update",
	TAG_ENTITY_UPDATE: "tag_entity_update",
	ITEM_RECOMMENDED_ITEMS_UPDATE: "item_recommended_items_update",
	CASCADES: "cascades",

	//menu-over-catalogue
	MENU_LIST_VIEWS: "menu_list_views",
	LOCATION_DETAILS_MENU_TAB_VIEWS: "location_details_menu_tab_views",
	NEW_MENU_CREATION: "new_menu_creation",
	NEW_SECTION_CREATION: "new_section_creation",
	NEW_SUBSECTION_CREATION: "new_subsection_creation",
	NEW_ITEM_CREATION: "new_item_creation",
	MENU_SECTION_UPDATE: "menu_section_update",
	MENU_ITEMS_BASIC_INFO_UPDATE: "menu_items_basic_info_update",
	MENU_ITEMS_IMAGE_UPDATE: "menu_items_image_update",
	MENU_MODIFIER_DETAILS_BASIC_INFO_UPDATE: "menu_modifier_details_basic_info_update",

	MENU_ITEM_MODIFIER_GROUP_ASSOCIATION: "menu_item_modifier_group_association",
	MENU_MODIFIERGROUP_DETAILS_BASIC_INFO_UPDATE: "menu_modifiergroup_details_basic_info_update",
	MENU_MODIFIERGROUP_DETAILS_NESTED_MODIFIERS_ASSOCIATION: "menu_modifiergroup_details_nested_modifiers_association",
	MENU_MODIFIER_DETAILS_NESTED_MODIFIERGROUP_ASSOCIATION: "menu_modifier_details_nested_modifiergroup_association",
	CONFLICTING_RULE_RESOLUTIONS: "conflicting_rule_resolutions",

	MENU_REORDER: "menu_reorder",
	MENU_CHANGES_SAVED: "menu_changes_saved",
	MENU_CHANGES_DISCARDED: "menu_changes_discarded",
	MENU_PREVIEW: "menu_preview",
	MENU_PUBLISH: "menu_publish",
	MENU_RULES_TAB_VIEWS: "menu_rules_tab_views",
	MENU_NEW_RULE_CREATED: "menu_new_rule_created",
	MENU_PUBLISH_HISTORY_TAB_VIEWS: "menu_publish_history_tab_views",
	MENU_OLDVERSION_RESTORE: "menu_oldversion_restore",
	MENU_PREVIEW_FIX_ISSUES_CLICKS: "menu_preview_fix_issues_clicks",
	MENU_REVERIFY: "menu_reverify"
};

export const TRACKING_ACTION = {
	ARCHIVE: "archive",
	RESTORE: "restore",
	SET_ONLINE: "set_online",
	SET_OFFLINE: "set_offline",
	ASSOCIATE: "associate",
	DISASSOCIATE: "disassociate"
};

export const TRACKING_ENTITY = {
	LOCATION: "location",
	CATEGORY: "category",
	ITEM: "item",
	MODIFIER: "modifier",
	MODIFIER_GROUP: "modifier group",
	TAX: "tax",
	CHARGES: "charges",
	TAGS: "tags"
};

export const TRACKING_SOURCE = {
	LIST_VIEW_BULK: "list view bulk",
	LIST_VIEW_THREE_DOT: "list view 3 dot",
	DETAIL_VIEW: "detail view",
	CATEGORY_DETAILS: "category details",
	SUB_CATEGORIES_TAB: "sub categories tab",
	BASIC_INFORMATION_TAB: "basic information tab",
	ITEM_DETAILS: "item details",
	CATEGORIES: "categories",
	ITEMS: "items",
	MODIFIERS: "modifiers",
	MODIFIER_GROUPS: "modifier groups",
	TAXES: "taxes",
	CHARGES: "charges",
	TAGS: "tags"
};

export const TRACKING_TYPE = {
	NEW_PAIR_CREATED: "new pair created",
	EXISTING_PAIR_UPDATED: "existing pair updated"
};

export const TRACKING_STATUS = {
	SUCCESS: "success",
	FAILURE: "failure"
};

export const TRACKING_MISC = {
	INCREASE: "increase",
	DECREASE: "decrease",
	SAME: "same",
	INDEFINITELY: "indefinitely"
};

export const CUSTOMER_TIMELINE_HEADER_FIELDS = [
	{ label: "Event", value: "event" },
	{ label: "Date & Time", value: "created" },
	{ label: "Notes", value: "notes" }
];

export const LOCATION_GROUPS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Locations Associated", value: "locations" }
];

export const ITEM_GROUPS_LIST_HEADER_FIELDS = [
	{ label: "Name", value: "name" },
	{ label: "Items Associated", value: "items" }
];

export const CUSTOMER_TIMELINE_EVENTS_MAP = {
	CUSTOMER_SIGNUP: "Customer signup",
	SEND_MESSAGE: "Message sent",
	SEND_EMAIL: "Email sent",
	SEND_SMS: "SMS sent",
	REDEEM: "Coupon redeem",
	ORDER_PLACED: "Online order",
	WALLET_TRANSACTION: "Wallet transaction",
	POINTS_TRANSACTION: "Loyalty points transaction",
	PAYMENT: "Payment",
	CUSTOMER_PURCHASE: "In-store purchase",
	CARD_LINKED: "Card linked",
	CARD_CREATED: "Card created",
	CARD_VERIFIED: "Card verified",
	CARD_ACTIVATED: "Card activated",
	PLASTIC_CARD_CREATED: "Plastic card created"
};

export const SA_PERFORMANCE_CHANNELS = [
	{ label: "Web", value: "WEB" },
	{ label: "iOS", value: "APP_IOS" },
	{ label: "Android", value: "APP_ANDROID" },
	{ label: "Satellite", value: "SATELLITE" },
	{ label: "Zomato", value: "ZOMATO" },
	{ label: "Foodpanda", value: "FOODPANDA" },
	{ label: "Swiggy", value: "SWIGGY" },
	{ label: "Ubereats", value: "UBEREATS" },
	{ label: "Scootsy", value: "SCOOTSY" }
];

export const healthMetricsdisplayNameMapping = {
	percentageAcknowledged: "ACKNOWLEDGED",
	percentageFoodReady: "FOOD READY",
	percentageDispatched: "DISPATCHED",
	percentageCancelled: "CANCELLED"
};

export const timeSpentDisplayNameMapping = {
	averageAcknowledged: "ACKNOWLEDGING",
	averageFoodReady: "PREPERATION",
	averageDispatched: "DISPATCHING",
	averageCancelled: "CANCELLING"
};

export const FOOD_TYPE_MAP = {
	A_1: "Vegetarian",
	A_2: "Non-vegetarian",
	A_3: "Eggetarian",
	A_4: "NA",
	VEGETARIAN: "Vegetarian",
	NON_VEGETARIAN: "Non-vegetarian",
	EGGETARIAN: "Eggetarian",
	NA: "NA"
};

export const FOOD_TYPES = [
	{ label: "Vegetarian", value: "VEGETARIAN" },
	{ label: "Non-vegetarian", value: "NON_VEGETARIAN" },
	{ label: "Eggetarian", value: "EGGETARIAN" },
	{ label: "NA", value: "NA" }
];

export const LOCATION_ENTITY_ASSOCIATION_TYPES = [
	{ label: "Item", value: "ITEM", event: TRACKING_EVENT_NAMES.ITEM_LOCATION_UPDATE },
	{ label: "Modifier", value: "OPTION", event: TRACKING_EVENT_NAMES.MODIFIER_LOCATION_UPDATE },
	{ label: "Category", value: "CATEGORY" },
	{
		label: "Location group",
		value: "LOCATION_GROUP",
		event: TRACKING_EVENT_NAMES.TAXES_LOCATION_GROUP_ITEM_GROUP_UPDATE,
		changeInAssociatedEntity: true
	}
];

export const ITEM_ENTITY_ASSOCIATION_TYPES = [
	{ label: "Category", value: "CATEGORY", event: TRACKING_EVENT_NAMES.CATEGORY_ITEM_UPDATE },
	{ label: "Modifier group", value: "OPTION_GROUP", event: "item_modifier_group_association" },
	{ label: "Item group", value: "ITEM_GROUP", event: TRACKING_EVENT_NAMES.TAXES_LOCATION_GROUP_ITEM_GROUP_UPDATE },
	{
		label: "Recommended items",
		value: "RECOMMENDED_ITEMS",
		event: TRACKING_EVENT_NAMES.ITEM_RECOMMENDED_ITEMS_UPDATE,
		changeInAssociatedEntity: true
	},
	{
		label: "Item tag",
		value: "ITEM_TAGS",
		event: TRACKING_EVENT_NAMES.TAG_ENTITY_UPDATE,
		changeInAssociatedEntity: true
	},
	{ label: "Location", value: "LOCATION" }
];

export const MODIFIER_ENTITY_ASSOCIATION_TYPES = [
	{
		label: "Modifier group",
		value: "OPTION_GROUP",
		event: TRACKING_EVENT_NAMES.MODIFIER_GROUP_MODIFIER_UPDATE,
		changeInAssociatedEntity: true
	},
	{ label: "Item tag", value: "TAGS", event: TRACKING_EVENT_NAMES.TAG_ENTITY_UPDATE, changeInAssociatedEntity: true }
];

export const MODIFIER_GROUP_ENTITY_ASSOCIATION_TYPES = [
	{ label: "Item", value: "ITEM", event: TRACKING_EVENT_NAMES.ITEM_MODIFIER_GROUP_UPDATE },
	{ label: "Modifier", value: "OPTION", event: TRACKING_EVENT_NAMES.MODIFIER_GROUP_MODIFIER_UPDATE },
	{ label: "Modifier", value: "OPTION", event: TRACKING_EVENT_NAMES.MODIFIER_NESTED_MODIFIER_GROUP_UPDATE }
];

export const CATALOGUE_BACKUP_ENTITIES_LIST_HEADER_FIELDS = {
	categories: [
		{ label: "Name", value: "title" },
		{ label: "Description", value: "description" }
	],
	items: [
		{ label: "Name", value: "name" },
		{ label: "Category", value: "category" },
		// { label: 'Description', value: 'description' },
		{ label: "Price", value: "price" }
	],
	optionGroups: [
		{ label: "Name", value: "name" },
		{ label: "Type", value: "type" }
	],
	options: [
		{ label: "Name", value: "name" },
		{ label: "Price", value: "price" }
	],
	taxes: [
		{ label: "Name", value: "name" },
		{ label: "Applicable On", value: "applicable-on" }
		// { label: 'Description', value: 'description' },
	],
	charges: [
		{ label: "Name", value: "name" },
		{ label: "Applicable On", value: "applicable-on" }
		// { label: 'Description', value: 'description' },
	],
	itemGroups: [
		{ label: "Name", value: "name" },
		{ label: "Description", value: "description" }
	],
	locationGroups: [
		{ label: "Name", value: "name" },
		{ label: "Description", value: "description" }
	],
	timingGroups: [
		{ label: "Name", value: "name" },
		{ label: "Description", value: "description" }
	]
};

export const ILGP_ENTITY_ASSOCIATION_TYPES = [
	{ label: "Tax", value: "TAX", event: TRACKING_EVENT_NAMES.TAXES_LOCATION_GROUP_ITEM_GROUP_UPDATE },
	{ label: "Charge", value: "CHARGE", event: TRACKING_EVENT_NAMES.CHARGES_LOCATION_GROUP_ITEM_GROUP_UPDATE }
];

export const TAG_GROUP_ITEM_TAGS_LIST_HEADER_FIELDS = [
	{ label: "Title", value: "title" },
	{ label: "Associated Items", value: "num-items" },
	{ label: "Associated Modifiers", value: "num-modifiers" }
];

export const WEBHOOK_HISTORY_LIST_HEADER_FIELDS = [
	{ label: "Delivery ID", value: "delivery-id" },
	{ label: "Last Delivery", value: "last-delivery" },
	{ label: "Retries Count", value: "retries-count" },
	{ label: "Status Code", value: "status-code" }
];

export const ORDER_WEBHOOK_HISTORY_LIST_HEADER_FIELDS = [
	{ label: "Event", value: "event" },
	{ label: "Url", value: "url" },
	{ label: "Delivery", value: "delivery" },
	{ label: "Status Code", value: "status-code" }
];

export const ORDER_EXTERNAL_LOGS_LIST_HEADER_FIELDS = [
	{ label: "Purpose", value: "purpose" },
	{ label: "Url", value: "url" },
	{ label: "Status Code", value: "status-code" }
];

export const ATLAS_ROLES_MAP = {
	atlas_admin: "Admin",
	atlas_billing: "Billing",
	atlas_catalogue_management: "Catalogue Management",
	atlas_default: "Default",
	atlas_hub_management: "Hub Management",
	atlas_marketing: "Marketing",
	atlas_operations_analysis: "Operations Analysis",
	atlas_sales_analysis: "Sales Analysis",
	atlas_support: "Support",
	"group_user_satellite_Non-HQ Reports": "Non HQ Reports",
	"group_user_satellite_Order Taker (HQ User)": "Satellite Order Taker"
};

export const DATE_COMPARE_FILTER_PAGES_MAP = {
	"/": "Dashboard",
	"/orders": "Orders",
	"/customers": "Customers",
	"/feedback": "Feedback",
	"/summary": "Sales Analytics",
	"/rhi": "Operations Analytics",
	"/referrals": "Referrals",
	"/payout-sheets": "Reconciliation Payouts",
	"/reconciliation-stats": "Reconciliation Stats",
	"/activiy-history": "Activity History",
	"/analytics": "Analytics V2",
	"/reports": "Reports V2"
};

export const DEFAULT_LOCATION = {
	lat: 12.9698773,
	lng: 77.73185389999999
};

export const NESTED_ENTITY_TYPES = [
	"category",
	"item",
	"location",
	"tax",
	"charge",
	"modifier-group",
	"modifier",
	"item-group",
	"location-group",
	"user-management",
	"item-tag",
	"order",
	"customer",
	"piper-academy",
	"payout-help",
	"timing-group-create",
	"timing-group",
	"catalogue-backup",
	"unified-user-management",
	"menu-create",
	"catalogue-verification"
];

export const NOTIFICATIONS_ENTITY_MAP = {
	"catalogue-backup": { entity: "backups", name: "Backups", type: "preview", section: "Catalogue" },
	"create-root-location": { entity: "locations", type: "edit" },
	"request-to-go-live": { entity: "locations", name: "Request to Go Live" },
	"on-demand-menu-verification": { entity: "locations", name: "Catalogue Verification" },
	"consolidated-recon-report": { entity: "payout-sheets", name: "Payouts", section: "Reconciliation" },
	"enable-and-publish-stores": { entity: "locations", name: "Go live successful" },
	"menu-pull-status": { entity: "menu-pull-status", name: "Menu Pull Request" }
};

// Drag & drop in categories section
export const CATEGORY_TYPES = {
	CATEGORYLIST: "categoriesList",
	CATEGORYITEMS: "categoryitems"
};

// Drag & drop in menu reordering
export const MENU_REORDER_TYPES = {
	SECTIONSLIST: "sectionsList",
	MODIFIERGROUPLIST: "modifierGroupsList",
	MODIFIERLIST: "modifiersList"
};

// set of businesses with disabled ids and url while entering location aggregator links
export const businessBasedDisabledFields = {
	platformId: {
		fidoo: true
	},
	platformUrl: {
		amazon: true,
		dunzo: true,
		magicpin: true,
		radyes: true,
		noon: true,
		careem: true,
		chatfood: true,
		fidoo: true
	}
};

export const inspirationalQuotesWithAuthorName = [
	{
		quote: "If anything is good for pounding humility into you permanently, it's the restaurant business.",
		author: "Anthony Bourdain"
	},
	{
		quote: "Impossible is just an opinion, nothing else!",
		author: "Vikas Khanna"
	},
	{
		quote: "I didn't invent the hamburger. I just took it more seriously than anyone else.",
		author: "Ray Kroc"
	},
	{
		quote: "After a good dinner one can forgive anybody, even one's own relations.",
		author: "Oscar Wilde"
	},
	{
		quote: "A great introduction to cultures is their cuisine. It not only reflects their evolution, but also their beliefs and traditions.",
		author: "Vikas Khanna"
	},
	{
		quote: "I get all of my motivation from my customers.",
		author: "Salt Bae"
	},
	{
		quote: "McDonald's is a people business, and that smile on that counter girl's face when she takes your order is a vital part of our image.",
		author: "Ray Kroc"
	},
	{
		quote: "Look after the customers and the business will take care of itself.",
		author: "Ray Kroc"
	},
	{
		quote: "I don't run restaurants that are out of control. We are about establishing phenomenal footholdings with talent.",
		author: "Gordon Ramsay"
	},
	{
		quote: "There's a bond among a kitchen staff, I think. You spend more time with your chef in the kitchen than you do with your own family.",
		author: "Gordon Ramsay"
	},
	{
		quote: "I think pressure's healthy, and very few can handle it.",
		author: "Gordon Ramsay"
	},
	{
		quote: "Without experimentation, a willingness to ask questions and try new things, we shall surely become static, repetitive, and moribund.",
		author: "Anthony Bourdain"
	},
	{
		quote: "If anything is good for pounding humility into you permanently, it's the restaurant business.",
		author: "Anthony Bourdain"
	},
	{
		quote: "I profoundly believe that the power of food has a primal place in our homes, that binds us to the best bits of life.",
		author: "Jamie Oliver"
	},
	{
		quote: "The whole idea is to earn the flavor. No one gives it to you.",
		author: "Jamie Oliver"
	}
];

export const funnyQuotesWithAuthor = [
	{
		quote: "You used so much oil, the U.S. want to invade the f—ing plate.",
		author: "Gordon Ramsay"
	},
	{
		quote: "Why did the chicken cross the road? Because you didn't f—ing cook it!",
		author: "Gordon Ramsay"
	},
	{
		quote: "This crab is so undercooked I can still hear it singing 'Under the Sea!'",
		author: "Gordon Ramsay"
	},
	{
		quote: "Holy moly, Stromboli!",
		author: "Gordon Ramsay"
	},
	{
		quote: "This Isn't Pizza, This Is a Mistake!",
		author: "Gordon Ramsay"
	}
];

export const reconciliationEnabledBizIds = {
	15754143: true,
	10230563: true,
	82779277: true,
	76720224: true,
	71574355: true,
	68128109: true,
	38270423: true,
	43862247: true,
	75274549: true,
	50092414: true,
	98115663: true
};

export const countrySpecificPopularCities = {
	uk: [
		{
			value: "London",
			valueForDisplay: "London, London,United Kingdom"
		},
		{
			value: "Birmingham",
			valueForDisplay: "Birmingham, Birmingham, United Kingdom"
		},
		{
			value: "Glasgow",
			valueForDisplay: "Glasgow, Scotland, United Kingdom"
		},
		{
			value: "Liverpool",
			valueForDisplay: "Liverpool, England, United Kingdom"
		},
		{
			value: "Bristol",
			valueForDisplay: "Bristol, England, United Kingdom"
		},
		{
			value: "Manchester",
			valueForDisplay: "Manchester, England, United Kingdom"
		},
		{
			value: "Sheffield",
			valueForDisplay: "Sheffield, England, United Kingdom"
		},
		{
			value: "Leeds",
			valueForDisplay: "Leeds, England, United Kingdom"
		}
	],
	india: [
		{
			value: "Bengaluru",
			valueForDisplay: "Bengaluru, Karnataka, India"
		},
		{
			value: "Chennai",
			valueForDisplay: "Chennai, Tamil Nadu, India"
		},
		{
			value: "Hyderabad",
			valueForDisplay: "Hyderabad, Telangana, India"
		},
		{
			value: "Delhi",
			valueForDisplay: "Delhi, Delhi, India"
		},
		{
			value: "Mumbai",
			valueForDisplay: "Mumbai, Maharashtra, India"
		},
		{
			value: "Kolkata",
			valueForDisplay: "Kolkata, West Bengal, India"
		},
		{
			value: "Chandigarh",
			valueForDisplay: "Chandigarh, Chandigarh, India"
		},
		{
			value: "Kochi",
			valueForDisplay: "Kochi, Kerala, India"
		}
	]
};

export const ANALYTICS_SECTIONS = {
	revenue: {
		hidden: false,
		comingSoon: false,
		topic: "Revenue",
		roles: ["isSalesAnalysis", "isMarketing", "isNonHqAnalytics"],
		tabs: [
			{
				label: "Revenue Summary",
				value: "gross_revenue",
				description: "Track the total revenue over time, and the distribution over platforms & brands.",
				icon: "/assets/analytics/line.svg",
				link: "/analytics/revenue?metric=gross_revenue"
			},
			{
				label: "Revenue Breakdown",
				value: "revenue_breakdown",
				description: "View how your total revenue is divided between platforms or brands.",
				icon: "/assets/analytics/pie.svg",
				link: "/analytics/revenue?metric=revenue_breakdown"
			},
			{
				label: "Average Order Value",
				value: "avg_order_value",
				description: "View a comparison of your locations based on the revenue earned, etc.",
				icon: "/assets/analytics/area.svg",
				link: "/analytics/revenue?metric=avg_order_value"
			},
			{
				label: "Revenue by Location",
				value: "revenue_by_location",
				description: "View a comparison of your locations based on the revenue earned, etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/revenue?metric=revenue_by_location"
			},
			{
				label: "Revenue by Item",
				value: "revenue_by_item",
				description: "View a comparison of your items based on the revenue earned, etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/revenue?metric=revenue_by_item"
			},
			{
				label: "Lost Revenue",
				value: "lost_revenue",
				description: "Track the revenue you lost due to lost orders over time.",
				icon: "/assets/analytics/line.svg",
				link: "/analytics/revenue?metric=lost_revenue"
			},
			{
				label: "Lost Revenue Breakdown",
				value: "lost_revenue_breakdown",
				description: "Track the revenue you lost with respect to each platform or brand.",
				icon: "/assets/analytics/pie.svg",
				link: "/analytics/revenue?metric=lost_revenue_breakdown"
			}
		]
	},
	orders: {
		hidden: false,
		comingSoon: false,
		topic: "Orders",
		roles: ["isSalesAnalysis", "isMarketing", "isNonHqAnalytics"],
		tabs: [
			{
				label: "Orders Received",
				value: "orders_received",
				description: "Track thenumber of orders received over time.",
				icon: "/assets/analytics/line.svg",
				link: "/analytics/orders?metric=orders_received"
			},
			{
				label: "Order Performance",
				value: "order_performance",
				description: "Track the number of orders that failed and at what point in the flow they failed.",
				icon: "/assets/analytics/filter.svg",
				link: "/analytics/orders?metric=order_performance"
			},
			{
				label: "Orders Breakdown",
				value: "orders_breakdown",
				description: "View how your orders are divided between platforms or brands.",
				icon: "/assets/analytics/pie.svg",
				link: "/analytics/orders?metric=orders_breakdown"
			},
			{
				label: "Orders by Location",
				value: "orders_by_location",
				description: "View a comparison of your locations based on the number of orders, etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/orders?metric=orders_by_location"
			},
			{
				label: "Order Frequency",
				value: "order_frequency",
				description: "View a heatmap of when the most orders are received.",
				icon: "/assets/analytics/heatmap.svg",
				link: "/analytics/orders?metric=order_frequency"
			},
			{
				label: "Orders by Item",
				value: "orders_by_item",
				description: "View a comparison of your items based on the number of orders, etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/orders?metric=orders_by_item"
			},
			{
				label: "Lost Orders",
				value: "lost_orders",
				description: "Track the orders you lost due to cancellations, rejections & failures",
				icon: "/assets/analytics/line.svg",
				link: "/analytics/orders?metric=lost_orders"
			},
			{
				label: "Lost Orders Breakdown",
				value: "lost_orders_breakdown",
				description: "Track the orders you lost with respect to each platform or brand.",
				icon: "/assets/analytics/pie.svg",
				link: "/analytics/orders?metric=lost_orders_breakdown"
			}
		]
	},
	operations: {
		hidden: false,
		comingSoon: false,
		topic: "Operations",
		roles: ["isOperationsAnalysis", "isMarketing", "isNonHqAnalytics"],
		tabs: [
			{
				label: "Order Completion Time",
				value: "order_completion_time",
				description: "View how long it takes on an average for you to complete an order.",
				icon: "/assets/analytics/bar.svg",
				link: "/analytics/operations?metric=order_completion_time"
			},
			{
				label: "Order Completion Funnel",
				value: "order_completion_funnel",
				description: "Track your orders through different steps of completing an order.",
				icon: "/assets/analytics/filter.svg",
				link: "/analytics/operations?metric=order_completion_funnel"
			},
			{
				label: "Restaurant Availability",
				value: "restaurant_availability",
				description: "View a heatmap of the availability status of your locations.",
				icon: "/assets/analytics/heatmap.svg",
				link: "/analytics/operations?metric=restaurant_availability"
			},
			{
				label: "Lost Orders",
				value: "lost_orders",
				description: "Track the orders you lost due to cancellations, rejections & failures.",
				icon: "/assets/analytics/line.svg",
				link: "/analytics/operations?metric=lost_orders"
			},
			{
				label: "Lost Orders Breakdown",
				value: "lost_orders_breakdown",
				description: "Track the orders you lost with respect to each platform or brand.",
				icon: "/assets/analytics/pie.svg",
				link: "/analytics/operations?metric=lost_orders_breakdown"
			}
		]
	},
	catalogue: {
		hidden: false,
		comingSoon: false,
		topic: "Catalogue",
		roles: ["isSalesAnalysis", "isMarketing", "isNonHqAnalytics"],
		tabs: [
			{
				label: "Category Performance",
				value: "category_performance",
				description: "View a comparison of your categories based on the number of items, net revenue etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/catalogue?metric=category_performance"
			},
			{
				label: "Item Performance",
				value: "item_performance",
				description: "View a comparison of your items based on the number of orders, offline time etc.",
				icon: "/assets/analytics/table.svg",
				link: "/analytics/catalogue?metric=item_performance"
			}
		]
	},
	"item-performance": {
		hidden: true,
		comingSoon: false,
		topic: "Item Performance",
		tabs: [
			{
				label: "Revenue Trend",
				value: "revenue_trend"
			},
			{
				label: "Units Sold",
				value: "units_sold"
			},
			{
				label: "Order Frequency",
				value: "order_frequency"
			},
			{
				label: "Location Performance",
				value: "location_performance"
			},
			{
				label: "Popular Add-Ons",
				value: "popular_add_ons"
			},
			{
				label: "Offline Count",
				value: "offline_count"
			},
			{
				label: "Item Availability",
				value: "item_availability"
			},
			{
				label: "Lost Orders",
				value: "lost_orders"
			},
			{
				label: "Lost Orders Breakdown",
				value: "lost_orders_breakdown"
			}
		]
	}
};

export const DEVELOPER_TOOLS_ENABLED_PRODUCTION_BIZ_IDS = {
	68033049: true,
	90391823: true,
	41467469: true,
	76720224: true // this id is added for devs' testing purpose
};

export const FRESH_CHAT_ENABLED_BIZ_IDS = {
	// Hygiene big bite
	82779277: true,
	80659712: true,
	77166181: true,
	79237259: true,
	10652745: true,

	// Keventers
	86246120: true,
	94367433: true,

	// Baskin robbins
	46759187: true,

	// UBQ
	87111124: true,

	// Chef bakers - RT nagar
	29175964: true,

	// ITC hotels
	74130528: true,
	95807950: true,
	23743418: true,
	17140182: true,
	52758270: true,
	80700125: true,

	// Empire restaurant
	85903735: true,

	// Taco bell
	83824390: true,

	// Piper cafe - (For testing purposes)
	76720224: true,
	10252919: true,

	// Freshchat enabling - batch 1
	44007425: true,
	14632907: true,
	50077172: true,
	94766228: true,
	29009301: true,
	25651076: true,
	67288894: true,
	71574355: true,
	55997540: true,
	53102604: true,
	82913313: true,
	12219162: true,
	13678071: true,
	28185353: true,
	75965460: true,
	77290475: true,
	25950916: true,
	42439247: true,
	73574477: true,
	50802177: true,

	// Freshchat enabling - batch 2
	90754854: true,
	35085518: true,
	53369063: true,
	90718154: true,
	50092414: true,
	58154050: true,
	36432160: true,
	42540257: true,
	61815612: true,
	48826869: true,
	19976014: true,
	89033435: true,
	29039121: true,
	85553187: true,
	95728841: true,
	74962037: true,
	23909620: true,
	62531549: true,
	10000951: true,
	80944794: true,

	// Freshchat enabling - batch 3
	10829264: true,
	95686708: true,
	10230563: true,
	47056306: true,
	72364246: true,
	94248790: true,
	43863906: true,
	29672199: true,
	98714040: true,
	84348861: true,
	17960520: true,
	55378995: true,
	12762454: true,
	39931487: true,
	41142315: true,
	64212230: true,
	53669048: true,
	31935332: true,
	74406124: true,
	85772719: true,

	// Freshchat enabling - batch 4
	18486699: true,
	55449220: true,
	95221541: true,
	90131052: true,
	35949518: true,
	63656142: true,
	63877288: true,
	34214919: true,
	89538331: true,
	45630054: true,
	20922799: true,
	74921140: true,
	61357571: true,
	31326671: true,
	32737345: true,
	42551063: true,
	59194267: true,
	13593106: true,
	29850458: true,
	62700465: true
};

export const MENUS_PUBLISH_STATES = [
	{
		value: "",
		valueForDisplay: "--All--"
	},
	{
		value: "published",
		valueForDisplay: "Published"
	},
	{
		value: "publish_scheduled",
		valueForDisplay: "Publish Scheduled"
	},
	{
		value: "publish_failed",
		valueForDisplay: "Publish Failed"
	},
	{
		value: "draft_saved",
		valueForDisplay: "Draft Saved"
	},
	{
		value: "unpublished",
		valueForDisplay: "Not Published"
	}
];

export const FOODTYPE_MAPS = {
	VEGETARIAN: "veg",
	EGGETARIAN: "egg",
	NON_VEGETARIAN: "non-veg",
	NA: "not-applicable"
};

export const WELCOME_BANNER_DISPLAY_ID = "foodPrepTimeBanner";

export const MIN_PIPER_CREDITS_BALANCE = 0;
export const PIPER_CREDITS_BALANCE_LIMIT = -2000;

// onboarding
export const ONBOARDING_FLOWS = {
	ATLAS_ONBOARDING_FLOW: "atlas-onboarding"
};

export const ATLAS_ONBOARDING_STEPS = {
	COMPLETE_COMPANY_DETAILS: "COMPLETE_COMPANY_DETAILS",
	CREATE_BRANDS: "CREATE_BRANDS",
	CONFIGURE_LOCATIONS: "CONFIGURE_LOCATIONS",
	CONFIGURE_MENUS: "CONFIGURE_MENUS",
	TAXES_CHARGES: "TAXES_CHARGES",
	GO_LIVE_ON_LOCATIONS: "GO_LIVE_ON_LOCATIONS",
	INVITE_USERS: "INVITE_USERS"
};

export const ActionItems = [
	{
		label: "Offline Storefronts",
		imageUrlRed: "/assets/periscope/offline-strorefronts.svg",
		imageUrlGreen: "/assets/periscope/offline-strorefronts-green.svg",
		key: "offlineStorePercent",
		link: "/periscope/storefront-availability",
		infoText: "Percentage of storefronts currently unavailable for ordering"
	},
	{
		label: "Item availability across locations",
		imageUrlRed: "/assets/periscope/items-shortages.svg",
		imageUrlGreen: "/assets/periscope/items-shortages-green.svg",
		key: "offlineItemsPercent",
		link: "/periscope/item-availability",
		infoText: "Items marked as unavailable across one or more storefronts"
	},
	{
		label: "Items with missing details",
		imageUrlRed: "/assets/periscope/items-missing-details.svg",
		imageUrlGreen: "/assets/periscope/items-missing-details-green.svg",
		key: "missingItemPercent",
		link: "/periscope/item-issues",
		infoText: "Menu items lacking essential information like images or descriptions"
	}
];

export const MAX_LOCATIONS_LIMIT_FOR_PERISCOPE__ACCESS = 5;

export const periscopeVidTimeStamps = {
	"storefront-availability": { time: "01:51", type: "Storefront Availability" },
	"item-availability": { time: "02:33", type: "Item Availability" },
	"item-issues": { time: "03:51", type: "Item Issues" },
	"activity-log": { time: "04:27", type: "Activity Log" },
	onboarding: { time: "00:27", type: "" },
	introduction: "00:00",
	home: { time: "01:15", type: "Home" }
};

export const PERISCOPE_BIZ_IDS_UNRESTRICTED_LOCATION_SELECTION = {
	staging: {
		82308678: true,
		51881985: true
	},
	prod: {
		22869133: true,
		44007425: true,
		18480538: true,
		96931814: true,
		17543733: true,
		49199670: true,
		54719054: true,
		20073071: true,
		65424893: true
	}
};
