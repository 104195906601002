import React, { useCallback, useEffect, useRef, useState } from "react";
import { SearchFilter } from "../../_commons/SearchFilter";
import ChevronIcon from "../../_icons/ChevronIcon";
import { connect } from "react-redux";
import { extractInitials, trackEvent } from "../../../atlas-utils";
import { store } from "../../../store/configureStore";
import { metricContainer, metric as metricValues } from "../../../containers/Analytics/CompareTableWidgets";
import { fetchItemsDebounced, fetchLocationGroups, fetchLocationList } from "../../../actions/analytics";
import { Transition, config, animated } from "react-spring/renderprops";

const SelectInput = ({
	analyticsFiltersState,
	metric,
	compareAnalytics,
	setDropDownStatus,
	dropDownStatus,
	colIndex,
	dropDownMetric,
	configItems,
	setSearchedLocationGroups,
	setSearchedLocations,
	search,
	setSearch
}) => {
	const columnData = compareAnalytics?.columnData;
	const results = dropDownMetric?.[metric];
	const getSelectedValues = compareAnalytics?.columnData?.[metric].data || [];
	const columnValues = useCallback(() => {
		return getSelectedValues.length > 1
			? getSelectedValues.reduce((acc, obj, index) => {
					if (index == 0) {
						return acc;
					} else {
						acc.push(obj.value);
						return acc;
					}
				}, [])
			: "";
	}, [compareAnalytics?.columnData?.[metric].data]);

	const [data, setData] = useState({ ...results });
	useEffect(() => {
		setData(results);
	}, [results, metric]);

	const debounceRef = useRef(null);
	const fetchLocationGroup = async (searchText) => {
		try {
			setSearchedLocationGroups({ items: [], loading: true });
			const resp = await fetchLocationGroups(5, 0, searchText);
			if (resp && resp.data && resp.data.locationGroups && resp.data.locationGroups.objects) {
				setSearchedLocationGroups({
					items: resp.data.locationGroups.objects,
					loading: false
				});
			} else {
				setSearchedLocationGroups({ items: [], loading: false });
			}
		} catch (err) {
			setSearchedLocationGroups({ items: [], loading: false });
		}
	};
	const fetchDebounceLocationOptions = async (searchText) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}
		if (!searchText) {
			return clearTimeout(debounceRef.current);
		}
		debounceRef.current = setTimeout(async () => {
			fetchLocationGroup(searchText);
			try {
				setSearchedLocations({ items: [], loading: true });
				const resp = await fetchLocationList(searchText, 5, 0, false);
				if (resp.data.stores && resp.data.stores.objects) {
					setSearchedLocations({ items: resp.data.stores.objects, loading: false });
				} else {
					setSearchedLocations({ items: [], loading: false });
				}
			} catch (err) {
				setSearchedLocations({ items: [], loading: false });
			}
		}, 300);
		return () => {
			clearTimeout(debounceRef.current);
		};
	};

	const fetchDebouncedData = (text) => {
		if (metric === "location") {
			fetchDebounceLocationOptions(text);
		} else if (metric === "item") {
			fetchItemsDebounced(text);
		} else if (metric === "platform") {
			const allPlatforms = configItems?.bizPlatforms?.items || [];
			const newPlatforms = allPlatforms.reduce((acc, plt) => {
				if (plt?.platformName && plt?.platformName.toLowerCase().startsWith(text.trim().toLowerCase())) {
					acc.push(plt);
				}
				return acc;
			}, []);
			setData({ ...data, details: [{ ...data.details[0], info: newPlatforms }] });
		} else if (metric == "brand") {
			const allBrands = configItems?.brands?.items || [];
			const newBrands = allBrands.reduce((acc, brand) => {
				if (brand?.name && brand?.name.toLowerCase().startsWith(text.trim().toLowerCase())) {
					acc.push(brand);
				}
				return acc;
			}, []);
			setData({ ...data, details: [{ ...data.details[0], info: newBrands }] });
		}
	};

	const setFilter = (field, value) => {
		setSearch(value);
		fetchDebouncedData(value);
	};

	const scrollContainerRef = useRef(null);
	const count = columnData[metric].count;
	const breakdown = compareAnalytics.breakdown;

	const handleSelect = (info, value, valueForDisplay, currIndex, heading = "") => {
		if (count < 4 || currIndex <= count) {
			let checkMetricData = columnData?.[metric]?.data.filter((item, index) => {
				if (index == 0) {
					return;
				}
				return item.value == value;
			});
			if (checkMetricData.length == 0) {
				const data = metricValues.reduce((acc, val) => {
					if (metricContainer[val] && metricContainer[val][metric]) {
						acc[val] = { loading: false, error: false };
						if (breakdown[val]?.type == "breakdown") {
							acc[val] = { ...acc[val], objectLoading: false, objectError: false };
						}
					}
					return acc;
				}, {});
				store.dispatch({
					type: "UPDATE_COMPARE_ANALYTICS_TABLE_DATA",
					payload: { metric, name: valueForDisplay, data }
				});
				let variables = { value: value, valueForDisplay: valueForDisplay };
				if (metric == "platform") {
					let image = info.image || info.logo;
					variables = { ...variables, image };
				}
				if (metric == "brand") {
					let image = info.image;
					let color = info.color;
					variables = { ...variables, image, color };
				}
				if (heading) {
					variables = { ...variables, field: heading };
				}
				if (currIndex <= count) {
					let answer = [...columnData[metric]?.data];

					answer.splice(currIndex, 1, variables);
					let columnInfo = answer.reduce((acc, curr, index) => {
						if (index == 0) {
							return acc;
						} else {
							acc[curr?.["valueForDisplay"]] =
								compareAnalytics.tableData?.[metric]?.[curr?.["valueForDisplay"]];
						}
						return acc;
					}, {});

					store.dispatch({
						type: "UPDATE_COMPARE_ANALYTICS_CHANGE_COLUMN_DATA",
						payload: {
							replace: true,
							metric,
							data: answer
						}
					});
					store.dispatch({
						type: "DELETE_COMPARE_ANALYTICS_TABLE_DATA",
						payload: { metric, data: columnInfo }
					});
				} else {
					store.dispatch({
						type: "UPDATE_COMPARE_ANALYTICS_COLUMN_DATA",
						payload: {
							metric,
							data: [variables],
							count: count + 1
						}
					});
					let addedData = [...columnData[metric]?.data, variables];
					let columnInfo = addedData.reduce((acc, curr, index) => {
						if (index == 0) {
							return acc;
						} else {
							acc[curr?.["valueForDisplay"]] =
								compareAnalytics.tableData?.[metric]?.[curr?.["valueForDisplay"]];
						}
						return acc;
					}, {});
					store.dispatch({
						type: "DELETE_COMPARE_ANALYTICS_TABLE_DATA",
						payload: { metric, data: columnInfo }
					});
				}
				setDropDownStatus();
			}
		}
	};

	const placeHolderText = {
		location: "Search by Location Name or City",
		item: "Search by Item Name or Category",
		platform: "Search",
		brand: "Search"
	};
	const [isOpen, setIsOpen] = useState(dropDownStatus == colIndex);
	useEffect(() => {
		setIsOpen(dropDownStatus == colIndex);
	}, [dropDownStatus]);
	const isLocationOverlapping = analyticsFiltersState?.isLocationOverlapping;
	const headerVisibilityToggle = analyticsFiltersState?.headerVisibilityToggle;

	return (
		<>
			<Transition
				native
				items={isOpen}
				from={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
				enter={{ opacity: 1, transform: "perspective(800px) rotateX(0deg)", transformOrigin: "top" }}
				leave={{ opacity: 0, transform: "perspective(800px) rotateX(-90deg)", transformOrigin: "top" }}
				config={config.stiff}
			>
				{(isOpen) =>
					isOpen &&
					((props) => (
						<animated.div style={props} className={"dropdown-component " + "left"}>
							{
								<div
									ref={scrollContainerRef}
									className={`all-words  ${(data.details || []).length !== 1 ? "scroll-none" : ""} ${
										isLocationOverlapping && headerVisibilityToggle ? "header-visible" : ""
									}`}
								>
									{data?.searchText && (
										<div className="search-box">
											<SearchFilter
												filterOption={{ field: "search" }}
												value={search}
												setFilter={setFilter}
												placeholder={placeHolderText?.[metric]}
												autoFocus
											/>
										</div>
									)}

									{(data.details || []).map((val, index) => {
										const heading = val?.heading;
										const options = val?.info || [];
										const value = val?.value;
										const valueForDisplay = val?.valueForDisplay;
										const displayImages = val?.displayImages;
										const anotherValueDisplay = val?.anotherValueDisplay;
										const loading = val?.loading;
										const error = val?.error;
										const viewMoreBtn = val?.viewMoreBtn;
										const handleFetchViewOptions = val?.handleFetchViewOptions;
										const emptyInfoText = val?.emptyInfoText;
										return (
											<div className="group" key={index}>
												{heading && <div className="mini-header">{heading}</div>}
												{search == "" && !loading && !error && options.length == 0 && (
													<div className="empty-info">{emptyInfoText} </div>
												)}
												{search == "" && (loading || error) && options.length == 0 && (
													<div className="compare-loading option-loading">
														{Array.from({ length: 5 }).map((_, index) => {
															return (
																<div key={index} className="option-loader">
																	{(loading || error) && (
																		<div
																			className={`${
																				loading ? "shimmer" : "error"
																			} W${100}% H(${"20px"}) title`}
																		/>
																	)}
																</div>
															);
														})}
													</div>
												)}
												{options.length > 0 ? (
													<>
														<div className={`results scroll-none `}>
															{(options || []).map((word, indexVal) => {
																if (
																	word?.[value] == "all" ||
																	valueForDisplay == "All Brands"
																) {
																	return;
																}
																return (
																	<div
																		onClick={() =>
																			handleSelect(
																				word,
																				word?.[value],
																				word?.[valueForDisplay],
																				colIndex,
																				heading
																			)
																		}
																		className={`word-text ${
																			columnValues().includes(word?.[value])
																				? "selected-btn"
																				: ""
																		}`}
																		key={indexVal}
																	>
																		<div className="flex">
																			{displayImages && (
																				<span className="image">
																					{word?.["image"] ? (
																						<img
																							className="main-logo"
																							src={word?.["image"]}
																							style={{
																								width: "20px",
																								height: "20px"
																							}}
																						/>
																					) : (
																						<span
																							className={
																								"main-logo " +
																								word?.color
																							}
																						>
																							{extractInitials(
																								word?.[valueForDisplay]
																							)}
																						</span>
																					)}
																				</span>
																			)}
																			<span>{word?.[valueForDisplay]}</span>
																		</div>
																		<div className="side-head">
																			{word?.[anotherValueDisplay]}
																		</div>
																	</div>
																);
															})}
														</div>
													</>
												) : (
													<></>
												)}

												{search.length > 1 && options.length == 0 && !loading && (
													<div className="loading-viewmore"> No results found </div>
												)}
												{(options.length > 0 || search.length > 0) && loading && (
													<div className="loading-viewmore" style={{}}>
														Loading...
													</div>
												)}
												{search.length == 0 && viewMoreBtn && !loading && (
													<div
														className="view-more"
														onClick={() => handleFetchViewOptions(heading)}
													>
														<ChevronIcon
															styles={{ width: "16px", height: "16px" }}
															color="#3b5feb"
														/>
														View More
													</div>
												)}
											</div>
										);
									})}
								</div>
							}
						</animated.div>
					))
				}
			</Transition>
		</>
	);
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	login: state?.login,
	compareAnalytics: state?.compareAnalytics,
	analyticsFiltersState: state.analyticsFiltersState,
	configItems: state?.configItems
});

export default connect(mapStateToProps)(SelectInput);
