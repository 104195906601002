import React, { useEffect, useMemo, useState } from "react";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useTrail, config, animated } from "react-spring";

// utils
import { extractInitials, formatDate, lS, trackEvent, truncateText, isFeatureFlagEnabled } from "../atlas-utils";

// component
import { Button } from "../components/_commons/Button";
import CreateIcon from "../components/_commons/CreateIcon";
import { SelectFilter } from "../components/_commons/SelectFilter";
import { SearchFilter } from "../components/_commons/SearchFilter";
import { SelectFilterCustom } from "../components/_commons/SelectFilterCustom";
import ContextMenu from "../components/_commons/ContextMenu";
import Placeholder from "../components/_commons/Placeholder";
import MenuPublish from "../components/MenusList/MenuPublish";
import ArchiveRestoreModal from "../components/_commons/ArchiveRestoreModal";
import CancelScheduledPublishModal from "../components/MenuEdit/CancelScheduledPublishModal";
import MenuCreate from "./MenuCreate";
import { Paginator } from "../components/_commons/Paginator";
import Image from "../components/_commons/Image";
import ImportMenuDrawer from "../components/MenusList/ImportMenuDrawer";
import StatusBox from "../components/_commons/MenuStatusBox";
import Breadcrumbs from "../components/_commons/Breadcrumbs";

// actions
import { fetchMenusList, fetchMenusListDebounced, resetTestCart } from "../actions/menus";
import { fetchBrands, toggleGlobalLoader } from "../actions/actions";

// client
import { store } from "../store/configureStore";
import history from "../history";
import { clientMenu } from "../client-menu";
import { client } from "../client";

// types
import { ActionTypes } from "../actions/_types";

// services
import NotificationServices from "../services/NotificationService";

// graphql
import { DUPLICATE_MENU, GET_MENU_PULL_INFO } from "../graphql/menus";
import { ONBOARDING_FLOWS, TRACKING_EVENT_NAMES } from "../client-config";
import { getMenuPullInfo } from "../actions/menuPull";
import CommonOnboardingBanner from "../components/OnboardingCommon/CommonOnboardingBanner";
import PosMenuErrorDrawer from "../components/MenusList/PosMenuErrorDrawer";

// constants
const CONTEXT_MENU_INITIAL_STATE = {
	contextId: null
};

const ARCHIVE_MENU_INIT_STATE = {
	isOpen: false,
	menuId: null,
	locationsCount: 0
};

const MENU_BASIC_INFO_INIT_STATE = {
	isOpen: false,
	imageUrl: "",
	menuId: "",
	desc: ""
};

const PUBLISH_STATE_CLASS_MAP = {
	"validation failed": "failed",
	draft: "draft",
	"publish scheduled": "intermediate-state",
	"publish in progress": "intermediate-state",
	"partially published": "intermediate-state",
	published: "success",
	"publish failed": "failed",
	"partially failed": "failed"
};

const MenusList = ({
	brands,
	isMultibrandEnabled,
	menusListState,
	menusPullInfoState,
	biz,
	stores,
	loginDetail,
	flowData,
	onboardingFlow = false,
	handleCreateNewMenu,
	onCtaClick,
	menuTestCart
}) => {
	const [contextMenuData, setContextMenuData] = useState(CONTEXT_MENU_INITIAL_STATE);
	const { selectedBrand, menusListData, isLoading, isActive: is_active } = menusListState;

	const { ongoingMenuPull, canPullMenu, canPullLocationMenu, menuPullPOS, lastMenuPull } = menusPullInfoState;

	const [archiveMenuState, setArchiveMenuState] = useState(ARCHIVE_MENU_INIT_STATE);
	const [menuBasicInfoUpdate, setMenuBasicInfoUpdate] = useState(MENU_BASIC_INFO_INIT_STATE);
	const [publishStateFilter, setPublishStateFilter] = useState("");
	const [isActive, setIsActive] = useState(is_active);
	const [isMenuPublishOpen, setIsMenuPublishOpen] = useState(false);
	const [temporaryPersistedData, setTemporaryPersistedData] = useState();
	const [offset, setOffset] = useState(0);
	const [searchQuery, setSearchQuery] = useState("");
	const [isMenuDuplicationProcess, setIsMenuDuplicationProcess] = useState(false);
	const [cancelScheduledPublishModalState, setCancelScheduledPublishModalState] = useState({
		isOpen: false,
		menuId: null
	});
	const [isEditSchedulePublishFlow, setIsEditSchedulePublishFlow] = useState(false);

	const [isOpenMenuPullDrawer, setIsOpenMenuPullDrawer] = useState(false);
	const [isPosMenuPullErrorDrawer, setIsPosMenuPullErrorDrawer] = useState(false);

	const isInternalUser = !!loginDetail?.email?.includes("@urbanpiper.com");
	const isOnboardingFlowEnabled = flowData?.name === ONBOARDING_FLOWS.ATLAS_ONBOARDING_FLOW;

	const showHideMenuPublish = (isVisible, type = "instant", menuId = null, isEditSchedulePublish = false) => {
		setIsMenuPublishOpen(isVisible);
		setTemporaryPersistedData({
			type,
			menuId
		});
		setIsEditSchedulePublishFlow(isEditSchedulePublish);
	};

	const handleArchiveRestoreModal = (toOpen, refresh = false, data) => {
		if (toOpen) {
			setArchiveMenuState({
				isOpen: true,
				...data
			});
			return;
		}

		setArchiveMenuState(ARCHIVE_MENU_INIT_STATE);
		if (refresh) {
			fetchMenusList(brands?.selectedBrand?.id, isActive, publishStateFilter);
		}
	};

	const handleBasicInfoEditStates = (data = null, refresh = false) => {
		setMenuBasicInfoUpdate(data ? { ...data } : MENU_BASIC_INFO_INIT_STATE);
		if (refresh) {
			fetchMenusList(brands?.selectedBrand?.id);
		}

		if (data) {
			setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
		}
	};

	const duplicateMenu = async (menuId) => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
		store.dispatch(toggleGlobalLoader(true));
		setIsMenuDuplicationProcess(true);
		try {
			const variables = {
				copyMenuInput: {
					menuId
				}
			};

			const resp = await clientMenu.mutate({
				mutation: DUPLICATE_MENU,
				variables
			});

			if (resp?.data?.copyMenu?.menuId) {
				NotificationServices.pushNotification({
					message: "Successfully duplicated menu",
					timeout: 5000,
					type: "success",
					isClosable: true,
					theme: "dark"
				});
				setOffset(0);
				fetchMenusList(brands?.selectedBrand?.id, isActive, publishStateFilter);
				setIsMenuDuplicationProcess(false);
			} else {
				store.dispatch(toggleGlobalLoader(false));
				NotificationServices.pushNotification({
					message: "Failed to duplicate menu",
					timeout: 5000,
					type: "error",
					isClosable: true,
					theme: "dark"
				});
				setIsMenuDuplicationProcess(false);
			}
		} catch (e) {
			store.dispatch(toggleGlobalLoader(false));
			setIsMenuDuplicationProcess(false);
			console.log(e);
			NotificationServices.pushNotification({
				message: "Failed to duplicate menu",
				timeout: 5000,
				type: "error",
				isClosable: true,
				theme: "dark"
			});
		}
	};
	const renderCancelScheduledPublishModal = (menuId) => {
		setCancelScheduledPublishModalState({
			isOpen: true,
			menuId
		});
	};

	const renderMenuItems = (record) => {
		return (
			<React.Fragment>
				{record.isActive && (
					<>
						{record?.menuType !== "POS_INTEGRATED" && (
							<div
								className="action-item"
								onClick={() =>
									handleBasicInfoEditStates({
										isOpen: true,
										name: record?.menuTitle,
										imageUrl: "",
										menuId: record?.menuId,
										imageUrl: record?.imageUrl,
										desc: record?.desc,
										brandId: record?.brand
									})
								}
							>
								Edit Details
							</div>
						)}

						{record?.scheduledPublishDetails?.scheduledAt ? (
							<>
								<div
									className="action-item"
									onClick={() => showHideMenuPublish(true, "scheduled", record?.menuId, true)}
								>
									Edit Scheduled Details
								</div>
								{!isOnboardingFlowEnabled && (
									<div
										className="action-item"
										onClick={() => showHideMenuPublish(true, "instant", record?.menuId)}
									>
										Publish Now
									</div>
								)}
								{!isOnboardingFlowEnabled && (
									<div
										className="action-item"
										onClick={() => renderCancelScheduledPublishModal(record?.menuId)}
									>
										Cancel Scheduled Publish
									</div>
								)}
							</>
						) : !isOnboardingFlowEnabled ? (
							<>
								<div
									className="action-item"
									onClick={() => showHideMenuPublish(true, "instant", record?.menuId)}
								>
									Publish Now
								</div>
								<div
									className="action-item"
									onClick={() => showHideMenuPublish(true, "schedule", record?.menuId)}
								>
									Schedule Publish
								</div>
							</>
						) : null}

						<div className="action-item" onClick={() => duplicateMenu(record?.menuId)}>
							Duplicate
						</div>
					</>
				)}
				<div
					className={`action-item ${record.isActive ? "action-item--archive" : "action-item--restore"}`}
					onClick={() => handleArchiveRestoreModal(true, false, record)}
				>
					{record.isActive ? "Archive" : "Restore"}
				</div>
			</React.Fragment>
		);
	};

	const handleBrandsLabelOption = (brand) => {
		return (
			<React.Fragment>
				<div className={"logo " + brand.color}>
					{brand.image ? <img src={brand.image} alt="" /> : extractInitials(brand?.name?.split(" "))}
				</div>
				<div title={brand.name}>
					{brand.name && brand.name.length > 18 ? brand.name.slice(0, 18) + "..." : brand.name}
				</div>
			</React.Fragment>
		);
	};

	const handleClickOutsideContextMenu = () => {
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};

	const openContextMenu = (i) => {
		if (!contextMenuData?.contextId || contextMenuData?.contextId !== i) {
			setContextMenuData((current) => ({ ...current, contextId: i }));
			return;
		}
		setContextMenuData(CONTEXT_MENU_INITIAL_STATE);
	};

	const handleBrandSelection = (field, value) => {
		store.dispatch({
			type: ActionTypes.UPDATE_MENUS_LIST_DATA,
			payload: {
				key: "selectedBrand",
				value: value
			}
		});
		store.dispatch({
			type: ActionTypes.UPDATE_SELECTED_BRAND,
			payload: value
		});
	};

	const openMenuDetailsSection = (menuId) => {
		if (!menuId) {
			return;
		}
		history.push(`/menus/edit/${menuId}`);
	};

	const handleStateFilter = (field, value) => {
		if (field === "publishState") {
			setPublishStateFilter(value?.value);
		} else if (field === "brandState") {
			store.dispatch({
				type: ActionTypes.UPDATE_MENUS_LIST_DATA,
				payload: {
					key: "isActive",
					value: value?.value
				}
			});
			setIsActive(value?.value);
		}
	};

	const handlePagination = (page) => {
		setOffset((page - 1) * 10);
		fetchMenusList(selectedBrand?.id, isActive, publishStateFilter, (page - 1) * 10, "");
	};

	const handleMenusListSearch = (field, searchQuery) => {
		setSearchQuery(searchQuery);
		fetchMenusListDebounced(selectedBrand?.id, isActive, publishStateFilter, offset, searchQuery);
	};

	const fetchMenu = () => {
		/**
		 * Conditional fetching of menuList is to avoid multiple api calls which causes multiple state updates
		 * and hence issue with the loader state for menu
		 *
		 * Why is this debounced?
		 * Sometimes there are mutliple state updates for selectedBrand which results in multiple api calls
		 * Whichever api call is resolved last, that state is updated in the store `menuData`. The api calls with no menus will always get resolved first. So to avoid this
		 * We are debouncing the api call to avoid multiple api calls.
		 */
		if (isMultibrandEnabled) {
			if (selectedBrand?.id) {
				fetchMenusListDebounced(selectedBrand?.id, isActive, publishStateFilter, offset, searchQuery);
				trackEvent(TRACKING_EVENT_NAMES.MENU_LIST_VIEWS, {});
			}
		} else {
			fetchMenusListDebounced("", isActive, publishStateFilter, offset, searchQuery);
			trackEvent(TRACKING_EVENT_NAMES.MENU_LIST_VIEWS, {});
		}
	};

	useEffect(() => {
		fetchMenu();
	}, [isActive, publishStateFilter, offset, selectedBrand]);

	useEffect(() => {
		if (ongoingMenuPull?.status === "success") {
			fetchMenu();
		}
	}, [ongoingMenuPull]);
	const handleResetTestCart = async (cartId) => {
		await resetTestCart({ cartId });
	};

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrands("", true);
		}
		if (!onboardingFlow) {
			handleBrandSelection(
				"",
				isMultibrandEnabled
					? lS.get("brand")
						? lS.get("brand")
						: {
								id: "all",
								name: "All Brands",
								image: "/assets/icons/icon-brands.svg"
						  }
					: null
			);
		}

		if (biz?.eisEnabled) {
			getMenuPullInfo();
		}
		if (menuTestCart?.data?.cartId) {
			handleResetTestCart(menuTestCart?.data?.cartId);
		}
	}, []);
	const handleRetryPosImport = () => {
		setIsOpenMenuPullDrawer(true);
	};

	return (
		<div
			className={`${!onboardingFlow ? "section-container-common" : ""} menus-list-container ${
				isMenuDuplicationProcess || isLoading ? "disabled" : ""
			}`}
		>
			{onboardingFlow && (
				<Breadcrumbs
					connectedLinks={[
						{
							title: "Setup Menus",
							onClick: onCtaClick
						}
					]}
				/>
			)}
			<Header
				setIsOpenMenuPullDrawer={setIsOpenMenuPullDrawer}
				showImport={isInternalUser && canPullMenu}
				disableMenuPull={ongoingMenuPull?.status === "inprogress"}
				onboardingFlow={onboardingFlow}
				handleCreateNewMenu={handleCreateNewMenu}
			/>

			{ongoingMenuPull?.status && <StatusBox status={ongoingMenuPull?.status} />}

			{lastMenuPull?.status === "failed" && !ongoingMenuPull && (
				<StatusBox
					status={lastMenuPull?.status}
					menuInfo={lastMenuPull}
					handleDrawerOpen={() => setIsPosMenuPullErrorDrawer(true)}
					handleRetryPosImport={handleRetryPosImport}
				/>
			)}

			{isPosMenuPullErrorDrawer && (
				<PosMenuErrorDrawer
					isOpen={isPosMenuPullErrorDrawer}
					handleDrawerClose={() => setIsPosMenuPullErrorDrawer(false)}
					report={lastMenuPull?.report}
				/>
			)}

			{isOpenMenuPullDrawer && (
				<ImportMenuDrawer
					handleClose={() => setIsOpenMenuPullDrawer(false)}
					isOpen={isOpenMenuPullDrawer}
					isMultibrandEnabled={isMultibrandEnabled}
					brands={brands}
					handleBrandsLabelOption={handleBrandsLabelOption}
					pos={menuPullPOS}
					ongoingMenuPull={ongoingMenuPull}
					canPullLocationMenu={canPullLocationMenu}
				/>
			)}
			<Filters
				brands={brands}
				isMultibrandEnabled={isMultibrandEnabled}
				handleBrandsLabelOption={handleBrandsLabelOption}
				selectedBrand={selectedBrand}
				handleBrandSelection={handleBrandSelection}
				isActive={isActive}
				handleStateFilter={handleStateFilter}
				searchQuery={searchQuery}
				handleMenusListSearch={handleMenusListSearch}
				isLoading={isLoading}
				onboardingFlow={onboardingFlow}
			/>

			{isOnboardingFlowEnabled && onboardingFlow ? <CommonOnboardingBanner onCtaClick={onCtaClick} /> : null}

			<MenuInfo
				menuData={menusListData?.objects}
				contextMenuData={contextMenuData}
				renderMenuItems={renderMenuItems}
				handleClickOutsideContextMenu={handleClickOutsideContextMenu}
				openContextMenu={openContextMenu}
				isLoading={isLoading}
				selectedBrand={selectedBrand}
				openMenuDetailsSection={openMenuDetailsSection}
			/>
			<Paginator limit={10} offset={offset} count={menusListData?.totalObjectCount} goToPage={handlePagination} />
			<MenuPublish
				isOpen={isMenuPublishOpen}
				publishType={temporaryPersistedData?.type}
				showHideMenuPublish={showHideMenuPublish}
				menuId={temporaryPersistedData?.menuId}
				brandId={selectedBrand?.id}
				isFromMenusList
				isEditSchedulePublishFlow={isEditSchedulePublishFlow}
			/>
			<ArchiveRestoreModal
				isOpen={archiveMenuState?.isOpen && archiveMenuState?.menuId}
				close={(refresh) => handleArchiveRestoreModal(false, refresh)}
				entityName="Menu"
				dataObject={archiveMenuState}
				mode={archiveMenuState?.isActive ? "archive" : "restore"}
				entityType="menus"
				sourceOfTrigger="menus-list"
			/>
			<MenuCreate
				isEditMode
				menuEditData={menuBasicInfoUpdate}
				handleBasicInfoEditStates={handleBasicInfoEditStates}
			/>
			<CancelScheduledPublishModal
				isOpen={cancelScheduledPublishModalState?.isOpen}
				setCancelScheduledPublishModalState={setCancelScheduledPublishModalState}
				menuId={cancelScheduledPublishModalState?.menuId}
			/>
		</div>
	);
};

const mapStateToProps = (store) => ({
	brands: store.configItems.brands,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	menusListState: store.menusListState,
	menusPullInfoState: store.menusPullInfoState,
	biz: store.login.loggedInbizDetail,
	stores: store.configItems.stores,
	loginDetail: store.login.loginDetail,
	flowData: store.atlasOnboardingState.flowData,
	menuTestCart: store.menuTestCart
});
export default connect(mapStateToProps)(MenusList);

const Filters = ({
	handleStateFilter = () => {},
	isActive = false,
	searchQuery = "",
	isMultibrandEnabled,
	brands,
	handleBrandsLabelOption,
	selectedBrand,
	handleBrandSelection,
	handleMenusListSearch,
	onboardingFlow = false,
	isLoading
}) => (
	<div className={`menu-filters`}>
		<div className="brand-state-filters">
			{isMultibrandEnabled && (
				<>
					<SelectFilterCustom
						options={brands.items}
						isLoading={brands.isLoading}
						field="brands"
						currValue={selectedBrand}
						setFilter={handleBrandSelection}
						labelKey={"name"}
						valueKey={"id"}
						isSearchable={false}
						customLabel={true}
						customOptions={true}
						renderLabel={handleBrandsLabelOption}
						renderOptions={handleBrandsLabelOption}
						placeholder="Select brand"
						readOnly={onboardingFlow}
					/>
				</>
			)}
			{/* <SelectFilter
				options={MENUS_PUBLISH_STATES}
				field={'publishState'}
				labelKey="valueForDisplay"
				valueKey="value"
				setFilter={handleStateFilter}
				isSearchable={false}
				isClearable={false}
				placeholder='Publish Status'
			/> */}
			<SelectFilter
				options={[
					{ value: true, valueForDisplay: "Active" },
					{
						value: false,
						valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span>
					}
				]}
				field={"brandState"}
				currValue={
					isActive
						? { value: "active", valueForDisplay: "Active" }
						: {
								value: "archived",
								valueForDisplay: <span style={{ color: "#ff425c" }}>Archived</span>
						  }
				}
				labelKey="valueForDisplay"
				valueKey="value"
				setFilter={handleStateFilter}
				isSearchable={false}
				isClearable={false}
			/>
		</div>
		<SearchFilter
			placeholder="Search"
			filterOption={{
				field: "name"
			}}
			setFilter={handleMenusListSearch}
			value={searchQuery}
		/>
	</div>
);

const Header = ({
	setIsOpenMenuPullDrawer = () => {},
	showImport = false,
	disableMenuPull = true,
	onboardingFlow = false,
	handleCreateNewMenu
}) => (
	<div className="header-container">
		<div className="header-text">
			<div className="title">Menus</div>
			<div className="subtitle">View, configure and publish all your menus</div>
		</div>
		<div className="btn-wrapper">
			{showImport && (
				<Button
					type="secondary"
					onClick={() => setIsOpenMenuPullDrawer(true)}
					classes={disableMenuPull ? "disabled" : ""}
				>
					<span>Import Menu</span>
				</Button>
			)}
			{onboardingFlow ? (
				<Button clickHandler={handleCreateNewMenu}>
					<CreateIcon />
					<span>Create New</span>
				</Button>
			) : (
				<Link to="/menus/new">
					<Button>
						<CreateIcon />
						<span>Create New</span>
					</Button>
				</Link>
			)}
		</div>
	</div>
);

const MenuInfo = ({
	menuData = [],
	contextMenuData,
	renderMenuItems,
	handleClickOutsideContextMenu,
	openContextMenu,
	isLoading = false,
	selectedBrand,
	openMenuDetailsSection
}) => {
	const trails = useTrail(menuData.length, {
		config: config.stiff,
		from: {
			opacity: 0
		},
		opacity: 1
	});

	const handleMenuInfoContainerClick = (menuId) => {
		if (contextMenuData?.contextId) {
			return;
		}
		store.dispatch({
			type: ActionTypes.RESET_PARENT_SECTIONS_STATE
		});
		openMenuDetailsSection(menuId);
	};

	const placeholderContent = {
		placeholderText: "No menus added yet!",
		placeholderSubtext:
			"Create a carefully curated list of dishes and beverages offered by your business to display to your customers.",
		placeholderImageUrl: "/assets/empty_states/graphics-empty-catalogue.svg",
		placeholderButtonContent: (
			<>
				<CreateIcon />
				<span>Create Your First Menu</span>
			</>
		),
		placeholderButtonClickAction: () => {
			history.push("/menus/new");
		},
		// redirectionLink: "/piper-academy/categories",
		// redirectionLinkText: "Learn more about menus",
		size: "medium"
	};

	return isLoading ? (
		<div>
			<div className="shimmer-container">
				<div className="shimmer H(60px) Mb(10px)" />
				<div className="shimmer H(60px) Mb(10px)" />
			</div>
		</div>
	) : trails?.length ? (
		trails.map((props, i) => (
			<animated.div className={`menu-info`} style={props} key={i}>
				<div className="menu-info--content">
					<div className="img-container">
						<img
							className={menuData[i]?.imageUrl ? "menu-cover-img" : ""}
							src={menuData[i]?.imageUrl || "/assets/icons/icon-menu-placeholder.svg"}
						/>
						{menuData[i]?.metadata?.lockStatus === "ACTIVE" && (
							<div className="lock-status-indicator">
								<Image src="/assets/icons/icon-lock-white.svg" alt="menu-locked" />
							</div>
						)}
					</div>
					<div className="info">
						<div className="brand-name">{menuData[i]?.brandName}</div>
						<div
							className="menu-title"
							title={menuData[i]?.name}
							onClick={() => handleMenuInfoContainerClick(menuData[i].menuId)}
						>
							<span
								className={isLoading ? "" : "hyperlink hyperlink--black-color"}
								onClick={() => openMenuDetailsSection(menuData[i]?.menuId)}
							>
								{truncateText(menuData[i]?.name, 45)}
							</span>
							<span className="created-by">
								{menuData[i]?.createdBy ? `by ${menuData[i]?.createdBy}` : ""}
							</span>
						</div>
						<div className="basic-overview">
							<MenuBasicOverviewEntity
								iconSrc="/assets/icons/icon-fork-spoon-mini.svg"
								count={menuData[i]?.metadata?.itemCount}
								entityName="items"
							/>
							<MenuBasicOverviewEntity
								iconSrc="/assets/icons/icon-location-pin.svg"
								count={menuData[i]?.metadata?.locationCount}
								entityName="locations"
							/>
							<MenuBasicOverviewEntity
								iconSrc="/assets/icons/icon-platforms-global.svg"
								count={menuData[i]?.metadata?.platformCount}
								entityName="platforms"
							/>
						</div>
					</div>
				</div>
				<div className="menu-info--activities">
					<div className="last-update">Last updated on {formatDate(menuData[i]?.updatedAt)}</div>
					<div
						className={`publish-state ${
							PUBLISH_STATE_CLASS_MAP[menuData[i]?.metadata?.publishStatus?.toLowerCase()]
						}`}
					>
						{menuData[i]?.metadata?.publishStatus}
					</div>
					<ContextMenu
						isOpen={contextMenuData?.contextId === menuData[i]?.menuId}
						data={{
							menuId: menuData[i]?.menuId,
							menuTitle: menuData[i]?.name,
							locationCount: menuData[i]?.locationCount,
							imageUrl: menuData[i]?.imageUrl,
							desc: menuData[i]?.description,
							isActive: menuData[i]?.status !== "archived",
							brand: menuData[i]?.brand,
							scheduledPublishDetails: menuData[i]?.scheduledPublishDetails,
							menuType: menuData[i]?.metadata?.menuType
						}}
						renderMenuItems={renderMenuItems}
						handleOutsideClick={
							contextMenuData?.contextId === menuData[i]?.menuId
								? () => handleClickOutsideContextMenu()
								: () => {}
						}
						handleOpenMenu={(e) => {
							e.stopPropagation();
							openContextMenu(menuData[i]?.menuId);
						}}
					/>
				</div>
			</animated.div>
		))
	) : (
		<Placeholder {...placeholderContent} />
	);
};

const MenuBasicOverviewEntity = ({ iconSrc, count, entityName }) => (
	<div className="basic-overview--entity">
		<img className="icon" src={iconSrc} alt={entityName} />
		<div className="count">{count}</div>
		<div className="entity-name">{entityName}</div>
	</div>
);
