import React, { useState, useCallback, useEffect, useRef } from "react";

// component
import { Button } from "../_commons/Button";
import FormFilters from "../_commons/FormFilters";
import { FormSidebar } from "../_commons/FormSidebar";
import { Paginator } from "../_commons/Paginator";
import { CheckBox } from "../_commons/CheckBox";
import { SelectAllFields } from "../_commons/SelectAllFields";
import { SelectFilter } from "../_commons/SelectFilter";
import { InputWithLabel } from "../_commons/InputWithLabel";

// third party
import { useTrail, config, animated } from "react-spring";
import { debounce } from "lodash";
import SatismeterService from "../../services/SatismeterService";

// utils
import { client } from "../../client";
import { scroll, printCurrency, trackEvent } from "../../atlas-utils";
import { store } from "../../store/configureStore";

// graphql
import {
	CASCADE_ITEM_PRICE,
	CASCADE_MODIFIER_PRICE,
	GET_ITEM_LOCATIONS,
	GET_MODIFIER_LOCATIONS
} from "../../graphql/cascadeLocationsPrice";

// actions
import { ActionTypes } from "../../actions/_types";
import { fetchTags, fetchCities, toggleGlobalLoader } from "../../actions/actions";
import { connect } from "react-redux";

// constants
import { TRACKING_ENTITY, TRACKING_EVENT_NAMES, TRACKING_STATUS } from "../../client-config";
const FILTER_INITIAL_STATE = {
	name: "",
	city: undefined,
	tags: undefined
};
const LOCATION_INITIAL_DATA = {
	count: 0,
	objects: [],
	filters: []
};

const CASCADE_EQUIVALENT_FILTERS = {
	location__city: "city",
	location__biz_location_nickname: "name",
	tags: "tags"
};

const CascadeLocationsPrice = ({
	entityType,
	entityId,
	salesPrice,
	currencySymbol,
	isOpen,
	close,
	modifierPriceCascade = false,
	tags,
	cities
}) => {
	const [offset, setOffset] = useState(0);
	const [limit, setLimit] = useState(10);
	const [currFilters, setCurrFilters] = useState(FILTER_INITIAL_STATE);
	const [associatedCitiesList, setAssociatedCitiesList] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [locationData, setLocationData] = useState(LOCATION_INITIAL_DATA);
	const [isCheckedAll, setIsCheckedAll] = useState(false);
	const tableRef = useRef();
	const [locationsToCascadePrice, setLocationsToCascadePrice] = useState({});
	const [cascadeSuccess, setSuccess] = useState(false);
	const [allSelected, setAllSelected] = useState(false);
	const [appliedLocationsCount, setAppliedLocationCount] = useState(0);
	const totalChanges = Object.keys(locationsToCascadePrice).filter((loc) => locationsToCascadePrice[loc]).length;

	const fetchItemLocData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(entityId),
				limit,
				offset
			};
			// sidebar filters
			let filtersObject = [];

			if (appliedFilters?.tags) {
				filtersObject = [{ field: "tags", value: appliedFilters?.tags.id }];
			}

			Object.keys(appliedFilters).forEach((f) => {
				if (!appliedFilters[f]) {
					return;
				}
				if (typeof appliedFilters[f] === "object") {
					if (appliedFilters[f].value) {
						filtersObject.push({
							field: f,
							value: appliedFilters[f].value
						});
					}
				} else {
					filtersObject.push({
						field: f,
						value: appliedFilters[f]
					});
				}
			});

			if (filtersObject.length > 0) {
				variables.filters = filtersObject;
			}

			const resp = await client.query({
				query: GET_ITEM_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			let locationsData = resp.data.item.associatedItemLocations;
			setLocationData(locationsData);
			setAllSelected(false);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, entityType, limit, offset, appliedFilters]);

	const fetchModifierLocData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: parseInt(entityId),
				limit,
				offset
			};
			// sidebar filters
			if (appliedFilters.location__biz_location_nickname) {
				variables.name = appliedFilters.location__biz_location_nickname;
			}
			if (appliedFilters.location__city) {
				variables.city = appliedFilters?.location__city?.value;
			}

			const resp = await client.query({
				query: GET_MODIFIER_LOCATIONS,
				variables,
				fetchPolicy: "no-cache"
			});
			let locationsData = resp.data.modifier;
			if (associatedCitiesList.length === 0) {
				setAssociatedCitiesList([
					...(locationsData.associatedCities ?? []).map((value) => ({ value: value, valueForDisplay: value }))
				]);
			}
			setLocationData({
				objects: locationsData?.associatedLocations,
				count: locationsData?.numAssociatedLocations
			});
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [entityId, entityType, limit, offset, appliedFilters]);

	const applyFilter = useCallback(
		debounce((filters) => {
			setAppliedFilters(filters);
			setOffset(0);
		}, 500),
		[]
	);

	const setFilter = (field, value) => {
		let newFilters = {
			...currFilters,
			[field]: value
		};
		setCurrFilters(newFilters);
		applyFilter(newFilters);
	};

	const handleClose = useCallback(
		(refresh = false) => {
			// reset state before closing
			setLocationData(LOCATION_INITIAL_DATA);
			setCurrFilters(FILTER_INITIAL_STATE);
			setAppliedFilters(FILTER_INITIAL_STATE);
			setOffset(0);
			setLocationsToCascadePrice({});
			setIsCheckedAll(false);
			setSuccess(false);
			setAppliedLocationCount(0);
			setAllSelected(false);
			setAssociatedCitiesList([]);
			setFilter("location__biz_location_nickname", "");
			close(refresh);
		},
		[close]
	);

	const updateItemLocData = async () => {
		const eventMeta = {
			entity: TRACKING_ENTITY.ITEM
		};

		store.dispatch(toggleGlobalLoader(true));
		setLoading(true);
		try {
			let locationsToUpdate = [];
			if (!allSelected) {
				let cascadedLocations = Object.keys(locationsToCascadePrice).filter(
					(loc) => locationsToCascadePrice[loc]
				);
				if (cascadedLocations.length !== 0) {
					locationsToUpdate = cascadedLocations.map((locationId) => parseInt(locationId));
				}
			}
			// sidebar filters
			let filtersObject = [];

			if (allSelected) {
				if (appliedFilters?.tags) {
					filtersObject = [{ field: "tags", value: appliedFilters?.tags.id }];
				}

				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: CASCADE_EQUIVALENT_FILTERS[f] || f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: CASCADE_EQUIVALENT_FILTERS[f] || f,
							value: appliedFilters[f]
						});
					}
				});
			}

			const resp = await client.mutate({
				mutation: CASCADE_ITEM_PRICE,
				variables: {
					id: entityId,
					itemPrice: salesPrice,
					locationsToUpdate: locationsToUpdate,
					cascadeAll: allSelected,
					filters: filtersObject
				}
			});
			let success = resp?.data?.saveItem?.status?.success;
			if (success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);

				setSuccess(true);
				setAppliedLocationCount(locationsToUpdate.length);
				// satismeter event
				SatismeterService.menuPublish();
			} else {
				eventMeta.status = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);
			}
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: success
						? "Prices cascaded successfully"
						: resp?.data?.saveItem?.status?.messages[0].message
						? resp?.data?.saveItem?.status?.messages[0].message
						: "Failed to cascade!",
					timeout: 2000,
					error: !success
				}
			});
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.FAILURE;
			trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
		store.dispatch(toggleGlobalLoader(false));
	};

	const updateModifierLocData = async () => {
		const eventMeta = {
			entity: TRACKING_ENTITY.MODIFIER
		};

		store.dispatch(toggleGlobalLoader(true));
		setLoading(true);
		try {
			let locationsToUpdate = [];
			let cascadedLocations = Object.keys(locationsToCascadePrice).filter((loc) => locationsToCascadePrice[loc]);
			if (cascadedLocations.length !== 0) {
				locationsToUpdate = cascadedLocations.map((locationId) => parseInt(locationId));
			}
			let filtersObject = [];
			if (allSelected) {
				Object.keys(appliedFilters).forEach((f) => {
					if (!appliedFilters[f]) {
						return;
					}
					if (typeof appliedFilters[f] === "object") {
						if (appliedFilters[f].value) {
							filtersObject.push({
								field: CASCADE_EQUIVALENT_FILTERS[f] || f,
								value: appliedFilters[f].value
							});
						}
					} else {
						filtersObject.push({
							field: CASCADE_EQUIVALENT_FILTERS[f] || f,
							value: appliedFilters[f]
						});
					}
				});
			}
			const resp = await client.mutate({
				mutation: CASCADE_MODIFIER_PRICE,
				variables: {
					id: entityId,
					optionPrice: salesPrice,
					locationsToUpdate: allSelected ? [] : locationsToUpdate,
					filters: filtersObject,
					cascadeAll: allSelected
				}
			});
			let success = resp?.data?.saveModifier?.status?.success;
			if (success) {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);

				setSuccess(true);
				setAppliedLocationCount(locationsToUpdate.length);
				// satismeter event
				SatismeterService.menuPublish();
			} else {
				eventMeta.status = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);
			}
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: success ? "Prices cascaded successfully" : "Failed to cascade!",
					timeout: 2000,
					error: !success
				}
			});
		} catch (error) {
			eventMeta.status = TRACKING_STATUS.SUCCESS;
			trackEvent(TRACKING_EVENT_NAMES.CASCADES, eventMeta);

			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
		store.dispatch(toggleGlobalLoader(false));
	};

	const handleUpdate = () => {
		if (modifierPriceCascade) {
			updateModifierLocData();
		} else {
			updateItemLocData();
		}
	};

	const handlePagination = useCallback(
		(page) => {
			setOffset((page - 1) * limit);
			setIsCheckedAll(false);
			scroll({ top: tableRef.current.offsetTop - 57, left: 0 });
		},
		[limit]
	);

	const handleCheck = (bizLocationId, toAssociate) => {
		setLocationsToCascadePrice({
			...locationsToCascadePrice,
			[bizLocationId]: toAssociate
		});
		setIsCheckedAll(() => {
			if (!toAssociate) {
				return false;
			}
		});
	};

	const handleCheckAll = (toCheckAll) => {
		setIsCheckedAll(toCheckAll);
		let updates = {};
		let data = locationData.objects;
		data.forEach((locData) => {
			updates[locData.location.id] = toCheckAll;
		});
		setLocationsToCascadePrice({
			...locationsToCascadePrice,
			...updates
		});
	};

	const handleCompleteSelection = (selectAllLocations = false) => {
		setAllSelected(selectAllLocations);
		handleCheckAll(selectAllLocations);
	};

	useEffect(() => {
		if (!modifierPriceCascade) {
			fetchTags("");
			fetchCities();
		}
	}, []);

	useEffect(() => {
		if (isOpen) {
			if (modifierPriceCascade) {
				fetchModifierLocData();
			} else {
				fetchItemLocData();
			}
		}
	}, [fetchItemLocData, fetchModifierLocData, isOpen]);

	return (
		<div className="cascade-price-entity-container">
			<FormSidebar
				isOpen={isOpen}
				close={handleClose}
				submit={() => {}}
				title="Cascade Price"
				subTitle={
					<div>
						Update the price of this item to{" "}
						<strong>
							{printCurrency(currencySymbol)}
							{salesPrice}
						</strong>{" "}
						for selected locations
					</div>
				}
				hideSubmitAction={true}
				cancelTitle="Dismiss"
				loading={confirmLoading}
				hideActions={!cascadeSuccess}
				isNested={true}
				headerRight={
					!cascadeSuccess && (
						<Button clickHandler={handleUpdate} classes={totalChanges === 0 ? "disabled" : ""}>
							{totalChanges === 0
								? "Update Prices"
								: allSelected
								? `Update Prices for ${locationData?.count} Location(s)`
								: `Update Prices for ${totalChanges} Location(s)`}
						</Button>
					)
				}
			>
				{cascadeSuccess ? (
					<div className={"success-container"}>
						<div className={"success-message"}>
							Prices were <strong>updated</strong> successfully at{" "}
							<strong>{allSelected ? locationData?.count : appliedLocationsCount} location(s)</strong>.
						</div>
					</div>
				) : (
					<div className="form-content" ref={tableRef}>
						<div className="search-container">
							<InputWithLabel
								value={currFilters?.location__biz_location_nickname}
								onChange={(e) => setFilter("location__biz_location_nickname", e.target.value)}
								placeholder="Enter Name"
							>
								Name
							</InputWithLabel>
							<SelectFilter
								title="City"
								options={modifierPriceCascade ? associatedCitiesList : cities}
								setFilter={setFilter}
								labelKey="valueForDisplay"
								valueKey="value"
								placeholder="Select city"
								field="location__city"
								currValue={currFilters.location__city}
							/>
							{!modifierPriceCascade && (
								<SelectFilter
									title="Tags"
									options={tags}
									field="tags"
									labelKey="name"
									valueKey="id"
									placeholder="Select tags"
									setFilter={setFilter}
									currValue={currFilters.tags}
								/>
							)}
						</div>
						{isCheckedAll && locationData?.count > 10 && (
							<SelectAllFields
								allSelected={allSelected}
								mainMessage={
									<span>
										{allSelected ? "All " : ""}
										<strong>
											{allSelected ? locationData.count : locationData?.objects?.length}{" "}
										</strong>{" "}
										location(s){" "}
										{allSelected ? "on all pages are selected." : "on this page are selected."}
									</span>
								}
								linkMessage={
									allSelected ? (
										" Clear selection"
									) : (
										<span>
											{" "}
											Select <strong>{locationData.count}</strong> locations from all pages
										</span>
									)
								}
								handleCompleteSelection={handleCompleteSelection}
							/>
						)}
						<Table
							data={locationData.objects}
							loading={loading}
							handleCheck={handleCheck}
							locationUpdates={locationsToCascadePrice}
							isCheckedAll={isCheckedAll}
							handleCheckAll={handleCheckAll}
							currencySymbol={currencySymbol}
							modifierPriceCascade={modifierPriceCascade}
							allSelected={allSelected}
						/>
						<Paginator
							limit={limit}
							offset={offset}
							count={locationData?.count}
							goToPage={handlePagination}
							readOnly={allSelected}
						/>
					</div>
				)}
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	tags: store.configItems.tags.items,
	cities: store.configItems.cities.items
}))(CascadeLocationsPrice);

export const Table = ({
	data,
	loading,
	sortList,
	sortedField,
	handleCheck,
	isCheckedAll,
	handleCheckAll,
	locationUpdates,
	currencySymbol,
	modifierPriceCascade,
	allSelected = false
}) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && (loading || allSelected) ? "disabled" : "") +
				" transaction-table-holder common-table-container locations-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader
						sortList={sortList}
						sortedField={sortedField}
						isCheckedAll={isCheckedAll}
						handleCheckAll={handleCheckAll}
						modifierPriceCascade={modifierPriceCascade}
					/>
					{!loading &&
						trails.map(({ rotate }, i) => (
							<TableList
								key={data[i]?.location?.id}
								handleCheck={handleCheck}
								locationUpdates={locationUpdates}
								currencySymbol={currencySymbol}
								data={data[i]}
								modifierPriceCascade={modifierPriceCascade}
								style={{
									transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
								}}
								{...data[i]?.location}
							/>
						))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Locations found!</div>}
					{loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ isCheckedAll, handleCheckAll, modifierPriceCascade }) => (
	<div className={`at-table-row transaction-header-row`}>
		<div className={`at-table-cell at-table-header at-header-text name`}>
			<CheckBox checked={isCheckedAll} clickHandler={() => handleCheckAll(!isCheckedAll)} title="Name">
				Name
			</CheckBox>
		</div>
		<div className={`at-table-cell at-table-header at-header-text city`}>City</div>
		<div className={`at-table-cell at-table-header at-header-text price`}>
			{modifierPriceCascade ? "Price at Location" : "Meraki Price"}
		</div>
		{!modifierPriceCascade && <div className={`at-table-cell at-table-header at-header-text price`}>Hub Price</div>}
	</div>
);

export const TableList = ({
	id,
	name,
	city,
	tags,
	style,
	handleCheck,
	locationUpdates,
	currencySymbol,
	modifierPriceCascade,
	data
}) => {
	const status = locationUpdates[id] === undefined ? false : locationUpdates[id];
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			<div className="at-table-cell at-cell-text name" title={name}>
				<CheckBox checked={status} clickHandler={() => handleCheck(id, !status)} title={name || "--"}>
					{name || "--"}
				</CheckBox>
				<div className="tags">
					{tags?.length > 0 && (
						<div className="tags-container table-mode">
							<div className="list">
								{tags.map(
									(tag, i) =>
										i < 3 && (
											<span key={i} className="tag-item" title={tag}>
												{tag}
											</span>
										)
								)}
								{tags.length > 3 && (
									<span className="tag-item more-tags" title={tags.slice(3).join(", ")}>
										+{tags.length - 3} more
									</span>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
			<div className="at-table-cell at-cell-text city">{city || "--"}</div>
			<div className="at-table-cell at-cell-text price">
				{data?.price ? (
					<div>
						{printCurrency(currencySymbol)}
						{data.price}
					</div>
				) : (
					"--"
				)}
			</div>
			{!modifierPriceCascade && (
				<div className="at-table-cell at-cell-text price">
					{data?.externalPrice ? (
						<div>
							{printCurrency(currencySymbol)}
							{data.externalPrice}
						</div>
					) : (
						"--"
					)}
				</div>
			)}
		</animated.div>
	);
};
