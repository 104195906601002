import React from "react";

const GlobeIcon = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		style={{ ...styles }}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M10 1.875C6.62652 1.875 3.72744 3.94148 2.50061 6.875H2.5V6.87744C2.09809 7.8393 1.875 8.89405 1.875 10C1.875 11.1059 2.09809 12.1607 2.5 13.1226V13.125H2.50061C3.72744 16.0585 6.62652 18.125 10 18.125C13.3735 18.125 16.2726 16.0585 17.4994 13.125H17.5V13.1226C17.9019 12.1607 18.125 11.1059 18.125 10C18.125 8.89405 17.9019 7.8393 17.5 6.87744V6.875H17.4994C16.2726 3.94148 13.3735 1.875 10 1.875ZM10 3.125C10.9509 3.125 11.9766 4.57247 12.486 6.875H7.51404C8.02336 4.57247 9.0491 3.125 10 3.125ZM7.4231 3.62793C6.89508 4.47102 6.4839 5.58062 6.22925 6.875H3.87878C4.6292 5.41113 5.88897 4.25061 7.4231 3.62793ZM12.5769 3.62793C14.111 4.25061 15.3708 5.41113 16.1212 6.875H13.7708C13.5161 5.58062 13.1049 4.47102 12.5769 3.62793ZM3.38684 8.125H6.04065C5.97498 8.72421 5.9375 9.34932 5.9375 10C5.9375 10.6507 5.97498 11.2758 6.04065 11.875H3.38684C3.21748 11.2785 3.125 10.65 3.125 10C3.125 9.34999 3.21748 8.72147 3.38684 8.125ZM7.30225 8.125H12.6978C12.7697 8.71406 12.8125 9.33853 12.8125 10C12.8125 10.6615 12.7697 11.2859 12.6978 11.875H7.30225C7.23034 11.2859 7.1875 10.6615 7.1875 10C7.1875 9.33853 7.23034 8.71406 7.30225 8.125ZM13.9594 8.125H16.6132C16.7825 8.72147 16.875 9.34999 16.875 10C16.875 10.65 16.7825 11.2785 16.6132 11.875H13.9594C14.025 11.2758 14.0625 10.6507 14.0625 10C14.0625 9.34932 14.025 8.72421 13.9594 8.125ZM3.87878 13.125H6.22925C6.4839 14.4194 6.89508 15.529 7.4231 16.3721C5.88897 15.7494 4.6292 14.5889 3.87878 13.125ZM7.51404 13.125H12.486C11.9766 15.4275 10.9509 16.875 10 16.875C9.0491 16.875 8.02336 15.4275 7.51404 13.125ZM13.7708 13.125H16.1212C15.3708 14.5889 14.111 15.7494 12.5769 16.3721C13.1049 15.529 13.5161 14.4194 13.7708 13.125Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(GlobeIcon);
