import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import DropdownCustom from "./DropdownCustom";
import { CheckBox } from "./CheckBox";
import { extractInitials } from "../../atlas-utils";
export const SelectFilterCustomGroup = forwardRef(
	(
		{
			title,
			groupedOptions = [],
			classes = "",
			requiredLabel = false,
			isSearchable = true,
			isCheckedAll = {},
			isClearable = false,
			handleCustomSearchLabel,
			customSearchLabel = false,
			globalIconKey = "image",
			optionUpdates,
			handleCheck,
			handleSearch = () => {},
			handleSelectFilterState = () => {},
			globalLoading = false,
			renderCustomLabel = () => {},
			customLabel
		},
		ref
	) => {
		const [isOpen, setIsOpen] = useState(false);
		const [searchVal, setSearchVal] = useState("");
		const searchInputRef = useRef(null);

		useImperativeHandle(ref, () => ({
			handleChange: () => {
				setIsOpen(!isOpen);
			}
		}));

		useEffect(() => {
			handleSearch(searchVal);
		}, [searchVal]);
		useEffect(() => {
			const entities = groupedOptions.reduce((acc, curr) => {
				acc.push(curr?.["field"]);
				return acc;
			}, []);
			entities.map((entity) => {
				handleSelectFilterState(isOpen, entity);
			});
			if (!isOpen) {
				groupedOptions.forEach((option) => {
					if (option?.setOffsetToZero) {
						option.handleFetchViewOptions(option?.field, true);
					}
				});
			}
		}, [isOpen]);

		const [viewAllBtnState, setViewAllBtnState] = useState({});

		const viewAllOptions = (key) => {
			setViewAllBtnState({ ...viewAllBtnState, [key]: false });
		};

		useEffect(() => {
			if (isOpen) {
				const filterBtns = groupedOptions.reduce((acc, curr, index) => {
					const groupItem = curr?.["groupItem"];
					const viewAllBtn = curr?.["viewAllBtn"];
					if (groupItem && viewAllBtn) {
						acc[groupItem] = viewAllBtn;
					}
					return acc;
				}, {});
				setViewAllBtnState(filterBtns);
			}
		}, [isOpen]);
		const getDropdownLabel = () => {
			if (customLabel) {
				const options = groupedOptions.reduce((acc, obj) => {
					acc.push({ field: obj.field, countDisplayText: obj.countDisplayText });
					return acc;
				}, []);
				return renderCustomLabel(options);
			}
			let infoLabel = groupedOptions.reduce((acc, curr, index) => {
				let count = 0;

				if (curr?.field && optionUpdates?.[curr?.field]) {
					count = Object.entries(optionUpdates[curr?.field]).filter((obj) => {
						return obj[1];
					}).length;
				}
				if (count >= 0) {
					const groupItemText = `All ${curr?.countDisplayText || "option"}s`;
					const selectedCountText = `${count} ${curr?.countDisplayText || "option"}${count > 1 ? "s" : ""}`;
					if (count === 0) {
						acc = acc
							? `${acc}, ${groupItemText} ${index == groupedOptions.length - 1 ? "selected" : ""}`
							: groupItemText;
					} else {
						acc = acc
							? `${acc}, ${selectedCountText} ${index == groupedOptions.length - 1 ? "selected" : ""}`
							: selectedCountText;
					}
				}
				return acc;
			}, "");
			if (infoLabel) {
				return infoLabel;
			}
			return " - - - ";
		};
		const handleClickDropdown = () => {
			setIsOpen(!isOpen);
			// focus search input when dropdown is opened, if it's searchable
			if (!isOpen && isSearchable) {
				setTimeout(() => {
					searchInputRef.current.focus();
				}, 300);
			}
		};

		const outsideClickHandler = () => {
			setIsOpen(false);
			setSearchVal("");
		};
		const handleClearCurrValue = (e) => {
			e.stopPropagation();
		};

		const handleSelect = (f, opt, setFilter) => {
			setFilter(f, opt);
			outsideClickHandler();
		};

		const getCount = (field) => {
			const count =
				optionUpdates[field] && Object.values(optionUpdates[field])
					? Object.values(optionUpdates[field])?.filter((val) => val === true).length
					: 0;
			return count;
		};

		const isCheckBoxDisabled = (readOnly = false, isChecked = false, maxSelectable, field) => {
			const count =
				optionUpdates[field] && Object.values(optionUpdates[field])
					? Object.values(optionUpdates[field])?.filter((val) => val === true).length
					: 0;
			if (maxSelectable !== 0) {
				if (!isChecked && maxSelectable === count) {
					return true;
				} else {
					return false;
				}
			}
			return readOnly;
		};

		return (
			<div className={`select-filter-custom-group ${classes}`}>
				{title && <div className={"meta-info" + (requiredLabel ? " required-sign" : "")}>{title} </div>}
				<DropdownCustom
					selected={getDropdownLabel()}
					isOpen={isOpen}
					handleClick={handleClickDropdown}
					handleOutsideClick={outsideClickHandler}
					clickEvent="mousedown"
					isClearable={isClearable}
					clearCurrValue={handleClearCurrValue}
					classes={globalLoading ? "no-click" : ""}
					selectedValueInlineStyles={{
						fontSize: "14px",
						whiteSpace: "nowrap",
						overflow: "hidden",
						textOverflow: "ellipsis",
						display: "block",
						width: "100%"
					}}
				>
					<div className="dropdown-options">
						{isSearchable && (
							<div className="option search-input">
								<input
									ref={searchInputRef}
									onChange={(e) => setSearchVal(e.target.value)}
									placeholder="Type to search"
								/>
							</div>
						)}
						{groupedOptions.length > 0 &&
							groupedOptions.map((currobject, currObjIndex) => {
								const labelKey = currobject?.labelKey;
								const valueKey = currobject?.valueKey;
								const currValue = currobject?.currValue;
								const showSelectAllOption = currobject?.["showSelectAllOption"];
								const multi = currobject?.["multi"];
								const showCheckBox = currobject?.["showCheckBox"];
								const maxViewLimit = currobject?.["maxViewLimit"];
								const viewAllBtn = currobject?.["viewAllBtn"];
								const handleFetchViewOptions = currobject?.handleFetchViewOptions;
								const viewMore = currobject?.["viewMore"];
								const groupItem = currobject?.["groupItem"];
								const options =
									maxViewLimit && viewAllBtn && viewAllBtnState?.[groupItem]
										? currobject?.["options"].slice(0, maxViewLimit)
										: currobject?.["options"];
								const handleCheckAll = currobject?.["handleCheckAll"];
								const field = currobject?.["field"];
								const selectAllOptionKey = currobject?.["selectAllOptionKey"];
								const maxSelectable = currobject?.["maxSelectable"];
								const isSearchExternal = currobject?.["isSearchExternal"];
								const isLoading = currobject?.["isLoading"];
								const error = currobject?.["error"];
								const emptyInfoText = currobject?.["emptyInfoText"];
								const showOnlyCount = currobject.hasOwnProperty("showOnlyCount")
									? currobject?.["showOnlyCount"]
									: true;
								let count = getCount(field);
								return (
									<div key={currObjIndex} className="grouped-options">
										{maxSelectable &&
										count >= 0 &&
										(options.length > 0 || !isLoading || searchVal) ? (
											<div
												className={
													"max-selectable-message" +
													(count === maxSelectable ? " max-selection-reached-color" : "")
												}
												style={{ ...(currObjIndex != 0 ? { marginTop: "15px" } : {}) }}
											>
												{isCheckedAll[field] && showOnlyCount
													? `All selected`
													: `Selected ${count}/${maxSelectable} `}
											</div>
										) : null}
										<div className="">
											<div
												className={`group-name ${maxSelectable ? "name-below" : "name-on-top"}`}
											>
												{groupItem}
											</div>
											{!searchVal && options.length === 0 && !isLoading && !error && (
												<div className="empty-info">{emptyInfoText}</div>
											)}
											{showSelectAllOption &&
												multi &&
												showCheckBox &&
												searchVal === "" &&
												(options.length > 0 || !isLoading) && (
													<div className="option">
														<CheckBox
															checked={isCheckedAll[field]}
															clickHandler={() =>
																handleCheckAll(
																	!isCheckedAll[field],
																	field,
																	currobject,
																	{
																		labelKey,
																		valueKey
																	}
																)
															}
														>
															{selectAllOptionKey
																? selectAllOptionKey
																: groupItem
																	? `All ${groupItem}`
																	: ""}
														</CheckBox>
													</div>
												)}
											<div>
												{(options || []).length > 0 &&
													options
														.filter((opt) => {
															return searchVal !== "" && !isSearchExternal
																? (customSearchLabel
																		? handleCustomSearchLabel(opt, {
																				labelKey,
																				valueKey
																			})
																		: opt?.[labelKey]
																	)
																		?.toLowerCase()
																		.includes(searchVal.toLowerCase())
																: true;
														})
														.map((opt, optIndex) => {
															const showIcon = currobject?.["showIcon"];
															const customOptions = currobject?.["customOptions"];
															const renderOptions = currobject?.["renderOptions"];
															const ellipsizedLength = currobject?.["ellipsizedLength"];
															const setFilter = currobject?.["setFilter"];
															const iconKey = currobject?.["iconKey"];

															return (
																<div
																	key={optIndex}
																	className={
																		"option " +
																		(!multi
																			? opt?.[labelKey] === currValue?.[labelKey]
																				? "selected"
																				: ""
																			: "")
																	}
																	onClick={
																		!showCheckBox
																			? () => handleSelect(field, opt, setFilter)
																			: (e) => e.stopPropagation()
																	}
																>
																	{!showCheckBox && showIcon ? (
																		opt?.icon ||
																		opt?.[iconKey] ||
																		opt?.[globalIconKey] ? (
																			<div className="option-icon">
																				<img
																					src={
																						opt.icon ||
																						opt[iconKey] ||
																						opt?.[globalIconKey]
																					}
																					alt=""
																				/>
																			</div>
																		) : (
																			<div
																				className={
																					"initials " +
																					(opt.color ? opt.color : "")
																				}
																			>
																				{extractInitials(
																					opt[labelKey]?.split(" ")
																				)}
																			</div>
																		)
																	) : null}
																	{customOptions && (
																		<div className="custom-option">
																			{renderOptions(
																				opt,
																				field,
																				handleCheck,
																				optionUpdates,
																				{
																					labelKey,
																					valueKey
																				}
																			)}
																		</div>
																	)}
																	{!customOptions && !showCheckBox && (
																		<div>
																			{opt[labelKey] &&
																			opt[labelKey].length > ellipsizedLength
																				? opt[labelKey].slice(
																						0,
																						ellipsizedLength
																					) + "..."
																				: opt[labelKey]}
																		</div>
																	)}

																	{!customOptions && showCheckBox && multi && (
																		<>
																			<CheckBox
																				checked={
																					optionUpdates[field] &&
																					optionUpdates[field][opt[valueKey]]
																						? true
																						: false
																				}
																				clickHandler={() =>
																					handleCheck(
																						optionUpdates[field] &&
																							optionUpdates[field][
																								opt[valueKey]
																							]
																							? false
																							: true,
																						field,
																						opt,
																						{ labelKey, valueKey }
																					)
																				}
																				readOnly={isCheckBoxDisabled(
																					opt?.readOnly,
																					optionUpdates[field] &&
																						optionUpdates[field][
																							opt[valueKey]
																						]
																						? true
																						: false,
																					maxSelectable,
																					field
																				)}
																				title={opt[labelKey]}
																			>
																				{showIcon ? (
																					opt?.["icon"] ||
																					opt?.[iconKey] ||
																					opt?.[globalIconKey] ? (
																						<div className="option-icon">
																							<img
																								src={
																									opt?.["icon"] ||
																									opt?.[iconKey] ||
																									opt?.[globalIconKey]
																								}
																								alt=""
																							/>
																						</div>
																					) : (
																						<div
																							className={
																								"initials " +
																								(opt.color
																									? opt.color
																									: "")
																							}
																						>
																							{extractInitials(
																								opt[labelKey]?.split(
																									" "
																								)
																							)}
																						</div>
																					)
																				) : null}
																				{opt[labelKey] &&
																				opt[labelKey].length > ellipsizedLength
																					? opt[labelKey].slice(
																							0,
																							ellipsizedLength
																						) + "..."
																					: opt[labelKey]}
																			</CheckBox>
																		</>
																	)}
																</div>
															);
														})}
												{options?.filter((opt) =>
													searchVal !== "" && !isSearchExternal
														? (customSearchLabel
																? handleCustomSearchLabel(opt, { labelKey, valueKey })
																: opt?.[labelKey]
															)
																?.toLowerCase()
																.includes(searchVal.toLowerCase())
														: true
												).length === 0 &&
													(isLoading || searchVal) && (
														<div className="option no-hover">
															{isLoading
																? "Loading..."
																: searchVal
																	? "No results found"
																	: ""}
														</div>
													)}
												{((options.length > 0 &&
													searchVal == "" &&
													viewMore &&
													isLoading &&
													isSearchExternal) ||
													(options.length > 0 && isLoading && isSearchExternal)) && (
													<div className="option no-hover">Loading...</div>
												)}
											</div>
										</div>
										{viewMore && !isLoading && searchVal == "" && (
											<div
												className="view-all-btn"
												style={{ cursor: "pointer" }}
												onClick={() => {
													handleFetchViewOptions(field, false, false);
												}}
											>
												View More
											</div>
										)}
									</div>
								);
							})}
					</div>
				</DropdownCustom>
			</div>
		);
	}
);
