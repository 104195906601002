import React, { useState, useRef } from "react";

// components
import { BackNavigation } from "../_commons/BackNavigation";
import BulkOperation from "../_commons/BulkOperation";
import { BulkOperationSuccess } from "../_commons/BulkOperationSuccess";
import { BulkOperationFailure } from "../_commons/BulkOperationFailure";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { downloadTemplate, handleBulkExportDownload } from "../../actions/actions";

// utils
import history from "../../history";
import { lS, scroll } from "../../atlas-utils";

// constatnt
const locationsData = [
	{
		fieldName: "Address",
		description: "Location street address",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "City",
		description: "City name",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Contact Phone",
		description: "A number to dial-in to this location",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Enabled for Ordering",
		description: "Indicates whether the location accepts online orders",
		isRequired: false,
		reqdDesc: "Default is 1",
		acceptedValues: ["Boolean", "0 - disabled", "1 - enabled"]
	},
	{
		fieldName: "Handle",
		description: "Unique identifier for the location (If left blank it will be auto generated)",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Hide Name for UI",
		description: "Indicates whether this location can be displayed in the website or apps",
		isRequired: false,
		reqdDesc: "Default is 0",
		acceptedValues: ["Boolean", "0 - No", "1 - Yes"]
	},
	{
		fieldName: "Is Enabled",
		description: "Indicates whether the location is active",
		isRequired: false,
		reqdDesc: "Default is 1",
		acceptedValues: ["Boolean", "0 - No", "1 - Yes"]
	},
	{
		fieldName: "Latitude",
		description: "The geo co-ordinate latitude for this location",
		isRequired: false,
		acceptedValues: "Decimal Number"
	},
	{
		fieldName: "Longitude",
		description: "The geo co-ordinate longitude for this location",
		isRequired: false,
		acceptedValues: "Decimal Number"
	},
	{
		fieldName: "Max Delivery Radius",
		description: "The geo radius (in metres) within which online orders for this location will be accepted",
		isRequired: false,
		acceptedValues: "Positive Integer"
	},
	{
		fieldName: "Min Order Delivery Time",
		description: "The minimum time (in seconds) to deliver an online order placed at this location",
		isRequired: false,
		acceptedValues: "Positive Integer"
	},
	{
		fieldName: "Min Order Pickup Time",
		description: "The minimum time (in seconds) to prepare an online order meant to be picked up by the customer",
		isRequired: false,
		acceptedValues: "Positive Integer"
	},
	{
		fieldName: "Min Order Total",
		description: "The minimum value of an online order to be accepted at this location",
		isRequired: false,
		acceptedValues: "Positive Integer"
	},
	{
		fieldName: "Name",
		description: "Name of the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Notification Email",
		description:
			"Email alerts for this location will be sent to these mail IDs. Multiple IDs should be comma-separated",
		isRequired: false,
		acceptedValues: ["Alphanumeric characters", "Valid email"]
	},
	{
		fieldName: "Notification Phone",
		description:
			"Phone numbers to which location alerts will be sent as SMS. Numbers should include ISD code and comma-separated for multiple numbers",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Ordering Temporary Disabled",
		description: "Indicates that this location is temporarily not accepting online orders",
		isRequired: false,
		reqdDesc: "Default is 0",
		acceptedValues: ["Boolean", "0 - location is enabled", "1 - location is disabled"]
	},
	{
		fieldName: "Ref ID",
		description:
			"A reference ID for the location. This can be an identifier for the location in your ERP/PoS system",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Sort Order",
		description: "The order of display precedence when this location appears in a list with other locations",
		isRequired: false,
		reqdDesc: "Default is 0",
		acceptedValues: "Positive Integer"
	},
	{
		fieldName: "Excluded Platforms",
		description: "Platforms on which the location is to be excluded",
		isRequired: false,
		acceptedValues: ["Comma separated values of platform names", "Swiggy,Zomato,Ubereats"]
	}
];
const lpaData = [
	{
		fieldName: "Platform ID",
		description: "Generated by Aggregator for the merchant",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Platform Location URL",
		description: "URL of your location on aggregators platform",
		isRequired: false,
		acceptedValues: "A valid URL"
	},
	{
		fieldName: "Platform-Location Handle",
		description: "Unique identifier for the location-platform association",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "State",
		description: "Not live/ Enabled/ Disabled/ Churned",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Not live", "1 - Enabled", "2 - Disabled", "3 - Churned"]
	},
	{
		fieldName: "Location Handle",
		description: "Unique identifier for the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Location Name",
		description: "Name of the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Platform",
		description: "Name of the platform",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "UPIPR Store ID",
		description: "Unique Id for the location",
		isRequired: true,
		acceptedValues: "Numeric characters"
	}
];

const FORM_TABS = [
	{
		label: "Locations",
		value: "locations"
	},
	{
		label: "Location-Platform associations",
		value: "lpa"
	}
];

const BulkLocations = (props) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [downloading, setDownloading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [csvFile, setCsvFile] = useState(undefined);
	const [uploadedFileName, setUploadedFileName] = useState(undefined);
	const [validation, setValidation] = useState(undefined);
	const [bulkOpState, setBulkOpState] = useState("default");
	const [headerFields, setHeaderFields] = useState(undefined);
	const [failureResponse, setFailureResponse] = useState(undefined);
	const [failureData, setFailureData] = useState(undefined);
	const [successData, setSuccessData] = useState(undefined);
	const [downloadAction, setDownloadAction] = useState(false);
	const topRef = useRef();
	const bottomRef = useRef();

	const currLocation = {
		pathname: props?.location?.pathname,
		title: "Bulk actions on locations"
	};
	const prevLocation = {
		pathname: "/locations",
		title: "Locations"
	};

	const handleBackAction = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
	};

	const setCurrentTab = (tab) => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleUploadNewFile = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");

		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleSaveChanges = () => {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Changes saved successfuly",
				timeout: 2000,
				error: false
			}
		});
		setTimeout(() => {
			history.push("/locations");
		}, 100);
	};

	const csvToJson = (data) => {
		let sanitisedData = data
			.split("\n")
			.map((row) => row.trim())
			.filter((row) => row !== "")
			.map((row) => row.split(","));
		const headers = sanitisedData[0].map((header) => header.toLowerCase().split(" ").join("_"));

		const fields = {};
		headers.map((header) => {
			fields[header] = true;
		});
		setHeaderFields(fields);

		const tableData = [];
		if (headers.includes("invalid_headers")) {
			let obj = {
				invalid_headers: sanitisedData[1].join(", ")
			};
			tableData.push(obj);
		} else {
			sanitisedData.map((row, i) => {
				if (i !== 0) {
					let obj = {};
					headers.map((column, j) => {
						obj[column] = row[j];
					});
					tableData.push(obj);
				}
			});
		}
		setFailureData(tableData);
	};

	const auth = lS.get("auth");

	const exportTemplate = async (emails, entity) => {
		setDownloading(true);
		const modelName = formTab === "lpa" ? `locationplatformassociation` : `store`;
		await handleBulkExportDownload(modelName, emails, entity);
		setDownloading(false);
	};

	const handleTemplateDownload = async () => {
		setDownloading(true);
		const modelName = formTab === "lpa" ? `locationplatformassociation` : `store`;
		await downloadTemplate(modelName);
		setDownloading(false);
	};

	const downloadErrorFile = () => {
		setDownloading(true);
		const file = new Blob([failureResponse], { type: "text/csv" });

		// process to auto download it
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download = formTab === "lpa" ? `Location-Platform-Associations-Errors.csv` : `Locations-Errors.csv`;
		link.click();
		setDownloading(false);
	};

	const handleUploadFile = async (e) => {
		setUploading(true);
		setValidation(undefined);

		if (!csvFile) {
			var files = await e.target.files;
			setCsvFile(files[0]);
			setUploadedFileName(files[0].name);
			var formData = new FormData();
			formData.append("model-name", formTab === "lpa" ? "locationplatformassociation" : "store");
			formData.append("sheet", files[0]);
		} else {
			var formData = new FormData();
			formData.append("model-name", formTab === "lpa" ? "locationplatformassociation" : "store");
			formData.append("sheet", csvFile);
		}

		try {
			const res = await fetch(process.env.REACT_APP_API_URL_CONFIG_SHEET, {
				method: "POST",
				headers: { Authorization: auth && `Bearer ${auth.token}` },
				body: formData
			});
			const contentType = res.headers.get("content-type");
			const status = res.status;

			if (contentType === "text/csv" && status === 200) {
				const data = await res.text();
				setFailureResponse(data);
				csvToJson(data);
				setDownloadAction(false);
				setBulkOpState("failure");

				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please correct the errors and try again",
						timeout: 2000,
						error: true
					}
				});
			} else if (contentType === "application/json") {
				const data = await res.json();
				if (status === 200) {
					const tableData = [{ message: data.message }];
					setSuccessData(tableData);
					setBulkOpState("success");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: data.message,
							timeout: 2000,
							error: false
						}
					});
				} else if (status === 400) {
					const fields = { reason: true };
					setHeaderFields(fields);

					const tableData = [{ reason: data.message }];
					setFailureData(tableData);

					setDownloadAction(true);
					setBulkOpState("failure");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please correct the errors and try again",
							timeout: 2000,
							error: true
						}
					});
				}
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
		setUploading(false);
	};

	return (
		<div>
			{bulkOpState === "default" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperation
							title="Bulk actions on locations"
							subTitle="Create or update your locations and location platforms associations in bulk"
							downloadTitle="Download Template"
							downloadTemplate={handleTemplateDownload}
							exportTitle={formTab === "lpa" ? "Location-Platform Associations" : "Locations"}
							exportTemplate={exportTemplate}
							data={formTab === "lpa" ? lpaData : locationsData}
							showTopBar={true}
							tabs={FORM_TABS}
							selectedTab={formTab}
							setCurrentTab={setCurrentTab}
							downloading={downloading}
							uploading={uploading}
							handleUploadFile={handleUploadFile}
							uploadedFileName={uploadedFileName}
							validation={validation}
							prevLocation={prevLocation}
							currLocation={currLocation}
						/>
					</div>
					<div ref={bottomRef}></div>
				</React.Fragment>
			)}
			{bulkOpState === "success" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationSuccess
							title="Bulk actions on locations"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							uploadedFileName={uploadedFileName}
							saveChanges={handleSaveChanges}
							data={successData || []}
						/>
					</div>
				</React.Fragment>
			)}
			{bulkOpState === "failure" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationFailure
							title="Bulk actions on locations"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							downloading={downloading}
							hideDownloadAction={downloadAction}
							uploadedFileName={uploadedFileName}
							downloadErrorFile={downloadErrorFile}
							uploadNewFile={handleUploadNewFile}
							headerFields={headerFields || {}}
							data={failureData || []}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
export default BulkLocations;
