import React from "react";
import Star from "../_icons/Star";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "../_commons/Button";
import { trackEvent } from "../../atlas-utils";
import Popover from "../_commons/Popover";
import { connect } from "react-redux";

const SchedulesIntro = ({ access }) => {
	const isAdmin = access?.isAdmin;
	const history = useHistory();
	const handleStart = () => {
		history.push("/reports/list/schedules/confirm-free-trial");
		trackEvent("schedule_reports_free_trial_interest_click", { triggered_from: "landing_page" });
	};

	const factors = [
		{
			img: "/assets/analytics/reports-banner/clock.svg",
			title: "Save manual effort",
			desc: "Reduce repetitive and free up time for more strategic work."
		},
		{
			img: "/assets/analytics/reports-banner/line.svg",
			title: "Consistency that delivers",
			desc: "Ensure timely delivery of any report—daily, weekly, or monthly—to keep everyone aligned."
		},
		{
			img: "/assets/analytics/reports-banner/group-task.svg",
			title: "Improved decision making",
			desc: "Access critical insights in your preferred format (CSV/XLSX) for faster and informed decision-making."
		},
		{
			img: "/assets/analytics/reports-banner/collab.svg",
			title: "Collaborative Synergy",
			desc: "Scheduled reports effortlessly for multiple stakeholders to drive alignment across teams."
		}
	];

	const renderPopover = (desc) => {
		return <div className="description">{desc}</div>;
	};
	return (
		<div className="schedule-report-intro">
			<div className="schedule-reports-banner">
				<div className="banner-text">
					<div className="banner-head">
						<span className="automate"> Automate </span> your workflow with scheduled reports{" "}
						<span>
							<img src="/assets/analytics/reports-banner/violet-star.svg" className="v-star" />
						</span>
					</div>
					<div className="description">
						Set up scheduled reports to automatically deliver key insights when you need them. This keeps
						you informed and saves you the hassle of creating reports manually.
					</div>
					<div className="trial-plan">
						{isAdmin ? (
							<Button clickHandler={handleStart} classes={"free-trail-btn"}>
								Start free trial
							</Button>
						) : (
							<Popover
								data={"Contact your admin for access"}
								showOnHover={true}
								renderPopover={renderPopover}
								position={"middle-right"}
							>
								<Button classes={"free-trail-btn hover-btn"}>Start free trial</Button>
							</Popover>
						)}

						<div className="free-trial">
							<Star variations={{ height: "14.5px", width: "15px", fill: "#E5B917" }} />
							<span className="text">Free trial for select members</span>
						</div>
					</div>
				</div>
				<div className="banner-img">
					<img src="/assets/analytics/reports-banner/plane-path.svg" className="plane-path" />

					<img
						src="/assets/analytics/reports-banner/ellipse-bottom-right.svg"
						className="ellipse-bottom-right"
					/>

					<img src="/assets/analytics/reports-banner/ellipse-top-left.svg" className="ellipse-top-left" />
					<img src="/assets/analytics/reports-banner/plane.svg" className="plane" />

					<img src="/assets/analytics/reports-banner/lines-pattern.svg" className="lines-pattern" />
					<div className="first-div" style={{ position: "relative" }}>
						<div className="top-customer">
							<div className="two-sparkles">
								<img src="/assets/analytics/reports-banner/two-sparkles.svg" className="two-sparkles" />
							</div>
							<div className="customer-summary" style={{}}>
								<div className="summary">Customer-Wise Summary</div>
								<div className="sales">Sales</div>
								<div className="customer">
									<div className="contact">sales@company.com</div>
									<div className="contact">operations@example.com</div>
									<div className="contact">+ 2 more</div>
								</div>
								<div className="green-box" style={{ position: "absolute" }}>
									Active
								</div>
							</div>
						</div>

						<div className="mobile-calendar">
							<img className="mobile-img" src="/assets/analytics/reports-banner/mobile.svg" />

							<div className="calendar-schedule">
								<span className="calendar">
									<img src="/assets/analytics/reports-banner/calendar.svg" />
								</span>
								Scheduled for everyday
							</div>
						</div>
					</div>
					<div className="second-div">
						<div className="schedule-details">
							<div className="detail">Schedule Details</div>
							<div className="sessions">
								<div className="repeat">
									<div className="repeat-every">Repeat Every</div>
									<div className="box g-5">
										<div>
											<span className="month">Month</span>
											<span className="date"> (Every 1st) </span>
										</div>
										<ButtonIcon icon="dropdown-arrow" color="#D0D0D0" classes="dropdown-icon" />
									</div>
								</div>
								<div className="clock">
									<div className="repeat-every">At</div>
									<div className="box">
										<span className="time">12</span>
										<span className="time">
											<ButtonIcon icon="dropdown-arrow" color="#D0D0D0" classes="dropdown-icon" />
										</span>
									</div>
								</div>
								<div className="clock">
									<div className="box">
										<span className="time">00</span>
										<span className="time">
											<ButtonIcon icon="dropdown-arrow" color="#D0D0D0" classes="dropdown-icon" />
										</span>
									</div>
								</div>
								<div className="clock">
									<div className="box">
										<span className="time">PM</span>
										<span className="time">
											<ButtonIcon icon="dropdown-arrow" color="#D0D0D0" classes="dropdown-icon" />
										</span>
									</div>
								</div>
							</div>
						</div>

						<div className="reports">
							<div className="s-sparkle">
								<img src="/assets/analytics/reports-banner/single-sparkle.svg" />
							</div>
							<div className="urbanpiper ">
								<div className="c-info">
									<div className="text">Your report is ready, Click on report to view</div>
									<div className="justify-between">
										<div className="comp-info">
											<div className="comp">
												<img src="/assets/icons/platform-urbanpiper.svg" />
												<div className="info">
													<div className="name">UrbanPiper</div>
													<div className="desc"> to: me and 3 others</div>
												</div>
											</div>
											<div className="support">
												{"<"} support{"@"}urbanpiper{"."}com {">"}
											</div>
										</div>
										<div className="time">Tue, 8 Oct, 10:00</div>
									</div>
								</div>
								<div className="all-boxes">
									{Array.from({ length: 4 }).map((_, index) => {
										return (
											<div className={`boxes ${index === 0 ? "zero" : ""}`} key={index}>
												<div
													className={"box1 flex-center " + (index === 0 ? "index-zero" : "")}
												>
													<div className={"box" + (index === 0 ? " index-zero" : "")}></div>
												</div>
												<div
													className={"box2 flex-center " + (index === 0 ? "index-zero" : "")}
												>
													<div className={"box" + (index === 0 ? " index-zero" : "")}></div>
												</div>
												<div
													className={"box3 flex-center " + (index === 0 ? "index-zero" : "")}
												>
													<div className={"box" + (index === 0 ? " index-zero" : "")}></div>
												</div>
												<div
													className={"box4 flex-center " + (index === 0 ? "index-zero" : "")}
												>
													<div className={"box" + (index === 0 ? " index-zero" : "")}></div>
												</div>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="factors">
				{factors.map((factor) => {
					return (
						<div className="factor">
							<div className="factor-img">
								<img src={factor.img} />
							</div>
							<div className="title"> {factor.title}</div> <div className="desc"> {factor.desc}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

const mapStateToProps = (store) => ({
	access: store.login.loginDetail.access
});
export default connect(mapStateToProps)(SchedulesIntro);
