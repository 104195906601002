import React, { useState, useCallback, useEffect } from "react";

// component
import { InputWithLabel } from "../_commons/InputWithLabel";
import { Textarea } from "../_commons/Textarea";
import { Button } from "../_commons/Button";
import { Loading } from "../_commons/Loading";

// utils
import { msaagesArrayToHtml, trackEvent } from "../../atlas-utils";
import { client } from "../../client";
import { store } from "../../store/configureStore";

// graphql
import { GET_ITEM_CUSTOM_FIELDS, UPDATE_ITEM_CUSTOM_FIELDS } from "../../graphql/items";

// actions
import { ActionTypes } from "../../actions/_types";
import { TRACKING_EVENT_NAMES, TRACKING_SOURCE, TRACKING_STATUS } from "../../client-config";
import { Modal } from "../_commons/Modal";

const CustomFields = ({ itemId, readOnly = true }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [newGroups, setNewGroups] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			setLoading(true);
			const variables = {
				id: itemId
			};
			const resp = await client.query({
				query: GET_ITEM_CUSTOM_FIELDS,
				variables,
				fetchPolicy: "no-cache"
			});
			setData(resp.data.item.keyValuesGroups);
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	}, [itemId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const addGroup = () => {
		const updatedGroups = newGroups.slice();
		updatedGroups.push({});
		setNewGroups(updatedGroups);
	};

	const updateData = (data) => {
		setNewGroups([]);
		setData(data);
	};

	const deleteNewGroup = (index) => {
		const updatedNewGroups = newGroups.filter((g, i) => i !== index);
		setNewGroups(updatedNewGroups);
	};

	const removeGroup = (id) => {
		const updatedData = data.filter((grp) => grp.id !== id);
		setData(updatedData);
	};

	return (
		<div className={"item-custom-fields-container" + (loading ? " no-click" : "")}>
			<div className="related-entities-header-container">
				<div>
					<div className="header-text">Add new Field Group</div>
					<div className="header-subtext">Create a new Field Group and update info based on the item</div>
				</div>
				<div>
					{!readOnly && (
						<div>
							<Button clickHandler={addGroup}>Add Field Group</Button>
						</div>
					)}
				</div>
			</div>
			<div>
				{newGroups.map((grp, i) => (
					<FieldGroup
						key={i}
						itemId={itemId}
						updateData={updateData}
						readOnly={readOnly}
						{...grp}
						removeGroup={() => deleteNewGroup(i)}
					/>
				))}
				{data.map((grp, i) => (
					<FieldGroup
						key={i}
						itemId={itemId}
						updateData={updateData}
						readOnly={readOnly}
						removeGroup={removeGroup}
						{...grp}
					/>
				))}
			</div>
			<div>
				{data.length === 0 && !loading && newGroups.length === 0 && (
					<div className="no-items-placeholder">No custom fields found for this item!</div>
				)}
				{data.length === 0 && loading && (
					<div className="P(10px 0)">
						<div className="shimmer H(60px) Mb(10px)" />
						<div className="shimmer H(60px) Mb(10px)" />
					</div>
				)}
			</div>
		</div>
	);
};
export default CustomFields;

const FieldGroup = ({ itemId, id, name = "", keyValues = [], updateData, removeGroup, readOnly }) => {
	const [isFormTouched, setFormTouched] = useState(false);
	const [loading, setLoading] = useState(false);
	const [newPairs, setNewPairs] = useState(keyValues.length > 0 ? [] : [{}]);
	const [data, setData] = useState({
		id,
		name,
		keyValues
	});
	const [editPairs, setEditPairs] = useState({});
	const [isGroupDeleteModalOpen, setGroupDeleteModalOpen] = useState(false);

	useEffect(() => {
		if (id) {
			setData({
				id,
				name,
				keyValues
			});
		}
	}, [keyValues.length, id]);

	const handleTitle = (e) => {
		setData({
			...data,
			name: e.target.value
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleKVPair = (index, field, value) => {
		let object;
		const newKV = data.keyValues.map((kv, i) => {
			if (i === index) {
				kv[field] = value;
				object = kv;
			}
			return kv;
		});
		setEditPairs({
			...editPairs,
			[object.id]: object
		});
		setData({
			...data,
			keyValues: newKV
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleNewKVPair = (index, field, value) => {
		const updatedNewPairs = newPairs.map((kv, i) => {
			if (i === index) {
				kv[field] = value;
			}
			return kv;
		});
		setNewPairs(updatedNewPairs);
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const addKVPair = () => {
		const updateNewPairs = newPairs.slice();
		updateNewPairs.push({ key: "", value: "" });
		setNewPairs(updateNewPairs);
	};

	const deleteKVPair = async (id) => {
		setLoading(true);
		try {
			const variables = {
				id: itemId,
				keyValuesToInsert: [],
				keyValuesToEdit: [],
				keyValuesToDelete: {
					keyValueIds: [parseInt(id)]
				}
			};
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_CUSTOM_FIELDS,
				variables
			});
			if (resp.data.saveItem.status.success) {
				const filterdKVs = data.keyValues.filter((kv) => kv.id !== id);
				setData({
					...data,
					keyValues: filterdKVs
				});
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.saveItem.status.messages),
						timeout: 3000,
						error: true
					}
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const deleteNewKVPair = (index) => {
		const updatedNewPairs = newPairs.filter((kv, i) => i !== index);
		setNewPairs(updatedNewPairs);
	};

	const deleteGroup = async (id) => {
		setLoading(true);
		try {
			const variables = {
				id: itemId,
				keyValuesToInsert: [],
				keyValuesToEdit: [],
				keyValuesToDelete: {
					keyValueGroupId: id,
					keyValueIds: []
				}
			};
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_CUSTOM_FIELDS,
				variables
			});
			if (resp.data.saveItem.status.success) {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `"${data.name}" group deleted!`,
						timeout: 2000,
						error: false
					}
				});
				removeGroup(id);
				setGroupDeleteModalOpen(false);
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.saveItem.status.messages),
						timeout: 3000,
						error: true
					}
				});
			}
		} catch (error) {
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	const saveFields = async () => {
		setLoading(true);

		try {
			const variables = {
				id: itemId,
				keyValuesToInsert: [],
				keyValuesToEdit: [],
				keyValuesToDelete: {}
			};
			const keyValuePairs = newPairs.map((kvPair) => {
				if (kvPair["key"] === undefined) {
					kvPair["key"] = "";
				}
				if (kvPair["value"] === undefined) {
					kvPair["value"] = "";
				}
				return kvPair;
			});
			// handle keyValuesToInsert
			if (keyValuePairs.length > 0) {
				variables.keyValuesToInsert = [
					{
						name: data.name,
						keyValues: keyValuePairs
					}
				];
				if (id) {
					variables.keyValuesToInsert[0].id = id;
				}
			}
			// handle keyValuesToEdit
			if (id) {
				variables.keyValuesToEdit = [
					{
						id,
						name: data.name,
						keyValues: Object.keys(editPairs).map((id) => ({
							id: editPairs[id].id,
							key: editPairs[id].key,
							value: editPairs[id].value
						}))
					}
				];
			}
			const resp = await client.mutate({
				mutation: UPDATE_ITEM_CUSTOM_FIELDS,
				variables
			});
			if (resp.data.saveItem.status.success) {
				trackEvent(TRACKING_EVENT_NAMES.CUSTOM_FIELDS_UPDATE, {
					source: TRACKING_SOURCE.ITEMS,
					status: TRACKING_STATUS.SUCCESS
				});

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: `"${data.name}" group saved!`,
						timeout: 2000,
						error: false
					}
				});
				updateData(resp.data.saveItem.object.keyValuesGroups);
				setNewPairs([]);
				setFormTouched(false);
			} else {
				trackEvent(TRACKING_EVENT_NAMES.CUSTOM_FIELDS_UPDATE, {
					source: TRACKING_SOURCE.ITEMS,
					status: TRACKING_STATUS.FAILURE
				});

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: msaagesArrayToHtml(resp.data.saveItem.status.messages),
						timeout: 3000,
						error: true
					}
				});
			}
		} catch (error) {
			trackEvent(TRACKING_EVENT_NAMES.CUSTOM_FIELDS_UPDATE, {
				source: TRACKING_SOURCE.ITEMS,
				status: TRACKING_STATUS.FAILURE
			});

			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
		}
		setLoading(false);
	};

	return (
		<div className="custom-field-group">
			<div className="group-title">
				<div className="title">Group Title</div>
				<InputWithLabel
					value={data.name}
					onChange={handleTitle}
					classes="key-input"
					readOnly={readOnly}
				></InputWithLabel>
				{!readOnly && (
					<img
						className="delete-icon"
						title="Delete Group"
						onClick={() => {
							if (id) {
								setGroupDeleteModalOpen(true);
							} else {
								removeGroup();
							}
						}}
						src="/assets/icons/delete.svg"
					/>
				)}
			</div>
			{isGroupDeleteModalOpen && (
				<Modal
					isOpen={isGroupDeleteModalOpen}
					title={"Delete Group ? "}
					showCancelAction
					showDeleteAction
					deleteAction={() => deleteGroup(data.id)}
					cancelAction={() => setGroupDeleteModalOpen(false)}
					close={() => setGroupDeleteModalOpen(false)}
				>
					<p className="delete-grp-content">
						This action will delete all nutritional information for other items in this group. Are you sure
						you want to proceed?
					</p>
				</Modal>
			)}
			{data.keyValues.map((kv, i) => (
				<div key={i} className="key-value-pair">
					<div className="header">
						<InputWithLabel
							value={kv.key}
							onChange={(e) => handleKVPair(i, "key", e.target.value)}
							classes={!readOnly && data.keyValues.length > 1 ? "key-input" : "key-input--wide"}
							readOnly={readOnly}
						>
							Name
						</InputWithLabel>
						<InputWithLabel
							value={kv.value}
							onChange={(e) => handleKVPair(i, "value", e.target.value)}
							classes={!readOnly && data.keyValues.length > 1 ? "key-input" : "key-input--wide"}
							readOnly={readOnly}
						>
							Value
						</InputWithLabel>
						{!readOnly && data.keyValues.length > 1 && (
							<img
								className="delete-icon"
								title="Delete Field"
								onClick={() => deleteKVPair(kv.id)}
								src="/assets/icons/delete.svg"
							/>
						)}
					</div>
				</div>
			))}
			{newPairs.map((kv, i) => (
				<div key={i} className="key-value-pair">
					<div className="header">
						<InputWithLabel
							value={kv.key}
							onChange={(e) => handleNewKVPair(i, "key", e.target.value)}
							classes={
								!readOnly && (id ? newPairs.length > 0 : newPairs.length > 1)
									? "key-input"
									: "key-input--wide"
							}
							readOnly={readOnly}
						>
							Name
						</InputWithLabel>
						<InputWithLabel
							value={kv.value}
							onChange={(e) => handleNewKVPair(i, "value", e.target.value)}
							classes={
								!readOnly && (id ? newPairs.length > 0 : newPairs.length > 1)
									? "key-input"
									: "key-input--wide"
							}
							readOnly={readOnly}
						>
							Value
						</InputWithLabel>
						{!readOnly && (id ? newPairs.length > 0 : newPairs.length > 1) && (
							<img
								className="delete-icon"
								title="Delete Field"
								onClick={() => deleteNewKVPair(i)}
								src="/assets/icons/delete.svg"
							/>
						)}
					</div>
				</div>
			))}
			{!readOnly && (
				<div className="action-buttons">
					<Button classes={"save-btn" + (!isFormTouched ? " disabled" : "")} clickHandler={saveFields}>
						Save
					</Button>
					<Button classes="at-btn--flat" clickHandler={addKVPair}>
						Add another field
					</Button>
				</div>
			)}
			{loading && <Loading />}
		</div>
	);
};
