import React, { useState, useRef } from "react";

// components
import BulkOperation from "../_commons/BulkOperation";
import { BulkOperationSuccess } from "../_commons/BulkOperationSuccess";
import { BulkOperationFailure } from "../_commons/BulkOperationFailure";

// clients
import { store } from "../../store/configureStore";

// actions
import { ActionTypes } from "../../actions/_types";
import { downloadTemplate, handleBulkExportDownload } from "../../actions/actions";

// utils
import history from "../../history";
import { lS, scroll } from "../../atlas-utils";
import { connect } from "react-redux";

// constants
const itemsData = [
	{
		fieldName: "CRM Title",
		description: "Text to easily identify the item (Column will be discontinued in next version)",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Description",
		description: "A brief description of the item",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Food Type",
		description: "Veg, Non-Veg, Eggetarian etc",
		isRequired: true,
		acceptedValues: [
			"Numeric label",
			"1 - Vegetarian",
			"2 - Non-Vegetarian",
			"3 - Eggetarian",
			"4 - Not Applicable"
		]
	},
	{
		fieldName: "Handle",
		description: "Unique identifier for the item. (If left blank it will be auto generated)",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Is Enabled",
		description: "Archive an item by marking it as inactive. Restore an archived item by marking it as active",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Inactive", "1 - Active"]
	},
	{
		fieldName: "Is Recommended",
		description: "Item is to be displayed as recommended item",
		isRequired: false,
		reqdDesc: "Defaults to 0",
		acceptedValues: ["Positive integer", "0 - Regular item", "1 - Recommended item"]
	},
	{
		fieldName: "Price",
		description: "Sales Price of item",
		isRequired: true,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "Price Descriptor",
		description: "Text to describe how price is applied",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Ref Id",
		description: "A reference ID for the item. This can be an identifier for the item in your ERP/PoS system",
		isRequired: false,
		reqdDesc: "Defaults to -1",
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Serves",
		description: "Number of people served by item",
		isRequired: false,
		reqdDesc: "Defaults to 1",
		acceptedValues: "Positive integer"
	},
	{
		fieldName: "Sort Order",
		description:
			"Control ordering of items as they are displayed to your customers. Values closer to zero will push the item to top while higher values will push it towards bottom",
		isRequired: false,
		reqdDesc: "Defaults to 0",
		acceptedValues: "Positive integer"
	},
	{
		fieldName: "Title",
		description: "Name of the item",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Weight",
		description: "Weight of item (in gms)",
		isRequired: false,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "Excluded Platforms",
		description: "Exclude item on select platforms",
		isRequired: false,
		acceptedValues: ["Alphanumeric characters", "Comma seperated values"]
	},
	{
		fieldName: "Fulfillment Modes",
		description: "Dine in, Delivery, Pick up etc",
		isRequired: false,
		acceptedValues: ["Alphanumeric characters", "Comma seperated values"]
	},
	{
		fieldName: "Option Group",
		description: "Modifier groups associated with the item",
		isRequired: false,
		acceptedValues: ["Alphanumeric characters", "Comma seperated values"]
	},
	{
		fieldName: "Category Handle",
		description: "Handle of the category, in which this item should be part of",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	}
];
const ilaData = [
	{
		fieldName: "Current Stock",
		description: "Count of items in stock at the location",
		isRequired: false,
		acceptedValues: ["Positive integer", "0 when stocked out"]
	},
	{
		fieldName: "External Price",
		description: "Price of item on aggregator platforms (HUB) at the location",
		isRequired: false,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "Handle",
		description: "Unique identifier for the item-location association. (If left blank it will be auto generated)",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Is Enabled",
		description: "Indicates whether the item-location association is to be archived",
		isRequired: true,
		acceptedValues: ["Positive integer", "0 - Inactive", "1 - Active"]
	},
	{
		fieldName: "Item Ref Id",
		description: "A reference ID for the item at the location. This can be an identifier in your ERP/PoS system",
		isRequired: false,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Price",
		description: "Price of item on your apps and website (Meraki) at the location",
		isRequired: false,
		acceptedValues: "Decimal format"
	},
	{
		fieldName: "Recommended",
		description: "Item is to be displayed as recommended item at the given location",
		isRequired: false,
		acceptedValues: ["Positive integer", "0 - Regular item", "1 - Recommended item"]
	},
	{
		fieldName: "Sort Order",
		description:
			"Control ordering of items as they are displayed to your customers. Values closer to zero will push the item to top while higher values will push it towards bottom",
		isRequired: false,
		acceptedValues: "Positive integer"
	},
	{
		fieldName: "Item Handle",
		description: "Unique identifier for the item",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Store Handle",
		description: "Unique identifier for the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	}
];

const csfData = [
	{
		fieldName: "Ref ID",
		description:
			"A reference ID for the custom field. This can be an identifier for the item in your ERP/PoS system",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Group",
		description: "Example: nutrition, ingredients etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Key",
		description: "Example: proteins, vitamin A, eggs etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Value",
		description: "Example: 20gm, 4ml etc.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Item Handle",
		description: "A reference ID for the item at the location.",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	}
];

const ilpaData = [
	{
		fieldName: "Item Handle",
		description: "Unique identifier for the item-location association",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Store Handle",
		description: "Unique identifier for the location",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Platform",
		description: "Name of the platform",
		isRequired: true,
		acceptedValues: "Alphanumeric characters"
	},
	{
		fieldName: "Price",
		description: "Price of item on your platforms at the location",
		isRequired: true,
		acceptedValues: "Decimal format"
	}
];

const FORM_TABS = [
	{
		label: "Items",
		value: "items"
	},
	{
		label: "Item-Location associations",
		value: "ila"
	},
	{
		label: "Item-Location-Platform associations",
		value: "ilpa"
	},
	{
		label: "Item custom fields",
		value: "csf"
	}
];

const BulkItems = (props) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [downloading, setDownloading] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [csvFile, setCsvFile] = useState(undefined);
	const [uploadedFileName, setUploadedFileName] = useState(undefined);
	const [validation, setValidation] = useState(undefined);
	const [bulkOpState, setBulkOpState] = useState("default");
	const [headerFields, setHeaderFields] = useState(undefined);
	const [failureResponse, setFailureResponse] = useState(undefined);
	const [failureData, setFailureData] = useState(undefined);
	const [successData, setSuccessData] = useState(undefined);
	const [downloadAction, setDownloadAction] = useState(false);
	const topRef = useRef();
	const bottomRef = useRef();
	const isMenuOverCatalogueEnabled = props.biz.isMenuOverCatalogueEnabled || false;
	const currLocation = {
		pathname: props.location.pathname,
		title: "Bulk actions on items"
	};
	const prevLocation = {
		pathname: "/items",
		title: "Items"
	};

	const handleBackAction = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
	};

	const setCurrentTab = (tab) => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");
		setFormTab(tab.value);
		// scroll to the top
		scroll({ top: topRef.current.offset - 57, left: 0 });
	};

	const handleUploadNewFile = () => {
		setCsvFile(undefined);
		setUploadedFileName(undefined);
		setBulkOpState("default");

		// scroll to the bottom
		setTimeout(() => {
			bottomRef.current.scrollIntoView({ behavior: "smooth" });
		}, 100);
	};

	const handleSaveChanges = () => {
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: "Changes saved successfuly",
				timeout: 2000,
				error: false
			}
		});
		setTimeout(() => {
			history.push("/items");
		}, 100);
	};

	const csvToJson = (data) => {
		let sanitisedData = data
			.split("\n")
			.map((row) => row.trim())
			.filter((row) => row !== "")
			.map((row) => row.split(","));
		const headers = sanitisedData[0].map((header) => header.toLowerCase().split(" ").join("_"));

		const fields = {};
		headers.map((header) => {
			fields[header] = true;
		});
		setHeaderFields(fields);

		const tableData = [];
		if (headers.includes("invalid_headers")) {
			let obj = {
				invalid_headers: sanitisedData[1].join(", ")
			};
			tableData.push(obj);
		} else {
			sanitisedData.map((row, i) => {
				if (i !== 0) {
					let obj = {};
					headers.map((column, j) => {
						obj[column] = row[j];
					});
					tableData.push(obj);
				}
			});
		}
		setFailureData(tableData);
	};

	const auth = lS.get("auth");

	const exportTemplate = async (emails, entity) => {
		setDownloading(true);
		const modelName =
			formTab === "ila"
				? `itemlocationassociation`
				: formTab === "csf"
					? `itemmodelkeyvalue`
					: formTab === "ilpa"
						? `itemlocationplatformassociation`
						: `item`;
		await handleBulkExportDownload(modelName, emails, entity);
		setDownloading(false);
	};

	const handleTemplateDownload = async () => {
		setDownloading(true);
		const modelName =
			formTab === "ila"
				? `itemlocationassociation`
				: formTab === "csf"
					? `itemmodelkeyvalue`
					: formTab === "ilpa"
						? `itemlocationplatformassociation`
						: `item`;
		await downloadTemplate(modelName);
		setDownloading(false);
	};

	const downloadErrorFile = () => {
		setDownloading(true);
		const file = new Blob([failureResponse], { type: "text/csv" });

		// process to auto download it
		const fileURL = URL.createObjectURL(file);
		const link = document.createElement("a");
		link.href = fileURL;
		link.download =
			formTab === "ila"
				? `Item-Location-Associations-Errors.csv`
				: formTab === "csf"
					? `Item-Custom-Field-Errors.csv`
					: `Items-Errors.csv`;
		link.click();
		setDownloading(false);
	};

	const handleUploadFile = async (e) => {
		setUploading(true);
		setValidation(undefined);

		if (!csvFile) {
			var files = await e.target.files;
			setCsvFile(files[0]);
			setUploadedFileName(files[0].name);
			var formData = new FormData();
			formData.append(
				"model-name",
				formTab === "ila"
					? `itemlocationassociation`
					: formTab === "csf"
						? `itemmodelkeyvalue`
						: formTab === "ilpa"
							? `itemlocationplatformassociation`
							: `item`
			);
			formData.append("sheet", files[0]);
		} else {
			var formData = new FormData();
			formData.append(
				"model-name",
				formTab === "ila"
					? `itemlocationassociation`
					: formTab === "csf"
						? `itemmodelkeyvalue`
						: formTab === "ilpa"
							? `itemlocationplatformassociation`
							: `item`
			);
			formData.append("sheet", csvFile);
		}

		try {
			const res = await fetch(process.env.REACT_APP_API_URL_CONFIG_SHEET, {
				method: "POST",
				headers: { Authorization: auth && `Bearer ${auth.token}` },
				body: formData
			});
			const contentType = res.headers.get("content-type");
			const status = res.status;

			if (contentType === "text/csv" && status === 200) {
				const data = await res.text();
				setFailureResponse(data);
				csvToJson(data);
				setDownloadAction(false);
				setBulkOpState("failure");

				// scroll to the top
				scroll({ top: topRef.current.offset - 57, left: 0 });

				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Please correct the errors and try again",
						timeout: 2000,
						error: true
					}
				});
			} else if (contentType === "application/json") {
				const data = await res.json();
				if (status === 200) {
					const tableData = [{ message: data.message }];
					setSuccessData(tableData);
					setBulkOpState("success");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: data.message,
							timeout: 2000,
							error: false
						}
					});
				} else if (status === 400) {
					const fields = { reason: true };
					setHeaderFields(fields);

					const tableData = [{ reason: data.message }];
					setFailureData(tableData);

					setDownloadAction(true);
					setBulkOpState("failure");

					// scroll to the top
					scroll({ top: topRef.current.offset - 57, left: 0 });

					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: "Please correct the errors and try again",
							timeout: 2000,
							error: true
						}
					});
				}
			} else {
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Something went wrong.",
						timeout: 5000,
						error: true
					}
				});
			}
		} catch (err) {
			console.log(err);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: "Something went wrong.",
					timeout: 5000,
					error: true
				}
			});
		}
		setUploading(false);
	};

	return (
		<div>
			{bulkOpState === "default" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperation
							title="Bulk actions on items"
							subTitle="Create or update items and item locations associations in bulk"
							downloadTitle="Download Template"
							downloadTemplate={handleTemplateDownload}
							exportTitle={
								formTab === "ila"
									? "Item-Location Associations"
									: formTab === "csf"
										? "Item Custom Fields"
										: formTab === "ilpa"
											? "Item-Location-Platform Associations"
											: "Items"
							}
							exportTemplate={exportTemplate}
							data={
								formTab === "ila"
									? ilaData
									: formTab === "csf"
										? csfData
										: formTab === "ilpa"
											? ilpaData
											: itemsData
							}
							showTopBar={true}
							tabs={isMenuOverCatalogueEnabled ? [FORM_TABS[0], FORM_TABS[3]] : FORM_TABS}
							selectedTab={formTab}
							setCurrentTab={setCurrentTab}
							downloading={downloading}
							uploading={uploading}
							handleUploadFile={handleUploadFile}
							uploadedFileName={uploadedFileName}
							validation={validation}
							hiddenTabs={props.differentialPricingEnabled ? [] : ["ilpa"]}
							prevLocation={prevLocation}
							currLocation={currLocation}
						/>
					</div>
					<div ref={bottomRef}></div>
				</React.Fragment>
			)}
			{bulkOpState === "success" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationSuccess
							title="Bulk actions on items"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							uploadedFileName={uploadedFileName}
							saveChanges={handleSaveChanges}
							data={successData || []}
						/>
					</div>
				</React.Fragment>
			)}
			{bulkOpState === "failure" && (
				<React.Fragment>
					<div className="section-container-common" ref={topRef}>
						<BulkOperationFailure
							title="Bulk actions on items"
							subTitle="Overview of data import request"
							handleBackAction={handleBackAction}
							downloading={downloading}
							hideDownloadAction={downloadAction}
							uploadedFileName={uploadedFileName}
							downloadErrorFile={downloadErrorFile}
							uploadNewFile={handleUploadNewFile}
							headerFields={headerFields || {}}
							data={failureData || []}
						/>
					</div>
				</React.Fragment>
			)}
		</div>
	);
};
const mapStateToProps = (store) => ({
	differentialPricingEnabled: store.login?.loggedInbizDetail?.differentialPricingEnabled || false,
	biz: store.login.loggedInbizDetail
});
export default connect(mapStateToProps)(BulkItems);
