import React, { useState, useEffect, useCallback, useReducer, useRef, useImperativeHandle } from "react";

// component
import { FormSidebar } from "../components/_commons/FormSidebar";
import { Topbar } from "../components/_commons/Topbar";
import { Button } from "../components/_commons/Button";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import { ArchiveRestore, CATALOGUE_ENTITY_TYPES } from "../components/_commons/ArchiveRestore";
import { Modal } from "../components/_commons/Modal";
import BasicInfo from "../components/LocationEdit/BasicInfo";
import Brands from "../components/LocationEdit/Brands";
import DirectPlatform from "../components/LocationEdit/DirectPlatform";
import SelfDelivery from "../components/LocationEdit/SelfDelivery";
import AggrPlatform from "../components/LocationEdit/AggrPlatform";
import Catalogue from "../components/BrandsEdit/Catalogue";
import Logs from "../components/BrandsEdit/Logs";
import Timings from "../components/LocationEdit/Timings";
import LocationGroups from "../components/LocationEdit/LocationGroups";
import Menu from "../components/LocationEdit/Menu";

// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import { connect } from "react-redux";

// utils
import history from "../history";
import {
	removeProp,
	adjustNestedContainer,
	scroll,
	extractInitials,
	findChangedKeysInTwoObjects,
	trackEvent,
	getMerakiV2Flag
} from "../atlas-utils";

// actions
import {
	fetchTimingGroups,
	fetchPaymentModes,
	fetchBizPlatforms,
	fetchTags,
	fetchTranslation,
	updateTranslation,
	fetchCiLocationGroups,
	fetchFulfillmentModes
} from "../actions/actions";
import { fetchLocationsList, fetchLocationDetail, editLocation } from "../actions/locations";
import { fetchHubConfigurations } from "../actions/hub";
import { ActionTypes } from "../actions/_types";

// reducers
import { locationDetailsReducer, LOCATION_DETAILS_INITIAL_STATE } from "../reducers/locations";

// graphql
import { GET_ITEM_TAGS } from "../graphql/items";

// constants
import {
	CATALOGUE_PLATFORMS_LOGO,
	ORDER_CHANNEL_MAP,
	TRACKING_EVENT_NAMES,
	TRACKING_SOURCE,
	TRACKING_STATUS
} from "../client-config";
const SWIGGY_SELF_DELIVERY = "swiggy_self_delivery";

export const FORM_TABS = [
	{
		label: "Basic Information",
		value: "basic"
	},
	{
		label: "Brands",
		value: "brands"
	},
	{
		label: "Hub",
		value: "hub"
	},
	{
		label: "Meraki",
		value: "meraki"
	},
	{
		label: "Self Delivery",
		value: "selfDelivery"
	},
	{
		label: "Location Groups",
		value: "locationGroups"
	},
	{
		label: "Schedules",
		value: "timings"
	},
	{
		label: "Catalogue",
		value: "catalogue"
	},
	{
		label: "Menu",
		value: "menu"
	},
	{
		label: "Logs",
		value: "logs"
	}
];
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const TRANSLATION_FIELDS_MAP = {
	bizLocationNickname: "biz_location_nickname"
};

const LocationEdit = ({
	match,
	biz,
	access = {},
	readOnly = false,
	supportedLanguages = [],
	selectedPlatform = null,
	selectedBrand = null,
	isNested = false,
	isForeignSource = false,
	closeNestedContainer,
	connectedRef,
	isMultibrandEnabled = false,
	hubConfigurations
}) => {
	const [formTab, setFormTab] = useState(FORM_TABS[0].value);
	const [isFormOpen, setFormState] = useState(false);
	const [archiveRestore, setArchiveRestore] = useState(false);
	const [isFormTouched, setFormTouched] = useState(false);
	const [currSelectedLang, setCurrSelectedLang] = useState({
		bizLocationNickname: {
			lang: supportedLanguages.length ? supportedLanguages[0].value : "",
			value: "",
			showActionBtn: false
		}
	});
	const [locationDetails, dispatch] = useReducer(locationDetailsReducer, LOCATION_DETAILS_INITIAL_STATE);
	const { loading, data, error, backupData } = locationDetails;
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isModalBusy, setModalBusy] = useState(false);
	const [modifiedExcludedPlatforms, setModifiedExcludedPlatforms] = useState({
		addedPlf: [],
		removedPlf: [],
		existingPlf: []
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [highlightField, setHighlightField] = useState(false);
	const [showPopover, setShowPopover] = useState(true);
	const nestedRef = useRef();
	const [orderingSlotChanged, setOrderingSlotChanged] = useState(false);
	const [holidayHoursChanged, setHolidayHoursChanged] = useState(false);
	const [isSelfDeliveryEnabled, setIsSelfDeliveryEnabled] = useState(false);
	const [brandOrderingSlotChanged, setBrandOrderingSlotChanged] = useState(false);
	const [brandHolidayHoursChanged, setBrandHolidayHoursChanged] = useState(false);
	const isMenuOverCatalogueEnabled = biz?.isMenuOverCatalogueEnabled || false;

	const formContainerRef = useRef();

	useEffect(() => {
		if (highlightField) {
			setTimeout(() => {
				setHighlightField(false);
			}, 5000);
		}
	}, [highlightField]);

	const handleNestedEntity = useCallback((toOpen = false, type, id) => {
		if (!toOpen) {
			setNestedEntity(NESTED_ENTITY_INITIAL_STATE);
			setModalBusy(false);
		} else {
			setNestedEntity({
				show: true,
				type,
				id
			});
			setModalBusy(true);
		}
		adjustNestedContainer(toOpen);
	}, []);

	useEffect(() => {
		setTimeout(() => setFormState(true), 60);
		fetchTimingGroups();
		fetchPaymentModes();
		fetchBizPlatforms(true);
		fetchTags("");
		fetchCiLocationGroups("", false);
		fetchFulfillmentModes();
	}, []);

	useEffect(() => {
		fetchLocationDetail(parseInt(match.params.id), dispatch, selectedBrand);
		fetchHubConfigurations({ id: match.params.id }, { id: selectedBrand });
	}, [match.params.id]);

	useEffect(() => {
		checkIsSelfDeliveryEnabled();
	}, [hubConfigurations]);

	const checkIsSelfDeliveryEnabled = () => {
		let _isSelfDeliveryEnabled = false;

		/**
			Either of the below should be true for self delivery to be enabled
		 */

		const { data: locationHubConfigurations, bizHubConfigurations } = hubConfigurations;

		// location level check
		const locationLevelPlatform = locationHubConfigurations?.find(
			(config) => config?.name === ORDER_CHANNEL_MAP.SWIGGY
		);
		const locationLevelPlatformConfig = locationLevelPlatform?.keys?.find((config) => {
			if (config?.key === SWIGGY_SELF_DELIVERY && String(config?.value) === "true") {
				return true;
			}
		});
		if (locationLevelPlatformConfig) {
			_isSelfDeliveryEnabled = true;
		}

		// biz level check
		const bizLevelPlatform = bizHubConfigurations?.find((config) => config?.name === ORDER_CHANNEL_MAP.SWIGGY);
		const bizLevelPlatformConfig = bizLevelPlatform?.keys?.find((config) => {
			if (config?.key === SWIGGY_SELF_DELIVERY && String(config?.value) === "true") {
				return true;
			}
		});
		if (bizLevelPlatformConfig) {
			_isSelfDeliveryEnabled = true;
		}

		setIsSelfDeliveryEnabled(_isSelfDeliveryEnabled);
	};

	const fetchItemTags = async (searchText = "") => {
		try {
			const variables = {
				limit: 200,
				offset: 0,
				sort: { field: "title", order: "ASC" }
			};
			if (searchText !== "") {
				variables.search = [{ key: "default", value: searchText }];
			}
			const resp = await client.query({
				query: GET_ITEM_TAGS,
				variables
			});
			// get unique tag groups and its tags
			let tagGroups = {};
			resp.data.itemTags.objects.forEach((tag) => {
				tag = {
					...tag,
					titleForDisplay: tag?.tagGroup ? `${tag.title} (${tag?.tagGroup?.title})` : `${tag.title} (General)`
				};
				if (tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"]) {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"].push(tag);
				} else {
					tagGroups[tag.tagGroup ? tag.tagGroup.title : "General"] = [tag];
				}
			});
			// combine all tag groups' tags using concat
			return [].concat.apply([], Object.values(tagGroups));
		} catch (error) {
			console.log(error);
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: error.message || "Something went wrong.",
					timeout: 2000,
					error: true,
					errObject: error
				}
			});
			return { options: [] };
		}
	};

	const handleCancel = async () => {
		if (nestedEntity.show) {
			nestedRef.current.handleCancel();
			return;
		}
		if (!isModalBusy) {
			setFormState(false);
			setTimeout(async () => {
				if (isNested || isForeignSource) {
					closeNestedContainer();
				} else {
					await fetchLocationsList(selectedPlatform, selectedBrand);
					history.push("/locations");
				}
			}, 100);
		}
	};

	useImperativeHandle(
		connectedRef,
		() => ({
			handleCancel
		}),
		[handleCancel]
	);

	const handleReloadData = async () => {
		await fetchLocationDetail(parseInt(match.params.id), dispatch, selectedBrand);
	};

	const handleForm = (field, value, brandId) => {
		if (brandId) {
			let updatedBrandLocations = data.brandLocations.map((brndLoc) => {
				let brandLocation = { ...brndLoc };
				if (brandLocation.brandId === brandId) {
					brandLocation[field] = value;
				}
				return brandLocation;
			});
			dispatch({
				type: ActionTypes.UPDATE_LOCATION_DETAIL,
				payload: {
					brandLocations: updatedBrandLocations,
					selectedBrandLocation: {
						...data.selectedBrandLocation,
						[field]: value
					}
				}
			});
		} else {
			dispatch({
				type: ActionTypes.UPDATE_LOCATION_DETAIL,
				payload: {
					[field]: value
				}
			});
		}
		if (!isFormTouched && field !== "orderingSlots") {
			setFormTouched(true);
		}
		if (field === "orderingSlots" && !brandId) {
			setOrderingSlotChanged(true);
		}
		if (field === "orderingSlots" && brandId) {
			setBrandOrderingSlotChanged(true);
		}
		if (field === "holidayHours" && !brandId) {
			setHolidayHoursChanged(true);
		}
		if (field === "holidayHours" && brandId) {
			setBrandHolidayHoursChanged(true);
		}
	};

	const handleBrandLocation = (brandLocation) => {
		dispatch({
			type: ActionTypes.UPDATE_LOCATION_DETAIL,
			payload: {
				selectedBrandLocation: brandLocation
			}
		});
	};

	const updateLocationDetail = (payload) => {
		dispatch({
			type: ActionTypes.UPDATE_LOCATION_DETAIL,
			payload
		});
	};

	const resetOrderingSlots = (backup = false) => {
		if (backup) {
			const payload = {
				orderingSlots: backupData?.orderingSlots,
				brandLocations: backupData?.brandLocations,
				selectedBrandLocation: backupData?.selectedBrandLocation,
				holidayHours: backupData?.holidayHours
			};
			updateLocationDetail(payload);
		}
		setOrderingSlotChanged(false);
		setHolidayHoursChanged(false);
		setBrandOrderingSlotChanged(false);
		setBrandHolidayHoursChanged(false);
	};

	const handleSubmit = async (polygons, saveBrandLocationOrderingSlots = false, holidayHoursUpdate = false) => {
		const { associatedPlatforms, associatedItemsCount, ...formData } = data;
		if (polygons) {
			formData.polygons = [...polygons];
		}
		const sanitisedData = removeProp(formData, "__typename");
		if (sanitisedData.merchantBizLocationId === "") {
			sanitisedData.merchantBizLocationId = "-1";
		}
		let variables = {};
		const changedFields = findChangedKeysInTwoObjects(sanitisedData, backupData);
		if (formTab === FORM_TABS[0].value || saveBrandLocationOrderingSlots) {
			setModalOpen(false);
			// fields in basic info tab
			const fields = [
				"id",
				"bizLocationNickname",
				"bizAddress",
				"city",
				"merchantBizLocationId",
				"sortOrder",
				"gstin",
				"fulfillmentModesList",
				"contactPhone",
				"tags",
				"excludedPlatforms",
				"isActive",
				"description",
				"seoDescription",
				"brandLocations",
				"orderPrepTime",
				"orderingSlots",
				"busyOrderPrepTime"
			];

			const getRelevantChangedFields = changedFields.filter((field) => fields.includes(field));
			const eventMeta = {
				fields_update: getRelevantChangedFields,
				num_of_fields_updated: getRelevantChangedFields.length
			};

			fields.forEach((f) => (variables[f] = sanitisedData[f]));
			if (holidayHoursUpdate && !biz?.isMultibrandEnabled) {
				variables.holidayHourIds = (data?.holidayHours?.objects || data?.holidayHours)?.map((obj) =>
					parseInt(obj.id)
				);
			}
			const resp = await editLocation(variables, dispatch, formTab);

			if (resp) {
				eventMeta["status"] = TRACKING_STATUS.SUCCESS;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_BASIC_INFO_UPDATE, eventMeta);

				setFormTouched(false);
				setBrandOrderingSlotChanged(false);
				setBrandHolidayHoursChanged(false);
				// Set currentExcludedPlatforms value as of excludedPlatforms value
				dispatch({
					type: ActionTypes.UPDATE_LOCATION_DETAIL,
					payload: {
						currentExcludedPlatforms: data.excludedPlatforms
					}
				});
				// update backup data
				dispatch({
					type: ActionTypes.UPDATE_LOCATION_BACKUP_DATA,
					payload: {
						...data
					}
				});
				return true;
			} else {
				eventMeta["status"] = TRACKING_STATUS.FAILURE;
				trackEvent(TRACKING_EVENT_NAMES.LOCATION_BASIC_INFO_UPDATE, eventMeta);
			}
		} else if ([FORM_TABS[3].value, FORM_TABS[4].value, FORM_TABS[6].value].includes(formTab)) {
			// fields in meraki tab, self delivery tab and schedules tab
			const fields = [
				"id",
				"polygons",
				"locLatitude",
				"locLongitude",
				"orderDeliveryRadius",
				"minOrderTotal",
				"minimumOrderDeliveryTime",
				"minimumOrderPickupTime",
				"paymentModesList",
				"hideNameForUi",
				"merchantBizLocationId",
				"isEnabledForOrdering",
				"orderingDisabledTemporary",
				"primaryContactPhone",
				"primaryContactEmail",
				"isActive",
				"orderingSlots",
				"orderPrepTime",
				"busyOrderPrepTime"
			];

			const getRelevantChangedFields = changedFields.filter((field) => fields.includes(field));
			const eventName =
				formTab === FORM_TABS[3].value
					? TRACKING_EVENT_NAMES.LOCATION_MERAKI_UPDATE
					: formTab === FORM_TABS[4].value
						? TRACKING_EVENT_NAMES.LOCATION_SELF_DELIVERY_UPDATE
						: TRACKING_EVENT_NAMES.LOCATION_TIMING_GROUPS_UPDATE;
			const eventMeta = {
				fields_update: getRelevantChangedFields,
				num_of_fields_updated: getRelevantChangedFields.length
			};
			fields.forEach((f) => (variables[f] = sanitisedData[f]));
			if (holidayHoursUpdate && !biz?.isMultibrandEnabled) {
				variables.holidayHourIds = (data?.holidayHours?.objects || data?.holidayHours)?.map((obj) =>
					parseInt(obj.id)
				);
			}
			const resp = await editLocation(variables, dispatch, formTab);
			if (resp) {
				eventMeta["status"] = TRACKING_STATUS.SUCCESS;
				trackEvent(eventName, eventMeta);

				setFormTouched(false);
				setOrderingSlotChanged(false);
				setHolidayHoursChanged(false);
				dispatch({
					type: ActionTypes.UPDATE_LOCATION_DETAIL,
					payload: {
						orderingSlots: variables?.orderingSlots
					}
				});
				// update backup data
				dispatch({
					type: ActionTypes.UPDATE_LOCATION_BACKUP_DATA,
					payload: {
						...data
					}
				});
				return true;
			} else {
				eventMeta["status"] = TRACKING_STATUS.FAILURE;
				trackEvent(eventName, eventMeta);
			}
		}
	};

	const handleChecksBeforeSubmit = () => {
		if (!isMultibrandEnabled) {
			// get modified and current excluded platforms list
			const excludedPlatforms = data.excludedPlatforms?.map((plf) => plf.platformName) || [];
			const currentExcludedPlatforms = data.currentExcludedPlatforms?.map((plf) => plf.platformName) || [];

			// check for modified excluded platforms to show modal alert
			let addedPlatforms = excludedPlatforms.filter((plf) => !currentExcludedPlatforms.includes(plf));
			let removedPlatforms = currentExcludedPlatforms.filter((plf) => !excludedPlatforms.includes(plf));
			let existingPlatforms = currentExcludedPlatforms.filter((plf) => excludedPlatforms.includes(plf));

			// check state of each platform for this location (hub section)
			data.associatedPlatforms.forEach((associatedPlf) => {
				addedPlatforms = addedPlatforms.filter((plf) =>
					associatedPlf?.platform?.platformName === plf ? associatedPlf.state !== "Disconnected" : plf
				);
				removedPlatforms = removedPlatforms.filter((plf) =>
					associatedPlf?.platform?.platformName === plf ? associatedPlf.state === "Disconnected" : plf
				);
				existingPlatforms = existingPlatforms.filter((plf) =>
					associatedPlf?.platform?.platformName === plf ? associatedPlf.state !== "Disconnected" : plf
				);
			});

			setModifiedExcludedPlatforms({
				addedPlf: addedPlatforms,
				removedPlf: removedPlatforms,
				existingPlf: existingPlatforms
			});

			if (addedPlatforms.length || removedPlatforms.length || existingPlatforms.length) {
				setModalOpen(true);
			} else {
				handleSubmit();
			}
		} else {
			handleSubmit();
		}
	};

	const handleArchiveRestore = useCallback(
		(success) => {
			if (success) {
				dispatch({
					type: ActionTypes.UPDATE_LOCATION_DETAIL,
					payload: {
						isActive: !data.isActive
					}
				});
			}
			setArchiveRestore(false);
		},
		[data]
	);

	const updateAggrPlatformData = (id, outletUrl, externalId, testId, state, brandId) => {
		if (isMultibrandEnabled) {
			let updatedBrandLocations = data.brandLocations.map((brndLoc) => {
				let brandLocation = { ...brndLoc };
				if (brandLocation.brand.id === brandId) {
					brandLocation.associatedPlatforms = brandLocation.associatedPlatforms.map((plf) => {
						let platform = { ...plf };
						if (platform.id === id) {
							platform.externalId = externalId;
							platform.outletUrl = outletUrl;
							platform.state = state;
							if (["zomato", "ubereats"].includes(platform?.platform?.platformName?.toLowerCase())) {
								platform.testId = testId;
							}
						}
						return platform;
					});
				}
				return brandLocation;
			});
			dispatch({
				type: ActionTypes.UPDATE_LOCATION_DETAIL,
				payload: {
					brandLocations: updatedBrandLocations
				}
			});
		} else {
			let associatedPlatforms = data.associatedPlatforms.slice();
			const platform = associatedPlatforms.find((pl) => pl.id === id);
			if (platform) {
				platform.outletUrl = outletUrl;
				platform.externalId = externalId;
				platform.testId = testId;
			}
			dispatch({
				type: ActionTypes.UPDATE_LOCATION_DETAIL,
				payload: {
					associatedPlatforms
				}
			});
		}
	};

	const handleLanguage = async (lang, field) => {
		const resp = await fetchTranslation(
			parseInt(match.params.id),
			"LOCATION",
			TRANSLATION_FIELDS_MAP[field],
			lang.value
		);
		if (resp) {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					lang: lang.value,
					value: resp.value,
					showActionBtn: false
				}
			});
		}
	};

	const handleTranslation = (field, value) => {
		if (currSelectedLang[field].lang === "en") {
			dispatch({
				type: ActionTypes.UPDATE_LOCATION_DETAIL,
				payload: {
					[field]: value
				}
			});
			if (!isFormTouched) {
				setFormTouched(true);
			}
		} else {
			setCurrSelectedLang({
				...currSelectedLang,
				[field]: {
					...currSelectedLang[field],
					value: value,
					showActionBtn: true
				}
			});
		}
	};

	const saveTranslation = async (field, value) => {
		const resp = await updateTranslation(
			parseInt(match.params.id),
			"LOCATION",
			TRANSLATION_FIELDS_MAP[field],
			currSelectedLang[field].lang,
			value
		);
		if (resp && !resp.status) {
			dispatch({
				type: ActionTypes.EDIT_LOCATION_DETAIL_FAILURE,
				error: resp.error
			});
		} else if (resp && resp.status) {
			setTimeout(() => {
				setCurrSelectedLang({
					...currSelectedLang,
					[field]: {
						...currSelectedLang[field],
						showActionBtn: false
					}
				});
				store.dispatch({
					type: "RESET_TRANSLATION"
				});
			}, 3000);
		}
	};

	const handleModal = (clickedCancel = false) => {
		setModalOpen(false);
		if (clickedCancel) {
			formContainerRef.current.toggleShowForm(true);
			setHighlightField(true);
		}
	};

	const modalTitle = () => {
		return (
			<div className="modal-title">
				<img src="/assets/icons/alert.svg" />
				<div>Read this Carefully!</div>
			</div>
		);
	};

	const handleBrandsLabelOption = (brandLocation) => {
		return (
			<React.Fragment>
				<div className={"logo " + brandLocation.brandColor}>
					{brandLocation.brandImage ? (
						<img src={brandLocation.brandImage} alt="" />
					) : (
						extractInitials(brandLocation?.brandName?.split(" "))
					)}
				</div>
				<div title={brandLocation.brandName}>
					{brandLocation.brandName && brandLocation.brandName.length > 35
						? brandLocation.brandName.slice(0, 35) + "..."
						: brandLocation.brandName}
				</div>
			</React.Fragment>
		);
	};

	const renderPopover = () => {
		return (
			<div className="popover-info">
				<div className="popover-header">Switching between brands</div>
				<div className="popover-message">
					Decide which brand's information you want to see by switching it here.
				</div>
			</div>
		);
	};

	const scrollDown = () => {
		setTimeout(() => {
			const formContainer = document.getElementsByClassName("form-container")[0];
			if (formContainer) {
				scroll({ top: formContainer.scrollHeight, left: 0 }, formContainer);
			}
		}, 275);
	};

	const hiddenTabs = [
		!access.isHubManagement ? "hub" : "",
		!access.isHubManagement ? "logs" : "",
		!biz?.modulesEnabled?.includes("Meraki") ? "meraki" : "",
		!isMultibrandEnabled ? "brands" : "",
		!biz?.modulesEnabled?.includes("Meraki") && !isSelfDeliveryEnabled ? "selfDelivery" : ""
	];

	const handleCreateNewClick = (type = "regular") => {
		store.dispatch({
			type: ActionTypes.REDIRECT_TO_LOCATION_DETAIL,
			payload: {
				redirectionId: parseInt(match.params.id)
			}
		});
		if (type === "regular") {
			history.push("/timing-groups/new");
		} else {
			history.push("/timing-groups/holiday-new");
		}
	};

	return (
		<div className="location-edit-container">
			<FormSidebar
				isOpen={isFormOpen}
				close={handleCancel}
				submit={formTab === FORM_TABS[0].value ? handleChecksBeforeSubmit : handleSubmit}
				title={data.bizLocationNickname || "Location"}
				subTitle="Edit this location"
				submitTitle="Save"
				connectedRef={formContainerRef}
				loading={loading}
				disabled={!isFormTouched}
				// showMoreBtn={formTab === FORM_TABS[0].value}
				// numRows={4}
				hideActions={
					(formTab === FORM_TABS[0].value && !isFormTouched) ||
					formTab === FORM_TABS[1].value ||
					formTab === FORM_TABS[2].value ||
					(formTab === FORM_TABS[3].value && !isFormTouched) ||
					(formTab === FORM_TABS[4].value && !isFormTouched) ||
					formTab === FORM_TABS[5].value ||
					formTab === FORM_TABS[6].value ||
					formTab === FORM_TABS[7].value ||
					formTab === FORM_TABS[8].value ||
					formTab === FORM_TABS[9].value
				}
				isNested={isNested}
				headerRight={
					access.isAdmin &&
					!readOnly && (
						<Button
							classes={data.isActive ? "at-btn--danger" : "at-btn--success"}
							clickHandler={() => setArchiveRestore(true)}
						>
							{data.isActive ? "Archive" : "Restore"}
						</Button>
					)
				}
			>
				<Topbar
					tabs={
						isMenuOverCatalogueEnabled
							? FORM_TABS.filter((t) => t.value !== "catalogue")
							: FORM_TABS.filter((t) => t.value !== "menu")
					}
					selectedTab={formTab}
					switchTab={(tab) => setFormTab(tab.value)}
					isStickyOnTop
					isScrollable
					hiddenTabs={hiddenTabs}
				/>
				<div className="form-content">
					{formTab === FORM_TABS[0].value && (
						<BasicInfo
							data={data}
							handleForm={handleForm}
							readOnly={!access.isAdmin || readOnly}
							validations={error.fields || {}}
							scrollDown={scrollDown}
							showLanguages={true}
							currSelectedLang={currSelectedLang}
							handleLanguage={handleLanguage}
							handleTranslation={handleTranslation}
							saveTranslation={saveTranslation}
							fetchItemTags={fetchItemTags}
							isMultibrandEnabled={isMultibrandEnabled}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							highlightField={highlightField}
							showPopover={showPopover}
							renderPopover={renderPopover}
							handlePopoverClose={() => setShowPopover(false)}
						/>
					)}
					{formTab === FORM_TABS[1].value && (
						<Brands
							locationId={parseInt(match.params.id)}
							handleReloadData={handleReloadData}
							readOnly={!access.isAdmin || readOnly}
						/>
					)}
					{formTab === FORM_TABS[2].value && (
						<AggrPlatform
							data={data}
							platforms={data.associatedPlatforms || []}
							locationId={parseInt(match.params.id)}
							updateAggrPlatformData={updateAggrPlatformData}
							locationName={data.bizLocationNickname || ""}
							fetchData={() => fetchLocationDetail(parseInt(match.params.id), dispatch)}
							isAdmin={access.isAdmin && !readOnly}
							isMultibrandEnabled={isMultibrandEnabled}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							showPopover={showPopover}
							renderPopover={renderPopover}
							handlePopoverClose={() => setShowPopover(false)}
							isSelfDeliveryEnabled={isSelfDeliveryEnabled}
						/>
					)}
					{formTab === FORM_TABS[3].value && (
						<DirectPlatform
							data={data}
							handleForm={handleForm}
							setModalBusy={setModalBusy}
							readOnly={!access.isAdmin || readOnly}
							validations={error.fields || {}}
							handleSubmit={handleSubmit}
							isMerakiV2Enabled={getMerakiV2Flag(biz)}
						/>
					)}
					{formTab === FORM_TABS[4].value && (
						<SelfDelivery
							data={data}
							handleForm={handleForm}
							setModalBusy={setModalBusy}
							readOnly={!access.isAdmin || readOnly}
							validations={error.fields || {}}
							handleSubmit={handleSubmit}
						/>
					)}
					{formTab === FORM_TABS[5].value && (
						<LocationGroups
							locationId={match.params.id}
							setModalBusy={setModalBusy}
							handleNestedEntity={handleNestedEntity}
							isMultibrandEnabled={isMultibrandEnabled}
							data={data}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							showPopover={showPopover}
							renderPopover={renderPopover}
							handlePopoverClose={() => setShowPopover(false)}
							readOnly={!access.isAdmin || readOnly}
						/>
					)}
					{formTab === FORM_TABS[6].value && (
						<Timings
							data={data}
							handleForm={handleForm}
							platforms={data.associatedPlatforms || []}
							orderingSlotChanged={orderingSlotChanged}
							holidayHoursChanged={holidayHoursChanged}
							brandOrderingSlotChanged={brandOrderingSlotChanged}
							brandHolidayHoursChanged={brandHolidayHoursChanged}
							resetOrderingSlots={resetOrderingSlots}
							handleSubmit={handleSubmit}
							isMultibrandEnabled={isMultibrandEnabled}
							readOnly={!access.isAdmin || readOnly}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							handleCreateNewClick={handleCreateNewClick}
						/>
					)}
					{formTab === FORM_TABS[7].value && (
						<Catalogue
							brandId={data?.selectedBrandLocation?.brandId || null}
							fromLocations={true}
							locationId={match.params.id}
							locationName={data?.bizLocationNickname || ""}
							selectedBrandLocation={data?.selectedBrandLocation}
							brandLocations={data?.brandLocations}
							isMultibrandEnabled={isMultibrandEnabled}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							showPopover={showPopover}
							renderPopover={renderPopover}
							handlePopoverClose={() => setShowPopover(false)}
							readOnly={!access.isAdmin || readOnly}
							containerClassName="location-edit-container"
							handleNestedEntity={handleNestedEntity}
						/>
					)}
					{formTab === FORM_TABS[8].value && (
						<Menu
							isMultibrandEnabled={isMultibrandEnabled}
							data={data}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
						/>
					)}
					{formTab === FORM_TABS[9].value && (
						<Logs
							locationId={match.params.id}
							locationName={data.bizLocationNickname}
							isMultibrandEnabled={isMultibrandEnabled}
							brandId={data?.selectedBrandLocation?.brandId}
							fromLocations={true}
							selectedBrandLocation={data?.selectedBrandLocation}
							brandLocations={data?.brandLocations}
							handleBrandLocation={handleBrandLocation}
							handleBrandsLabelOption={handleBrandsLabelOption}
							showPopover={showPopover}
							renderPopover={renderPopover}
							handlePopoverClose={() => setShowPopover(false)}
						/>
					)}
					<ArchiveRestore
						isOpen={archiveRestore}
						close={handleArchiveRestore}
						entityType={CATALOGUE_ENTITY_TYPES[0]}
						entityName={data.bizLocationNickname}
						object={data}
						mode={data.isActive ? "archive" : "restore"}
						sourceOfTrigger={TRACKING_SOURCE.DETAIL_VIEW}
					/>
					<NestedEntityContainer
						show={nestedEntity.show}
						type={nestedEntity.type}
						id={nestedEntity.id}
						params={{
							path: "locations",
							locationId: isMultibrandEnabled
								? parseInt(data?.selectedBrandLocation?.id)
								: parseInt(match.params.id)
						}}
						closeNestedContainer={() => handleNestedEntity(false)}
						nestedRef={nestedRef}
						readOnly={!access.isAdmin || readOnly}
					/>
					<Modal
						isOpen={modalOpen}
						close={handleModal}
						title={modalTitle()}
						showSubmitAction={true}
						submitTitle="Proceed"
						submitAction={handleSubmit}
						showCancelAction={true}
						cancelTitle="Cancel"
						cancelAction={() => handleModal(true)}
					>
						<div className="message">
							<ul>
								{(modifiedExcludedPlatforms.existingPlf.length > 0 ||
									modifiedExcludedPlatforms.addedPlf.length > 0) && (
									<li className="added-plf">
										<div className="text">
											<span>{data.bizLocationNickname || "This location"}</span> will be
											disconnected from the following platform(s):
										</div>
										<div className="all-platforms">
											{modifiedExcludedPlatforms.existingPlf.map((plf, i) => (
												<div className="platform-container" key={i}>
													<img
														src={
															CATALOGUE_PLATFORMS_LOGO[plf.toLowerCase()] ||
															"/assets/icons/icons8-globe-40.png"
														}
														alt=""
													/>
													<div>{plf}</div>
												</div>
											))}
											{modifiedExcludedPlatforms.addedPlf.map((plf, i) => (
												<div className="platform-container" key={i + 20}>
													<img
														src={
															CATALOGUE_PLATFORMS_LOGO[plf.toLowerCase()] ||
															"/assets/icons/icons8-globe-40.png"
														}
														alt=""
													/>
													<div>{plf}</div>
												</div>
											))}
										</div>
									</li>
								)}
								{modifiedExcludedPlatforms.removedPlf.length > 0 && (
									<li className="removed-plf">
										<div className="text">
											An integrate request will be triggered to take{" "}
											<span>{data.bizLocationNickname || "this location"}</span> live on
											platform(s) below:
										</div>
										<div className="all-platforms">
											{modifiedExcludedPlatforms.removedPlf.map((plf, i) => (
												<div className="platform-container" key={i}>
													<img
														src={
															CATALOGUE_PLATFORMS_LOGO[plf.toLowerCase()] ||
															"/assets/icons/icons8-globe-40.png"
														}
														alt=""
													/>
													<div>{plf}</div>
												</div>
											))}
										</div>
									</li>
								)}
							</ul>
						</div>
					</Modal>
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	biz: store.login.loggedInbizDetail,
	access: store.login.loginDetail.access,
	supportedLanguages: store.login.loggedInbizDetail.supportedLanguages,
	selectedPlatform: store.locationsList.selectedPlatform,
	selectedBrand: store.locationsList.selectedBrand,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	locationsList: store.locationsList,
	hubConfigurations: store.hubConfigurations
}))(LocationEdit);
