// clients
import { client } from "../client";
import { store } from "../store/configureStore";

// graphql
import {
	GET_TIMING_GROUPS_LIST,
	GET_TIMING_GROUP_DETAIL,
	EDIT_TIMING_GROUP_DETAIL,
	GET_HOLIDAY_SCHEDULE_DETAIL,
	GET_HOLIDAY_SCHEDULE_LIST,
	EDIT_HOLIDAY_SCHEDULE_DETAIL
} from "../graphql/timingGroups";

// utils
import { parseErrorMessages, msaagesArrayToHtml } from "../atlas-utils";

// actions
import { toggleGlobalLoader } from "./actions";
import { ActionTypes } from "./_types";

// constants
import { DAYS } from "../components/TimingGroupEdit/RegularSchedule";
import { SLOT_INITIAL_STATE } from "../containers/TimingGroupCreate";
import moment from "moment";
import { BRAND_COLORS } from "../client-config";

export const fetchTimingGroupsList = async (
	active = true,
	search = "",
	brand = "",
	limit = 10,
	offset = 0,
	sort = {}
) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_TIMING_GROUPS_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		let searchObject = [];
		if (search !== "") {
			searchObject.push({
				key: "default",
				value: search
			});
		}
		let filtersObject = [];
		filtersObject.push({
			field: "is_active",
			value: String(active)
		});
		// sort
		// sort !== date -> to prevent holiday hour sort's effect on regular timings
		if (sort.field !== "" && sort.order !== "" && sort.field !== "date") {
			variables.sort = sort;
		}
		// set filter
		variables.filters = filtersObject;
		variables.search = searchObject;
		if (brand !== "") {
			variables.brand = brand.toString();
		}
		const resp = await client.query({
			query: GET_TIMING_GROUPS_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_TIMING_GROUPS_LIST_SUCCESS,
			payload: {
				...resp.data.timingsGroup,
				objects: resp.data.timingsGroup.objects.map((timing) => ({
					...timing,
					associatedBrands: timing.associatedBrands?.map((brand) => ({
						...brand,
						color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					}))
				}))
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_TIMING_GROUPS_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
export const fetchHolidayScheduleList = async (
	active = true,
	search = "",
	brand = "",
	limit = 10,
	offset = 0,
	sort = {}
) => {
	store.dispatch(toggleGlobalLoader(true));
	store.dispatch({
		type: ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_REQUEST
	});
	try {
		const variables = {
			limit,
			offset
		};
		let searchObject = [];
		if (search !== "") {
			searchObject.push({
				key: "default",
				value: search
			});
		}
		let filtersObject = [];
		filtersObject.push({
			field: "is_active",
			value: active
		});

		// sort
		if (sort?.field !== "" && sort?.order !== "") {
			variables.sort = sort;
		}

		// set filter
		variables.filters = filtersObject;
		variables.search = searchObject;
		// if (brand !== "") {
		// 	variables.brand = brand.toString();
		// }

		const resp = await client.query({
			query: GET_HOLIDAY_SCHEDULE_LIST,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_SUCCESS,
			payload: {
				...resp.data.holidayHours,
				objects: resp.data.holidayHours.objects.map((timing) => ({
					...timing,
					associatedBrands: timing.associatedBrands?.map((brand) => ({
						...brand,
						color: BRAND_COLORS[Math.floor(Math.random() * BRAND_COLORS.length)]
					}))
				}))
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_HOLIDAY_SCHEDULE_LIST_FAILURE,
			error
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
	store.dispatch(toggleGlobalLoader(false));
};
export const getStartEndTimes = (slot) => {
	return {
		startTime: `${slot?.startHours?.value}:${slot?.startMinutes?.value}`,
		endTime: `${slot?.endHours?.value}:${slot?.endMinutes?.value}`
	};
};
/**
 *
 * @param {[SLOT_INITIAL_STATE]} slots
 * @returns
 *  true  - if any property is empty ,
 *  false - if no property is empty
 */

export const hasEmptyProperty = (slots = []) => {
	if (slots.length > 0) {
		for (const slot of slots) {
			for (const prop in slot) {
				if (slot[prop] === null || (typeof slot[prop] === "object" && Object.keys(slot[prop]).length === 0)) {
					return true;
				}
			}
		}
	}
	return false;
};

/**
 *
 * @param {[TIME_SLOT_INITIAL_STATE]} timeSlots
 * @returns
 *  true  - if any time slot has empty properties ,
 *  false - if no time slot has empty properties
 */

export const checkForEmptyDetails = (timeSlots = []) => {
	if (timeSlots?.length > 0) {
		for (const timeSlot of timeSlots) {
			if (hasEmptyProperty(timeSlot?.slots)) {
				return true;
			}
		}
	}
	return false;
};

export const addEmptySlot = (timeslots, slotIndex) => {
	return timeslots.map((timeslot, timeslotIndex) => {
		if (timeslotIndex === slotIndex) {
			return {
				...timeslot,
				slots: [...timeslot.slots, SLOT_INITIAL_STATE]
			};
		}
		return timeslot;
	});
};

export const getUpdatedTimeSlots = (data, timeSlots, updatedTimeSlotIndex, updatedSlotIndex) => {
	return timeSlots.map((timeslot, timeslotIndex) => {
		if (timeslotIndex === updatedTimeSlotIndex && updatedSlotIndex >= 0) {
			const updatedSlots = timeslot.slots.map((slot, slotIndex) => {
				if (slotIndex === updatedSlotIndex) {
					const updatedSlotDetails = {
						...slot,
						...data
					};
					return updatedSlotDetails;
				}
				return slot;
			});
			return {
				...timeslot,
				slots: updatedSlots
			};
		} else if (timeslotIndex === updatedTimeSlotIndex) {
			return {
				...timeslot,
				...data
			};
		}
		return timeslot;
	});
};
export const getFilteredDays = (slot) => {
	switch (slot?.scheduleType?.value) {
		case "Daily":
			return DAYS;
		case "Weekdays":
			return DAYS.slice(0, 5);
		case "Weekends":
			return DAYS.slice(5, 8);
		default:
			return Object.keys(slot?.selectDays || {}).filter((day) => slot?.selectDays?.[day]);
	}
};
export const parseTimingGroupData = (to = "form", data, timeSlots = []) => {
	let result = { ...data };
	switch (to) {
		case "form":
			if (result["timeSlots"]) {
				result.timeSlots.map((slot) => {
					const startTime = slot.starttime.split(":");
					const endTime = slot.endtime.split(":");
					slot.startHours = { label: startTime[0], value: startTime[0], index: Number(startTime[0]) };
					slot.startMinutes = { label: startTime[1], value: startTime[1], index: Number(startTime[1]) };
					slot.endHours = { label: endTime[0], value: endTime[0], index: Number(endTime[0]) };
					slot.endMinutes = { label: endTime[1], value: endTime[1], index: Number(endTime[1]) };
				});
			}
			break;
		case "server":
			const daySlots = [];
			result.error = false;
			result.message = "";
			if (checkForEmptyDetails(timeSlots)) {
				result.error = true;
				result.message = "Please select both Open Time and Close Time for a time slot";
				break;
			}

			for (const slot of timeSlots) {
				if (result.error) break;
				else if (
					slot?.scheduleType?.value === "Custom" &&
					Object.values(slot.selectDays).filter((val) => val === true).length === 0
				) {
					result.error = true;
					result.message = "Please select day(s) for a time slot";
					return;
				}
				const filteredDays = getFilteredDays(slot);
				filteredDays.forEach((day) => {
					const index = daySlots.findIndex((obj) => obj.day === day.value);
					if (index !== -1) {
						slot.slots.forEach((ts) => daySlots[index].slots.push(getStartEndTimes(ts)));
					} else {
						const newDaySlot = { day: day.value || day, slots: [] };
						slot.slots.forEach((ts) => newDaySlot.slots.push(getStartEndTimes(ts)));
						daySlots.push(newDaySlot);
					}
				});
			}
			result.daySlots = daySlots;
			break;
		default:
			break;
	}
	return result;
};

export const fetchTimingGroupDetail = async (id, brandId, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.GET_TIMING_GROUPS_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		if (brandId) {
			variables.brand = brandId;
		}
		const resp = await client.query({
			query: GET_TIMING_GROUP_DETAIL,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_TIMING_GROUPS_DETAIL_SUCCESS,
			payload: parseTimingGroupData("form", resp.data.timingsGroupDetail)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_TIMING_GROUPS_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Timing Group details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
export const fetchHolidayScheduleDetail = async (id, brandId, fetchPolicy = "no-cache") => {
	store.dispatch({
		type: ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_REQUEST
	});
	try {
		const variables = {
			id
		};
		if (brandId) {
			variables.brand = brandId;
		}

		const resp = await client.query({
			query: GET_HOLIDAY_SCHEDULE_DETAIL,
			variables,
			fetchPolicy
		});
		store.dispatch({
			type: ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_SUCCESS,
			payload: parseTimingGroupData("form", resp.data.holidayHourDetail)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.GET_HOLIDAY_SCHEDULE_DETAIL_FAILURE,
			error: {
				message: "There was an error while fetching Holiday Schedule details"
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

export const editTimingGroupDetail = async (data, timeSlots) => {
	store.dispatch({
		type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_REQUEST
	});
	try {
		const variables = parseTimingGroupData("server", data, timeSlots);
		if (variables.error) {
			store.dispatch({
				type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_FAILURE,
				error: {
					message: variables.message
				}
			});
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: variables.message,
					timeout: 3000,
					error: true
				}
			});
		} else {
			const resp = await client.mutate({
				mutation: EDIT_TIMING_GROUP_DETAIL,
				variables
			});
			if (resp.data.saveTimingsGroup.status.success) {
				store.dispatch({
					type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_SUCCESS
				});
				store.dispatch({
					type: ActionTypes.SHOW_GLOBAL_MESSAGE,
					payload: {
						message: "Schedule saved!",
						timeout: 2000,
						error: false
					}
				});
				store.dispatch({
					type: ActionTypes.UPDATE_TIMING_GROUPS_LIST,
					payload: resp.data.saveTimingsGroup.object
				});
				return true;
			} else {
				// handle error message
				if (
					resp.data.saveTimingsGroup.status.messages.length &&
					resp.data.saveTimingsGroup.status.messages[0].field === null
				) {
					store.dispatch({
						type: ActionTypes.SHOW_GLOBAL_MESSAGE,
						payload: {
							message: resp.data.saveTimingsGroup.status.messages[0].message,
							timeout: 3000,
							error: true
						}
					});
					store.dispatch({
						type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_FAILURE,
						error: {}
					});
				} else {
					store.dispatch({
						type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_FAILURE,
						error: parseErrorMessages(resp.data.saveTimingsGroup.status.messages)
					});
				}
			}
		}
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.EDIT_TIMING_GROUPS_DETAIL_FAILURE,
			error: {
				message: "There was an error while saving Timing Group details",
				fields: error
			}
		});
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 2000,
				error: true,
				errObject: error
			}
		});
	}
};

export const archiveRestoreTimingGroup = async (data) => {
	try {
		const resp = await client.mutate({
			mutation: EDIT_TIMING_GROUP_DETAIL,
			variables: data
		});
		if (resp.data.saveTimingsGroup.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveTimingsGroup.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};

export const archiveRestoreHolidayHour = async (data) => {
	const startDate = data?.dateFrom
		? moment(data?.dateFrom).format("YYYY-MM-DD")
		: moment(data?.date).format("YYYY-MM-DD");
	const endDate = data?.dateTo ? moment(data?.dateTo).format("YYYY-MM-DD") : moment(data?.date).format("YYYY-MM-DD");
	try {
		const resp = await client.mutate({
			mutation: EDIT_HOLIDAY_SCHEDULE_DETAIL,
			variables: {
				...data,
				date: moment(data?.date).format("YYYY-MM-DD"),
				dateTo: endDate,
				dateFrom: startDate,
				daySlots: data?.daySlots || []
			}
		});
		if (resp.data.saveHolidayHour.status.success) {
			return true;
		} else {
			// handle error message
			store.dispatch({
				type: ActionTypes.SHOW_GLOBAL_MESSAGE,
				payload: {
					message: msaagesArrayToHtml(resp.data.saveHolidayHour.status.messages),
					timeout: 5000,
					error: true
				}
			});
			return false;
		}
	} catch (error) {
		throw error;
	}
};
