import React, { useState, useCallback, useEffect, useRef, useImperativeHandle, forwardRef } from "react";

// components
import { SelectFilter } from "../../_commons/SelectFilter";
import { SelectFilterCustom } from "../../_commons/SelectFilterCustom";
import { NewDateCompareFilter } from "../../_commons/NewDateCompareFilter";

// third party
import { connect } from "react-redux";
import uniqBy from "lodash/uniqBy";

// graphql
import { client } from "../../../client";

// store
import { store } from "../../../store/configureStore";

// utils
import { trackEvent } from "../../../atlas-utils";

// actions
import { ActionTypes } from "../../../actions/_types";
import { fetchBrands, fetchStoresDebounced, fetchBizPlatforms, fetchItemCategories } from "../../../actions/actions";
import { GET_STORES_LIST } from "../../../graphql/misc";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../../client-config";
import { SelectFilterCustomGroup } from "../../_commons/SelectFilterCustomGroup";
import { fetchLocationGroupDetail, fetchLocationGroups, fetchStores } from "../../../actions/analytics";
import ExclamatoryIcon from "../../_icons/ExclamatoryIcon";
import Indicator from "./Indicator";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const FILTERING_ENTITIES = ["brand_id", "location_id", "platform_names", "location_group_id"];

const Filters = forwardRef(
	({
		showBrands = false,
		showLocations = false,
		showPlatforms = false,
		showDateCompare = false,
		isMultibrandEnabled = false,
		brands,
		stores,
		bizPlatforms,
		access,
		associatedLocations = [],
		module = "",
		analyticsFiltersState,
		dimensions,
		loading = false,
		includeAllTime = false,
		handleCloseIndicator = () => {},
		resetRef
	}) => {
		const {
			currentFilters,
			appliedFilters,
			currentDateFilter,
			appliedDateFilter,
			initialFiltersFromUrl,
			locationGroupFetchPolicy
		} = analyticsFiltersState;
		const [isDateCompareFilterWrapped, setIsDateCompareFilterWrapped] = useState(false);
		const [optionUpdates, setOptionUpdates] = useState({
			brand_id: {},
			location_id: {},
			platform_names: {},
			location_group_id: {}
		});
		const location = useLocation();
		useEffect(() => {
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
				payload: {
					headerVisibilityToggle: true,
					isLocationOverlapping: false
				}
			});
		}, [location.pathname]);
		const [isCheckedAll, setIsCheckedAll] = useState({
			brand_id: true,
			location_id: true,
			platform_names: true,
			location_group_id: true
		});
		useImperativeHandle(resetRef, () => ({
			clear: () => {
				setOptionUpdates({ brand_id: {}, location_id: {}, platform_names: {}, location_group_id: {} });
			}
		}));
		const filtersContainerRef = useRef();
		const filtersRightRef = useRef();
		const [platformsData, setPlatformsData] = useState([]);
		const [locationsData, setLocationsData] = useState({ limit: 50, offset: 0, items: [], loading: false });
		const [brandsData, setBrandsData] = useState([]);
		const [selectedLocations, setSelectedLocations] = useState([]);
		const [selectedLocationGroups, setSelectedLocationGroups] = useState([]);
		const [locationGroups, setLocationGroups] = useState({
			limit: 3,
			offset: 0,
			items: [],
			loading: false,
			error: false
		});
		const [isSelectFilterOperationsLoading, setIsSelectFilterOperationsLoading] = useState(false);
		const [isFilterLocationGroupLoading, setIsFilterLocationGroupLoading] = useState(false);
		const [searchedLocations, setSearchedLocations] = useState([]);
		const [searchedLocationGroups, setSearchedLocationGroups] = useState([]);
		const [searchVal, setSearchVal] = useState("");
		const [filteredBrands, setFilteredBrands] = useState(
			!access?.isAdmin && (access?.isNonHqReport || access?.isNonHqAnalytics)
				? [...new Set(associatedLocations?.map((loc) => loc?.brandName))]
				: []
		);
		const [filteredBrandLocations, setFilteredBrandLocations] = useState(
			!access?.isAdmin && (access?.isNonHqReport || access?.isNonHqAnalytics)
				? [...new Set(associatedLocations?.map((loc) => loc?.name))]
				: []
		);
		const debounceRef = useRef();

		const fetchSearchedSelectedLocations = async (locationIds = [], searchVal = "") => {
			if (locationIds?.length || searchVal) {
				try {
					const variables = {
						limit: 50,
						sort: {
							field: "name",
							order: "ASC"
						}
					};

					let filters = [
						{
							field: "is_active",
							value: true
						}
					];

					if (locationIds?.length) {
						filters.push({
							field: "biz_location_id",
							value: String(locationIds.join(","))
						});
					}

					if (searchVal) {
						filters.push({
							field: "name",
							value: searchVal
						});
					}

					variables.filters = filters;

					const resp = await client.query({
						query: GET_STORES_LIST,
						variables,
						fetchPolicy: "cache-first"
					});
					return resp;
				} catch (error) {
					console.log(error);
				}
			}
		};

		const fetchIndividualSelectedLocationGroups = async (locationGroupIds = []) => {
			try {
				const fetchPromises = locationGroupIds.map((id) => fetchLocationGroupDetail(id));
				const results = await Promise.all(fetchPromises);
				const finalResult = results.reduce((acc, curr) => {
					if (curr?.data?.locationGroup?.id) {
						acc.push({
							id: curr?.data?.locationGroup?.id,
							title: curr?.data?.locationGroup?.title
						});
					}
					return acc;
				}, []);
				return finalResult;
			} catch (error) {
				console.error(error);
			}
		};
		const fetchSearchedSelectedLocationGroups = async () => {
			try {
				setLocationGroups({ ...locationGroups, loading: true, error: false });
				const resp = await fetchLocationGroups(5, 0, searchVal);
				setSearchedLocationGroups(resp.data.locationGroups.objects);
				setLocationGroups({ ...locationGroups, loading: false, error: false });
			} catch (err) {
				setLocationGroups({ ...locationGroups, loading: false, error: true });
				setSelectedLocationGroups([]);
				console.log(err);
			}
		};

		useEffect(() => {
			if (debounceRef.current) {
				clearTimeout(debounceRef.current);
			}
			if (!searchVal) {
				setSearchedLocations([]);
				return clearTimeout(debounceRef.current);
			}

			debounceRef.current = setTimeout(async () => {
				fetchSearchedSelectedLocationGroups();
				setIsSelectFilterOperationsLoading(true);
				try {
					const resp = await fetchSearchedSelectedLocations([], searchVal);
					if (resp) {
						setSearchedLocations(resp.data.stores.objects);
					}
				} catch (err) {
				} finally {
					setIsSelectFilterOperationsLoading(false);
				}
			}, 300);

			return () => {
				clearTimeout(debounceRef.current);
			};
		}, [searchVal]);

		const fetchLocationGroupsItems = async (offsetZero = false) => {
			if (
				(locationGroups?.count && locationGroups.offset + locationGroups.limit <= locationGroups?.count) ||
				locationGroups.items.length == 0 ||
				offsetZero
			) {
				setLocationGroups({ ...locationGroups, loading: true, error: false });
				try {
					let limit = locationGroups.limit;
					let offset = offsetZero
						? 0
						: (locationGroups?.items || []).length > 0
							? locationGroups.offset + limit
							: locationGroups.offset;
					let setSelectedOnTop = offset == 0 ? true : false;
					const resp = await fetchLocationGroups(limit, offset, "", locationGroupFetchPolicy);
					if (locationGroupFetchPolicy === "no-cache") {
						store.dispatch({
							type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
							payload: { locationGroupFetchPolicy: "cache-first" }
						});
					}
					let items;
					let objects = resp.data.locationGroups.objects;
					items =
						offset == 0 ? uniqBy([...objects], "id") : uniqBy([...locationGroups.items, ...objects], "id");
					let locationGroupsWithTopSelected = [];
					if (setSelectedOnTop) {
						const selectedIds = new Set(selectedLocationGroups.map((group) => group.id));
						const restLocationGroups = (items || []).filter((locGroup) => !selectedIds.has(locGroup.id));
						locationGroupsWithTopSelected = [...selectedLocationGroups, ...restLocationGroups];
					}
					offset = resp.data.locationGroups.offset;
					setLocationGroups({
						...locationGroups,
						offset,
						items: setSelectedOnTop ? locationGroupsWithTopSelected : items,
						loading: false,
						error: false,
						count: resp.data.locationGroups.count
					});
				} catch (err) {
					setLocationGroups({ ...locationGroups, loading: false, error: true });
				}
			}
		};

		const fetchLocations = async (offsetZero = false) => {
			if (
				(locationsData?.count && locationsData.offset + locationsData.limit <= locationsData?.count) ||
				locationsData.items.length == 0 ||
				offsetZero
			)
				setLocationsData({ ...locationsData, loading: true, error: false });
			try {
				let limit = locationsData.limit;
				let offset = offsetZero
					? 0
					: (locationsData?.items || []).length > 0
						? locationsData.offset + limit
						: locationsData.offset;

				let setSelectedOnTop = offset == 0 ? true : false;

				const resp = await fetchStores(limit, offset);
				let items;

				let objects = resp.data.stores.objects;
				items = offset == 0 ? uniqBy([...objects], "id") : uniqBy([...locationsData.items, ...objects], "id");

				let locationsWithTopSelected = [];

				if (setSelectedOnTop) {
					const selectedIds = new Set(selectedLocations.map((group) => group.id));
					const restLocations = (items || []).filter((loc) => !selectedIds.has(loc.id));
					locationsWithTopSelected = [...selectedLocations, ...restLocations];
				}
				offset = resp.data.stores.offset;
				setLocationsData({
					...locationsData,
					offset,
					items: setSelectedOnTop ? locationsWithTopSelected : items,
					loading: false,
					error: false,
					count: resp.data.stores.count
				});
			} catch (err) {
				setLocationsData({ ...locationsData, loading: false, error: true });
			}
		};

		useEffect(() => {
			// [entity].items.length === 0 avoids frequent API calls on URL change
			if (isMultibrandEnabled && showBrands && brands?.items?.length === 0) {
				fetchBrands("", true);
			}
			if (showLocations && stores?.items?.length === 0) {
				fetchStoresDebounced("", 20, null, true);
			}
			if (showPlatforms && bizPlatforms?.items?.length === 0) {
				fetchBizPlatforms(true, true, true);
			}
			if ((locationGroups.items || []).length === 0) {
				fetchLocationGroupsItems();
			}
			if ((locationsData.items || []).length == 0) {
				fetchLocations();
			}
		}, []);

		useEffect(() => {
			if (![platformsData?.isLoading, brandsData?.isLoading].every((item) => item === false)) {
				return;
			}
			if (platformsData?.items?.length) {
				setEntitiesWithSelectedOnTop("platform_names");
			}
			if (brandsData?.items?.length) {
				setEntitiesWithSelectedOnTop("brand_id");
			}
		}, [platformsData?.isLoading, brandsData?.isLoading]);

		useEffect(() => {
			if (
				![
					!locationGroups.offset == 0,
					!locationsData.offset == 0,
					locationGroups?.loading,
					locationsData?.loading,
					isSelectFilterOperationsLoading,
					isFilterLocationGroupLoading,
					platformsData?.isLoading,
					brandsData?.isLoading
				].every((item) => item === false)
			) {
				return;
			}

			if (platformsData?.items?.length) {
				setEntitiesWithSelectedOnTop("platform_names");
			}
			if (brandsData?.items?.length) {
				setEntitiesWithSelectedOnTop("brand_id");
			}
			if (selectedLocations?.length) {
				setEntitiesWithSelectedOnTop("location_id");
			}

			if (selectedLocationGroups?.length) {
				setEntitiesWithSelectedOnTop("location_group_id");
			}
		}, [
			locationGroups?.loading,
			locationsData?.loading,
			isSelectFilterOperationsLoading,
			isFilterLocationGroupLoading,
			initialFiltersFromUrl?.location_group_id,
			initialFiltersFromUrl?.location_id,
			platformsData?.isLoading,
			brandsData?.isLoading
		]);

		const setEntitiesWithSelectedOnTop = (entity) => {
			if (entity === "location_id") {
				let locationsWithSelectedOnTop = locationsData?.items || [];
				if (selectedLocations?.length) {
					const _selectedLocations = [...selectedLocations];
					const _restLocations = locationsData?.items?.filter(
						(loc) => !selectedLocations.find((sel) => sel.id === loc.id)
					);
					locationsWithSelectedOnTop = [..._selectedLocations, ..._restLocations];
				}

				setLocationsData({
					...locationsData,
					items: locationsWithSelectedOnTop
				});
			} else if (entity === "platform_names") {
				let platformsWithSelectedOnTop = platformsData?.items || [];
				const selectedPlatformsKeys = Object.keys(optionUpdates.platform_names);
				if (selectedPlatformsKeys.length) {
					const _restPlatforms = [];
					const _selectedPlatforms = platformsData?.items?.filter((platform) => {
						if (selectedPlatformsKeys.includes(String(platform.id))) {
							return platform;
						} else {
							_restPlatforms.push(platform);
						}
					});

					platformsWithSelectedOnTop = [..._selectedPlatforms, ..._restPlatforms];
					setPlatformsData({
						...platformsData,
						items: platformsWithSelectedOnTop
					});
				}
			} else if (entity === "brand_id") {
				let brandsWithSelectedOnTop = brandsData?.items || [];
				const selectedBrandsKeys = Object.keys(optionUpdates.brand_id).map(String);
				if (selectedBrandsKeys.length) {
					const _restBrands = [];
					const _selectedBrands = brandsData?.items?.filter((brand) => {
						if (selectedBrandsKeys.includes(String(brand.id))) {
							return brand;
						} else {
							_restBrands.push(brand);
						}
					});

					brandsWithSelectedOnTop = [..._selectedBrands, ..._restBrands];
					setBrandsData({
						...brands,
						items: brandsWithSelectedOnTop
					});
				}
			} else if (entity == "location_group_id") {
				let locationGroupsWithTopSelected = locationGroups?.items || [];
				if (selectedLocationGroups?.length) {
					const _selectedLocationGroups = [...selectedLocationGroups];
					const _restLocationGroups = (locationGroups?.items || []).filter(
						(locGroup) => !selectedLocationGroups.find((sel) => sel.id === locGroup.id)
					);
					locationGroupsWithTopSelected = [..._selectedLocationGroups, ..._restLocationGroups];
				}
				setLocationGroups({
					...locationGroups,
					items: locationGroupsWithTopSelected
				});
			}
		};

		useEffect(() => {
			setBrandsData(brands);
		}, [brands]);

		useEffect(() => {
			if (!bizPlatforms?.isLoading && bizPlatforms?.items?.length) {
				const _bizPlatforms = { ...bizPlatforms };

				if (access.primeAccess?.length && access.primeAccess?.find((prm) => prm.posEnabled) !== undefined) {
					if (!_bizPlatforms.items?.find((item) => item.id === "prime")) {
						_bizPlatforms.items.push({
							id: "prime",
							platformName: "Prime",
							image: CATALOGUE_PLATFORMS_LOGO["prime"]
						});
					}
				}

				setPlatformsData(_bizPlatforms);
			}
		}, [bizPlatforms]);

		const resetFilters = () => {
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_RESET
			});
			setOptionUpdates({
				brand_id: {},
				location_id: {},
				platform_names: {},
				location_group_id: {}
			});
			setSelectedLocationGroups([]);
			setIsCheckedAll({
				brand_id: true,
				location_id: true,
				platform_names: true,
				location_group_id: true
			});
			setSelectedLocations([]);
		};

		const isFirstRender = useRef(true);
		const resetFilter = useRef(null);
		useEffect(() => {
			if (
				analyticsFiltersState?.compareVisit &&
				analyticsFiltersState?.compareVisit.includes("compare") &&
				!location?.pathname.includes("compare")
			) {
				resetFilters();
				resetFilter.current = true;
				return;
			}

			if (isFirstRender.current) {
				if (resetFilter.current) {
					resetFilters();
				}
				isFirstRender.current = false;
				return;
			}
			// only need to use this for the first time whenever a user lands on a page via URL, this is triggered on URL change as well
			const _initialFiltersFromUrl = { ...initialFiltersFromUrl };
			const _optionUpdates = { ...optionUpdates };
			const _isCheckedAll = { ...isCheckedAll };

			FILTERING_ENTITIES.forEach((entity) => {
				if (_initialFiltersFromUrl[entity]?.find((item) => item === "all")) {
					_isCheckedAll[entity] = true;
					if (entity === "location_id") {
						setSelectedLocations([]);
						_optionUpdates["location_id"] = {};
					} else if (entity === "location_group_id") {
						setSelectedLocationGroups([]);
						_optionUpdates["location_group_id"] = {};
					}
				} else {
					if (entity === "location_id") {
						_isCheckedAll[entity] = false;
						const _selectedLocations = [];
						_optionUpdates[entity] = _initialFiltersFromUrl[entity]?.reduce((acc, opt) => {
							acc[opt] = true;
							if (entity === "location_id") {
								_selectedLocations.push(opt);
							}
							return acc;
						}, {});
						if (entity === "location_id" && _selectedLocations.length) {
							setIsSelectFilterOperationsLoading(true);
							fetchSearchedSelectedLocations(_selectedLocations)
								.then((resp) => {
									if (resp) {
										setSelectedLocations(resp.data.stores.objects);
										setIsSelectFilterOperationsLoading(false);
									}
								})
								.catch((err) => {
									setIsSelectFilterOperationsLoading(false);
								});
						}
					} else if (entity === "location_group_id") {
						_isCheckedAll[entity] = false;
						const __selectedLocationGroups = [];
						_optionUpdates[entity] = _initialFiltersFromUrl[entity]?.reduce((acc, opt) => {
							acc[opt] = true;
							if (entity === "location_group_id") {
								__selectedLocationGroups.push(opt);
							}
							return acc;
						}, {});
						if (__selectedLocationGroups.length) {
							setIsFilterLocationGroupLoading(true);
							fetchIndividualSelectedLocationGroups(__selectedLocationGroups)
								.then((resp) => {
									if (resp) {
										setSelectedLocationGroups(resp);
										setIsFilterLocationGroupLoading(false);
									}
								})
								.catch((err) => {
									setIsFilterLocationGroupLoading(false);
								});
						}
					} else {
						_isCheckedAll[entity] = false;
						const _selectedLocations = [];
						_optionUpdates[entity] = _initialFiltersFromUrl[entity]?.reduce((acc, opt) => {
							acc[opt] = true;
							if (entity === "location_id") {
								_selectedLocations.push(opt);
							}

							return acc;
						}, {});
					}
				}
			});
			_isCheckedAll["location_id"] =
				Object.keys(_optionUpdates["location_group_id"] || []).length == 0 &&
				Object.keys(_optionUpdates["location_id"] || []).length == 0
					? true
					: false;
			setIsCheckedAll(_isCheckedAll);
			setOptionUpdates(_optionUpdates);
			// track event
			trackFiltersEvent(_initialFiltersFromUrl);
		}, [initialFiltersFromUrl]);

		const handleSearchLocations = (searchVal) => {
			setSearchVal(searchVal);
		};

		const detectFiltersWrap = () => {
			// check if filters container is getting wrapped and change date compare
			// filter dropdown position to either left or right
			if (filtersContainerRef.current && filtersRightRef.current) {
				if (filtersRightRef.current.offsetTop > filtersContainerRef.current.offsetTop) {
					setIsDateCompareFilterWrapped(true);
				} else {
					setIsDateCompareFilterWrapped(false);
				}
			}
		};

		useEffect(() => {
			detectFiltersWrap();
		}, [appliedDateFilter, dimensions]);

		const updateAnalyticsFiltersState = (payload) => {
			store.dispatch({
				type: ActionTypes.ANALYTICS_FILTERS_STATE_CHANGE,
				payload
			});
		};

		const handleSelectFilterState = (isOpen = false, field = null) => {
			if (!isOpen) {
				setEntitiesWithSelectedOnTop(field);

				updateAnalyticsFiltersState({
					appliedFilters: currentFilters
				});
			}
		};

		const handleCheck = (state, field, option, props) => {
			let _currentFilters = { ...currentFilters };
			let _selectedLocations = { ...selectedLocations };
			let _selectedLocationGroups = { ...selectedLocationGroups };

			const _optionUpdates = {
				...optionUpdates,
				[field]: {
					...optionUpdates[field],
					[option.id]: state
				}
			};
			const _isCheckedAllUpdates = { ...isCheckedAll };
			_isCheckedAllUpdates[field] = false;
			if (field === "location_group_id" && !state) {
				if (
					Object.keys(_optionUpdates["location_group_id"] || {}).filter(
						(obj) => _optionUpdates["location_group_id"][obj]
					).length == 0 &&
					Object.keys(_optionUpdates["location_id"] || {}).filter(
						(obj) => _optionUpdates["location_group_id"][obj]
					).length == 0
				) {
					_isCheckedAllUpdates["location_id"] = true;
				}
			}

			setOptionUpdates(_optionUpdates);

			if (field === "location_group_id" && state && isCheckedAll["location_id"]) {
				_isCheckedAllUpdates["location_id"] = false;
				_optionUpdates["location_group_id"] = {
					...optionUpdates[field],
					[option.id]: state
				};
				setSelectedLocations([]);
			}
			setIsCheckedAll(_isCheckedAllUpdates);

			// redux state updates
			if (state) {
				_currentFilters[field] = [String(option.id), ..._currentFilters[field]];
				_currentFilters[field] = _currentFilters[field].filter((item) => item !== "all");
				_currentFilters[field] = [...new Set(_currentFilters[field])]; // remove duplicates

				if (field === "location_id") {
					_selectedLocations = uniqBy([...selectedLocations, option], "id");
				}
				if (field === "location_group_id") {
					_selectedLocationGroups = uniqBy([...selectedLocationGroups, option], "id");
				}
			} else {
				if (field === "location_id") {
					_selectedLocations = selectedLocations.filter((loc) => loc.id !== option.id);
				}
				if (field === "location_group_id") {
					_selectedLocationGroups = selectedLocationGroups.filter((locGroup) => locGroup.id !== option.id);
				}
				_currentFilters[field] = _currentFilters[field].filter((item) => item !== String(option.id));
				const locationGroupsKeysLen = Object.keys(_optionUpdates["location_group_id"] || {}).filter(
					(obj) => _optionUpdates["location_group_id"][obj]
				).length;
				const locationKeysLen = Object.keys(_optionUpdates["location_id"] || {}).filter(
					(obj) => _optionUpdates["location_id"][obj]
				).length;

				if (
					_currentFilters[field].length === 0 &&
					(field !== "location_id" || (field == "location_id" && locationGroupsKeysLen == 0))
				) {
					if (field === "location_group_id" && locationGroupsKeysLen == 0 && locationKeysLen == 0) {
						field = "location_id";
					}
					// if no option is selected, select all
					handleCheckAll(true, field);
					return;
				}
			}

			if (field === "location_id") {
				setSelectedLocations(_selectedLocations);
			}
			if (field == "location_group_id") {
				setSelectedLocationGroups(_selectedLocationGroups);
			}

			updateAnalyticsFiltersState({
				currentFilters: _currentFilters
			});

			if (field === "location_group_id") {
				trackEvent("analytics_filter_loc_grp_selected", { module });
			}
			// track event
			trackFiltersEvent(_currentFilters);
		};

		const handleCheckAll = (state, field, options, props) => {
			// redux state updates
			let _currentFilters = { ...currentFilters };
			let _optionUpdates = { ...optionUpdates };
			if (field === "location_id" && state) {
				_currentFilters["location_group_id"] = ["all"];
				_optionUpdates["location_group_id"] = {};
				setSelectedLocationGroups([]);
				setSelectedLocations([]);
			}

			const _isCheckedAllUpdates = { ...isCheckedAll };

			_isCheckedAllUpdates[field] = state;
			_currentFilters[field] = state ? ["all"] : [];
			_optionUpdates[field] = {};

			setOptionUpdates(_optionUpdates);
			setIsCheckedAll(_isCheckedAllUpdates);

			if (field === "location_id") {
				setSelectedLocations([]);
			}
			if (field == "location_group_id") {
				setSelectedLocationGroups([]);
			}

			updateAnalyticsFiltersState({
				currentFilters: _currentFilters
			});
		};

		const trackFiltersEvent = (data = []) => {
			const filtersApplied = {};
			Object.keys(data).forEach((key) => {
				const entityArr = data[key] || [];
				entityArr.forEach((entity) => {
					if (entity?.id !== "all") {
						filtersApplied[key.split("_")[0]] = true;
					}
				});
			});
			if (Object.keys(filtersApplied).length > 0) {
				trackEvent("analytics_filter_applied", { ...filtersApplied, module });
			}
		};

		const handleFetchViewOptions = (entity, offsetZero = false, setSelectedOnTop = false) => {
			if (entity == "location_group_id") {
				fetchLocationGroupsItems(offsetZero);
			} else if (entity == "location_id") {
				fetchLocations(offsetZero);
			}
		};

		let optionValues = [
			{
				groupItem: "Location Groups",
				options: searchVal
					? (searchedLocationGroups || [])?.filter((locGroup) => locGroup.id !== "all")
					: (locationGroups.items || [])?.filter((brand) => brand.id !== "all"),
				isLoading: locationGroups.loading,
				error: locationGroups?.error,
				showSelectAllOption: false,
				field: "location_group_id",
				labelKey: "title",
				valueKey: "id",
				ellipsizedLength: 27,
				customOptions: false,
				multi: true,
				showCheckBox: true,
				showIcon: false,
				handleCheckAll: handleCheckAll,
				handleFetchViewOptions: handleFetchViewOptions,
				iconKey: "image",
				maxSelectable: 15,
				showOnlyCount: false,
				countDisplayText: "location group",
				viewAllBtn: true,
				maxViewLimit: searchVal.length > 0 ? 5 : locationGroups?.items.length,
				setOffsetToZero: true,
				viewMore:
					locationGroups?.count &&
					locationGroups?.offset + locationGroups.limit <= locationGroups?.count &&
					(locationGroups?.items || []).length < locationGroups?.count &&
					locationGroups?.items.length < 60
						? true
						: false,
				isSearchExternal: true,
				emptyInfoText: "No groups marked for use in filters. Update group settings to show them here "
			},
			{
				groupItem: "Locations",
				options: searchVal
					? searchedLocations?.filter(
							(store) =>
								store.id !== "all" &&
								(filteredBrandLocations?.length > 0
									? filteredBrandLocations?.includes(store.name)
									: store)
						) || []
					: locationsData?.items?.filter(
							(store) =>
								store.id !== "all" &&
								(filteredBrandLocations?.length > 0
									? filteredBrandLocations?.includes(store.name)
									: store)
						) || [],
				isLoading: locationsData?.loading || isSelectFilterOperationsLoading,
				showSelectAllOption: true,
				field: "location_id",
				labelKey: "name",
				valueKey: "id",
				ellipsizedLength: 27,
				customOptions: false,
				multi: true,
				showCheckBox: true,
				handleCheckAll: handleCheckAll,
				iconKey: "image",
				maxSelectable: 15,
				countDisplayText: "location",
				isSearchExternal: true,
				viewAllBtn: true,
				maxViewLimit: searchVal.length > 0 ? 5 : locationsData?.items.length,
				setOffsetToZero: true,
				viewMore:
					locationsData?.count &&
					locationsData?.offset + locationsData.limit <= locationsData?.count &&
					(locationsData?.items || []).length < locationsData?.count &&
					locationsData.items.length <= 200
						? true
						: false,
				isSearchExternal: true,
				handleFetchViewOptions: handleFetchViewOptions
			}
		];
		const excalamtoryIcon = <ExclamatoryIcon fill="#E5B917" width={"24px"} height={"24px"} />;
		const isLocationOverlapping = analyticsFiltersState?.isLocationOverlapping;

		const [showIndicator, setShowIndicator] = useState(true);
		const firstRender = useRef(true);

		useEffect(() => {
			if (firstRender.current) {
				firstRender.current = false;
				return;
			}
			if (!showIndicator) {
				setShowIndicator(true);
			}
		}, [Object.keys(appliedFilters).reduce((acc, f) => acc + appliedFilters[f]?.join(""), "")]);
		const handleClose = () => {
			handleCloseIndicator();
			setShowIndicator(false);
		};
		const handleCustomLocationsLabel = (options) => {
			const locationGroupsKeysLen = Object.keys(optionUpdates["location_group_id"] || {}).filter(
				(obj) => optionUpdates["location_group_id"][obj]
			).length;
			const locationKeysLen = Object.keys(optionUpdates["location_id"]).filter(
				(obj) => optionUpdates["location_id"][obj]
			).length;

			const renderText = options.reduce((acc, opt) => {
				const currObj = optionUpdates?.[opt?.field] || {};
				const count = Object.keys(optionUpdates?.[opt?.field] || {}).filter((obj) => {
					return currObj[obj];
				}).length;
				if (count > 0) {
					acc += `${opt?.field == "location_id" && locationGroupsKeysLen > 0 ? "," : ""} ${count} ${
						opt?.countDisplayText
					}${count > 1 ? "s" : ""}`;
				} else {
					if (
						(opt?.field === "location_id" &&
							locationKeysLen == 0 &&
							locationGroupsKeysLen == 0 &&
							isCheckedAll["location_id"]) ||
						(opt?.field === "location_id" &&
							locationGroupsKeysLen == 0 &&
							!isCheckedAll["location_id"] &&
							locationKeysLen == 0)
					) {
						acc += `${acc.length > 0 ? " " : ""}All Locations`;
					}
				}
				return acc;
			}, "");
			return renderText + " selected";
		};
		const locationFilterRef = useRef();

		const handleOpen = () => {
			locationFilterRef.current.handleChange();
		};

		if (showBrands || showLocations || showPlatforms || showDateCompare) {
			return (
				<div className="analytics-filters-wrap">
					<div className="analytics-filters" ref={filtersContainerRef}>
						<div className="filters-left">
							{isMultibrandEnabled && showBrands && (
								<React.Fragment>
									<SelectFilterCustom
										options={
											brandsData.items?.filter(
												(brand) =>
													brand.id !== "all" &&
													(filteredBrands?.length > 0
														? filteredBrands?.includes(brand.name)
														: brand)
											) || []
										}
										field={"brand_id"}
										isLoading={brandsData.isLoading}
										labelKey={"name"}
										valueKey={"id"}
										iconKey={"image"}
										multi={true}
										showCheckBox={true}
										requiredLabel={true}
										showSelectAllOption
										showIcon={true}
										ellipsizedLength={40}
										optionUpdates={optionUpdates}
										handleCheck={handleCheck}
										isCheckedAll={isCheckedAll}
										handleCheckAll={handleCheckAll}
										placeholder={"Select brands"}
										selectAllPlaceholder={"All Brands"}
										countDisplayText={"brand"}
										handleSelectFilterState={handleSelectFilterState}
									/>
								</React.Fragment>
							)}
							{showLocations && (
								<SelectFilterCustomGroup
									globalLoading={
										isFilterLocationGroupLoading ||
										(locationsData.items.length == 0 && locationsData?.loading && !searchVal) ||
										(locationGroups.items.length == 0 && locationGroups?.loading && !searchVal) ||
										(isSelectFilterOperationsLoading && !searchVal)
									}
									renderCustomLabel={handleCustomLocationsLabel}
									customLabel
									groupedOptions={optionValues}
									isCheckedAll={isCheckedAll}
									selectAllPlaceholder="select Location, Location group"
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									handleSearch={handleSearchLocations}
									handleSelectFilterState={handleSelectFilterState}
									classes="location-and-group-analytics"
									ref={locationFilterRef}
								/>
							)}
							{showPlatforms && (
								<SelectFilterCustom
									options={platformsData.items?.filter((plf) => plf.id !== "all") || []}
									field={"platform_names"}
									labelKey={"platformName"}
									valueKey={"id"}
									iconKey={"image"}
									multi={true}
									showCheckBox={true}
									requiredLabel={true}
									showSelectAllOption
									showIcon={true}
									ellipsizedLength={40}
									isLoading={platformsData.isLoading}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									placeholder={"Select platforms"}
									selectAllPlaceholder="All Platforms"
									countDisplayText={"platform"}
									handleSelectFilterState={handleSelectFilterState}
								/>
							)}
						</div>
						<div className="filters-right" ref={filtersRightRef}>
							{showDateCompare && (
								<NewDateCompareFilter
									showDropdown={true}
									loading={loading}
									currentDateFilter={currentDateFilter}
									appliedDateFilter={appliedDateFilter}
									updateState={updateAnalyticsFiltersState}
									position={isDateCompareFilterWrapped ? "left" : "right"}
									module={module}
									includeAllTime={includeAllTime}
								/>
							)}
						</div>
					</div>
					{showIndicator && isLocationOverlapping && (
						<Indicator
							icon={excalamtoryIcon}
							header="Your current selection contains overlapping locations. "
							description="Data has been aggregated to avoid duplication."
							setShowIndicator={setShowIndicator}
							handleClose={handleClose}
							suggestion={"Change Selection"}
							combineHeaderAndDesc
							showCloseBtn
							headerVisibilityToggle={analyticsFiltersState.headerVisibilityToggle}
							hideHeadText
							clickHandler={handleOpen}
						/>
					)}
				</div>
			);
		}
		return null;
	}
);
export default connect((store) => ({
	brands: store.configItems.brands,
	stores: store.configItems.stores,
	bizPlatforms: store.configItems.bizPlatforms,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	access: store.login.loginDetail.access,
	associatedLocations: store.login.loginDetail.associatedLocations,
	analyticsFiltersState: store.analyticsFiltersState,
	dimensions: store.configItems.dimensions
}))(Filters);

export const BreakdownByFilter = ({
	currValue,
	setFilter,
	labelKey = "label",
	valueKey = "value",
	isMultibrandEnabled = false,
	readOnly = false
}) => {
	if (!isMultibrandEnabled) {
		return null;
	}
	return (
		<SelectFilter
			options={[
				{ label: "Platform", value: "platform" },
				{ label: "Brand", value: "brand" }
			]}
			currValue={currValue}
			field="breakdownBy"
			setFilter={setFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Breakdown By <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			readOnly={readOnly}
			placeholder="Select breakdown by"
		/>
	);
};

export const CompareFilter = ({
	currValue,
	setFilter,
	labelKey = "label",
	valueKey = "value",
	options = [],
	module,
	metric,
	readOnly = false
}) => {
	const handleSetFilter = (field, value) => {
		setFilter(field, value);
		// track event
		trackEvent("analytics_compare_filter", {
			module,
			metric,
			compare: value.label
		});
	};
	return (
		<SelectFilter
			options={options}
			currValue={currValue}
			field="compare"
			setFilter={handleSetFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Compare <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			placeholder="Select compare"
			readOnly={readOnly}
		/>
	);
};

export const CategoryFilter = ({
	isLoading = false,
	options,
	currValue,
	setFilter,
	labelKey = "name",
	valueKey = "id"
}) => {
	useEffect(() => {
		fetchItemCategories(true);
	}, []);

	return (
		<SelectFilter
			isLoading={isLoading}
			options={options}
			currValue={currValue}
			field="category"
			setFilter={setFilter}
			isClearable={false}
			customDropdownLabel={
				currValue ? (
					<div className="custom-value">
						Category <span>{currValue[labelKey]}</span>
					</div>
				) : null
			}
			labelKey={labelKey}
			valueKey={valueKey}
			placeholder="Select catagory"
		/>
	);
};
