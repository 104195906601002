import React from "react";

const MagicWand = ({ height = 14, width = 14, fill = "#2543B6" }) => {
	return (
		<>
			<svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M3.71887 1.6875L3.61505 2.4115C3.54671 2.88962 3.17087 3.26546 2.69275 3.3338L1.96875 3.43762V3.87488L2.69275 3.9787C3.17087 4.04704 3.54671 4.42288 3.61505 4.901L3.71887 5.625H4.15613L4.25995 4.901C4.32829 4.42288 4.70413 4.04704 5.18225 3.9787L5.90625 3.87488V3.43762L5.18225 3.3338C4.70413 3.26546 4.32829 2.88962 4.25995 2.4115L4.15613 1.6875H3.71887ZM8.71875 1.6875L8.65228 2.15277C8.60841 2.46018 8.36698 2.70216 8.05957 2.74603L7.59375 2.8125V3.09375L8.05902 3.16022C8.36643 3.20409 8.60841 3.44552 8.65228 3.75293L8.71875 4.21875H9L9.06647 3.75348C9.11034 3.44607 9.35177 3.20409 9.65918 3.16022L10.125 3.09375V2.8125L9.65973 2.74603C9.35232 2.70216 9.11034 2.46073 9.06647 2.15332L9 1.6875H8.71875ZM13.6906 2.2511C13.5909 2.25579 13.5116 2.28645 13.4561 2.34174C13.4557 2.3421 13.4553 2.34247 13.455 2.34283L2.29834 13.4995C1.85903 13.9388 1.85903 14.6515 2.29834 15.0908L3.19043 15.9829C3.62974 16.4225 4.34194 16.4225 4.78125 15.9829L15.9379 4.82629C15.9382 4.82593 15.9386 4.82556 15.939 4.8252C16.0535 4.71066 16.0609 4.49643 15.96 4.22033C15.859 3.94424 15.6562 3.624 15.3873 3.31615C15.1184 3.0083 14.8007 2.73262 14.4903 2.53775C14.1799 2.34289 13.8966 2.24136 13.6906 2.2511ZM13.7983 3.59088C14.0425 3.92122 14.3606 4.23892 14.6909 4.48242L10.7358 8.4375L9.8432 7.54541L13.7983 3.59088ZM13.5 10.9688L13.3671 11.8998C13.2793 12.5147 12.7959 12.9981 12.1811 13.0858L11.25 13.2188V13.7813L12.1811 13.9142C12.7959 14.0019 13.2793 14.4853 13.3671 15.1002L13.5 16.0313H14.0625L14.1954 15.1002C14.2832 14.4853 14.7666 14.0019 15.3814 13.9142L16.3125 13.7813V13.2188L15.3814 13.0858C14.7666 12.9981 14.2832 12.5147 14.1954 11.8998L14.0625 10.9688H13.5Z"
					fill={fill}
				/>
			</svg>
		</>
	);
};

export default React.memo(MagicWand);
