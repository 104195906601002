import React from "react";

const ClockIcon = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		style={{ ...styles }}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M9.99984 1.66663C5.39734 1.66663 1.6665 5.39746 1.6665 9.99996C1.6665 14.6025 5.39734 18.3333 9.99984 18.3333C14.6023 18.3333 18.3332 14.6025 18.3332 9.99996C18.3332 5.39746 14.6023 1.66663 9.99984 1.66663ZM12.744 13.9225L9.1665 10.345V4.99996H10.8332V9.65496L13.9223 12.7441L12.744 13.9225Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(ClockIcon);
