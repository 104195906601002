import React from "react";

const UpdateIcon = ({ styles = {}, variations = {}, color = "#6D7AA8" }) => (
	<svg
		style={{ ...styles }}
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...variations}
	>
		<path
			d="M10 0L6.66667 3.33333L10 6.66667V4.16667C13.2158 4.16667 15.8333 6.78333 15.8333 10C15.8333 10.7333 15.691 11.4334 15.4427 12.0801L16.7057 13.3415C17.2099 12.334 17.5 11.2017 17.5 10C17.5 5.865 14.1358 2.5 10 2.5V0ZM3.29427 6.65853C2.7901 7.66603 2.5 8.79833 2.5 10C2.5 14.135 5.86417 17.5 10 17.5V20L13.3333 16.6667L10 13.3333V15.8333C6.78417 15.8333 4.16667 13.2167 4.16667 10C4.16667 9.26667 4.30896 8.56659 4.55729 7.91992L3.29427 6.65853Z"
			fill={color}
		/>
	</svg>
);

export default React.memo(UpdateIcon);
