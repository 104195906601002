import React from "react";

// components
import { Button } from "../_commons/Button";
import { BackNavigation } from "./BackNavigation";

// third party
import { useTrail, config, animated } from "react-spring";

export const BulkOperationFailure = ({
	title,
	subTitle,
	backActionTitle = "Back",
	handleBackAction,
	uploadedFileName = "",
	downloadErrorFile,
	uploadNewFile,
	headerFields = {},
	data = [],
	loading,
	downloading,
	hideDownloadAction = false,
	disabled = false
}) => {
	return (
		<div className={"bulk-operation-failure " + (downloading ? "downloading" : "")}>
			<BackNavigation title={backActionTitle} clickHandler={handleBackAction} />
			<Header title={title} subTitle={subTitle} />
			<div className="uploaded-file">
				<div className="header">
					<div className="header-text">Uploaded File</div>
					<div className="subheader-text">
						<span className="uploaded-file-icon">
							<img src="/assets/icons/paper-clip.svg" alt="" />
						</span>
						<span>{uploadedFileName || "--"}</span>
						<span className="uploaded-file-status failure">
							{data.length > 0
								? `${data.length} ${data.length > 1 ? "Errors Found" : "Error Found"}`
								: "No Errors Found"}
						</span>
					</div>
				</div>
			</div>
			<div className="errors-found">
				<div className="header">
					<div className="header-text">Errors Found</div>
					<div className="subheader-text">
						Please correct these errors and try again.
						<span className="header-action-button">
							{!hideDownloadAction && (
								<div className="action-buttons-container">
									<Button clickHandler={downloadErrorFile} classes={disabled ? "disabled" : ""}>
										Download Error File
									</Button>
								</div>
							)}
							<div className="action-buttons-container">
								<Button
									clickHandler={uploadNewFile}
									classes={disabled ? "disabled" : ""}
									type={!hideDownloadAction ? "secondary" : ""}
								>
									Upload New File
								</Button>
							</div>
						</span>
					</div>
				</div>
				<Table loading={loading} headerFields={headerFields} data={data} />
			</div>
		</div>
	);
};

const Header = ({ title, subTitle }) => (
	<div className="credits-section-header transactions-list-header">
		<div className="header-text">
			<div className="title" title={title || ""}>
				{title || "--"}
			</div>
			<div className="subtitle" title={subTitle || ""}>
				{subTitle || "--"}
			</div>
		</div>
	</div>
);

const Table = ({ loading, headerFields, data }) => {
	const trails = useTrail(data.length, {
		config: config.stiff,
		from: {
			rotate: -90
		},
		rotate: 0
	});
	return (
		<div
			className={
				(data.length > 0 && loading ? "disabled" : "") +
				" transaction-table-holder common-table-container template-table-container"
			}
		>
			<div className="transactions-list-table bordered">
				<div className="at-table-row-based">
					<TableHeader {...headerFields} />
					{trails.map(({ rotate }, i) => (
						<TableList
							key={i}
							style={{
								transform: rotate.interpolate((rt) => `rotate3d(1, 0, 0, ${rt}deg)`)
							}}
							{...data[i]}
						/>
					))}
					{data.length === 0 && !loading && <div className="no-items-placeholder">No Errors found!</div>}
					{data.length === 0 && loading && (
						<div className="P(10px)">
							<div className="shimmer H(60px) Mb(10px)" />
							<div className="shimmer H(60px) Mb(10px)" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const TableHeader = ({ title, name, reason, invalid_headers }) => (
	<div className={`at-table-row transaction-header-row`}>
		{title && <div className="at-table-cell at-table-header at-header-text title">Title</div>}
		{name && <div className="at-table-cell at-table-header at-header-text name">Name</div>}
		{reason && <div className="at-table-cell at-table-header at-header-text reason">Reason</div>}
		{invalid_headers && (
			<div className="at-table-cell at-table-header at-header-text invalid-headers">Invalid Headers</div>
		)}
		{!title && !name && !reason && !invalid_headers && (
			<div className="at-table-cell at-table-header at-header-text errors">Errors</div>
		)}
	</div>
);

const TableList = ({ title, name, reason, invalid_headers, style }) => {
	return (
		<animated.div
			// style={style}
			className="at-table-row transaction-rows"
		>
			{title && (
				<div className="at-table-cell at-cell-text title" title={title || ""}>
					{title || "--"}
				</div>
			)}
			{name && (
				<div className="at-table-cell at-cell-text name" title={name || ""}>
					{name || "--"}
				</div>
			)}
			{reason && (
				<div className="at-table-cell at-cell-text reason" title={reason || ""}>
					{reason || "--"}
				</div>
			)}
			{invalid_headers && (
				<div className="at-table-cell at-cell-text invalid-headers" title={invalid_headers || ""}>
					{invalid_headers || "--"}
				</div>
			)}
			{!title && !name && !reason && !invalid_headers && (
				<div className="at-table-cell at-cell-text errors">Errors found</div>
			)}
		</animated.div>
	);
};
