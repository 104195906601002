import React, { useState } from "react";

// components
import { Link } from "react-router-dom";
import { Button } from "../_commons/Button";
import { SelectFilter } from "../_commons/SelectFilter";
import { SearchFilter } from "../_commons/SearchFilter";

//third party
import { HorizonBanner } from "@urbanpiper-engineering/horizon";

// assets
import CreateIcon from "../_commons/CreateIcon";

export const Header = (props) => {
	const [showBanner, setShowBanner] = useState(true);

	return (
		<>
			{
				/* UNS Transition Banners */
				showBanner && (
					<HorizonBanner
						bannerHeading={
							"Heads up! 'Platform Discounts' will soon move to 'Marketing' to simplify navigation."
						}
						onClose={() => setShowBanner(false)}
						className="UNSTransitionBanner"
						status="info"
					/>
				)
			}
			<div className="credits-section-header transactions-list-header">
				<div className="header-text">
					<div className="title">Platform Discounts</div>
					<div className="subtitle">Central repository for all your discounts on aggregator channels</div>
				</div>
				<div className="header-action-button">
					<div className="filter-buttons">
						{props.dimensions.width > 768 && (
							<div
								className={
									(props.filterActive ? "active" : "") + " filter-in-header campaign-list-filter"
								}
							>
								<div className="container" onClick={props.flipShowFilters}>
									<img className="filter-icon" src="/assets/icons/icon-sorting-options.svg" alt="" />
									<div className="filter-title">
										Filter
										{props.filterCount > 0 && (
											<span className="filter-count">{props.filterCount}</span>
										)}
									</div>
								</div>
							</div>
						)}
						<div className="action-buttons-container">
							<Link to="/discounts/new">
								<Button>
									<CreateIcon />
									<span>Add Discount</span>
								</Button>
							</Link>
						</div>
						<div className="action-buttons-container dropdown">
							<SelectFilter
								options={props.platformsList}
								field="selectedPlatform"
								currValue={props.selectedPlatform}
								setFilter={props.setPlatform}
								labelKey="valueForDisplay"
								valueKey="value"
								isClearable={false}
								placeholder="Select platform"
							/>
						</div>
						{props.dimensions.width > 768 && (
							<SearchFilter
								filterOption={props.filterOption}
								value={props.value}
								setFilter={props.setFilter}
								placeholder="Search by name"
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
