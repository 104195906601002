import React, { useEffect, useMemo, useRef, useState } from "react";
import CreateIcon from "../../_commons/CreateIcon";
import { connect } from "react-redux";
import { fetchBizPlatforms, fetchBrands } from "../../../actions/analytics";
import SelectInput from "./SelectInput";
import { ButtonIcon } from "../../_commons/ButtonIcon";
import { store } from "../../../store/configureStore";
import { extractInitials, nthFyDate, trackEvent } from "../../../atlas-utils";
import Store from "../../_icons/Store";
import Item from "../../_icons/Item";
import Platform from "../../_icons/Platform";
import Brand from "../../_icons/Brand";
import { fetchItems, fetchLocationGroups, fetchLocationList } from "../../../actions/analytics";

const EnterLocation = ({
	colIndex,
	configItems,
	metric,
	compareAnalytics,
	handleSelect,
	setDropDownStatus,
	dropDownStatus,
	analyticsFiltersState
}) => {
	const columnText = compareAnalytics.columnData[metric]?.data;
	const count = compareAnalytics.columnData[metric]?.count;

	let columnData = compareAnalytics.columnData[metric]?.data;

	let tableData = compareAnalytics.tableData?.[metric];

	const brandsArray = configItems?.brands?.items;
	const bizPlatformsArray = configItems?.bizPlatforms?.items;
	const itemsArray = compareAnalytics?.itemList?.objects;
	const loadingColumnData = compareAnalytics?.columnData?.loading;
	const [search, setSearch] = useState("");

	const [locationsData, setLocationsData] = useState({
		limit: 50,
		offset: 0,
		items: [],
		loading: false,
		error: false
	});
	const [locationGroups, setLocationGroups] = useState({
		limit: 3,
		offset: 0,
		items: [],
		loading: false,
		error: false
	});
	const [searchedlocations, setSearchedLocations] = useState({ error: false, loading: false, items: [] });
	const [searchedLocationGroups, setSearchedLocationGroups] = useState({ error: false, loading: false, items: [] });

	const fetchAllLocations = async (offsetZero = false) => {
		if (
			(locationsData?.count && locationsData.offset + locationsData.limit <= locationsData?.count) ||
			locationsData.items.length == 0 ||
			offsetZero
		)
			setLocationsData({ ...locationsData, loading: true, error: false });
		try {
			let limit = locationsData.limit;
			let offset = offsetZero
				? 0
				: (locationsData?.items || []).length > 0
					? locationsData.offset + limit
					: locationsData.offset;

			const resp = await fetchLocationList("", limit, offset, false);
			offset = resp.data.stores.offset;
			limit = resp.data.stores.limit;
			let items = offset == 0 ? resp.data.stores.objects : [...locationsData.items, ...resp.data.stores.objects];
			setLocationsData({
				...locationsData,
				offset,
				items,
				loading: false,
				error: false,
				count: resp.data.stores.count
			});
		} catch (err) {
			setLocationsData({ ...locationsData, loading: false, error: true });
		}
	};

	const fetchAllLocationGroups = async (offsetZero = false) => {
		if (
			(locationGroups?.count && locationGroups.offset + locationGroups.limit <= locationGroups?.count) ||
			locationGroups.items.length == 0 ||
			offsetZero
		) {
			setLocationGroups({ ...locationGroups, loading: true, error: false });
			try {
				let limit = locationGroups.limit;
				let offset = offsetZero
					? 0
					: (locationGroups?.items || []).length > 0
						? locationGroups.offset + limit
						: locationGroups.offset;

				const resp = await fetchLocationGroups(limit, offset);
				let items;
				let objects = resp.data.locationGroups.objects;
				items = offset == 0 ? objects : [...locationGroups.items, ...objects];
				offset = resp.data.locationGroups.offset;
				setLocationGroups({
					...locationGroups,
					offset,
					items,
					loading: false,
					error: false,
					count: resp.data.locationGroups.count
				});
			} catch (err) {
				setLocationGroups({ ...locationGroups, loading: false, error: true });
			}
		}
	};

	const handleFetchViewOptions = (args) => {
		if (args == "Locations") {
			fetchAllLocations();
		} else if (args === "Location Groups") {
			fetchAllLocationGroups();
		}
	};
	const dataObject = {
		brand: {
			searchText: true,
			details: [
				{
					info: brandsArray,
					displayImages: true,
					valueForDisplay: "name",
					value: "id",
					image: "image",
					loading: configItems?.brands?.isLoading,
					error: configItems?.brands?.error
				}
			]
		},
		platform: {
			searchText: true,
			details: [
				{
					info: bizPlatformsArray,
					valueForDisplay: "platformName",
					value: "id",
					image: "image",
					displayImages: true,
					loading: configItems?.bizPlatforms?.isLoading,
					error: configItems?.bizPlatforms?.error
				}
			]
		},
		item: {
			searchText: true,
			details: [
				{
					info: itemsArray,
					valueForDisplay: "title",
					value: "id",
					displayImages: false,
					loading: compareAnalytics?.itemList?.loading,
					error: compareAnalytics?.itemList?.error
				}
			]
		},
		location: {
			searchText: true,
			details: [
				{
					heading: "Location Groups",
					info: search == "" ? locationGroups?.items || [] : searchedLocationGroups?.items || [],
					valueForDisplay: "title",
					value: "id",
					anotherValueDisplay: "city",
					loading: search == "" ? locationGroups?.loading : searchedLocationGroups?.loading,
					viewMoreBtn:
						locationGroups?.count &&
						locationGroups?.offset + locationGroups.limit <= locationGroups?.count &&
						(locationGroups?.items || []).length < locationGroups?.count &&
						locationGroups?.items.length < 60
							? true
							: false,
					handleFetchViewOptions: handleFetchViewOptions,
					error: locationGroups?.error,
					emptyInfoText: "No groups marked for use in filters. Update group settings to show them here "
				},
				{
					heading: "Locations",
					info: search == "" ? locationsData?.items || [] : searchedlocations?.items || [],
					valueForDisplay: "title",
					value: "id",
					anotherValueDisplay: "city",
					loading: search == "" ? locationsData?.loading : searchedlocations?.loading,
					viewMoreBtn:
						locationsData?.count &&
						locationsData?.offset + locationsData.limit <= locationsData?.count &&
						(locationsData?.items || []).length < locationsData?.count &&
						locationsData?.items.length <= 200
							? true
							: false,
					handleFetchViewOptions: handleFetchViewOptions,
					error: locationsData?.error
				}
			]
		}
	};

	const dropDownMetric = { [metric]: { ...dataObject?.[metric] } };

	const headerRef = useRef(null);

	const fetchConfig = () => {
		const fetchFunctions = {
			brand: () => fetchBrands("", true),
			platform: () => fetchBizPlatforms(false, true, true),
			item: () => fetchItems("", 50, 0),
			location: () => {
				fetchAllLocations(true);
				fetchAllLocationGroups(true);
			}
		};
		const fetchFunction = fetchFunctions[metric];
		if (fetchFunction) {
			fetchFunction();
		}
	};
	useEffect(() => {
		fetchConfig();
		setSearch("");
	}, [dropDownStatus]);
	const columnNames =
		compareAnalytics?.columnData?.[metric]?.data?.slice(1).map((curr) => curr?.["valueForDisplay"]) || [];

	const handleClose = (event, index) => {
		event.stopPropagation();
		const removedCol = columnData.splice(index, 1);
		let value = columnNames.reduce((acc, curr) => {
			if (removedCol?.[0]?.["valueForDisplay"] !== curr) {
				acc[curr] = tableData[curr];
			}
			return acc;
		}, {});

		store.dispatch({
			type: "UPDATE_COMPARE_ANALYTICS_CHANGE_COLUMN_DATA",
			payload: {
				metric,
				data: columnData,
				count: count - 1
			}
		});

		store.dispatch({
			type: "DELETE_COMPARE_ANALYTICS_TABLE_DATA",
			payload: { metric, data: value }
		});
		const { initialFiltersFromUrl } = analyticsFiltersState;
	};

	const images = {
		location: (variations) => <Store variations={variations} />,
		item: (variations) => <Item variations={variations} />,
		platform: (variations) => <Platform variations={variations} />,
		brand: (variations) => <Brand variations={variations} />
	};

	const handleOption = (image = "", color = "", name) => {
		if (name === "Prime") {
			image = "/assets/icons/prime-logo.svg";
		}
		return (
			<React.Fragment>
				<div className={"main-logo " + color}>
					{image ? <img src={image} alt="" /> : extractInitials(name?.split(" "))}
				</div>
			</React.Fragment>
		);
	};

	if (colIndex == 0) {
		return <div className="table-location-header main-text location">{columnText[0]}</div>;
	} else {
		const valueForDisplay = columnText[colIndex]?.valueForDisplay;
		return (
			<>
				<div className={`table-location-header ${metric ? metric : ""} `}>
					{valueForDisplay ? (
						<div
							className={` ${dropDownStatus == colIndex ? "dropdown-top" : ""}  main-text   `}
							onClick={() => handleSelect(colIndex)}
						>
							<div className="text-img">
								{metric == "platform" || metric == "brand" ? (
									<>
										{handleOption(
											columnText[colIndex]?.image,
											columnText[colIndex]?.color,
											valueForDisplay
										)}
									</>
								) : (
									<>
										<span className="img">{images?.[metric]({ fill: "#363636" })}</span>
									</>
								)}
								{valueForDisplay}
							</div>
							<div className="close-icon" onClick={(event) => handleClose(event, colIndex)}>
								<ButtonIcon icon={"close"} color={"#374F78"} />
							</div>
						</div>
					) : (
						<>
							{loadingColumnData ? (
								<div className={`${"shimmer"} W${100}%`} style={{ height: "33px" }} />
							) : (
								<div className="adding-data" onClick={() => handleSelect(colIndex)}>
									<div className="create">
										<CreateIcon color={"#8A8A8A"} />
										<span>{` Add ${nthFyDate(colIndex)} ${metric}`}</span>
									</div>
									<span className="menus-icon-div">{images?.[metric]({ fill: "#AAAAAA" })}</span>
								</div>
							)}
						</>
					)}

					{dropDownStatus == colIndex && (
						<div ref={headerRef}>
							{
								<SelectInput
									dropDownMetric={dropDownMetric}
									metric={metric}
									inputBox={true}
									setDropDownStatus={setDropDownStatus}
									dropDownStatus={dropDownStatus}
									colIndex={colIndex}
									searchedlocations={searchedlocations}
									setSearchedLocationGroups={setSearchedLocationGroups}
									searchedLocationGroups={searchedLocationGroups}
									setSearchedLocations={setSearchedLocations}
									search={search}
									setSearch={setSearch}
								/>
							}
						</div>
					)}
				</div>
			</>
		);
	}
};

const mapStateToProps = (state) => ({
	configItems: state?.configItems,
	compareAnalytics: state.compareAnalytics,
	analyticsFiltersState: state.analyticsFiltersState
});

export default connect(mapStateToProps)(EnterLocation);
