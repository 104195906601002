import React from "react";
import { Modal } from "../_commons/Modal";
import { capitaliseText } from "../../atlas-utils";

const ConflictingRuleModal = ({
	isOpen,
	handleReviewConflicts = {},
	handleDiscardNewRule = {},
	latestModifiedRuleDetails
}) => {
	const getConflictingRuleString = (data = []) => {
		const uniqueData = Array.from(new Map(data.map((item) => [item.ruleId, item])).values());
		let str = "";
		const tempData = uniqueData.map((conflictObj) => capitaliseText(conflictObj?.ruleName));
		if (tempData?.length > 1) {
			str = tempData?.join(", ");
		} else if (tempData?.length) {
			str = tempData?.[0];
		}
		return str;
	};
	return (
		<Modal
			isOpen={isOpen}
			classes="rules-conflicting-modal-container"
			showDeleteAction={true}
			disabled={false}
			deleteAction={handleDiscardNewRule}
			deleteTitle="Discard New Rule"
			showCancelAction
			cancelTitle="Review Conflicts"
			cancelAction={handleReviewConflicts}
			title={
				<div className="error-rule-header">
					<img src="/assets/icons/error-red-icon.svg" />
					<p>Conflicting Rules</p>
				</div>
			}
		>
			<div className="rules-conflicting-modal-content">
				<p>This rule conflicts with {latestModifiedRuleDetails?.conflicts?.length} existing rule</p>
				<div className="conflict-list">
					<p>
						<span>New Rule : </span> {capitaliseText(latestModifiedRuleDetails?.ruleObject?.name)}
					</p>
					<p>
						<span>Conflicting Rule : </span>{" "}
						{getConflictingRuleString(latestModifiedRuleDetails?.conflicts)}
					</p>
				</div>
				<p>Resolve the conflict or discard the new rule. </p>
			</div>
		</Modal>
	);
};

export default ConflictingRuleModal;
