import moment from "moment";
import { trackEvent } from "../atlas-utils";

export const removeTrailingZeros = (number) => {
	return parseFloat(number).toString();
};

export const getBrandDetail = (brandId, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => brand?.id == brandId);
	}
	return null;
};

export const getPlatformDetailViaName = (platformName, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((plt) => plt?.platformName?.toLowerCase() == platformName?.toLowerCase());
	}
	return null;
};

export const getBrandDetailViaName = (brandName, brands) => {
	if (brands && brands.length > 0) {
		return brands.find((brand) => (brand?.name).toLowerCase() == brandName.toLowerCase());
	}
	return null;
};

export const getPlatformNames = (bizPlatforms, lowercase = false) => {
	if (bizPlatforms && bizPlatforms.length > 0) {
		return bizPlatforms
			.map((platform) => {
				if (lowercase) {
					if (platform?.platformName.toLowerCase() == "all platforms") {
						return null;
					}
					return platform?.platformName.toLowerCase();
				} else {
					return platform?.platformName;
				}
			})
			.filter((name) => name !== null);
	}
};

export const getPlatformsDetailsViaId = (platformId, platforms) => {
	if (platforms && platforms.length > 0) {
		return platforms.find((platform) => platform.id == platformId);
	}
	return null;
};

export const dateFormat = (dateString) => {
	if (dateString) {
		const localDate = moment.utc(dateString).local();
		const currentDate = moment();
		if (localDate.isSame(currentDate, "day")) {
			return `Updated: Today ${localDate.format("h:mm A")}`;
		} else {
			return `Updated: ${localDate.format("D MMMM, h:mm A")}`;
		}
	}
};

export const getFutureDate = (value, unit = "days", format = "D MMM YYYY") => {
	return moment().add(value, unit).format(format);
};

export const roundAndFormatNumber = (number, decimalPlaces) => {
	if (isNaN(number)) return "0";

	let roundedNumber = number.toFixed(decimalPlaces);

	// Remove trailing zeros
	let formattedNumber = roundedNumber.replace(/(\.\d*[1-9])0+$|\.0*$/, "$1");
	return formattedNumber;
};

export const timeToMinutes = (timeStr) => {
	timeStr = String(timeStr).toUpperCase();
	const [time, period] = timeStr.split(/(AM|PM)/);
	const [hours, minutes] = time.split(":").map(Number);
	let totalMinutes = hours % 12;
	if (period === "PM") totalMinutes += 12;
	totalMinutes = totalMinutes * 60 + minutes;
	return totalMinutes;
};

export const minutesToTime = (totalMinutes) => {
	const hours = Math.floor(totalMinutes / 60);
	const minutes = totalMinutes % 60;
	const period = hours >= 12 ? "PM" : "AM";
	const adjustedHours = hours % 12 || 12;
	const formattedMinutes = String(minutes).padStart(2, "0");
	return `${adjustedHours}:${formattedMinutes} ${period}`;
};

function parseTimeRange(startStr, endStr) {
	const startMinutes = timeToMinutes(startStr);
	const endMinutes = timeToMinutes(endStr);

	let adjustedEndMinutes = endMinutes;
	if (endMinutes < startMinutes) {
		adjustedEndMinutes += 24 * 60;
	}

	return [startMinutes, adjustedEndMinutes];
}

const convertDurationToHoursMinutes = (duration) => {
	const hours = Math.floor(duration / 60);
	const minutes = duration % 60;
	if (hours) {
		if (minutes) {
			return `${hours}hr ${minutes}min`;
		}
		return `${hours}hr`;
	}
	return `${minutes}min`;
};

export const getUniqueKeys = (data) => {
	const durations = new Set();
	data.forEach((periodData) => {
		periodData.forEach((entry) => {
			if (entry.duration) {
				durations.add(entry.duration);
			}
		});
	});
	return Array.from(durations);
};

export const getColorByStatus = (status) => {
	switch (status) {
		case "online":
			return "#D9EFE1";
		case "online (Storefront Offline)":
			return "#e1ece3";
		case "offline":
			return "#FFCCCC";
		case "removed":
			return "#F5F5F5";
		case "removed (Storefront Offline)":
			return "#f0e6e3";
		case "unavailable":
			return "#F5F5F5";
		default:
			return "#ffffff";
	}
};

export const transformData = (data, keys) => {
	return data.map((periodData, index) => {
		const transformed = { country: periodData[0].country };
		keys.forEach((key) => {
			const item = periodData.find((item) => item.duration === key);
			transformed["country"] = index;
			if (item) {
				transformed[key] = parseFloat(item.value);
				transformed[`${key}_color`] = getColorByStatus(item.status);
				transformed[`${key}_data`] = item;
			} else {
				transformed[key] = 0;
				transformed[`${key}_color`] = getColorByStatus("data unavailable");
			}
		});
		return transformed;
	});
};

function convertTo12HourFormat(times) {
	const [hours, minutes] = times.split(":");

	const period = hours >= 12 ? "PM" : "AM";
	const formattedHour = hours % 12 || 12;
	return `${formattedHour}:${minutes} ${period}`;
}

export const calculateContinuousPercentages = (chunk, index, timeOption, dataLength) => {
	let segments = [];
	chunk.forEach((data, index) => {
		const startTime = convertTo12HourFormat(data.startTime.split(" ")[1]);
		let endTime = convertTo12HourFormat(data.endTime.split(" ")[1]);
		const day = data.startTime.split(" ")[0];
		if (index == chunk.length - 1) {
			endTime = "11:59PM";
		}
		let [startRange, endRange] = parseTimeRange(startTime, endTime);
		if (index == 0) {
			segments.push({ start_time: startTime, end_time: endTime, status: data.status, startRange, endRange, day });
		} else {
			const lastSegment = segments[segments.length - 1];
			if (lastSegment["end_time"] === startTime && data.status == lastSegment["status"]) {
				lastSegment["end_time"] = endTime;
				lastSegment["endRange"] = endRange;
			} else {
				segments.push({
					start_time: startTime,
					end_time: endTime,
					status: data.status,
					startRange,
					endRange,
					day
				});
			}
		}
	});
	if (
		dataLength == 1 &&
		(timeOption === "last_6_hours" ||
			timeOption === "last_2_hours" ||
			timeOption === "last_1_hour" ||
			timeOption === "last_12_hours")
	) {
		segments = segments.filter((seg) => seg.status !== "out_of_time_range");
	}

	const totalDuration = segments.reduce((total, seg) => total + (seg.endRange - seg.startRange), 0);
	let totalPercentage = 0;
	const percentages = segments.map((seg, index) => {
		const duration = seg.endRange - seg.startRange;
		let percentage = (duration / totalDuration) * 100;

		if (index == segments.length - 1) {
			percentage = 100 - totalPercentage;
		}

		totalPercentage += percentage;

		const durationFormatted = convertDurationToHoursMinutes(duration);
		return {
			value: `${percentage}%`,
			status: seg.status,
			duration: `${seg.start_time} - ${seg.end_time}`,
			start_time: seg.start_time,
			end_time: seg.end_time,
			totalTime: durationFormatted,
			day: seg.day
		};
	});

	return percentages;
};

export const transformDataa = (values) => {
	const keys = new Set();
	const result = values.reduce((currArr, ans, index) => {
		let curr = { country: index };
		ans.forEach((val) => {
			const duration = String(val.duration) + String(index);
			keys.add(duration);
			curr[duration] = parseFloat(val.value);
			curr[duration + "_color"] = getColorByStatus(val.status);
			curr[duration + "_data"] = val;
			curr["day"] = val.day;
		});
		currArr.push(curr);
		return currArr;
	}, []);
	return [result, Array.from(keys)];
};

export const periscopeFilterEvent = (type) => {
	trackEvent("periscope_filter_applied", {
		filter_type: type
	});
};

export const periscopeHelpClicked = (type) => {
	trackEvent("periscope_help_clicked", {
		module: type
	});
};
