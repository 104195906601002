import React from "react";

// component
import CopyIcon from "../_commons/CopyIcon";

// third party
import { connect } from "react-redux";

// client
import { store } from "../../store/configureStore";

// utils
import { capitaliseTextStrict, commifyNumbers, printCurrency } from "../../atlas-utils";

// constants
const AGGREGATOR_DEDUCTIONS_MAP = {
	"Payment mechanism fees": "Payment Mechanism Fees",
	"Logistics charges": "Logistics Charges"
};

const BasicInfo = ({ biz, data, loading, selectedPlatform }) => {
	const isZomatov2 = data?.platformManager === "zomatov2";
	const isZomatov3 = data?.platformManager === "zomatov3";
	const isZomatov4 = data?.platformManager === "zomatov4";

	const copyTextToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		store.dispatch({
			type: "SHOW_GLOBAL_MESSAGE",
			payload: {
				message: `Payout ID has been copied to clipboard.`,
				timeout: 2000,
				error: false
			}
		});
	};

	let chargesRefundSubFields = undefined;
	try {
		chargesRefundSubFields = JSON.parse(data.chargesRefundSubFields);
	} catch {
		chargesRefundSubFields = {};
	}

	return (
		<React.Fragment>
			{loading ? (
				<div className="P(10px)">
					<div className="shimmer H(60px) Mb(10px)" />
					<div className="shimmer H(60px) Mb(10px)" />
				</div>
			) : (
				<div className="form-row basic-info">
					<div className="payouts-info">
						<div className="payouts-info--blocks">
							<div className="sub-title">Payout ID</div>
							<div className="value">
								{data?.id || "--"}
								{data?.id && (
									<span className="copy-icon" onClick={() => copyTextToClipboard(data?.id)}>
										<CopyIcon />
									</span>
								)}
							</div>
						</div>
						<div className="payouts-info--blocks">
							<div className="sub-title">Uploaded By</div>
							<div className="value" title={data?.uploadedBy}>
								{data?.uploadedBy === "auto-forwarding"
									? "Email Auto-forwarding"
									: data?.uploadedBy || "--"}
							</div>
						</div>
					</div>
					<div className="payouts-info">
						<div className="payouts-info--blocks">
							<div className="sub-title">Order Count</div>
							<div className="value">{data?.orderCount || "--"}</div>
						</div>
						<div className="payouts-info--blocks">
							<div className="sub-title">Total Receivable</div>
							<div className="value">
								{(data?.payoutAmount === 0 || data?.payoutAmount) && printCurrency(biz.currencySymbol)}
								&nbsp;
								{commifyNumbers(data?.payoutAmount, null, { minimumFractionDigits: 2 }) || "--"}
							</div>
						</div>
					</div>
					<div className="payout-breakdown">
						<div className="border-block">PAYOUT BREAKDOWN</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">A. Order Total</div>
								<div className="header-value">
									{(data?.orderTotal === 0 || data?.orderTotal) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.orderTotal, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							{(isZomatov2 || isZomatov3 || isZomatov4 || selectedPlatform?.value !== "zomato") && (
								<div className="breakdown-types-values">
									<div>Subtotal</div>
									<div>
										{(data?.orderSubtotal === 0 || data?.orderSubtotal) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.orderSubtotal, null, { minimumFractionDigits: 2 }) ||
											"--"}
									</div>
								</div>
							)}
							{(isZomatov2 || selectedPlatform?.value !== "zomato") && (
								<div className="breakdown-types-values">
									<div>Charges</div>
									<div>
										{(data?.totalCharges === 0 || data?.totalCharges) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.totalCharges, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							)}
							{isZomatov3 ||
								(isZomatov4 && (
									<div className="breakdown-types-values">
										<div>Packaging Charge</div>
										<div>
											{(data?.packingCharge === 0 || data?.packingCharge) &&
												printCurrency(biz.currencySymbol)}
											&nbsp;
											{commifyNumbers(data?.packingCharge, null, { minimumFractionDigits: 2 }) ||
												"--"}
										</div>
									</div>
								))}
							{isZomatov3 ||
								(isZomatov4 && (
									<div className="breakdown-types-values">
										<div>Delivery Charge</div>
										<div>
											{(data?.deliveryCharge === 0 || data?.deliveryCharge) &&
												printCurrency(biz.currencySymbol)}
											&nbsp;
											{commifyNumbers(data?.deliveryCharge, null, { minimumFractionDigits: 2 }) ||
												"--"}
										</div>
									</div>
								))}
							{(isZomatov2 || isZomatov3 || isZomatov4 || selectedPlatform?.value !== "zomato") && (
								<div className="breakdown-types-values">
									<div>Discount</div>
									<div>
										{(data?.discount === 0 || data?.discount) && (
											<span>
												{data?.discount !== 0 && "-"} {printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{commifyNumbers(data?.discount, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							)}
							{(isZomatov2 || isZomatov3 || isZomatov4 || selectedPlatform?.value !== "zomato") && (
								<div className="breakdown-types-values">
									<div>Taxes</div>
									<div>
										{(data?.totalTaxes === 0 || data?.totalTaxes) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.totalTaxes, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							)}
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">B. Commissionable Amount</div>
								<div className="header-value">
									{(data?.commissionableAmount === 0 || data?.commissionableAmount) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.commissionableAmount, null, { minimumFractionDigits: 2 }) ||
										"--"}
								</div>
							</div>
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">
									{isZomatov3 || isZomatov4 ? "C." : "B."}{" "}
									{`${data.platformName ? capitaliseTextStrict(data.platformName) : "Aggregator"}`}{" "}
									Deductions
								</div>
								<div className="header-value">
									{(data?.aggregatorCommissionTotal === 0 || data?.aggregatorCommissionTotal) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.aggregatorCommissionTotal, null, {
										minimumFractionDigits: 2
									}) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>
									Commission{" "}
									{data?.aggregatorCommissionPercentage
										? `@ ${commifyNumbers(data.aggregatorCommissionPercentage)} %`
										: ""}
								</div>
								<div>
									{(data?.aggregatorCommission === 0 ||
										data?.serviceFee ||
										data?.aggregatorCommission) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{data?.serviceFee || data?.serviceFee === 0
										? data.serviceFee.trim()
										: commifyNumbers(data?.aggregatorCommission, null, {
												minimumFractionDigits: 2
										  }) || "--"}
								</div>
							</div>
							{data?.aggregatorDeductionSubfields?.map((subfield, i) => (
								<div className="breakdown-types-values" key={i}>
									<div>{AGGREGATOR_DEDUCTIONS_MAP[subfield.key] || subfield.key}</div>
									<div>
										{(subfield.value === 0 || subfield.value) && (
											<span>
												{subfield.value !== 0 && subfield.key === "Fees Discount" && "-"}{" "}
												{printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							))}
							{!isZomatov2 && !isZomatov3 && !isZomatov4 && (
								<div className="breakdown-types-values">
									<div>Taxes</div>
									<div>
										{(data?.aggregatorCommissionTax === 0 || data?.aggregatorCommissionTax) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.aggregatorCommissionTax, null, {
											minimumFractionDigits: 2
										}) || "--"}
									</div>
								</div>
							)}
						</div>
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">
									{isZomatov3 || isZomatov4 ? "D." : "C."} Order Level Adjustments
								</div>
								<div className="header-value">
									{(data?.orderLevelAdjustments === 0 || data?.orderLevelAdjustments) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.orderLevelAdjustments, null, { minimumFractionDigits: 2 }) ||
										"--"}
								</div>
							</div>
							{data?.orderAdjustmentSubfields?.map((subfield, i) => (
								<div className="breakdown-types-values" key={i}>
									<div>{subfield.key === "Gst Deduction" ? "GST Deduction" : subfield.key}</div>
									<div>
										{(subfield.value === 0 || subfield.value) && printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							))}
						</div>
						{!isZomatov2 && data?.chargesRefundTotal && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">D. Other Charges and Refunds</div>
									<div className="header-value">
										{data.chargesRefundTotal && data?.chargesRefundTotal?.trim() !== "" && (
											<span>
												{data?.chargesRefundTotal?.trim?.()?.includes("-") ? "-" : ""}{" "}
												{printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{(data?.chargesRefundTotal?.trim() !== "" &&
											data?.chargesRefundTotal?.trim().replace("-", "")) ||
											"--"}
									</div>
								</div>
								{chargesRefundSubFields &&
									Object.keys(chargesRefundSubFields).map((subfield, i) => (
										<div className="breakdown-types-values" key={i}>
											<div>{capitaliseTextStrict(subfield.replaceAll("_", " "), true)}</div>
											<div>
												{(chargesRefundSubFields[subfield] === 0 ||
													chargesRefundSubFields[subfield]) && (
													<span>
														{chargesRefundSubFields[subfield] < 0 ? "-" : ""}{" "}
														{printCurrency(biz.currencySymbol)}
													</span>
												)}
												&nbsp;
												{commifyNumbers(
													chargesRefundSubFields[subfield] < 0
														? -1 * chargesRefundSubFields[subfield]
														: chargesRefundSubFields[subfield],
													null,
													{ minimumFractionDigits: 2 }
												) || "--"}
											</div>
										</div>
									))}
							</div>
						)}
						<div className="breakdown-types">
							<div className="breakdown-types-header-with-value">
								<div className="header-text">
									{data?.chargesRefundTotal || isZomatov3 || isZomatov4
										? "E. Tax liabilities"
										: "D. Tax liabilities"}
								</div>
								<div className="header-value">
									{(data?.totalTaxLiabilites === 0 || data?.totalTaxLiabilites) &&
										printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.totalTaxLiabilites, null, { minimumFractionDigits: 2 }) ||
										"--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>TCS</div>
								<div>
									{(data?.tcs === 0 || data?.tcs) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.tcs, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							<div className="breakdown-types-values">
								<div>TDS</div>
								<div>
									{(data?.tds === 0 || data?.tds) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.tds, null, { minimumFractionDigits: 2 }) || "--"}
								</div>
							</div>
							{(isZomatov3 || isZomatov4) && (
								<div className="breakdown-types-values">
									<div>GST paid by Zomato on behalf of restaurant</div>
									<div>
										{(data?.gst === 0 || data?.gst) && printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.gst, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							)}
						</div>
						{
							(isZomatov2 || data?.otherSpends) && !isZomatov3 && !isZomatov4 && (
								<div className="breakdown-types">
									<div className="breakdown-types-header-with-value">
										<div className="header-text">
											E. Pay Later Deductions & Miscellaneous Deductions
										</div>
										<div className="header-value">
											{data.otherSpends && data?.otherSpends?.trim() !== "" && (
												<span>
													{data?.otherSpends?.trim?.()?.includes("-") && "-"}{" "}
													{printCurrency(biz.currencySymbol)}
												</span>
											)}
											&nbsp;
											{(data?.otherSpends?.trim() !== "" &&
												data?.otherSpends?.trim().replace("-", "")) ||
												"--"}
										</div>
									</div>
								</div>
							)
							// :
							// data?.otherSpends &&
							// <div className='breakdown-types'>
							// 	<div className='breakdown-types-header-with-value'>
							// 		<div className='header-text'>
							// 			E. Ads and other spends
							// 		</div>
							// 		<div className='header-value'>
							// 			{
							// 				(data.otherSpends && data?.otherSpends?.trim() !== "") &&
							// 				<span>{data?.otherSpends?.trim?.()?.includes("-") && "-"} {printCurrency(biz.currencySymbol)}</span>
							// 			}
							// 			&nbsp;
							// 			{data?.otherSpends?.trim() !== "" && data?.otherSpends?.trim().replace("-", "")|| "--"}
							// 		</div>
							// 	</div>
							// </div>
						}
						{isZomatov2 && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">F. Additions</div>
									<div className="header-value">
										{data.misc && data?.misc?.trim() !== "" && (
											<span>
												{data?.misc?.trim?.()?.includes("-") && "-"}{" "}
												{printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{(data?.misc?.trim() !== "" && data?.misc?.trim().replace("-", "")) || "--"}
									</div>
								</div>
								{data?.miscSubFields?.map((subfield, i) => (
									<div className="breakdown-types-values" key={i}>
										<div>{subfield.key}</div>
										<div>
											{(subfield.value === 0 || subfield.value) &&
												printCurrency(biz.currencySymbol)}
											&nbsp;
											{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
										</div>
									</div>
								))}
							</div>
						)}
						{(isZomatov3 || isZomatov4) && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">F. Investments in Growth Services</div>
									<div className="header-value">
										{(data?.growthServices === 0 || data?.growthServices) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.growthServices, null, { minimumFractionDigits: 2 }) ||
											"--"}
									</div>
								</div>
								{data?.growthServicesSubFields?.map((subfield, i) => (
									<div className="breakdown-types-values" key={i}>
										<div>{subfield.key}</div>
										<div>
											{(subfield.value === 0 || subfield.value) &&
												printCurrency(biz.currencySymbol)}
											&nbsp;
											{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
										</div>
									</div>
								))}
							</div>
						)}
						{(isZomatov3 || isZomatov4) && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">G. Investments in Hyperpure</div>
									<div className="header-value">
										{(data?.hyperpure === 0 || data?.hyperpure) &&
											printCurrency(biz.currencySymbol)}
										&nbsp;
										{commifyNumbers(data?.hyperpure, null, { minimumFractionDigits: 2 }) || "--"}
									</div>
								</div>
							</div>
						)}
						{(isZomatov3 || isZomatov4) && (
							<div className="breakdown-types">
								<div className="breakdown-types-header-with-value">
									<div className="header-text">H. Additions</div>
									<div className="header-value">
										{data.misc && data?.misc?.trim() !== "" && (
											<span>
												{data?.misc?.trim?.()?.includes("-") && "-"}{" "}
												{printCurrency(biz.currencySymbol)}
											</span>
										)}
										&nbsp;
										{(data?.misc?.trim() !== "" && data?.misc?.trim().replace("-", "")) || "--"}
									</div>
								</div>
								{data?.miscSubFields?.map((subfield, i) => (
									<div className="breakdown-types-values" key={i}>
										<div>{subfield.key}</div>
										<div>
											{(subfield.value === 0 || subfield.value) &&
												printCurrency(biz.currencySymbol)}
											&nbsp;
											{commifyNumbers(subfield.value, null, { minimumFractionDigits: 2 }) || "--"}
										</div>
									</div>
								))}
							</div>
						)}
						<div className="border-block border-block--end">
							<div>
								<strong>
									{isZomatov3 || isZomatov4
										? "Final Payment (B - C - D - E - F - G + H)"
										: isZomatov2
										? "Final Payment (A - B - C - D - E + F)"
										: data.otherSpends || data.chargesRefundTotal
										? "Final Payment (A - B - C - D - E)"
										: "Final Payment (A - B - C - D)"}
								</strong>
							</div>
							<div>
								<strong>
									{(data?.netPayable === 0 || data?.netPayable) && printCurrency(biz.currencySymbol)}
									&nbsp;
									{commifyNumbers(data?.netPayable, null, { minimumFractionDigits: 2 }) || "--"}
								</strong>
							</div>
						</div>
					</div>
					<div className="payout-breakdown">
						<div className="border-block">BANK TRANSACTIONS</div>
						<div className="utr-details">COMING SOON...</div>
					</div>
				</div>
			)}
		</React.Fragment>
	);
};
const mapStateToProps = (store) => ({
	tags: store.configItems.tags,
	bizPlatforms: store.configItems.bizPlatforms,
	biz: store.login.loggedInbizDetail,
	selectedPlatform: store.reconciliationPayoutsList.selectedPlatform
});
export default connect(mapStateToProps)(BasicInfo);
