import gql from "graphql-tag";

export const GET_ITEMS_LIST = gql`
	query getItemsList(
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$search: [SearchArgument]
		$brand: String
		$menuId: String
		$categoryId: Int
	) {
		items(
			limit: $limit
			offset: $offset
			filters: $filters
			sort: $sort
			search: $search
			brand: $brand
			menuId: $menuId
			categoryId: $categoryId
		) {
			limit
			offset
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				itemTitle
				itemPrice
				itemDesc
				crmTitle
				associatedLocationsCount
				imageUrl
				category
				foodType
				tags
				markupPrice
				merchantRefId
				isEnabled
				itemDiscreteCategory {
					id
					name
					isActive
				}
				brandsData {
					id
					name
					image
				}
			}
		}
	}
`;

export const CREATE_ITEM = gql`
	mutation createItem(
		$foodType: FoodTypeType
		$isRecommended: Boolean
		$itemDesc: String
		$itemDiscreteCategory: Int
		$itemTitle: String
		$itemPrice: Float
		$markupPrice: Float
		$merchantRefId: String
		$crmTitle: String
		$sortOrder: Int
		$tags: [String]
		$fulfillmentModes: [Int]
		$serves: Int
		$itemWeight: Float
		$priceDescriptor: String
		$preOrderEnabled: Boolean
		$timingsGroup: Int
		$itemGroups: [Int]
	) {
		saveItem(
			input: {
				foodType: $foodType
				isRecommended: $isRecommended
				itemDesc: $itemDesc
				itemDiscreteCategory: $itemDiscreteCategory
				itemTitle: $itemTitle
				itemPrice: $itemPrice
				markupPrice: $markupPrice
				merchantRefId: $merchantRefId
				crmTitle: $crmTitle
				sortOrder: $sortOrder
				tags: $tags
				fulfillmentModes: $fulfillmentModes
				serves: $serves
				itemWeight: $itemWeight
				priceDescriptor: $priceDescriptor
				preOrderEnabled: $preOrderEnabled
				timingsGroup: $timingsGroup
				itemGroups: $itemGroups
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				itemTitle
				itemPrice
				itemDesc
				locationsCount
				imageUrl
				category
				tags
				crmTitle
				foodType
				markupPrice
			}
		}
	}
`;

export const GET_ITEM_TAGS = gql`
	query getItemTags($limit: Int, $offset: Int, $sort: SortInput, $search: [SearchArgument]) {
		itemTags(limit: $limit, offset: $offset, sort: $sort, search: $search) {
			count
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					valueForDisplay
					value
				}
			}
			searchKeywords {
				key
				valueForDisplay
			}
			objects {
				id
				title
				tagGroup {
					id
					title
				}
			}
		}
	}
`;

export const GET_ITEM = gql`
	query getItem($id: Int) {
		item(id: $id) {
			id
			foodType
			isRecommended
			merchantRefId
			crmTitle
			itemTitle
			itemPrice
			markupPrice
			itemDesc
			sortOrder
			tags
			isEnabled
			itemWeight
			serves
			preOrderEnabled
			priceDescriptor
			timingsGroup {
				id
				title
			}
			itemDiscreteCategory {
				id
				name
				isActive
			}
			fulfillmentModes {
				id
				nameForDisplay
			}
			optionGroups {
				count
			}
			locations {
				count
			}
			platforms {
				id
				platformName
				logo
				isItemAssociated
			}
		}
	}
`;

export const GET_ITEM_MENU_SERVICE = gql`
	query getItemMenuService($menuId: String!, $itemId: String!, $categoryId: String) {
		item(menuId: $menuId, itemId: $itemId, categoryId: $categoryId) {
			id
			name
			crmName
			foodType
			description
			price
			markupPrice
			merchantRefId
			tags
			sortOrder
			isEnabled
			weight
			serves
			preOrderEnabled
			priceDescriptor
			category {
				id
				name
			}
			timingsGroup {
				id
				title
			}
			imageUrl
			isRecommended
			itemGroups {
				id
				name
			}
			fulfillmentModes {
				id
				name
			}
			overridenFields
			ruleAppliedFields
		}
	}
`;

export const GET_ITEM_CUSTOM_FIELDS = gql`
	query getItem($id: Int) {
		item(id: $id) {
			id
			keyValuesGroups {
				id
				name
				keyValues {
					id
					key
					value
				}
			}
		}
	}
`;

export const UPDATE_ITEM_CUSTOM_FIELDS = gql`
	mutation updateItemCustomFields(
		$id: Int
		$keyValuesToInsert: [ModelKVGroupInput]
		$keyValuesToEdit: [ModelKVGroupEditInput]
		$keyValuesToDelete: ModelKVGroupDeleteInput
	) {
		saveItem(
			input: {
				id: $id
				keyValuesToInsert: $keyValuesToInsert
				keyValuesToEdit: $keyValuesToEdit
				keyValuesToDelete: $keyValuesToDelete
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				keyValuesGroups {
					id
					name
					keyValues {
						id
						key
						value
					}
				}
			}
		}
	}
`;

export const GET_ITEM_LOCATIONS = gql`
	query getItemLocations(
		$id: Int
		$limit: Int
		$offset: Int
		$filters: [ListFilterArgument]
		$sort: SortInput
		$brandId: Int
	) {
		item(id: $id) {
			id
			associatedItemLocations(limit: $limit, offset: $offset, sort: $sort, filters: $filters, brandId: $brandId) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						value
						valueForDisplay
					}
				}
				objects {
					id
					location {
						id
						name
						city
						tags
					}
					price
					externalPrice
					available
					currentStock
					itemRefId
					actualPrice
					associatedItemLocationPlatforms {
						id
						price
						bizPlatform {
							id
							platformName
						}
						actualPrice
					}
				}
			}
		}
	}
`;

export const UPDATE_ITEM_LOCATION_FIELDS = gql`
	mutation updateItemLocationFields($ilas: [ItemLocationAssociationFieldsInputType]!) {
		updateItemLocationFields(input: { ilas: $ilas }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_ITEM_LOCATION_PLATFORM_FIELDS = gql`
	mutation updateItemLocationPlatformFields($ilpas: [UpdateItemLocationPlatformAssociationInput]) {
		updateItemLocationPlatforms(input: { ilpas: $ilpas }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_ITEM_AVAILABILITY = gql`
	mutation updateItemAvailability(
		$ilaIds: [Int]!
		$action: String
		$itemId: Int
		$associateAll: Boolean
		$disassociateAll: Boolean
		$filters: [ListFilterArgument]
	) {
		itemAction(
			input: {
				ilaIds: $ilaIds
				action: $action
				itemId: $itemId
				associateAll: $associateAll
				disassociateAll: $disassociateAll
				filters: $filters
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const UPDATE_ITEM_PLATFORMS = gql`
	mutation updateItemPlatforms($id: Int!, $availablePlatforms: [AvailablePlatformInput]!) {
		updateItemPlatforms(input: { id: $id, availablePlatforms: $availablePlatforms }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const GET_ITEM_RELATED = gql`
	query getItemRelated($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument], $sort: SortInput) {
		item(id: $id) {
			id
			recommendedItems(limit: $limit, offset: $offset, sort: $sort, filters: $filters) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					itemTitle
					itemPrice
					crmTitle
					tags
					category
					foodType
				}
			}
		}
	}
`;

export const GET_ITEM_MODIFIER_GROUPS = gql`
	query getItemModifierGroups($id: Int, $limit: Int, $offset: Int, $sort: SortInput) {
		item(id: $id) {
			id
			optionGroups(limit: $limit, offset: $offset, sort: $sort) {
				count
				filters {
					hide
					field
					valueForDisplay
					type
					values {
						valueForDisplay
						value
					}
				}
				objects {
					id
					optionGroupTitle
					optionGroupDesc
					optionGroupMaxSelectable
					optionGroupMinSelectable
					isVariant
				}
			}
		}
	}
`;

export const GET_MENU_SERVICE_ITEM_MODIFIER_GROUPS = gql`
	query getMenuServiceItemModifierGroups($menuId: String!, $itemId: String!, $limit: Int, $offset: Int) {
		item(menuId: $menuId, itemId: $itemId) {
			optionGroups(limit: $limit, offset: $offset) {
				objects {
					id
					name
					description
					minSelectable
					maxSelectable
					isVariant
					crmName
					options(limit: $limit, offset: $offset) {
						objects {
							id
							name
							crmName
							price
							foodType
							nestedOptionGroups(limit: $limit, offset: $offset) {
								objects {
									id
									name
									description
									minSelectable
									maxSelectable
									isVariant
									crmName
									options(limit: $limit, offset: $offset) {
										objects {
											id
											name
											crmName
											price
											foodType
											nestedOptionGroups(limit: $limit, offset: $offset) {
												objects {
													id
													name
													description
													minSelectable
													maxSelectable
													isVariant
													crmName
													options(limit: $limit, offset: $offset) {
														objects {
															id
															name
															crmName
															price
															foodType
														}
														totalObjectCount
													}
												}
												totalObjectCount
											}
										}
										totalObjectCount
									}
								}
								totalObjectCount
							}
						}
						totalObjectCount
					}
				}
				totalObjectCount
			}
		}
	}
`;

export const GET_ITEM_TAXES_AND_CHARGES = gql`
	query getItemTaxesAndCharges($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		item(id: $id) {
			id
			taxes(limit: $limit, offset: $offset, filters: $filters) {
				count
				objects {
					id
					title
					description
					applicableOn
					value
				}
			}
			charges(limit: $limit, offset: $offset, filters: $filters) {
				count
				objects {
					id
					title
					description
					applicableOn
					value
				}
			}
		}
	}
`;

export const GET_ITEM_NON_PLATFORM_IMAGES = gql`
	query getItemNonPlatformImages($id: Int) {
		item(id: $id) {
			id
			nonPlatformImages {
				id
				objectId
				image
				isDefault
			}
		}
	}
`;

export const GET_ITEM_PLATFORM_IMAGES = gql`
	query getItemPlatformImages($id: Int) {
		item(id: $id) {
			id
			platformImages {
				id
				objectId
				image
				platforms
			}
		}
	}
`;

export const UPLOAD_ITEM_IMAGE = gql`
	mutation uploadItemImage($entityId: Int!, $entityType: String!, $tags: [String], $image: Upload!) {
		saveImage(entityId: $entityId, entityType: $entityType, tags: $tags, image: $image) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const SAVE_DEFAULT_IMAGE = gql`
	mutation saveDefaultImage($imageId: Int!, $itemId: Int!) {
		saveDefaultImage(input: { imageId: $imageId, itemId: $itemId }) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const DELETE_IMAGE = gql`
	mutation deleteImage($imageIds: [Int]!) {
		deleteImage(imageIds: $imageIds) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const EDIT_ITEM = gql`
	mutation editItem(
		$id: Int
		$foodType: FoodTypeType
		$isRecommended: Boolean
		$itemDesc: String
		$itemDiscreteCategory: Int
		$itemTitle: String
		$itemPrice: Float
		$markupPrice: Float
		$merchantRefId: String
		$crmTitle: String
		$sortOrder: Int
		$tags: [String]
		$fulfillmentModes: [Int]
		$isEnabled: Boolean
		$itemWeight: Float
		$serves: Int
		$priceDescriptor: String
		$preOrderEnabled: Boolean
		$timingsGroup: Int
		$locationsToUpdate: [Int]
		$itemGroups: [Int]
	) {
		saveItem(
			input: {
				id: $id
				foodType: $foodType
				isRecommended: $isRecommended
				itemDesc: $itemDesc
				itemDiscreteCategory: $itemDiscreteCategory
				itemTitle: $itemTitle
				itemPrice: $itemPrice
				markupPrice: $markupPrice
				merchantRefId: $merchantRefId
				crmTitle: $crmTitle
				sortOrder: $sortOrder
				tags: $tags
				fulfillmentModes: $fulfillmentModes
				isEnabled: $isEnabled
				itemWeight: $itemWeight
				serves: $serves
				priceDescriptor: $priceDescriptor
				preOrderEnabled: $preOrderEnabled
				timingsGroup: $timingsGroup
				locationsToUpdate: $locationsToUpdate
				itemGroups: $itemGroups
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
			object {
				id
				itemTitle
				itemPrice
				itemDesc
				locationsCount
				imageUrl
				category
				tags
				crmTitle
				foodType
				markupPrice
			}
		}
	}
`;

export const VALIDATE_ITEM_HANDLE = gql`
	query validateItemHandle($filters: [ListFilterArgument]) {
		items(filters: $filters) {
			count
		}
	}
`;

export const GET_ITEM_ITEM_GROUPS = gql`
	query getItem($id: Int, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		item(id: $id) {
			itemGroups(limit: $limit, offset: $offset, filters: $filters) {
				count
				objects {
					id
					title
					numAssociatedItems
				}
			}
		}
	}
`;

export const GET_ITEM_GROUPS = gql`
	query itemItemGroupsAssociations($itemId: Int!, $limit: Int, $offset: Int, $filters: [ListFilterArgument]) {
		itemItemGroupsAssociations(itemId: $itemId, limit: $limit, offset: $offset, filters: $filters) {
			filters {
				hide
				field
				valueForDisplay
				type
				values {
					value
					valueForDisplay
				}
			}
			count
			objects {
				id
				title
				description
				isAssociated
				includesAll
			}
		}
	}
`;

export const UPDATE_ITEM_GROUPS_ASSOCIATION = gql`
	mutation updateItemGroupAssociation(
		$itemId: Int!
		$itemGroupsToAssociate: [Int]
		$itemGroupsToDisassociate: [Int]
	) {
		updateItemGroupAssociation(
			input: {
				itemId: $itemId
				itemGroupsToAssociate: $itemGroupsToAssociate
				itemGroupsToDisassociate: $itemGroupsToDisassociate
			}
		) {
			status {
				success
				messages {
					field
					message
				}
			}
		}
	}
`;

export const ITEM_ACTION_LOGS = gql`
	query getItemActionLogs(
		$requiredFilters: RequiredFilterArgument
		$filters: [ListFilterArgument]
		$query: ItemActionLogsQuery!
	) {
		getItemActionLogs(requiredFilters: $requiredFilters, filters: $filters, query: $query) {
			objects {
				objects {
					bizId
					biz
					locationId
					locationName
					itemId
					itemName
					platform
					userId
					userName
					action
					status
					source
					message
					timestamp
				}
				pagination {
					limit
					offset
					count
				}
			}
		}
	}
`;
