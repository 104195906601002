// client
import { client } from "../client";
import { store } from "../store/configureStore";

// third party
import moment from "moment";

// utils
import { capitaliseTextStrict } from "../atlas-utils";

// graphql
import { GET_METRIC_DATA, GET_LINE_CHART_DATA, GET_HEATMAP_CHART_DATA, GET_TABULAR_DATA } from "../graphql/analytics";

// actions
import { ActionTypes } from "./_types";
import { getMetricsData, getTabularData, getHeatmapChartData } from "./analytics";

// update rewind state
export const updateRewindState = (payload) => {
	store.dispatch({
		type: ActionTypes.UPDATE_REWIND_STATE,
		payload
	});
};

// get duration object of last year
export const getDurationObject = () => {
	const durationObj = {};

	durationObj.duration = {
		custom: {
			startDate: `${moment().year() - 1}-01-01`,
			endDate: `${moment().year() - 1}-12-31`
		}
	};

	return durationObj;
};

// get applied filters and applied date filter
const getAllAppliedFilters = () => {
	const isMultibrandEnabled = store.getState().login.loggedInbizDetail.isMultibrandEnabled;
	const bizId = store.getState().login.loggedInbizDetail.id;
	const durationObject = getDurationObject();
	let filters = [];

	return { durationObject, filters, bizId, isMultibrandEnabled };
};

// total orders count
export const fetchOrdersReceivedMetrics = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_orders_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getMetricsData(resp.data.getMetricData?.objects);
		updateRewindState({
			totalOrders: data?.["order_received_orders"]?.value || 0
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// net revenue
export const fetchGrossRevenueMetrics = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_gross_revenue_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getMetricsData(resp.data.getMetricData?.objects);
		updateRewindState({
			netRevenue: data?.["order_revenue"]?.value || 0
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// top platform by order count
export const fetchOrdersBreakdownTable = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_breakdown_plt_tabular",
			filters,
			sort: {
				field: "ORDER_COMPLETED_ORDERS",
				order: "DESC"
			},
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getTabularData(resp.data.getTabularData?.objects || {});
		const topPlatform = data?.rows?.find((row) => row?.platform) || {};
		updateRewindState({
			topPlatform: {
				name: capitaliseTextStrict(topPlatform?.platform || "", true),
				orders: topPlatform?.["ORDER_COMPLETED_ORDERS"] || 0,
				netRevenue: topPlatform?.["ORDER_REVENUE"] || 0
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// top location by order count
export const fetchOrdersByLocation = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_location_tabular",
			filters,
			sort: {
				field: "ORDER_COMPLETED_ORDERS",
				order: "DESC"
			},
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getTabularData(resp.data.getTabularData?.objects || {});
		const topLocation = data?.rows?.find((row) => row?.name) || {};
		updateRewindState({
			topLocation: {
				name: capitaliseTextStrict(topLocation?.name || "", true),
				orders: topLocation?.["ORDER_COMPLETED_ORDERS"] || 0,
				netRevenue: topLocation?.["ORDER_REVENUE"] || 0
			}
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// total items count
export const fetchCatalogueMetrics = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "catalogue_category_performance_metrics",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_METRIC_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getMetricsData(resp.data.getMetricData?.objects);
		updateRewindState({
			totalItems: data?.["item_count"]?.value || 0
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// top item by units sold
export const fetchOrdersByItem = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_by_item_tabular",
			filters,
			sort: {
				field: "ITEM_SOLD",
				order: "DESC"
			},
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getTabularData(resp.data.getTabularData?.objects || {});
		updateRewindState({
			topItemUnitsSold: capitaliseTextStrict(data?.rows?.find((row) => row?.name)?.name || "", true)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// top item by net revenue
export const fetchRevenueByItem = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "revenue_by_item_tabular",
			filters,
			sort: {
				field: "ITEM_REVENUE",
				order: "DESC"
			},
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getTabularData(resp.data.getTabularData?.objects || {});
		updateRewindState({
			topItemNetRevenue: capitaliseTextStrict(data?.rows?.find((row) => row?.name)?.name || "", true)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// golden hour time period
export const fetchOrderFrequencyTimePeriod = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_CREATED_MONTH" }
		];

		const variables = {
			query: "order_order_distribution_heatmap",
			filters: [...filters, { field: "group_by", value: compareOptions[0].value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects);

		let maxValue = 0;
		let timePeriod = "";
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
					timePeriod = cell.x?.replace(" ", "");
				}
			});
		});
		updateRewindState({
			goldenHourTimePeriod: timePeriod
				? `${timePeriod} - ${moment(timePeriod, "hA").add("1", "hour").format("hA")}`
				: ""
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// busiest day by date
export const fetchOrderFrequencyBusiestDay = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const compareOptions = [
			{ label: "Hours", value: "ORDER_CREATED_DATE_HOUR" },
			{ label: "Days of the Week", value: "ORDER_CREATED_WEEK_DAY" },
			{ label: "Dates", value: "ORDER_CREATED_DATE" },
			{ label: "Weeks", value: "ORDER_CREATED_MONTH_WEEK" },
			{ label: "Months", value: "ORDER_CREATED_MONTH" }
		];

		const variables = {
			query: "order_order_distribution_heatmap",
			filters: [...filters, { field: "group_by", value: compareOptions[2].value }],
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_HEATMAP_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const graphData = getHeatmapChartData(resp.data.getHeatmapChartData?.objects);

		let maxValue = 0;
		let date = "";
		graphData.forEach((obj) => {
			obj.data.forEach((cell) => {
				if (cell.y > maxValue) {
					maxValue = cell.y;
					date = `${cell.x} ${obj.id}`;
				}
			});
		});
		updateRewindState({
			busiestDayOrders: date ? moment(date, "Do MMM YYYY").format("Do MMMM, YYYY") : ""
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// best month by order count
export const fetchOrdersReceivedChart = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_received_line",
			filters,
			requiredFilters: {
				bizId,
				...durationObject
			}
		};

		const resp = await client.query({
			query: GET_LINE_CHART_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		let maxValue = 0;
		let month = "";
		(resp.data.getLineChartData.objects ?? []).forEach((obj) => {
			(obj.data ?? []).forEach((pt) => {
				if (Math.round(pt.y) > maxValue) {
					maxValue = Math.round(pt.y);
					month = pt.x;
				}
			});
		});

		updateRewindState({
			bestMonthOrders: month ? moment(month).format("MMMM") : ""
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};

// best city by order count
export const fetchBestCityByOrder = async () => {
	try {
		const { durationObject, filters, bizId } = getAllAppliedFilters();
		const variables = {
			query: "order_breakdown_city_tabular",
			filters,
			sort: {
				field: "ORDER_COMPLETED_ORDERS",
				order: "DESC"
			},
			requiredFilters: {
				bizId,
				...durationObject,
				limit: 5,
				offset: 0
			}
		};

		const resp = await client.query({
			query: GET_TABULAR_DATA,
			variables,
			fetchPolicy: "cache-first"
		});

		const data = getTabularData(resp.data.getTabularData?.objects || {});
		updateRewindState({
			bestCityOrders: capitaliseTextStrict(data?.rows?.find((row) => row?.platform)?.platform || "", true)
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: ActionTypes.SHOW_GLOBAL_MESSAGE,
			payload: {
				message: error.message || "Something went wrong.",
				timeout: 5000,
				error: true,
				errObject: error
			}
		});
	}
};
