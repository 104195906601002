import React from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import { InputWithLabel } from "../_commons/InputWithLabel";
import MonogramGenerator from "../_commons/MonogramGenerator";

// thirdparty
import classNames from "classnames";

// utils
import { capitaliseText } from "../../atlas-utils";

// constants
import { CATALOGUE_PLATFORMS_LOGO } from "../../client-config";
import WarningCard from "../_commons/WarningCard";
export const PLATFORM_MAP = {
	ubereats: "Uber Eats",
	doordash: "DoorDash"
};
const HOW_IT_WORKS = [
	"Open a menu link on platform for the selected brand and location",
	"Copy link from browser",
	"Paste in the platform URL field",
	"Proceed to import menu"
];

function AddPlatformUrl({
	selectedBrand = null,
	selectedPlatform,
	sourceURL,
	setSourceURL = () => {},
	validationMessage = ""
}) {
	const openLinkInNewTab = (url) => {
		if (url) {
			window.open(url, "_blank");
		}
	};

	return (
		<div className="onboarding-menus__add-platform-url">
			<WarningCard
				showIcon
				title="This is a one-time action"
				description="Make sure the URL you choose is the one that best represents your menu for the brand, you will not be able to undo this action."
			/>
			<div className="integration-summary-logo">
				{selectedBrand?.id ? (
					<div className="selected-brand">
						{selectedBrand.image ? (
							<img src={selectedBrand.image} alt={selectedBrand?.name} className="logo" />
						) : (
							<MonogramGenerator value={selectedBrand?.name} className="logo" />
						)}
						<Text variant={TextVariant.BODY1} className="brand-title">
							{" "}
							{selectedBrand.name && selectedBrand.name.length > 25
								? selectedBrand.name.slice(0, 25) + "..."
								: selectedBrand.name}
						</Text>
					</div>
				) : null}
				<div className="selected-platform">
					<img
						className="logo"
						src={
							selectedPlatform?.logo ||
							CATALOGUE_PLATFORMS_LOGO[selectedPlatform?.platformName] ||
							"/assets/icons/icons8-globe-40.png"
						}
						alt=""
					/>
					<Text variant={TextVariant.BODY1} className="platform-name">
						{PLATFORM_MAP[selectedPlatform?.platformName] || capitaliseText(selectedPlatform?.platformName)}
					</Text>
				</div>
			</div>

			<Text className="title" variant={TextVariant.HEADING4}>
				Enter a platform URL you want to import menu from
			</Text>

			<div className="import-url-wrapper">
				<InputWithLabel
					className={classNames("import-url-input", {
						"import-url-input--error": Boolean(validationMessage)
					})}
					value={sourceURL}
					onChange={(e) => setSourceURL(e.target.value)}
					validationMessage={validationMessage}
					placeholder="Enter platform URL, for example www.swiggy.com/goodburger-bangalore"
				>
					Platform URL
					<img
						className="open-link-icon"
						src="/assets/icons/open-link-icon.svg"
						alt="open-link"
						onClick={() => {
							openLinkInNewTab(sourceURL);
						}}
					/>
				</InputWithLabel>
			</div>

			<div className="how-it-works">
				<Text variant={TextVariant.HEADING4} className="how-it-works__title">
					How it works
				</Text>
				<div className="how-it-works__body">
					{HOW_IT_WORKS.map((item, index) => {
						return (
							<Text variant={TextVariant.HEADING5} key={index} className="item">
								<span className="item-number">{index + 1}</span>
								{item}
							</Text>
						);
					})}
				</div>
			</div>
		</div>
	);
}

export default AddPlatformUrl;
