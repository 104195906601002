import React, { Component } from "react";

// components
import { Header } from "../components/LocationGroupsList/Header";
import { CommonTable } from "../components/_commons/CommonTable";
import { Filters } from "../components/_commons/Filters";
import { Paginator } from "../components/_commons/Paginator";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import CreateIcon from "../components/_commons/CreateIcon";

// clients
import { store } from "../store/configureStore";

// third party
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { debounce } from "lodash";

// actions
import { fetchBrands, fetchBrandsDebounced, fetchStoresDebounced } from "../actions/actions";
import { fetchLocationGroupsList } from "../actions/locationGroups";
import { ActionTypes } from "../actions/_types";

// utils
import { scroll, adjustNestedContainer } from "../atlas-utils";
import history from "../history";

// constant
import { TRACK_EVENT } from "../atlas-utils/tracking";
import { NESTED_ENTITY_TYPES } from "../client-config";
import { fetchBrandsList } from "../actions/brands";

const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};

const columns = [
	{
		name: "Name",
		field: "name",
		sortKey: "title",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text name" title={record.title} key={i}>
				<Link className="link-text" to={`/location-groups/edit/${record.id}`}>
					<span className="hyperlink hyperlink--black-color">{record.title || record.id}</span>
				</Link>
			</div>
		)
	},
	{
		name: "Description",
		field: "description",
		sortKey: "description",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text description" title={record.description} key={i}>
				{(record.description && record.description.length > 120
					? record.description.slice(0, 120) + "..."
					: record.description) || "--"}
			</div>
		)
	},
	{
		name: "Locations Associated",
		field: "locations_associated",
		render: (record, i) => (
			<div className="at-table-cell at-cell-text locations_associated" key={i}>
				{record.numAssociatedLocations | 0}
			</div>
		)
	}
];

@connect((store) => ({
	locationGroupsList: store.locationGroupsList,
	locationGroupsListState: store.locationGroupsListState,
	biz: store.login.loggedInbizDetail,
	configItems: store.configItems,
	access: store.login.loginDetail.access
}))
export class LocationGroupsList extends Component {
	constructor(props) {
		super(props);
		this.nestedRef = React.createRef();
		this.state = {
			isOpen: false,
			showFilters: false,
			storesLookup: {},
			brandsLookup: {},
			title: "",
			nestedEntity: NESTED_ENTITY_INITIAL_STATE
		};
	}

	async componentDidMount() {
		// set tracking related info
		const eventName = "location_groups_list_view_default";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// fetch Location Groups list
		await fetchLocationGroupsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const eventMeta = {
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		// PubSub.publish(TRACK_EVENT, {
		// 	tracker: 'mixpanel',
		// 	eventName,
		// 	eventMeta,
		// });

		// the below code preloads the stores dropdown
		const { configItems } = this.props;
		if (!configItems.stores.items.length) {
			fetchStoresDebounced("");
		}
		if (!configItems.brands.items.length) {
			await fetchBrandsList();
			console.log("brands fetched", configItems.brands);
		}
	}

	handleViewItem = (toOpen = false, type, id) => {
		if (!toOpen) {
			this.setState({ nestedEntity: NESTED_ENTITY_INITIAL_STATE });
		} else {
			this.setState({
				nestedEntity: {
					show: true,
					type,
					id
				}
			});
		}
		adjustNestedContainer(toOpen);
	};

	handleNestedEntity = this.handleViewItem.bind(this);

	handlePiperAcademy = () => {
		store.dispatch({
			type: "UPDATE_PIPER_ACADEMY_STATE",
			payload: {
				location: "location-groups"
			}
		});
		this.handleNestedEntity(true, NESTED_ENTITY_TYPES[13], "");
	};

	flipShowFilters = () => {
		this.setState({
			showFilters: !this.state.showFilters
		});
	};

	updatelocationGroupsListState = (payload) => {
		store.dispatch({
			type: ActionTypes.LOCATION_GROUPS_LIST_STATE_CHANGE,
			payload
		});
	};

	filterSidebarCloseHandler = () => {
		this.setState({
			showFilters: false
		});
		this.updatelocationGroupsListState({
			currentFilters: this.props.locationGroupsListState.appliedFilters
		});
	};

	setFilter = (field, value) => {
		let currentFilters = {
			...this.props.locationGroupsListState.currentFilters
		};
		currentFilters[field] = value;
		this.updatelocationGroupsListState({
			currentFilters
		});
	};

	setSearchFilter = (field, value) => {
		let currentFilters = {
			...this.props.locationGroupsListState.currentFilters
		};
		this.setState({
			[field]: value
		});
		currentFilters[field] = value;
		this.updatelocationGroupsListState({
			currentFilters
		});
		this.applySearchFilter();
	};

	applySearchFilter = debounce(() => this.applyFilters(), 500);

	applyFilters = async () => {
		this.setState({
			showFilters: false
		});
		this.updatelocationGroupsListState({
			appliedFilters: {
				...this.props.locationGroupsListState.currentFilters
			},
			offset: 0
		});

		// set tracking related info
		const eventName = "location_groups_list_view_filter";
		let perfStart = 0;
		let perfEnd = 0;
		if (window.performance) {
			perfStart = window.performance.now();
		}

		// apply filters
		await fetchLocationGroupsList();

		// set tracking related info and send the event to be logged
		if (window.performance) {
			perfEnd = window.performance.now();
		}
		const { appliedFilters } = store.getState().locationGroupsListState;
		const eventMeta = {
			filters: JSON.stringify(Object.values(appliedFilters)),
			time_to_load: Number(((perfEnd - perfStart) / 1000).toFixed(1))
		};
		PubSub.publish(TRACK_EVENT, {
			tracker: "mixpanel",
			eventName,
			eventMeta
		});
	};

	clearFilters = () => {
		this.setState(
			{
				showFilters: false
			},
			() => {
				this.updatelocationGroupsListState({
					currentFilters: {},
					appliedFilters: {},
					offset: 0
				});
				fetchLocationGroupsList();
			}
		);
	};

	handlePagination = (page) => {
		// set new offset
		const { limit } = this.props.locationGroupsListState;
		const offset = (page - 1) * limit;
		this.updatelocationGroupsListState({
			offset
		});
		// fetch new Location Groups list
		fetchLocationGroupsList();
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	handlePageSize = async (field, size) => {
		// set new limit
		const { limit } = this.props.locationGroupsListState;
		if (size && size?.value !== limit) {
			this.updatelocationGroupsListState({
				[field]: size.value
			});
			// fetch new Location Groups list
			await fetchLocationGroupsList();
		}
		// scroll to top of the list
		if (this.tableRef) {
			scroll({ top: this.tableRef?.offsetTop - 57, left: 0 });
		}
	};

	sortList = (field) => {
		const sort = {
			field
		};
		this.updatelocationGroupsListState({
			offset: 0
		});
		store.dispatch({
			type: ActionTypes.LOCATION_GROUPS_LIST_STATE_CHANGE_SORT,
			payload: {
				sort
			}
		});
		fetchLocationGroupsList();
	};

	updateStoresLookup = (id, title) => {
		this.setState({
			storesLookup: {
				...this.state.storesLookup,
				[id]: title
			}
		});
	};
	updateBrandsLookup = (id, title) => {
		this.setState({
			brandsLookup: {
				...this.state.brandsLookup,
				[id]: title
			}
		});
	};

	render() {
		const { locationGroupsList, locationGroupsListState, configItems, access } = this.props;
		const { limit, offset, currentFilters, sortedField } = locationGroupsListState;

		let filterCount = 0;
		for (let f in currentFilters) {
			if (f !== "title") {
				if (currentFilters[f].value != "") {
					filterCount++;
				}
			}
		}

		const filterOptions = locationGroupsList.data.filters
			.map((f) => {
				if (f.type === "MULTIPLE" && f.field === "locations") {
					f = {
						...f,
						isAsync: true,
						asyncOptions: this.props.configItems.stores,
						asyncLookup: this.state.storesLookup,
						updateAsyncLookup: this.updateStoresLookup,
						handleAsyncSearch: fetchStoresDebounced,
						labelKey: "name",
						valueKey: "id"
					};
				}
				if (f.field === "brand_id") {
					f = {
						...f,
						type: "MULTIPLE",
						isAsync: true,
						asyncOptions: this.props.configItems.brands,
						asyncLookup: this.state.brandsLookup,
						updateAsyncLookup: this.updateBrandsLookup,
						handleAsyncSearch: fetchBrandsDebounced,
						labelKey: "name",
						valueKey: "id"
					};
				}
				return f;
			})
			.filter((f) => f.field !== "title");

		const searchFilterOption = locationGroupsList.data.filters.find((fl) => fl.field === "title");

		const placeholderContent = {
			placeholderText: "No location groups added yet!",
			placeholderImageUrl: "/assets/empty_states/graphics-empty-location.svg",
			placeholderSubtext: "Associate locations in one location group to manage them efficiently",
			placeholderButtonContent: (
				<>
					<CreateIcon />
					<span>Add a new Location Group</span>
				</>
			),
			placeholderButtonClickAction: () => {
				history.push("/location-groups/new");
			},
			redirectionLink: "/piper-academy/location-groups",
			redirectionLinkText: "learn more about Location Groups",
			size: "medium"
		};

		return (
			<div className="location-groups-section section-container-common" ref={(ref) => (this.tableRef = ref)}>
				{configItems.dimensions.width > 768 && (
					<Filters
						isOpen={this.state.showFilters}
						close={this.filterSidebarCloseHandler}
						apply={this.applyFilters}
						clear={this.clearFilters}
						options={filterOptions}
						currentFilters={currentFilters}
						setFilter={this.setFilter}
					/>
				)}
				<Header
					filterCount={filterCount}
					flipShowFilters={this.flipShowFilters}
					filterActive={this.state.showFilters}
					dimensions={configItems.dimensions}
					filterOption={searchFilterOption}
					setFilter={this.setSearchFilter}
					value={this.state.title}
					handlePiperAcademy={this.handlePiperAcademy}
					isCatalogueManagement={access.isCatalogueManagement}
				/>
				<CommonTable
					loading={locationGroupsList.loading}
					data={locationGroupsList.data.objects || []}
					columns={columns}
					sortList={this.sortList}
					sortedField={sortedField}
					classes="location-groups-list-table-container"
					content="Location Groups"
					showPlaceholder
					placeholderContent={placeholderContent}
				/>
				<Paginator
					limit={limit}
					offset={offset}
					count={locationGroupsList.data.count || 0}
					goToPage={this.handlePagination}
					setPageSize={this.handlePageSize}
					showPageSize={true}
				/>
				<NestedEntityContainer
					show={this.state.nestedEntity.show}
					type={this.state.nestedEntity.type}
					id={this.state.nestedEntity.id}
					closeNestedContainer={() => this.handleNestedEntity(false)}
					nestedRef={this.nestedRef}
					isNested={false}
					isForeignSource={true}
				/>
			</div>
		);
	}
}
