import React, { useEffect, useState, useRef, useCallback } from "react";

// components
import Text, { TextVariant } from "@urbanpiper-engineering/horizon/dist/base/HorizonText";
import ChooseOnboardingMethod from "../components/OnboardingCommon/ChooseOnboardingMethod";
import ChooseBrands from "../components/OnboardingCommon/ChooseBrands";
import UploadSpreadSheet from "../components/OnboardingLocations/UploadSpreadSheet";
import LocationCreationSuccess from "../components/OnboardingLocations/LocationCreationSuccess";
import ConfigureManually from "../components/OnboardingLocations/ConfigureManually";
import Footer from "../components/OnboardingCommon/Footer";
import OnboardingSetupHeader from "../components/OnboardingCommon/OnboardingSetupHeader";

// utils
import history from "../history";
import { businessBasedDisabledFields } from "../client-config";
import { lS, validatePlatformUrl, scroll, decideMethodAndValidatePlatformId, trackEvent } from "../atlas-utils";

// third party
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import uniqBy from "lodash/uniqBy";
import camelCase from "camelcase";

// actions
import { fetchBrands } from "../actions/actions";
import { fetchBizPlatforms } from "../actions/actions";
import LocationCreationFailure from "../components/OnboardingLocations/LocationCreationFailure";
import { client } from "../client";
import { CREATE_LOCATION } from "../graphql/locations";
import { FORM_FIELDS_MAP } from "../components/LocationCreate/LocationCreateSuccess";

// constants

// steps for each onboarding method
export const CONFIGURE_LOCATIONS_MANUALLY_STEPS = {
	ADD_LOCATION_DETAILS: "addLocationDetails"
	// SELECT_PLATFORMS: "selectPlatforms",
	// CONFIGURE_LOCATIONS_PLATFORMS: "configureLocationsPlatforms"
};
const LOCATION_ONBOARDING_METHODS = [
	{
		id: uuidv4(),
		title: "Import from third party integrations like DSP and POS",
		recommended: true,
		comingSoon: true,
		image: "/assets/third-party-location-onboarding.png",
		onCtaClick: () => {},
		steps: {}
	},
	{
		id: uuidv4(),
		title: "Upload restaurant data on as a spreadsheet",
		recommended: false,
		comingSoon: true,
		image: "/assets/spreadsheet-location-onboarding.png",
		onCtaClick: () => {},
		steps: {}
	},
	{
		id: uuidv4(),
		title: "Configure all restaurant data manually",
		recommended: false,
		comingSoon: false,
		image: "/assets/manual-location-onboarding.png",
		onCtaClick: () => {},
		steps: CONFIGURE_LOCATIONS_MANUALLY_STEPS
	}
];

const LOCATION_INIT_STATE = {
	city: null,
	bizLocationNickname: "",
	bizAddress: "",
	brands: [],
	platforms: []
};

const LS_ENUMS = {
	ONBOARDING_ADD_LOCATION_DETAILS: "onboardingAddLocationDetails"
};

function OnboardingLocations({ bizId, bizCountry, isMultibrandEnabled, bizPlatforms }) {
	const [selectedOnboardingMethod, setSelectedOnboardingMethod] = useState({});
	const [selectedMethodStep, setSelectedMethodStep] = useState({});
	const [brandsList, setBrandsList] = useState([]);
	const [selectedBrandIds, setSelectedBrandIds] = useState({});
	const [selectedPlatforms, setSelectedPlatforms] = useState({});
	const addLocationDetailsFormDataFromLocalStorage = lS.get(LS_ENUMS.ONBOARDING_ADD_LOCATION_DETAILS) || [];
	const [locationFormData, setLocationFormData] = useState(addLocationDetailsFormDataFromLocalStorage?.[bizId]);
	const [addLocationDetailsFormValidations, setAddLocationDetailsFormValidations] = useState({});
	const [PLAFormValidations, setPLAFormValidations] = useState({});
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const containerRef = useRef();

	const handleAddMoreRows = (numberOfRows) => {
		if (!numberOfRows) return;

		// Create a new array with deep copies of LOCATION_INIT_STATE
		const newRows = Array.from({ length: numberOfRows }, () => cloneDeep(LOCATION_INIT_STATE));
		let updatedLocationFormData = cloneDeep(locationFormData);
		if (!updatedLocationFormData) updatedLocationFormData = [];
		updatedLocationFormData = [...updatedLocationFormData, ...newRows];
		setLocationFormData(updatedLocationFormData);
	};

	const handleSelectOnboardingMethod = (method) => {
		// scroll to the top
		if (containerRef?.current) {
			scroll({ top: containerRef?.current?.offset - 57, left: 0 }, window, "instant");
		}
		setSelectedOnboardingMethod(method);
	};

	if (!locationFormData?.length) {
		handleAddMoreRows(5);
	}

	useEffect(() => {
		if (isMultibrandEnabled) {
			fetchBrandsList();
		}
		fetchBizPlatforms();
	}, [isMultibrandEnabled]);

	useEffect(() => {
		if (selectedOnboardingMethod?.id) {
			const steps = selectedOnboardingMethod.steps;
			if (Object.keys(steps).length) {
				const initialStep = Object.keys(steps)[0];
				setSelectedMethodStep(steps[initialStep]);
			}
		}
	}, [selectedOnboardingMethod]);

	useEffect(() => {
		//on initial load, set the selected platforms to all the platforms
		if (addLocationDetailsFormDataFromLocalStorage?.[bizId]) {
			const _selectedPlatforms = {};
			const _location = addLocationDetailsFormDataFromLocalStorage?.[bizId]?.[0];
			if (isMultibrandEnabled) {
				const _brands = _location?.brands || [];
				_brands.forEach((brand) => {
					if (brand?.platforms?.length) {
						brand.platforms.forEach((platform) => {
							_selectedPlatforms[platform.id] = true;
						});
					}
				});
			} else {
				if (_location?.platforms?.length) {
					_location.platforms.forEach((platform) => {
						_selectedPlatforms[platform.id] = true;
					});
				}
			}
			setSelectedPlatforms(_selectedPlatforms);
		}
	}, [bizPlatforms, brandsList]);

	useEffect(() => {
		const methodSteps = selectedOnboardingMethod?.steps;
		if (selectedMethodStep === methodSteps?.CONFIGURE_LOCATIONS_PLATFORMS && !areBizPlatformsSelected()) {
			setSelectedMethodStep(methodSteps.SELECT_PLATFORMS);
		}
	}, [selectedPlatforms]);

	useEffect(() => {
		const _locationFormData = cloneDeep(locationFormData);
		if (!_locationFormData) return;
		_locationFormData.forEach((location, index) => {
			if (!location?.brands?.length) {
				location.brands = cloneDeep(brandsList);
			}
		});
		setLocationFormData(_locationFormData);
	}, [brandsList]);

	useEffect(() => {
		if (Object.keys(selectedPlatforms).length !== 0) {
			const _locationFormData = cloneDeep(locationFormData);
			for (let key in selectedPlatforms) {
				if (selectedPlatforms[key]) {
					_locationFormData.forEach((location, index) => {
						const bizPlatform = bizPlatforms?.items?.find((bizPl) => bizPl.id === key);
						// cloneDeep to avoid memory reference issues
						if (bizPlatform) {
							location.platforms = cloneDeep(
								uniqBy([...location.platforms, bizPlatform], "platformName")
							);
							if (isMultibrandEnabled) {
								if (location.brands?.length) {
									location.brands.forEach((brand) => {
										brand.platforms = cloneDeep(
											uniqBy([...(brand.platforms || []), bizPlatform], "platformName")
										);
									});
								}
							}
						}
					});
				} else {
					_locationFormData.forEach((location, index) => {
						const bizPlatform = bizPlatforms?.items?.find((bizPl) => bizPl.id === key);
						if (bizPlatform) {
							location.platforms = location.platforms.filter((plf) => plf.id !== key);
							if (isMultibrandEnabled) {
								if (location.brands?.length) {
									location.brands.forEach((brand) => {
										brand.platforms = (brand.platforms || []).filter((plf) => plf.id !== key);
									});
								}
							}
						}
					});
				}
			}
			setLocationFormData(_locationFormData);
		}
	}, [selectedPlatforms]);

	useEffect(() => {
		// if no location data is present, add 7 rows
		lS.set(LS_ENUMS.ONBOARDING_ADD_LOCATION_DETAILS, {
			[bizId]: locationFormData
		});
	}, [locationFormData]);

	useEffect(() => {
		// scroll to the top
		if (containerRef?.current) {
			scroll({ top: containerRef?.current?.offset - 57, left: 0 }, window, "instant");
		}
	}, []);

	const fetchBrandsList = async () => {
		const brands = await fetchBrands("", false);
		setBrandsList(brands);
	};

	const handleBrandSelection = (brandIds) => {
		const _selectedBrandIds = { ...selectedBrandIds };
		if (_selectedBrandIds[brandIds]) {
			_selectedBrandIds[brandIds] = false;
		} else {
			_selectedBrandIds[brandIds] = true;
		}
		setSelectedBrandIds(_selectedBrandIds);
	};

	const handleAddLocationDetailsFormData = (field, value, rowIndex) => {
		const updatedLocationFormData = cloneDeep(locationFormData);
		updatedLocationFormData[rowIndex][field] = value;
		setLocationFormData(updatedLocationFormData);
	};

	const handlePLAFormData = (field, value, platformId, brandId, rowIndex) => {
		const _locationFormData = cloneDeep(locationFormData);
		if (isMultibrandEnabled) {
			_locationFormData[rowIndex].brands.forEach((brand) => {
				if (brand.id === brandId) {
					brand.platforms.forEach((platform) => {
						if (platform?.id === platformId) {
							platform[field] = value;
						}
					});
				}
			});
			setLocationFormData(_locationFormData);
		} else {
			_locationFormData[rowIndex].platforms.forEach((platform) => {
				if (platform?.id === platformId) {
					platform[field] = value;
				}
			});
			setLocationFormData(_locationFormData);
		}
	};

	const areRequiredLocationDetailsFilled = () => {
		if (locationFormData?.length > 0) {
			let allFieldsFilled = true;
			let numLocationsFilled = 0;
			(locationFormData ?? []).forEach((loc) => {
				if (loc.city && loc.bizLocationNickname) {
					numLocationsFilled += 1;
				}
				if (!loc.city && !loc.bizLocationNickname) {
					return;
				}
				if (!loc.city || !loc.bizLocationNickname) {
					allFieldsFilled = false;
				}
			});
			return numLocationsFilled > 0 && allFieldsFilled;
		}
		return !!(locationFormData?.length === 0);
	};

	const areBizPlatformsSelected = () => {
		const selectedBizPlatforms = Object.keys(selectedPlatforms).filter((key) => selectedPlatforms[key]);
		return selectedBizPlatforms.length > 0;
	};

	const isContinueDisabled = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		switch (selectedMethodStep) {
			case methodSteps.ADD_LOCATION_DETAILS:
				return !areRequiredLocationDetailsFilled();

			case methodSteps.SELECT_PLATFORMS:
				// return !areBizPlatformsSelected();
				return false;

			case methodSteps.CONFIGURE_LOCATIONS_PLATFORMS:
				return false;

			default:
				return false;
		}
	};

	const getFooterPrimaryBtnTxt = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		switch (selectedMethodStep) {
			case methodSteps.ADD_LOCATION_DETAILS:
				// return "Continue";
				return "Create";

			case methodSteps.SELECT_PLATFORMS:
				if (areBizPlatformsSelected()) {
					return "Continue";
				}
				return "Skip and Continue";

			case methodSteps.CONFIGURE_LOCATIONS_PLATFORMS:
				return "Continue";

			default:
				return "Continue";
		}
	};

	const validateAddLocationsFormData = () => {
		let _locationFormData = cloneDeep(locationFormData);
		let isValid = false;
		const validations = {};

		_locationFormData = _locationFormData.filter((location, index) => {
			if (location.bizLocationNickname || location.bizAddress || location?.city?.value) {
				const isBizLocationNicknameValid = location.bizLocationNickname ? true : false;
				const isCityValid = location?.city?.value ? true : false;

				validations[index] = {
					bizLocationNickname: isBizLocationNicknameValid ? "" : "Location nickname is required",
					city: isCityValid ? "" : "City is required"
				};

				if (isBizLocationNicknameValid && isCityValid) {
					isValid = true;
				}
				return true;
			} else {
				return false;
			}
		});

		if (isValid) {
			// removes unwanted rows if the data is valid
			setLocationFormData(_locationFormData);
			setAddLocationDetailsFormValidations(validations);
			return true;
		}

		setAddLocationDetailsFormValidations(validations);
		return false;
	};

	const validatePLAFormData = () => {
		const _locationFormData = cloneDeep(locationFormData);
		let isValid = true;
		const validations = {};
		const plaIds = [];

		if (isMultibrandEnabled) {
			_locationFormData.forEach((location, i) => {
				const brands = location.brands;
				brands.forEach((brand, j) => {
					const platforms = brand.platforms;
					platforms.forEach((plf, k) => {
						let platformUrlInvalidMessage = "Invalid platform URL";
						let platformIdInvalidMessage = "Platform ID is required";
						let isPlatformUrlValid = false;
						let isPlatformIdValid = false;

						if (plf.platformUrl && plf?.platformUrl?.trim()) {
							isPlatformUrlValid = validatePlatformUrl(plf.platformName, plf.platformUrl);
						}

						if (plf.platformId && plf?.platformId?.trim()) {
							if (!plaIds.includes(plf.platformId)) {
								isPlatformIdValid = true;
								plaIds.push(plf.platformId);
							} else {
								isPlatformIdValid = false;
								platformIdInvalidMessage = "Platform ID should be unique";
							}
						}

						if (validations[i] === undefined) {
							validations[i] = {
								platforms: {}
							};
						}

						if (validations[i]["platforms"][brand.id] === undefined) {
							validations[i]["platforms"][brand.id] = {};
						}

						if ((plf.platformId && !isPlatformIdValid) || (plf.platformUrl && !isPlatformUrlValid)) {
							isValid = false;
						}

						validations[i]["platforms"][brand.id][plf.id] = {
							platformUrl: !plf.platformUrl || isPlatformUrlValid ? "" : platformUrlInvalidMessage,
							platformId: !plf.platformId || isPlatformIdValid ? "" : platformIdInvalidMessage
						};
					});
				});
			});
		} else {
			_locationFormData.forEach((location, i) => {
				const platforms = location.platforms;
				platforms.forEach((plf, j) => {
					let platformUrlInvalidMessage = "Invalid platform URL";
					let platformIdInvalidMessage = "Platform ID is required";
					let isPlatformUrlValid = false;
					let isPlatformIdValid = false;

					if (plf.platformUrl && plf?.platformUrl?.trim()) {
						isPlatformUrlValid = validatePlatformUrl(plf.platformName, plf.platformUrl);
					}

					if (plf.platformId && plf?.platformId?.trim()) {
						if (!plaIds.includes(plf.platformId)) {
							isPlatformIdValid = true;
							plaIds.push(plf.platformId);
						} else {
							isPlatformIdValid = false;
							platformIdInvalidMessage = "Platform ID should be unique";
						}
					}

					if (validations[i] === undefined) {
						validations[i] = {
							platforms: {}
						};
					}

					if ((plf.platformId && !isPlatformIdValid) || (plf.platformUrl && !isPlatformUrlValid)) {
						isValid = false;
					}

					validations[i]["platforms"][plf.id] = {
						platformUrl: !plf.platformUrl || isPlatformUrlValid ? "" : platformUrlInvalidMessage,
						platformId: !plf.platformId || isPlatformIdValid ? "" : platformIdInvalidMessage
					};
				});
			});
		}

		setPLAFormValidations(validations);
		return isValid;
	};

	const handleContinue = () => {
		const methodSteps = selectedOnboardingMethod?.steps;
		// const currentMethodStepIndex = Object.values(methodSteps).indexOf(selectedMethodStep);
		switch (selectedMethodStep) {
			case methodSteps.ADD_LOCATION_DETAILS:
				const isValid = validateAddLocationsFormData();
				if (isValid) {
					// setSelectedMethodStep(methodSteps.SELECT_PLATFORMS);
					onSubmitCreateLocationsManually();
				}
				break;
			case methodSteps.SELECT_PLATFORMS:
				if (areBizPlatformsSelected()) {
					setSelectedMethodStep(methodSteps.CONFIGURE_LOCATIONS_PLATFORMS);
				} else {
					onSubmitCreateLocationsManually();
				}
				break;
			case methodSteps.CONFIGURE_LOCATIONS_PLATFORMS:
				const isPlatformDataValid = validatePLAFormData();
				if (isPlatformDataValid) {
					onSubmitCreateLocationsManually();
				}
				break;
			default:
				break;
		}
	};

	const handleBack = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		const currentMethodStepIndex = Object.values(methodSteps).indexOf(selectedMethodStep);
		if (currentMethodStepIndex > 0) {
			setSelectedMethodStep(Object.values(methodSteps)[currentMethodStepIndex - 1]);
		} else {
			history.push("/onboarding");
		}
	};

	const onTrySubmitCreateLocationsManually = () => {
		setError(false);
		onSubmitCreateLocationsManually();
	};

	const onSubmitCreateLocationsManually = useCallback(async () => {
		setLoading(true);
		try {
			const variables = { bizLocation: [] };

			locationFormData
				.filter((location) => location?.bizLocationNickname && location?.city?.value)
				.forEach((location, index) => {
					const { bizLocationNickname, city, bizAddress, brands, platforms } = location;

					const bizLocation = {
						bizLocationNickname: bizLocationNickname,
						city: city?.value || null,
						bizAddress: bizAddress,
						merchantBizLocationId: "-1",
						sortOrder: index
					};

					if (isMultibrandEnabled) {
						bizLocation.brands = brands.map((brand) => {
							return {
								brandId: brand.id,
								platforms: (brand.platforms ?? []).map((platform) => {
									return {
										platformName: platform.platformName,
										externalId: platform.platformId,
										outletUrl: platform.platformUrl
									};
								})
							};
						});
					} else {
						bizLocation.platforms = (platforms ?? []).map((platform) => {
							return {
								platformName: platform.platformName,
								externalId: platform.platformId,
								outletUrl: platform.platformUrl
							};
						});
					}
					variables.bizLocation.push(bizLocation);
				});
			const resp = await client.mutate({
				mutation: CREATE_LOCATION,
				variables
			});
			if (resp.data.createBrandLocations?.status?.success) {
				setSuccess(true);
				setLocationFormData([]);

				// track event
				trackEvent("onboarding_location_complete", {
					method: "manual"
				});
			} else {
				if (resp.data.createBrandLocations?.status?.messages.length) {
					const errors = resp.data.createBrandLocations?.status?.messages;
					let updatedValidations = {};
					let foundErrors = true;
					errors.forEach((error) => {
						let field = error.field.split("#");
						switch (field.length) {
							case 2: {
								const f = FORM_FIELDS_MAP[camelCase(field[1])] || camelCase(field[1]);
								updatedValidations[field[0]][f] = error.message;
								break;
							}

							case 3: {
								const f = FORM_FIELDS_MAP[camelCase(field[2])] || camelCase(field[2]);
								updatedValidations = {
									...updatedValidations,
									[field[0]]: {
										...(updatedValidations?.[field[0]] ?? {}),
										platforms: {
											...(updatedValidations?.[field[0]]?.platforms ?? {}),
											[locationFormData?.[field[0]]?.platforms?.[field[1]]?.id]: {
												...(updatedValidations?.[field[0]]?.platforms?.[
													locationFormData?.[field[0]]?.platforms?.[field[1]]?.id
												] ?? {}),
												[f]: error.message
											}
										}
									}
								};
								break;
							}
							case 4: {
								const f = FORM_FIELDS_MAP[camelCase(field[3])] || camelCase(field[3]);
								updatedValidations = {
									...updatedValidations,
									[field[0]]: {
										...(updatedValidations?.[field[0]] ?? {}),
										platforms: {
											...(updatedValidations?.[field[0]]?.platforms ?? {}),
											[locationFormData?.[field[0]]?.brands?.[field[1]]?.id]: {
												...(updatedValidations?.[field[0]]?.platforms?.[
													locationFormData?.[field[0]]?.brands?.[field[1]]?.id
												] ?? {}),
												[locationFormData?.[field[0]]?.brands?.[field[1]]?.platforms?.[field[2]]
													?.id]: {
													...(updatedValidations?.[field[0]]?.platforms?.[
														locationFormData?.[field[0]]?.brands?.[field[1]]?.platforms?.[
															field[2]
														]?.id
													] ?? {}),
													[f]: error.message
												}
											}
										}
									}
								};
								break;
							}

							default: {
								return null;
							}
						}
					});

					if (Object.keys(updatedValidations).length) {
						setPLAFormValidations(updatedValidations);
					}
				}
			}
		} catch (error) {
			setError(true);
		}
		setLoading(false);
	}, [locationFormData]);

	if (!selectedOnboardingMethod?.id) {
		return (
			<div className="onboarding-locations section-container-common" ref={containerRef}>
				<OnboardingSetupHeader title="Setup Locations" />
				<ChooseOnboardingMethod
					methods={cloneDeep(LOCATION_ONBOARDING_METHODS)
						.sort((a, b) => a.comingSoon - b.comingSoon)
						.filter((methods) =>
							isMultibrandEnabled
								? methods.title !== "Import from third party integrations like DSP and POS"
								: true
						)}
					setSelectedOnboardingMethod={handleSelectOnboardingMethod}
					subHeaderText="Choose a method to onboard your restaurant details on UrbanPiper"
					headerText="How would you like to set up your restaurant locations?"
				/>
			</div>
		);
	}

	if (success) {
		return (
			<div className="onboarding-locations section-container-common" ref={containerRef}>
				<OnboardingSetupHeader
					title="Locations"
					breadcrumbsTitle="Setup Locations"
					onCtaClick={() => setSelectedOnboardingMethod({})}
				/>
				<LocationCreationSuccess autoRedirect={false} title={"Your locations were successfully created"} />
			</div>
		);
	}

	if (error) {
		return (
			<div className="onboarding-locations section-container-common" ref={containerRef}>
				<OnboardingSetupHeader
					title="Error creating locations"
					breadcrumbsTitle="Setup Locations"
					onCtaClick={() => setSelectedOnboardingMethod({})}
				/>
				<LocationCreationFailure onTryAgain={onTrySubmitCreateLocationsManually} />
			</div>
		);
	}

	return (
		<div className="onboarding-locations section-container-common" ref={containerRef}>
			{selectedOnboardingMethod?.id === LOCATION_ONBOARDING_METHODS[2].id && (
				<React.Fragment>
					<OnboardingSetupHeader
						title="Setup Manually"
						// totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
						// currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
						loading={loading}
						onCtaClick={() => setSelectedOnboardingMethod({})}
						breadcrumbsTitle="Setup Locations"
					/>
					<ConfigureManually
						handleAddMoreRows={handleAddMoreRows}
						locationFormData={locationFormData}
						addLocationDetailsFormValidations={addLocationDetailsFormValidations}
						handleAddLocationDetailsFormData={handleAddLocationDetailsFormData}
						selectedPlatforms={selectedPlatforms}
						setSelectedPlatforms={setSelectedPlatforms}
						selectedMethodStep={selectedMethodStep}
						setSelectedMethodStep={setSelectedMethodStep}
						handlePLAFormData={handlePLAFormData}
						PLAFormValidations={PLAFormValidations}
					/>
					<Footer
						isContinueDisabled={isContinueDisabled()}
						handleBack={handleBack}
						handleContinue={handleContinue}
						isLoading={loading}
						primaryBtnTxt={getFooterPrimaryBtnTxt()}
					/>
				</React.Fragment>
			)}
		</div>
	);
}

const mapStateToProps = (store) => ({
	bizId: store?.login?.loggedInbizDetail?.id,
	bizCountry: store.login.loggedInbizDetail.country,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	bizPlatforms: store?.configItems.bizPlatforms
});

export default connect(mapStateToProps)(OnboardingLocations);
