import React, { useState, useEffect, useCallback, useRef } from "react";

// components
import OnboardingSetupHeader from "../components/OnboardingCommon/OnboardingSetupHeader";
import ChooseOnboardingMethod from "../components/OnboardingCommon/ChooseOnboardingMethod";
import ChooseBrands from "../components/OnboardingCommon/ChooseBrands";
import Footer from "../components/OnboardingCommon/Footer";
import ChooseMenuPullSource from "../components/OnboardingMenus/ChooseMenuPullSource";
import PreviewMenuPullSource from "../components/OnboardingMenus/PreviewMenuPullSource";
import AddPlatformUrl from "../components/OnboardingMenus/AddPlatformUrl";
import MenuCreationStatus from "../components/OnboardingMenus/MenuCreationStatus";
import MenusList from "./MenusList";
import { NestedEntityContainer } from "../components/_commons/NestedEntityContainer";
import HorizonBanner from "@urbanpiper-engineering/horizon/dist/base/HorizonBanner";

// store
import { store } from "../store/configureStore";

// graphql
import { client } from "../client";
import { URL_MENU_PULL } from "../graphql/misc";

// utils
import {
	validatePlatformUrl,
	lS,
	adjustNestedContainer,
	scroll,
	trackEvent,
	capitaliseTextStrict
} from "../atlas-utils";
import history from "../history";

// third party
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import cloneDeep from "lodash/cloneDeep";

// actions
import { ActionTypes } from "../actions/_types";
import { fetchBrands, fetchBizPlatforms } from "../actions/actions";

// constants
import { NESTED_ENTITY_TYPES } from "../client-config";
const NESTED_ENTITY_INITIAL_STATE = {
	show: false,
	type: null,
	id: null
};
const IMPORT_MENU_FROM_FOOD_DELIVERY_PLATFORMS_OR_POS = {
	SELECT_SERVICE: "selectService",
	PREVIEW_SERVICE_DETAILS: "previewServiceDetails",
	ADD_PLATFORM_URL: "addPlatformURL"
};
const CONFIGURE_YOUR_RESTAURANT_MENU_MANUALLY = {
	CREATE_MENU: "createMenu"
};
const IS_MULTIBRAND_ENABLED = store?.getState()?.login?.loggedInbizDetail?.isMultibrandEnabled || false;
const MENU_ONBOARDING_METHODS = [
	{
		id: uuidv4(),
		title: "Import menu from online ordering platform/POS",
		recommended: true,
		comingSoon: false,
		image: "/assets/third-party-location-onboarding.png",
		onCtaClick: () => {},
		steps: IS_MULTIBRAND_ENABLED
			? { SELECT_BRAND: "selectBrand", ...IMPORT_MENU_FROM_FOOD_DELIVERY_PLATFORMS_OR_POS }
			: IMPORT_MENU_FROM_FOOD_DELIVERY_PLATFORMS_OR_POS
	},
	{
		id: uuidv4(),
		title: "Upload restaurant menu as a spreadsheet",
		recommended: false,
		comingSoon: true,
		image: "/assets/spreadsheet-location-onboarding.png",
		onCtaClick: () => {},
		steps: {}
	},
	{
		id: uuidv4(),
		title: "Configure your restaurant menu manually",
		recommended: false,
		comingSoon: false,
		image: "/assets/manual-location-onboarding.png",
		onCtaClick: () => {},
		steps: IS_MULTIBRAND_ENABLED
			? { SELECT_BRAND: "selectBrand", ...CONFIGURE_YOUR_RESTAURANT_MENU_MANUALLY }
			: CONFIGURE_YOUR_RESTAURANT_MENU_MANUALLY
	}
];

function OnboardingMenus({ isMultibrandEnabled, bizPlatforms, menuPullUrlState, menuPullUrlBrand }) {
	const [selectedOnboardingMethod, setSelectedOnboardingMethod] = useState({});
	const [selectedMethodStep, setSelectedMethodStep] = useState(null);
	const [showNoLocationAssociationBanner, setShowNoLocationAssociationBanner] = useState(false);
	const [brandsList, setBrandsList] = useState([]);
	const [selectedBrand, setSelectedBrand] = useState({});
	const [selectedPlatform, setSelectedPlatorm] = useState({});
	const [sourceURL, setSourceURL] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [validationMessage, setValidationMessage] = useState("");
	const [areBrandsLoading, setAreBrandsLoading] = useState(false);
	const [disabledBrands, setDisabledBrands] = useState([]);
	const [nestedEntity, setNestedEntity] = useState(NESTED_ENTITY_INITIAL_STATE);
	const [isMenuCreateOpen, setMenuCreateOpen] = useState(false);
	const nestedRef = useRef();
	const containerRef = useRef();

	useEffect(() => {
		if (isMultibrandEnabled) {
			if (selectedMethodStep === MENU_ONBOARDING_METHODS[0].steps.SELECT_BRAND) fetchBrandsList();
		}
		fetchBizPlatforms(false, false, false, [
			{
				field: "is_indexing_supported",
				value: "true"
			}
		]);
	}, [isMultibrandEnabled, selectedMethodStep]);

	useEffect(() => {
		// scroll to the top
		if (containerRef?.current) {
			scroll({ top: containerRef?.current?.offset - 57, left: 0 }, window, "instant");
		}
	}, []);

	useEffect(() => {
		switch (selectedOnboardingMethod?.id) {
			case MENU_ONBOARDING_METHODS[0].id:
				isMultibrandEnabled
					? setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.SELECT_BRAND)
					: setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.SELECT_SERVICE);
				break;

			case MENU_ONBOARDING_METHODS[1].id:
				break;

			case MENU_ONBOARDING_METHODS[2].id:
				isMultibrandEnabled
					? setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.SELECT_BRAND)
					: setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.CREATE_MENU);
				break;

			default:
				break;
		}
		// scroll to the top
		if (containerRef?.current) {
			scroll({ top: containerRef?.current?.offset - 57, left: 0 }, window, "instant");
		}
	}, [selectedOnboardingMethod]);

	const handleNestedEntity = useCallback((toOpen = false, type, record) => {
		if (!toOpen) {
			setMenuCreateOpen(false);
			setTimeout(() => setNestedEntity(NESTED_ENTITY_INITIAL_STATE), 100);
		} else {
			setNestedEntity({
				show: true,
				type
			});
			setTimeout(() => setMenuCreateOpen(true), 60);
		}
		adjustNestedContainer(toOpen);
	}, []);

	const handleCreateNewMenu = () => {
		handleNestedEntity(true, NESTED_ENTITY_TYPES[19]);
	};

	const handleMenuPullWithURL = async () => {
		setLoading(true);
		try {
			//reset the redux state so that the next URL pull doesnt carry previous result by default.
			store.dispatch({
				type: ActionTypes.MENU_PULL_FROM_URL_STATUS_UPDATE,
				payload: {
					status: "default",
					brandId: null
				}
			});
			const response = await client.mutate({
				mutation: URL_MENU_PULL,
				variables: {
					platform: selectedPlatform?.platformName?.replace(/ /g, "")?.toLowerCase(),
					menuUrl: sourceURL,
					brandId: selectedBrand?.id || null
				}
			});
			if (response?.data?.urlMenuPull?.status?.success) {
				setSuccess(true);
				if (isMultibrandEnabled) {
					const _disabledBrands = brandsList
						.filter((brand) => brand["lockedForMenuUrlPull"] == true)
						.map((brand) => brand.id);

					setDisabledBrands(_disabledBrands);
				}
				// track event
				trackEvent("onboarding_menu_requested", {
					method: capitaliseTextStrict(selectedPlatform?.platformName, true)
				});
			} else {
				setError(false);
			}
			setSourceURL("");
		} catch (error) {
			store.dispatch({
				type: ActionTypes.MENU_PULL_FROM_URL_STATUS_UPDATE,
				payload: {
					status: "failure",
					brandId: selectedBrand.id ?? null
				}
			});
			setSourceURL("");
			setError(true);
		}
		setLoading(false);
	};

	const fetchBrandsList = async () => {
		setAreBrandsLoading(true);
		const brands = await fetchBrands("", false, 50, "ONBOARDING");
		setBrandsList(brands);
		const disabledBrandList = brands
			.filter((brand) => brand["lockedForMenuUrlPull"] == true)
			.map((brand) => brand.id);

		const brandWithoutLocation = brands.find((brand) => brand["brandLocationsCount"] == 0);
		setShowNoLocationAssociationBanner(Boolean(brandWithoutLocation));

		setDisabledBrands(disabledBrandList);
		setAreBrandsLoading(false);
	};

	const isContinueDisabled = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		switch (selectedMethodStep) {
			case methodSteps.SELECT_BRAND:
				return !selectedBrand?.id;
			case methodSteps.SELECT_SERVICE:
				return !selectedPlatform?.platformName;
			case methodSteps.PREVIEW_SERVICE_DETAILS:
				return !selectedPlatform?.platformName;
			case methodSteps.ADD_PLATFORM_URL:
				return !sourceURL;
			default:
				return true;
		}
	};

	const handleBack = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		const currentMethodStepIndex = Object.values(methodSteps).indexOf(selectedMethodStep);
		if (currentMethodStepIndex > 0) {
			setSelectedMethodStep(Object.values(methodSteps)[currentMethodStepIndex - 1]);
		} else {
			setSelectedOnboardingMethod({});
		}
		setSourceURL("");
	};

	const handleContinue = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		switch (selectedMethodStep) {
			case methodSteps.SELECT_BRAND:
				if (methodSteps.SELECT_SERVICE) {
					setSelectedMethodStep(methodSteps.SELECT_SERVICE);
				}
				if (methodSteps.CREATE_MENU) {
					// update selected brand in menu create drawer
					if (isMultibrandEnabled) {
						store.dispatch({
							type: ActionTypes.UPDATE_MENUS_LIST_DATA,
							payload: {
								key: "selectedBrand",
								value: selectedBrand || null
							}
						});
					}
					setSelectedMethodStep(methodSteps.CREATE_MENU);
					handleCreateNewMenu();
				}
				break;
			case methodSteps.SELECT_SERVICE:
				setSelectedMethodStep(methodSteps.PREVIEW_SERVICE_DETAILS);
				break;
			case methodSteps.PREVIEW_SERVICE_DETAILS:
				setSelectedMethodStep(methodSteps.ADD_PLATFORM_URL);
				break;
			case methodSteps.ADD_PLATFORM_URL:
				if (sourceURL?.trim()) {
					const isValid = validatePlatformUrl(selectedPlatform?.platformName, sourceURL);
					if (isValid) {
						handleMenuPullWithURL();
						setValidationMessage("");
					} else {
						setValidationMessage("Please enter a valid URL");
					}
				} else {
					setValidationMessage("Please enter a valid URL");
				}
				break;
			default:
				break;
		}
	};

	const handlePlatformSelection = (platform) => {
		if (platform?.platformName) {
			setSelectedPlatorm(platform);
			setSelectedMethodStep(IMPORT_MENU_FROM_FOOD_DELIVERY_PLATFORMS_OR_POS.PREVIEW_SERVICE_DETAILS);
		}
	};

	const onBackToSetup = () => {
		setSelectedOnboardingMethod(null);
		setSelectedOnboardingMethod(null);
		setSelectedMethodStep(null);
		setSourceURL("");
		setError(null);
		setSuccess(null);
		setSelectedBrand(null);
	};

	const onTryAgain = () => {
		const selectedMethodSteps = selectedOnboardingMethod?.methodSteps || [];
		const lastStep = selectedMethodSteps?.[selectedMethodStep?.length - 1];
		setSelectedMethodStep(lastStep || null);
	};

	const onViewMenu = () => {
		history.push("/menus");
	};

	const onSetupNextBrand = () => {
		if (isMultibrandEnabled) {
			setSelectedBrand(null);
			setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.SELECT_BRAND);
		} else {
			setSelectedMethodStep(MENU_ONBOARDING_METHODS[0].steps.SELECT_SERVICE);
		}
		setSourceURL("");
		setError(null);
		setSuccess(null);
		setSelectedPlatorm(null);
	};

	const renderCurrentStep = () => {
		const methodSteps = selectedOnboardingMethod.steps;
		if (selectedOnboardingMethod?.id === MENU_ONBOARDING_METHODS[0].id) {
			switch (selectedMethodStep) {
				case methodSteps.SELECT_BRAND:
					return (
						<React.Fragment>
							<OnboardingSetupHeader
								title="Select brand"
								totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
								currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
								loading={loading}
								onCtaClick={() => setSelectedOnboardingMethod({})}
								breadcrumbsTitle="Setup Menus"
							/>

							{showNoLocationAssociationBanner && (
								<div className="onboarding-banner-card">
									<HorizonBanner
										bannerHeading={"Some brands do not have a location associated"}
										bannerText={
											"Please note that the menu import feature is only available for brands with a location associated. If you have a brand without a location, please add a location to proceed."
										}
										status="warning"
									/>
								</div>
							)}

							<ChooseBrands
								brandsList={brandsList}
								setSelectedBrand={setSelectedBrand}
								selectedBrand={selectedBrand}
								disabledBrands={disabledBrands}
								loading={areBrandsLoading}
							/>
							<Footer
								isContinueDisabled={isContinueDisabled()}
								handleBack={handleBack}
								handleContinue={handleContinue}
								isLoading={loading}
							/>
						</React.Fragment>
					);
				case methodSteps.SELECT_SERVICE:
					return (
						<React.Fragment>
							<OnboardingSetupHeader
								title="Select service"
								totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
								currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
								loading={loading}
								onCtaClick={() => setSelectedOnboardingMethod({})}
								breadcrumbsTitle="Setup Menus"
							/>
							<ChooseMenuPullSource
								selectedBrand={selectedBrand}
								bizPlatforms={bizPlatforms}
								handlePlatformSelection={handlePlatformSelection}
								onBackToSetup={onBackToSetup}
							/>
							<Footer
								isContinueDisabled={isContinueDisabled()}
								handleBack={handleBack}
								handleContinue={handleContinue}
								isLoading={loading}
								showContinue={false}
							/>
						</React.Fragment>
					);
				case methodSteps.PREVIEW_SERVICE_DETAILS:
					return (
						<React.Fragment>
							<OnboardingSetupHeader
								title="Preview service details"
								totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
								currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
								loading={loading}
								onCtaClick={() => setSelectedOnboardingMethod({})}
								breadcrumbsTitle="Setup Menus"
							/>
							<PreviewMenuPullSource
								selectedPlatform={selectedPlatform}
								onProceed={handleContinue}
								onBack={handleBack}
							/>
						</React.Fragment>
					);
				case methodSteps.ADD_PLATFORM_URL:
					return (
						<React.Fragment>
							<OnboardingSetupHeader
								title="Add platform URL"
								totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
								currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
								loading={loading}
								onCtaClick={() => setSelectedOnboardingMethod({})}
								breadcrumbsTitle="Setup Menus"
							/>
							<AddPlatformUrl
								selectedPlatform={selectedPlatform}
								selectedBrand={selectedBrand}
								sourceURL={sourceURL}
								setSourceURL={setSourceURL}
								validationMessage={validationMessage}
							/>
							<Footer
								isContinueDisabled={isContinueDisabled()}
								handleBack={handleBack}
								handleContinue={handleContinue}
								isLoading={loading}
							/>
						</React.Fragment>
					);
				default:
					return null;
			}
		} else if (selectedOnboardingMethod?.id === MENU_ONBOARDING_METHODS[1].id) {
			return null;
		} else {
			switch (selectedMethodStep) {
				case methodSteps.SELECT_BRAND:
					return (
						<React.Fragment>
							<OnboardingSetupHeader
								title="Select brand"
								totalSteps={Object.keys(selectedOnboardingMethod.steps).length}
								currentStep={Object.values(selectedOnboardingMethod.steps).indexOf(selectedMethodStep)}
								loading={loading}
								onCtaClick={() => setSelectedOnboardingMethod({})}
								breadcrumbsTitle="Setup Menus"
							/>
							<ChooseBrands
								brandsList={brandsList}
								setSelectedBrand={setSelectedBrand}
								selectedBrand={selectedBrand}
								disabledBrands={disabledBrands}
								loading={areBrandsLoading}
							/>
							<Footer
								isContinueDisabled={isContinueDisabled()}
								handleBack={handleBack}
								handleContinue={handleContinue}
								isLoading={loading}
							/>
						</React.Fragment>
					);
				case methodSteps.CREATE_MENU:
					return (
						<React.Fragment>
							<MenusList
								onboardingFlow
								onCtaClick={() => setSelectedOnboardingMethod({})}
								handleCreateNewMenu={handleCreateNewMenu}
							/>
							<NestedEntityContainer
								isOpen={isMenuCreateOpen}
								show={nestedEntity.show}
								type={nestedEntity.type}
								id={nestedEntity.id}
								closeNestedContainer={() => handleNestedEntity(false)}
								nestedRef={nestedRef}
								isNested={false}
								isForeignSource={true}
								onboardingFlow={true}
							/>
						</React.Fragment>
					);
				default:
					return null;
			}
		}
	};

	if (!selectedOnboardingMethod?.id) {
		return (
			<div className="onboarding-menus section-container-common" ref={containerRef}>
				<OnboardingSetupHeader title="Setup Menus" />
				<ChooseOnboardingMethod
					methods={cloneDeep(MENU_ONBOARDING_METHODS).sort((a, b) => a.comingSoon - b.comingSoon)}
					setSelectedOnboardingMethod={setSelectedOnboardingMethod}
					subHeaderText="Choose a method to start onboarding your restaurant’s menu"
					headerText="Setup your restaurant menu"
				/>
			</div>
		);
	}

	if (success || error) {
		return (
			<div className="onboarding-menus section-container-common" ref={containerRef}>
				<OnboardingSetupHeader
					title="Menu Import"
					breadcrumbsTitle="Setup Menus"
					onCtaClick={() => setSelectedOnboardingMethod({})}
				/>
				<MenuCreationStatus
					selectedBrand={selectedBrand}
					onBackToSetup={onBackToSetup}
					onSetupNextBrand={onSetupNextBrand}
					onViewMenu={onViewMenu}
					onTryAgain={onTryAgain}
					state={menuPullUrlState}
					menuPullUrlBrand={menuPullUrlBrand}
				/>
			</div>
		);
	}

	return (
		<div className="onboarding-menus section-container-common" ref={containerRef}>
			{renderCurrentStep()}
		</div>
	);
}

const mapStateToProps = (store) => ({
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled,
	bizPlatforms: store?.configItems.bizPlatforms,
	menuPullUrlState: store?.menuPullFromUrlState?.status,
	menuPullUrlBrand: store?.menuPullFromUrlState?.brandId
});

export default connect(mapStateToProps)(OnboardingMenus);
