import React, { useEffect, useRef, useState } from "react";
import { ButtonIcon } from "../_commons/ButtonIcon";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { scroll, trackEvent } from "../../atlas-utils";
import MailIcon from "../_icons/MailIcon";
import CalendarTime from "../_icons/CalendarTime";
import FreeTrial from "../_commons/FreeTrial";
import { fetchConfigurationValues, saveConfigurationValues } from "../../actions/actions";
import { connect } from "react-redux";

const ScheduleFreeTrial = ({ scheduleReportsList }) => {
	const history = useHistory();

	const freeTrial = scheduleReportsList?.schedule_reports_enabled?.value == "1";
	const firstTrialRender = useRef(0);
	if (freeTrial && firstTrialRender.current == 0) {
		history.push("/reports/list/schedules");
	}
	const [submitBtnLoading, setSubmitBtnLoading] = useState(false);

	const getFreeTrialReportData = async () => {
		const resp = await fetchConfigurationValues({
			keys: ["schedule_reports_enabled"]
		});
	};

	const handleSelect = async () => {
		if (!freeTrial) {
			setSubmitBtnLoading(true);
			firstTrialRender.current = 1;
			const status = await saveConfigurationValues({
				configurations: [
					{
						key: "schedule_reports_enabled",
						value: "1"
					}
				]
			});
			if (status) {
				trackEvent("schedule_reports_free_trial_confirm");
			}
			setSubmitBtnLoading(false);
		} else {
			history.push("/reports/list/schedules/confirm-free-trial/schedule-first-report", {
				state: { pushTo: "/reports/list/schedules/confirm-free-trial" }
			});
		}
	};

	const handleBack = () => {
		history.push("/reports/list/schedules");
	};
	useEffect(() => {
		if (!freeTrial) {
			getFreeTrialReportData();
		}
		scroll();
	}, []);

	return (
		<div className="schedule-report-intro">
			<FreeTrial
				heading={!freeTrial ? "Get free trial" : "Free trial started"}
				successIcon={freeTrial}
				submitTitle={!freeTrial ? "Confirm free trial" : "Schedule your first report"}
				submitBtn
				submitClass={"submit-class"}
				cancelBtn={!freeTrial}
				submitBtnLoading={submitBtnLoading}
				handleSubmit={handleSelect}
				handleCancel={handleBack}
				infoText={!freeTrial ? "Free trial access for limited time." : ""}
			>
				<div className="plans">
					<div className="svg">
						<CalendarTime styles={{ height: "20px", width: "20px" }} color={"#6D7AA8"} />
					</div>
					<div className="text">
						Create schedule <span className="text-bold">Unlimited</span>
					</div>
				</div>
				<div className="plans">
					<div className="svg">
						<MailIcon variations={{ width: "18px", height: "14px" }} color={"#6D7AA8"} />
					</div>
					<div className="text">
						Email notification recipient <span className="text-bold">Multiple</span>
					</div>
				</div>
				<div className="plans">
					<ButtonIcon classes="svg" icon="resume" color="#6D7AA8" />
					<div className="text">
						Pause / Resume schedules at <span className="text-bold">No Cost </span>
					</div>
				</div>
			</FreeTrial>
		</div>
	);
};

export default connect((store) => ({
	scheduleReportsList: store.scheduleReportsList,
	access: store.login.loginDetail.access
}))(ScheduleFreeTrial);
