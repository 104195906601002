import React, { useState, useEffect, useCallback } from "react";

// components
import { FormSidebar } from "../components/_commons/FormSidebar";
import { NewDateCompareFilter } from "../components/_commons/NewDateCompareFilter";
import ReportFilters from "../components/Reports/ReportFilters";
import ShareOnEmail from "../components/Reports/ShareOnEmail";
import StatusInfo from "../components/_commons/StatusInfo";

// third party
import history from "../history";
import { connect } from "react-redux";
import moment from "moment";

// store
import { store } from "../store/configureStore";

// utils
import { trackEvent } from "../atlas-utils";

// actions
import { ActionTypes } from "../actions/_types";
import {
	fetchReportDetail,
	generateReportV2,
	fetchPendingReportsTaskList,
	fetchReportPreview
} from "../actions/reports";

// constants
import { REPORTS_GROUP_MAP } from "../components/Reports/Overview";
const ALLOWED_MONTHS = 12;

const ReportDownload = ({
	match,
	reportDetail,
	isMultibrandEnabled = false,
	fromPreview = false,
	isOpen = false,
	close
}) => {
	const [isFormOpen, setFormOpen] = useState(isOpen);
	const [isFormTouched, setFormTouched] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [filtersState, setFiltersState] = useState({});
	const [showEmailExport, setShowEmailExport] = useState(false);
	const {
		loading,
		data,
		currentFilters,
		appliedFilters,
		currentDateFilter,
		appliedDateFilter,
		validations,
		optionUpdates,
		isCheckedAll,
		emails
	} = reportDetail;

	useEffect(() => {
		if (!fromPreview) {
			setTimeout(() => setFormOpen(true), 60);
			fetchReportDetail(`codex##${match.params.id}`);
		}
	}, [match.params.id]);

	useEffect(() => {
		if (isOpen && fromPreview) {
			setTimeout(() => setFormOpen(true), 60);
			setFiltersState({ appliedFilters, appliedDateFilter, optionUpdates, isCheckedAll, validations });
		}
	}, [isOpen]);

	const updateReportDetail = (payload) => {
		store.dispatch({
			type: ActionTypes.UPDATE_REPORT_DETAIL,
			payload
		});
	};

	const handleClose = useCallback(
		(reset = false) => {
			setFormOpen(false);

			// reset state
			if (reset && !fromPreview) {
				store.dispatch({
					type: ActionTypes.RESET_REPORT_DETAIL
				});
			}

			if (fromPreview) {
				close();
				if (reset) {
					setFiltersState({});
				} else {
					updateReportDetail({ ...filtersState });
				}
				return;
			}

			setTimeout(() => {
				history.push("/reports/list/overview");
			}, 100);
		},
		[filtersState]
	);

	const setFilter = (field, value) => {
		const filters = {
			...currentFilters,
			[field]: value
		};
		updateReportDetail({
			currentFilters: filters,
			appliedFilters: filters
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const setDateRangeFilter = (payload) => {
		updateReportDetail({
			...payload,
			validations: {
				...validations,
				timePeriod: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheck = (state, field, option, props) => {
		const updates = {
			...optionUpdates,
			[field]: {
				...optionUpdates[field],
				[option[props.valueKey]]: state
			}
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: false
		};
		let updatedLocationsList = [...(data.filters.find((f) => f.field === "locations")?.values ?? [])];
		// custom logic to handle locations filter for multi brand biz
		if (field === "brands") {
			if (state) {
				updatedLocationsList = [
					...updatedLocationsList,
					...data.locationsList.filter((loc) => loc.brandId === option[props.valueKey])
				];
			} else {
				updatedLocationsList = updatedLocationsList.filter((location) => {
					if (location.brandId === option[props.valueKey]) {
						updates.locations = {
							...(updates.locations ?? {}),
							[location.value]: false
						};
					}
					return location.brandId !== option[props.valueKey];
				});
			}
			isCheckedAllUpdates["locations"] = false;
		}
		updateReportDetail({
			data: {
				...data,
				filters: data.filters.map((f) => {
					if (f.field === "locations") {
						f.values = updatedLocationsList;
					}
					return f;
				})
			},
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleCheckAll = (state, field, options, props) => {
		const filterOptions = {};
		options.forEach((opt) => {
			filterOptions[opt[props.valueKey]] = state;
		});
		const updates = {
			...optionUpdates,
			[field]: filterOptions
		};
		const isCheckedAllUpdates = {
			...isCheckedAll,
			[field]: state
		};
		let updatedLocationsList = [...(data.filters.find((f) => f.field === "locations")?.values ?? [])];
		if (field === "brands") {
			if (state) {
				updatedLocationsList = data.locationsList;
				isCheckedAllUpdates["locations"] = false;
			} else {
				updates["locations"] = {};
				isCheckedAllUpdates["locations"] = state;
				updatedLocationsList = [];
			}
		}
		updateReportDetail({
			data: {
				...data,
				filters: data.filters.map((f) => {
					if (f.field === "locations") {
						f.values = updatedLocationsList;
					}
					return f;
				})
			},
			optionUpdates: updates,
			isCheckedAll: isCheckedAllUpdates,
			validations: {
				...validations,
				[field]: ""
			}
		});
		if (!isFormTouched) {
			setFormTouched(true);
		}
	};

	const handleValidations = () => {
		// validations for required fields
		let validationsFound = false;
		const updatedValidations = { ...validations };
		const validationMessage = "This field is required.";

		if (!appliedDateFilter?.current?.dateFilter) {
			validationsFound = true;
			updatedValidations.timePeriod = validationMessage;
		}

		data.filters
			.filter((f) => f.field !== "time_period")
			.forEach((f) => {
				const field = f?.field?.replace(/([-_]\w)/g, (f) => f[1]?.toUpperCase());
				if (f.required && Object.values(optionUpdates[field])?.filter((val) => val)?.length === 0) {
					validationsFound = true;
					updatedValidations[field] = validationMessage;
				}
			});

		return { validationsFound, updatedValidations };
	};

	const handleExportReport = async (exportType) => {
		const { validationsFound, updatedValidations } = handleValidations();
		if (validationsFound) {
			updateReportDetail({
				validations: updatedValidations
			});
			return;
		}

		// show email input drawer for report export
		if (exportType.id === "email" && !showEmailExport) {
			// close report detail
			setFormOpen(false);
			// open email export drawer
			setTimeout(() => setShowEmailExport(true), 200);
			return;
		}

		// export report
		setIsLoading(true);
		const status = await generateReportV2(exportType);
		if (status?.success) {
			// track event
			trackReportDownloadEvent(exportType);

			// get pending export report tasks to show in ongoing tasks component
			setTimeout(() => {
				fetchPendingReportsTaskList();
			}, 500);
			handleClose(true);
		}
		setIsLoading(false);
	};

	const handlePreviewReport = () => {
		const { validationsFound, updatedValidations } = handleValidations();
		if (validationsFound) {
			updateReportDetail({
				validations: updatedValidations
			});
			return;
		}

		// fetch preview
		fetchReportPreview();

		if (fromPreview) {
			handleClose(true);
			return;
		}

		history.push(
			`/reports/preview/${match.params.id}?name=${data?.name?.toLowerCase()?.split(" ")?.join("-")}&group=${
				data?.group
			}`
		);
	};

	const renderTitle = () => {
		if (data?.name) {
			return data?.name;
		}

		if (loading) {
			return (
				<div className="title-shimmer W(300px)">
					<div className="shimmer H(30px)" />
				</div>
			);
		}

		return "Report";
	};

	const renderSubTitle = () => {
		if (data?.description) {
			return data?.description;
		}

		if (loading) {
			return (
				<div className="subtitle-shimmer W(600px) Pb(5px) Pt(5px)">
					<div className="shimmer H(20px)" />
				</div>
			);
		}

		return "Export this report";
	};

	const trackReportDownloadEvent = (exportType) => {
		trackEvent("reports_download", {
			report_category: REPORTS_GROUP_MAP[data?.group]?.name || "",
			report_name: data?.name || "",
			report_format: exportType?.id?.toUpperCase() || "",
			report_emails: emails?.length || 0,
			download_src: "regular"
		});
	};

	const handleCloseEmailExport = (emailExportSuccess = false, exportType) => {
		// close email export drawer
		setShowEmailExport(false);
		if (emailExportSuccess) {
			// track event
			trackReportDownloadEvent(exportType);

			// close report detail and redirect to reports list
			handleClose(true);
		} else {
			// open report detail
			setTimeout(() => setFormOpen(true), 100);
		}
	};

	if (showEmailExport) {
		return <ShareOnEmail isOpen={showEmailExport} close={handleCloseEmailExport} />;
	}

	return (
		<div className="report-download">
			<FormSidebar
				isOpen={isFormOpen}
				close={() => handleClose(!fromPreview)}
				isNested={false}
				loading={isLoading}
				title={renderTitle()}
				subTitle={renderSubTitle()}
				hideSubmitAction={!fromPreview}
				multiActionsSubmit={!fromPreview}
				submitTitle={fromPreview ? "Preview" : "Export Report"}
				submit={fromPreview ? handlePreviewReport : undefined}
				hideCancelAction={fromPreview}
				cancelTitle={data?.previewAvailable ? "Preview" : "Cancel"}
				cancel={data?.previewAvailable ? handlePreviewReport : () => handleClose(!fromPreview)}
				showSecondaryAction={data?.previewAvailable}
				secondaryAction={() => handleClose(!fromPreview)}
				disabled={!data?.exports?.length}
				actions={
					data?.emailAvailable
						? [
								{
									id: "email",
									title: "Share on Email",
									clickHandler: () => handleExportReport({ id: "email", name: "Share on Email" })
								},
								...(data?.exports ?? [])?.map((type) => ({
									id: type.id,
									title: type.name,
									clickHandler: () => handleExportReport(type)
								}))
							]
						: (data?.exports ?? [])?.map((type) => ({
								id: type.id,
								title: type.name,
								clickHandler: () => handleExportReport(type)
							}))
				}
			>
				<div className="form-content">
					{!data?.filters?.length && loading ? (
						<div className="filters-container">
							<div className="shimmer W(330px) H(60px)"></div>
							<div className="shimmer W(330px) H(60px)"></div>
							<div className="shimmer W(330px) H(60px)"></div>
							<div className="shimmer W(330px) H(60px)"></div>
						</div>
					) : (
						<React.Fragment>
							<div className="filters-container">
								{data?.filters?.find((filter) => filter.field === "time_period") && (
									<NewDateCompareFilter
										title="Date"
										requiredLabel={true}
										showDropdown={true}
										hideComparison={true}
										currentDateFilter={currentDateFilter}
										appliedDateFilter={appliedDateFilter}
										updateState={setDateRangeFilter}
										position={"left"}
										monthsShown={1}
										minDate={
											currentDateFilter?.current?.rangeEndDate
												? moment(currentDateFilter?.current?.rangeEndDate)
														.subtract(30, "days")
														.diff(moment().subtract(ALLOWED_MONTHS, "months"), "days") >= 0
													? moment(currentDateFilter?.current?.rangeEndDate).subtract(
															30,
															"days"
														)
													: moment().subtract(12, "months")
												: ALLOWED_MONTHS
													? moment().subtract(ALLOWED_MONTHS, "months")
													: ""
										}
										maxDate={
											currentDateFilter?.current?.rangeStartDate
												? moment(currentDateFilter?.current?.rangeStartDate)
														.add(30, "days")
														.diff(moment(), "days") < 0
													? moment(currentDateFilter?.current?.rangeStartDate).add(30, "days")
													: moment()
												: moment()
										}
										defaultCustomRange={10}
										isClearable={true}
										setDefaultRange={false}
										hidePresetTypes={["This year", "90 D"]}
										validationMessage={validations?.timePeriod || ""}
										showCustomTooltip={true}
										tooltipInfo="Date range is limited up to 31 days"
										tooltipPosition="right"
									/>
								)}
								<ReportFilters
									options={data.filters || []}
									filtersToDisplay={data.filters?.map((f) => f.field) || []}
									setFilter={setFilter}
									currentFilters={currentFilters}
									optionUpdates={optionUpdates}
									handleCheck={handleCheck}
									isCheckedAll={isCheckedAll}
									handleCheckAll={handleCheckAll}
									validations={validations}
									showFields={true}
									isMultibrandEnabled={isMultibrandEnabled}
								/>
							</div>
							{!data?.previewAvailable && (
								<StatusInfo
									icon="info"
									status="info"
									color="#3b5feb"
									header="Preview will not be available"
									description="You can export/download and see the results"
								/>
							)}
						</React.Fragment>
					)}
				</div>
			</FormSidebar>
		</div>
	);
};
export default connect((store) => ({
	reportDetail: store.reportDetail,
	isMultibrandEnabled: store.login.loggedInbizDetail.isMultibrandEnabled
}))(ReportDownload);
