import { store } from "../store/configureStore";
import { clientPersicope } from "../client-periscope";
import {
	ACTIVITY_LOG_ITEM_AVAILABILITY,
	ACTIVITY_LOG_ITEM_AVAILABILITY_WITHOUT_BRAND,
	ACTIVITY_LOG_LOCATION_AVAILABILITY,
	ACTIVITY_LOG_LOCATION_AVAILABILITY_WITHOUT_BRAND,
	CREATE_BIZ_SUBSCRIPTION_MUTATION,
	CREATE_IMPORTANT_ITEM_MUTATION,
	CREATE_OR_UPDATE_HISTORY,
	DETAILS_OF_ITEM_ISSUE,
	GET_ACTIVITY_LOG_FOR_ITEM_AVAILABILITY_TIMESERIES,
	GET_ACTIVITY_LOG_FOR_LOCATION_AVAILABILITY_TIMESERIES,
	GET_DASHBOARD_METRICS_QUERY,
	GET_ITEM_AVAILABAILITY_METRICS_QUERY,
	GET_ITEM_AVAILABILITY_TABLE_ITEMS_METRICS,
	GET_LOCATION_AVAILABILITY_METRICS_QUERY,
	GET_LOCATION_AVAILABILITY_TABLE_METRICS,
	GET_LOCATIONS_LIST,
	GET_MENU_CATERGORIES_FILTER_VALUES,
	GET_PERISCOPE_ENABLED_BRANDS,
	GET_PERISCOPE_ENABLED_CITIES,
	GET_PERISCOPE_ENABLED_LOCATIONS,
	GET_SPECIFIC_ITEM_DETAILS,
	GET_STORE_METRICS_QUERY,
	ITEM_ISSUES_INFO_MATRIX,
	ITEM_ISSUES_METRICS,
	PERISCOPE_BIZ_SUBSCRIPTION_QUERY,
	PERISCOPE_BIZ_WAITLIST_QUERY,
	PERISCOPE_EARLY_ACCESS_STORES_REGISTER,
	PERISCOPE_JOIN_WAITLIST,
	REMOVE_IMPORTANT_ITEM_MUTATION,
	VISIT_HISTORY_QUERY
} from "../graphql/periscope";
import { toggleGlobalLoader } from "./actions";

import { debounce } from "lodash";
import NotificationServices from "../services/NotificationService";
import { client } from "../client";
import { ActionTypes } from "./_types";
import { lS } from "../atlas-utils";
import { GET_CONFIGURATION_VALUES, SAVE_CONFIGURATION_VALUES } from "../graphql/misc";
import { fetchPeriscopeData as fetchPeriscopeRefreshStatus } from "./notifications";

export const fetchDashboardMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_DASHBOARD_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_DASHBOARD_METRICS_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getDashboardMetrics.lastUpdate }
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_DASHBOARD_SUCCESS,
			payload: resp
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_DASHBOARD_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchStoreMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_STORE_METRIC_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_STORE_METRICS_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_STORE_METRIC_SUCCESS,
			payload: resp
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_STORE_METRIC_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};
export const fetchLocationMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_LOCATION_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_LOCATION_AVAILABILITY_METRICS_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});

		store.dispatch({
			type: ActionTypes.PERISCOPE_LOCATION_SUCCESS,
			payload: resp
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_LOCATION_FAILURE
			});
		} else {
			if (periscopeDataFetch) {
				store.dispatch({
					type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
					payload: { periscopeDataFetch: false }
				});
			}
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchItemMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_ITEM_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_ITEM_AVAILABAILITY_METRICS_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});

		store.dispatch({
			type: ActionTypes.PERISCOPE_ITEM_SUCCESS,
			payload: resp
		});
		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_ITEM_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}

		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchLocationAvailabilityTableMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_LOCATION_AVAILABILITY_TABLE_METRICS,
			variables,
			fetchPolicy: "no-cache"
		});

		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_OBJECTS_CHANGE,
			payload: resp.data.getLocationAvailabilityMatrix
		});

		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_LIST_STATE_CHANGE,
			payload: { count: resp.data.getLocationAvailabilityMatrix.meta.totalCount }
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getLocationAvailabilityMatrix.meta.lastUpdate }
		});
		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch({
			type: ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_SUCCESS
		});

		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.LOCATION_AVAILABILITY_PERISCOPE_STATE_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}

		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedLocationAvailabilityTableMetrics = debounce((...args) => {
	return fetchLocationAvailabilityTableMetrics(...args);
}, 300);

export const fetchItemAvailabilityItemTableMetrics = async (variables, view = "item", periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_REQUEST,
				payload: { view }
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_ITEM_AVAILABILITY_TABLE_ITEMS_METRICS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_OBJECTS_CHANGE,
			payload: { objects: resp.data.getItemAvailabilityMatrix, view }
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { count: resp?.data?.getItemAvailabilityMatrix?.meta?.totalCount }
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getItemAvailabilityMatrix.meta.lastUpdate }
		});
		store.dispatch(toggleGlobalLoader(false));

		store.dispatch({
			type: ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_SUCCESS,
			payload: { view }
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVILABILITY_VIEW_CHANGE,
			payload: { view: view }
		});

		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.ITEM_AVAIALABILITY_ITEM_STATE_DATA_FAILURE,
				payload: { view: view }
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}

		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { count: 0 }
		});
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedItemAvailabilityItemTableMetrics = debounce((...args) => {
	return fetchItemAvailabilityItemTableMetrics(...args);
}, 300);

export const fetchItemAvailabilitySpecificLocation = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_LOCATION_REQUEST
			});
			store.dispatch(toggleGlobalLoader(true));
		}
		const resp = await clientPersicope.query({
			query: GET_ITEM_AVAILABILITY_TABLE_ITEMS_METRICS,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_OBJECTS_CHANGE,
			payload: resp.data.getItemAvailabilityMatrix
		});

		store.dispatch(toggleGlobalLoader(false));

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_SUCCESS
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_LOCATION_DATA_REQUEST_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedItemAvailabilitySpecificLocation = debounce((...args) => {
	return fetchItemAvailabilitySpecificLocation(...args);
}, 300);

export const fetchSpecificItemTableMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_ITEM_REQUEST
			});
		}

		const resp = await clientPersicope.query({
			query: GET_SPECIFIC_ITEM_DETAILS,
			variables,
			fetchPolicy: "no-cache"
		});

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_OBJECTS_CHANGE,
			payload: resp.data.getItemDetails
		});

		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_SUCCESS
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getItemDetails.meta.lastUpdate }
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_DATA_REQUEST_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	}
};

export const fetchDebouncedSpecificItemTableMetrics = debounce((...args) => {
	return fetchSpecificItemTableMetrics(...args);
}, 300);

export const fetchMenuCategoriesValues = async (variables, place) => {
	try {
		const resp = await clientPersicope.query({
			query: GET_MENU_CATERGORIES_FILTER_VALUES,
			variables,
			fetchPolicy: "no-cache"
		});
		if (place == "location") {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_LOCATION_FILTERS,
				payload: { menuCategoriesFilterValues: resp.data.getMenuCategories }
			});
		} else if (place == "item") {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_AVAILABILITY_STATE_ITEM_FILTERS,
				payload: { menuCategoriesFilterValues: resp.data.getMenuCategories }
			});
		} else if (place == "itemIssues") {
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_STATE_FILTERS,
				payload: { menuCategoriesFilterValues: resp.data.getMenuCategories }
			});
		} else if (place == "itemAvailability") {
			store.dispatch({
				type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
				payload: { menuCategoriesFilterValues: resp.data.getMenuCategories }
			});
		} else if (place == "specificItemIssues") {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_STATE_FILTERS,
				payload: { menuCategoriesFilterValues: resp.data.getMenuCategories }
			});
		}
	} catch (error) {
		console.log("Error ", error);
	}
};

export const handleCreateCheckItem = async (variables) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: CREATE_IMPORTANT_ITEM_MUTATION,
			variables: variables
		});
		NotificationServices.pushNotification({
			message: "Item added to important items ",
			timeout: 3000,
			type: "success",
			isClosable: true,
			theme: "dark"
		});
		return resp.data.createImportantItem;
	} catch (error) {
		NotificationServices.pushNotification({
			message: "Item did not added to important items ",
			timeout: 3000,
			type: "error",
			isClosable: true
		});
	}
};

export const handleRemoveImportantItem = async (variables) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: REMOVE_IMPORTANT_ITEM_MUTATION,
			variables: variables
		});
		return resp.data.removeImportantItem;
	} catch (error) {}
};

export const visitHistory = async (variables) => {
	try {
		const resp = await clientPersicope.query({
			query: VISIT_HISTORY_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { visitHistory: resp.data.getVisitHistoryForBiz }
		});
	} catch (error) {}
};

export const createVisitHistory = async (variables) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: CREATE_OR_UPDATE_HISTORY,
			variables: variables
		});
		store.dispatch({
			type: ActionTypes.ITEM_AVAILABILITY_STATE_FILTERS,
			payload: { visitHistory: resp.data.createOrUpdateVisitHistory }
		});
	} catch (error) {}
};

export const fetchItemsIssuesMetrics = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.GET_ITEM_ISSUES_METRIC_STATE_CHANGE,
				payload: { loading: true }
			});
		}
		const resp = await clientPersicope.mutate({
			mutation: ITEM_ISSUES_METRICS,
			variables: variables
		});

		store.dispatch({
			type: ActionTypes.GET_ITEM_ISSUES_METRIC_STATE_CHANGE,
			payload: { data: resp.data.getItemsIssuesMetrics, loading: false, error: false }
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.GET_ITEM_ISSUES_METRIC_STATE_CHANGE,
				payload: { error: true, loading: false }
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	}
};

export const fetchItemsIssuesInfo = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch(toggleGlobalLoader(true));
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_STATE_REQUEST
			});
		}
		const resp = await clientPersicope.query({
			query: ITEM_ISSUES_INFO_MATRIX,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_OBJECTS_CHANGE,
			payload: resp.data.getItemsIssuesInfo
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getItemsIssuesInfo.meta.lastUpdate }
		});
		store.dispatch({
			type: ActionTypes.ITEM_ISSUES_STATE_SUCCESS
		});

		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		store.dispatch(toggleGlobalLoader(false));
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.ITEM_ISSUES_STATE_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	}
};

export const fetchDebouncedItemsIssuesInfo = debounce((...args) => {
	return fetchItemsIssuesInfo(...args);
}, 300);

export const fetchSpecificItemIssue = async (variables, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch(toggleGlobalLoader(true));
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST
			});
		}
		const resp = await clientPersicope.query({
			query: DETAILS_OF_ITEM_ISSUE,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST_SUCCESS
		});
		store.dispatch({
			type: ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_OBJECTS_CHANGE,
			payload: resp.data.getItemIssuesDetails
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getItemIssuesDetails.meta.lastUpdate }
		});
		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.SPECIFIC_ITEM_ISSUE_DATA_REQUEST_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedSpecificItemIssue = debounce((...args) => {
	return fetchSpecificItemIssue(...args);
}, 300);

export const fetchActivityLogLocationDetails = async (variables, isMultibrandEnabled, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch(toggleGlobalLoader(true));
			store.dispatch({
				type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST
			});
		}
		const resp = await clientPersicope.query({
			query: isMultibrandEnabled
				? ACTIVITY_LOG_LOCATION_AVAILABILITY
				: ACTIVITY_LOG_LOCATION_AVAILABILITY_WITHOUT_BRAND,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_DATA_OBJECTS_CHANGE,
			payload: resp.data.getActivityLogForLocationAvailability
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getActivityLogForLocationAvailability.meta.lastUpdate }
		});
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST_SUCCESS
		});
		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedActivityLogLocationDetails = debounce((...args) => {
	return fetchActivityLogLocationDetails(...args);
}, 300);

export const fetchActivityLogItemDetails = async (variables, isMultibrandEnabled, periscopeDataFetch = false) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch(toggleGlobalLoader(true));
			store.dispatch({
				type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST
			});
		}
		const resp = await clientPersicope.query({
			query: isMultibrandEnabled ? ACTIVITY_LOG_ITEM_AVAILABILITY : ACTIVITY_LOG_ITEM_AVAILABILITY_WITHOUT_BRAND,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_DATA_OBJECTS_CHANGE,
			payload: resp.data.getActivityLogForItemAvailability
		});
		store.dispatch({
			type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST_SUCCESS
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
			payload: { timestamp: resp.data.getActivityLogForItemAvailability.meta.lastUpdate }
		});
		store.dispatch(toggleGlobalLoader(false));
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.ACTIVITY_LOG_DATA_REQUEST_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
		store.dispatch(toggleGlobalLoader(false));
	}
};

export const fetchDebouncedActivityLogItemDetails = debounce((...args) => {
	return fetchActivityLogItemDetails(...args);
}, 300);

export const fetchEarlyAccessLocationsList = async (variables, periscopeDataFetch) => {
	try {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_REQUEST
			});
		}
		const resp = await client.query({
			query: GET_LOCATIONS_LIST,
			variables,
			fetchPolicy: "cache-first"
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_CHANGE,
			payload: resp.data.stores
		});
		if (periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	} catch (error) {
		if (!periscopeDataFetch) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_FAILURE
			});
		} else {
			store.dispatch({
				type: ActionTypes.PERISCOPE_RESPONSE_DATA_CHANGE,
				payload: { periscopeDataFetch: false }
			});
		}
	}
};

export const fetchDebouncedEarlyAccessLocationsList = debounce((...args) => {
	return fetchEarlyAccessLocationsList(...args);
}, 300);

export const periscopeEarlyAccessCheckout = async (storesData) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: PERISCOPE_EARLY_ACCESS_STORES_REGISTER,
			variables: {
				input: storesData
			}
		});
		if (resp?.data?.saveBulkStores) {
			return true;
		}
	} catch (error) {
		console.log(error);
		return false;
	}
};

export const periscopeCreateSubscription = async (subscriptionInput) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: CREATE_BIZ_SUBSCRIPTION_MUTATION,
			variables: {
				input: subscriptionInput
			}
		});
		fetchPeriscopeRefreshStatus(subscriptionInput.bizId);
		let localStorage = lS.get("periscope") || {};
		localStorage = { ...localStorage, bizSubscription: resp.data.createBizSubscription };
		lS.set("periscope", localStorage);
		return resp;
	} catch (error) {
		console.log(error);
	}
};

export const periscopeCheckUserWaitList = async (variables) => {
	try {
		const resp = await clientPersicope.query({
			query: PERISCOPE_BIZ_WAITLIST_QUERY,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { waitlist: resp.data.getWaitlistForBiz }
		});
		let localStorage = lS.get("periscope") || {};
		if (resp.data.getWaitlistForBiz?.bizId) {
			localStorage = { ...localStorage, waitlist: true };
		} else {
			localStorage = { ...localStorage, waitlist: false };
		}
		lS.set("periscope", localStorage);
	} catch (error) {}
};

export const periscopeBizSubscriptionQuery = async (variables) => {
	try {
		const resp = await clientPersicope.query({
			query: PERISCOPE_BIZ_SUBSCRIPTION_QUERY,
			variables: variables
		});
		if (resp.data.getBizSubscription == null) {
			let localStorage = lS.get("periscope") || {};
			localStorage = { ...localStorage, bizSubscription: null };
			lS.set("periscope", localStorage);
		} else {
			let localStorage = lS.get("periscope") || {};
			localStorage = { ...localStorage, bizSubscription: resp.data.getBizSubscription };
			lS.set("periscope", localStorage);
		}
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { bizSubscription: resp.data.getBizSubscription }
		});
	} catch (error) {}
};

export const periscopeJoinWaitlist = async (variables) => {
	try {
		const resp = await clientPersicope.mutate({
			mutation: PERISCOPE_JOIN_WAITLIST,
			variables: variables
		});
		store.dispatch({
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_STATE_CHANGE,
			payload: { waitlist: resp.data.joinWaitlist }
		});
		let localStorage = lS.get("periscope") || {};
		localStorage = { ...localStorage };
		lS.set("periscope", localStorage);
	} catch (error) {
		console.log(error);
	}
};

export const fetchPeriscopeEnabledCities = async (bizId, includeAll = false, search = "") => {
	try {
		const resp = await clientPersicope.query({
			query: GET_PERISCOPE_ENABLED_CITIES,
			variables: { bizId, limit: 50, offset: 0, search }
		});
		let allCities = [...resp?.data?.getPeriscopeEnabledCities].map((city) => {
			return { value: city, valueForDisplay: city, __typename: "CityType" };
		});
		if (includeAll) {
			allCities.unshift({ value: "all", valueForDisplay: "All Cities", __typename: "CityType" });
		}
		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_CITIES",
			payload: allCities
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_CITIES",
			payload: []
		});
	}
};

export const fetchDebouncedPersicopeEnabledCities = debounce((...args) => {
	return fetchPeriscopeEnabledCities(...args);
}, 300);

export const fetchPeriscopeEnabledBrands = async (bizId) => {
	try {
		const resp = await clientPersicope.query({
			query: GET_PERISCOPE_ENABLED_BRANDS,
			variables: { bizId }
		});
		const brands = resp?.data?.getPeriscopeEnabledBrands;
		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_BRANDS",
			payload: brands
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_BRANDS",
			payload: []
		});
	}
};

export const fetchPeriscopeEnabledLocations = async (bizId) => {
	try {
		const resp = await clientPersicope.query({
			query: GET_PERISCOPE_ENABLED_LOCATIONS,
			variables: { bizId }
		});
		const locations = resp?.data?.getPeriscopeEnabledLocations;

		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_LOCATIONS",
			payload: locations
		});
	} catch (error) {
		console.log(error);
		store.dispatch({
			type: "GET_PERISCOPE_ENABLED_LOCATIONS",
			payload: []
		});
	}
};

export const fetchActivityLogLocationAvailabilityTimeSeries = async (variables) => {
	try {
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: { loading: true, error: false }
		});
		const resp = await clientPersicope.query({
			query: GET_ACTIVITY_LOG_FOR_LOCATION_AVAILABILITY_TIMESERIES,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: {
				loading: false,
				error: false,
				data: resp.data.getActivityLogForLocationAvailabilityTimeseries
			}
		});
	} catch (err) {
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: { loading: false, error: true }
		});
		console.log(err);
	}
};

export const fetchActivityLogItemAvailabilityTimeSeries = async (variables) => {
	try {
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: { loading: true, error: false }
		});
		const resp = await clientPersicope.query({
			query: GET_ACTIVITY_LOG_FOR_ITEM_AVAILABILITY_TIMESERIES,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: {
				loading: false,
				error: false,
				data: resp.data.getActivityLogForItemAvailabilityTimeseries
			}
		});
	} catch (err) {
		store.dispatch({
			type: "ACTIVITY_LOG_GRAPH_DATA",
			payload: { loading: false, error: true }
		});
	}
};

export const fetchConfigurationValuesForPeriscope = async (variables) => {
	try {
		const resp = await client.query({
			query: GET_CONFIGURATION_VALUES,
			variables,
			fetchPolicy: "no-cache"
		});
		store.dispatch({
			payload: { configurationValues: resp.data.configurationValues },
			type: ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_VALUES_CHANGE
		});
	} catch (err) {}
};

export const saveConfigurationValues = async (variables) => {
	store.dispatch(toggleGlobalLoader(true));
	try {
		const resp = await client.mutate({
			mutation: SAVE_CONFIGURATION_VALUES,
			variables,
			fetchPolicy: "no-cache"
		});

		if (resp.data?.saveConfigurationValues?.status?.success) {
			store.dispatch({
				type: ActionTypes.PERISCOPE_EARLY_ACCESS_DATA_VALUES_CHANGE,
				payload: {
					configurationValues: [
						{
							key: "periscope_enabled",
							value: "1",
							__typename: "ConfigurationKeyValue"
						}
					]
				}
			});
			return resp.data?.saveConfigurationValues;
		} else {
			return false;
		}
	} catch (err) {
		return false;
	} finally {
		store.dispatch(toggleGlobalLoader(false));
	}
};
